import { CircularProgress } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import { GameService } from 'src/services/game.service';
import { TrainingService } from 'src/services/training.service';
import { ActionDialog } from 'src/components/General/ActionDialog/ActionDialog';
import { FullScreenDialog } from 'src/components/General/FullScreenDialog/FullScreenDialog';
import { SimpleList } from 'src/components/General/SimpleList/SimpleList';
import { PresetsForm } from 'src/components/Training/Presets/PresetsForms/PresetsForm';
import { NCTitle, NCTitleType } from 'src/atoms/NCTitle/NCTitle';
import { TrainingPreset } from 'src/models/TrainingPreset';
import { TrainingThematic } from 'src/models/TrainingThematic';
import { Game } from 'src/models/Game';
import { SearchBar } from 'src/components/General/SearchBar/SearchBar';
import './Presets.scss';


export const TrainingPresets: React.FunctionComponent = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [rows, setRows] = useState<any>([]);
    const [preset, setPreset] = useState<TrainingPreset>();
    const [actionDelete, setActionDelete] = useState<boolean>(false);
    const [actionEdit, setActionEdit] = useState<boolean>(false);
    const [actionCreate, setActionCreate] = useState<boolean>(false);
    const [actualPage, setActualPage] = useState<number>(0);
    const [totalItem, setTotalItem] = useState<number>(0);
    const [gameList, setGameList] = useState<Array<Game>>([]);
    const [searchedGame, setSearchedGame] = useState<string>('');
    const [thematics, setThematics] = useState<Array<TrainingThematic>>([]);
    const [searchRes, setSearchRes] = useState<any>();
    const searchFields: {[key: string]: { label: string }} = {
        path: { label: 'Path'},
        kind: { label: 'Kind'},
        _id: { label: 'ID' },
    }

    const columns = ['Name', 'Path', 'Kind', 'Game', 'Show in graph'];
    const keepRows = ['name', 'path', 'kind', 'game', 'showInGraph'];

    useEffect(() => {
        setIsLoading(true);
        TrainingService.getPresets(20, actualPage).then((data) => {
            setTotalItem(Math.round(Math.ceil(parseInt(data.total, 10))/ 20));
            setRows(data.docs)
            setIsLoading(false);
        }).catch(() => { toast.error('Error while fetching training presets')});

        getGames();
        getThematics();
    }, [actualPage]);

    const getPresetByFilter = () => {
        setIsLoading(true);
        let query = '';
        if (searchedGame) {
            query += `&game._id=${searchedGame}`;
        }
        if (searchRes) {
            Object.keys(searchRes).forEach((item) => {
                query = query.concat(query, `&${item}=${searchRes[item]}`);
            });
            setIsLoading(true);
        }
        TrainingService.getPresets(20, actualPage, query).then((data) => {
            setTotalItem(Math.round(Math.ceil(parseInt(data.total, 10))/ 20));
            setRows(data.docs)
            setIsLoading(false);
        }).catch(() => { toast.error('Error while fetching training presets')});

        getGames();
        getThematics();
    }

    const getGames = () => {
        GameService.getGames(50, 0, true).then((data) => {
            setGameList(data.docs);
        }).catch(() => { toast.error('Error while fetching game list')});
    }

    const getThematics = () => {
        TrainingService.getThematics(5000, 0).then((data) => {
            setThematics(data.docs)
        }).catch(() => { toast.error('Error while fetching training thematics')});
    }

    const actionManager = (preset: TrainingPreset, kind: string) => {
        setPreset(preset);

        switch (kind) {
            case 'delete':
                setActionDelete(true);
                break;
            case 'edit':
                setActionEdit(true);
                break;
            case 'create': {
                setPreset(
                    new TrainingPreset(
                        {en: ''},
                        '',
                        {
                            _id: '',
                            name: '',
                            overwolfId: 0,
                        },
                        '',
                        '',
                        false,
                    )
                );
                setActionCreate(true);
                break;
            }
            default:
                return;
        }
    };

    const deleteGame = (choice: boolean) => {
        if (choice && preset) {
            TrainingService.deletePreset(preset._id).then(() => {
                updateList(true);
            }).catch(error => console.log(error));
        }
    }

    const updateList = (result: boolean) => {
        setActionEdit(false);
        setActionCreate(false);
        let query = '';
        if (searchedGame) {
            query += `&game._id=${searchedGame}`;
        }

        if (result) {
            TrainingService.getPresets(20, actualPage, query).then((data) => {
                setTotalItem(Math.round(Math.ceil(parseInt(data.total, 10))/ 20));
                setRows(data.docs)
                setIsLoading(false);
            }).catch(() => { toast.error('Error while fetching training presets')});
        }
    }

    const handleRows = () => {
        rows.forEach((row: any) => {
            if (row.game.overwolfId !== undefined) {
                gameList.forEach((game: any) => {
                    if (game.overwolfId !== undefined) {
                        if (row.game.overwolfId === game.overwolfId) {
                            row.game.name = game.name;
                        }
                    }
                });
            }
        });
        return rows;
    }

    return (
        <div className="NC-container NC-card d-flex flex-column">
            <NCTitle text={'Training Presets'} type={ NCTitleType.TRAINING }/>
            <div className="filter-background d-flex flex-row justify-content-between ">
                <div className="w-50">
                    <SearchBar setSearchResult={ setSearchRes } searchFields={ searchFields } actionHook={getPresetByFilter}/>
                </div>
                <TextField
                    select
                    label="Select"
                    value={searchedGame}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => setSearchedGame(event.target.value)}
                    className="w-50"
                >
                    { gameList.map((game: any, index: number) => {
                            if (game.overwolfId) {
                                return (
                                    <MenuItem key={index} value={game._id}>
                                        {game.name}
                                    </MenuItem>
                                );
                            } else {
                                return null;
                            }
                        })
                    }
                </TextField>
            </div>

            <div className="d-flex">
                {
                    isLoading && <CircularProgress className="mx-auto"/>
                }
                {
                    !isLoading &&
                    <SimpleList
                        rows={handleRows}
                        columns={columns}
                        keepRows={keepRows}
                        actionsButtons={{ create: true, edit: true, delete: true }}
                        handleClickOpen={actionManager}
                        pagination={{ actual: actualPage, total: totalItem, setActual: setActualPage }}
                    />
                }
            </div>
            <ActionDialog
                open={actionDelete}
                title={`Are you sure to delete this game : ${preset?.name.en}`}
                openHook={setActionDelete}
                actionHook={deleteGame}
            />
            {
                preset &&
                <FullScreenDialog
                    open={actionEdit}
                    openHook={setActionEdit}
                    actionHook={updateList}
                    componentToDisplay={
                        <PresetsForm
                            preset={preset}
                            thematics={thematics}
                            actionHook={updateList}
                            gameList={gameList}
                        />
                    }
                />
            }
            {
                preset &&
                <FullScreenDialog
                    open={actionCreate}
                    openHook={setActionCreate}
                    actionHook={updateList}
                    componentToDisplay={
                        <PresetsForm
                            preset={preset}
                            thematics={thematics}
                            actionHook={ updateList }
                            creationMode={ true }
                            gameList={gameList}
                        />
                    }
                />
            }
        </div>
    );
}