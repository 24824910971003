import { TranslatedString } from "./TranslatedString";

export class VideoModel {

    constructor(
        public _id: string,
        public title: TranslatedString,
        public link: TranslatedString,
        public category: any,
        public published: boolean = false,
        public game: { _id: string, value: string, name: string },
    ) {
    }
}