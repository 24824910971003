import './NoGameForm.scss';

import {EGameImage} from 'src/models/Game';
import React, {useState} from 'react';

import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {MediaManager} from 'src/components/General/MediaManager/MediaManager';
import Switch from '@material-ui/core/Switch';
import Tab from '@material-ui/core/Tab';
import {TabPanel} from 'src/atoms/TabPanel/TabPanel';
import Tabs from '@material-ui/core/Tabs';
import {toast} from 'react-toastify';
import useTheme from '@material-ui/core/styles/useTheme';
import { NoGameInfo } from '../NoGameInfo/GameInfo';
import { Game, updateGame, createGame } from '@wolfiesports/srm-component';

enum TabsName {
    Info = 0,
    Media = 1,
}

interface NoGameFormProps {
    actionHook: any;
    game: Game;
    creationMode?: boolean;
}

export const NoGameForm: React.FunctionComponent<NoGameFormProps> = (props: NoGameFormProps) => {
    const [game, setGame] = useState<Game | null>(props.game);
    const [value, setValue] = useState<number>(0);
    const [edit, setEdit] = useState<boolean>(false);
    const theme = useTheme();
    console.log(props.creationMode);
    const a11yProps = (index: any) => {
        return {
            id: `full-width-tab-${index}`,
            'aria-controls': `full-width-tabpanel-${index}`,
        };
    };

    const saveGame = () => {
        if (game) {
            if (edit) {
                console.log('Update Game', game, game.active, game.formatToJsonAPI());
                updateGame(new Game(game)).then((data) => {
                    if (data) {
                        toast.success('Game saved');
                        props.actionHook(true);
                    } else {
                        toast.error('Error while updating game');
                        props.actionHook(false);
                    }
                });
            } else {
                createGame(new Game(game)).then(() => {
                    toast.success('Game created');
                    props.actionHook(true);
                }).catch(() => {
                    toast.error('Error while creating game');
                    props.actionHook(false);
                });
            }
        }
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>, newValue: number) => {
        setValue(newValue);
    };

    return (
        <React.Fragment>
            <div className="w-100 NC-tab">
                {
                    !props.creationMode &&
                    <>
                        <AppBar position="static" color="default">
                            <Tabs
                                value={value}
                                onChange={(event: React.ChangeEvent<any>, newValue: any) => handleChange(event, newValue)}
                                variant="fullWidth"
                            >
                                <Tab label="Infos" {...a11yProps(0)} />
                                <Tab label="Media" {...a11yProps(1)} />
                            </Tabs>
                        </AppBar>
                    </>
                }
                {
                    props.creationMode &&
                    <>
                        <AppBar position="static" color="default">
                            <Tabs
                                value={value}
                                onChange={(event: React.ChangeEvent<any>, newValue: any) => handleChange(event, newValue)}
                                variant="fullWidth"
                            >
                                <Tab label="Infos" {...a11yProps(0)} />
                            </Tabs>
                        </AppBar>
                    </>
                }
                <div className="w-100 d-flex">
                    <div className="w-50">
                        {
                            !props.creationMode &&
                            <FormControlLabel
                                control={<Switch color="primary" checked={edit} onChange={() => setEdit(!edit)}/>}
                                label="Edit"
                                labelPlacement="start"
                            />
                        }

                    </div>
                    <div className="w-50">
                        {
                            (edit || props.creationMode) &&
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                    saveGame();
                                }}
                            >
                                Save
                            </Button>
                        }
                    </div>
                </div>
                <TabPanel value={value} index={TabsName.Info} dir={theme.direction}>
                    {
                        game &&
                        <NoGameInfo game={game} edit={edit} setGame={setGame} creationMode={props.creationMode}/>
                    }
                </TabPanel>
                {
                    !props.creationMode &&
                    <TabPanel value={value} index={TabsName.Media} dir={theme.direction}>
                        {
                            game &&
                            <MediaManager
                                id={game.id}
                                path={['media']}
                                secondPath={['games']}
                                slug={game.id}
                                mediaNames={Object.keys(EGameImage)}
                            />
                        }
                    </TabPanel>
                }
            </div>
        </React.Fragment>
    );
};