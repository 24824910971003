import React, {ChangeEvent, useEffect, useState,} from 'react';
import TextField from '@material-ui/core/TextField';
import './AdministratorsForm.scss';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Checkbox from '@material-ui/core/Checkbox';
import {AdministratorService} from 'src/services/administrator.service';
import {CompanyService} from 'src/services/company.service';
import {Administrator, AdministratorsRole} from 'src/models/Administrator';
import {Company} from 'src/models/Company';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import createStyles from '@material-ui/core/styles/createStyles';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input/Input';
import AppBar from '@material-ui/core/AppBar/AppBar';
import Tabs from '@material-ui/core/Tabs/Tabs';
import Tab from '@material-ui/core/Tab/Tab';
import {TabPanel} from '../../../atoms/TabPanel/TabPanel';
import {AdministratorActivity} from '../AdministratorActivity/AdministratorActivity';
import {ListItemText} from "@material-ui/core";
import {toast} from "react-toastify";


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formControl: {
            width: '100%',
        },
        chips: {
            display: 'flex',
            flexWrap: 'wrap',
        },
        chip: {
            margin: 2,
        },
        noLabel: {
            marginTop: theme.spacing(3),
        },
    })
);

enum TabsName {
    Info = 0,
    Activity = 1,
}

interface AdministratorsFormProps {
    administrator: any;
    setAdministrator: any;
    creationMode?: boolean;
    actionHook: any;
}

export const AdministratorsForm: React.FunctionComponent<AdministratorsFormProps> = (props: AdministratorsFormProps) => {
    const [edit, setEdit] = useState(false);
    const [administrator, setAdministrator] = useState<Administrator>(props.administrator);
    const [showPassword, setShowPassword] = useState(false);
    const classes = useStyles();
    const [value, setValue] = useState(0);

    const [companies, setCompanies] = useState<Array<Company>>();
    const [selectedCompany, setSelectedCompany] = useState<any>(props.administrator.company);
    const [selectedAdminRoles, setSelectedAdminRoles] = useState<Array<AdministratorsRole>>(props.administrator.role || []);

    const roles: any = AdministratorsRole;


    useEffect(() => {
        CompanyService.getAllCompanies().then((data) => {
            setCompanies(data);
        });
    }, []);

    if (!administrator) {
        return <></>;
    }

    const handleSelectedCompany = (event: any) => {
        setSelectedCompany(event.target.value);
        administrator.company = event.target.value;
        setAdministrator(administrator);
    };


    const onAdministratorChange = (event: any) => {
        const {name, value} = event.currentTarget;
        setAdministrator(Object.assign({}, administrator, {
            [name]: value,
        }));
    };

    const save = () => {
        if (selectedAdminRoles.length === 0) {
            toast.error('Choose at least one role (lowest role: Guest)');
            return;
        }

        if (!selectedAdminRoles || selectedAdminRoles.length > 0) {
            administrator.role = selectedAdminRoles;
            setAdministrator(administrator);
        }
        if (selectedCompany) {
            administrator.company = selectedCompany;
            setAdministrator(administrator);
        }

        if (edit) {
            AdministratorService.updateAdministrator(administrator).then(() => {
                props.actionHook(true);
            });
        } else {
            AdministratorService.createAdministrator(administrator).then(() => {
                props.actionHook(true);
            });
        }
    };

    const renderSaveButton = () => {
        if (!edit && !props.creationMode) {
            return <></>;
        }
        return (
            <Button
                className="mt-5 w-25 mx-auto"
                variant="contained"
                color="primary"
                onClick={() => {
                    save();
                }}
            >
                Save
            </Button>
        );
    };

    const renderEditButton = () => {
        if (props.creationMode) {
            return <></>;
        }
        return (
            <FormControlLabel
                value="edit"
                control={<Switch color="primary" checked={edit} onChange={() => {
                    setEdit(!edit);
                }}/>}
                label="Edit"
            />
        );
    };

    const handleChange = (event: React.ChangeEvent<{ value: any }>) => {
        setSelectedAdminRoles(event.target.value);
    };

    function a11yProps(index: any) {
        return {
            id: `full-width-tab-${index}`,
            'aria-controls': `full-width-tabpanel-${index}`,
        };
    }

    const handleChangeValue = (event: React.ChangeEvent<HTMLInputElement>, newValue: number) => {
        setValue(newValue);
    };

    const renderPassword = () => {
        if (!props.creationMode) {
            return <></>;
        }
        return (
            <div className="d-flex flex-row row my-2">
                <div className="col-12 col-sm-6 px-3">
                    <TextField
                        disabled={!edit && !props.creationMode}
                        className="w-100"
                        id='password'
                        name="password"
                        label="Password"
                        autoComplete="new-password"
                        value={administrator.password}
                        type={showPassword ? 'text' : 'password'}
                        onChange={(event: ChangeEvent<HTMLInputElement>) => (onAdministratorChange(event))}
                    />
                </div>
                <FormControlLabel
                    className="col-12 col-sm-6 my-auto"
                    control={
                        <Checkbox
                            checked={showPassword}
                            onChange={() => {
                                setShowPassword(!showPassword);
                            }}
                            name="checkedB"
                            color="primary"
                        />
                    }
                    label="Show password"
                />
            </div>
        );
    };

    const getRoleLabel = (value: number) => {
        switch (value) {
            case 0:
                return 'GUEST';
            case 1:
                return 'TOURNAMENT';
            case 2:
                return 'TRAINING';
            case 3:
                return 'LEGAL';
            case 4:
                return 'PARTNER';
            case 5:
                return 'NCADMIN';
            default:
                return 'UNKNOWN';
        }
    };

    return (
        <div>
            <AppBar position="static" color="default" className="mb-2">
                <Tabs
                    value={value}
                    onChange={(event: React.ChangeEvent<any>, newValue: any) => handleChangeValue(event, newValue)}
                    variant="fullWidth"
                >
                    <Tab label="Infos" {...a11yProps(0)} />
                    <Tab label="Activity" {...a11yProps(1)} />
                </Tabs>
            </AppBar>
            <TabPanel value={value} index={TabsName.Info}>
                <form className="d-flex flex-column administrator-form">
                    {renderEditButton()}
                    <div className="d-flex flex-row row my-2">
                        <div className="col-12 col-sm-6 px-3">
                            <TextField
                                disabled={!edit && !props.creationMode}
                                className="w-100"
                                id='first_name'
                                name="first_name"
                                label="First Name"
                                value={administrator.first_name}
                                onChange={(event: ChangeEvent<HTMLInputElement>) => (onAdministratorChange(event))}
                            />
                        </div>
                        <div className="col-12 col-sm-6 px-3">
                            <TextField
                                disabled={!edit && !props.creationMode}
                                className="w-100"
                                id='last_name'
                                name="last_name"
                                label="Last Name"
                                value={administrator.last_name}
                                onChange={(event: ChangeEvent<HTMLInputElement>) => (onAdministratorChange(event))}
                            />
                        </div>
                    </div>
                    <div className="d-flex flex-row row my-2">
                        <div className="col-12 col-sm-6 px-3">
                            <TextField
                                disabled={!props.creationMode}
                                className="w-100"

                                id='email'
                                name="email"
                                label="Email"
                                autoComplete="off"
                                value={administrator.email}
                                onChange={(event: ChangeEvent<HTMLInputElement>) => (onAdministratorChange(event))}
                            />
                        </div>
                        <div className="col-12 col-sm-6 px-3">
                            <TextField
                                disabled={!edit && !props.creationMode}
                                className="w-100"
                                id='favorite_language'
                                name="favorite_language"
                                label="Favorite Language"
                                value={administrator.favorite_language}
                                onChange={(event: ChangeEvent<HTMLInputElement>) => (onAdministratorChange(event))}
                            />
                        </div>
                    </div>
                    {renderPassword()}
                    <div className="d-flex flex-row row my-2">
                        <div className="col-12 col-sm-6 px-3">
                            <TextField
                                disabled
                                className="w-100"
                                id='date_creation'
                                name="date_creation"
                                label="Date Creation"
                                value={administrator.date_creation}
                                onChange={(event: ChangeEvent<HTMLInputElement>) => (onAdministratorChange(event))}
                            />
                        </div>
                        <div className="col-12 col-sm-6 px-3">
                            <TextField
                                disabled
                                className="w-100"
                                id='_id'
                                name="_id"
                                label="ID"
                                value={administrator._id}
                                onChange={(event: ChangeEvent<HTMLInputElement>) => (onAdministratorChange(event))}
                            />
                        </div>
                        <div className="col-12 col-sm-6 px-3">
                            <FormControl className={classes.formControl}>
                                <InputLabel id="administrator-role">Role</InputLabel>
                                <Select
                                    disabled={!edit && !props.creationMode}
                                    className="w-100"
                                    multiple
                                    value={selectedAdminRoles}
                                    onChange={handleChange}
                                    input={<Input id="select-multiple-administrator-role"/>}
                                    renderValue={(selected) => {
                                        return (
                                            <div className="d-flex flex-row flex-wrap">
                                                {
                                                    (selected as Array<number>).map(
                                                        (value: number, index: number) => (
                                                        <div key={index} className={`${roles[value]} styled-role m-1`}>
                                                            {getRoleLabel(value)}
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        );
                                    }}
                                >
                                    { AdministratorsRole &&
                                        Object.keys(AdministratorsRole).map((name: string) => {
                                            if (isNaN(Number(name))) {
                                                return (
                                                    <MenuItem key={Number(name)} value={Number(name)}>
                                                        <Checkbox
                                                            checked={selectedAdminRoles.indexOf(Number(name)) > -1}
                                                        />
                                                        <ListItemText primary={getRoleLabel(Number(name))} />
                                                    </MenuItem>
                                                );
                                            } else {
                                                return null;
                                            }
                                        })
                                    }
                                </Select>
                            </FormControl>
                        </div>
                        <div className="col-12 col-sm-6 px-3" style={{alignSelf: 'flex-end'}}>
                            <FormControl className="w-100">
                                <InputLabel id="demo-simple-select-label">Companies</InputLabel>
                                <Select
                                    disabled={!edit && !props.creationMode}
                                    label="Companies"
                                    name="companies"
                                    value={selectedCompany ? selectedCompany : ''}
                                    onChange={handleSelectedCompany}
                                >
                                    <MenuItem value={''}>None</MenuItem>
                                    {
                                        companies && companies.map((company: Company) => {
                                            return (
                                                <MenuItem key={Math.random()}
                                                          value={company._id}>{company.name}</MenuItem>
                                            );
                                        })
                                    }
                                </Select>
                            </FormControl>
                        </div>
                    </div>
                    {renderSaveButton()}
                </form>
            </TabPanel>
            <TabPanel value={value} index={TabsName.Activity}>
                <AdministratorActivity administratorId={administrator._id}/>
            </TabPanel>
        </div>
    );
};