import React from 'react';

interface NCPlatformIconsProps {
    platforms: Array<string>;
}

export const NCPlatformIcons: React.FunctionComponent<NCPlatformIconsProps> = (
	{ platforms }
) => {
	return (
		<div className="platform-container">
			<div 
				className="d-flex marquee-container"
				style={{ width: `${platforms.length * 40}px` }}
			>
				<div
					className={`d-flex marquee-slider ${platforms.length > 4 ? 'slidable' : ''}`}
					style={{ animationDuration: `${platforms.length}s` }}
				>
					{platforms.map(
						(platformSlug: string,index: number) => {
							return (
								<div
									key={`${platformSlug}-platform-${index}`}
									className="icon mx-2"
									style={{
										maskImage: `url(${process.env.REACT_APP_S3_URL}/media/platforms/${platformSlug}.svg)`,
										WebkitMaskImage: `url(${process.env.REACT_APP_S3_URL}/media/platforms/${platformSlug}.svg)`,
									}}
								/>
							);
						}
					)}
				</div>
			</div>
			{platforms.length > 4 && <div className="plus">+</div>}
		</div>
	);
};