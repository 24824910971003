import React, {useState} from 'react';
import Pagination from '@material-ui/lab/Pagination';

interface PaginateProps {
    pagination: { actual: number, total: number, setActual: any };
}

export const Paginate: React.FunctionComponent<PaginateProps> = (props: PaginateProps) => {
    const [actualPage, setActualPage] = useState<number>(props.pagination.actual + 1);

    const changePage = (event: any, value: any) => {
        setActualPage(value);
        props.pagination.setActual(value - 1);
    };

    return (
        <>
            {
                props.pagination.total > 0 &&
                <Pagination
                    count={props.pagination.total}
                    page={actualPage}
                    color="primary"
                    className="my-2"
                    onChange={changePage}
                />
            }
        </>
    );
};