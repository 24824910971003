import { Button, Checkbox, FormControlLabel, InputLabel, ListItemText, MenuItem, Select, Switch, TextField } from '@material-ui/core';
import ColorPicker from 'material-ui-color-picker';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Add, Done, Clear } from '@material-ui/icons';
import { GameAccountService } from 'src/services/game-account.service';
import { Account, AccountType, EGameAccountImage, GameAccount } from 'src/models/GameAccount';
import { MediaManager } from 'src/components/General/MediaManager/MediaManager'; 
import { NoPlatform } from 'src/models/Platform';
import { Game, getGamesAdmin } from '@wolfiesports/srm-component';

interface AccountFormProps {
    account: Account;
    setAccount: any;
    creationMode?: boolean;
    actionHook: any;
}

export const AccountForm: React.FunctionComponent<AccountFormProps> = ({
                                                                           account,
                                                                           setAccount,
                                                                           creationMode,
                                                                           actionHook
                                                                       }) => {
    const [ gameList, setGameList ] = useState<Array<Game>>([]);
    const [ gameAccountList, setGameAccountList ] = useState<Array<GameAccount>>([]);
    const [ platformList, setPlatformList ] = useState<Array<NoPlatform>>([]);
    const [ newGame, setNewGame ] = useState<string>('default');

    useEffect(() => {
        Promise.all([
            getGame(),
            getPlatforms(),
            getGameAccount(account.id),
        ]);
    }, [ account ]);

    const onAccountChange = (event: any) => {
        setAccount(Object.assign({}, account, {
            [event.currentTarget.name]: event.currentTarget.value,
        }))
    }

    const onPlaceHolderChange = (event: any) => {
        setAccount(Object.assign({}, account, {
            'placeholder': event.currentTarget.value,
        }))
    }

    const onATypeChange = (event: any) => {
        setAccount(Object.assign({}, account, {
            'atype': event.target.value,
        }))
    }

    const onColorChange = (color: string) => {
        if (color && color !== undefined) {
            setAccount(Object.assign({}, account, {
                'color': color,
            }))
        }
    }

    const onActiveChange = (event: any, value: any) => {
        if (!value) {
            setAccount(Object.assign({}, account, {
                'active': 0,
            }))
        } else {
            setAccount(Object.assign({}, account, {
                'active': 1,
            }))
        }
    }

    const getGame = async (limit: number = 128) => {
        const res = await getGamesAdmin(limit);
        setGameList(res?.list?.sort((a, b) => a.id.localeCompare(b.id)) || []);
    }

    const getGameAccount = async (accountid: string) => {
        const res = await GameAccountService.getGameAccountByAccount(accountid);
        setGameAccountList(res.list.sort((a, b) => a.account.localeCompare(b.account)));
    }

    const getPlatforms = async () => {
        const res = await GameAccountService.getPlatforms();
        setPlatformList(res.list.sort((a, b) => a.id.localeCompare(b.id)));
    }

    const handleSelectGame = (event: React.ChangeEvent<{name?: string | undefined; value: unknown;}>) => {
        setNewGame(event.target.value as string);
    };

    const handleSelectPlatform = (event: React.ChangeEvent<{name?: string | undefined; value: unknown;}>, gameaccount: GameAccount) => {
        GameAccountService.updateGameAccount(gameaccount.id, gameaccount.account, gameaccount.active, event.target.value as Array<string>);
        const index = gameAccountList.indexOf(gameaccount);
        gameAccountList[index].platforms = event.target.value as Array<string>;
        setGameAccountList([...gameAccountList]);
    }

    const createGameAccount = async () => {
        GameAccountService.createGameAccount(newGame, account.id, 1, []);
        gameAccountList.push({
            account: account.id,
            route: `ga/${account}`,
            id: newGame,
            active: 1,
            platforms: [],
        });
        setGameAccountList([...gameAccountList]);
    }

    const removeGameAccount = async (gameaccount: any) => {
        const newActiveState = gameaccount.active === 1 ? 0 : 1;
        const game = gameaccount.id || newGame;

        GameAccountService.updateGameAccount(game, account.id, newActiveState, gameaccount.platforms);
        const index = gameAccountList.indexOf(gameaccount);
        gameAccountList[index].active = newActiveState;
        setGameAccountList([...gameAccountList]);
    }

    const saveChanges = async () => {
        if (creationMode) {
            const res = await GameAccountService.createAccount(
                account.id,
                account.title,
                account.color,
                account.active,
                account.atype,
                account.placeholder
            );
            if (!res) {
                toast.error('Failed to create account');
                return;
            }
            toast.success('Account created');
        } else if (!creationMode) {
            const res = await GameAccountService.updateAccount(
                account.id,
                account.title,
                account.color,
                account.active,
                account.atype,
                account.placeholder
            );
            if (!res) {
                toast.error('Failed to update account');
                return;
            }
            toast.success('Account updated');
        } else {
            return;
        }
        actionHook(true);
    }

    return (
        <div className="w-100 p-4">
            <div className="d-flex">
                <Button
                    variant="contained"
                    color="primary"
                    onClick={saveChanges}
                >
                    Save
                </Button>
            </div>
            <div className="d-flex mt-3 mb-3">
                <TextField
                    disabled={!creationMode}
                    label="ID"
                    name="id"
                    value={account.id}
                    className="w-50"
                    onChange={(event) => onAccountChange(event)}
                />
                <FormControlLabel
                    value="active"
                    control={
                        <Switch
                            color="primary"
                            checked={account.active === 1 ? true : false}
                            name="active"
                            onChange={(event: any) => {
                                onActiveChange(event, !account.active);
                            }}
                        />
                    }
                    label="Active"
                    labelPlacement="start"
                />
            </div>
            <div className="d-flex mb-3">
                <TextField
                    label="Title"
                    value={account.title}
                    className="w-50"
                    name="title"
                    onChange={(event) => onAccountChange(event)}
                />
                <TextField
                    label="Placeholder"
                    value={account.placeholder}
                    className="w-50"
                    name="placeholder"
                    onChange={(event) => onPlaceHolderChange(event)}
                />
                <Select
                    label="AType"
                    value={account.atype}
                    onChange={t => onATypeChange(t)}
                    className="w-50 ml-2"
                    name="atype"
                >
                    <MenuItem value={AccountType.EDITOR}>EDITOR</MenuItem>
                    <MenuItem value={AccountType.GAME}>GAME</MenuItem>
                </Select>
            </div>
            <div className="d-flex mb-3">
                <ColorPicker
                    className="w-25"
                    label="Color"
                    value={`${String(account.color)}`}
                    onChange={color => onColorChange(color)}
                />
            </div>
            {
                !creationMode &&
                <>
                    <h5>GameAccount Image</h5>
                    <hr />
                    <p className="color-grey">Recommended size : 980x752 - format : PNG - transparent background
                        necessary</p>
                    <MediaManager
                        id={account.id}
                        path={[ 'media' ]}
                        secondPath={[ 'gameaccount' ]}
                        slug={account.id}
                        mediaNames={[EGameAccountImage.GameAccountImage]}
                        customTitle="GameAccount Image"
                    />
                    <hr/>
                    <h5>Associed game</h5>
                    {gameAccountList.sort((a, b) => b.active - a.active).map((ga, index) => {
                        return (<div key={index}>
                            <span className={`${ga.active ? '' : 'line-throught'}`}>{ga.id}</span>
                            <React.Fragment>
                                <Button
                                    variant="text"
                                    color="primary"
                                    onClick={() => removeGameAccount(ga)}
                                >
                                    {ga.active === 0 && <Done/>}
                                    {ga.active === 1 && <Clear/>}
                                </Button>
                                <InputLabel id="platforms-select-label">Platforms</InputLabel>
                                <Select
                                    label="platforms"
                                    labelId='platroms-select-label'
                                    value={ga.platforms}
                                    onChange={e => handleSelectPlatform(e, ga)}
                                    className="w-50 ml-2"
                                    name="platforms"
                                    renderValue={(selected) => (selected as Array<string>).join(',')}
                                    multiple
                                >
                                    {
                                        platformList.map((p, index) => {
                                            return (<MenuItem key={index} value={p.id}>
                                                <Checkbox checked={ga.platforms.indexOf(p.id) > -1} />
                                                <ListItemText primary={p.id} />
                                            </MenuItem>)
                                        })
                                    }
                                </Select>
                            </React.Fragment>
                        </div>)
                    })}
                    <div className="row p-3">
                        <InputLabel id="games-select-label">Games</InputLabel>
                        <Select
                            disabled={creationMode}
                            label="Game"
                            labelId='games-select-label'
                            name="game"
                            className="col-4 my-2 px-1"
                            defaultValue={'default'}
                            onChange={e => handleSelectGame(e)}
                        >
                            {gameList.map((game: any, index: number) => {
                                return <MenuItem key={index} value={game.id} disabled={gameAccountList.some(ac => ac.id === game.id)}>{game.id}</MenuItem>;
                            })}
                        </Select>

                        <div className="col-2 mt-4 d-flex justify-content-center">
                            <React.Fragment>
                                <Button
                                    variant="text"
                                    color="primary"
                                    onClick={createGameAccount}
                                >
                                    <Add/>
                                </Button>
                            </React.Fragment>
                        </div>
                    </div>
                </>
            }
        </div>
    );
}
