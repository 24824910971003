import { PremiumStatus } from './User';

export enum RewardKind {
	CashKind = 'cash',
	CoinKind = 'coin',
	XPKind = 'xp',
	EloKind = 'elo',
	GiftKind = 'gift',
}

export interface Reward {
	kind: RewardKind;
	giftId?: string;
	value?: number;
	cur?: string;
	title?: string;
	desc?: string;
}

export enum TransactionKind {
	RewardCash = 'reward/cash',
	RewardElo = 'reward/elo',
	RewardXP = 'reward/xp',
	RewardCoin = 'reward/coin',
	RewardGift = 'reward/gift',
	WithdrawCash = 'withdraw/cash',
	WithdrawCoin = 'withdraw/coin',
	WithdrawGift = 'withdraw/gift',
	AdminCash = 'admin/cash',
	AdminCoin = 'admin/coin',
}

export enum TransactionStatus {
	Validating,
	Transiting,
	Delivered,
	Cancelled,
	Unprocessable,
}

export function TransactionStatusToString(status: TransactionStatus) {
    return ['validating', 'transiting', 'delivered', 'cancelled', 'unprocessable'][status];
}

export interface User {
	email?: string;
	name?: string;
	code?: string;
	firstname?: string;
	lastname?: string;
	phone?: string;
	birthday?: string;
	status: PremiumStatus;
	confirmation: boolean;
}

export interface PaypalUser {
	name: string;
	email: string;
	country: string;
}

export interface Transaction {
    owner: string;
    route: string;
	approuved: boolean;
	benefactor?: string;
	detail: Reward;
	kind: TransactionKind;
	date: number;
	status: TransactionStatus;
	reason?: string;
    /** type */
	t: string;
	user?: User;
	mpesaID?: string;
	paypalAuthCode: string;
	paypalUser?: PaypalUser;
}
