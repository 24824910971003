import { Dialog, DialogContent, DialogTitle, TextField, Button, DialogActions } from '@material-ui/core';
import React, { useState } from 'react';

interface CreateFolderFormProps {
    openDialog: boolean;
    setOpenDialog: (isOpen: boolean) => void;
    actionHook: (name: string) => void;
}

export const CreateFolderForm: React.FunctionComponent<CreateFolderFormProps> = (props: CreateFolderFormProps) => {
    const [ name, setName ] = useState<string>('');

    const onChangeName = (name: string) => {
        setName(name);
    }

    const close = () => {
        props.setOpenDialog(false);
    }

    const save = () => {
        close();
        setName('');
        props.actionHook(name);
    }

    return (
        <Dialog open={props.openDialog} onClose={() => close()} aria-labelledby="form-dialog-title">
            <div className="d-flex justify-content-between">
                <DialogTitle id="form-create-folder">Create Folder</DialogTitle>
            </div>
            <DialogContent className="d-flex">
                <TextField name="folder" label="Create Folder" value={name} onChange={(event) => onChangeName(event.target.value)} />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => close()}>Cancel</Button>
                <Button onClick={() => save()} color="primary">Create</Button>
            </DialogActions>
        </Dialog>
    );
};