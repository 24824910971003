import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import React from 'react';
import {TournamentPlayer} from 'src/models/TournamentMatch';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import StarsIcon from '@material-ui/icons/Stars';
import { Link } from 'react-router-dom';
import VisibilityIcon from '@material-ui/icons/Visibility';

interface TournamentPlayerInfosProps {
    player: TournamentPlayer;
}

export const TournamentPlayerInfos: React.FunctionComponent<TournamentPlayerInfosProps> = ({player}) => {

    return (
        <>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon/>}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography component="div" className="d-flex justify-content-center align-items-center">
                        <AccountCircleIcon fontSize="large"/>
                        <div className="d-flex flex-column">
                            <div className="mx-2">{player.name}</div>
                            <div className="mx-2">Account: {player.account}</div>
                        </div>
                        {player.captain ? <StarsIcon className="yellow-color"/> : ''}
                        <Link target="_blank" to={`/users/${player.userId}`} className="ml-2">
                            <VisibilityIcon className="primary-color"/>
                        </Link>
                    </Typography>
                </AccordionSummary>
                <AccordionDetails className="d-flex flex-column">
                    <div>ID: {player.userId}</div>
                    <div>Email: {player.email}</div>
                    <div>Status: {player.status}</div>
                    <div>Elo: {player.elo}</div>
                </AccordionDetails>
            </Accordion>
        </>
    );
};