import React, {useEffect, useState} from 'react';
import {TrainingService} from 'src/services/training.service';
import {Mode, TrainingModule} from 'src/models/TrainingModule';
import {Game} from 'src/models/Game';
import {GameService} from 'src/services/game.service';
import CircularProgress from '@material-ui/core/CircularProgress';
import {TrainingModuleCard} from 'src/atoms/TrainingModuleCard/TrainingModuleCard';

interface ProgramListProps {
    language: string;
    update: any;
}

export const ModuleList: React.FunctionComponent<ProgramListProps> = (props: ProgramListProps) => {
    const [isLoading, setIsLoading] = useState(true);
    const [moduleList, setModuleList] = useState<Array<TrainingModule>>([]);
    const [gameList, setGameList] = useState<Array<Game>>([]);

    useEffect(() => {
        if (gameList.length > 0) {
            updateList();
        } else {
            getModules();
            get360Games();
        }
        // eslint-disable-next-line
    }, [props.update]);

    const getModules = (limit = 50, page = 0) => {
        TrainingService.getModule(limit, page).then((data) => {
            data.docs.filter((module: TrainingModule) => module.mode !== Mode.CHALLENGE ? moduleList.push(module) : null);
            setModuleList(moduleList);
            if (data.total > (page + 1) * limit) {
                getModules(limit, page + 1);
            } else {
                setIsLoading(false);
            }
        });
    };

    const get360Games = () => {
        GameService.get360Games().then((data) => {
            setGameList(data);
        });
    };

    const updateList = () => {
        setIsLoading(true);
        setModuleList([]);
        getModules();
    };

    return (
        <>
            {
                isLoading && <CircularProgress className="mx-auto"/>
            }
            {
                !isLoading &&
                <div className="w-100 d-flex flex-column program-list">
                    {
                        gameList && gameList.map((game: Game, index: number) => {
                            return (
                                <div key={index}>
                                    <div className="select-image-game">
                                        <img alt="game"
                                             src={`${process.env.REACT_APP_S3_URL}/game/${game._id}/medias/SelectImage`}/>
                                    </div>
                                    <div className="d-flex flex-row list-container NC-info mb-3 mt-1">
                                        {
                                            moduleList && moduleList.map((module: TrainingModule, index: number) => {
                                                if (module.game === game._id) {
                                                    return <TrainingModuleCard module={module} updateHook={updateList}
                                                                               key={index} noProgram={true}/>;
                                                }
                                                return null;
                                            })
                                        }
                                    </div>
                                </div>
                            );
                        })
                    }
                </div>
            }
        </>
    );
};