import { MenuItem, Select } from '@material-ui/core';
import './UserAccount.scss';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import React, { useState } from 'react';
import { PremiumService } from 'src/services/premium.service';
import { PremiumDurationScope, PremiumStatus, UserStatus } from 'src/models/User';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import { toast } from 'react-toastify';
import { User } from '@wolfiesports/srm-component';

interface UserAccountProps {
    user: User;
    userChange: any;
}

export const UserAccount: React.FunctionComponent<UserAccountProps> = (props: UserAccountProps) => {
    const [editedUser] = useState<User>(props.user);
    const [selectedDurationScope, setSelectedDurationScope] = useState<PremiumDurationScope>(PremiumDurationScope.DAY);
    const [selectedDurationTime, setSelectedDurationTime] = useState<number>(0);
    const [selectedUserStatus, setSelectedUserStatus] = useState<UserStatus>(props.user.valid ? UserStatus.CONFIRMED : UserStatus.NONE);

    const handleDurationScope = (event: any) => {
        setSelectedDurationScope(event.target.value);
    };

    const handleUserStatus = (event: any) => {
        setSelectedUserStatus(event.target.value);
        editedUser.valid = event.target.value === UserStatus.CONFIRMED;
    };

    const handleDurationTime = (event: any) => {
        setSelectedDurationTime(event.target.value);
    };

    const saveUserInfos = () => {
        props.userChange(editedUser);
    };

    const addPremium = () => {
        PremiumService.addPremiumToUser(selectedDurationScope, selectedDurationTime, editedUser).then(data => {
            if (!data) {
                toast.error('Failed to update account');
                return;
            }
            toast.success('User account updated');
            props.userChange(editedUser);
        });
    };

    const renderSaveButton = () => {

        return (
            <React.Fragment>
                <Button
                    className="w-25"
                    variant="contained"
                    color="primary"
                    onClick={saveUserInfos}
                >
                    Save
                </Button>
            </React.Fragment>
        );
    };

    return (
        <div className="NC-card">
            <div className="p-3 row">
                <div className="row w-100">
                    <div className="col-md-6 col-12 my-2">
                        <FormControl className="w-100">
                            <InputLabel>User status</InputLabel>
                            <Select
                                value={selectedUserStatus}
                                onChange={handleUserStatus}
                            >
                                {
                                    Object.keys(UserStatus).map((key, index: number) => {
                                        return <MenuItem key={index} value={key}>{key}</MenuItem>;
                                    })
                                }
                            </Select>
                        </FormControl>
                    </div>
                    <div className="col-md-6 col-12 my-2">
                        <FormControl className="w-100" disabled>
                            <InputLabel>Subscriptions status</InputLabel>
                            <Select value={editedUser.premium.status} disabled>
                                {
                                    Object.keys(PremiumStatus).map((key, index: number) => {
                                        return <MenuItem key={index} value={key}>{key}</MenuItem>;
                                    })
                                }
                            </Select>
                        </FormControl>
                    </div>
                </div>
                <div className="col-md-12 col-12 m-4">
                    <div className="d-flex flex-column text-center">
                        <Typography>
                            Created at: {(new Date(editedUser.reg)).toLocaleDateString()}
                        </Typography>
                        <Typography className={editedUser.premium.until ? 'primary-color' : 'red'}>
                            This user is {editedUser.premium.until ? 'premium until: ' : 'not premium.'}
                            {editedUser.premium.until ? new Date(editedUser.premium.until).toLocaleDateString() : ''}
                        </Typography>
                        <div className="yellow-color">* These informations above are provided by the auth and may not
                            reflect the reality.
                        </div>
                    </div>
                </div>
                <div className="add-premium-background col-12 mt-4 pb-4 d-flex flex-column">
                    <Typography className="mx-md-2 my-2 font-weight-bold">Add premium to this user:</Typography>
                    <div className="row px-3">
                        <FormControl className="col-md-3 col-12 px-2 my-2">
                            <InputLabel id="demo-simple-select-label">Duration scope</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={selectedDurationScope}
                                onChange={handleDurationScope}
                            >
                                {
                                    Object.values(PremiumDurationScope).map((value, index: number) => {
                                        return <MenuItem key={index} value={value}>{value}</MenuItem>;
                                    })
                                }
                            </Select>
                        </FormControl>
                        <FormControl className="col-md-3 col-12 px-2 my-2">
                            <TextField
                                id="standard-password-input"
                                label="Duration"
                                type="number"
                                value={selectedDurationTime}
                                onChange={handleDurationTime}
                                autoComplete="current-password"
                            />
                        </FormControl>
                        <div className="col-md-3 col-12 px-2" />
                        <Button
                            className="col-md-3 col-12 px-2 my-2 float-right"
                            variant="contained"
                            color="primary"
                            onClick={addPremium}
                        >
                            Add Premium
                        </Button>
                    </div>
                </div>
                <div className="col-12 mt-4 d-flex justify-content-center">
                    {renderSaveButton()}
                </div>
            </div>
        </div>
    );
};