import React, {PropsWithChildren, useEffect, useState} from 'react';
import './UserMedia.scss';
import cc from 'classcat';
import {S3LoaderService} from 'src/services/s3Loader.service';

export interface UserMediaProps {
    className?: string;
    userId: string;
    mediaPath: string;
    alt?: string;
    isPrivate?: boolean;
    display?: 'all' | 'link' | 'image';
    onLoad?: () => any;
    children?: any
}

enum State {
    Loading,
    Success,
    Failure, alt
}

async function blobToBase64(blob: Blob) {
    // eslint-disable-next-line
    return new Promise<string | ArrayBuffer | null>((resolve, _) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.readAsDataURL(blob);
    });
}

export const UserMedia: React.FunctionComponent<UserMediaProps> = ({
                                                                       userId,
                                                                       mediaPath,
                                                                       alt,
                                                                       isPrivate,
                                                                       className,
                                                                       display = 'all',
                                                                       onLoad,
                                                                       children
                                                                   }) => {

    const [state, setState] = useState(State.Loading);
    const [href, setHref] = useState('');
    const [src, setSrc] = useState('');

    useEffect(() => {
        setState(State.Loading);

        if (isPrivate) {
            S3LoaderService.getPrivateUserMedia(userId, mediaPath)
                .then(blob => {
                    if (!blob) {
                        setState(State.Failure);
                        return;
                    }

                    if (display === 'all' || display === 'link') {
                        setHref(window.URL.createObjectURL(blob));
                    }
                    blobToBase64(blob).then(data => {
                        if (display === 'all' || display === 'image') {
                            setSrc((data || '').toString());
                        }
                        handleLoad();
                    });
                });
        } else {
            if (display === 'all' || display === 'link') {
                setHref(`${process.env.REACT_APP_S3_URL}/user/${userId}/medias/${mediaPath}`);
            }
            if (display === 'all' || display === 'image') {
                setSrc(`${process.env.REACT_APP_S3_URL}/user/${userId}/medias/${mediaPath}`);
            }
        }
        // eslint-disable-next-line
    }, [isPrivate, userId, mediaPath]);

    const handleError = () => {
        if (state !== State.Loading) {
            return;
        }

        setState(State.Failure);
    };

    const handleLoad = () => {
        if (state !== State.Loading) {
            return;
        }

        setState(State.Success);
        // eslint-disable-next-line
        onLoad?.();
    };

    let stateClass: string;
    switch (state) {
        case State.Loading:
            stateClass = 'loading';
            break;
        case State.Success:
            stateClass = 'success';
            break;
        case State.Failure:
        default:
            stateClass = 'failure';
            break;
    }

    const Link = ({children}: PropsWithChildren<any>) => {
        return <a href={href} target="_blank" rel="noopener noreferrer" className="user-media-link">{children}</a>;
    };

    const Image = () => {
        return (
            <img className={cc([stateClass, className?.split(' '), 'user-media-image'])}
                 src={src}
                 alt={alt || mediaPath}
                 onError={handleError}
                 onLoad={handleLoad}
            />
        );
    };

    switch (display) {
        case 'image':
            return <Image/>;
        case 'link':
            return (
                <Link>
                    {children}
                </Link>
            );
        case 'all':
        default:
            return (
                <Link>
                    <Image/>
                    {children}
                </Link>
            );
    }
};
