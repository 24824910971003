import React, {ChangeEvent, useEffect, useState} from 'react';
import {TrainingProgram} from 'src/models/TrainingProgram';
import {NCTitle} from 'src/atoms/NCTitle/NCTitle';
import {Editor} from '@tinymce/tinymce-react';
import {Button} from '@material-ui/core';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import TextField from '@material-ui/core/TextField';
import { TranslatedString } from 'src/models/TranslatedString';

interface ProgramHowItWorkProps {
    program: TrainingProgram;
    programChange: any;
    edit: boolean;
    lang: string;
}

export const ProgramHowItWork: React.FunctionComponent<ProgramHowItWorkProps> = ({
                                                                                     program,
                                                                                     programChange,
                                                                                     edit,
                                                                                     lang
                                                                                 }) => {
    const [onChanged, setOnChanged] = useState<boolean>(false);

    useEffect(() => {
        setOnChanged(false);
    }, [onChanged]);

    const onEditorChange = (content: any) => {
        program.description[lang] = content;
        programChange(program);
    };

    const addNewStep = () => {
        program.steps.push({en: ''});
        setOnChanged(true);
        programChange(program);
    };

    const deleteStep = (axe: any) => {
        const index = program.steps.indexOf(axe, 0);
        if (index > -1) {
            program.steps.splice(index, 1);
        }
        setOnChanged(true);
        programChange(program);
    };

    const onArrayTranslatedChange = (event: any, index: number) => {
        const {name, value} = event.currentTarget;
        const myLang = program[name as keyof TrainingProgram] as Array<TranslatedString>;
        myLang[index][lang] = value;
        programChange(program);
    };


    return (
        <div className="row px-0 px-sm-5 mt-3">

            <div className="col-12 mb-3 px-3">
                <NCTitle text={'How does it work'} color="white"/>
                <Editor apiKey={process.env.REACT_APP_TINYMCE_KEY}
                        init={{
                            height: 200,
                            a11y_advanced_options: true,
                            image_title: true,
                            automatic_uploads: true,
                            file_picker_types: 'image',
                            plugins: 'link lists media table image imagetools media',
                            toolbar: 'undo redo | styleselect fontselect fontsizeselect removeformat | bold italic | alignleft aligncenter alignright alignjustify | ' +
                                'bullist numlist outdent indent | image media link | spellchecker | forecolor backcolor',
                        }}
                        value={program.work[lang] || ''}
                        onEditorChange={(event: any) => (onEditorChange(event))}
                />
                {(program.work['en'] || program.work['fr']) &&
                <p>Helper
                    : {program.work['en'] ? (program.work['en'] !== undefined ? 'EN : "' + program.work['en'] + '"' : '') : (program.work['fr'] !== undefined ? 'FR : "' + program.work['fr'] + '"' : '')}</p>}
            </div>

            <div className="col-12 mb-3 px-3">
                <NCTitle text={'Objectives'} color="white"/>
                <Editor apiKey={process.env.REACT_APP_TINYMCE_KEY}
                        init={{
                            height: 200,
                            a11y_advanced_options: true,
                            image_title: true,
                            automatic_uploads: true,
                            file_picker_types: 'image',
                            plugins: 'link lists media table image imagetools media',
                            toolbar: 'undo redo | styleselect fontselect fontsizeselect removeformat | bold italic | alignleft aligncenter alignright alignjustify | ' +
                                'bullist numlist outdent indent | image media link | spellchecker | forecolor backcolor',
                        }}
                        value={program.objectif[lang] || ''}
                        onEditorChange={(event: any) => (onEditorChange(event))}
                />
                {(program.objectif['en'] || program.objectif['fr']) &&
                <p>Helper
                    : {program.objectif['en'] ? (program.objectif['en'] !== undefined ? 'EN : "' + program.objectif['en'] + '"' : '') : (program.objectif['fr'] !== undefined ? 'FR : "' + program.objectif['fr'] + '"' : '')}</p>}
            </div>

            <div className="col-12 mb-3 px-3">
                <NCTitle text={'Steps'} color="white"/>
                <Button variant="contained" className="my-2" color="primary" onClick={addNewStep}>Add answer</Button>
                {
                    program.steps && program.steps.map((step: any, index: number) => {
                        return (
                            <div className="col-12 mb-3 px-3 row" key={index}>
                                <div className="col-1 align-self-center">
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        className="mr-2 my-auto deleteAnswerButton"
                                        onClick={() => deleteStep(step)}
                                    ><DeleteForeverIcon/></Button>
                                </div>
                                <TextField
                                    disabled={!edit}
                                    name="steps"
                                    label="Step"
                                    className="col-11"
                                    defaultValue={step[lang] || ''}
                                    onChange={(event: ChangeEvent<HTMLInputElement>) => (onArrayTranslatedChange(event, index))}
                                    helperText={step['en'] ? (step['en'] !== undefined ? 'EN : "' + step['en'] + '"' : '') : (step['fr'] !== undefined ? 'FR : "' + step['fr'] + '"' : '')}
                                />
                            </div>
                        );
                    })
                }
            </div>
        </div>
    );


};