import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import React, {useState} from 'react';
import {HomepageGame, HomepageGameCount, HomepageGameLink} from 'src/models/HomepageGame';
import ControlPointIcon from '@material-ui/icons/ControlPoint';

interface HomepageGameInfoProps {
    homepage: HomepageGame;
    setHomepage: any;
    edit?: boolean;
    creationMode?: boolean;
}

export const HomepageGameInfo: React.FunctionComponent<HomepageGameInfoProps> = (props: HomepageGameInfoProps) => {
    const [count, setCount] = useState<HomepageGameCount>(props.homepage.count);
    const [link, setLink] = useState<HomepageGameLink>(props.homepage.link);
    const [target, setTarget] = useState<Array<string>>(props.homepage.target ? props.homepage.target : []);
    const [targetItem, setTargetItem] = useState<string>('');


    const onHomepageGameChange = (event: any, optionalArgument: any = undefined) => {
        if (optionalArgument !== undefined) {
            event.currentTarget.value = optionalArgument;
        }
        props.setHomepage(Object.assign({}, props.homepage, {
            [event.currentTarget.name]: event.currentTarget.value,
        }));
    };

    const onHomepageGameCountChange = (event: any) => {
        const {name, value} = event.currentTarget;
        setCount(Object.assign({}, count, {
            [name]: value,
        }));
        props.homepage.count = count;
        props.setHomepage(props.homepage);
    };

    const onHomepageGameLinkChange = (event: any) => {
        const {name, value} = event.currentTarget;
        setLink(Object.assign({}, link, {
            [name]: value,
        }));
        props.homepage.link = link;
        props.setHomepage(props.homepage);
    };

    const addTarget = () => {
        target.push(targetItem);
        setTarget(target);
        props.homepage.target = target;
        props.setHomepage(props.homepage);
        setTargetItem('');
    };

    const deleteTarget = (event: any, index: number) => {
        target.splice(index, 1);
        setTarget(target);
        event.currentTarget.remove();
        props.homepage.target = target;
        props.setHomepage(props.homepage);
    };

    return (
        <div className="w-100 p-4">
            <div className="d-flex mb-3">
                {
                    !props.creationMode &&
                    <TextField
                        disabled
                        label="ID"
                        name="id"
                        value={props.homepage.id}
                        className="w-50"
                    />
                }
                <div className="w-50 ml-3">
                    <FormControlLabel
                        value="start"
                        disabled={!props.edit && !props.creationMode}
                        control={
                            <Switch
                                color="primary"
                                checked={props.homepage.published}
                                name="published"
                                onChange={(event: any) => {
                                    onHomepageGameChange(event, !props.homepage.published);
                                }}
                            />
                        }
                        label="Published"
                        labelPlacement="start"
                    />
                </div>
            </div>
            <div className="d-flex mb-3">
                <TextField
                    disabled={!props.edit && !props.creationMode}
                    label="Name"
                    value={props.homepage.name}
                    className="w-50"
                    name="name"
                    onChange={(event: any) => {
                        onHomepageGameChange(event);
                    }}
                />
                <TextField
                    disabled={!props.edit && !props.creationMode}
                    label="Value"
                    value={props.homepage.value ? props.homepage.value : ''}
                    className="w-50"
                    name="value"
                    onChange={(event: any) => {
                        onHomepageGameChange(event);
                    }}
                />
            </div>
            <div className="mb-3">
                <h5>Target</h5>
                <div className="d-flex">
                    <div className="d-flex justify-content-center align-items-center">
                        <TextField
                            disabled={!props.edit && !props.creationMode}
                            label="Target"
                            value={targetItem}
                            name="target"
                            onChange={(event: any) => setTargetItem(event.currentTarget.value)}
                        />
                        <Button
                            disabled={!props.edit && !props.creationMode}
                            color="primary"
                            onClick={() => addTarget()}
                        >
                            <ControlPointIcon/>
                        </Button>
                    </div>

                    <div className="w-50 d-flex">
                        {
                            target && target.map((item, index) => {
                                return <Chip
                                    disabled={!props.edit && !props.creationMode}
                                    key={index}
                                    label={item}
                                    className="m-1"
                                    color="primary"
                                    clickable
                                    onDelete={(event: any) => deleteTarget(event, index)}
                                />;
                            })
                        }
                    </div>
                </div>
            </div>
            <div className="mb-3">
                <h5>Link</h5>
                <div className="d-flex">
                    <TextField
                        disabled={!props.edit && !props.creationMode}
                        label="Shop"
                        value={link.shop ? link.shop : ''}
                        className="w-25 m-1"
                        name="shop"
                        onChange={(event: any) => {
                            onHomepageGameLinkChange(event);
                        }}
                    />
                    <TextField
                        disabled={!props.edit && !props.creationMode}
                        label="Training"
                        value={link.training ? link.training : ''}
                        className="w-25 m-1"
                        name="training"
                        onChange={(event: any) => {
                            onHomepageGameLinkChange(event);
                        }}
                    />
                    <TextField
                        disabled={!props.edit && !props.creationMode}
                        label="Videos"
                        value={link.videos ? link.videos : ''}
                        className="w-25 m-1"
                        name="videos"
                        onChange={(event: any) => {
                            onHomepageGameLinkChange(event);
                        }}
                    />
                </div>
            </div>
            <div className="mb-3">
                <h5>Count</h5>
                <div className="d-flex">
                    <TextField
                        disabled={!props.edit && !props.creationMode}
                        label="Tournaments"
                        value={count.tournaments ? count.tournaments : 0}
                        className="w-25 m-1"
                        name="tournaments"
                        onChange={(event: any) => {
                            onHomepageGameCountChange(event);
                        }}
                    />
                    <TextField
                        disabled={!props.edit && !props.creationMode}
                        label="Videos"
                        value={count.videos ? count.videos : 0}
                        className="w-25 m-1"
                        name="videos"
                        onChange={(event: any) => {
                            onHomepageGameCountChange(event);
                        }}
                    />
                    <TextField
                        disabled={!props.edit && !props.creationMode}
                        label="Trainings"
                        value={count.trainings ? count.trainings : 0}
                        className="w-25 m-1"
                        name="trainings"
                        onChange={(event: any) => {
                            onHomepageGameCountChange(event);
                        }}
                    />
                </div>
            </div>
        </div>
    );
};