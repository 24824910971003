import CircularProgress from '@material-ui/core/CircularProgress';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import React, {useEffect, useState} from 'react';
import {toast} from 'react-toastify';
import {ActionDialog} from 'src/components/General/ActionDialog/ActionDialog';
import {FullScreenDialog} from 'src/components/General/FullScreenDialog/FullScreenDialog';
import {HomepageForm} from 'src/components/Homepage/HomepageForm/HomepageForm';
import {SimpleList} from 'src/components/General/SimpleList/SimpleList';
import {HomepageEvent} from 'src/models/HomepageEvent';
import {HomepageGame} from 'src/models/HomepageGame';
import {HomepageService} from 'src/services/homepage.service';
import {NCTitle} from 'src/atoms/NCTitle/NCTitle';

export const HomepageEvents: React.FunctionComponent = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [rows, setRows] = useState<any>([]);
    const [homepage, setHomepage] = useState<HomepageEvent | HomepageGame>();
    const [actualPage, setActualPage] = useState<number>(0);
    const [totalItem, setTotalItem] = useState<number>(0);
    const [HPtype, setHPType] = useState<boolean>(false);
    const [actionDelete, setActionDelete] = useState<boolean>(false);
    const [actionEdit, setActionEdit] = useState<boolean>(false);
    const [actionCreate, setActionCreate] = useState<boolean>(false);

    const columns = ['Name', 'Value', 'Link', 'Published'];
    const keepRows = ['name', 'value', 'link', 'published'];

    useEffect(() => {
        HomepageService.getHomepageEvents(20, actualPage).then((data) => {
            setTotalItem(Math.ceil(data.total / 20));
            setRows(data.docs);
            setIsLoading(false);
        }).catch(error => console.log(error));
    }, [totalItem, actualPage]);

    const switchHomePageType = () => {
        const bool = !HPtype;
        setIsLoading(true);
        setHPType(bool);
        if (bool) {
            HomepageService.getHomepageGames(20, actualPage).then((data) => {
                setTotalItem(Math.ceil(data.total / 20));
                setRows(data.docs);
                setIsLoading(false);

            }).catch(error => console.log(error));
        } else {
            HomepageService.getHomepageEvents(20, actualPage).then((data) => {
                setTotalItem(Math.ceil(data.total / 20));
                setRows(data.docs);
                setIsLoading(false);
            }).catch(error => console.log(error));
        }
    };

    const actionManager = (homepage: HomepageEvent | HomepageGame, kind: string) => {
        setHomepage(homepage);

        switch (kind) {
            case 'delete':
                setActionDelete(true);
                break;
            case 'edit':
                setActionEdit(true);
                break;
            case 'create': {
                if (!HPtype) {
                    setHomepage(new HomepageEvent(
                        '',
                        '',
                        '',
                        '',
                        false,
                        [],
                        new Date(),
                    ));
                } else {
                    setHomepage(new HomepageGame(
                        '',
                        '',
                        '',
                        {tournaments: 0, videos: 0, trainings: 0},
                        {shop: '', tournament: [], training: '', videos: ''},
                        false,
                        [],
                        new Date(),
                    ));
                }
                setActionCreate(true);
                break;
            }
            default:
                return;
        }
    };

    const deleteHomepage = (choice: boolean) => {
        if (!choice) {
            return;
        }
        if (!homepage) {
            toast.error('Error while deleting');
            return;
        }
        if (HPtype) {
            HomepageService.deleteHomePageGame(homepage?.id).then(() => {
                toast.success('Homepage deleted');
                updateList();
            });
        } else {
            HomepageService.deleteHomePageEvent(homepage?.id).then(() => {
                toast.success('Homepage deleted');
                updateList();
            });
        }
    };

    const updateList = () => {
        setActionEdit(false);
        setActionCreate(false);
        if (HPtype) {
            HomepageService.getHomepageGames(20, actualPage).then((data) => {
                setTotalItem(Math.ceil(data.total / 20));
                setRows(data.docs);
                setIsLoading(false);

            }).catch(error => console.log(error));
        } else {
            HomepageService.getHomepageEvents(20, actualPage).then((data) => {
                setTotalItem(Math.ceil(data.total / 20));
                setRows(data.docs);
                setIsLoading(false);
            }).catch(error => console.log(error));
        }
    };

    return (
        <div className="NC-container NC-card">
            <div className="d-flex w-100">
                <NCTitle text={`Homepage ${HPtype ? 'Game' : 'Event'}`}/>
                <FormControlLabel
                    control={
                        <Switch
                            checked={HPtype}
                            onChange={() => switchHomePageType()}
                            name="Type"
                            color="primary"
                        />
                    }
                    label="Game"
                    className="ml-5"
                />
            </div>
            <div className="d-flex">
                {
                    isLoading && <CircularProgress className="mx-auto"/>
                }
                {
                    !isLoading &&
                    <SimpleList
                        rows={rows}
                        columns={columns}
                        keepRows={keepRows}
                        handleClickOpen={actionManager}
                        actionsButtons={{create: true, delete: true, edit: true}}
                        pagination={{actual: actualPage, total: totalItem, setActual: setActualPage}}
                    />
                }
            </div>
            <ActionDialog
                open={actionDelete}
                title={`Are you sure to delete this game : ${homepage?.name}`}
                openHook={setActionDelete}
                actionHook={deleteHomepage}
            />
            {
                homepage &&
                <FullScreenDialog
                    open={actionEdit}
                    openHook={setActionEdit}
                    actionHook={updateList}
                    componentToDisplay={
                        <HomepageForm
                            homepage={homepage}
                            actionHook={updateList}
                            type={HPtype}
                        />
                    }
                />
            }
            {
                homepage &&
                <FullScreenDialog
                    open={actionCreate}
                    openHook={setActionCreate}
                    actionHook={updateList}
                    componentToDisplay={
                        <HomepageForm
                            homepage={homepage}
                            actionHook={updateList}
                            creationMode={true}
                            type={HPtype}
                        />
                    }
                />
            }
        </div>
    );
};