import {Button, Link, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import React, {useEffect, useState} from 'react';
import {NCTitle, NCTitleType} from 'src/atoms/NCTitle/NCTitle';
import {SearchBar} from 'src/components/General/SearchBar/SearchBar';
import {Tournament} from 'src/models/Tournament';
import {NtService} from 'src/services/nt.service';
import VisibilityIcon from '@material-ui/icons/Visibility';
import moment from 'moment';
import {DynamoPagination} from 'src/atoms/DynamoPagination/DynamoPagination';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import {ActionDialog} from '../../../components/General/ActionDialog/ActionDialog';
import {toast} from 'react-toastify';

export const TournamentListFull: React.FunctionComponent = () => {
    const [searchResult, setSearchResult] = useState<any>();
    const [tournaments, setTournaments] = useState<Array<Tournament>>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [fromDate, setFromDate] = useState<string>();
    const [toDate, setToDate] = useState<string>();
    const [openDeleteMatchDialog, setOpenDeleteMatchDialog] = useState<boolean>(false);

    const [lastKeys, setLastKeys] = useState<Array<any>>([]);
    const [page, setPage] = useState<number>(0);
    const [isLastPage, setIsLastPage] = useState<boolean>(true);
    const [selectedTournament, setSelectedTournament] = useState<string>();

    const searchFields: { [key: string]: { label: string } } = {
        search: {label: 'Name'},
        partner: {label: 'Partner'},
    };

    useEffect(() => {
        let query = '';
        setIsLoading(true);
        if (searchResult) {
            Object.keys(searchResult).forEach((item) => {
                query = query.concat(query, `&${item}=${searchResult[item]}`);
            });
            setIsLoading(true);
        }

        if (fromDate && toDate) {
            query = query.concat(query, `&start=${fromDate}&end=${toDate}`);
        }
        if (lastKeys.length > 0) {
            if (page > 0 && lastKeys[page - 1]) {
                query = query.concat(query, `&id=${lastKeys[page - 1].id}&lastDate=${lastKeys[page - 1].date}`);
            }
        }

        NtService.getTournamentList(20, '', query).then((data: any) => {
            setTournaments(data.docs);

            if (data.last) {
                lastKeys[page] = data.last;
                setLastKeys(lastKeys);
                setIsLastPage(false);
            } else {
                setIsLastPage(true);
            }
        });

        setIsLoading(false);
    }, [searchResult, toDate, page, lastKeys, fromDate]);

    const onToDateChange = (event: any) => {
        setToDate(toTimestamp(event.target.value));
    };

    const onFromDateChange = (event: any) => {
        setFromDate(toTimestamp(event.target.value));
    };

    const toTimestamp = (strDate: any) => {
        return (Date.parse(strDate) / 1000).toString();
    };

    const openActionDialog = (tournamentId: string) => {
        setSelectedTournament(tournamentId);
        setOpenDeleteMatchDialog(true);
    };

    const deleteTournamentMatches = (choice: boolean) => {
        if (!choice || !selectedTournament) {
            return;
        }
        NtService.deleteRequestAndMatchesFromTournament(selectedTournament).then(data => {
            if (!data) {
                toast.error('Error');
                return;
            }
            toast.success('Success');
        });
    };

    return (
        <div className="NC-container NC-card d-flex flex-column">
            <NCTitle text={'Tournament List'} type={NCTitleType.TOURNAMENT}/>
            <div className="d-flex">
                <div className="w-75">
                    <SearchBar setSearchResult={setSearchResult} searchFields={searchFields}/>
                </div>
                <div className="w-75 d-flex justify-content-center align-items-center">
                    <TextField
                        id="datetime-local"
                        label="From"
                        type="datetime-local"
                        defaultValue={fromDate}
                        className="mx-2"
                        onChange={(event: any) => {
                            onFromDateChange(event);
                        }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    <TextField
                        id="datetime-local"
                        label="To"
                        type="datetime-local"
                        defaultValue={toDate}
                        className="mx-2"
                        onChange={(event: any) => {
                            onToDateChange(event);
                        }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </div>
            </div>
            <div className="d-flex">
                {
                    isLoading && <CircularProgress className="mx-auto"/>
                }
                {
                    !isLoading && tournaments.length > 0 &&
                    <div className="w-100 d-flex flex-column">
                        <DynamoPagination
                            page={page}
                            setPage={setPage}
                            itemPerPage={20}
                            displayNext={isLastPage}
                        />
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="left">Name</TableCell>
                                        <TableCell align="center">partner</TableCell>
                                        <TableCell align="center">ID</TableCell>
                                        <TableCell align="center">G4G ID</TableCell>
                                        <TableCell align="center">Date</TableCell>
                                        <TableCell align="center">Go to Matches</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        tournaments.map((t: Tournament) => {
                                            return (
                                                <TableRow key={t.id}>
                                                    <TableCell align="left">{t.name}</TableCell>
                                                    <TableCell align="center">{t.partner}</TableCell>
                                                    <TableCell align="center">{t.id}</TableCell>
                                                    <TableCell align="center">{t.g4gId}</TableCell>
                                                    <TableCell
                                                        align="center">{moment(t.date * 1000).format('LLLL').toString()}</TableCell>
                                                    <TableCell align="center"
                                                               className="d-flex justify-content-between">
                                                        <Link href={`/tournaments/${t.id}/matchs/list`}>
                                                            <Button
                                                                variant="text"
                                                                color="primary"
                                                            >
                                                                <VisibilityIcon className="primary-color"/>
                                                            </Button>
                                                        </Link>
                                                        <Button
                                                            variant="text"
                                                            color="secondary"
                                                            onClick={() => {
                                                                openActionDialog(t.id);
                                                            }}
                                                        >
                                                            <DeleteForeverIcon/>
                                                        </Button>
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                }
            </div>
            <ActionDialog
                open={openDeleteMatchDialog}
                title={'Are you sure to delete all matches and requests from this tournament ?'}
                openHook={setOpenDeleteMatchDialog}
                actionHook={deleteTournamentMatches}
            />
        </div>
    );
};