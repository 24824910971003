import axios, { AxiosError } from 'axios';
import { toast } from 'react-toastify';
import { SkillGame, SkillGameBlock } from 'src/models/SkillGame';
import { PredicateEditionAugmented } from 'src/models/TrainingBrick';
import { TrainingBroadcast } from 'src/models/TrainingBroadcast';
import { TrainingGuide } from 'src/models/TrainingGuide';
import { TrainingModule } from 'src/models/TrainingModule';
import { TrainingPreset } from 'src/models/TrainingPreset';
import { TrainingProgram } from 'src/models/TrainingProgram';
import { TrainingQuestion, TrainingQuiz } from 'src/models/TrainingQuiz';
import { TrainingThematic } from 'src/models/TrainingThematic';

export class TrainingService {
    private static baseUrl: string = String(process.env.REACT_APP_TRAINING_URL);
    static token = (): string | null => localStorage.getItem('accessToken');

    static async getPresets(limit: number = 20, page: number = 0, query: string = '') {
        let res;
        try {
            res = (await axios.get(`${TrainingService.baseUrl}/preset?limit=${limit}&page=${page}${query}`, {
                headers: {
                    'x-access-token': TrainingService.token(),
                }
            }));
        } catch (e) {
            throw (e as AxiosError).response;
        }

        if (!res.data && !res.data.docs) {
            throw Error;
        }

        let presetsList = [];
        presetsList = res.data.docs.map((item: TrainingPreset) => {
            const model = new TrainingPreset(
                item.name,
                item.path,
                item.game,
                item.kind,
                item.thematic,
                item.showInGraph,
            );
            model._id = item._id
            return model;
        });

        return {
            docs: presetsList,
            page: res.data.page,
            pages: res.data.pages,
            total: res.data.total,
        };
    }

    static async getPresetsCount(query: string = ''): Promise<any> {
        let res: number;

        try {
            res = (await axios.get(
                `${TrainingService.baseUrl}/preset/count${query}`, {
                    headers: {
                        'x-access-token': TrainingService.token(),
                    }
                }
            )).data;
        } catch {
            throw Error;
        }

        return res;
    }

    static async createPreset(preset: TrainingPreset): Promise<TrainingPreset> {
        if (!preset) {
            throw Error;
        }
        let newPreset: TrainingPreset;
        try {
            newPreset = (await axios.post(`${TrainingService.baseUrl}/preset`, preset,
                {
                    headers: {
                        'x-access-token': TrainingService.token(),
                    }
                })).data;
        } catch (e: any) {
            if (e.response.status === 409) {
                toast.error('Preset path already exist and must be unique');
            } else {
                toast.error('Fail to create preset');
            }
            throw (e as AxiosError).response;
        }

        toast.success('Preset created');
        return newPreset
    }

    static async updatePreset(preset: TrainingPreset): Promise<boolean> {
        if (!preset) {
            return false;
        }

        try {
            await axios.put(
                `${TrainingService.baseUrl}/preset/${preset._id}`,
                preset,
                {
                    headers: {
                        'x-access-token': TrainingService.token(),
                    }
                }
            );
        } catch (e) {
            toast.error('Fail to update preset, try again later or contact #public-backoffice');
            return false;
        }

        toast.success('Preset updated');
        return true;
    }

    static async deletePreset(id: string): Promise<boolean> {
        if (!id) {
            return false;
        }

        try {
            await axios.delete(
                `${TrainingService.baseUrl}/preset/${id}`,
                {
                    headers: {
                        'x-access-token': TrainingService.token(),
                    }
                }
            );
        } catch {
            toast.error('Fail to delete preset, try again later or contact #public-backoffice');
            return false;
        }

        toast.success('Preset deleted');
        return true;
    }


    static async getThematics(limit: number = 20, page: number = 0) {
        let res;

        try {
            res = (await axios.get(`${TrainingService.baseUrl}/thematic?limit=${limit}&page=${page}`));
        } catch (e) {
            throw (e as AxiosError).response;
        }
        if (!res.data && !res.data.docs) {
            throw Error;
        }

        let presetsList = [];
        presetsList = res.data.docs.map((item: TrainingThematic) => {
            const model = new TrainingThematic(
                item.name,
                item.gameIds,
                item.value,
                item.color,
                item.visible,
            );
            model._id = item._id
            return model;
        });

        return {
            docs: presetsList,
            page: res.data.page,
            total: res.data.total,
        };
    }

    static async getThematicCount(query: string = ''): Promise<any> {
        let res: number;

        try {
            res = (await axios.get(
                `${TrainingService.baseUrl}/thematic/count${query}`,
            )).data;
        } catch {
            throw Error;
        }

        return res;
    }

    static async createThematic(thematic: TrainingThematic): Promise<TrainingThematic> {
        if (!thematic) {
            throw Error;
        }
        let newThematic: TrainingThematic;

        try {
            newThematic = (await axios.post(`${TrainingService.baseUrl}/thematic`, thematic,
                {
                    headers: {
                        'x-access-token': TrainingService.token(),
                    }
                })).data;
        } catch (e) {
            toast.error('Fail to create thematic, try again later or contact #public-backoffice');
            throw (e as AxiosError).response;
        }

        toast.success('Thematic created');
        return newThematic
    }

    static async updateThematic(thematic: TrainingThematic): Promise<boolean> {
        if (!thematic) {
            return false;
        }
        try {
            await axios.put(
                `${TrainingService.baseUrl}/thematic/${thematic._id}`,
                thematic,
                {
                    headers: {
                        'x-access-token': TrainingService.token(),
                    }
                }
            );
        } catch (e) {
            toast.error('Fail to update thematic, try again later or contact #public-backoffice');
            return false;
        }

        toast.success('Thematic updated');
        return true;
    }

    static async deleteThematic(id: string): Promise<boolean> {
        if (!id) {
            return false;
        }

        try {
            await axios.delete(
                `${TrainingService.baseUrl}/thematic/${id}`,
                {
                    headers: {
                        'x-access-token': TrainingService.token(),
                    }
                }
            );
        } catch {
            toast.error('Fail to delete thematic, try again later or contact #public-backoffice');
            return false;
        }

        toast.success('Thematic deleted');
        return true;
    }


    static async getQuiz(limit: number = 20, page: number = 0, query: string = ''): Promise<any> {
        let res;

        try {
            res = (await axios.get(
                `${TrainingService.baseUrl}/quiz?limit=${limit}&page=${page}${query}`, {
                    headers: {
                        'x-access-token': TrainingService.token(),
                    }
                }
            )).data.data;
        } catch (e) {
            throw (e as AxiosError).response;
        }

        const quizList = res.docs.map((quiz: TrainingQuiz) => {
            return new TrainingQuiz(
                quiz._id,
                quiz.published,
                quiz.questions,
                quiz.name,
                quiz.game
            );
        });

        return {
            docs: quizList,
            page: res.page,
            total: res.total,
        };
    }


    static async getQuizCount(query: string = ''): Promise<any> {
        let res: number;

        try {
            res = (await axios.get(
                `${TrainingService.baseUrl}/quiz/count${query}`, {
                    headers: {
                        'x-access-token': TrainingService.token(),
                    }
                }
            )).data;
        } catch {
            throw Error;
        }

        return res;
    }

    static async deleteQuiz(quizId: string): Promise<boolean> {
        if (!quizId) {
            return false;
        }

        try {
            await axios.delete(
                `${TrainingService.baseUrl}/quiz/${quizId}`,
                {
                    headers: {
                        'x-access-token': TrainingService.token(),
                    }
                }
            );
        } catch {
            toast.error('Fail to delete quiz, try again later or contact #public-backoffice');
            return false;
        }

        toast.success('Quiz deleted');
        return true;
    }

    static async updateQuiz(quizId: string, body: any): Promise<boolean> {
        if (!quizId && !body) {
            return false;
        }
        delete body._id;

        try {
            await axios.put(
                `${TrainingService.baseUrl}/quiz/${quizId}`,
                body,
                {
                    headers: {
                        'x-access-token': TrainingService.token(),
                    }
                }
            );
        } catch {
            toast.error('Fail to update quiz, try again later or contact #public-backoffice');
            return false;
        }

        toast.success('Quiz updated');
        return true;
    }

    static async createQuiz(body: TrainingQuiz): Promise<TrainingQuiz> {
        if (!body) {
            throw Error;
        }

        delete body['_id' as keyof TrainingQuiz];
        let newQuiz: TrainingQuiz;

        try {
            newQuiz = (await axios.post(
                `${TrainingService.baseUrl}/quiz`,
                body,
                {
                    headers: {
                        'x-access-token': TrainingService.token(),
                    }
                }
            )).data.data;
        } catch (e) {
            toast.error('Fail to create quiz, try again later or contact #public-backoffice');
            throw (e as AxiosError).response;
        }

        toast.success('Quiz created');
        return newQuiz;
    }

    static async createQuizQuestion(quizzId: string, body: TrainingQuestion): Promise<TrainingQuestion> {
        if (!body) {
            throw Error;
        }
        delete body['_id' as keyof TrainingQuestion];
        let newQuestion: TrainingQuestion;

        try {
            newQuestion = (await axios.post(
                `${TrainingService.baseUrl}/quiz/${quizzId}/question`,
                body,
                {
                    headers: {
                        'x-access-token': TrainingService.token(),
                    }
                }
            )).data.data;
        } catch (e) {
            throw (e as AxiosError).response;
        }

        return newQuestion;
    }


    static async getBroadcast(limit: number = 20, page: number = 0, query: any = ''): Promise<any> {
        let res;

        try {
            res = (await axios.get(
                `${TrainingService.baseUrl}/broadcast?limit=${limit}&page=${page}${query}`, {
                    headers: {
                        'x-access-token': TrainingService.token(),
                    }
                }
            )).data.data
        } catch (e) {
            throw (e as AxiosError).response;
        }

        return {
            docs: res.docs,
            page: res.page,
            total: res.total,
        };
    }

    static async getBroadcastCount(query: string = ''): Promise<any> {
        let res: number;

        try {
            res = (await axios.get(
                `${TrainingService.baseUrl}/broadcast/count${query}`, {
                    headers: {
                        'x-access-token': TrainingService.token(),
                    }
                }
            )).data;
        } catch {
            throw Error;
        }

        return res;
    }

    static async createBroadcast(body: TrainingBroadcast): Promise<TrainingBroadcast> {
        if (!body) {
            throw Error;
        }
        let newBroadcast: TrainingBroadcast;
        delete body['_id' as keyof TrainingBroadcast];

        try {
            newBroadcast = (await axios.post(
                `${TrainingService.baseUrl}/broadcast`,
                body,
                {
                    headers: {
                        'x-access-token': TrainingService.token(),
                    },
                },
            )).data.data;
        } catch (e) {
            toast.error('Fail to create broadcast, try again later or contact #public-backoffice');
            throw (e as AxiosError).response;
        }

        toast.success('Broadcast created');
        return newBroadcast;
    }

    static async updateBroadcast(_id: string, body: TrainingBroadcast): Promise<boolean> {
        if (!_id && !body) {
            return false;
        }
        delete body['_id' as keyof TrainingBroadcast];
        try {
            await axios.put(
                `${TrainingService.baseUrl}/broadcast/${_id}`,
                body,
                {
                    headers: {
                        'x-access-token': TrainingService.token(),
                    },
                },
            );
        } catch {
            toast.error('Fail to update broadcast, try again later or contact #public-backoffice');
            return false;
        }

        toast.success('Broadcast updated');
        return true;
    }

    static async deleteBroadcast(_id: string): Promise<boolean> {
        if (!_id) {
            return false;
        }


        try {
            await axios.delete(
                `${TrainingService.baseUrl}/broadcast/${_id}`,
                {
                    headers: {
                        'x-access-token': TrainingService.token(),
                    },
                },
            );
        } catch {
            toast.error('Fail to delete broadcast, try again later or contact #public-backoffice');
            return false;
        }

        toast.success('Broadcast deleted');
        return true;
    }

    static async getSkillGames(limit: number = 20, page: number = 0, query: string = ''): Promise<any> {
        let res;

        try {
            res = (await axios.get(
                `${TrainingService.baseUrl}/game?limit=${limit}&page=${page}${query}`, {
                    headers: {
                        'x-access-token': TrainingService.token(),
                    }
                }
            )).data.data;
        } catch {
            throw Error;
        }

        return {
            docs: res.docs,
            total: res.total,
            page: res.page,
        }
    }

    static async createSkillGame(body: SkillGame): Promise<SkillGame> {
        if (!body) {
            throw Error;
        }

        delete body['_id' as keyof SkillGame];
        let res: SkillGame;
        try {
            res = (await axios.post(
                `${TrainingService.baseUrl}/game`,
                body,
                {
                    headers: {
                        'x-access-token': TrainingService.token(),
                    },
                }
            )).data.data;
        } catch {
            throw Error;
        }

        return res;
    }

    static async updateSkillGame(_id: string, body: SkillGame): Promise<boolean> {
        if (!_id && !body) {
            return false;
        }
        try {
            await axios.put(
                `${TrainingService.baseUrl}/game/${_id}`,
                body,
                {
                    headers: {
                        'x-access-token': TrainingService.token(),
                    },
                }
            );
        } catch {
            return false;
        }


        return true;
    }

    static async deleteSkillGame(skillGameId: string): Promise<boolean> {
        if (!skillGameId) {
            return false;
        }

        try {
            await axios.delete(
                `${TrainingService.baseUrl}/game/${skillGameId}`,
                {
                    headers: {
                        'x-access-token': TrainingService.token(),
                    },
                },
            );
        } catch {
            return false;
        }

        return true;
    }

    static async getSkillGamesBlock(limit: number = 20, page: number = 0, query: string = ''): Promise<any> {
        let res;

        try {
            res = (await axios.get(
                `${TrainingService.baseUrl}/game-block?limit=${limit}&page=${page}${query}`, {
                    headers: {
                        'x-access-token': TrainingService.token(),
                    }
                }
            )).data.data;
        } catch {
            throw Error;
        }

        return {
            docs: res.docs,
            total: res.total,
            page: res.page,
        }
    }

    static async createSkillGameBlock(body: SkillGameBlock): Promise<SkillGameBlock> {
        if (!body) {
            throw Error;
        }

        delete body['_id' as keyof SkillGameBlock];
        let res: SkillGameBlock;
        try {
            res = (await axios.post(
                `${TrainingService.baseUrl}/game-block`,
                body,
                {
                    headers: {
                        'x-access-token': TrainingService.token(),
                    },
                }
            )).data.data;
        } catch {
            throw Error;
        }

        return res;
    }

    static async updateSkillGameBlock(_id: string, body: SkillGameBlock): Promise<boolean> {
        if (!_id && !body) {
            return false;
        }

        try {
            await axios.put(
                `${TrainingService.baseUrl}/game-block/${_id}`,
                body,
                {
                    headers: {
                        'x-access-token': TrainingService.token(),
                    },
                }
            );
        } catch {
            return false;
        }


        return true;
    }

    static async deleteSkillGameBlock(_id: string): Promise<boolean> {
        if (!_id) {
            return false;
        }

        try {
            await axios.delete(
                `${TrainingService.baseUrl}/game-block/${_id}`,
                {
                    headers: {
                        'x-access-token': TrainingService.token(),
                    },
                },
            );
        } catch {
            return false;
        }

        return true;
    }

    static async getPrograms(limit: number = 20, page: number = 0, query: any = ''): Promise<any> {
        let res;

        try {
            res = (await axios.get(
                `${TrainingService.baseUrl}/program?limit=${limit}&page=${page}${query}`,
                {
                    headers: {
                        'x-access-token': TrainingService.token(),
                    }
                }
            )).data
        } catch (e) {
            throw (e as AxiosError).response;
        }
        return {
            docs: res.docs,
            page: res.page,
            total: res.total,
        };
    }

    static async getProgramById(programId: string): Promise<any> {
        let res;

        try {
            res = (await axios.get(
                `${TrainingService.baseUrl}/program/${programId}`,
                {
                    headers: {
                        'x-access-token': TrainingService.token(),
                    }
                }
            )).data
        } catch (e) {
            throw (e as AxiosError).response;
        }

        return res;
    }

    static async updateProgram(_id: string, body: TrainingProgram): Promise<boolean> {
        if (!_id && !body) {
            return false;
        }

        delete body['_id' as keyof TrainingProgram];

        try {
            await axios.put(
                `${TrainingService.baseUrl}/program/${_id}`,
                body,
                {
                    headers: {
                        'x-access-token': TrainingService.token(),
                    },
                },
            );
        } catch {
            toast.error('Fail to update program, try again later or contact #public-backoffice');
            return false;
        }

        toast.success('Program updated');
        return true;
    }

    static async createProgram(body: TrainingProgram): Promise<TrainingProgram> {
        if (!body) {
            throw Error;
        }
        let newProgram: TrainingProgram;

        delete body['_id' as keyof TrainingProgram];

        try {
            newProgram = (await axios.post(
                `${TrainingService.baseUrl}/program`,
                body,
                {
                    headers: {
                        'x-access-token': TrainingService.token(),
                    },
                },
            )).data.data;
        } catch (e) {
            toast.error('Fail to create program, try again later or contact #public-backoffice');
            throw (e as AxiosError).response;
        }

        toast.success('Program created');
        return newProgram;
    }

    static async deleteProgram(_id: string): Promise<boolean> {
        if (!_id) {
            return false;
        }

        try {
            await axios.delete(
                `${TrainingService.baseUrl}/program/${_id}`,
                {
                    headers: {
                        'x-access-token': TrainingService.token(),
                    },
                },
            );
        } catch {
            toast.error('Fail to delete program, try again later or contact #public-backoffice');
            return false;
        }

        toast.success('Program deleted');
        return true;
    }

    static async getModule(limit: number = 20, page: number = 0, query: any = ''): Promise<any> {
        let res;

        try {
            res = (await axios.get(
                `${TrainingService.baseUrl}/module?limit=${limit}&page=${page}${query}`,
                {
                    headers: {
                        'x-access-token': TrainingService.token(),
                    }
                }
            )).data
        } catch (e) {
            throw (e as AxiosError).response;
        }
        return {
            docs: res.docs,
            page: res.page,
            total: res.total,
        };
    }

    static async getModuleById(moduleId: string): Promise<any> {
        let res;

        try {
            res = (await axios.get(
                `${TrainingService.baseUrl}/module/${moduleId}`,
                {
                    headers: {
                        'x-access-token': TrainingService.token(),
                    }
                }
            )).data
        } catch (e) {
            throw (e as AxiosError).response;
        }

        return res;
    }

    static async updateModule(_id: string, body: TrainingModule): Promise<boolean> {
        if (!_id && !body) {
            return false;
        }


        try {
            await axios.put(
                `${TrainingService.baseUrl}/module/${_id}`,
                body,
                {
                    headers: {
                        'x-access-token': TrainingService.token(),
                    },
                },
            );
        } catch {
            toast.error('Fail to update module, try again later or contact #public-backoffice');
            return false;
        }

        toast.success('Module updated');
        return true;
    }

    static async createModule(body: TrainingModule): Promise<TrainingModule> {
        if (!body) {
            throw Error;
        }
        let newModule: TrainingModule;

        delete body['_id' as keyof TrainingModule];

        try {
            newModule = (await axios.post(
                `${TrainingService.baseUrl}/module`,
                body,
                {
                    headers: {
                        'x-access-token': TrainingService.token(),
                    },
                },
            )).data.data;
        } catch (e) {
            toast.error('Fail to create module, try again later or contact #public-backoffice');
            throw (e as AxiosError).response;
        }

        toast.success('Module created');
        return newModule;
    }

    static async deleteModule(_id: string): Promise<boolean> {
        if (!_id) {
            return false;
        }

        try {
            await axios.delete(
                `${TrainingService.baseUrl}/module/${_id}`,
                {
                    headers: {
                        'x-access-token': TrainingService.token(),
                    },
                },
            );
        } catch {
            toast.error('Fail to delete module, try again later or contact #public-backoffice');
            return false;
        }

        toast.success('Module deleted');
        return true;
    }

    static async getTrainingGuides(limit: number = 20, page: number = 0, query: string = ''): Promise<any> {
        let res;

        try {
            res = (await axios.get(
                `${TrainingService.baseUrl}/guide?limit=${limit}&page=${page}${query}`, {
                    headers: {
                        'x-access-token': TrainingService.token(),
                    }
                }
            )).data;
        } catch {
            throw Error;
        }

        return {
            docs: res.docs,
            page: res.page,
            total: res.total,
        }
    }

    static async createTrainingGuide(body: TrainingGuide): Promise<TrainingGuide> {
        if (!body) {
            throw Error;
        }
        let newGuide: TrainingGuide;
        delete body['_id' as keyof TrainingGuide];
        try {
            newGuide = (await axios.post(
                `${TrainingService.baseUrl}/guide`,
                body,
                {
                    headers: {
                        'x-access-token': TrainingService.token(),
                    }
                }
            )).data;
        } catch {
            throw Error;
        }

        return newGuide;
    }

    static async updateTrainingGuide(_id: string, body: TrainingGuide): Promise<boolean> {
        if (!_id && !body) {
            return false;
        }
        delete body['_id' as keyof TrainingGuide];

        try {
            await axios.put(
                `${TrainingService.baseUrl}/guide/${_id}`,
                body,
                {
                    headers: {
                        'x-access-token': TrainingService.token(),
                    }
                }
            );
        } catch {
            return false;
        }

        return true;
    }

    static async deleteTrainingGuide(_id: string): Promise<boolean> {
        if (!_id) {
            return false;
        }
        try {
            await axios.delete(
                `${TrainingService.baseUrl}/guide/${_id}`,
                {
                    headers: {
                        'x-access-token': TrainingService.token(),
                    }
                }
            );
        } catch {
            return false;
        }

        return true;
    }

    static async createPrimaryBlock(module: string, body: any): Promise<any> {
        if (!module && !body) {
            return;
        }

        const token = localStorage.getItem('accessToken');

        try {
            const data = await axios.post(
                `${TrainingService.baseUrl}/block/${module}/primary`,
                body,
                {
                    headers: {
                        'x-access-token': token,
                    },
                },
            );
            return data.data.data;
        } catch {
            toast.error('Fail to create primary block, try again later or contact #public-backoffice');
            throw Error;
        }
    }

    static async updateBlock(primaryId: string, body: any): Promise<boolean> {
        if (!primaryId && !body) {
            return false;
        }

        delete body._id;

        try {
            await axios.post(
                `${TrainingService.baseUrl}/block/${primaryId}/element`,
                body,
                {
                    headers: {
                        'x-access-token': TrainingService.token(),
                    },
                },
            );
        } catch {
            toast.error('Fail to update module, try again later or contact #public-backoffice');
            return false;
        }

        toast.success('Module updated');
        return true;
    }

    static comparePredicate(predicateA: PredicateEditionAugmented, predicateB: PredicateEditionAugmented): boolean {
        return predicateA.description.en === predicateB.description.en &&
            predicateA.operator === predicateB.operator &&
            predicateA.path === predicateB.path &&
            predicateA.value === predicateB.value;
    }

    static parsePath = (path: string) => {
        const parsedPath = path.split('$$');
        return {metric: parsedPath[1], path: parsedPath[parsedPath.length - 1]};
    };
}