import Box from '@material-ui/core/Box';
import React from 'react';
import './TabPanel.scss';

interface TabPanelProps {
    children?: React.ReactNode;
    dir?: string;
    index: any;
    value: any;
  }

export const TabPanel: React.FunctionComponent<TabPanelProps> = (props: TabPanelProps) => {
	const { children, value, index, ...other } = props;
  
	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`full-width-tabpanel-${index}`}
			aria-labelledby={`full-width-tab-${index}`}
			className="tab-panel-container p-md-5"
			{...other}
		>
			{value === index && (
				<Box>
					{children}
				</Box>
			)}
		</div>
	);
};