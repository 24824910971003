import Autocomplete from '@material-ui/lab/Autocomplete';
import { Button } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import React, {ChangeEvent, useEffect, useState} from 'react';
import { NCTitle } from 'src/atoms/NCTitle/NCTitle';
import { GroupCountry } from 'src/models/GroupCountry';
import { Country } from 'src/models/Country';
import { NCSwitch } from '@wolfiesports/srm-component';

interface GroupCountryFormProps {
    groupCountry?: GroupCountry;
    countries: Array<Country>;
    setOpenModal: (open: boolean) => void;
    actionHook: (groupCountry: GroupCountry) => void;
}

export const GroupCountryForm: React.FunctionComponent<GroupCountryFormProps> = (props: GroupCountryFormProps) => {
    const [ groupCountry, setGroupCountry ] = useState<GroupCountry>(props.groupCountry?._id ? props.groupCountry : Object.assign({}));
    const [ countries, setCountries ] = useState<Array<string>>(props.groupCountry?.countries ?? []);

    useEffect(() => {
        setGroupCountry(props.groupCountry?._id ? props.groupCountry : Object.assign({}));
        setCountries(props.groupCountry?.countries ?? []);
    }, [props.groupCountry])

    const onGroupCountryChange = (event: ChangeEvent<HTMLInputElement>) => {
        const {name, value} = event.currentTarget;
        groupCountry.value = value;
        setGroupCountry(groupCountry);
        if (groupCountry._id) {
            setGroupCountry(Object.assign({}, groupCountry, {
                [name]: value,
            }));
        }
    };

    const close = () => {
        props.setOpenModal(false);
        setGroupCountry(new GroupCountry('', '', []));
    }

    const save = () => {
        close();
        groupCountry.countries = countries;
        props.actionHook(groupCountry);
    }

    const onSelectChange = (countries: Array<string>) => {
        setCountries(countries);
    }

    return (
        <div className="w-100 NC-tab">
            <NCTitle text={groupCountry.value || 'New Group Country'}/>
            {
                !groupCountry.value &&
                <TextField
                    className="w-100 mb-4"
                    name="value"
                    label="Value"
                    value={groupCountry.value}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => (onGroupCountryChange(event))}
                />
            }
            {
                <Autocomplete
                    multiple
                    id="tags-outlined"
                    value={countries}
                    options={props.countries.map(country => country.iso2)}
                    getOptionLabel={(country: string) => country}
                    filterSelectedOptions
                    onChange={(event, value) => onSelectChange(value)}
                    renderInput={(params: any) => (
                        <TextField
                            {...params}
                            variant="outlined"
                            placeholder="Countries"
                        />
                    )}
                />
            }
            <div className="d-flex mt-3">
                <div className="mr-2 secondary-color-light">Enable as filter on tournament homepage</div>
                <NCSwitch
                    checked={groupCountry.isHomeFilter}
                    onChange={(e) => {
                        groupCountry.isHomeFilter = e;
                        setGroupCountry({ ...groupCountry});
                    }}
                />
            </div>

            <div className="d-flex justify-content-center">
                <Button className="m-2" variant="contained" color="secondary" onClick={() => close()}>Cancel</Button>
                <Button className="m-2" variant="contained" color="primary" onClick={() => save()}>Save</Button>
            </div>
        </div>
    );
};