import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import React, {useState} from 'react';
import {Game} from 'src/models/Game';
import {Seo} from 'src/models/Seo';
import {LangPicker} from 'src/components/General/LangPicker/LangPicker';
import { TranslatedString } from 'src/models/TranslatedString';

interface SeoInfoProps {
    seo: Seo;
    setSeo: any;
    edit?: boolean;
    creationMode?: boolean;
    gameList: Array<Game>;
}

export const SeoInfo: React.FunctionComponent<SeoInfoProps> = (props: SeoInfoProps) => {
    const [lang, setLang] = useState<string>('en');
    const [seoState, setSeoState] = useState<Seo>(props.seo);

    const onSeoInfoChange = (event: any, isTranslatedString = false, optionalArgument: any = undefined) => {
        let name, value;

        if (optionalArgument) {
            name = event.target.name;
            value = event.target.value;
            seoState.game = value;
        } else {
            name = event.currentTarget.name;
            value = event.currentTarget.value;
        }

        if (isTranslatedString) {
            const mySeoAttr = seoState[name as keyof Seo] as TranslatedString;
            mySeoAttr[lang] = value;
        } else {
            if (!optionalArgument) {
                setSeoState(Object.assign({}, seoState, {
                    [name]: value,
                }));
            }

        }
        props.setSeo(seoState);
    };

    return (
        <div className="w-100 p-4">
            <div className="d-flex mb-3">
                {
                    !props.creationMode &&
                    <TextField
                        disabled
                        label="ID"
                        name="_id"
                        value={seoState._id}
                        className="w-50"
                    />
                }
                <div className="w-50 mx-auto">
                    <LangPicker
                        setLang={setLang}
                    />
                </div>
            </div>
            <div className="d-flex mb-3">
                <TextField
                    disabled={!props.edit && !props.creationMode}
                    label="Title"
                    value={seoState.title[lang]}
                    className="w-50"
                    name="title"
                    onChange={(event: any) => {
                        onSeoInfoChange(event);
                    }}
                />
                <TextField
                    disabled={!props.edit && !props.creationMode}
                    label="Value"
                    value={seoState.value}
                    className="w-50 ml-3"
                    name="value"
                    onChange={(event: any) => {
                        onSeoInfoChange(event);
                    }}
                />
            </div>
            <div className="d-flex mb-3">
                <FormControl className="w-50">
                    <InputLabel>Game</InputLabel>
                    <Select
                        labelId="Game"
                        value={seoState.game ? seoState.game : ''}
                        onChange={(event: any) => {
                            onSeoInfoChange(event, false, true);
                        }}
                        name="game"
                    >
                        <MenuItem value="''">Select a game</MenuItem>
                        {
                            props.gameList.map((game) => {
                                return (
                                    <MenuItem value={game._id} key={game._id}>
                                        <em>{game.name}</em>
                                    </MenuItem>
                                );
                            })
                        }
                    </Select>
                </FormControl>
                <TextField
                    disabled={!props.edit && !props.creationMode}
                    label="Keywords"
                    value={seoState.keywords[lang]}
                    className="w-50 ml-3"
                    name="keywords"
                    onChange={(event: any) => {
                        onSeoInfoChange(event);
                    }}
                />
            </div>
            <div className="mb-3">
                <TextField
                    disabled={!props.edit && !props.creationMode}
                    label="Description"
                    multiline
                    rowsMax={10}
                    rows={5}
                    value={seoState.description[lang]}
                    className="w-100"
                    name="description"
                    onChange={(event: any) => {
                        onSeoInfoChange(event);
                    }}
                />
            </div>
        </div>
    );
};