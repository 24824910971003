import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import { App } from './App';
import { createTheme, MuiThemeProvider } from '@material-ui/core';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import { QueryClient, QueryClientProvider } from 'react-query';

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement
);

const theme = createTheme({
	palette: {
		primary: {
			main: '#b2f617',
		},
		secondary: {
			main: '#292D30',
		},
		type: 'dark'
	},
});

root.render(
	<MuiThemeProvider theme={theme}>
		<QueryClientProvider client={queryClient}>
			<App />
		</QueryClientProvider>
		<ToastContainer
			position="top-right"
			autoClose={5000}
			hideProgressBar={false}
			newestOnTop={true}
			closeOnClick
			rtl={false}
			pauseOnFocusLoss
			draggable
			pauseOnHover
		/>
	</MuiThemeProvider>
);