import { BrowserRouter, Navigate, Outlet, Route, Routes } from 'react-router-dom';

import { AdministratorSetting } from 'src/components/Administrators/AdministratorSetting/AdministratorSetting';
import { Administrators } from 'src/pages/Administrators/Administrators';
import { AdministratorsRole } from 'src/models/Administrator';
import { Bracket } from 'src/pages/Tournaments/MatchList/Bracket';
import { Broadcast } from 'src/pages/Training/Broadcast/Broadcast';
import { Challenges } from 'src/pages/Challenges/Challenges';
import { Companies } from 'src/pages/Companies/Companies';
import { CornerBuilder } from 'src/pages/CornerBuilder/CornerBuilder';
import { Countries } from 'src/pages/Countries/Countries';
import { Currencies } from 'src/pages/Currencies/Currencies';
import { Dashboard } from 'src/pages/Dashboard/Dashboard';
import { FileManagerForm } from 'src/components/FileManager/FileManagerForm';
import { FileManagerMode } from 'src/models/MediaLibrary';
import {GameAccounts} from 'src/pages/GameAccounts/GameAccounts';
import { GamePages } from 'src/pages/GamePages/GamePages';
import { Games } from 'src/pages/Games/Games';
import { Guides } from 'src/pages/Training/Guides/Guides';
import { HomepageCarousel } from 'src/pages/Homepage/HomepageCarousel';
import { HomepageEvents } from 'src/pages/Homepage/HomepageEvents';
import { HomepageNews } from 'src/pages/Homepage/HomepageNews';
import { MatchListFull } from 'src/pages/Tournaments/MatchList/MatchList';
import { MatchSheet } from 'src/components/Tournaments/MatchSheet/MatchSheet';
import { Navbar } from 'src/components/General/Navbar/Navbar';
import { Organization } from 'src/pages/Organization/Organization';
import { Partners } from 'src/pages/Partners/Partners';
import { Platforms } from 'src/pages/Platforms/Platforms';
import { Premium } from 'src/pages/Premium/Premium';
import { Quiz } from 'src/pages/Training/Quiz/Quiz';
import React, { useEffect } from 'react';
import { Promotions } from 'src/pages/Promotions/Promotions';
import { SeoPage } from 'src/pages/Seo/Seo';
import { SkillGameBlocks } from 'src/pages/Training/SkillGameBlocks/SkillGameBlocks';
import { SkillGames } from 'src/pages/Training/SkillGames/SkillGames';
import { TeamList } from 'src/pages/Team/TeamList/TeamList';
import { TournamentListFull } from 'src/pages/Tournaments/TournamentList/TournamentList';
import { TournamentMatchRefereeing } from 'src/pages/Tournaments/TournamentMatchRefereeing/TournamentMatchRefereeing';
import { TournamentRefereeList } from 'src/pages/Tournaments/TournamentRefereeList/TournamentRefereeList';
import { Login } from 'src/pages/Login/Login';
import { useSession } from 'src/session/session';
import { TransactionDetail } from 'src/pages/TransactionDetail/TransactionDetail';
import { Transactions } from 'src/pages/Transactions/Transactions';
import { UserDetails } from 'src/pages/UserDetails/UserDetails';
import { Users } from 'src/pages/Users/Users';
import { Video } from 'src/pages/Video/Video';
import { Vouchers } from 'src/pages/Premium/Vouchers/Vouchers';
import { WebappBOTournament } from 'src/pages/WebappTournament/WebappTournament';
import { toast } from 'react-toastify';
import { useStoreState, useStoreActions } from 'src/store';
import { TrainingDashboard } from 'src/pages/Training/Dashboard/Dashboard';
import { TrainingPresets } from 'src/pages/Training/Presets/Presets';
import { TrainingThematics } from 'src/pages/Training/Thematics/Thematics';
import { TrainingPrograms } from 'src/pages/Training/Programs/Programs';
import { TrainingModules } from 'src/pages/Training/Modules/Module';
import { TournamentsDashboard } from 'src/pages/Tournaments/Dashboard/Dashboard';
import axios from 'axios';

export const BaseRouter: React.FunctionComponent = () => {
    const user = useSession();
    const isLoggedIn = useStoreState(state => state.isLoggedIn);
    const setIsLoggedIn = useStoreActions(s => s.setIsLoggedIn);
    const administratorRole: Array<AdministratorsRole> | undefined = useStoreState(state => state.currentAdministrator?.role);

    useEffect(() => {
        console.log('Router');
        user.refetch()
    }, [])

    useEffect(() => {
        console.log(user);
    }, [user]);

    axios.interceptors.response.use(
        (config) => {
            if (config.status === 401) {
                setIsLoggedIn(false);
            }
            return config;
        },
        (error) => {
          console.log(error);
        }
    );

    if(!isLoggedIn) {
        return (
            <div className="p-md-5">
                <BrowserRouter>
                    <Routes>
                        <Route path="*" element={<Login/>}/>
                    </Routes>
                </BrowserRouter>
            </div>
        );
    }

    const ProtectedRoute = ({ askedRole, redirectPath = '/' }: { askedRole: Array<AdministratorsRole>, redirectPath?: string}) => {
        if (!administratorRole || !administratorRole.some(r => askedRole.includes(r))) {
            toast.error('You don\'t have permissions to access this page');
            return <Navigate to={redirectPath} replace />;
        }
      
        return <Outlet />;
    };

    return (
        <div className="p-md-5">
            <BrowserRouter>
                <Navbar/>
                <Routes>
                    <Route path="/dashboard" element={<Dashboard/>}/>
                    <Route element={<ProtectedRoute askedRole={[ AdministratorsRole.NCADMIN ]} />}>
                        <Route path="/users/:userId" element={<UserDetails/>}/>
                        <Route path="/users" element={<Users/>}/>
                        <Route path="/administrators" element={<Administrators/>}/>
                        <Route path="/currencies" element={<Currencies/>}/>
                        <Route path="/teams/:teamId" element={<TeamList/>}/>
                        <Route path="/teams" element={<TeamList/>}/>
                        <Route path="/countries" element={<Countries/>}/>
                        <Route path="/homepage/news" element={<HomepageNews/>}/>
                        <Route path="/homepage/events" element={<HomepageEvents/>}/>
                        <Route path="/homepage/carousel" element={<HomepageCarousel/>}/>
                        <Route path="/organizations" element={<Organization/>}/>
                        <Route path="/partners" element={<Partners/>}/>
                        <Route path="/withdrawal/:owner/*" element={<TransactionDetail/>}/>
                        <Route path="/withdrawal" element={<Transactions/>}/>
                        <Route path="/gameaccounts" element={<GameAccounts/>}/>
                        <Route path="/cornerbuilder" element={<CornerBuilder/>}/>
                        <Route path="/seo" element={<SeoPage/>}/>
                        <Route path="/platforms" element={<Platforms/>}/>
                        <Route path="/gamepages" element={<GamePages/>}/>
                        <Route path="/premium" element={<Premium/>}/>
                        <Route path="/voucher" element={<Vouchers/>}/>
                        <Route path="/profile" element={<AdministratorSetting/>}/>
                        <Route path="/companies" element={<Companies/>}/>
                        <Route path="/promotions" element={<Promotions/>}/>
                        <Route path="/videos" element={<Video/>}/>
                        <Route path="/challenges" element={<Challenges/>}/>
                        <Route path="/media-library" element={<FileManagerForm mode={FileManagerMode.NORMAL}/>}/>
                    </Route>
                    <Route element={<ProtectedRoute askedRole={[ AdministratorsRole.TRAINING, AdministratorsRole.NCADMIN ]} />}>
                        <Route path="/games" element={<Games/>}/>
                        <Route path="/countries" element={<Countries/>}/>
                        <Route path="/training/dashboard" element={<TrainingDashboard/>}/>
                        <Route path="/training/presets" element={<TrainingPresets/>}/>
                        <Route path="/training/thematics" element={<TrainingThematics/>}/>
                        <Route path="/challenges" element={<Challenges/>}/>
                        <Route path="/training/quiz" element={<Quiz/>}/>
                        <Route path="/training/broadcast" element={<Broadcast/>}/>
                        <Route path="/training/skillgames" element={<SkillGames/>}/>
                        <Route path="/training/skillgameblocks" element={<SkillGameBlocks/>}/>
                        <Route path="/training/guides" element={<Guides/>}/>
                        <Route path="/training/program/:programId" element={<TrainingPrograms/>}/>
                        <Route path="/training/module/:moduleId" element={<TrainingModules/>}/>
                        <Route path="/training/program/" element={<TrainingPrograms/>}/>
                        <Route path="/training/module/" element={<TrainingModules/>}/>
                    </Route>
                    <Route element={<ProtectedRoute askedRole={[ AdministratorsRole.TOURNAMENT, AdministratorsRole.NCADMIN ]} />}>
                        <Route path="/tournaments/dashboard" element={<TournamentsDashboard/>}/>
                        <Route path="/tournaments/refereeing/matchs/list" element={<TournamentMatchRefereeing/>}/>
                        <Route path="/tournaments/list" element={<TournamentListFull/>}/>
                        <Route path="/tournaments/referee-list" element={<TournamentRefereeList/>}/>
                        <Route path="/tournaments/:tournamentid/matchs/list" element={<MatchListFull/>}/>
                        <Route path="/tournaments/:tournamentid/matchs/bracket" element={<Bracket/>}/>
                        <Route path="/tournaments/:tournamentid/matchs/:matchid" element={<MatchSheet/>}/>
                        <Route path="/tournaments/beta/*" element={<WebappBOTournament/>}/>
                    </Route>
                    <Route path="/" element={<Navigate to="/dashboard"/>}/>
                    <Route path="*" element={<Navigate to="/dashboard"/>}/>
                </Routes>
            </BrowserRouter>
        </div>
    );
}