
import {
    CircularProgress,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from '@material-ui/core';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { TeamInvitation } from '../../../models/Team';
import { TeamService } from '../../../services/team.service';
import './UserInvitationsTable.scss';

interface UserInvitationsTableProps {
    userId: string;
}

export const UserInvitationsTable: React.FunctionComponent<UserInvitationsTableProps> = (
    props: UserInvitationsTableProps
) => {
    const { userId } = props;
    const [isLoading, setIsLoading] = useState(true);
    const [invitations, setInvitations] = useState<Array<TeamInvitation>>([]);

    useEffect(() => {
        const getInvitations = async () => {
            setIsLoading(true);
            TeamService.getInvitationsByUser(userId)
                .then((data) => {
                    setInvitations(data.list);
                    setIsLoading(false);
                })
                .catch(error => console.log(error));
        };
        getInvitations();
    }, [userId]);

    return (
        isLoading
            ? <CircularProgress className="mx-auto" />
            : <TableContainer>
                <Table>
                    <TableHead>
                            <TableRow>
                                <TableCell align="left">Tag</TableCell>
                                <TableCell align="left">Name</TableCell>
                                <TableCell align="left">Date</TableCell>
                                <TableCell align="left">User sending request</TableCell>
                            </TableRow>
                    </TableHead>
                    <TableBody>
                        {invitations.length > 0 
                            ? invitations.map((invitation: TeamInvitation) => {
                                const { team: teamSlug, teamInfo, date } = invitation;
                                const { owner } = teamInfo;
                                return (
                                    <TableRow key={teamSlug}>
                                        <TableCell align="left" className="text-uppercase font-weight-bold">{teamInfo.tag}</TableCell>
                                        <TableCell align="left">{teamInfo.name}</TableCell>
                                        <TableCell align="left">
                                            {moment(Date.parse(date)).format('L').toString()}
                                        </TableCell>
                                        <TableCell align="left">
                                            <div className="d-flex owner">
                                                <div className="avatar-container">
                                                    <img className="avatar"
                                                        alt=""
                                                        src={`${process.env.REACT_APP_S3_URL}/user/${owner.id}/medias/ProfileImage`}
                                                        onError={e => e.currentTarget.src = `${process.env.REACT_APP_S3_URL}/media/default/default-user-avatar.svg`}
                                                    />
                                                </div>
                                                <span className="my-auto ml-2 mr-5">{owner.name}</span>
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                );
                            })
                            : <TableRow>
                                <TableCell align="left">No data</TableCell>
                            </TableRow>
                        }
                    </TableBody>
                </Table>
            </TableContainer>
    );
};