import { Game, GameStatus, GameType, NCInput, NCSelect } from '@wolfiesports/srm-component';
import React from 'react';

interface NoGameInfoProps {
    game: Game;
    setGame: any;
    edit?: boolean;
    creationMode?: boolean;
}

export const NoGameInfo: React.FunctionComponent<NoGameInfoProps> = (props: NoGameInfoProps) => {

    const onGameInfoChange = (key: string, value: any) => {
        console.log("Newname,", key);
        console.log("NewValue", value);
        const newGame = Object.assign({}, props.game, {
            [key]: value,
        });

        console.log("New Game 1", newGame);
        props.setGame(newGame);
    };

    return (
        <div className="w-100 p-4">
            <div className="d-flex mb-3">
                {
                    <NCInput
                        disabled={!props.edit && !props.creationMode}
                        label="ID"
                        name="id"
                        value={props.game.id}
                        onChange={(value: any) => {
                            onGameInfoChange('id', value);
                        }}
                    />
                }
                <div className="w-50 ml-3">
                    <NCSelect
                        selectFields={[
                            {key: "Inactive", value: GameStatus.Inactive},
                            {key: "Active", value: GameStatus.Active}
                        ]}
                        selectedField={props.game.active}
                        disabled={!props.edit && !props.creationMode}
                        fieldName="key"
                        fieldValue='value'
                        actionHook={(value: any) => {
                            onGameInfoChange('active', Number(value));
                        }}
                    />
                </div>
            </div>
            <div className="d-flex mb-3">
                <NCInput
                    disabled={!props.edit && !props.creationMode}
                    label="Title"
                    value={props.game.title}
                    name="title"
                    onChange={(value: any) => {
                        onGameInfoChange('title', value);
                    }}
                />
                <NCInput
                    disabled={!props.edit && !props.creationMode}
                    label="Icon"
                    value={props.game.icon}
                    name="icon"
                    onChange={(value: any) => {
                        onGameInfoChange('icon', value);
                    }}
                />
            </div>
            <div className='d-flex mb-3'>
            <div className="w-50 ml-3">
                    <NCSelect
                        disabled={!props.edit && !props.creationMode}
                        selectFields={[
                            {key: "External", value: GameType.External}, 
                            {key: "Embedded", value: GameType.Embedded}
                        ]}
                        selectedField={props.game.gameType}
                        fieldName="key"
                        fieldValue='value'
                        actionHook={(value: any) => {
                            onGameInfoChange('gameType', Number(value));
                        }}
                    />
                </div>
            </div>
        </div>
    );
};