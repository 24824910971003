import Chip from '@material-ui/core/Chip';
import CircularProgress from '@material-ui/core/CircularProgress';
import React, {useEffect, useState} from 'react';
import {toast} from 'react-toastify';
import {NCTitle} from 'src/atoms/NCTitle/NCTitle';
import {ActionDialog} from 'src/components/General/ActionDialog/ActionDialog';
import {FullScreenDialog} from 'src/components/General/FullScreenDialog/FullScreenDialog';
import {SimpleList} from 'src/components/General/SimpleList/SimpleList';
import {BroadcastForm} from 'src/components/Training/Broadcast/BroadcastForm';
import {TrainingBroadcast} from 'src/models/TrainingBroadcast';
import {GameService} from 'src/services/game.service';
import {TrainingService} from 'src/services/training.service';
import './Broadcast.scss';

export const Broadcast: React.FunctionComponent = () => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [broadcast, setBroadcast] = useState<TrainingBroadcast>();
    const [rows, setRows] = useState<Array<any>>([]);
    const [actualPage, setActualPage] = useState<number>(0);
    const [totalItem, setTotalItem] = useState<number>(0);
    const [owGame, setOwGame] = useState<Array<any>>([]);

    const [actionDelete, setActionDelete] = useState<boolean>(false);
    const [actionCreate, setActionCreate] = useState<boolean>(false);
    const [actionEdit, setActionEdit] = useState<boolean>(false);

    const columns = ['Message', 'Disabled', 'Primary', 'Games'];
    const keepRows = ['message', 'disabled', 'primary', 'gamesName'];

    useEffect(() => {
        TrainingService.getBroadcast(20, actualPage).then((data) => {
            setRows(data.docs);
            setTotalItem(Math.ceil(data.total / 20));
            setActualPage(data.page);
            setIsLoading(false);
        });
        GameService.get360Games().then((data) => {
            setOwGame(data);
        });
    }, [totalItem, actualPage]);

    const actionManager = (broadcast: TrainingBroadcast, kind: string) => {
        setBroadcast(broadcast);

        switch (kind) {
            case 'delete':
                setActionDelete(true);
                break;
            case 'edit':
                setActionEdit(true);
                break;
            case 'create': {
                setBroadcast(new TrainingBroadcast(
                    '',
                    false,
                    false,
                    [],
                    {en: ''},
                    new Date(),
                    new Date(),
                ));
                setActionCreate(true);
                break;
            }
            default:
                return;
        }
    };

    const deleteBroadcast = (choice: boolean) => {
        if (choice && broadcast) {
            TrainingService.deleteBroadcast(broadcast._id).then((data: boolean) => {
                if (!data) {
                    toast.error('Failed to delete broadcast');
                    updateList(false);
                    return;
                }
                toast.success('Broadcast removed');
                updateList(true);
            });
        }
    };

    const updateList = (update = false) => {
        setActionDelete(false);
        setActionEdit(false);
        setActionCreate(false);
        if (update) {
            setIsLoading(true);
            TrainingService.getBroadcast(20, actualPage).then((data) => {
                setRows(data.docs);
                setTotalItem(Math.ceil(data.total / 20));
                setActualPage(data.page);
                setIsLoading(false);
            });
        }
    };

    const filterRows = () => {
        const tmpRows: any = rows;
        tmpRows.forEach((row: any) => {
            row.gamesName = '';
            row.games.forEach((id: string) => {
                if (owGame.find(g => g._id === id)) {
                    row.gamesName = row.gamesName.concat(
                        ' ',
                        (owGame.find(g => g._id === id)).name,
                    );
                }
            });
        });
        return tmpRows;
    };

    return (
        <React.Fragment>
            <div className="broadcast NC-container NC-card d-flex flex-column">
                <NCTitle text={'Broadcast'}/>
                <div className="d-flex w-100">
                    {
                        owGame.map(g => {
                            return (
                                <div className="mx-2" key={g._id}>
                                    <Chip
                                        icon={<div className={`state ${g.state}`}></div>}
                                        label={g.name}
                                    />
                                </div>
                            );
                        })
                    }
                </div>
                <div className="d-flex">
                    {
                        isLoading && <CircularProgress className="mx-auto"/>
                    }
                    {
                        !isLoading &&
                        <SimpleList
                            rows={filterRows}
                            columns={columns}
                            keepRows={keepRows}
                            actionsButtons={{create: true, edit: true, delete: true}}
                            handleClickOpen={actionManager}
                            pagination={{actual: actualPage, total: totalItem, setActual: setActualPage}}
                            translatedString={true}
                        />
                    }
                </div>
                <ActionDialog
                    open={actionDelete}
                    title={'Are you sure to delete this broadcast'}
                    openHook={setActionDelete}
                    actionHook={deleteBroadcast}
                />
                {
                    broadcast &&
                    <FullScreenDialog
                        open={actionEdit}
                        openHook={setActionEdit}
                        actionHook={updateList}
                        componentToDisplay={
                            <BroadcastForm
                                broadcast={broadcast}
                                gameList={owGame}
                                actionHook={updateList}
                            />
                        }
                    />
                }
                {
                    broadcast &&
                    <FullScreenDialog
                        open={actionCreate}
                        openHook={setActionCreate}
                        actionHook={updateList}
                        componentToDisplay={
                            <BroadcastForm
                                broadcast={broadcast}
                                creationMode={true}
                                gameList={owGame}
                                actionHook={updateList}
                            />
                        }
                    />
                }
            </div>
        </React.Fragment>
    );
};