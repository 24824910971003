import axios, { AxiosError } from 'axios';
import { UserSanction } from 'src/models/UserSanction';
import { Account, GameInfo, Media, Settings, User } from '../models/User';

export class UserService {
    private static baseUrl: string = String(process.env.REACT_APP_PRIVATE_AUTH_URL);
    static authToken = (): string | null => localStorage.getItem('authToken');
    static token = (): string | null => localStorage.getItem('accessToken');

    static async getUsers( lastKey?: any): Promise<any> {
        let res;

        try {
            res = await axios.get(
                `${UserService.baseUrl}/users`,
                {
                    headers: {
                        Authorization: `Bearer ${UserService.authToken()}`,
                    },
                    method: 'GET',
                    params: { ...lastKey }
                }
            );
        } catch (e) {
            throw (e as AxiosError).response;
        }

        if (!res.data && !res.data) {
            throw Error;
        }

        const usersList = res.data.map((item: any) => {
            return new User(
                item.identity.id,
                item.identity.email,
                item.identity.firstName,
                item.identity.lastName,
                item.identity.nickname,
                item.identity.code,
                item.identity.birthday,
                {
                    confirmation: item.account.confirmation,
                    subscriptionStatus: item.account.subscriptionStatus,
                    premiumUntil: item.account.premiumUntil,
                    createdAt: item.account.createdAt,
                },
                {
                    favoriteGames: item.gameInfo.favoriteGames,
                    favoritePlatforms: item.gameInfo.favoritePlatforms,
                },
                {
                    BannerImage: item.media.BannerImage,
                    ProfileImage: item.media.ProfileImage,
                },
                {
                    countryCode: item.settings.countryCode,
                    currency: item.settings.currency,
                    hasOptedIn: item.settings.hasOptedIn,
                    lang: item.settings.lang,
                },
                item.identity.phoneNumber,
            );
        });

        let totalItems: number = 0;
        if (res.headers['x-count-item']) {
            totalItems = parseInt(res.headers['x-count-item'], 10);
        }

        return {
            docs: usersList,
            total: totalItems,
        }
    }

    static async getUserById(id: string): Promise<User | null> {
        if (!id) {
            return null
        }
        let res;

        try {
            res = await axios.get(
                `${UserService.baseUrl}/users/${id}`,
                {
                    headers: {
                        Authorization: `Bearer ${UserService.authToken()}`,
                    }
                }
            );
        } catch (e) {
            throw (e as AxiosError).response;
        }
        return new User(
            res.data.identity.id,
            res.data.identity.email,
            res.data.identity.firstName,
            res.data.identity.lastName,
            res.data.identity.nickname,
            res.data.identity.code,
            res.data.identity.birthday,
            new Account(
                res.data.account.confirmation,
                res.data.account.subscriptionStatus,
                res.data.account.premiumUntil,
                res.data.account.createdAt,
            ),
            new GameInfo(
                res.data.gameInfo.favoriteGames,
                res.data.gameInfo.favoritePlatforms,
            ),
            new Media(
                res.data.media.BannerImage,
                res.data.media.ProfileImage,
            ),
            new Settings(
                res.data.settings.countryCode,
                res.data.settings.currency,
                res.data.settings.hasOptedIn,
                res.data.settings.lang,
            ),
            res.data.identity.phoneNumber,
        );
    }

    static async getUserActivity(userId: string, limit: number = 20, page: number = 0): Promise<any> {
        if (!userId) {
            throw Error;
        }

        let res;
        try {
            res = await axios.get(
                `${UserService.baseUrl}/users/${userId}/activity?limit=${limit}&start=${page}`,
                {
                    headers: {
                        Authorization: `Bearer ${UserService.authToken()}`,
                    }
                }
            );
        } catch (e) {
            throw (e as AxiosError).response;
        }

        let totalItems: number = 0;
        if (res.headers['x-count-item']) {
            totalItems = parseInt(res.headers['x-count-item'], 10);
        }


        return {
            docs: res.data,
            pages: page,
            total: totalItems,
        }
    }

    static async updateUser(user: User): Promise<boolean> {
        if (!user) {
            return false;
        }

        user.gameInfo.favoriteGames = (user.gameInfo.favoriteGames as Array<any>).map(favorite => favorite.id ? favorite.id : favorite);

        const body = {
            account: user.account,
            gameInfo: user.gameInfo,
            identity: {
                id: user._id,
                email: user.email,
                firstName: user.firstName,
                lastName: user.lastName,
                code: user.code,
                birthday: user.birthday,
            },
            settings: user.settings,
            content: {
                about: {}
            }
        };

        try {
            await axios.patch(
                `${UserService.baseUrl}/users/${user._id}`,
                body,
                {
                    headers: {
                        Authorization: `Bearer ${UserService.authToken()}`,
                    }
                }
            );
        } catch (e) {
            return false;
        }
        return true;
    }

    static async deleteUser(userId: string): Promise<boolean> {
        if (!userId) {
            return false;
        }

        try {
            await axios.delete(
                `${UserService.baseUrl}/users/${userId}`,
                {
                    headers: {
                        Authorization: `Bearer ${UserService.authToken()}`,
                    }
                }
            )
        } catch {
            return false;
        }
        return true;
    }

    static async addBannedUser(userBan: UserSanction): Promise<boolean> {
        if (!userBan) {
            return false;
        }

        const body = {
            userId: userBan.userId,
            date: userBan.date,
            status: userBan.status,
            reason: userBan.reason,
            adminId: userBan.adminId,
            description: userBan.description,
            bannedUntil: userBan.bannedUntil,
        };

        try {
            await axios.post(
                `${process.env.REACT_APP_ADMINAPI_URL}/userban/${userBan.userId}`,
                body,
                {
                    headers: {
                        'x-access-token': UserService.token(),
                    }
                }
            )
        } catch {
            return false;
        }
        return true;
    }

    static async getUserBannedHistory(userId: string): Promise<Array<UserSanction>> {
        if (!userId) {
            throw Error;
        }

        let res;
        try {
            res = await axios.get(
                `${process.env.REACT_APP_ADMINAPI_URL}/userban/${userId}/history`,
                {
                    headers: {
                        'x-access-token': UserService.token(),
                    }
                }
            );
        } catch (e) {
            throw (e as AxiosError).response;
        }

        return res.data;
    }
}