import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import {TransitionProps} from '@material-ui/core/transitions';
import './FullScreenDialog.scss';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children?: React.ReactElement },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="right" ref={ref} {...props} />;
});

interface FullScreenDialogProps {
    open: boolean;
    openHook: any;
    actionHook?: (choice: boolean) => any;
    componentToDisplay: any;
    center?: boolean;
    disableEnforceFocus?: boolean;
}

export const FullScreenDialog: React.FunctionComponent<FullScreenDialogProps> = (props: FullScreenDialogProps) => {
    const handleClose = () => {
        props.openHook(false);
        if (props.actionHook !== undefined) {
            props.actionHook(false);
        }
    };

    return (
        <Dialog fullScreen={props.center ? false : true} open={props.open} onClose={handleClose}
                TransitionComponent={Transition} classes={{paperWidthSm: 'min-width=1000px'}} disableEnforceFocus={props.disableEnforceFocus}>
            <AppBar className="position-relative">
                <Toolbar>
                    <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                        <CloseIcon/>
                    </IconButton>
                </Toolbar>
            </AppBar>
            <div className="px-5 pt-2">
                {props.componentToDisplay}
            </div>
        </Dialog>
    );
};