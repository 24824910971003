import { CircularProgress } from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React, { useEffect, useState } from 'react';
import { NCTitle } from 'src/atoms/NCTitle/NCTitle';
import { FullScreenDialog } from 'src/components/General/FullScreenDialog/FullScreenDialog';
import { SimpleList } from 'src/components/General/SimpleList/SimpleList';
import { PremiumPlanForm } from 'src/components/Premium/PremiumPlanForm/PremiumPlanForm';
import { PremiumProductForm } from 'src/components/Premium/PremiumProductForm/PremiumProductForm';
import { Country } from 'src/models/Country';
import { Currency } from 'src/models/Currency';
import { PremiumPlan } from 'src/models/PremiumPlan';
import { PremiumProduct } from 'src/models/PremiumProduct';
import { CountryService } from 'src/services/country.service';
import { CurrencyService } from 'src/services/currency.service';
import { PremiumService } from 'src/services/premium.service';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { ActionDialog } from 'src/components/General/ActionDialog/ActionDialog';
import { toast } from 'react-toastify';

export const Premium: React.FunctionComponent = () => {
    const [ plans, setPlans ] = useState<Array<PremiumPlan>>([]);
    const [ products, setProducts ] = useState<Array<PremiumProduct>>([]);
    const [ isLoading, setIsLoading ] = useState<boolean>(false);
    const [ plan, setPlan ] = useState<PremiumPlan | undefined>();
    const [ product, setProduct ] = useState<PremiumProduct | undefined>();
    const [ planActionCreate, setPlanActionCreate ] = useState<boolean>(false);
    const [ planActionEdit, setPlanActionEdit ] = useState<boolean>(false);
    const [ productActionCreate, setProductActionCreate ] = useState<boolean>(false);
    const [ currencies, setCurrencies ] = useState<Array<Currency>>();
    const [ countries, setCountries ] = useState<Array<Country>>();
    const [ itemToDelete, setItemToDelete ] = useState<PremiumProduct | PremiumPlan>();
    const [ actionDeletePlan, setActionDeletePlan ] = useState<boolean>(false);
    const [ actionDeleteProduct, setActionDeleteProduct ] = useState<boolean>(false);
    const columns = [ 'Name', 'Price', 'Currency', 'Country', 'One Time Payment', 'Active' ];
    const keepRows = [ 'name', 'price', 'currency', 'countries', 'oneTimePayment', 'active' ];

    useEffect(() => {
        setIsLoading(true);
        PremiumService.getProducts().then((data) => {
            setProducts(data.data);
        }).catch(error => console.log(error));
        PremiumService.getPlans().then((data) => {
            setPlans(data.data);
        }).catch(error => console.log(error));
        CurrencyService.getAllCurrencies().then((data) => {
            setCurrencies(data);
        });
        CountryService.getAllCountries().then((data) => {
            setCountries(data);
        });
        setIsLoading(false);
    }, []);

    const actionManager = (item: any, kind: string, premiumKind?: string, productId?: string) => {
        if (premiumKind === 'product') {
            setProduct(item);
            switch (kind) {
                case 'create': {
                    setProduct(
                        new PremiumProduct(
                            '',
                            {
                                stripe: '',
                                paypal: '',
                            }
                        )
                    );
                    setProductActionCreate(true);
                    break;
                }
                default:
                    return;
            }
        } else {
            setPlan(item);
            switch (kind) {
                case 'switchActive':
                    PremiumService.updatePlanActive(item).then((res) => {
                        updateList(res);
                    }).catch(error => console.log(error));

                    break;
                case 'create': {
                    setPlan(
                        new PremiumPlan(
                            '',
                            productId || '',
                            {
                                stripe: '',
                                paypal: '',
                            },
                            '',
                            [],
                            0,
                            '',
                            0,
                            false,
                            false,
                        ));
                    setPlanActionCreate(true);
                    break;
                }
                case 'edit':{
                    setPlanActionEdit(true);
                    break;
                }
                case 'delete':
                    setItemToDelete(item);
                    setActionDeletePlan(true);
                    break;
                default:
                    return;
            }
        }
    };

    const updateList = (choice: boolean) => {
        setPlanActionCreate(false);
        setPlanActionEdit(false);
        setProductActionCreate(false);
        if (choice) {
            PremiumService.getProducts().then((data) => {
                setProducts(data.data);
            }).catch(error => console.log(error));
            PremiumService.getPlans().then((data) => {
                setPlans(data.data);
            }).catch(error => console.log(error));
        }
    };

    const deleteProduct = async (choice: boolean) => {
        if (choice && itemToDelete) {
            for (const plan of plans) {
                if (plan.product === itemToDelete?._id && plan.active) {
                    try {
                        await PremiumService.updatePlanActive(plan);
                        await PremiumService.deletePremiumPlan(plan._id);
                    } catch {
                        toast.error('Fail to delete plan');
                        return;
                    }
                }
            }
            try {
                await PremiumService.deletePremiumProduct(itemToDelete._id)
                updateList(true);
            } catch {
                toast.error('Fail to delete product');
                return;
            }
        }
    }

    const deletePlan = (choice: boolean) => {
        if (choice && itemToDelete) {
            PremiumService.deletePremiumPlan(itemToDelete._id).then(() => {
                updateList(true);
            })
        }
    }

    const renderPlansList = (productId: string) => {
        const rows: PremiumPlan[] = [];
        plans.forEach(element => {
            if (element.product === productId)
                rows.push(element);
        });

        return (
            <>
                <SimpleList
                    rows={rows}
                    columns={columns}
                    keepRows={keepRows}
                    actionsButtons={{ create: true, edit: true, delete: true, switchActive: true }}
                    handleClickOpen={actionManager}
                    metaData={productId}
                />
                {plan && <FullScreenDialog
                    open={planActionCreate}
                    openHook={setPlanActionCreate}
                    actionHook={updateList}
                    componentToDisplay={
                        <PremiumPlanForm premiumPlan={plan} setPremiumPlan={setPlan} actionHook={updateList}
                                         products={products} currencies={currencies} countries={countries}
                                         creationMode={true}/>
                    }
                />}
                {plan && <FullScreenDialog
                    open={planActionEdit}
                    openHook={setPlanActionEdit}
                    actionHook={updateList}
                    componentToDisplay={
                        <PremiumPlanForm premiumPlan={plan} setPremiumPlan={setPlan} actionHook={updateList}
                                         products={products} currencies={currencies} countries={countries}
                                         creationMode={false}/>
                    }
                />}
            </>
        );
    };

    return (
        <>
            <div className="NC-container NC-card d-flex flex-column">
                <div className="d-flex flex-row justify-content-between">
                    <NCTitle text={'Premium'}/>
                    <Button variant="text" color="primary" className="mx-1 my-auto h-25"
                            onClick={() => actionManager({}, 'create', 'product')}><AddIcon/></Button>
                </div>
                {isLoading && <CircularProgress className="mx-auto"/>}
                {!isLoading && products?.map((item: PremiumProduct) => (
                    <div key={item._id} className="mb-1">
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon/>}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <div className="d-flex flex-row justify-content-between w-100 align-items-center">
                                    <Typography className="font-weight-bold">{item.name}</Typography>
                                    <Button
                                        variant="text"
                                        onClick={() => {
                                            setItemToDelete(item);
                                            setActionDeleteProduct(true);
                                        }}
                                        color="secondary"
                                    >
                                        <DeleteForeverIcon/>
                                    </Button>

                                </div>
                            </AccordionSummary>
                            <AccordionDetails>
                                {renderPlansList(item._id)}
                            </AccordionDetails>
                        </Accordion>
                    </div>
                ))}
                {product && <FullScreenDialog
                    open={productActionCreate}
                    openHook={setProductActionCreate}
                    actionHook={updateList}
                    componentToDisplay={
                        <PremiumProductForm
                            premiumProduct={product}
                            setPremiumProduct={setProduct}
                            actionHook={updateList}
                            creationMode={true}/>
                    }
                />}
            </div>
            <ActionDialog
                open={actionDeletePlan}
                title={`Are you sure to delete this plan ${itemToDelete?.name} ?`}
                openHook={setActionDeletePlan}
                actionHook={deletePlan}
            />
            <ActionDialog
                open={actionDeleteProduct}
                title={`Are you sure to delete this product ${itemToDelete?.name}?`}
                openHook={setActionDeleteProduct}
                actionHook={deleteProduct}
            />
        </>
    );
};
