import React, {useState} from 'react';
import './LangPicker.scss';
import {Languages} from 'src/models/Languages';
import {store, useStoreState} from 'src/store';
import {createStyles, makeStyles, Theme, withStyles} from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';
import {FormControl} from '@material-ui/core';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';

const BootstrapInput = withStyles((theme: Theme) =>
    createStyles({
        root: {
            'label + &': {
                marginTop: theme.spacing(3),
            },
        },
        input: {
            borderRadius: 4,
            position: 'relative',
            backgroundColor: theme.palette.background.paper,
            border: '1px solid #ced4da',
            fontSize: 16,
            padding: '10px 26px 10px 12px',
            transition: theme.transitions.create(['border-color', 'box-shadow']),
            '&:focus': {
                borderRadius: 4,
                borderColor: '#b2f617',
                boxShadow: '0 0 0 0.2rem rgb(178, 246, 23,0.14)',
            },
        },
    }),
)(InputBase);

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        margin: {
            margin: theme.spacing(1),
        },
    }),
);

export interface LangPickerProps {
    title?: string;
    websiteLanguage?: boolean;
    setLang?: any;
    disabled?: boolean;
    noTitle?: boolean;
    onlyFlag?: boolean;
}

export const LangPicker: React.FunctionComponent<LangPickerProps> = (props: LangPickerProps) => {
    const classes = useStyles();
    const [selectedWebsiteLanguage, setSelectedWebsiteLanguage] = useState(useStoreState(state => state.websiteLang));
    const [selectedContentLanguage, setSelectedContentLanguage] = useState(useStoreState(state => state.contentLang));
    const [languages] = useState(store.getState().languages);
    const actions = store.getActions();

    const handleChange = (event: any) => {
        if (props.websiteLanguage) {
            setSelectedWebsiteLanguage(event);
            actions.setWebsiteLang(event);
        } else {
            setSelectedContentLanguage(event);
            actions.setContentLang(event);
            props.setLang(event);
        }
    };

    return (
        <div>
            <FormControl className={classes.margin}>
                {props.title &&
                <InputLabel>{props.title}</InputLabel>
                }
                <Select
                    disabled={props.disabled || false}
                    id="demo-customized-select"
                    value={props.websiteLanguage ? selectedWebsiteLanguage : selectedContentLanguage}
                    onChange={(event: any) => handleChange(event.target.value)}
                    input={<BootstrapInput/>}
                >
                    {
                        languages && languages.map((lang: Languages) => (
                            <MenuItem key={lang._id} value={lang.code}>
                                {lang.medias.ContentImage &&
                                <img alt={`${lang.name}img`} src={lang.medias.ContentImage} className="flag-img mr-2"/>}
                                {!props.onlyFlag && lang.name}
                            </MenuItem>
                        ))
                    }
                </Select>
            </FormControl>
        </div>

    );
};