import React, {ChangeEvent, useState} from 'react';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import ColorPicker from 'material-ui-color-picker';
import {FormControl} from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';
import Input from '@material-ui/core/Input';
import TextField from '@material-ui/core/TextField';
import {useStoreState} from 'src/store';
import {TrainingService} from 'src/services/training.service';
import {LangPicker} from 'src/components/General/LangPicker/LangPicker';
import {MediaManager} from 'src/components/General/MediaManager/MediaManager';
import {Game} from 'src/models/Game';
import {EThematicsMedia, TrainingThematic} from 'src/models/TrainingThematic';
import './ThematicsForm.scss';
import { TranslatedString } from 'src/models/TranslatedString';

interface ThematicsFormProps {
    actionHook: any;
    thematic: TrainingThematic;
    creationMode?: boolean;
    gameList: Array<Game>;
}

export const ThematicsForm: React.FunctionComponent<ThematicsFormProps> = (props: ThematicsFormProps) => {
    const [thematic, setThematic] = useState<TrainingThematic>(props.thematic);
    const [edit, setEdit] = useState<boolean>(false);
    const [langValue, setLangValue] = useState(useStoreState(state => state.contentLang));
    const [state, setState] = useState({
        visible: props.thematic.visible,
    });
    const [color, setColor] = useState<string>(props.thematic.color);
    const [selectedGames, setSelectedGames] = useState<Array<string>>(props.thematic.gameIds);

    const renderEditButton = () => {
        if (props.creationMode) {
            return <></>;
        }
        return (
            <FormControlLabel
                value="edit"
                control={<Switch color="primary" checked={edit} onChange={() => {
                    setEdit(!edit);
                }}/>}
                label="Edit"
            />
        );
    };

    const renderSaveButton = () => {
        if (!edit && !props.creationMode) {
            return <></>;
        }
        return (
            <Button
                className="mt-5 w-25 mx-auto"
                variant="contained"
                color="primary"
                onClick={() => {
                    save();
                }}
            >
                Save
            </Button>
        );
    };

    const save = () => {
        thematic.color = color;
        thematic.gameIds = selectedGames;
        if (edit) {
            TrainingService.updateThematic(thematic).then((data) => {
                if (data) {
                    props.actionHook(true);
                } else {
                    props.actionHook(false);
                }
            });
        } else {
            TrainingService.createThematic(thematic).then(() => {
                props.actionHook(true);
            }).catch(() => {
                props.actionHook(false);
            });
        }
    };

    const onThematicChange = (event: any, isTranslatedString = false) => {
        const {name, value} = event.currentTarget;
        if (isTranslatedString) {
            const myLang = thematic[name as keyof TrainingThematic] as TranslatedString;
            myLang[langValue] = value;
            setThematic(Object.assign({}, thematic, {
                [name.langValue]: value,
            }));
        } else {
            setThematic(Object.assign({}, thematic, {
                [name]: value,
            }));
        }
    };

    const handleSetLang = (lang: string) => {
        setLangValue(lang);
    };

    const handleShowInGraphChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setState({...state, [event.target.name]: event.target.checked});
        thematic.visible = event.target.checked;
        setThematic(thematic);
    };

    const handleGamesChange = (event: any) => {
        setSelectedGames(event.target.value as string[]);
    };

    return (
        <form className="d-flex flex-column">
            {renderEditButton()}
            <div className="row">
                <div className="col-12 px-3 row mb-3 translated-background">
                    <TextField
                        disabled={!edit && !props.creationMode}
                        className="col-12 col-sm-6 my-auto px-3"
                        id='name'
                        name="name"
                        label="Name"
                        value={thematic.name[langValue] || ''}
                        onChange={(event: ChangeEvent<HTMLInputElement>) => (onThematicChange(event, true))}
                    />
                    <div className="col-12 col-sm-6 d-flex justify-content-center">
                        <LangPicker title={'Translate'} setLang={handleSetLang}/>
                    </div>
                </div>
                <TextField
                    disabled={!edit && !props.creationMode}
                    className="col-12 col-sm-6 px-3"
                    id='value'
                    name="value"
                    label="Value"
                    value={thematic.value}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => (onThematicChange(event))}
                />
                <ColorPicker
                    className="col-12 col-sm-2 mx-auto px-3"
                    label="Color"
                    defaultValue={color}
                    onChange={color => color !== undefined ? setColor(color) : 'bonjour'}
                />
                <FormControlLabel
                    disabled={!edit && !props.creationMode}
                    control={<Switch checked={thematic.visible} onChange={handleShowInGraphChange} name="visible"
                                     color="primary"/>}
                    label="Visible"
                    labelPlacement="start"
                    className="col-12 col-sm-1 m-auto px-3"
                />
                <FormControl className="col-12 col-sm-6 px-3">
                    <InputLabel id="demo-mutiple-checkbox-label">Games</InputLabel>
                    <Select
                        disabled={!edit && !props.creationMode}
                        labelId="demo-mutiple-checkbox-label"
                        id="demo-mutiple-checkbox"
                        multiple
                        value={selectedGames}
                        onChange={handleGamesChange}
                        input={<Input/>}
                        renderValue={(selected) => {
                            return (
                                <div>
                                    {
                                        (selected as Array<string>).map((g: string, index: number) => {
                                            const tto = props.gameList && props.gameList.find(game => game._id === g);
                                            return <div className="mx-1" key={index}>{tto ? tto.name : ''}</div>;
                                        })
                                    }
                                </div>
                            );
                        }}
                    >
                        {props.gameList && props.gameList.map((game: Game) => (
                            <MenuItem key={game._id} value={game._id}>
                                <Checkbox checked={selectedGames.indexOf(game._id) > -1}/>
                                <ListItemText primary={game.name}/>
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <div className={(props.creationMode ? 'd-none' : '') + ' col-12 col-sm-6 m-auto px-3'}>
                    <MediaManager
                        path={['training', 'thematic']}
                        id={thematic._id}
                        mediaNames={Object.keys(EThematicsMedia)}
                    />
                </div>
            </div>

            {renderSaveButton()}
        </form>
    );
};