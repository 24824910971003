import axios, { AxiosError } from 'axios';
import { toast } from 'react-toastify';
import { MongoPaginateResult } from 'src/models/PaginateResult';
import { PartnerModel } from 'src/models/Partner';
import { LocalStorageService } from './local-storage.service';

export class PartnerService {
    private static baseUrl: string = `${String(process.env.REACT_APP_ADMINAPI_URL)}/partner`;
    
    static async getPartners(page?: number, sortColumn?: string): Promise<MongoPaginateResult<PartnerModel>> {
        let res;
        const sort: {[key: string]: any} = {};
        if (sortColumn) {
            sort[sortColumn] = 1;
        }

        try {
            res = await axios.get(
                `${PartnerService.baseUrl}`, {
                    params: {
                        page,
                        sort
                    },
                    headers: {
                        'x-access-token': LocalStorageService.getToken(),
                    }
                }
            );
        } catch (e) {
            throw (e as AxiosError).response;
        }

        return res.data;
    }

    static async getAllPartners(): Promise<Array<PartnerModel>> {
        try {
            return (await axios.get(
                `${PartnerService.baseUrl}/all`, {
                    headers: {
                        'x-access-token': LocalStorageService.getToken(),
                    }
                }
            )).data;
        } catch (e) {
            throw (e as AxiosError).response;
        }
    }


    static async getPartner(id: string): Promise<PartnerModel> {
        let res;

        try {
            res = await axios.get(
                `${PartnerService.baseUrl}/${id}`,
            );
        } catch (e) {
            throw (e as AxiosError).response;
        }

        return res.data;
    }

    static async createPartner(partner: PartnerModel): Promise<PartnerModel> {
        let res;

        try {
            res = await axios.post(
                `${PartnerService.baseUrl}`,
                partner,
                {
                    headers: {
                        'x-access-token': LocalStorageService.getToken(),
                    }
                }
            );
        } catch (e) {
            toast.error('Fail to create partner, try again later or contact #platform-public');
            throw (e as AxiosError).response;
        }
        toast.success('Partner created');
        return res.data;
    }

    static async updatePartner(partner: PartnerModel): Promise<boolean> {
        try {
            await axios.put(`${PartnerService.baseUrl}/${partner._id}`, partner, {
                headers: {
                    'x-access-token': LocalStorageService.getToken(),
                }
            });
        } catch (e) {
            toast.error('Fail to update partner, try again later or contact #platform-public');
            return false;
        }
        toast.success('Partner updated');
        return true;
    }

    static async deletePartner(partnerId: string): Promise<void> {
        let res;

        try {
            res = await axios.delete(`${PartnerService.baseUrl}/${partnerId}`, {
                headers: {
                    'x-access-token': LocalStorageService.getToken(),
                }
            });
        } catch (e) {
            toast.error('Fail to delete partner, try again later or contact #platform-public');
            throw (e as AxiosError).response;
        }
        toast.success('Partner deleted');
        return res.data;
    }
}