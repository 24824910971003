export class Voucher {
    constructor(
        public name: string,
        public startDate: Date,
        public endDate: Date,
        public numberGiven: number,
        public numberUsed: number,
        public statusToIncrease: string,
        public numberOfDaysToIncrease: number,
        public operation: string,
        public organisation: string
    ) {}
}