import axios, { AxiosError } from 'axios';
import { HomepageCarouselModel } from 'src/models/HomepageCarousel';

export class HomepageCarouselService {
    private static baseUrl: string = String(process.env.REACT_APP_ADMINAPI_URL);
    static token = (): string | null => localStorage.getItem('accessToken');

    static async getHomepageCarousel(limit: number = 20, page: number = 0, query?: string | undefined) {
        let res;
        try {
            res = await axios.get(
                `${HomepageCarouselService.baseUrl}/homepage/carousel?limit=${limit}&page=${page}${query ? '&countryGroup=' + query : ''}`, {
                    headers: {
                        'x-access-token': HomepageCarouselService.token(),
                    }
                }
            );
        } catch (e) {
            throw (e as AxiosError).response;
        }

        if (!res.data && !res.data.docs) {
            throw Error;
        }

        let carouselList = [];
        carouselList = res.data.docs.map((item: HomepageCarouselModel) => {
            const model = new HomepageCarouselModel(
                item.title,
                item.description,
                item.image,
                item.link,
                item.mobileImage,
                item.video,
                item.countryGroup,
            );
            model.published = item.published;
            model._id = item._id;
            return model;
        });

        return {
            docs: carouselList,
            page: res.data.page,
            total: res.data.total,
        };
    }

    static async createHomepageCarousel(carousel: HomepageCarouselModel): Promise<HomepageCarouselModel> {
        if (!carousel) {
            throw Error;
        }
        let newHomepageCarousel: HomepageCarouselModel;

        try {
            newHomepageCarousel = (await axios.post(
                `${HomepageCarouselService.baseUrl}/homepage/carousel`,
                carousel, {
                    headers: {
                        'x-access-token': HomepageCarouselService.token(),
                    }
                }
            )).data;
        } catch (e) {
            throw (e as AxiosError).response;
        }

        return newHomepageCarousel;
    }

    static async updateHomepageCarousel(carousel: HomepageCarouselModel): Promise<boolean> {
        if (!carousel) {
            return false;
        }

        try {
            await axios.put(
                `${HomepageCarouselService.baseUrl}/homepage/carousel/${carousel._id}`,
                carousel, {
                    headers: {
                        'x-access-token': HomepageCarouselService.token(),
                    }
                }
            );
        } catch {
            return false;
        }

        return true;
    }

    static async deleteHomepageCarousel(id: string): Promise<boolean> {
        if (!id) {
            return false;
        }

        try {
            await axios.delete(
                `${HomepageCarouselService.baseUrl}/homepage/carousel/${id}`, {
                    headers: {
                        'x-access-token': HomepageCarouselService.token(),
                    }
                }
            );
        } catch {
            return false;
        }

        return true;
    }
}