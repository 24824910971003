import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {useTheme} from '@material-ui/core/styles';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import {Button} from '@material-ui/core';

interface ResponsiveDialogProps {
    title: string;
    open: boolean;
    openHook: any;
    actionHook: (choice: boolean) => any;
}

export const ActionDialog: React.FunctionComponent<ResponsiveDialogProps> = (props: ResponsiveDialogProps) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const handleClose = () => {
        props.openHook(false);
        props.actionHook(false);

    };

    const action = () => {
        props.actionHook(true);
        props.openHook(false);
    };

    return (
        <Dialog
            fullScreen={fullScreen}
            open={props.open}
            onClose={handleClose}
            aria-labelledby="responsive-dialog-title"
            className="action-dialog"
            classes={{paperWidthSm: 'min-width=auto'}}
        >
            <DialogTitle className="text-center">{props.title}</DialogTitle>
            <DialogActions className="d-flex align-items-center justify-content-center">
                <Button variant="contained" onClick={handleClose} color="secondary">
                    Cancel
                </Button>

                <Button onClick={action} variant="contained" color="primary">
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>
    );
};