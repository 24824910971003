export enum PremiumStatus {
    NONE = 'NONE',
    TRIAL = 'TRIAL',
    PREMIUM = 'PREMIUM'
}

// Premium class for no-user
export class Premium {
    constructor(
        public status: PremiumStatus,
        public until: Date | null,
    ){}
}

// SentenceType for no-user
export enum SentenceType {
    None = 0,
    Notice = 1,
    Warning = 2,
    Temporary = 3,
    Definitive = 4,
}

// Sentence for no-user
export class Sentence {
    constructor(
        public notice: number,
        public warning: number,
        public type: SentenceType,
        public until: Date | null,
    ){}
}

// Phone structure for no-user
export class Phone {
    public constructor(
        public number: string | null,
        public valid:  boolean,
    ){}
}

// SensitiveUser structure
export class NoUser {
    constructor(
        public email: string,
        public route: string,
        public userId: string,
        public name: string,
        public code: string,
        public valid: boolean,
        public origin: string,
        public reg: Date,
        public ban: Sentence,
        public country: string,
        public premium: Premium,
        public firstName: string | null,
        public lastName: string | null,
        public birthday: Date | null,
        public phone: Phone,
        public hasOptedIn: boolean,
        public lang: string,
        public currency: string
    ){}
}

export enum BanType {
    NONE = 0,
    NOTICE = 1,
    WARNING = 2,
    TEMPORARY = 3,
    DEFINITIVE = 4,
}

export enum BanReason {
    Default = 0,
	Attitude = 1,
	Delay = 2,
	FairPlay = 3,
	Cheat = 4,
}

export class BanHistory {
    constructor(
        public userId: string,
        public route: string,
        public date: Date,
        public until: Date | null,
        public type: BanType,
        public reason: BanReason,
        public message: string | null,
        public admin: string,
    ){}
}