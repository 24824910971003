import { Button, FormControl, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { Administrator } from 'src/models/Administrator';
import { BanHistory, BanReason } from 'src/models/NoUser';
import { NoUserService } from 'src/services/no-user.service';
import { useStoreState } from 'src/store';
import './SanctionForm.scss';
import { BanType, User } from '@wolfiesports/srm-component';

enum SelectType {
    SANCTION = 'sanction',
    REASON = 'reason',
    MOTIF = 'motif',
}

interface SanctionFormProps {
    user: User;
    setOpenBanForm: (isOpen: boolean) => void;
    actionHook: (bh :BanHistory) => void;
}

export const SanctionForm: React.FunctionComponent<SanctionFormProps> = (props: SanctionFormProps) => {
    const sanctionTypes: Array<string> = Object.keys(BanType).filter(f => isNaN(Number(f)));
    const sanctionReasons: Array<string> = Object.keys(BanReason).filter(f => isNaN(Number(f)));
    const [ selectedSanction, setSelectedSanction ] = useState<BanType>(BanType.NONE);
    const [ selectedReason, setSelectedReason ] = useState<BanReason>(BanReason.Default);
    const [ selectedMotif, setSelectedMotif ] = useState<number>(0);
    const [ motifs, setMotifs ] = useState<Array<string>>([]);
    const [ description, setDescription ] = useState<string>('');
    const [ userBan, setUserBan ] = useState<BanHistory>(new BanHistory(props.user.userId, "ban", new Date(), null, BanType.NONE, BanReason.Default, null, ''));
    const admin: Administrator | null = useStoreState(state => state.currentAdministrator);
    const seriousSanctions = [ BanType.WARNING, BanType.TEMPORARY, BanType.DEFINITIVE ];
    const reasons: Array<{ reason: BanReason, motifs: Array<{ motif: string, text: string }> }> = [
        {
            reason: BanReason.Default, motifs: [{ motif: '', text: '' }],
        },
        {
            reason: BanReason.Cheat, motifs: [
                { motif: 'Providing a false proof', text: 'You have provided a false proof, this is totally forbidden and will result in a permanent ban from the site.' },
                { motif: 'Using Glitch', text: 'You used a glitch in the game to your advantage' },
                { motif: 'Use of cheat', text: 'You have used a cheat, this is strictly forbidden and will result in a permanent ban from the site.' },
                { motif: 'Lying in an attempt to cheat', text: 'You intentionally lied in order to cheat' },
                { motif: 'False result validation', text: 'You entered a wrong result' },
                { motif: 'Using mouse and keyboard', text: 'You have used a mouse and keyboard in a tournament reserved for controller players, this provides a considerable advantage and is strictly forbidden' },
                { motif: 'Multiple accounts', text: 'You have more than one Wolfie Sports account, this is strictly forbidden.' },
                { motif: 'Miscellaneous', text: '' },
            ],
        },
        {
            reason: BanReason.FairPlay, motifs: [
                { motif: 'Insulting the opponent', text: 'You insulted your opponent' },
                { motif: 'Lack of respect for the opponent', text: 'You have behaved inappropriately towards your opponent' },
                { motif: 'Lack of respect for the staff', text: 'You behaved inappropriately towards the staff' },
                { motif: 'Spam in a toxic way', text: 'You spammed your opponent in order to disrupt him/her' },
                { motif: 'T-bag', text: 'You t-bagged your openent, this is disrespectful in the game.' },
                { motif: 'Miscellaneous', text: '' },
            ],
        },
        {
            reason: BanReason.Delay, motifs: [
                { motif: 'Intentional delay', text: 'You deliberately delayed your opponent and the tournament' },
                { motif: 'Delays the tournament', text: 'You delayed your match, in the future be careful to limit these delays as they considerably lengthen the duration of tournaments' },
            ],
        },
        {
            reason: BanReason.Attitude, motifs: [
                { motif: 'Abusive complaint', text: 'You accused your opponent without proof, in the future please make sure you can prove what you are complaining about' },
                { motif: 'Leaves the game without telling the opponent or referee', text: 'You left the game without warning the opponent or the staff, this is a lack of respect and a waste of tournament time.' },
                { motif: 'Threatening the opponent or staff', text: 'You have threatened your opponent and/or the staff, this results in a permanent ban from the site' },
                { motif: 'Miscellaneous', text: '' },
            ],
        }
    ];

    const getDateLocal = (date: Date) => {
        return new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString();
    }

    const dateNow = new Date(Date.now());

    useEffect(() => {
        setReasonMotifsSelect(BanReason.Default);
        if (selectedSanction !== BanType.TEMPORARY) {
            userBan.until = null;
        }
        if (selectedSanction === BanType.TEMPORARY && !userBan.until) {
            userBan.until = dateNow;
        }
        // eslint-disable-next-line
    }, [ selectedSanction ]);

    const setReasonMotifsSelect = (value: BanReason) => {
        setSelectedReason(value);
        if (!seriousSanctions.includes(selectedSanction)) {
            setDescription('');
            return;
        }
        if (value === BanReason.Default) {
            setMotifs([]);
            setDescription('');
            return;
        }
        const selectedReasonMotifs = reasons.filter(r => r.reason === value).map(r => r.motifs.map(m => m.motif))[0];
        setMotifs(selectedReasonMotifs);
        setSelectedMotif(0);
        setDescription(reasons.filter(r => r.reason === value)[0].motifs[0].text);
    }

    const onSelectionChange = (value: number, type: SelectType) => {
        switch (type) {
            case SelectType.SANCTION:
                setSelectedSanction(value as BanType);
                break;
            case SelectType.REASON:
                setReasonMotifsSelect(value as BanReason);
                break;
            case SelectType.MOTIF:
                setSelectedMotif(value);
                setDescription(reasons.filter(item => item.reason === selectedReason)[0].motifs[value].text);
                break;
            default:
                break;
        }
    };

    const onDescriptionChange = (value: string) => {
        setDescription(value);
    }

    const onDateChange = (value: string) => {
        userBan.until = new Date(value);
    };

    const close = () => {
        props.setOpenBanForm(false);
    }

    const save = async () => {
        let message = `Are you sure to send a ${sanctionTypes[selectedSanction]} to the user ${props.user.name}`;
        if (seriousSanctions.includes(selectedSanction)) {
            message = message + ` with this reason : ${sanctionReasons[selectedReason]}`;
        }
        if (!window.confirm(message)) {
            return;
        }
        userBan.type = selectedSanction;
        userBan.reason = selectedReason;
        if (admin !== null) {
            userBan.admin = admin.first_name;
        }
        if (selectedSanction !== BanType.TEMPORARY) {
            userBan.until = null;
        }
        userBan.date = new Date(Date.now());
        userBan.userId = props.user.userId;
        userBan.message = description;
        setUserBan(userBan);
        await NoUserService.addBan(userBan);
        props.actionHook(userBan);
        close();
    }

    return (
        <div className="sanction-form w-100 pb-4">
            <FormControl className="w-100 mb-5">
                <InputLabel>{props.user.userId} {props.user.name}</InputLabel>
            </FormControl>
            <FormControl className="w-100 my-2">
                <InputLabel>Sanction</InputLabel>
                <Select label="Sanction" name="sanctions" value={selectedSanction} onChange={(event) =>
                        onSelectionChange(event.target.value as number, SelectType.SANCTION)}>
                    {
                        sanctionTypes.map((sanction: string, index: BanType) => {
                            return (
                                <MenuItem key={index} value={index}>{sanction}</MenuItem>
                            );
                        })
                    }
                </Select>
            </FormControl>
            {
                selectedSanction && seriousSanctions.includes(selectedSanction) ?
                    <FormControl className="w-100 my-2">
                        <InputLabel>Reason</InputLabel>
                        <Select label="Reason" name="reasons" value={selectedReason}
                            onChange={(event) => onSelectionChange(event.target.value as number, SelectType.REASON)}>
                            {
                                sanctionReasons.map((sanction: string, index: BanReason) => {
                                    return (
                                        <MenuItem key={index} value={index}>{sanction}</MenuItem>
                                        );
                                    })
                            }
                        </Select>
                    </FormControl>
                :
                    null
            }
            {
                selectedSanction && seriousSanctions.includes(selectedSanction) && motifs?.length > 0 ?
                    <FormControl className="w-100 my-2">
                        <InputLabel>Motif</InputLabel>
                        <Select label="Motif" name="motifs" value={selectedMotif}
                            onChange={(event) => onSelectionChange(event.target.value as number, SelectType.MOTIF)}>
                            {
                                motifs && motifs.map((motif: string, index: number) => {
                                    return (
                                        <MenuItem key={motif} value={index}>{motif}</MenuItem>
                                        );
                                    })
                            }
                        </Select>
                    </FormControl>
                :
                    null
            }
            {
                selectedSanction && selectedSanction === BanType.TEMPORARY ?
                    <FormControl className="w-100 my-2">
                        <TextField
                            id="datetime-local"
                            label="Banned until"
                            type="datetime-local"
                            defaultValue={getDateLocal(dateNow).substr(0, 16)}
                            onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                onDateChange(event.target.value);
                            }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </FormControl>
                :
                    null
            }
            <FormControl className="w-100 my-2">
                <TextField
                    name="description"
                    label="Description"
                    value={description}
                    multiline
                    rowsMax={4}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => (onDescriptionChange(event.target.value))}
                />
            </FormControl>
            <Button onClick={() => save()} color="primary">Save</Button>
        </div>
    );
};