import axios, { AxiosError } from 'axios';
import { Currency } from 'src/models/Currency';

export interface GetCurrencyQuery {
    active?: boolean;
}

export class CurrencyService {
    private static baseUrl: string = String(process.env.REACT_APP_ADMINAPI_URL);
    static token = (): string | null => localStorage.getItem('accessToken');

    static async getCurrencies(limit: number = 10, page: number = 0, query: GetCurrencyQuery = {}): Promise<any> {
        let res;

        try {
            res = await axios.get(
                `${CurrencyService.baseUrl}/currencies?limit=${limit}&page=${page}&${new URLSearchParams(query as any).toString()}`, {
                    headers: {
                        'x-access-token': CurrencyService.token(),
                    }
                }
            );
        } catch (e) {
            throw (e as AxiosError).response;
        }
        let currenciesList = [];
        if (!res.data && !res.data.docs) {
            throw Error;
        }
        currenciesList = res.data.docs.map((item: any) => {
            return new Currency(
                item._id,
                item.symbol,
                item.picto,
                item.active,
                item.rateFromUSD,
            );
        });
        return {
            docs: currenciesList,
            page: res.data.page,
            total: res.data.total,
            pages: res.data.pages,
        }
    }

    static async getAllCurrencies(): Promise<Array<Currency>> {
        let res: Array<Currency>;

        try {
            res = (await axios.get(
                `${CurrencyService.baseUrl}/currencies/all`, {
                    headers: {
                        'x-access-token': CurrencyService.token(),
                    }
                }
            )).data;
        } catch (e) {
            throw (e as AxiosError).response;
        }

        return res;
    }

    static async updateCurrency(id: string, body: Currency) {
        let res: Currency;

        try {
            res = (await axios.put(
                `${CurrencyService.baseUrl}/currencies/${id}`, body, {
                    headers: {
                        'x-access-token': CurrencyService.token(),
                    }
                }
            )).data;
        } catch (e) {
            throw (e as AxiosError).response;
        }

        return res;
    }

    static async deleteCurrency(_id: string): Promise<boolean> {
        if (!_id) {
            return false
        }

        try {
            await axios.delete(
                `${CurrencyService.baseUrl}/currencies/${_id}`, {
                    headers: {
                        'x-access-token': CurrencyService.token(),
                    }
                }
            );
        } catch {
            return false;
        }

        return true;
    }
}