import React, {ChangeEvent, useEffect, useState} from 'react';
import Button from '@material-ui/core/Button';
import {Company, ECompanyImage} from 'src/models/Company';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import {MenuItem, Select} from '@material-ui/core';
import {Country} from 'src/models/Country';
import {CountryService} from 'src/services/country.service';
import {CompanyService} from 'src/services/company.service';
import {MediaManager} from 'src/components/General/MediaManager/MediaManager';

interface CompanyFormProps {
    actionHook: any;
    company: Company;
    creationMode?: boolean;
}

export const CompanyForm: React.FunctionComponent<CompanyFormProps> = (props: CompanyFormProps) => {
    const [company, setCompany] = useState<Company>(props.company);

    const [countries, setCountries] = useState<Array<Country>>();
    const [selectedCountry, setSelectedCountry] = useState<any>(props.company.country);

    useEffect(() => {
        CountryService.getAllCountries().then((data) => {
            setCountries(data);
        });
    }, []);

    const saveCompany = () => {
        if (company) {
            if (!props.creationMode) {
                CompanyService.updateCompany(company).then(() => {
                    props.actionHook(true);
                }).catch(() => {
                    props.actionHook(false);
                });
            } else {
                CompanyService.createCompany(company).then(() => {
                    props.actionHook(true);
                }).catch(() => {
                    props.actionHook(false);
                });
            }
        }
    };


    const handleSelectedCountry = (event: any) => {
        setSelectedCountry(event.target.value);
        company.country = event.target.value;
        setCompany(company);
    };

    const renderSaveButton = () => {
        return (
            <div className="w-100 mt-5 text-center">
                <Button
                    className="w-25"
                    variant="contained"
                    color="primary"
                    onClick={() => {
                        saveCompany();
                    }}
                >
                    Save
                </Button>
            </div>
        );
    };


    const onFieldChange = (event: any) => {
        const {name, value} = event.currentTarget;
        setCompany(Object.assign({}, company, {
            [name]: value,
        }));
    };

    return (
        <>
            <div className="d-flex flex-row row my-2">
                <div className="col-12 col-sm-6 my-1 px-3">
                    <TextField
                        className="w-100"
                        name="name"
                        label="Name"
                        defaultValue={company ? company.name : ''}
                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                            onFieldChange(event);
                        }}
                    />
                </div>
                <div className="col-12 col-sm-6 my-1 px-3">
                    <TextField
                        className="w-100"
                        name="contact_email"
                        label="Email"
                        defaultValue={company ? company.contact_email : ''}
                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                            onFieldChange(event);
                        }}
                    />
                </div>

                <div className="col-12 col-sm-6 my-1 px-3">
                    <TextField
                        className="w-100"
                        name="address"
                        label="Address"
                        defaultValue={company ? company.address : ''}
                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                            onFieldChange(event);
                        }}
                    />
                </div>

                <div className="col-12 col-sm-6 my-1 px-3">
                    <FormControl className="w-100">
                        <InputLabel id="demo-simple-select-label">Country</InputLabel>
                        <Select
                            label="Countries"
                            name="countries"
                            value={selectedCountry}
                            onChange={handleSelectedCountry}
                        >
                            {
                                countries && countries.map((country: Country) => {
                                    return (
                                        <MenuItem key={Math.random()} value={country._id}>{country.name}</MenuItem>
                                    );
                                })
                            }
                        </Select>
                    </FormControl>
                </div>
                {
                    !props.creationMode &&
                    <div className="col-12 col-sm-6 my-1 px-3">
                        <TextField
                            disabled
                            className="w-100"
                            name="slug"
                            label="Slug"
                            defaultValue={company.slug}
                        />
                    </div>
                }
                {
                    !props.creationMode &&
                    <div className="col-12 mt-4 px-3">
                        <p>Media</p>
                        <MediaManager
                            path={['companies']}
                            id={company.slug}
                            secondPath={['medias']}
                            mediaNames={Object.keys(ECompanyImage)}
                        />
                    </div>
                }
            </div>
            {renderSaveButton()}
        </>
    );
};