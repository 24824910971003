import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import {TransitionProps} from '@material-ui/core/transitions';
import {Button} from '@material-ui/core';
import './ResultDialog.scss';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children?: React.ReactElement },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="right" ref={ref} {...props} />;
});

interface ResultDialogProps {
    open: boolean;
    openHook: any;
    actionHook?: (choice: boolean) => any;
    componentToDisplay: any;
    displayButtons?: boolean;
    currentRound?: number;
}

export const ResultDialog: React.FunctionComponent<ResultDialogProps> = (props: ResultDialogProps) => {
    const handleClose = () => {
        props.openHook(false);
        if (props.actionHook !== undefined) {
            props.actionHook(false);
        }
    };

    return (
        <Dialog open={props.open} onClose={handleClose} TransitionComponent={Transition}>
            <AppBar className="position-relative">
                <Toolbar>
                    <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                        <CloseIcon/>
                    </IconButton>
                    <MuiDialogTitle>
                        Upload Results
                        {
                            props.currentRound &&
                            <div>Round {props.currentRound}</div>
                        }
                    </MuiDialogTitle>
                </Toolbar>
            </AppBar>
            <div className="px-3 px-md-5 pt-2">
                {props.componentToDisplay}
            </div>
            {props.displayButtons && props.actionHook &&
            <DialogActions className="d-flex align-items-center justify-content-center">
                <Button variant="contained" onClick={() => {
                    props.actionHook && props.actionHook(false);
                }} color="secondary">
                    Cancel
                </Button>

                <Button onClick={() => {
                    props.actionHook && props.actionHook(true);
                }} variant="contained" color="primary">
                    Confirm
                </Button>
            </DialogActions>
            }
        </Dialog>
    );
};