import React, {ChangeEvent, useState,} from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import {PremiumService} from 'src/services/premium.service';
import {PremiumProduct} from 'src/models/PremiumProduct';

interface PremiumProductFormProps {
    premiumProduct: any;
    setPremiumProduct: any;
    creationMode?: boolean;
    actionHook: any;
}

export const PremiumProductForm: React.FunctionComponent<PremiumProductFormProps> = (props: PremiumProductFormProps) => {
    const [edit, setEdit] = useState(false);
    const [premiumProduct, setPremiumProduct] = useState<PremiumProduct>(props.premiumProduct);

    if (!premiumProduct) {
        return <></>;
    }

    const onFaqCategoryChange = (event: any) => {
        const {name, value} = event.currentTarget;
        setPremiumProduct(Object.assign({}, premiumProduct, {
            [name]: value,
        }));
    };

    const save = () => {
        PremiumService.createProduct(premiumProduct).then(() => {
            props.actionHook(true);
        });
    };

    const renderSaveButton = () => {
        if (!edit && !props.creationMode) {
            return <></>;
        }
        return (
            <Button
                className="mt-5 w-25 mx-auto"
                variant="contained"
                color="primary"
                onClick={() => {
                    save();
                }}
            >
                Save
            </Button>
        );
    };

    const renderEditButton = () => {
        if (props.creationMode) {
            return <></>;
        }
        return (
            <FormControlLabel
                value="edit"
                control={<Switch color="primary" checked={edit} onChange={() => {
                    setEdit(!edit);
                }}/>}
                label="Edit"
                labelPlacement="start"
            />
        );
    };

    return (
        <form className="d-flex flex-column">
            {renderEditButton()}

            <div className="d-flex flex-row row my-2">
                <TextField
                    disabled={!edit && !props.creationMode}
                    className="col-12 col-sm-6 px-3"
                    id='name'
                    name="name"
                    label="Name"
                    value={premiumProduct.name}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => (onFaqCategoryChange(event))}
                />
            </div>
            {renderSaveButton()}
        </form>
    );
};