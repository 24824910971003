import { User } from '@wolfiesports/srm-component';
import axios, { AxiosError } from 'axios';
import { PremiumPlan } from 'src/models/PremiumPlan';
import { PremiumProduct } from 'src/models/PremiumProduct';
import { PremiumDurationScope } from 'src/models/User';

export class PremiumService {
    private static baseUrl: string = String(process.env.REACT_APP_PRIVATE_SUBSCRIPTION_URL);
    static token = (): string | null => localStorage.getItem('accessToken');

    static async getProducts(): Promise<any> {
        let res;

        try {
            res = await axios.get(`${PremiumService.baseUrl}/products`, {
                headers: {
                    'x-access-token': PremiumService.token(),
                }
            });
        } catch (e) {
            throw (e as AxiosError).response;
        }

        return res.data;
    }

    static async getPlans(): Promise<any> {
        let res;

        try {
            res = await axios.get(`${PremiumService.baseUrl}/plans`, {
                headers: {
                    'x-access-token': PremiumService.token(),
                }
            });
        } catch (e) {
            throw (e as AxiosError).response;
        }

        return res.data;
    }

    static async createProduct(product: PremiumProduct): Promise<any> {
        try {
            await axios.post(`${PremiumService.baseUrl}/products`, product,
                {
                    headers: {
                        'x-access-token': PremiumService.token(),
                    }
                });
        } catch (e) {
            throw (e as AxiosError).response;
        }

        return true;
    }

    static async createPlan(plan: PremiumPlan): Promise<any> {
        try {
            await axios.post(`${PremiumService.baseUrl}/products/${plan.product}/plans/admin`, plan,
                {
                    headers: {
                        'x-access-token': PremiumService.token(),
                    }
                });
        } catch (e) {
            throw (e as AxiosError).response;
        }

        return true;
    }

    static async updatePlanActive(plan: PremiumPlan): Promise<any> {
        try {
            await axios.put(`${PremiumService.baseUrl}/plans/${plan._id}/trigger`, { active: !plan.active },
                {
                    headers: {
                        'x-access-token': PremiumService.token(),
                    }
                });
        } catch (e) {
            throw (e as AxiosError).response;
        }
        return true;
    }

    static async updatePlanCountries(plan: PremiumPlan): Promise<any> {
        try {
            await axios.put(`${PremiumService.baseUrl}/plans/${plan._id}/countries`, { countries: plan.countries || [] },
                {
                    headers: {
                        'x-access-token': PremiumService.token(),
                    }
                });
        } catch (e) {
            throw (e as AxiosError).response;
        }
        return true;
    }

    static async addPremiumToUser(durationScope: PremiumDurationScope, durationTime: number, user: User): Promise<boolean> {
        if (!durationScope || !durationTime || !user) {
            return false;
        }

        const param = {
            type: 'BACKOFFICE.FREE.OFFER',
            resource: {
                billing_agreement_id: user.userId,
                amount: 0,
                create_time: Date.now(),
            },
            duration: durationTime,
            interval: durationScope,
            userId: user.userId,
            email: user.email
        };

        try {
            await axios.post(`${PremiumService.baseUrl}/webhooks/backoffice/admin`, param,
                {
                    headers: {
                        'x-access-token': PremiumService.token(),
                    }
                });
        } catch (e) {
            return false;
        }
        return true;
    }

    static async addTrialToUser() {
        const products = await this.getProducts();
        const productId = products.data.filter((item: { name: string, _id: string }) => item.name === 'trial');

        if (productId[0]._id !== '') {
            try {
                await axios.post(`${PremiumService.baseUrl}/apply/trial/${productId[0]._id}`, {},
                    {
                        headers: {
                            'x-access-token': PremiumService.token(),
                        }
                    });
            } catch (e) {
                throw (e as AxiosError).response;
            }
            return true;
        } else {
            return false;

        }
    }

    static async getSubscription(userId: string) {
        try {
            const res = await axios.get(`${PremiumService.baseUrl}/accounts/${userId}/admin`,
            {
                headers: {
                    'x-access-token': PremiumService.token(),
                }
            });
            return res.data;
        } catch (e) {
            throw (e as AxiosError).response;
        }
    }

    static async deletePremiumPlan(planId: string) {
        try {
            const res = await axios.delete(`${PremiumService.baseUrl}/plans/${planId}`,
                {
                    headers: {
                        'x-access-token': PremiumService.token(),
                    }
                });
            return res.data;
        } catch (e) {
            throw (e as AxiosError).response;
        }
    }

    static async deletePremiumProduct(productId: string) {
        try {
            const res = await axios.delete(`${PremiumService.baseUrl}/products/${productId}`,
                {
                    headers: {
                        'x-access-token': PremiumService.token(),
                    }
                });
            return res.data;
        } catch (e) {
            throw (e as AxiosError).response;
        }

    }
}