import { Icon, IconType } from '@wolfiesports/srm-component';
import {
    CircularProgress,
    Link,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from '@material-ui/core';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';
import { ScrollTopButton } from 'src/components/General/ScrollTopButton/ScrollTopButton';
import { SearchBar } from 'src/components/General/SearchBarNew/SearchBar';
import { TeamAugmented, TeamGame } from 'src/models/Team';
import { TeamService } from 'src/services/team.service';
import { ActionDialog } from '../../../components/General/ActionDialog/ActionDialog';
import { TeamDetails } from '../../../components/Team/TeamDetails/TeamDetails';
import { AdministratorsRole } from '../../../models/Administrator';
import { LastKey } from '../../../models/Common';
import { store } from '../../../store';
import './TeamList.scss';

export const TeamList: React.FunctionComponent = () => {
    const { teamId } = useParams();
    const [teams, setTeams] = useState<Array<TeamAugmented>>([]);
    const [team, setTeam] = useState<TeamAugmented>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [partialLoading, setPartialLoading] = useState<boolean>(false);
    const [lastKey, setLastKey] = useState<LastKey>();
    const [actionDelete, setActionDelete] = useState<boolean>(false);
    const searchFields: {[key: string]: { label: string }} = {
        search: { label: ''},
    }
    const [research, setResearch] = useState<{ [key: string]: string }>();
    const scrollableRef = useRef<HTMLDivElement | null>(null);
    const [showButton, setShowButton] = useState<boolean>(false);

    useEffect(() => {
        if (!teamId) return;

        TeamService.getTeam(teamId).then(async team => {
            const teamAugmented: TeamAugmented = {
                ...team,
                members: (await TeamService.getTeamMembers(team.team)).list,
                favoriteGames: (await TeamService.getTeamGames(team.team)).list,
            }
            setTeams([teamAugmented]);
            setTeam(teamAugmented);
        });
        // eslint-disable-next-line
    }, [teamId]);

    useEffect(() => {
        setTeam(undefined);
        getTeamList(true);
        // eslint-disable-next-line
    }, [research]);

    const getTeamList = async (reset: boolean = false) => {
        if (isLoading || teamId) {
            return;
        }

        setIsLoading(true);
        if (lastKey && !reset) {
            setPartialLoading(true);
        }

        let query = '';
        if (research) {
            Object.keys(research).forEach((item) => {
                query = query.concat(query, `&${item}=${research[item]}`);
            });
        }

        const data = await TeamService.getTeamList(reset ? undefined : lastKey, query);
        const teamList = await Promise.all(data.list.map(async (t: any) => {
            t.members = (await TeamService.getTeamMembers(t.team)).list;
            t.favoriteGames = (await TeamService.getTeamGames(t.team)).list;
            return t;
        }));

        setIsLoading(false);
        setPartialLoading(false);

        if (lastKey && !reset) {
            setTeams(teams.concat(teamList));
        } else {
            setTeams(teamList);
        }

        if (data.lastKey) {
            setLastKey(data.lastKey);
        } else {
            setLastKey(undefined);
        }
    }

    const actionManager = async (team: TeamAugmented, kind: string) => {
        setTeam(team);

        switch (kind) {
            case 'delete':
                setActionDelete(true);
                break;
            case 'create': {
                break;
            }
            default:
                return;
        }
    };

    const deleteTeam = async (choice: boolean) => {
        if (choice && team) {
            const res = await TeamService.deleteTeam(team.team);
            if (!res) {
                toast.error('Failed to delete team');
                updateList(false);
                return;
            }
            toast.success('Team deleted');
            updateList(true);
        }
    }

    const updateList = (result: boolean) => {
        setTeam(undefined);
        setActionDelete(false);

        if (result) {
            getTeamList();
        }
    }

    const handleScroll = (e: any) => {
        if (team) {
            return;
        }

        setShowButton(e.target.scrollTop > 300);
        if (lastKey && e.target.scrollHeight - e.target.scrollTop - window.screen.height < 1000) {
            getTeamList();
        }
    }

    const scrollTop = (clicked: boolean) => {
        if (!clicked || !scrollableRef) {
            return;
        }
        scrollableRef.current?.scrollTo(0, 0);
    }

    return (
        <div
            className="page-container p-4 overflow-auto"
            style={{backgroundImage: `url(${process.env.REACT_APP_S3_URL}/game/5d31aa9684d0814f4c04bbd5/medias/BackgroundImage`}}
            onScroll={(e) => handleScroll(e)}
            ref={scrollableRef}
        >
            <div>
                <h5 className="mt-3">{team ? "Team management" : "Team list"}</h5>
                <div className="my-5">
                    <SearchBar searchFields={ searchFields } actionHook={ setResearch }/>
                </div>
                <div className="d-flex flex-column mt-5">
                    {
                        (!isLoading || partialLoading) && teams.length > 0 &&
                        <div className="w-100 d-flex flex-column mb-4">
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                            <TableRow>
                                                <TableCell align="left">Tag</TableCell>
                                                <TableCell align="left">Name</TableCell>
                                                <TableCell align="left">Captain</TableCell>
                                                <TableCell align="left">Members</TableCell>
                                                <TableCell align="left">Favorite games</TableCell>
                                                <TableCell align="left">Creation date</TableCell>
                                                <TableCell align="center">Actions</TableCell>
                                            </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            teams.map((t: TeamAugmented) => {
                                                return (
                                                    <TableRow key={t.team}>
                                                        <TableCell align="left" className="text-uppercase font-weight-bold">{t.tag}</TableCell>
                                                        <TableCell align="left">{t.name}</TableCell>
                                                        <TableCell align="left">
                                                            <div className="d-flex owner">
                                                                <div className="avatar-container">
                                                                    { t &&
                                                                        <img
                                                                            alt=""
                                                                            className="avatar"
                                                                            src={ `${process.env.REACT_APP_S3_URL}/media/assets/user/profile/${t.owner.avatar}` }
                                                                            onError={ (e) => e.currentTarget.src = `${process.env.REACT_APP_S3_URL}/media/default/default-user-avatar.svg` }
                                                                        />
                                                                    }
                                                                </div>
                                                                <span className="my-auto ml-2 mr-5">{t.owner.name}</span>
                                                            </div>
                                                        </TableCell>
                                                        <TableCell align="left">{t.members.length}</TableCell>
                                                        <TableCell align="left">
                                                            <div className="game-container">
                                                                <div
                                                                    className="d-flex marquee-container"
                                                                    style={{width: `${t.favoriteGames.length * 40}px`}}
                                                                >
                                                                    <div
                                                                        className={`d-flex marquee-slider ${t.favoriteGames.length > 4 ? 'slidable' : ''}`}
                                                                        style={{animationDuration: `${t.favoriteGames.length}s`}}
                                                                    >
                                                                    {
                                                                        t.favoriteGames.map((game: TeamGame) => {
                                                                            return (
                                                                                <div
                                                                                    key={game.name}
                                                                                    className="icon mx-2"
                                                                                    style={{
                                                                                        maskImage: `url(${process.env.REACT_APP_S3_URL}/${game.route.replace('games', 'game')}/medias/IconImage)`,
                                                                                        WebkitMaskImage:`url(${process.env.REACT_APP_S3_URL}/${game.route.replace('games', 'game')}/medias/IconImage)`
                                                                                    }}
                                                                                ></div>
                                                                            )
                                                                        })
                                                                    }
                                                                        {
                                                                        t.favoriteGames.map((game: TeamGame) => {
                                                                            return (
                                                                                <div
                                                                                    key={game.name}
                                                                                    className="icon mx-2"
                                                                                    style={{
                                                                                        maskImage: `url(${process.env.REACT_APP_S3_URL}/${game.route.replace('games', 'game')}/medias/IconImage)`,
                                                                                        WebkitMaskImage:`url(${process.env.REACT_APP_S3_URL}/${game.route.replace('games', 'game')}/medias/IconImage)`
                                                                                    }}
                                                                                ></div>
                                                                            )
                                                                        })
                                                                    }
                                                                    </div>
                                                                </div>
                                                                {
                                                                    t.favoriteGames.length > 4 &&
                                                                    <div className="plus">+</div>
                                                                }
                                                            </div>
                                                        </TableCell>
                                                        <TableCell align="left">{moment(Date.parse(t.date)).format('L').toString()}</TableCell>
                                                        <TableCell align="center" className="d-flex justify-content-around">
                                                            <div
                                                                className="icon"
                                                                style={{
                                                                    maskImage: `url(${process.env.REACT_APP_S3_URL}/media/icons/trashcan.svg)`,
                                                                    WebkitMaskImage:`url(${process.env.REACT_APP_S3_URL}/media/icons/trashcan.svg)`
                                                                }}
                                                                onClick={() => { actionManager(t, 'delete') }}
                                                            ></div>

                                                            <a
                                                                href={ `${process.env.REACT_APP_PLATFORM_URL}/team/profile/${t.team}` }
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                                className="icon"
                                                                style={{
                                                                    maskImage: `url(${process.env.REACT_APP_S3_URL}/media/icons/goToLink.svg)`,
                                                                    WebkitMaskImage:`url(${process.env.REACT_APP_S3_URL}/media/icons/goToLink.svg)`
                                                                }}
                                                            > </a>

                                                            {   store.getState().currentAdministrator?.role.includes(AdministratorsRole.NCADMIN) &&
                                                                <Link href={team ? `/teams` : `/teams/${t.team}`}>
                                                                    <Icon icon={team ? IconType.Back : IconType.Settings} width={24} height={24} />
                                                                </Link>
                                                            }
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            })
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            {
                                showButton && !team &&
                                <div className="scroll-button">
                                    <ScrollTopButton size={ 62 } actionHook={ scrollTop }/>
                                </div>
                            }
                        </div>
                    }
                    {
                       isLoading && <CircularProgress className="mx-auto"/>
                    }
                </div>
                <ActionDialog
                    open={actionDelete}
                    title={`Are you sure to delete this team: ${team?.name}`}
                    openHook={setActionDelete}
                    actionHook={deleteTeam}
                />
            </div>
            { team &&
                <TeamDetails team={team}></TeamDetails>
            }
        </div>
    );
}