import { v4 as uuidv4 } from 'uuid';
import {EventMessage, MessageType, SenderType} from 'src/models/ChatMessage';
import { Administrator } from 'src/models/Administrator';

// import ReconnectingEventSource from "reconnecting-eventsource";
type MessageListener = (room: string, data: EventMessage) => void;

type AccessTokenCallBack = () => string | null;

const ping = (ws: WebSocket | null) => {
    if (!ws || ws.readyState !== WebSocket.OPEN) {
        return;
    }
    ws.send(JSON.stringify({ action: "ping" }));
};

class EventSourceSingletonRT {
    private websocket: WebSocket | null;
    private callbacksByAction: { [type in MessageType]?: { [room: string]: MessageListener } };
    private pingInterval: NodeJS.Timeout;
    private accessTokenCallback: AccessTokenCallBack;
    private bufferedJoins: Array<string> = [];
    private joinedRooms:{ [roomId: string]: number} = {};

    public constructor() {
        this.websocket = null;
        this.callbacksByAction = {};
    }

    private updateEventSource = () => {
        if (this.websocket !== null && this.websocket.readyState < WebSocket.CLOSING) {
            return;
        }

        const token = this.accessTokenCallback();
        if (!token) {
            return;
        }

        if (this.websocket !== null) {
            this.websocket.close();
        }

        if (this.pingInterval) {
            clearInterval(this.pingInterval);
        }

        if (Object.keys(this.callbacksByAction).length > 0) {
            this.websocket = new WebSocket(`${process.env.REACT_APP_RT_WS_URL}?connectionType=admin&token=${token}`);
            this.websocket.addEventListener('open', this.onOpen);
            this.websocket.addEventListener('message', this.onMessage);
            this.pingInterval = setInterval(() => ping(this.websocket), 2 * 60 * 1000);
        } else {
            this.websocket = null;
        }
    };

    private onOpen = () => {
        while (this.bufferedJoins.length > 0) {
            const room = this.bufferedJoins.pop();
            if (!room) {
                break;
            }

            this.joinRoom(room);
        }
    }

    private onMessage = (messageEvent: MessageEvent) => {
        const message = JSON.parse(messageEvent.data) as EventMessage;
        const { type, room } = message;
        const callbacks = this.callbacksByAction[type];
        const srmCallbacks = this.callbacksByAction["srm"];

        if (!callbacks && !srmCallbacks) {
            return;
        }
        if (callbacks) {
            const listener = callbacks[room];
            if (!listener) {
                return;
            }
            listener(room, message);
        }
        if (srmCallbacks) {
            const listener = srmCallbacks[room];
            if (!listener) {
                return;
            }
            listener(room, message);
        }
    }

    public sendMessage = (reason: MessageType, roomId: string, message: string, admin: Administrator): EventMessage | null => {
        if (!this.websocket || this.websocket.readyState !== WebSocket.OPEN) {
            return null;
        }
        const msg: EventMessage = {
            room: roomId,
            target: '',
            type: reason,
            content: {
                message: message,
                id: uuidv4(),
            },
            sender: admin._id,
            senderType: SenderType.Admin,
            senderName: 'ADMIN',
            date: new Date().toISOString(),
        };
        this.websocket.send(JSON.stringify({ ...msg, action: 'event' }));
        return msg;
    }

    private joinRoom = (room: string) => {
        if(!this.websocket) {
            return;
        }
        if(this.joinedRooms[room]) {
            return;
        }
        if (this.websocket.readyState !== WebSocket.OPEN) {
            this.bufferedJoins.push(room);
        } else {
            this.websocket.send(JSON.stringify({
                action: "join",
                room: room
            }));
            if(this.joinedRooms[room]) {
                this.joinedRooms[room] ++;
            } else {
                this.joinedRooms[room] = 1;
            }
        }
    };

    public subscribe = (type: MessageType, room: string, accessTokenCallback: AccessTokenCallBack, callback: MessageListener): void => {
        this.accessTokenCallback = accessTokenCallback;
        if (!this.callbacksByAction[type]) {
            this.callbacksByAction[type] = {};
        }
        const callbacks = this.callbacksByAction[type]!;
        if(!callbacks[room]) {
            callbacks[room] = callback;
            this.updateEventSource();
            this.joinRoom(room);
        }
    }

    public subscribeSRM = (room: string, accessTokenCallback: AccessTokenCallBack, callback: MessageListener): void => {
        this.accessTokenCallback = accessTokenCallback;
        if (!this.callbacksByAction["srm"]) {
            this.callbacksByAction["srm"] = {};
        }
        const callbacks = this.callbacksByAction["srm"]!;
        if(!callbacks[room]) {
            callbacks[room] = callback;
            this.updateEventSource();
            this.joinRoom(room);
        }
    }

    public unsubscribe = (type: MessageType, room: string): void => {
        const callbacks = this.callbacksByAction[type];
        if (!callbacks) {
            return;
        }

        delete callbacks[room];
        if(this.joinedRooms[room]) {
            this.joinedRooms[room]--;
            if(this.joinedRooms[room] <= 0) {
                delete this.joinedRooms[room];
            }
        }
        if (Object.keys(callbacks).length <= 0) {
            delete this.callbacksByAction[type];
        }

        this.updateEventSource();
    }

    public unsubscribeSRM = (room: string): void => {
        const callbacks = this.callbacksByAction["srm"];
        if (!callbacks) {
            return;
        }

        delete callbacks[room];
        if(this.joinedRooms[room]) {
            this.joinedRooms[room]--;
            if(this.joinedRooms[room] <= 0) {
                delete this.joinedRooms[room];
            }
        }
        if (Object.keys(callbacks).length <= 0) {
            delete this.callbacksByAction["srm"];
        }

        this.updateEventSource();
    }
}

const eventSourceSingletonRT = new EventSourceSingletonRT();

export default eventSourceSingletonRT;
