import { Accordion, Button, FormControl, FormControlLabel, IconButton, InputLabel, List, ListItem, ListItemSecondaryAction, ListItemText, MenuItem, Select, Switch, TextField } from '@material-ui/core';
import { EModuleImage, TrainingModule } from 'src/models/TrainingModule';
import React, { ChangeEvent, useEffect, useState } from 'react';

import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import { Company } from 'src/models/Company';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { GamePartial } from 'src/models/GamePartial';
import { MediaManager } from 'src/components/General/MediaManager/MediaManager';
import { ProgramLevel } from 'src/models/TrainingProgram';
import { BrickType, ComparisonOperatorLUT, Kind, Operator, PredicateCondition, PredicateEditionAugmented, TrainingBrickAssignement, Type } from 'src/models/TrainingBrick';
import { ModalAddPresets } from 'src/components/Training/Presets/ModalAddPresets';
import { TrainingService } from 'src/services/training.service';
import { TrainingPreset } from 'src/models/TrainingPreset';
import DeleteIcon from '@material-ui/icons/Delete';
import CreateIcon from '@material-ui/icons/Create';
import { TranslatedString } from 'src/models/TranslatedString';

interface ModuleInformationProps {
    module: TrainingModule;
    primaryBlock: any;
    lang: string;
    games: Array<GamePartial>;
    isMenuBrick: boolean;
    companies: Array<Company>;
    isPublished: boolean;
    onGameChange: (game: string) => void;
    setUpdateModule: () => void;
    onMenuChange: () => void;
    toCapitalizeCase: (text: string) => string;
    onPrerequisiteChange: (selectedPrerequisite: PredicateEditionAugmented | undefined) => void;
}

export const ModuleInformation: React.FunctionComponent<ModuleInformationProps> = (props: ModuleInformationProps) => {
    const levels = Object.keys(ProgramLevel);
    const emptyPredicate: PredicateEditionAugmented = { path: '', description: {}, operator: Operator.eq, options: { kind: Kind.String }, type: Type.COMPARISON, predicateOptionsHandlers: {} };    const [ selectedGame, setSelectedGame ] = useState<GamePartial | string>();
    const [ selectedLevel, setSelectedLevel ] = useState<ProgramLevel>();
    const [ selectedCompany, setSelectedCompany ] = useState<string>();
    const [ checkComingSoon, setComingSoon ] = useState<boolean>(true);
    const [ newModule, setUpdateModule ] = useState<TrainingModule>(props.module);
    const [ presetList, setPresetList ] = useState<Array<TrainingPreset>>([]);
    const [ openDialog, setOpenDialog] = useState<boolean>(false);
    const [ modulePrerequisites, setModulePrerequisites ] = useState<Array<any>>([]);
    const [ assignementCount, setAssignementCount] = useState<number>(0);
    const [ viewAll, setViewAll] = useState<boolean>(false);
    const [ selectedPrerequisite, setSelectedPrerequisite] = useState<PredicateEditionAugmented | undefined>();
    const [ modalPredicate, setModalPredicate ] = useState<PredicateEditionAugmented>(emptyPredicate);
    const [ predicateIndex, setPredicateIndex ] = useState<number>(-1);
    const [ oldPredicate, setOldPredicate ] = useState<PredicateEditionAugmented>(emptyPredicate);

    useEffect(() => {
        setUpdateModule(props.module);
        setSelectedGame(props.module.game);
        setSelectedLevel(props.module.level);
        setSelectedCompany(props.module.company);
        setComingSoon(props.module.comingSoon);
        if (props.module.game) {
            TrainingService.getPresets(500, 0, `&game._id=${props.module.game}`).then((data: any) => {
                setPresetList(data.docs);
            });
        }
    }, [ props.module ]);

    useEffect(() => {
        if (props.primaryBlock && props.primaryBlock.length > 0) {
            getModulePrerequisites();
        }
        // eslint-disable-next-line
    }, [ props.primaryBlock, props.onMenuChange ]);

    if (!newModule) {
        return <></>;
    }

    const onModuleChange = (event: any, translated = false) => {
        const {name, value} = event.currentTarget;
        if (translated) {
            const myNewModuleAttr = newModule[name as keyof TrainingModule] as TranslatedString;
            myNewModuleAttr[props.lang] = value;
            setUpdateModule(newModule);
            if (newModule._id) {
                setUpdateModule(Object.assign({}, newModule, {
                    [name.lang]: value,
                }));
            }
        }
    };

    const onGameChange = (game: string) => {
        newModule.game = game;
        props.onGameChange(game);
        setSelectedGame(game);
        setUpdateModule(newModule);
    };

    const onLevelChange = (level: ProgramLevel) => {
        newModule.level = level;
        setSelectedLevel(level);
        setUpdateModule(newModule);
    }

    const onComingSoonChange = (check: boolean) => {
        newModule.comingSoon = check;
        setComingSoon(check);
        setUpdateModule(newModule);
    };

    const onCompanyChange = (company: string) => {
        newModule.company = company;
        setSelectedCompany(company);
        setUpdateModule(newModule);
    }

    const refreshPrerequisites = (type: PredicateCondition, predicate: PredicateEditionAugmented) => {
        if (type !== PredicateCondition.PREREQUISITE) {
            return;
        }

        if (TrainingService.comparePredicate(oldPredicate, emptyPredicate)) {
            setBrickPrerequisite(props.primaryBlock[0], predicate);
        } else {
            setBrickPrerequisite(props.primaryBlock[0], predicate, oldPredicate);
        }
        getModulePrerequisites();
    };

    const setBrickPrerequisite = (brick: any, predicate: PredicateEditionAugmented, oldPredicate?: PredicateEditionAugmented) => {
        if (brick.type === BrickType.ASSIGNEMENT) {
            if (oldPredicate) {
                const index = findPredicateIndex(oldPredicate, JSON.parse(JSON.stringify((brick as TrainingBrickAssignement).startCondition.members)));
                if (index > -1) {
                    (brick as TrainingBrickAssignement).startCondition.members[index] = predicate;
                }
            } else {
                (brick as TrainingBrickAssignement).startCondition.members.push(predicate);
            }
        } else if (brick.children && brick.children.length > 0) {
            for (const child of brick.children) {
                setBrickPrerequisite(child, predicate, oldPredicate);
            }
        }
    }

    const getBrickPrerequisite = (brick: any, count: number) => {
        if (brick.type === BrickType.ASSIGNEMENT) {
            count++;
            for (const member of brick.startCondition.members) {
                const index = findPredicateIndex(member, modulePrerequisites);
                if (index > -1) {
                    modulePrerequisites[index].count++;
                } else {
                    member.count = 1;
                    modulePrerequisites.push(member);
                }
                modulePrerequisites.sort((predicateA, predicateB) => predicateB.count - predicateA.count);
                setModulePrerequisites(modulePrerequisites);
            }
        } else if (brick.children && brick.children.length > 0) {
            for (const child of brick.children) {
                count = getBrickPrerequisite(child, count);
            }
        }
        return count;
    }

    const removeBrickPrerequisite = (brick: any, predicate: PredicateEditionAugmented) => {
        if (brick.type === BrickType.ASSIGNEMENT) {
            const index = findPredicateIndex(predicate, (brick as TrainingBrickAssignement).startCondition.members);
            if (index > -1) {
                (brick as TrainingBrickAssignement).startCondition.members.splice(index, 1);
            }
        } else if (brick.children && brick.children.length > 0) {
            for (const child of brick.children) {
                removeBrickPrerequisite(child, predicate);
            }
        }
    }

    const findPredicateIndex = (predicate: PredicateEditionAugmented, startConditions: Array<PredicateEditionAugmented>) => {
        for (let i = 0; i < startConditions.length; i++) {
            if (TrainingService.comparePredicate(predicate, startConditions[i])) {
                return i;
            }
        }
        return -1;
    }

    const deletePrerequisite = (index: any) => {
        removeBrickPrerequisite(props.primaryBlock[0], modulePrerequisites[index]);
        const prerequisites = JSON.parse(JSON.stringify(modulePrerequisites));
        prerequisites.splice(index, 1);
        setModulePrerequisites(prerequisites);
    }

    const getModulePrerequisites = () => {
        modulePrerequisites.splice(0, modulePrerequisites.length);
        setModulePrerequisites([]);
        const count = getBrickPrerequisite(props.primaryBlock[0], 0);
        setAssignementCount(count);
    }

    const savePrerequisites = () => {
        TrainingService.updateBlock(props.primaryBlock[0]._id, props.primaryBlock[0]);
    }

    const selectedPrerequisiteChange = (item: PredicateEditionAugmented | undefined) => {
        setSelectedPrerequisite(item);
        props.onPrerequisiteChange(item);
    }

    const openModal = (data: PredicateEditionAugmented = emptyPredicate, index: number = -1) => {
        setOldPredicate(JSON.parse(JSON.stringify(data)));
        setModalPredicate(data);
        setPredicateIndex(index);
        setOpenDialog(true);
    };

    return (
        <>
        <div className="module-information w-100">
            <Accordion expanded={!props.isMenuBrick} key={newModule._id} onChange={() => props.onMenuChange()}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon/>}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                >
                    Module Information
                </AccordionSummary>
                <AccordionDetails className="d-flex flex-column">
                    <FormControl className="w-100 mb-4">
                        <TextField
                            name="name"
                            label="Title (mandatory)"
                            value={newModule.name[props.lang]}
                            onChange={(event: ChangeEvent<HTMLInputElement>) => (onModuleChange(event, true))}
                        />
                    </FormControl>
                    <FormControl className="w-100 mb-4">
                        <TextField
                            name="description"
                            label="Description (mandatory)"
                            multiline
                            value={newModule.description[props.lang]}
                            onChange={(event: ChangeEvent<HTMLInputElement>) => (onModuleChange(event, true))}
                        />
                    </FormControl>
                    <FormControl className="w-100 mb-4">
                        <InputLabel shrink>Game</InputLabel>
                        <Select
                            name="game"
                            value={selectedGame || ''}
                            onChange={(event) => {onGameChange(event.target.value as string)}}
                        >
                            {
                                props.games.map((game: any, index: number) => {
                                    return <MenuItem key={index} value={game._id}>{game.name}</MenuItem>;
                                })
                            }
                        </Select>
                    </FormControl>
                    <FormControl className="w-100 mb-4">
                        <InputLabel shrink>Level</InputLabel>
                        <Select
                            name="level"
                            value={selectedLevel || ''}
                            onChange={(event) => {onLevelChange(event.target.value as ProgramLevel)}}
                        >
                            <MenuItem key={0} value=''>NONE</MenuItem>
                            {
                                levels.map((level: string, index: number) => {
                                    return <MenuItem key={index} value={level}>{level}</MenuItem>;
                                })
                            }
                        </Select>
                    </FormControl>
                    <FormControl className="w-100 mb-4">
                        <InputLabel>Company</InputLabel>
                        <Select
                            label="Company"
                            name="company"
                            value={selectedCompany || ''}
                            onChange={(event) => {onCompanyChange(event.target.value as string)}}
                        >
                            <MenuItem value=''>None</MenuItem>
                            {
                                props.companies && props.companies.map((company: Company, index: number) => {
                                    return (
                                        <MenuItem key={index} value={company.slug}>
                                            {company.name}
                                        </MenuItem>
                                    );
                                })
                            }
                        </Select>
                    </FormControl>
                    <FormControlLabel control={
                        <Switch
                            disabled={props.isPublished ||assignementCount < 1}
                            color="primary"
                            checked={!props.isPublished && checkComingSoon}
                            onChange={(event) => {
                                onComingSoonChange(event.currentTarget.checked);
                            }}
                        />}
                        label="Coming Soon"
                    />
                    {
                        newModule && newModule._id ?
                            <FormControl className="w-100 my-4">
                                <InputLabel className="mb-5" shrink>
                                    Image
                                </InputLabel>
                                <div className="w-100">
                                    <MediaManager
                                        path={ ['training', 'module'] }
                                        id={ newModule._id }
                                        secondPath={ ['medias'] }
                                        mediaNames={ Object.keys(EModuleImage) }
                                        width={'w-100'}
                                    />
                                </div>
                            </FormControl>
                        : null
                    }
                    {
                        assignementCount > 0 &&
                        <Button className="m-2" variant="contained" color="secondary" onClick={() => openModal()}>
                            Add prerequisite
                        </Button>
                    }
                    <FormControl className="w-100">
                        <List>
                            {modulePrerequisites && modulePrerequisites.length > 0 && modulePrerequisites.map((item, index) =>
                            (item.count === assignementCount  || viewAll) &&
                            <ListItem
                                key={index}
                                onMouseOver={() => selectedPrerequisiteChange(item)}
                                onMouseLeave={() => selectedPrerequisiteChange(undefined)}
                                className={selectedPrerequisite === item ? 'primary-color' : ''}
                            >
                                <ListItemText primary={`${item.path} ${ComparisonOperatorLUT[item.operator as Operator]} ${item.value}`}
                                    secondary={item.description[props.lang]} />
                                <ListItemSecondaryAction className="d-flex">
                                    <div className={`my-auto ${selectedPrerequisite === item ? 'primary-color' : ''}`}>{item.count} / {assignementCount}</div>
                                    <IconButton edge="end" aria-label="update" onClick={() => openModal(item, index)}>
                                        <CreateIcon />
                                    </IconButton>
                                    <IconButton edge="end" aria-label="delete" onClick={() => deletePrerequisite(index)}>
                                        <DeleteIcon />
                                    </IconButton>
                                </ListItemSecondaryAction>
                            </ListItem>
                            )}
                        </List>
                    </FormControl>
                    {
                        modulePrerequisites.filter(item => item.count === assignementCount).length < modulePrerequisites.length &&
                        <Button onClick={() => setViewAll(!viewAll)}>{`View ${viewAll ? 'less' : 'all'}`}</Button>
                    }
                    {
                        assignementCount > 0 &&
                        <Button className="m-2 mt-4" variant="contained" color="primary" onClick={() => savePrerequisites()}>Save all bricks</Button>
                    }
                </AccordionDetails>
            </Accordion>
            {
                <ModalAddPresets
                    open={openDialog}
                    type={PredicateCondition.PREREQUISITE}
                    presets={presetList}
                    lang={props.lang}
                    setOpenDialog={setOpenDialog}
                    predicate={modalPredicate}
                    predicateIndex={predicateIndex}
                    toCapitalizeCase={props.toCapitalizeCase}
                    actionHook={refreshPrerequisites}
                />
            }
        </div>
        </>
    );
}
