import Chip from '@material-ui/core/Chip';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import SearchIcon from '@material-ui/icons/Search';
import React, {useState} from 'react';
import './SearchBar.scss';

// remove searchResult and SetSearch result because actionHook provide research obj
// remove when tournament part is done
// remove the optional actionHook

interface SearchBarProps {
    searchResult?: any;
    setSearchResult?: React.Dispatch<any>;
    searchFields: { [key: string]: SearchField };
    actionHook?: (search?: any) => any;
}

interface SearchField {
    label: string
}

export const SearchBar: React.FunctionComponent<SearchBarProps> = (props: SearchBarProps) => {
    const [searchText, setSearchText] = useState<string>('');
    const [searchField, setSearchField] = useState<string>(Object.keys(props.searchFields)[0]);
    const [searchStore, setSearchStore] = useState<{ [key: string]: string }>(props.searchResult || {});

    const handleKeyDown = (e: any) => {
        if (e.keyCode === 13) {
            doSearch();
        }
    };

    const doSearch = () => {
        const textTrimed = searchText.trim();
        searchStore[searchField] = textTrimed;
        setSearchStore({...searchStore});
        if (props.setSearchResult) {
            props.setSearchResult(searchStore);
        }
        if (props.actionHook) {
            props.actionHook(searchStore);
        }
        setSearchText('');
    }

    const removeItemFromStore = (key: string) => {
        delete searchStore[key];
        setSearchStore({...searchStore});
        if (props.setSearchResult) {
            props.setSearchResult(searchStore);
        }
        if (props.actionHook) {
            if (props.actionHook.length) {
                props.actionHook(searchStore);
                return;
            }
            props.actionHook();
        }

    }

    const onTextChange = (event: any) => {
        setSearchText(event.currentTarget.value)
    }

    return (
        <>
            <div className="w-100 search-bar-container p-2">
                <div className="search-bar-input-container d-flex w-100">
                    <Select
                        value={searchField}
                        className="mx-3"
                        onChange={(event: any) => {
                            setSearchField(event.target.value)
                        }}
                    >
                        {
                            Object.keys(props.searchFields).map((key: any, index: number) => {
                                return <MenuItem key={index} value={key}>{props.searchFields[key].label}</MenuItem>
                            })
                        }
                    </Select>
                    <TextField
                        label="..."
                        value={searchText}
                        name="searchBar"
                        className="w-100"
                        onChange={(event) => {
                            onTextChange(event)
                        }}
                        onKeyDown={handleKeyDown}
                    />
                    <div
                        className="search-button d-flex justify-content-center align-items-center mx-3"
                        onClick={() => {
                            doSearch()
                        }}
                    >
                        <SearchIcon fontSize="large"/>
                    </div>
                </div>
                <div className="d-flex mt-2">
                    {
                        Object.keys(searchStore).map((key: any, index: number) => {
                            return (
                                <Chip
                                    label={`${key}: ${searchStore[key]}`}
                                    key={index}
                                    onDelete={() => removeItemFromStore(key)}
                                    color="primary"
                                    className="ml-2"
                                />
                            );
                        })
                    }
                </div>
            </div>
        </>
    );
}