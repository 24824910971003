import { NCPreviewSearch, SearchBar } from '@wolfiesports/srm-component';
import { CircularProgress, Tooltip } from '@material-ui/core';
import { User } from '@wolfiesports/srm-component';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { NCTitle } from 'src/atoms/NCTitle/NCTitle';
import { ActionDialog } from 'src/components/General/ActionDialog/ActionDialog';
import { SimpleList } from 'src/components/General/SimpleList/SimpleList';
import { NoUser } from 'src/models/NoUser';
import { Organization } from 'src/models/Organization';
import { NCCommonService } from 'src/services/ncCommon.service';
import { NoUserService } from 'src/services/no-user.service';
import { UserService } from 'src/services/user.service';

export const Users: React.FunctionComponent = () => {
    const [user, setUser] = useState<NoUser>();
    const [isLoading, setIsLoading] = useState(true);
    const [rows, setRows] = useState<Array<NoUser> | Array<User>>([]);
    const [actionDelete, setActionDelete] = useState<boolean>(false);
    const [actualPage, setActualPage] = useState<number>(0);
    const columns = ['Nickname', 'Code' ,'Email', 'Origin' , 'Is Valid', 'Is Premium', 'Is Ban' ];
    const keepRows = ['name', 'code' ,'email', 'origin' ,'valid' , 'premium' , 'ban' ];
    const searchFields: {[key: string]: { label: string }} = {
        email: { label: 'Email'},
        nickname: { label: 'Nickname'},
        _id: { label: 'ID' },
    }
    const loader = useRef<HTMLDivElement>(null);
    const [ research, setResearch ] = useState<{ [key: string]: string }>();
    const [typeOfSelect, setTypeOfSelect] = useState<string>('email');
    const [ lastKey, setLastKey ] = useState<any>(undefined);
    const [ selectedOrganization, setSelectedOrganization ] =useState<string>('wolfiesports');
    const [ organizations, setOrganizations ] = useState<Array<Organization>>([]);

    useEffect(() => {
        getUsers(true);
    }, [research, typeOfSelect]);

    useEffect(() => {
        getOrganizationUsers();
    }, [selectedOrganization]);

    const scrollObserver = useCallback((entries: any) => {
        if (entries[0].isIntersecting) {
            setActualPage((no) => no + 1);
        }
    }, []);

    useEffect(() => {
        const observer = new IntersectionObserver(scrollObserver, {});
        if (loader.current !== null) {
            observer.observe(loader.current);
        }
    }, [scrollObserver]);

    
    useEffect(() => {
        if (lastKey && actualPage > 1) {
            getUsers();
        }
    }, [actualPage]);

    useEffect(() => {
        NCCommonService.getAllOrganisation().then((data) => {
            setOrganizations([
                { name: 'None' } as Organization,
                ...(data.sort((a, b) => a.name.localeCompare(b.name)))
            ]);
        });
    }, []);

    const getUsers = async ( reset: boolean = false) => {
        if(research?.search && research.search.length > 1){
            let res: any = []
            if(typeOfSelect === 'nickname') {
                res = await NoUserService.getUserByNickName(research['search']);
                setLastKey(null);
            }
            if(typeOfSelect === 'email') {
                res = await NoUserService.getUserByEmail(research['search'], (lastKey && !reset) ? lastKey : null);
                setLastKey(res.lastKey?.email ? res.lastKey.email : null)
            }
            if(typeOfSelect === '_id') {
                try{
                    let tempObj : User | null = null;
                    tempObj = await NoUserService.getNoUserById(research['search']);
                    res.push({...tempObj});
                    setLastKey(null);
                } catch {
                    setRows([]);
                }
            }

            if (!lastKey || reset) {
                if(res.list){
                    setRows(filterOrganization(res.list));
                } else setRows(filterOrganization(res));
            } else {
                if(res.list){
                    setNewUsers(filterOrganization(res.list));
                } else setNewUsers(filterOrganization(res));
            }
        }
        setIsLoading(false);
    };

    const filterOrganization = (data : Array<NoUser>) => {
        return data.filter((u: NoUser) => u.origin === selectedOrganization  )
    }

    const getOrganizationUsers = async () => {
        if(selectedOrganization) {
            try{
                const res = await NoUserService.getUsersByOrganization(selectedOrganization.toLowerCase());
                setLastKey(null);
                setRows(res.list)
            } catch {
                setRows([]);
            }
        }
    }

    const setNewUsers = (data : any) => {
        const tempArr = [...rows, ...data];
        setRows(tempArr);
    }


    const actionManager = (user: NoUser, kind: string) => {
        setUser(user);

        switch (kind) {
            case 'delete':
                setActionDelete(true);
                break;
            case 'edit':
                window.open(`${process.env.REACT_APP_URL}/users/${user.userId}`, "_blank");
                break;
            case 'create': {
                break;
            }
            default:
                return;
        }
    };  

    const deleteUser = (choice: boolean) => {
        if (!user) {
            toast.error('An error has occured');
            return;
        }
        if (choice) {
            UserService.deleteUser(user.userId).then((data) => {
                if (data) {
                    toast.success(`${user.email} deleted`);
                    refreshUserList();
                } else {
                    toast.error('An error has occured');
                }
            })
        }
    };

    const refreshUserList = () => {
        setIsLoading(true);
    }

    const renderCell = (cell: any, key: string) => {
        switch (key) {
            case 'premiumState':
                return <span className={cell === 'PREMIUM' ? 'premium-secondary-color' : 'premium-primary-color'}>{cell}</span>;
            case 'premiumError':
                switch (cell) {
                    case 'endDateConflict':
                        return <Tooltip title="End date on subscription and user settings are not matching">
                                    <span className="font-weight-bold text-uppercase blink red">Conflict</span>
                                </Tooltip>;
                    default:
                        return null;
                }
            default:
                return null;
        }
    };

    return (
        <div className="NC-container NC-card">
            <NCTitle text={`Users (0)`} />
            <div className='d-flex'>
                <div className='w-50'>
                    <span className='ml-3'>Search for users</span>
                    <SearchBar 
                        onSelectChange={(e) => setTypeOfSelect(e)}
                        searchFields={searchFields}
                        actionHook={() => getUsers()}
                        hideStore
                        value={
                            research ? research['search'] : undefined
                        }
                        typingHook={(e) => setResearch({ search: e })}
                    />
                </div>
                <div className='ml-3'>
                <span>Organization</span>
                    <NCPreviewSearch
                        searchFields={{
                            search: { label: 'Label1' },
                        }}
                        placeHolder={'None'}
                        list={organizations}
                        displayParam="name"
                        hideStore={false}
                        onSelection={(org) => {
                            setSelectedOrganization(org.name);
                        }}
                        onChange={() => {
                            setSelectedOrganization('wolfiesports');
                        }}
                        value={selectedOrganization ?? undefined }
                    />
                </div>
            </div>
            <div className="user-form-container d-flex mt-5">
                {
                    isLoading && <CircularProgress className="mx-auto" />
                }
                {
                    !isLoading &&
                    <SimpleList
                        rows={rows}
                        columns={columns}
                        keepRows={keepRows}
                        actionsButtons={{ edit: true, create: false, delete: true }}
                        handleClickOpen={actionManager}
                        renderCell={renderCell}
                    />
                }
            </div>
            <ActionDialog
                open={actionDelete}
                title={`Are you sure to delete this user: ${user?.email}`}
                openHook={setActionDelete}
                actionHook={deleteUser}
            />
            <div ref={loader}/>
        </div>
    );
}