import React from 'react';
import './App.scss';
import messages_en from './_translations/en.json';
import { BaseRouter } from './components/General/BaseRouter/BaseRouter';
import { StoreProvider } from 'easy-peasy';
import { store } from './store';
import { IntlProvider } from 'react-intl';

export const App: React.FunctionComponent = () => {
	return (
		<StoreProvider store={store}>
			<IntlProvider locale="en" messages={messages_en as any} defaultLocale="en">
				<div className="App">
					<BaseRouter/>
				</div>
			</IntlProvider>
		</StoreProvider>
	);
};