import { TranslatedString } from "./TranslatedString";

export class TrainingGuide {

    constructor(
        public _id: string,
        public name: TranslatedString,
        public description: TranslatedString,
        public content: TranslatedString,
        public slug: TranslatedString,
        public game: string,
    ) {}
}