import React, {useEffect, useState} from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
    TableBody,
    TableContainer,
    TableHead,
    TableRow,
    TableCell,
    Table,
} from '@material-ui/core';
import {NCTitle, NCTitleType} from 'src/atoms/NCTitle/NCTitle';
import {
    MatchConflictedStatus,
    MatchConflictedSubject,
    TournamentMatchConflited,
} from 'src/models/TournamentMatch';
import {NtService} from 'src/services/nt.service';
import {DynamoPagination} from 'src/atoms/DynamoPagination/DynamoPagination';
import moment from 'moment';

interface RequestListProps {
    tournamentId: string;
    match: string;
}

export const MatchRequestsList: React.FunctionComponent<RequestListProps> = ({
    tournamentId,
    match
}) => {

    const [requests, setRequests] = useState<Array<TournamentMatchConflited>>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [seconds, setSeconds] = useState<number>(0);

    const [lastKeys, setLastKeys] = useState<Array<any>>([]);
    const [page, setPage] = useState<number>(0);
    const [isLastPage, setIsLastPage] = useState<boolean>(true);

    useEffect(() => {
        (async () => {
            await search(lastKeys, page);
        })()
        const interval = setInterval(() => {
            setSeconds(seconds + 1);
        }, 30000);
        return () => clearInterval(interval);
    // eslint-disable-next-line
    }, [page, lastKeys, seconds]);

    const search = async (lastKeys: Array<any>, page: number) => {
        let query = '';
        if (lastKeys.length > 0) {
            if (page > 0 && lastKeys[page - 1]) {
                query = query.concat(query, `&route=${lastKeys[page - 1].route}`);
            }
        }
        const data = await NtService.getRequestsByMatch(tournamentId, match, query)
        setRequests([...data.docs]);
        if (data.last) {
            lastKeys[page] = data.last;
            setLastKeys(lastKeys)
            setIsLastPage(false);
        } else {
            setIsLastPage(true);
        }
        setIsLoading(false);
    }

    return (
        <div className="NC-container NC-card d-flex flex-column">
            <NCTitle text={'Match Requests'} type={NCTitleType.TOURNAMENT}/>
            <div className="d-flex">
                {
                    isLoading && <CircularProgress className="mx-auto"/>
                }
                {
                    !isLoading && requests.length > 0 &&
                    <div className="w-100 d-flex flex-column">
                        <DynamoPagination
                            page={page}
                            setPage={setPage}
                            itemPerPage={20}
                            displayNext={isLastPage}
                        />
                        <TableContainer>
                            <Table size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>User</TableCell>
                                        <TableCell>Date</TableCell>
                                        <TableCell>Subject</TableCell>
                                        <TableCell>State</TableCell>
                                        <TableCell>Assignee</TableCell>
                                        <TableCell>Reason</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        requests.map((r: TournamentMatchConflited) => {
                                            return (
                                                <TableRow key={r.route}>
                                                    <TableCell>{ r.user ? r.user.name : "" }</TableCell>
                                                    <TableCell>{ moment(r.rDate).format("LLLL") }</TableCell>
                                                    <TableCell>{ MatchConflictedSubject[r.subject] }</TableCell>
                                                    <TableCell>{ MatchConflictedStatus[r.status] }</TableCell>
                                                    <TableCell>{ r.assignee ? r.assignee : "" }</TableCell>
                                                    <TableCell>{ r.reason }</TableCell>
                                                </TableRow>
                                            );
                                        })
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                }
            </div>
        </div>
    );
}