export class Country {
    constructor(
        public _id: string,
        public langs: Array<string>,
        public name: string,
        public active: boolean,
        public tax: number,
        public iso2: string,
        public createdAt: Date
    ) {

    }
}