import { ICognitoStorage } from 'amazon-cognito-identity-js';

/**
 * a fake cognito storage which prevent cognito from
 * storing information in LocalStorage
 */
export class FakeStorage implements ICognitoStorage {
    constructor() { return; }

    setItem = () => null;
    getItem = () => null;
    removeItem = () => null;
    clear = () => null;
}
