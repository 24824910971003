import axios, { AxiosError } from 'axios';
import { PaginatedList } from 'src/models/Common';
import { Tournament, TournamentState } from '../models/Tournament';
import {
    MatchState,
    TournamentMatch,
    TournamentMatchConflited,
    TournamentTeam
} from '../models/TournamentMatch';

export class NtService {
    private static baseUrl = String(process.env.REACT_APP_NOT_TOURNAMENT);
    static token = (): string | null => localStorage.getItem('accessToken');

    static async getTournamentList(limit: number = 10, lastElement: string = '', query: string = ''): Promise<any> {
        let res;

        try {
            res = (await axios.get(
                `${NtService.baseUrl}/admin/tournaments?limit=${limit}${lastElement}${query}`,
                {
                    headers: {
                        'authorization': NtService.token(),
                    }
                }
            )).data;
        } catch (e) {
            throw (e as AxiosError).response;
        }

        if (!res.list) {
            throw Error;
        }
        const tournamentList: Array<Tournament> = res.list.map((t: Tournament) => {
            return new Tournament(
                t.id,
                t.g4gId,
                t.name,
                t.partner,
                t.route,
                t.templateId,
                t.date,
                t.state,
                t.gameSlug,
                t.platforms
            );
        });

        return {
            docs: tournamentList,
            last: res.lastKey ? res.lastKey : null,
        };
    }

    static async getMatchesFromTournament(tournamentId: string, stateFilter: Array<MatchState> = [], lastRoute?: string): Promise<Array<TournamentMatch>> {
        if (!tournamentId) {
            throw Error;
        }

        const queryParam = [];
        if (stateFilter && stateFilter.length > 0) {
            queryParam.push(...stateFilter.map(s => `state=${MatchState[s]}`));
        }
        if (lastRoute) {
            queryParam.push(`route=${lastRoute}`);
        }

        let res;
        try {
            res = (await axios.get(
                `${NtService.baseUrl}/tournaments/${tournamentId}/matchs${queryParam.length > 0 ? `?${queryParam.join('&')}` : ''}`,
                {
                    headers: {
                        'authorization': NtService.token(),
                    }
                }
            )).data;
        } catch (e) {
            throw (e as AxiosError).response;
        }

        if (!res.List) {
            throw Error;
        }

        const matchList: Array<TournamentMatch> = res.List;
        if (res.LastKey) {
            return NtService.getMatchesFromTournament(tournamentId, stateFilter, res.LastKey.route).then(m => matchList.concat(m));
        }
        return matchList;
    }

    static async getTournamentById(tournamentId: string): Promise<Tournament | null> {
        if (!tournamentId) {
            throw Error;
        }
        let res;
        try {
            res = (await axios.get(
                `${NtService.baseUrl}/tournaments/${tournamentId}`,
                {
                    headers: {
                        'authorization': NtService.token(),
                    },
                    method: 'GET',
                }
            )).data;
        } catch (e) {
            throw (e as AxiosError).response;
        }

        return res;
    }

    static async getMatchById(tournamentId: string, matchId: string): Promise<TournamentMatch> {
        if (!matchId || !tournamentId) {
            throw Error;
        }
        let res;
        try {
            res = (await axios.get(
                `${NtService.baseUrl}/tournaments/${tournamentId}/matchs/${matchId}`,
                {
                    headers: {
                        'authorization': NtService.token(),
                    },
                    method: 'GET',
                }
            )).data;

        } catch (e) {
            throw (e as AxiosError).response;
        }

        return res;
    }

    static async forceCheckIn(tournamentId: string, matchId: string, userId: string): Promise<boolean> {
        if (!tournamentId || !matchId || !userId) {
            return false;
        }

        try {
            await axios.patch(
                `${NtService.baseUrl}/admin/tournaments/${tournamentId}/matchs/${matchId}/checkin`,
                {
                    userId: userId,
                },
                {
                    headers: {
                        'authorization': NtService.token(),
                    },
                    method: 'PATCH',
                }
            );
        } catch (e) {
            return false;
        }

        return true;
    }

    static async confirmScore(tournamentId: string, matchId: string, userId: string): Promise<boolean> {
        if (!tournamentId || !matchId || !userId) {
            return false;
        }

        try {
            await axios.patch(
                `${NtService.baseUrl}/admin/tournaments/${tournamentId}/matchs/${matchId}/confirm`,
                {
                    userId: userId,
                },
                {
                    headers: {
                        'authorization': NtService.token(),
                    },
                    method: 'PATCH',
                }
            );
        } catch (e) {
            return false;
        }

        return true;
    }

    static async sendScore(
        tournamentId: string,
        matchId: string,
        userId: string,
        score: number,
        round: number,
        confirmed: boolean,
    ): Promise<boolean> {
        if (!tournamentId || !matchId || !userId || !round) {
            return false;
        }

        try {
            await axios.patch(
                `${NtService.baseUrl}/admin/tournaments/${tournamentId}/matchs/${matchId}/score`,
                {
                    userId: userId,
                    score: +score || 0,
                    round: +round,
                    confirmed: confirmed,
                },
                {
                    headers: {
                        'authorization': NtService.token(),
                    },
                    method: 'PATCH',
                }
            );
        } catch {
            return false;
        }
        return true;
    }

    static async cancelTournament(
        tournamentId: string,
        reason?: string,
    ): Promise<boolean> {
        if (!tournamentId) {
            return false;
        }

        try {
            await axios.patch(
                `${NtService.baseUrl}/admin/tournaments/${tournamentId}/state`,
                {
                    state: TournamentState.Cancelled,
                    reason,
                },
                {
                    headers: {
                        'authorization': NtService.token(),
                    },
                    method: 'PATCH',
                }
            );
        } catch {
            return false;
        }
        return true;
    }

    static async forceAllScore(tournamentId: string, matchId: string, values: Array<number>): Promise<boolean> {
        if (!tournamentId || !matchId || !values || values.length === 0) {
            return false;
        }

        try {
            await axios.patch(
                `${NtService.baseUrl}/admin/tournaments/${tournamentId}/matchs/${matchId}/force`,
                {
                    values: values.map(v => +v || 0),
                },
                {
                    headers: {
                        'authorization': NtService.token(),
                    },
                    method: 'PATCH',
                }
            );
        } catch {
            return false;
        }
        return true;
    }

    static async registerTeam(tournamentId: string, team: TournamentTeam, checkin: boolean): Promise<boolean> {
        if (!tournamentId || !team || !checkin) {
            return false;
        }

        try {
            await axios.patch(
                `${NtService.baseUrl}/admin/tournaments/${tournamentId}/register`,
                {
                    team: team,
                    checkin: checkin,
                },
                {
                    headers: {
                        'authorization': NtService.token(),
                    },
                    method: 'PATCH',
                }
            );
        } catch {
            return false;
        }
        return true;
    }

    static async unregisterTeam(tournamentId: string, userId: string): Promise<boolean> {
        if (!tournamentId || !userId) {
            return false;
        }

        try {
            await axios.delete(
                `${NtService.baseUrl}/admin/tournaments/${tournamentId}/register`,
                {
                    headers: {
                        'authorization': NtService.token(),
                    },
                    method: 'DELETE',
                    data: {
                        userId: userId,
                    }
                }
            );
        } catch {
            return false;
        }
        return true;
    }

    static async changeMatchState(tournamentId: string, matchId: string, state: number): Promise<boolean> {
        if (!tournamentId || !matchId || !state) {
            return false;
        }

        try {
            await axios.patch(
                `${NtService.baseUrl}/tournaments/${tournamentId}/matchs/${matchId}/state`,
                {
                    state: state,
                },
                {
                    headers: {
                        'authorization': NtService.token(),
                    },
                    method: 'PATCH',
                }
            );
        } catch {
            return false;
        }
        return true;
    }

    static async getAllConflictedMatches(): Promise<Array<TournamentMatchConflited>> {
        const matchList: Array<TournamentMatchConflited> = [];

        return matchList;
    }

    static async getConflictedMatchesByTournament(tournamentId: string): Promise<Array<TournamentMatchConflited>> {
        if (!tournamentId) {
            throw Error;
        }

        let res;

        try {
            res = (await axios.get(
                `${NtService.baseUrl}/tournaments/${tournamentId}/requests`,
                {
                    headers: {
                        'authorization': NtService.token(),
                    },
                    method: 'GET',
                }
            )).data;
        } catch (e) {
            throw (e as AxiosError).response;
        }

        if (!res.List) {
            throw Error;
        }

        const matchList: Array<TournamentMatchConflited> = res.List.map((match: TournamentMatchConflited) => {
            return new TournamentMatchConflited(
                match.id,
                match.matchUrl,
                match.route,
                match.status,
                match.subject,
                match.user,
                match.rDate,
                match.name,
                match.reason,
                match.p,
                match.assignee,
            );
        });

        return matchList;
    }

    static async getMatchesRequestList(status: string, query: string = ''): Promise<any> {
        let res;

        try {
            res = (await axios.get(
                `${NtService.baseUrl}/requests?status=${status}${query}`,
                {
                    headers: {
                        'authorization': NtService.token(),
                    },
                    method: 'GET',
                }
            )).data;
        } catch (e) {
            throw (e as AxiosError).response;
        }
        if (!res.List) {
            throw Error;
        }

        const matchList: Array<TournamentMatchConflited> = res.List.map((match: TournamentMatchConflited) => {
            return new TournamentMatchConflited(
                match.id,
                match.matchUrl,
                match.route,
                match.status,
                match.subject,
                match.user,
                match.rDate,
                match.name,
                match.reason,
                match.p,
                match.assignee,
            );
        });

        return {
            docs: matchList,
            last: res.LastKey ? res.LastKey : null
        };
    }

    static async getRequestsByMatch(tournamentId: string, match: string , query: string = ''): Promise<any> {
        let res;
        let url = `${NtService.baseUrl}/admin/tournaments/${tournamentId}/${match}/requests`
        if (query !== '') {
            url = `${url}?${query}`
        }
        try {
            res = (await axios.get(url,
                {
                    headers: {
                        'authorization': NtService.token(),
                    },
                    method: 'GET',
                }
            )).data;
        } catch (e) {
            throw (e as AxiosError).response;
        }
        if (!res.list) {
            throw Error;
        }
        const matchList: Array<TournamentMatchConflited> = res.list.map((match: TournamentMatchConflited) => {
            return new TournamentMatchConflited(
                match.id,
                match.matchUrl,
                match.route,
                match.status,
                match.subject,
                match.user,
                match.rDate,
                match.name,
                match.reason,
                match.p,
                match.assignee,
            );
        });
        return {
            docs: matchList,
            last: res.lastKey ? res.lastKey : null
        };
    }

    static async updateMatchAssignee(tournamentId: string, match: string): Promise<void> {
        let res;
        try {
            res = (await axios.patch(`${NtService.baseUrl}/admin/tournaments/${tournamentId}/${match}/assignee`,
                {},
                {
                    headers: {
                        'authorization': NtService.token(),
                    },
                    method: 'PATCH',
                }
            ));
        } catch (e) {
            throw (e as AxiosError).response;
        }
        if (res.status > 299 || res.status < 200) {
            throw Error;
        }
    }

    static async getAdminRequest(id: string, route: string): Promise<TournamentMatchConflited | null> {
        if (!id || !route) {
            return null
        }
        let res;

        try {
            res = (await axios.get(
                `${NtService.baseUrl}/tournaments/${id}/${route}`,
                {
                    headers: {
                        'authorization': NtService.token(),
                    },
                    method: 'GET',
                }
            )).data;
        } catch (e) {
            throw (e as AxiosError).response;
        }

        if (!res.id) {
            return null;
        }

        return new TournamentMatchConflited(
            res.id,
            res.matchUrl,
            res.route,
            res.requestStatus,
            res.subject,
            res.user,
            res.date,
            res.name,
            res.p,
            res.assignee,
        );
    }

    static async resetMatch(tournamentId: string, matchId: string): Promise<boolean> {
        if (!tournamentId || !matchId) {
            return false;
        }

        try {
            await axios.patch(
                `${NtService.baseUrl}/admin/tournaments/${tournamentId}/matchs/${matchId}/reset`,
                {},
                {
                    headers: {
                        'authorization': NtService.token(),
                    },
                    method: 'PATCH',
                },
            )
        } catch {
            return false;
        }
        return true;
    }

    static async cancelMatch(tournamentId: string, matchId: string): Promise<boolean> {
        if (!tournamentId || !matchId) {
            return false;
        }

        try {
            await axios.patch(
                `${NtService.baseUrl}/admin/tournaments/${tournamentId}/matchs/${matchId}/cancel`,
                {},
                {
                    headers: {
                        'authorization': NtService.token(),
                    },
                    method: 'PATCH',
                },
            )
        } catch {
            return false;
        }
        return true;
    }

    static async requestHandler(
        tournamentId: string,
        request: string,
        status: number,
        name?: string
    ): Promise<boolean> {
        if (!tournamentId || !request) {
            return false;
        }
        try {
            await axios.patch(
                `${NtService.baseUrl}/tournaments/${tournamentId}/${request}`,
                {
                    Status: status,
                    Name: name,
                },
                {
                    headers: {
                        'authorization': NtService.token(),
                    },
                    method: 'PATCH',
                }
            );
        } catch {
            return false;
        }

        return true;
    }

    static async getMatchProof(tournamentId: string, matchId: string): Promise<any> {
        if (!tournamentId || !matchId) {
            throw Error;
        }
        let res;

        try {
            res = (await axios.get(
                `${NtService.baseUrl}/tournaments/${tournamentId}/matchs/${matchId}/proofs`,
                {
                    headers: {
                        'authorization': NtService.token(),
                    },
                    method: 'GET',
                }
            )).data;
        } catch {
            throw Error;
        }

        return res;
    }

    static async setFullScore(
        tournamentId: string,
        matchId: string,
        userId: string,
        score: number
    ): Promise<any> {
        if (!tournamentId || !matchId) {
            throw Error;
        }
        try {
            const res = (await axios.patch(
                `${NtService.baseUrl}/admin/tournaments/${tournamentId}/matchs/${matchId}/fullScore`,
                {
                    userId: userId,
                    score: +score || 0,
                },
                {
                    headers: {
                        'authorization': NtService.token(),
                    },
                    method: 'PATCH',
                }
            ));
            return res.data;
        } catch {
            throw Error;
        }
    }

    static async setCurrentRound(
        tournamentId: string,
        matchId: string,
        round: number
    ): Promise<any> {
        if (!tournamentId || !matchId) {
            throw Error;
        }
        try {
            return await axios.patch(
                `${NtService.baseUrl}/admin/tournaments/${tournamentId}/matchs/${matchId}/round`,
                {
                    round: +round,
                },
                {
                    headers: {
                        'authorization': NtService.token(),
                    },
                    method: 'PATCH',
                }
            );
        } catch {
            throw Error;
        }
    }

    static async addRound(
        tournamentId: string,
        matchId: string,
        teamId: string,
        roundNumber: number
    ): Promise<any> {
        if (!tournamentId || !matchId || !teamId || !roundNumber) {
            throw Error;
        }
        try {
            return await axios.patch(
                `${NtService.baseUrl}/admin/tournaments/${tournamentId}/matchs/${matchId}/teams/${teamId}/rounds/${roundNumber}`,
                {},
                {
                    headers: {
                        'authorization': NtService.token(),
                    },
                    method: 'PATCH',
                }
            );
        } catch {
            throw Error;
        }
    }

    static async deleteRequestAndMatchesFromTournament(tournamentId: string): Promise<boolean> {
        if (!tournamentId) {
            return false;
        }

        try {
            await axios.delete(
                `${NtService.baseUrl}/tournaments/${tournamentId}/requests`,
                {
                    headers: {
                        'authorization': NtService.token(),
                    },
                    method: 'DELETE',
                }
            );
        } catch {
            return false;
        }
        return true;
    }

    static async getRegisteredTournamentsByUser(userId: string): Promise<PaginatedList<any>> {
        try {
            const res = (await axios.get(
                `${NtService.baseUrl}/admin/users/${userId}/registered`,
                {
                    headers: {
                        'authorization': NtService.token(),
                    },
                    method: 'GET',
                }
            ));
            return res.data;
        } catch(e) {
            throw (e as AxiosError).response;
        }
    }
}