import { ReactSRMWrapper } from '@cactus/react-srm-wrapper';
import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import eventSourceSingletonRT from 'src/services/eventSourceSingletonRT';


export const Challenges: React.FunctionComponent = () => {
    const boTarget = 'bo-challenges';
    const originUrl = `${process.env.REACT_APP_BASE_BUNDLE_URL}/${boTarget}`;
    const [event, setEvent] = useState<any>(null);
    const navigate = useNavigate();

    const data = {
        fromBackOffice: true,
        windowed: true,
    }

    const sendSrmEvent = async(data?: any): Promise<any> => {
        setEvent({ type: 'ws', data: data });
    }

    const eventSubscribe = async(data?: any): Promise<any> => {
        if (data.rt) {
            eventSourceSingletonRT.subscribeSRM(data.id,
                () => localStorage.getItem('authToken'),
                (event) => {
                    if (!event) {
                        return;
                    }
                    sendSrmEvent(event);
                });
        }
        return;

    }

    const eventHandlers = {
        'srm.subscribe': eventSubscribe
    }

    return (
        <div className="page-wrapper">
            <ReactSRMWrapper
                originUrl={originUrl}
                exportPath={`nicecactus.${boTarget}`}
                basename="/challenges"
                language="en"
                arguments={data}
                eventHandlers={eventHandlers}
                event={event}
                nav={ navigate }
            />
        </div>
    );
}