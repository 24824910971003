import { Select, MenuItem } from '@material-ui/core';
import React, { ChangeEvent, useEffect, useState } from 'react'
import { TeamMember, TeamPermission } from 'src/models/Team';

import './TeamCard.scss';


interface TeamCardProps {
    member: TeamMember;
    selected?: TeamPermission;
    noClose?: boolean;
    actionHook: (memberId: string, value: TeamPermission) => void;
    closeClicked: (memberId: string) => void;
}

export const TeamCard: React.FunctionComponent<TeamCardProps> = ({ member, selected, noClose, actionHook, closeClicked }) => {
    const permissions = Object.values(TeamPermission).filter((p: string | number) => typeof p === 'number');
    const [selectedPermission, setSelectedPermission] = useState<TeamPermission>();

    useEffect(() => {
        setSelectedPermission((typeof selected !== 'undefined') ? selected : member.permission);
    }, [selected, member])

    const renderPermission = (p: TeamPermission) => {
        return (
            <div className="d-flex permission-container">
                <div
                    className="mask-icon my-auto ml-2"
                    style={{
                        maskImage: `url(${process.env.REACT_APP_S3_URL}/media/icons/${
                            p === TeamPermission.OWNER ? 'crown' :
                            p === TeamPermission.MANAGER ? 'people' : 'user'}.svg)`,
                        WebkitMaskImage:  `url(${process.env.REACT_APP_S3_URL}/media/icons/${
                            p === TeamPermission.OWNER ? 'crown' :
                            p === TeamPermission.MANAGER ? 'people' : 'user'}.svg)`,
                    }}
                ></div>
                <div className="text-capitalize my-auto ml-1 mr-3">{TeamPermission[p].toLowerCase()}</div>
            </div>
        );
    }

    return (
        <div
            className="team-card position-relative px-2 py-3 background-fullsize"
            style={{
                backgroundImage:
                    `url(${process.env.REACT_APP_S3_URL}/media/assets/user/banner/${member.banner}.png),
                    url(${process.env.REACT_APP_S3_URL}/media/default/default-user-banner.jpg)`
            }}
        >
            <div className="position-relative d-flex content mt-1">
                <div className="avatar-container">
                    <img
                        alt=""
                        className="avatar"
                        src={ `${process.env.REACT_APP_S3_URL}/media/assets/user/profile/${member.avatar}.png` }
                        onError={(e) => e.currentTarget.src = `${process.env.REACT_APP_S3_URL}/media/default/default-user-avatar.svg` }
                    />
                </div>
                <div className="d-flex flex-column ml-3 mr-2">
                    <div>{member.name}</div>
                    <div className="mb-3 code">#{member.code}</div>
                    {
                        (typeof selectedPermission === 'number') &&
                        <Select
                            defaultValue={''}
                            renderValue={(val: unknown) => renderPermission(val as TeamPermission)}
                            value={selectedPermission}
                            className="select"
                            onChange={(event: ChangeEvent<{ name?: string | undefined, value: unknown}>) => {
                                if (selectedPermission !== event.target.value) {
                                    actionHook(member.userId, event.target.value as TeamPermission);
                                }
                                setSelectedPermission(event.target.value as TeamPermission);
                            }}
                        >
                            {
                                permissions && permissions.map((p, index) => {
                                    return (
                                        <MenuItem key={index} value={p}>
                                            {renderPermission(p as TeamPermission)}
                                        </MenuItem>
                                    )
                                })
                            }
                        </Select>
                    }
                </div>
            </div>
            {
                !noClose &&
                <div
                    className="close-button position-absolute d-flex flex-column justify-content-center"
                    onClick={() => closeClicked(member.userId)}
                >
                    <div
                        className="mask-icon mx-auto"
                        style={{
                            maskImage: `url(${process.env.REACT_APP_S3_URL}/media/icons/close.svg)`,
                            WebkitMaskImage: `url(${process.env.REACT_APP_S3_URL}/media/icons/close.svg)`
                        }}
                    ></div>
                </div>
            }
        </div>
    );
}