import React, {useEffect, useState} from 'react';

import {ActionDialog} from 'src/components/General/ActionDialog/ActionDialog';
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress';
import {FullScreenDialog} from 'src/components/General/FullScreenDialog/FullScreenDialog';
import {NCTitle} from 'src/atoms/NCTitle/NCTitle';
import {OrganizationService} from 'src/services/organization.service';
import {SimpleList} from 'src/components/General/SimpleList/SimpleList';
import {Voucher} from 'src/models/Voucher';
import {VoucherForm} from 'src/components/Premium/Voucher/VoucherForm/VoucherForm';
import {VoucherService} from 'src/services/voucher.service';
import {toast} from 'react-toastify';

export const Vouchers: React.FunctionComponent = () => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [actualPage, setActualPage] = useState<number>(0);
    const [totalItem, setTotalItem] = useState<number>(0);
    const [voucher, setVoucher] = useState<Voucher>();
    const [actionDelete, setActionDelete] = useState<boolean>(false);
    const [actionEdit, setActionEdit] = useState<boolean>(false);
    const [actionCreate, setActionCreate] = useState<boolean>(false);
    const [rows, setRows] = useState<any>([]);
    const columns = ['Name', 'Operation', 'Organization', 'Given', 'Used'];
    const keepRows = ['name', 'operation', 'organisation', 'numberGiven', 'numberUsed'];

    useEffect(() => {
        setIsLoading(true);
        VoucherService.getVouchers(20, actualPage).then(data => {
            setTotalItem(Math.ceil(data.total / 20));
            parseOrganizations(data.docs);
        });
    }, [totalItem, actualPage]);


    const parseOrganizations = async (vouchers: Array<Voucher>) => {
        for (const vouch of vouchers) {
            if (vouch.organisation) {
                try {
                    await OrganizationService.getOrganization(vouch.organisation).then(org => {
                        (vouch as any).organisation = org.name;
                    });
                } catch (e) {
                    console.error(e);
                }
            }
        }
        setRows(vouchers);
        setIsLoading(false);
    };

    const actionManager = (voucher: Voucher, kind: string) => {
        setVoucher(voucher);
        switch (kind) {
            case 'delete':
                setActionDelete(true);
                break;
            case 'edit':
                setActionEdit(true);
                break;
            case 'create': {
                setVoucher(
                    new Voucher(
                        '',
                        new Date(),
                        new Date(),
                        0,
                        0,
                        '',
                        0,
                        '',
                        '',
                    ));
                setActionCreate(true);
                break;
            }
            default:
                return;
        }
    };

    const deleteVoutcher = (choice: boolean) => {
        if (choice && voucher) {
            VoucherService.deleteVoucher(voucher.name).then(data => {
                if (!data) {
                    toast.error('Failed to delete voutcher');
                    updateList(false);
                    return;
                }
                toast.error('Voutcher deleted');
                updateList(true);
            });
        }
    };

    const updateList = (result: boolean) => {
        setActionEdit(false);
        setActionCreate(false);
        setActionDelete(false);

        if (result) {
            setIsLoading(true);
            VoucherService.getVouchers(20, actualPage).then(data => {
                setTotalItem(Math.ceil(data.total / 20));
                setRows(data.docs);
                setIsLoading(false);
            });
        }
    };

    return (
        <div className="NC-container NC-card d-flex flex-column">
            <NCTitle text={'Vouchers'}/>
            <div className="d-flex">
                {
                    isLoading && <CircularProgress className="mx-auto"/>
                }
                {
                    !isLoading &&
                    <SimpleList
                        rows={rows}
                        columns={columns}
                        keepRows={keepRows}
                        actionsButtons={{create: true, edit: false, delete: true}}
                        handleClickOpen={actionManager}
                        pagination={{actual: actualPage, total: totalItem, setActual: setActualPage}}
                    />
                }
            </div>
            <ActionDialog
                open={actionDelete}
                title={`Are you sure to delete this voutcher : ${voucher?.name}`}
                openHook={setActionDelete}
                actionHook={deleteVoutcher}
            />
            {
                voucher &&
                <FullScreenDialog
                    open={actionEdit}
                    openHook={setActionEdit}
                    actionHook={updateList}
                    componentToDisplay={
                        <VoucherForm
                            voucher={voucher}
                            actionHook={updateList}
                        />
                    }
                />
            }
            {
                voucher &&
                <FullScreenDialog
                    open={actionCreate}
                    openHook={setActionEdit}
                    actionHook={updateList}
                    componentToDisplay={
                        <VoucherForm
                            voucher={voucher}
                            actionHook={updateList}
                            creationMode={true}
                        />
                    }
                />
            }
        </div>
    );
};