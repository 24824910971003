import React from 'react';
import {TrainingProgram} from 'src/models/TrainingProgram';
import {NCTitle} from 'src/atoms/NCTitle/NCTitle';
import {Editor} from '@tinymce/tinymce-react';

interface ProgramAdviceProps {
    program: TrainingProgram;
    programChange: any;
    lang: string;
}

export const ProgramAdvice: React.FunctionComponent<ProgramAdviceProps> = ({program, programChange, lang}) => {

    const onEditorChange = (content: any) => {
        program.advice[lang] = content;
        programChange(program);
    };

    return (
        <div className="row px-0 px-sm-5 mt-3">
            <div className="col-12 mb-3 px-3">
                <NCTitle text={'Advice'} color="white"/>
                <Editor apiKey={process.env.REACT_APP_TINYMCE_KEY}
                        init={{
                            height: 300,
                            a11y_advanced_options: true,
                            image_title: true,
                            automatic_uploads: true,
                            file_picker_types: 'image',
                            plugins: 'link lists media table image imagetools media',
                            toolbar: 'undo redo | styleselect fontselect fontsizeselect removeformat | bold italic | alignleft aligncenter alignright alignjustify | ' +
                                'bullist numlist outdent indent | image media link | spellchecker | forecolor backcolor',
                        }}
                        value={program.advice[lang] || ''}
                        onEditorChange={(event: any) => (onEditorChange(event))}
                />
                {(program.advice['en'] || program.advice['fr']) &&
                <p>Helper
                    : {program.advice['en'] ? (program.advice['en'] !== undefined ? 'EN : "' + program.advice['en'] + '"' : '') : (program.advice['fr'] !== undefined ? 'FR : "' + program.advice['fr'] + '"' : '')}</p>}
            </div>
        </div>
    );


};