import { Button, Icon, IconType, NCDialog, NCList } from '@wolfiesports/srm-component';
import { NCListProps } from '@wolfiesports/srm-component';
import React, { useEffect, useState } from 'react';
import { PromotionForm } from 'src/components/Promotion/PromotionForm';
import { PromotionModel } from 'src/models/Promotion';
import { LangService } from 'src/services/lang.service';
import { PromotionService } from 'src/services/promotion.service';
import "./Promotions.scss";


export const Promotions: React.FunctionComponent = () => {
    const [ promotionList, setPromotionList ] = useState<Array<PromotionModel>>([]);
    const [ showDialog, setShowDialog ] = useState<boolean>(false);
    const [ langs, setLangs ] = useState<Array<any>>([]);
    const [ creationMode, setCreationMode ] = useState<boolean>(true);

    const [ promotion, setPromotion ] = useState<PromotionModel>();

    const [table, setTable] = useState<NCListProps>({
        header: [
            {
                thId: 'name',
                thClassName: '',
                thContent: 'Name',
            },
            {
                thId: 'organization',
                thClassName: '',
                thContent: 'Organization',
            },
            {
                thId: 'regions',
                thClassName: '',
                thContent: 'Regions',
            },
            {
                thId: 'homepage',
                thClassName: '',
                thContent: 'Homepage',
            },
            {
                thId: 'date',
                thClassName: '',
                thContent: 'Creation date',
            },
            {
                thId: 'actions',
                thClassName: '',
                thContent: 'Actions',
            },
        ],
        data: [],
    });

    useEffect(() => {
        getLanguages();
        getPromotions();
    }, []);

    useEffect(() => {
        const fillRows = () => {
            return promotionList.map(promotion => {
                return {
                    tr: {
                        trData: promotion,
                        trClassName: '',
                    },
                    name: {
                        tdContent: promotion.name,
                        tdClassName: '',
                    },
                    organization: {
                        tdContent: promotion.organization,
                        tdClassName: '',
                    },
                    regions: {
                        tdContent: (
                            <div className='d-flex'>
                                {
                                    (promotion.regions || []).map(r => {
                                        return  <div key={r} className='mr-2'>{r}</div>
                                    })
                                }
                            </div>
                        ),
                        tdClassName: '',
                    },
                    homepage: {
                        tdContent: promotion.displayOnHomepage ?
                            <Icon
                                icon={IconType.Success}
                                width={24}
                                height={24}
                            /> : <></>,
                        tdClassName: '',
                    },
                    date: {
                        tdContent: <div>{ new Date(promotion.createdAt).toLocaleString() }</div>,
                        tdClassName: '',
                    },
                    actions: {
                        tdContent: (
                            <div className='d-flex'>
                                <Icon
                                    icon={IconType.Settings}
                                    width={24}
                                    height={24}
                                    styleName='mr-2'
                                    onClick={() => {
                                        setCreationMode(false);
                                        setPromotion(promotion);
                                        setShowDialog(true);
                                    }}
                                />
                                <Icon
                                    icon={IconType.Trashcan}
                                    width={24}
                                    height={24}
                                    onClick={() => {
                                        deletePromotion(promotion._id)
                                    }}
                                />
                            </div>
                        ),
                        tdClassName: '',
                    }
                };
            });
        };

        setTable((prev: NCListProps) => {
            return {
                ...prev,
                data: [...fillRows()],
            };
        });
        // eslint-disable-next-line
    }, [promotionList]);

    const getLanguages = async () => {
        const res = await LangService.getLanguages();
        setLangs(res.docs);
    }

    const getPromotions = async () => {
        const res = await PromotionService.getPromotions();
        setPromotionList(res.docs);
    }

    const deletePromotion = async (id: string) => {
        await PromotionService.deletePromotion(id);
        getPromotions();
    }

    return (
        <div className="NC-container NC-card d-flex flex-column nc-promotions">
            <div className="mb-4 mx-auto">
                <Button
                    label="Add promotion"
                    icon={{ type: IconType.Plus, width: 14, height: 14 }}
                    setClick={() => {
                        setCreationMode(true);
                        setPromotion(new PromotionModel('', {}, {}));
                        setShowDialog(true);
                    }}
                />
            </div>

            <NCList
                variant="nc-tournament-list"
                header={table.header}
                data={table.data}
            />

            {
                promotion &&
                <NCDialog
                    show={showDialog}
                    setShow={setShowDialog}
                    title={"Promotional Banner"}
                >
                    <PromotionForm
                        promotion={promotion}
                        langs={langs}
                        actionHook={() => {
                            setShowDialog(false);
                            getPromotions();
                        }}
                        creationMode={creationMode}
                    />
                </NCDialog>

            }
        </div>
    );
};