import React, { useEffect, useState } from "react";
import { CornerService } from "src/services/corner.service";
import { Corner } from "src/models/Corner";
import { SimpleList } from "src/components/General/SimpleList/SimpleList";
import { NCTitle, NCTitleType } from "src/atoms/NCTitle/NCTitle";
import { CircularProgress } from "@material-ui/core";
import { FullScreenDialog } from "src/components/General/FullScreenDialog/FullScreenDialog";
import { CornerForm } from "src/components/Corners/CornerForm/CornerForm";

export const CornerBuilder: React.FunctionComponent = () => {
  const [corners, setCorners] = useState<Corner[]>([]);
  const [corner, setCorner] = useState<Corner>();
  const [actualPage, setActualPage] = useState<number>(0);
  const [totalItem, setTotalItem] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error>();
  const [openEditDialog, setOpenEditDialog] = useState<boolean>(false);

  const columns = ["Name", "Id"];
  const keepRows = ["name", "_id"];

  useEffect(() => {
    CornerService.getCorners()
      .then(setCorners)
      .then(() => setIsLoading(false))
      .catch((error) => {
        setError(error);
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    setTotalItem(corners.length);
  }, [corners]);

  const handleClickOpen = (corner: Corner, kind: string) => {
    setCorner(corner);

    switch (kind) {
      case "edit":
        setOpenEditDialog(true);
        break;
      default:
        return;
    }
  };

  return (
    <div className="NC-container NC-card">
      <FullScreenDialog
        open={openEditDialog}
        openHook={setOpenEditDialog}
        componentToDisplay={
          <CornerForm corner={corner} />
        }
      />
      <NCTitle
        text={`Corners (${totalItem ? totalItem : "0"})`}
        type={NCTitleType.CORNER}
      />
      {error && <div className="corner-builder-error">{error.message}</div>}
      {isLoading && (
        <CircularProgress
          data-testid="corner-builder-loading"
          className="mx-auto"
        />
      )}
      <div className="d-flex" data-testid="corner-builder-list">
        <SimpleList
          rows={corners}
          columns={columns}
          keepRows={keepRows}
          pagination={{
            actual: actualPage,
            total: Math.ceil(totalItem / 20),
            setActual: setActualPage,
          }}
          actionsButtons={{ edit: true }}
          handleClickOpen={handleClickOpen}
        />
      </div>
    </div>
  );
};
