import React, {ChangeEvent, useEffect, useState} from 'react';

import AppBar from '@material-ui/core/AppBar';
import { Autocomplete } from '@material-ui/lab';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import {CountryService} from 'src/services/country.service';
import { Editor } from '@tinymce/tinymce-react';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { GroupCountry } from 'src/models/GroupCountry';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import {Languages} from 'src/models/Languages';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import { NewsModel } from 'src/models/News';
import {NewsService} from 'src/services/news.service';
import { PartnerModel } from 'src/models/Partner';
import Select from '@material-ui/core/Select';
import Switch from '@material-ui/core/Switch';
import Tab from '@material-ui/core/Tab';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';
import TextField from '@material-ui/core/TextField';
import { toast } from 'react-toastify';
import {useStoreState} from 'src/store';
import { TranslatedString } from 'src/models/TranslatedString';

interface NewsFormProps {
    newsProps: NewsModel;
    actionHook: any;
    creationMode?: boolean;
    partnerList: Array<PartnerModel>;
}

export const NewsForm: React.FunctionComponent<NewsFormProps> = ({newsProps, actionHook, creationMode, partnerList}) => {
    const [news, setNews] = useState<NewsModel>(newsProps);
    const [languages] = useState(useStoreState(state => state.languages));
    const [edit, setEdit] = useState<boolean>(false);
    const [langValue, setLangValue] = useState(useStoreState(state => state.contentLang));
    const [published, setPublished] = useState<boolean>(newsProps.published || false);
    const [countryGroups, setCountryGroups] = useState<Array<GroupCountry>>([]);
    const [selectedGroups, setSelectedGroups] = useState<Array<string>>(newsProps.countryGroup || []);
    const [groupList, setGroupList] = useState<Array<string>>();

    useEffect(() => {
        CountryService.getGroupCountries(50, 0).then((data: any) => {
            setCountryGroups(data.docs);
            setGroupList(data.docs.map((group: GroupCountry) => group.value));
        }).catch(error => console.log(error));
    }, [])

    useEffect(() => {
        if(groupList) {
            setSelectedGroups(selectedGroups.filter((group => groupList.includes(group))));
        }
        // eslint-disable-next-line
    }, [ groupList ]);

    const saveNews = () => {
        if (news) {
            news.countryGroup = selectedGroups;
            news.published = published;
            if (edit) {
                NewsService.updateNews(news).then(data => {
                    if (!data) {
                        toast.error('Failed to update news')
                        actionHook(false);
                        return;
                    }
                    actionHook(true);
                    toast.success('News updated');
                });
            } else {
                NewsService.createNews(news).then(data => {
                    if (!data) {
                        toast.error('Failed to create news')
                        actionHook(false);
                        return;
                    }
                    toast.success('News created');
                    actionHook(true);
                });
            }
        }
    };
    const handleTabChange = (event: React.ChangeEvent<HTMLInputElement>, newValue: string) => {
        setLangValue(newValue);
    };

    const onNewsFieldChange = (event: any, isTranslatedString = false, lang = '') => {
        const {name, value} = event.currentTarget;
        if (isTranslatedString) {
            const myNewsAttr = news[name as keyof NewsModel] as TranslatedString;
            myNewsAttr[lang] = value;
            setNews(news);
        } else {
            setNews(Object.assign({}, news, {
                [name]: value,
            }));
        }
    };

    const onContentChange = (content: string, lang: string) => {
        news.content[lang] = content;
        setNews(news);
    }

    const onGroupChange = (event: any) => {
      setSelectedGroups(event.target.value);
    };

    const onPartnerChange = (partnerId: string | null) => {
        news.partner = partnerId;
        setNews({...news});
    }

    return (
        <div className="w-100">
            <div className="w-100 d-flex align-items-center mb-3">
                <div className="w-50">
                    {
                        !creationMode &&
                        <FormControlLabel
                            control={<Switch color="primary" checked={edit} onChange={() => setEdit(!edit)}/>}
                            label="Edit"
                            labelPlacement="start"
                        />
                    }

                </div>
                <div className="w-50">
                    {
                        (edit || creationMode) &&
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                saveNews();
                            }}
                        >
                            Save
                        </Button>
                    }
                </div>
                <FormControlLabel
                    control={
                        <Switch
                            checked={published}
                            onChange={() => setPublished(!published)}
                            name="Type"
                            color="primary"
                        />
                    }
                    label="Published"
                    className="w-50"
                />
                <TextField
                    disabled={!edit && !creationMode}
                    
                    name="tag"
                    label="Tag"
                    defaultValue={news.tag}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => (onNewsFieldChange(event, false))}
                />
            </div>
            <form className="d-flex flex-column NC-tab">
                <TabContext value={langValue}>
                    <AppBar position="static">
                        <TabList onChange={(event: React.ChangeEvent<any>, langValue: any) => handleTabChange(event, langValue)} aria-label="simple tabs example">
                            {
                                languages.map((lang: Languages) => (
                                    <Tab key={lang._id} label={lang.name} value={lang.code}/>
                                ))
                            }
                        </TabList>
                    </AppBar>
                    {
                        languages.map((lang: Languages) => (
                            <TabPanel className="w-100" key={lang._id} value={lang.code}>
                                <div className="d-flex flex-row row my-2">
                                    <TextField
                                        disabled={!edit && !creationMode}
                                        className="col-12 mb-1"
                                        name="title"
                                        label="Title"
                                        defaultValue={news.title[lang.code]}
                                        onChange={(event: ChangeEvent<HTMLInputElement>) => (onNewsFieldChange(event, true, lang.code))}
                                    />
                                    <InputLabel>Content</InputLabel>
                                    <div className="col-12 mb-1 px-0">
                                        <Editor apiKey={process.env.REACT_APP_TINYMCE_KEY}
                                                disabled={!edit && !creationMode}
                                                init={{
                                                    height: 400,
                                                    a11y_advanced_options: true,
                                                    image_title: true,
                                                    automatic_uploads: true,
                                                    file_picker_types: 'image',
                                                    pagebreak_split_block: true,
                                                    plugins: 'link lists media table image imagetools media pagebreak',
                                                    toolbar: 'undo redo | styleselect fontselect fontsizeselect removeformat | bold italic | alignleft aligncenter alignright alignjustify | ' +
                                                        'bullist numlist outdent indent | image media link | spellchecker | forecolor backcolor',
                                                }}
                                                value={news.content[lang.code]}
                                                onEditorChange={(content: string) => (onContentChange(content, lang.code))}
                                        />
                                    </div>
                                    <Autocomplete
                                        className="w-100 py-2"
                                        value={news.partner}
                                        options={partnerList.map(p=>p._id || '')}
                                        getOptionLabel={(partnerId: string) => partnerList.find(p => p._id === partnerId)?.name || ''}
                                        onChange={(_, value) => onPartnerChange(value)}
                                        renderInput={(params    ) => (
                                            <TextField
                                                {...params}
                                                variant="outlined"
                                                placeholder="Partner"
                                            />
                                        )}
                                        disabled={!edit && !creationMode}
                                    />
                                    <FormControl className="w-100 pb-4">
                                      <InputLabel>Group of countries</InputLabel>
                                      <Select
                                        disabled={!edit && !creationMode}
                                        multiple
                                        defaultValue={selectedGroups}
                                        onChange={onGroupChange}
                                        input={<Input/>}
                                        renderValue={(selected) => {
                                          return (
                                            <div>
                                              {
                                                (selected as Array<string>).map((group: string, index: number) => {
                                                  return selectedGroups.includes(group) ?
                                                    <div className="mx-1" key={index}>{group}</div> :
                                                    ''
                                                })
                                              }
                                            </div>
                                          );
                                        }}
                                      >
                                      {
                                        countryGroups && countryGroups.map((group: GroupCountry) => (
                                          <MenuItem key={group._id} value={group.value}>
                                            <Checkbox checked={selectedGroups.indexOf(group.value) > -1}/>
                                            <ListItemText primary={group.value}/>
                                          </MenuItem>
                                        ))
                                      }
                                      </Select>
                                  </FormControl>
                                </div>
                            </TabPanel>
                        ))
                    }
                </TabContext>
            </form>
        </div>
    );
};