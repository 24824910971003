import CircularProgress from '@material-ui/core/CircularProgress';
import React, {useEffect, useState} from 'react';
import {toast} from 'react-toastify';
import {NCTitle, NCTitleType} from '../../../atoms/NCTitle/NCTitle';
import {ActionDialog} from '../../../components/General/ActionDialog/ActionDialog';
import {FullScreenDialog} from '../../../components/General/FullScreenDialog/FullScreenDialog';
import {SimpleList} from '../../../components/General/SimpleList/SimpleList';
import {SkillGameBlockForm} from '../../../components/Training/SkillGame/SkillGameBlockForm/SkillGameBlockForm';
import {SkillGameBlock} from '../../../models/SkillGame';
import {TrainingService} from '../../../services/training.service';

export const SkillGameBlocks: React.FunctionComponent = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [actualPage, setActualPage] = useState<number>(0);
    const [totalItem, setTotalItem] = useState<number>(0);
    const [rows, setRows] = useState<any>([]);
    const [block, setBlock] = useState<SkillGameBlock>();
    const [actionDelete, setActionDelete] = useState<boolean>(false);
    const [actionEdit, setActionEdit] = useState<boolean>(false);
    const [actionCreate, setActionCreate] = useState<boolean>(false);

    useEffect(() => {
        TrainingService.getSkillGamesBlock(20, actualPage).then(data => {
            setTotalItem(Math.ceil(data.total / 20));
            setRows(data.docs);
            setIsLoading(false);
        });
    }, [totalItem, actualPage]);

    const actionManager = (block: SkillGameBlock, kind: string) => {
        setBlock(block);

        switch (kind) {
            case 'delete':
                setActionDelete(true);
                break;
            case 'edit':
                setActionEdit(true);
                break;
            case 'create': {
                setBlock(
                    new SkillGameBlock(
                        '',
                        {},
                        '',
                        false,
                        [],
                        [],
                    )
                );
                setActionCreate(true);
                break;
            }
            default:
                return;
        }
    };

    const deleteBlock = (choice: boolean) => {
        if (!choice) {
            return;
        }

        if (block) {
            TrainingService.deleteSkillGameBlock(block._id).then(data => {
                if (!data) {
                    toast.error('Failed to delete block');
                }
                toast.success('Block deleted');
                updateList(true);
            });
        }
    };

    const updateList = (result: boolean) => {
        setActionEdit(false);
        setActionCreate(false);
        setActionDelete(false);
        if (result) {
            setIsLoading(true);
            TrainingService.getSkillGamesBlock(20, actualPage).then(data => {
                setTotalItem(Math.ceil(data.total / 20));
                setRows(data.docs);
                setIsLoading(false);
            });
        }
    };

    const filterRows = () => {
        const tmpRows: any = rows;
        tmpRows.forEach((row: any) => {
            row.abilitiesNames = '';
            row.abilities.forEach((name: string) => {
                row.abilitiesNames = row.abilitiesNames.concat(
                    ' ',
                    `${name},`,
                );
            });
        });
        return tmpRows;
    };

    return (
        <div className="NC-container NC-card d-flex flex-column">
            <NCTitle text={'Skill game Blocks'} type={NCTitleType.TRAINING}/>
            <div className="d-flex">
                {
                    isLoading && <CircularProgress className="mx-auto"/>
                }
                {
                    !isLoading &&
                    <SimpleList
                        rows={filterRows}
                        columns={['Name', 'Value', 'Abilities']}
                        keepRows={['name', 'value', 'abilitiesNames']}
                        actionsButtons={{create: true, edit: true, delete: true}}
                        handleClickOpen={actionManager}
                        pagination={{actual: actualPage, total: totalItem, setActual: setActualPage}}
                    />
                }
            </div>
            <ActionDialog
                open={actionDelete}
                title={'Are you sure to delete this block ?'}
                openHook={setActionDelete}
                actionHook={deleteBlock}
            />
            {
                block &&
                <FullScreenDialog
                    open={actionEdit}
                    openHook={setActionEdit}
                    actionHook={updateList}
                    componentToDisplay={
                        <SkillGameBlockForm
                            block={block}
                            actionHook={updateList}
                        />
                    }
                />
            }
            {
                block &&
                <FullScreenDialog
                    open={actionCreate}
                    openHook={setActionCreate}
                    actionHook={updateList}
                    componentToDisplay={
                        <SkillGameBlockForm
                            block={block}
                            actionHook={updateList}
                            creationMode={true}
                        />
                    }
                />
            }
        </div>
    );
};