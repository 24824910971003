import { Button } from '@material-ui/core';
import React from 'react';
import './CategorizedButton.scss';

export enum CategorizedButtonVariant {
    TRAINING = 'training',
    TOURNAMENT = 'tournament',
}

interface CategorizedButtonProps {
    rest?: any;
    variant: CategorizedButtonVariant;
    text: string;
}

export const CategorizedButton: React.FunctionComponent<CategorizedButtonProps> = ({ variant, text, ...rest }) => {
	return (
		<Button
			{... rest}
			className={`categorized-button ${variant}`}
            
		>
			{text}
		</Button>
	);
};