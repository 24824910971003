import { CircularProgress } from '@material-ui/core';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import IconButton from '@material-ui/core/IconButton';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { ImageUpload } from 'src/atoms/ImageUpload/ImageUpload';
import "./Media.scss";

interface MediaProps {
    title: string;
    s3Key: string;
    link: string;
    path: string;
    icon: string;
    uploadMedia: (type: string, path: Array<string>, key: string, file: any) => Promise<boolean>;
    disabled?: boolean;
    width?: string;
}

export const Media: React.FunctionComponent<MediaProps> = (
    props: MediaProps
) => {
    const [media, setMedia] = useState<string>();
    const [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect(() => {
        const img = new Image();
        img.src = `${props.link}?latest=${moment().valueOf()}`;
        img.onerror = () => setIsLoading(false); 
        img.onload = () => {
            setMedia(img.src);
            setIsLoading(false);
        };
    }, [props.link]);

    const uploadMedia = async(type: string, path: Array<string>, key: string, file: any) => {
        setIsLoading(true);
        const isUploaded = await props.uploadMedia(type, path, key, file);
        if (isUploaded) {
            setMedia(file);
        }
        setIsLoading(false);
    }

    return (
        <GridListTile className={props.width}>
            {isLoading 
                ? <CircularProgress/>
                : media 
                    ? <img src={media} alt={props.title} /> 
                    : <></>
            }
            <GridListTileBar
                title={props.title}
                actionIcon={
                    <IconButton 
                        disabled={props.disabled} 
                        aria-label={`info about ${props.title}`}
                        className={props.icon}
                    >
                        <ImageUpload 
                            name={props.title} 
                            s3Key={props.s3Key} 
                            path={props.path} 
                            uploadMedia={uploadMedia}
                        />
                    </IconButton>
                }
            />
        </GridListTile>
    );
};