import "./PromotionForm.scss";

import React, { useState, useEffect } from "react";
import { ButtonType, ButtonTheme, NCActions, NCFlagSelector, NCMediaUpload, NCInput, NCMultiSearch, NCPreviewSearch, NCSwitch, NCPromotionalBanner } from "@wolfiesports/srm-component";
import { GroupCountry } from "src/models/GroupCountry";
import { PromotionModel } from "src/models/Promotion";
import { PromotionService } from "src/services/promotion.service";
import { CountryService } from "src/services/country.service";
import { OrganizationService } from "src/services/organization.service";
import { OrganizationModel } from "src/models/Organization";

interface PromotionFormProps {
    promotion: PromotionModel,
    langs: Array<any>;
    creationMode?: boolean;
    actionHook: (changed: boolean) => void;
}

export const PromotionForm: React.FunctionComponent<PromotionFormProps> = (props: PromotionFormProps) => {
    const [promotion, setPromotion] = useState<PromotionModel>(props.promotion);
    const [ selectedLang, setSelectedLang] = useState<string>('en');
    const [ name, setName ] = useState<string>(promotion.name);
    const [ buttonLink, setButtonLink ] = useState<string>(promotion.buttonLink ? promotion.buttonLink[selectedLang] : '');
    const [ buttonLabel, setButtonLabel ] = useState<string>(promotion.buttonText ? promotion.buttonText[selectedLang] : '');
    const [ description, setDescription ] = useState<string>(promotion.description[selectedLang] || '');
    const [ image, setImage ] = useState<string>(promotion.image[selectedLang] || '');
    const [ displayHomepage, setDisplayHomepage ] = useState<boolean>(promotion.displayOnHomepage || false);

    const [ allRegions, setAllRegions ] = useState<Array<GroupCountry>>([]);
    const [ selectedRegions, setSelectedRegions ] = useState<Array<string>>(promotion.regions || []);

    const [ allOrganizations, setAllOrganizations ] = useState<Array<OrganizationModel>>([]);
    const [ selectedOrganization, setSelectedOrganization ] = useState<OrganizationModel>();

    const [ tournamentId, setTournamentId ] = useState<string>(promotion.tournamentId || '');

    useEffect(() => {
        getCountryGroups();
        getOrganizations();
        // eslint-disable-next-line
    }, []);

    const savePromotion = async () => {
        try {
            if (props.creationMode) {
                await PromotionService.createPromotion(promotion);
            } else {
                await PromotionService.updatePromotion(promotion);
            }
            props.actionHook(true);
        } catch {
            props.actionHook(false);
        }
    }

    const getCountryGroups = async () => {
        let res = await CountryService.getGroupCountries(50);
        let list = res.docs;
        while (res.page < res.pages -1) {
            res = await CountryService.getGroupCountries(50, res.page + 1);
            list = [...list, res.docs];
        }
        setAllRegions(list);
    }

    const getOrganizations = async () => {
        const res = await OrganizationService.getOrganizations();
        setSelectedOrganization(res.find((o: OrganizationModel) => o.domain === promotion.organization ));
        setAllOrganizations(res);
    }

    return (
        <div>
            <NCFlagSelector
                languages={props.langs}
                actionHook={(lang) => {
                    setSelectedLang(lang);
                    setDescription(promotion.description[lang]);
                    setButtonLabel(promotion.buttonText ? promotion.buttonText[lang] : '');
                    setButtonLink(promotion.buttonLink ? promotion.buttonLink[lang] : '');
                    setImage(promotion.image[lang]);
                }}
                publicUrl={process.env.REACT_APP_S3_URL || ''}
            ></NCFlagSelector>

            <div className="d-flex justify-content-between mb-4">
                <div className='col-4'>
                    <NCMediaUpload
                        mediaLibrary
                        currentImg={image}
                        actionHook={(_, file) => {
                            setImage(file);
                            promotion.image[selectedLang] = file;
                        }}
                        s3PublicUrl={process.env.REACT_APP_S3_URL || ''}
                    />
                </div>
                <div className='col-8'>
                    <div className='mb-4'>
                        <NCInput
                            id={'promotion-name-' + selectedLang}
                            key={'promotion-name-' + selectedLang}
                            label="Name"
                            value={name}
                            onChange={(text) => {
                                setName(text);
                                promotion.name = text;
                                setPromotion(promotion);
                            }}
                            type={'text'}
                        />
                    </div>
                    <div className='mb-4'>
                        <NCInput
                            id={'promotion-description-' + selectedLang}
                            key={'promotion-description-' + selectedLang}
                            label="Promotional banner description"
                            value={description}
                            onChange={(text) => {
                                setDescription(text);
                                promotion.description[selectedLang] = text;
                                setPromotion(promotion);
                            }}
                            type={'text'}
                        />
                    </div>
                    <div className="d-flex row">
                        <div className="col-6">
                            <NCInput
                                id={'promotion-button-link-' + selectedLang}
                                key={'promotion-button-link-' + selectedLang}
                                label="Buton link (with https://)"
                                value={buttonLink}
                                onChange={(text) => {
                                    setButtonLink(text);
                                    if (promotion.buttonLink) {
                                        promotion.buttonLink[selectedLang] = text;
                                    } else {
                                        promotion.buttonLink = { selectedLang: text };
                                    }
                                    setPromotion(promotion);
                                }}
                                type={'text'}
                            />
                        </div>
                        <div className="col-6">
                            <NCInput
                                id={'promotion-button-text-' + selectedLang}
                                key={'promotion-button-text-' + selectedLang}
                                label="Buton label"
                                value={buttonLabel}
                                onChange={(text) => {
                                    setButtonLabel(text);
                                    if (promotion.buttonText) {
                                        promotion.buttonText[selectedLang] = text;
                                    } else {
                                        promotion.buttonText = { selectedLang: text };
                                    }
                                    setPromotion(promotion);
                                }}
                                type={'text'}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-12 mb-4">
                <div className="mb-2">
                    Whitelisted zones and countries
                </div>
                <div>
                    <NCMultiSearch
                        searchFields={{
                            search: { label: 'Label1' },
                        }}
                        list={allRegions}
                        hideSelected={true}
                        displayParam="value"
                        compareParam="value"
                        selected={selectedRegions}
                        onSelection={(selected: GroupCountry) => {
                            const item = allRegions.find(
                                (item) => item.value === selected.value,
                            );
                            if (item) {
                                selectedRegions.push(item.value);
                                setSelectedRegions([
                                    ...selectedRegions,
                                ]);
                                promotion.regions = selectedRegions;
                                setPromotion(promotion);
                            }
                        }}
                        onDelete={(deleted: any) => {
                            const selected = selectedRegions.filter(r => r !== deleted.value)
                            setSelectedRegions([
                                ...selected,
                            ]);
                            promotion.regions = selected;
                            setPromotion(promotion);
                        }}
                        hideStore={false}
                    />
                </div>
            </div>
            <div className="col-12 mb-4">
                <div className="mb-2">
                    Organization
                </div>
                <NCPreviewSearch
                    searchFields={{
                        search: { label: 'Label1' },
                    }}
                    placeHolder={
                        selectedOrganization
                            ? selectedOrganization.name
                            : ''
                    }
                    list={[{name: 'None', domain: null}, ...allOrganizations]}
                    displayParam="name"
                    hideStore={false}
                    onSelection={(e) => {
                        setSelectedOrganization(e);
                        promotion.organization = e.domain;
                        setPromotion(promotion);
                    }}
                    value={
                        selectedOrganization
                            ? selectedOrganization.name
                            : undefined
                    }
                />
            </div>
            <div className="col-12 mb-4">
                <NCInput
                    label="Tournament Id"
                    value={tournamentId}
                    onChange={(text) => {
                        setTournamentId(text);
                        promotion.tournamentId = text;
                        setPromotion(promotion);
                    }}
                    type={'text'}
                />
            </div>
            <div className="col-12 text-center mb-4">
                <div className="mb-2">Display on homepage</div>
                <div>
                    <NCSwitch
                        checked={displayHomepage}
                        onChange={(e) => {
                            setDisplayHomepage(e);
                            promotion.displayOnHomepage = e;
                            setPromotion(promotion);
                        }}
                    />
                </div>
            </div>


            <div className="col-12 mb-4">
                <NCPromotionalBanner
                    image={image}
                    text={[description]}
                    buttonText={buttonLabel}
                    buttonLink={buttonLink}
                />
            </div>

            <NCActions
                actions={[
                    {
                        disabled: promotion.name.length < 1 || !promotion.image.en || promotion.image.en.length < 1,
                        label: 'save',
                        theme: ButtonTheme.CLASSIC,
                        setClick: () => {
                            savePromotion()
                        },
                    },
                    {
                        label: 'cancel',
                        theme: ButtonTheme.CLASSIC,
                        type: ButtonType.SECONDARY,
                        setClick: () => {
                            props.actionHook(false);
                        },
                    },
                ]}
            />
        </div>
    )
};