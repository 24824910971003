import { Game } from "./Game";
import { TranslatedString } from "./TranslatedString";

export class TrainingQuiz {
    constructor(
        public _id: string,
        public published: boolean,
        public questions: Array<TrainingQuestion>,
        public name: TranslatedString,
        public game: Game,
    ) {}

}

export class Choices {
    constructor(
        public name: TranslatedString,
        public answerType: any,
        public selected: boolean = false

    ) {}
}

export class TrainingQuestion {
    constructor(
        public _id: string,
        public published: boolean,
        public comment: TranslatedString,
        public name: TranslatedString,
        public level: number,
        public choices: Array<Choices>,
    ) {}
}