import { useState, useCallback } from "react";
import qs from 'query-string';

const qsOptions: qs.ParseOptions & qs.StringifyOptions = { arrayFormat: 'bracket' };

const setHashStringWithoutPageReload = (qsValue: string) => {
  window.location.hash = qsValue;
};

const setHashStringValue = (key: string, value: any, queryString = window.location.hash) => {
  const values = qs.parse(queryString, qsOptions);
  const newQsValue = qs.stringify({ ...values, [key]: value }, qsOptions);

  setHashStringWithoutPageReload(newQsValue);
};

function getHashStringValue<T>(key: string, queryString = window.location.hash): T {
  const values = qs.parse(queryString, qsOptions);

  return values[key] as any;
}

function useQueryState<T>(key: string, initialValue: T): [T, React.Dispatch<React.SetStateAction<T>>] {
  const [value, setValue] = useState<T>(getHashStringValue<T>(key) || initialValue);
  const onSetValue = useCallback(
    (newValue: any) => {
      setValue(newValue);
      setHashStringValue(key, newValue);
    },
    [key]
  );

  return [value, onSetValue];
}

export default useQueryState;
