import Button from '@material-ui/core/Button/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel/FormControlLabel';
import Switch from '@material-ui/core/Switch/Switch';
import TextField from '@material-ui/core/TextField/TextField';
import React, {useEffect, useState} from 'react';
import {toast} from 'react-toastify';
import {Voucher} from 'src/models/Voucher';
import {VoucherService} from 'src/services/voucher.service';
import {OrganizationModel} from 'src/models/Organization';
import {OrganizationService} from 'src/services/organization.service';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

interface VoucherFormProps {
    actionHook: any;
    voucher: Voucher;
    creationMode?: boolean;
}

export const VoucherForm: React.FunctionComponent<VoucherFormProps> = ({actionHook, voucher, creationMode}) => {
    const [voucherState, setVoucherState] = useState<Voucher>(voucher);
    const [edit, setEdit] = useState<boolean>(false);

    const [organizations, setOraganisations] = useState<Array<OrganizationModel>>();
    const [selectedOrganization, setSelectedOrganization] = useState<OrganizationModel>();

    useEffect(() => {
        OrganizationService.getOrganizations().then((data) => {
            setOraganisations(data);
        });
    }, []);

    const saveVoucher = () => {
        if (voucherState) {
            if (edit) {
                toast.error('Can\'t modify voucher');
            } else {
                VoucherService.createVoucher(voucherState).then(() => {
                    toast.success('Voucher created');
                    actionHook(true);
                });
            }
        }
    };

    const onVoucherChange = (event: any) => {
        const {name, value} = event.currentTarget;
        setVoucherState(Object.assign({}, voucherState, {
            [name]: value,
        }));
    };

    const onDateChange = (event: any) => {
        const {name, value} = event.currentTarget;
        setVoucherState(Object.assign({}, voucherState, {
            [name]: new Date(value).valueOf(),
        }));
    };

    const handleOrganization = (event: any) => {
        setSelectedOrganization(event.target.value);
        voucherState.organisation = event.target.value;
        setVoucherState(voucherState);
    };


    return (
        <div className="w-100">
            <div className="w-100 d-flex">
                <div className="w-50">
                    {
                        !creationMode &&
                        <FormControlLabel
                            control={<Switch color="primary" checked={edit} onChange={() => setEdit(!edit)}/>}
                            label="Edit"
                            labelPlacement="start"
                        />
                    }

                </div>
                <div className="w-50">
                    {
                        (edit || creationMode) &&
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                saveVoucher();
                            }}
                        >
                            Save
                        </Button>
                    }
                </div>
            </div>
            <div className="w-100">
                <div className="d-flex mb-3">
                    <TextField
                        disabled={!edit && !creationMode}
                        label="Name"
                        value={voucherState.name}
                        className="w-100"
                        name="name"
                        onChange={(event: any) => {
                            onVoucherChange(event);
                        }}
                    />
                </div>
                <div className="d-flex mb-3">
                    <TextField
                        disabled={!edit && !creationMode}
                        label="Operation"
                        value={voucherState.operation}
                        className="w-50"
                        name="operation"
                        onChange={(event: any) => {
                            onVoucherChange(event);
                        }}
                    />
                    <FormControl className="w-50 ml-2">
                        <InputLabel id="demo-simple-select-label">Organization</InputLabel>
                        <Select
                            label="Organizations"
                            name="organizations"
                            value={selectedOrganization}
                            onChange={handleOrganization}
                            disabled={!edit && !creationMode}
                        >
                            {
                                organizations &&
                                organizations.map((organization: OrganizationModel) => {
                                    return (
                                        <MenuItem key={organization._id}
                                                  value={organization._id}>{organization.name}</MenuItem>
                                    );
                                })
                            }
                        </Select>
                    </FormControl>
                </div>
                <div className="d-flex mb-3">
                    <TextField
                        name="startDate"
                        label="Start Date"
                        type="datetime-local"
                        defaultValue={(new Date(voucherState.startDate)).toISOString().slice(0, 16)}
                        className="w-50"
                        onChange={(event: any) => {
                            onDateChange(event);
                        }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    <TextField
                        name="endDate"
                        label="End Date"
                        type="datetime-local"
                        defaultValue={(new Date(voucherState.startDate)).toISOString().slice(0, 16)}
                        className="ml-2 w-50"
                        onChange={(event: any) => {
                            onDateChange(event);
                        }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </div>
                <div className="d-flex mb-3">
                    <TextField
                        disabled={!edit && !creationMode}
                        label="Number Given"
                        value={voucherState.numberGiven}
                        className="w-50"
                        name="numberGiven"
                        type="number"
                        onChange={(event: any) => {
                            onVoucherChange(event);
                        }}
                    />
                    <TextField
                        disabled={!edit && !creationMode}
                        label="Number Used"
                        value={voucherState.numberUsed}
                        className="ml-2 w-50"
                        type="number"
                        name="numberUsed"
                        onChange={(event: any) => {
                            onVoucherChange(event);
                        }}
                    />
                </div>
                <div className="d-flex mb-3">
                    <TextField
                        disabled={!edit && !creationMode}
                        label="Status to increase"
                        value={voucherState.statusToIncrease}
                        className="w-50"
                        name="statusToIncrease"
                        onChange={(event: any) => {
                            onVoucherChange(event);
                        }}
                    />
                    <TextField
                        disabled={!edit && !creationMode}
                        label="Number of days to increase"
                        value={voucherState.numberOfDaysToIncrease}
                        className="ml-2 w-50"
                        type="number"
                        name="numberOfDaysToIncrease"
                        onChange={(event: any) => {
                            onVoucherChange(event);
                        }}
                    />
                </div>
            </div>
        </div>
    );
};