import React, {ChangeEvent, useEffect, useState,} from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import {TabContext} from '@material-ui/lab';
import AppBar from '@material-ui/core/AppBar';
import TabList from '@material-ui/lab/TabList';
import {Languages} from 'src/models/Languages';
import {CircularProgress, Tab} from '@material-ui/core';
import TabPanel from '@material-ui/lab/TabPanel';
import {useStoreState} from 'src/store';
import {EModuleImage, Mode, TrainingModule} from 'src/models/TrainingModule';
import {CompanyService} from 'src/services/company.service';
import {TrainingService} from 'src/services/training.service';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import {TrainingProgram} from 'src/models/TrainingProgram';
import MenuItem from '@material-ui/core/MenuItem';
import {Game} from 'src/models/Game';
import {Company} from 'src/models/Company';
import {NCTitle} from 'src/atoms/NCTitle/NCTitle';
import {MediaManager} from 'src/components/General/MediaManager/MediaManager';
import { TranslatedString } from 'src/models/TranslatedString';


interface CreateModuleFormProps {
    module: any;
    setModule: any;
    creationMode?: boolean;
    actionHook: any;
}

export const CreateModuleForm: React.FunctionComponent<CreateModuleFormProps> = (props: CreateModuleFormProps) => {
    const [edit, setEdit] = useState(false);
    const [module, setModule] = useState<TrainingModule>(props.module);
    const [languages] = useState(useStoreState(state => state.languages));
    const [langValue, setLangValue] = useState(useStoreState(state => state.contentLang));
    const [selectedMode, setSelectedMode] = useState<string>(module.mode);
    const [gameList] = useState(useStoreState(state => state.gameList));
    const [selectedGame, setSelectedGame] = useState<string>(module.game);
    const [selectedProgram, setSelectedProgram] = useState<string | undefined>(module.program || '');
    const [programList, setProgramList] = useState<Array<TrainingProgram>>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [state, setState] = useState({
        comingSoon: module.comingSoon,
    });
    const [companyList, setCompanyList] = useState<Array<Company>>([]);
    const [selectedCompany, setSelectedCompany] = useState<string>(module.company);

    useEffect(() => {
        TrainingService.getPrograms().then((data) => {
            setProgramList(data.docs);
        }).catch(error => console.log(error));
        CompanyService.getCompanies().then((data) => {
            setCompanyList(data.docs);
        }).catch(error => console.log(error));
        setIsLoading(false);
    }, []);


    const handleTabChange = (event: React.ChangeEvent<HTMLInputElement>, newValue: string) => {
        setLangValue(newValue);
    };

    const handleMode = (event: any) => {
        setSelectedMode(event.target.value);
        module.mode = event.target.value;
        setModule(module);
    };

    const handleGame = (event: any) => {
        setSelectedGame(event.target.value);
        module.game = event.target.value;
        setModule(module);
    };

    const handleProgram = (event: any) => {
        setSelectedProgram(event.target.value);
        module.program = event.target.value;
        setModule(module);
    };

    const handleCompany = (event: any) => {
        setSelectedCompany(event.target.value);
        module.company = event.target.value;
        setModule(module);
    };

    const renderEditButton = () => {
        if (props.creationMode) {
            return <></>;
        }
        return (
            <FormControlLabel
                value="edit"
                control={<Switch color="primary" checked={edit} onChange={() => {
                    setEdit(!edit);
                }}/>}
                label="Edit"
                labelPlacement="start"
            />
        );
    };

    const save = () => {
        if (edit) {
            TrainingService.updateModule(module._id, module).then(() => {
                props.actionHook(true);
            });
        } else {
            TrainingService.createModule(module).then(() => {
                props.actionHook(true);
            });
        }
    };

    const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setState({...state, [event.target.name]: event.target.checked});
        setModule({ ...module, [event.target.name]: event.target.checked});
    };

    const onFieldChange = (event: any, isTranslatedString = false, lang: string) => {
        const {name, value} = event.currentTarget;
        if (isTranslatedString) {
            const myLang = module[name as keyof TrainingModule] as TranslatedString;
            myLang[lang] = value;
            setModule({ ...module, [name]: myLang});
        } else {
            setModule(Object.assign({}, module, {
                [name]: value,
            }));
        }
    };

    const renderSaveButton = () => {
        if (!edit && !props.creationMode) {
            return <></>;
        }
        return (
            <Button
                className="mt-5 w-25 mx-auto"
                variant="contained"
                color="primary"
                onClick={() => {
                    save();
                }}
            >
                Save
            </Button>
        );
    };

    return (
        <>
            {
                isLoading && <CircularProgress className="mx-auto"/>
            }
            {
                !isLoading &&
                <form className="d-flex flex-column ">
                    <TabContext value={langValue}>
                        <AppBar position="static">
                            <TabList onChange={(event: React.ChangeEvent<any>, langValue: any) => handleTabChange(event, langValue)}>
                                {
                                    languages.map((lang: Languages) => (
                                        <Tab label={lang.name} value={lang.code} key={lang._id}/>
                                    ))
                                }
                            </TabList>
                        </AppBar>
                        {
                            languages.map((lang: Languages, index: number) => (
                                <TabPanel className="w-100 background my-2 px-5" key={lang._id} value={lang.code}>
                                    {renderEditButton()}
                                    <div className="d-flex flex-row row my-2 ">
                                        {
                                            module._id &&
                                            <TextField
                                                disabled
                                                className="col-12 col-sm-6 px-3 mb-3"
                                                name="_id"
                                                label="ID"
                                                key={index}
                                                defaultValue={module._id}
                                            />

                                        }
                                        <FormControl className="col-12 col-sm-6 mb-3 px-3">
                                            <TextField
                                                disabled={!edit && !props.creationMode}
                                                name="name"
                                                label="Name"
                                                key={index + languages.length}
                                                defaultValue={module.name[lang.code] ? module.name[lang.code] : ''}
                                                onChange={(event: ChangeEvent<HTMLInputElement>) => (onFieldChange(event, true, lang.code))}
                                            />
                                        </FormControl>

                                        <FormControl className="col-12 col-sm-6 mb-3 px-3">
                                            <InputLabel id="demo-simple-select-label">Program</InputLabel>
                                            <Select
                                                label="Program"
                                                name="program"
                                                value={selectedProgram}
                                                onChange={handleProgram}
                                                disabled={!edit && !props.creationMode}
                                            >
                                                {
                                                    programList &&
                                                    programList.map((program: TrainingProgram, index: number) => {
                                                        return (
                                                            <MenuItem key={index} value={program._id}>
                                                                {program.name[lang.code]}
                                                            </MenuItem>
                                                        );
                                                    })
                                                }
                                                <MenuItem key={programList.length} value={''}>
                                                    No program
                                                </MenuItem>
                                            </Select>
                                        </FormControl>

                                        <FormControl className="col-12 col-sm-6 mb-3 px-3">
                                            <InputLabel id="demo-simple-select-label">Mode</InputLabel>
                                            <Select
                                                label="Mode"
                                                name="mode"
                                                value={selectedMode}
                                                onChange={handleMode}
                                                disabled={!edit && !props.creationMode}
                                            >
                                                {
                                                    Mode &&
                                                    Object.values(Mode).map((mode: any, index: number) => {
                                                        return (
                                                            <MenuItem key={index} value={mode}>
                                                                {mode.toLowerCase().charAt(0).toUpperCase() + mode.slice(1).toLowerCase()}
                                                            </MenuItem>
                                                        );
                                                    })
                                                }
                                            </Select>

                                        </FormControl>
                                        <FormControl className="col-12 col-sm-6 mb-3 px-3">
                                            <InputLabel id="demo-simple-select-label">Game</InputLabel>
                                            <Select
                                                label="Game"
                                                name="game"
                                                value={selectedGame}
                                                onChange={handleGame}
                                                disabled={!edit && !props.creationMode}
                                            >
                                                {
                                                    gameList &&
                                                    gameList.map((game: Game, index: number) => {
                                                        return (
                                                            <MenuItem key={index} value={game._id}>
                                                                {game.name}
                                                            </MenuItem>
                                                        );
                                                    })
                                                }
                                            </Select>
                                        </FormControl>
                                        <div className="col-12 col-sm-6 mb-3 d-flex flex-row justify-content-center">
                                            <FormControlLabel
                                                disabled={!edit && !props.creationMode}
                                                value="comingSoon"
                                                control={<Switch color="primary" checked={module.comingSoon}
                                                                 onChange={handleSwitchChange}/>}
                                                label="Coming Soon"
                                                name="comingSoon"
                                                labelPlacement="start"
                                                className="mx-2"
                                            />
                                        </div>
                                        <TextField
                                            disabled={!edit && !props.creationMode}
                                            className="col-12 px-3 mb-3"
                                            name="description"
                                            label="Description"
                                            key={index}
                                            defaultValue={module.description[lang.code] ? module.description[lang.code] : ''}
                                            onChange={(event: ChangeEvent<HTMLInputElement>) => (onFieldChange(event, true, lang.code))}
                                        />
                                        <FormControl className="col-12 col-sm-6 mb-3 px-3">
                                            <InputLabel>Company</InputLabel>
                                            <Select
                                                label="Company"
                                                name="company"
                                                value={selectedCompany || ''}
                                                onChange={handleCompany}
                                                disabled={!edit && !props.creationMode}
                                            >
                                                <MenuItem value={''}>None</MenuItem>
                                                {
                                                    companyList &&
                                                    companyList.map((company: Company, index: number) => {
                                                        return (
                                                            <MenuItem key={index} value={company.slug}>
                                                                {company.name}
                                                            </MenuItem>
                                                        );
                                                    })
                                                }
                                            </Select>
                                        </FormControl>
                                    </div>
                                </TabPanel>
                            ))
                        }
                    </TabContext>
                    {
                        !props.creationMode &&
                        <div className="col-12 mb-3 px-3">
                            <NCTitle text={'Image'} color="white"/>
                            <div className="w-100">
                                <MediaManager
                                    path={['training', 'module']}
                                    id={module._id}
                                    mediaNames={Object.keys(EModuleImage)}
                                />
                            </div>
                        </div>
                    }
                    {renderSaveButton()}
                </form>
            }
        </>
    );
};