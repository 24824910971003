import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import React, {ChangeEvent, useState} from 'react';
import {useStoreState} from 'src/store';
import TabContext from '@material-ui/lab/TabContext';
import AppBar from '@material-ui/core/AppBar';
import TabList from '@material-ui/lab/TabList';
import {Languages} from 'src/models/Languages';
import Tab from '@material-ui/core/Tab';
import TabPanel from '@material-ui/lab/TabPanel';
import {Game} from 'src/models/Game';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import {VideoModel} from 'src/models/Video';
import {MediaService} from 'src/services/media.service';
import { TranslatedString } from 'src/models/TranslatedString';

interface VideoFormProps {
    videoProps: VideoModel;
    actionHook: any;
    creationMode?: boolean;
    gameList: Array<Game>;
}

export const VideoForm: React.FunctionComponent<VideoFormProps> = ({
                                                                       videoProps,
                                                                       actionHook,
                                                                       creationMode,
                                                                       gameList
                                                                   }) => {
    const [video, setVideo] = useState<VideoModel>(videoProps);
    const [languages] = useState(useStoreState(state => state.languages));
    const [edit, setEdit] = useState<boolean>(false);
    const [langValue, setLangValue] = useState(useStoreState(state => state.contentLang));
    const [published, setPublished] = useState<boolean>(videoProps.published || false);


    const saveVideo = () => {
        if (video) {
            video.published = published;
            if (edit) {
                MediaService.updateVideo(video).then(data => {
                    if (!data) {
                        actionHook(false);
                        return;
                    }
                    actionHook(true);
                });
            } else {
                MediaService.createVideo(video).then(data => {
                    if (!data) {
                        actionHook(false);
                        return;
                    }
                    actionHook(true);
                });
            }
        }
    };
    const handleTabChange = (event: React.ChangeEvent<HTMLInputElement>, newValue: string) => {
        setLangValue(newValue);
    };

    const onVideoChange = (event: any, isTranslatedString = false, lang = '') => {
        const {name, value} = event.currentTarget;
        if (isTranslatedString) {
            const myLang = video[name as keyof VideoModel] as TranslatedString;
            myLang[lang] = value;
            setVideo(video);
        } else {
            setVideo(Object.assign({}, video, {
                [name]: value,
            }));
        }
    };

    const onGameChange = (event: any) => {
        video.game = event.target.value;
        for (const game of gameList) {
            if (game._id === event.target.value) {
                video.game = {
                    _id: game._id,
                    name: game.name,
                    value: game.slug,
                };
            }
        }
        setVideo(video);
    };

    return (
        <div className="w-100">
            <div className="w-100 d-flex">
                <div className="w-50">
                    {
                        !creationMode &&
                        <FormControlLabel
                            control={<Switch color="primary" checked={edit} onChange={() => setEdit(!edit)}/>}
                            label="Edit"
                            labelPlacement="start"
                        />
                    }

                </div>
                <div className="w-50">
                    {
                        (edit || creationMode) &&
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                saveVideo();
                            }}
                        >
                            Save
                        </Button>
                    }
                </div>
                <FormControlLabel
                    control={
                        <Switch
                            checked={published}
                            onChange={() => setPublished(!published)}
                            name="Type"
                            color="primary"
                        />
                    }
                    label="Published"
                    className="w-50"
                />
            </div>
            <form className="d-flex flex-column NC-tab">
                <TabContext value={langValue}>
                    <AppBar position="static">
                        <TabList onChange={(event: React.ChangeEvent<any>, langValue: any) => handleTabChange(event, langValue)} aria-label="simple tabs example">
                            {
                                languages.map((lang: Languages) => (
                                    <Tab key={lang._id} label={lang.name} value={lang.code}/>
                                ))
                            }
                        </TabList>
                    </AppBar>
                    {
                        languages.map((lang: Languages) => (
                            <TabPanel className="w-100" key={lang._id} value={lang.code}>
                                <div className="d-flex flex-row row my-2">
                                    <TextField
                                        disabled={!edit && !creationMode}
                                        className="col-12 mb-1 col-md-6 px-3"
                                        name="title"
                                        label="Title"
                                        defaultValue={video.title[lang.code]}
                                        onChange={(event: ChangeEvent<HTMLInputElement>) => (onVideoChange(event, true, lang.code))}
                                    />
                                    <FormControl className="col-12 mb-1 col-md-6 px-3">
                                        <InputLabel>Game</InputLabel>
                                        <Select
                                            disabled={!edit && !creationMode}
                                            labelId="Game"
                                            defaultValue={video.game._id ? video.game._id : ''}
                                            onChange={(event: any) => {
                                                onGameChange(event);
                                            }}
                                            name="game"
                                        >
                                            <MenuItem value=''>Select a game</MenuItem>
                                            {
                                                gameList.map((game) => {
                                                    return (
                                                        <MenuItem value={game._id} key={game._id}>
                                                            {game.name}
                                                        </MenuItem>
                                                    );
                                                })
                                            }
                                        </Select>
                                    </FormControl>
                                    <TextField
                                        disabled={!edit && !creationMode}
                                        className="col-12 mb-1 px-3"
                                        name="link"
                                        label="Link"
                                        defaultValue={video.link[lang.code]}
                                        onChange={(event: ChangeEvent<HTMLInputElement>) => (onVideoChange(event, true, lang.code))}
                                    />
                                </div>
                            </TabPanel>
                        ))
                    }
                </TabContext>
            </form>
        </div>
    );
};