import React, {useEffect, useState} from 'react';
import {NCTitle} from 'src/atoms/NCTitle/NCTitle';
import CircularProgress from '@material-ui/core/CircularProgress';
import {SimpleList} from 'src/components/General/SimpleList/SimpleList';
import {toast} from 'react-toastify';
import {ActionDialog} from 'src/components/General/ActionDialog/ActionDialog';
import {FullScreenDialog} from 'src/components/General/FullScreenDialog/FullScreenDialog';

import { NGPlatformService } from 'src/services/ngplatform.service';
import { NoPlatform } from 'src/models/Platform';
import { NoPlatformForm } from 'src/components/Platform/PlatformForm/NoPlatformForm';

export const Platforms: React.FunctionComponent = () => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [rows, setRows] = useState<any>([]);
    const [actualPage, setActualPage] = useState<number>(0);
    const [totalItem, setTotalItem] = useState<number>(0);
    const [actionDelete, setActionDelete] = useState<boolean>(false);
    const [actionEdit, setActionEdit] = useState<boolean>(false);
    const [actionCreate, setActionCreate] = useState<boolean>(false);
    const [platform, setPlatform] = useState<NoPlatform>();

    const columns = ['ID', 'Name', 'Active'];
    const keepRows = ['id', 'title', 'active'];

    useEffect(() => {
        NGPlatformService.getPlatforms(undefined).then(data => {
            setTotalItem(data.list.length);
            setRows(data.list);
            setIsLoading(false);
        });
    }, [actualPage]);


    const actionManager = (platform: NoPlatform, kind: string) => {
        setPlatform(platform);

        switch (kind) {
            case 'delete':
                setActionDelete(true);
                break;
            case 'edit':
                setActionEdit(true);
                break;
            case 'create': {
                setPlatform(
                    new NoPlatform(
                        '',
                        '',
                        '',
                        1,
                        ''

                    )
                );
                setActionCreate(true);
                break;
            }
            default:
                return;
        }
    };

    const updateList = () => {
        setIsLoading(true);
        setActionCreate(false);
        setActionDelete(false);
        setActionEdit(false);
        NGPlatformService.getPlatforms(undefined).then(data =>{
            setTotalItem(data.list.length);
            setRows(data.list);
            setIsLoading(false);
        });
    };

    const deletePlatform = (choice: boolean) => {
        if (!choice) {
            return;
        }
        if (platform) {
            NGPlatformService.deletePlatform(platform.id).then(data => {
                if (!data) {
                    toast.error('Failed to delete platform');
                    return;
                }
                toast.success('Platform deleted');
                updateList();
            });
        }

    };

    return (
        <div className="NC-container NC-card d-flex flex-column">
            <NCTitle text={'Platforms'}/>
            <div className="d-flex">
                {
                    isLoading && <CircularProgress className="mx-auto"/>
                }
                {
                    !isLoading &&
                    <SimpleList
                        rows={rows}
                        columns={columns}
                        keepRows={keepRows}
                        actionsButtons={{create: true, edit: true, delete: true}}
                        handleClickOpen={actionManager}
                        pagination={{actual: actualPage, total: totalItem, setActual: setActualPage}}
                        translatedString={true}
                    />
                }
            </div>
            <ActionDialog
                open={actionDelete}
                title={`Are you sure to delete this platform : ${platform?.title}`}
                openHook={setActionDelete}
                actionHook={deletePlatform}
            />
            {
                platform &&
                <FullScreenDialog
                    open={actionEdit}
                    openHook={setActionEdit}
                    actionHook={updateList}
                    componentToDisplay={
                        <NoPlatformForm
                            platformProps={platform}
                            actionHook={updateList}
                        />
                    }
                />
            }
            {
                platform &&
                <FullScreenDialog
                    open={actionCreate}
                    openHook={setActionCreate}
                    actionHook={updateList}
                    componentToDisplay={
                        <NoPlatformForm
                            platformProps={platform}
                            actionHook={updateList}
                            creationMode={true}
                        />
                    }
                />
            }
        </div>
    );
};