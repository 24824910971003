import axios from 'axios';

export interface LastKey {
    id: string,
    route: string,
}
export class NGUserService {
    private static baseUrl: string = String(process.env.REACT_APP_NG_URL);
    static token = (): string | null => localStorage.getItem('accessToken');

    // To Check, either bad request format, or if request is made by an admin, games returned are always those of the admin
    static async getUserGames(userId: string) {
        console.log("UserId", userId)
        try {
            const response = await axios.get(
                `${NGUserService.baseUrl}/users/${userId}/games`,
                {
                    headers: {
                        'authorization': NGUserService.token(),
                    }
                }
            );
            console.log("GetGames", response.data)
            return response.data;
        } catch (error) {
            console.error("Error fetching user games:", error);
        }
    }

    static async updateUserGames(userId: string, body: string[]): Promise<boolean> {
        if (!body && !userId) {
            return false;
        }

        console.log("Editing Games for", userId, body)

        try {
            await axios.put(
                `${NGUserService.baseUrl}/users/${userId}/games`,
                { body },
                {
                    headers: {
                        'authorization': NGUserService.token(),
                    }
                }
            );
        } catch {
            return false;
        }

        return true;
    }
}