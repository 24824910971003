import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { NCTitle } from 'src/atoms/NCTitle/NCTitle';
import { UserForm } from 'src/components/User/UserForm/UserForm';
import './UserDetails.scss';
import { NoUserService } from 'src/services/no-user.service';
import { User } from '@wolfiesports/srm-component';


export const UserDetails: React.FunctionComponent = () => {

    const { userId }: any = useParams();
    const navigate = useNavigate();
    const { tab: queryTab } = useParams();

    const [user, setUser] = useState<User | null>(null);
    const [tab, _setTab] = useState<number>(parseInt(queryTab || '', 10) || 0);

    useEffect(() => {
        refreshUser();
        // eslint-disable-next-line
    }, [userId]);

    const setTab = (t: number) => {
        navigate({ search: t !== 0 ? `tab=${t}` : '' });
        _setTab(t);
    };

    const refreshUser = async () => {
        if (!userId) {
            setUser(null);
        }

        await NoUserService.getNoUserById(userId)
            .then(noUser => {
                if (noUser) {
                    setUser(new User(noUser as any));
                }
            })
            .catch(error => {
                console.error("Error fetching user:", error);
            });
    };

    const back = () => {
        navigate('/users');
    };

    if (!user) {
        return (
            <></>
        );
    }

    return (
        <div className="NC-container NC-card user-details">
            <IconButton onClick={back}>
                <FontAwesomeIcon icon={faArrowLeft} />
            </IconButton>
            <NCTitle text={`${user.name}#${user.code}`} />
            <UserForm user={user} updateUser={refreshUser} tab={tab} tabChange={setTab} />
        </div>
    );
};
