import React from 'react';
import CardContent from '@material-ui/core/CardContent';
import Fab from '@material-ui/core/Fab';
import Grid from '@material-ui/core/Grid';
import AddPhotoAlternateIcon from '@material-ui/icons/AddPhotoAlternate';

interface ImageUploadProps {
    uploadMedia: any;
    name: string;
    s3Key: string;
    path: string;
}

export const ImageUpload: React.FunctionComponent<ImageUploadProps> = ({ name, s3Key, path, uploadMedia }) => {

	const handleUploadClick = (event: any) => {
		const file = event.target.files[0];
		const reader = new FileReader();
		reader.onload = (event: any) => {
			uploadMedia(name, path, s3Key, event.target.result.toString());
		};
        
		reader.readAsDataURL(file);
	};

	return (
		<>
			<CardContent>
				<Grid container justify="center" alignItems="center">
					<input
						accept="image/*"
						className="d-none"
						multiple
						id={`button-uploadImage-${s3Key}`}
						type="file"
						onChange={handleUploadClick}
					/>
					<label htmlFor={`button-uploadImage-${s3Key}`}>
						<Fab component="span">
							<AddPhotoAlternateIcon />
						</Fab>
					</label>
				</Grid>
			</CardContent>
		</>
	);
};