import {CircularProgress} from '@material-ui/core';
import React, {useEffect, useState} from 'react';
import {NCTitle} from 'src/atoms/NCTitle/NCTitle';
import {ActionDialog} from 'src/components/General/ActionDialog/ActionDialog';
import {SimpleList} from 'src/components/General/SimpleList/SimpleList';
import {Currency} from 'src/models/Currency';
import {CurrencyService} from 'src/services/currency.service';
import './Currencies';

export const Currencies: React.FunctionComponent = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [rows, setRows] = useState([]);
    const [currency, setCurrency] = useState<Currency>();
    const [deleteAction, setDeleteAction] = useState<boolean>(false);
    const [actualPage, setActualPage] = useState<number>(0);
    const [totalItem, setTotalItem] = useState<number>(0);
    const columns = ['Symbol', 'Picto', 'Active', 'Rate from USD'];
    const keepRows = ['symbol', 'picto', 'active', 'rateFromUSD'];

    useEffect(() => {
        CurrencyService.getCurrencies(20, actualPage).then((data: any) => {
            setTotalItem(Math.ceil(data.total / 20));
            setRows(data.docs);
            setIsLoading(false);
        }).catch(error => console.log(error));
    }, [actualPage]);

    const actionListener = (currency: Currency, kind: string) => {
        setCurrency(currency);

        switch (kind) {
            case 'delete':
                setDeleteAction(true);
                break;
            case 'switchActive':
                updateCurrency(currency);
                break;
            case 'edit':
                break;
            case 'create':
                break;
            default:
                return;
        }
    };

    const getCurrencies = () => {
        CurrencyService.getCurrencies(20, actualPage).then((data: any) => {
            setTotalItem(Math.ceil(data.total / 20));
            setRows(data.docs);
            setIsLoading(false);
        }).catch(error => console.log(error));
    }

    const updateCurrency = (currency: Currency) => {
        currency.active = !currency.active;
        CurrencyService.updateCurrency(currency._id, currency).then(() => {
            getCurrencies();
        }).catch(error => console.log(error));
    }

    const deleteCurrency = (choice: boolean) => {
        if (choice && currency) {
            CurrencyService.deleteCurrency(currency._id).then(() => {
                getCurrencies();
            }).catch(error => console.log(error));
        }
    };

    return (
        <div className="NC-container NC-card">
            <NCTitle text={'Currencies'}/>
            <div className="d-flex">
                {
                    isLoading && <CircularProgress className="mx-auto"/>
                }
                {
                    !isLoading &&
                    <SimpleList
                        rows={rows}
                        columns={columns}
                        keepRows={keepRows}
                        actionsButtons={{switchActive: true}}
                        handleClickOpen={actionListener}
                        pagination={{actual: actualPage, total: totalItem, setActual: setActualPage}}
                    />
                }
            </div>
            <ActionDialog
                open={deleteAction}
                title={`Are you sure to delete this currency : ${currency?.symbol}`}
                openHook={setDeleteAction}
                actionHook={deleteCurrency}
            />
        </div>
    );
};