import React, {ChangeEvent, useEffect, useState} from 'react';
import {Access, EProgramImage, ProgramLevel, TrainingProgram} from 'src/models/TrainingProgram';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import {useStoreState} from 'src/store';
import {Game} from 'src/models/Game';
import {Company} from 'src/models/Company';
import {UserService} from 'src/services/user.service';
import {MediaManager} from 'src/components/General/MediaManager/MediaManager';
import {NCTitle} from 'src/atoms/NCTitle/NCTitle';
import {CompanyService} from 'src/services/company.service';
import { TranslatedString } from 'src/models/TranslatedString';

interface ProgramGeneralProps {
    program: TrainingProgram;
    programChange: any;
    edit: boolean;
    lang: string;
}

export const ProgramGeneral: React.FunctionComponent<ProgramGeneralProps> = ({program, programChange, edit, lang}) => {

    const [selectedAccess, setSelectedAccess] = useState<string>(program.access);
    const [selectedLevel, setSelectedLevel] = useState<string>(program.level);
    const [selectedGame, setSelectedGame] = useState<string>(program.game);
    const [selectedCompany, setSelectedCompany] = useState<string>(program.company);
    const [gameList] = useState(useStoreState(state => state.gameList));
    const [author, setAuthor] = useState<any>();
    const [state, setState] = useState({
        premium: program.premium,
        published: program.published,
    });
    const [companyList, setCompanyList] = useState<Array<Company>>([]);

    useEffect(() => {
        UserService.getUserById(program.author).then((data) => {
            setAuthor(data);
        }).catch(error => console.log(error));
        CompanyService.getCompanies().then((data) => {
            setCompanyList(data.docs);
        }).catch(error => console.log(error));
    }, [program.author, program.company]);


    const onFieldChange = (event: any, isTranslatedString = false) => {
        const {name, value} = event.currentTarget;
        if (isTranslatedString) {
            const myLang = program[name as keyof TrainingProgram] as TranslatedString;
            myLang[lang] = value;
            programChange(program);
        } else {
            programChange(Object.assign({}, program, {
                [name]: value,
            }));
        }
    };

    const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setState({...state, [event.target.name]: event.target.checked});
        programChange({ ...program, [event.target.name]: event.target.checked});
    };

    const handleAccess = (event: any) => {
        setSelectedAccess(event.target.value);
        program.access = event.target.value;
        programChange(program);
    };

    const handleGame = (event: any) => {
        setSelectedGame(event.target.value);
        program.game = event.target.value;
        programChange(program);
    };

    const handleLevel = (event: any) => {
        setSelectedLevel(event.target.value);
        program.level = event.target.value;
        programChange(program);
    };

    const handleCompany = (event: any) => {
        setSelectedCompany(event.target.value);
        program.company = event.target.value;
        programChange(program);
    };

    return (
        <div className="row px-0 px-sm-5 mt-3">
            {program._id &&
            <TextField
                disabled
                className="col-12 col-sm-6 mb-3 px-3"
                name="_id"
                label="ID"
                value={program._id}
            />
            }
            {author &&
            <TextField
                disabled
                className="col-12 col-sm-6 mb-3 px-3"
                name="author"
                label="Author"
                value={`${author.nickname} / ${author.email}`}
            />
            }
            <TextField
                disabled={!edit}
                className="col-12 col-sm-6 mb-3 px-3"
                name="name"
                label="Name"
                defaultValue={program.name[lang] || ''}
                onChange={(event: ChangeEvent<HTMLInputElement>) => (onFieldChange(event, true))}
                helperText={program.name['en'] ? `EN : "${program.name['en']}"` : `FR : "${program.name['fr']}"`}
            />
            <div className="col-12 col-sm-6 mb-3 d-flex flex-row justify-content-center">
                <FormControlLabel
                    disabled={!edit}
                    value="premium"
                    control={<Switch color="primary" checked={program.premium} onChange={handleSwitchChange}/>}
                    label="Premium"
                    name="premium"
                    labelPlacement="start"
                    className="mx-2"
                />
                <FormControlLabel
                    disabled={!edit}
                    value="published"
                    name='published'
                    control={<Switch color="primary" checked={program.published} onChange={handleSwitchChange}/>}
                    label="Published"
                    labelPlacement="start"
                    className="mx-2"
                />
            </div>
            <FormControl className="col-12 col-sm-6 mb-3 px-3">
                <InputLabel id="demo-simple-select-label">Game</InputLabel>
                <Select
                    label="Game"
                    name="game"
                    value={selectedGame}
                    onChange={handleGame}
                    disabled={!edit}
                >
                    {
                        gameList &&
                        gameList.map((game: Game, index: number) => {
                            return (
                                <MenuItem key={index} value={game._id}>
                                    {game.name}
                                </MenuItem>
                            );
                        })
                    }
                </Select>
            </FormControl>
            <FormControl className="col-12 col-sm-6 mb-3 px-3">
                <InputLabel id="demo-simple-select-label">Access</InputLabel>
                <Select
                    label="Access"
                    name="access"
                    value={selectedAccess}
                    onChange={handleAccess}
                    disabled={!edit}
                >
                    {
                        Access &&
                        Object.values(Access).map((access: any, index: number) => {
                            return (
                                <MenuItem key={index} value={access}>
                                    {access.toLowerCase().charAt(0).toUpperCase() + access.slice(1).toLowerCase()}
                                </MenuItem>
                            );
                        })
                    }
                </Select>
            </FormControl>
            <FormControl className="col-12 col-sm-6 mb-3 px-3">
                <InputLabel id="demo-simple-select-label">Level</InputLabel>
                <Select
                    label="Level"
                    name="level"
                    value={selectedLevel}
                    onChange={handleLevel}
                    disabled={!edit}
                >
                    {
                        ProgramLevel &&
                        Object.values(ProgramLevel).map((level: any, index: number) => {
                            return (
                                <MenuItem key={index} value={level}>
                                    {level.toLowerCase().charAt(0).toUpperCase() + level.slice(1).toLowerCase()}
                                </MenuItem>
                            );
                        })
                    }
                </Select>
            </FormControl>
            <TextField
                disabled={!edit}
                className="col-12 col-sm-6 mb-3 px-3"
                name="estimatedDuration"
                label="Estimated Duration"
                defaultValue={program.estimatedDuration[lang] || ''}
                onChange={(event: ChangeEvent<HTMLInputElement>) => (onFieldChange(event, true))}
                helperText={program.estimatedDuration['en'] ? `EN : "${program.estimatedDuration['en']}"` : `FR : "${program.estimatedDuration['fr']}"`}
            />
            <TextField
                disabled={!edit}
                className="col-12 col-sm-6 mb-3 px-3"
                name="mode"
                label="Mode"
                defaultValue={program.mode[lang] || ''}
                onChange={(event: ChangeEvent<HTMLInputElement>) => (onFieldChange(event, true))}
                helperText={program.mode['en'] ? `EN : "${program.mode['en']}"` : `FR : "${program.mode['fr']}"`}
            />
            <TextField
                disabled={!edit}
                className="col-12 col-sm-6 mb-3 px-3"
                name="requiredLevel"
                label="Required Level"
                defaultValue={program.requiredLevel[lang] || ''}
                onChange={(event: ChangeEvent<HTMLInputElement>) => (onFieldChange(event, true))}
                helperText={program.requiredLevel['en'] ? `EN : "${program.requiredLevel['en']}"` : `FR : "${program.requiredLevel['fr']}"`}
            />
            <FormControl className="col-12 col-sm-6 mb-3 px-3">
                <InputLabel>Company</InputLabel>
                <Select
                    label="Company"
                    name="company"
                    value={selectedCompany || ''}
                    onChange={handleCompany}
                    disabled={!edit}
                >
                    <MenuItem value={''}>None</MenuItem>
                    {
                        companyList &&
                        companyList.map((company: Company, index: number) => {
                            return (
                                <MenuItem key={index} value={company.slug}>
                                    {company.name}
                                </MenuItem>
                            );
                        })
                    }
                </Select>
            </FormControl>

            <div className="col-12 col-sm-6 mb-3 px-3">
                <NCTitle text={'Image'} color="white"/>
                <MediaManager
                    path={['training', 'program']}
                    id={program._id}
                    mediaNames={Object.keys(EProgramImage)}
                />
            </div>
        </div>
    );
};