import { TranslatedString } from "./TranslatedString";

export class HomepageCarouselModel {
    _id: string;
    date_creation: Date = new Date();
    countries: Array<string> = [];
    published: boolean = false;

    constructor(
        public title: TranslatedString,
        public description: TranslatedString,
        public image: string,
        public link: string,
        public mobileImage: string,
        public video: string,
        public countryGroup: Array<string>,
    ) {
    }
}

export type HomepageCarouselModelKey = keyof HomepageCarouselModel;