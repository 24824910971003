import axios, { AxiosError } from 'axios';
import { toast } from 'react-toastify';
import { Administrator, AdministratorsLanguage } from 'src/models/Administrator';
import { store } from 'src/store';
import jwt_decode from "jwt-decode";
import { ICognitoUserId } from 'src/session/types';
import {
    ListUsersCommand,
    CognitoIdentityProviderClient,
} from "@aws-sdk/client-cognito-identity-provider";
import { LangService } from './lang.service';
import { GameService } from './game.service';
export class AdministratorService {
    private static baseUrl: string = String(process.env.REACT_APP_ADMINAPI_URL);
    private static actions = store.getActions();

    static token = (): string | null  => localStorage.getItem('accessToken');

    static async login(email: string, password: string): Promise<any> {
        let res;
        try {
            res = await axios.post(
                `${AdministratorService.baseUrl}/administrators/login`,
                { email, password }
            );
            localStorage.setItem('refreshToken', res.data.refreshToken);
            localStorage.setItem('accessToken', res.data.accessToken);
            localStorage.setItem('IsLoggedIn', 'true');
            await AdministratorService.getCurrentAdministrator(res.data.accessToken, res.data.refreshToken);
        } catch (e) {
            throw (e as AxiosError).response;
        }
        return res.data;
    }

    static async getAdministrators(limit: number = 20, paginationToken: string = ""): Promise<any> {
        /*let res;

        try {
            res = await axios.get(
                `${AdministratorService.baseUrl}/administrators?limit=${limit}&page=${page}`, {
                    headers: {
                        'x-access-token': AdministratorService.token(),
                    }
                }
            );
        } catch (e) {
            throw (e as AxiosError).response;
        }*/
        try {
            const client = new CognitoIdentityProviderClient({ region: process.env.REACT_APP_AWS_REGION });
            const command = new ListUsersCommand({
                UserPoolId: process.env.REACT_APP_AWS_USER_POOL_ID,
                Limit: limit,
                PaginationToken: paginationToken
            });
        
            return client.send(command);
        } catch (e: any) {
            console.log(e);
            return null
        }
    }

    static async getAdministratorsByCompany(limit: number = 20, page: number = 0, company: string): Promise<any> {
        let res;

        try {
            res = await axios.get(
                `${AdministratorService.baseUrl}/administrators/by-company?limit=${limit}&page=${page}&company=${company}`, {
                    headers: {
                        'x-access-token': AdministratorService.token(),
                    }
                }
            );
        } catch (e) {
            throw (e as AxiosError).response;
        }

        return {
            docs: res.data.docs,
            pages: page,
            total: res.data.total,
        }
    }

    static async getCurrentAdministrator(token: string, authToken: string): Promise<Administrator> {
        if (!authToken) {
            AdministratorService.actions.logOutAction();
        }
        let admin: Administrator;
        try {
            console.log('token', authToken);
            const decoded = jwt_decode(authToken) as ICognitoUserId;
            console.log('decoded', decoded);
            admin = new Administrator(
                decoded.email,
                '',
                decoded.first_name,
                decoded.last_name,
                AdministratorsLanguage.ENGLISH
            );
            admin.role = decoded['cognito:groups'];
            /*admin._id = res.data.administrator._id;
            admin.role = res.data.administrator.role;
            admin.company = res.data.administrator.company;
            admin.date_creation = res.data.administrator.date_creation;*/
            console.log(admin);
            AdministratorService.actions.setCurrentAdministrator(admin);
            AdministratorService.actions.setWebsiteLang(admin.favorite_language);
            AdministratorService.actions.setContentLang(admin.favorite_language);
            AdministratorService.actions.setIsLoggedIn(true);
            localStorage.setItem('accessToken', token);

            if (authToken) {
                AdministratorService.actions.setAuthToken(authToken);
                localStorage.setItem('authToken', authToken);
            }
        } catch (e) {
            store.getActions().logOutAction();
            throw (e as AxiosError).response;
        }
        try {
            LangService.getLanguages().then((data) => {
                AdministratorService.actions.setLanguages(data.docs);
            });
            GameService.getAllGames();
        } catch (e) {
            throw (e as AxiosError).response;
        }
        return admin;
    }

    static async refreshToken(refreshToken: string): Promise<any> {
        const administrator = JSON.parse(localStorage.getItem('administrator') as string);
        if (!refreshToken || !administrator|| Object.keys(administrator).length < 1) {
            throw Error;
        }
        let res;
        try {
            res = (await axios.post(
                `${AdministratorService.baseUrl}/administrators/refresh`,
                {
                    id: administrator._id,
                },
                {
                    headers: {
                        'x-refresh-token': refreshToken,
                    }
                }
            )).data;
        } catch {
            throw Error;
        }
        if (res && res.token) {
            localStorage.setItem('accessToken', res.token);
            //await AdministratorService.getCurrentAdministrator(res.token);
        }
        return true;
    }

    static async getAdministratorsById(adminId: any): Promise<any> {
        let res;

        try {
            res = await axios.get(`${AdministratorService.baseUrl}/administrators/by-id/${adminId}`, {
                headers: {
                    'x-access-token': AdministratorService.token(),
                }
            });
        } catch (e) {
            throw (e as AxiosError).response;
        }

        return res.data;
    }

    static async createAdministrator(administrator: any): Promise<Administrator> {
        let res;

        try {
            res = await axios.post(
                `${AdministratorService.baseUrl}/administrators`,
                administrator,
                {
                    headers: {
                        'x-access-token': AdministratorService.token(),
                    }
                }
            );
        } catch (e: any) {
            if (e.response) {
                for (const response of e.response.data.errors) {
                    toast.error(response.msg);
                }
            }
            throw (e as AxiosError).response;
        }

        return new Administrator(
            res.data.email,
            res.data.password,
            res.data.first_name,
            res.data.last_name,
            res.data.favorite_language,
        );
    }

    static async updatePassword(adminId: string, newPassword: string, confirmNewPassword: string): Promise<boolean> {
        if (!adminId && !newPassword && !confirmNewPassword) {
            return false;
        }

        try {
            await axios.put(
                `${AdministratorService.baseUrl}/administrators/${adminId}/password`,
                {
                    newPassword: newPassword,
                    confirmNewPassword: confirmNewPassword,
                }
            );
        } catch (e: any) {
            if (!e.response.data.errors) {
                toast.error('Update password failed');
            } else {
                e.response.data.errors.forEach((e: any) => {
                    if (e.msg === 'administrator.newpassword.too_short') {
                        toast.error('Password to short');
                    }
                    if (e.msg === 'administrator.password.must_contain_uppercase_character_and_one_number') {
                        toast.error('Password must contain one uppercase and one number');
                    }
                    if (e.msg === 'administrator.confirmnewpassword.too_short') {
                        toast.error('Confirm password to short');
                    }
                    if (e.msg === 'administrator.confirmnewpassword.must_contain_uppercase_character_and_one_number') {
                        toast.error('Confirm password must contain one uppercase and one number');
                    }
                });
            }

            return false;
        }
        return true;
    }

    static async updateAdministrator(administrator: any): Promise<boolean> {

        try {
            await axios.put(`${AdministratorService.baseUrl}/administrators/${administrator._id}`, administrator, {
                headers: {
                    'x-access-token': AdministratorService.token(),
                }
            });
        } catch (e) {
            return false;
        }
        return true;
    }

    static async deleteAdministrator(adminId: any): Promise<any> {
        let res;

        try {
            res = await axios.delete(`${AdministratorService.baseUrl}/administrators/${adminId._id}`, {
                headers: {
                    'x-access-token': AdministratorService.token(),
                }
            });
        } catch (e) {
            throw (e as AxiosError).response;
        }

        return res.data;
    }

    static async recordAcitvity(body: any): Promise<void> {
        if (!body) {
            return;
        }

        try {
            await axios.post(
                `${AdministratorService.baseUrl}/administrators/activity`,
                body,
                {
                    headers: {
                        'x-access-token': AdministratorService.token(),
                    }
                }
            );
        } catch {
            return;
        }

        return;
    }

    static async getAdministratorActivity(limit = 20, page = 0, administratorId: string) {
        if (!administratorId) {
            throw Error;
        }

        let res;

        try {
            res = await axios.get(
                `${AdministratorService.baseUrl}/administrators/activity?limit=${limit}&page=${page}&administratorId=${administratorId}`, {
                    headers: {
                        'x-access-token': AdministratorService.token(),
                    }
                }
            );
        } catch (e) {
            throw (e as AxiosError).response;
        }

        return res.data;
    }
}