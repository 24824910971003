export class TournamentMatch {
    constructor(
        public id: string,
        public route: string,
        public coordinate: object,
        public currentRound: number,
        public clusterIndex: number,
        public currentRoundStartDate: Date,
        public date: Date,
        public ep: number,
        public state: MatchState,
        public teamNb: number,
        public teams: Array<TournamentTeam>,
        public transition: Transition,
        public type: number,
        public bo: number,
        public nbMatch: number,
        public gameName: string,
        public setting: MatchSettings,
        public maps: Array<MapMode>,
        public label: string,
        public lData: { [key: string]: string },
        public assignee?: string,
        public fp?: Date,
    ) {}
}


export class Transition {
    constructor(
        public type: number,
        public selection: { [key: string]: Destination },
    ){}
}

export class Destination {
    constructor(
        public pos: number,
        public route: string,
    ){}
}

export class MapMode {
    constructor(
        public name: string,
        public mode: string,
        public thumbnail: string,
    ) {}
}

export enum MatchScoreType {
	Score = 0,
	WinLose = 1,
	Ranking = 2,
}

export  class MatchSettings {
    constructor (
        public delayTimer: number,
        public scoreType: MatchScoreType,
    ) {}
}

export class TournamentMatchConflited {
    constructor (
        public id: string,
        public matchUrl: string,
        public route: string,
        public status: number,
        public subject: number,
        public user: TournamentPlayerConflicted,
        public rDate: Date,
        public name: string,
        public reason: string,
        public p: string,
        public assignee?: string | undefined,
    ) {}
}

export class TournamentPlayerConflicted {
    constructor (
        public account: string,
        public avatar: string | null,
        public captain: true,
        public currentMatch: string,
        public elo: number,
        public email: string,
        public endDate: Date,
        public id: string,
        public name: string,
        public route: string,
        public status: PlayerStatus,
        public teamName: string,
        public teamRoute: string,
        public userId: string,
    ) {}
}

export class TournamentTeam {
    constructor (
        public id: string,
        public avatar: string,
        public checkIn: boolean,
        public fullScore: number,
        public name: string,
        public players: { [key: string]: TournamentPlayer },
        public premium: boolean,
        public registration: Date,
        public rounds: { [key: number]: TournamentTeamRound },
        public route: string,
        public seed: number,
        public tag: string,
        public ranking: number,
        public slug: string,
        public prev: string|null,
        public pos: number,
    ) {}
}

export class TournamentTeamRound {
    constructor (
        public confirmed: boolean,
        public diff: any,
        public rank: number,
        public score: number,
    ) {}
}

export class TournamentPlayer {
    constructor (
        public userId: string,
        public account: string,
        public avatar: string,
        public captain: boolean,
        public elo: number,
        public email: string,
        public endDate: Date,
        public name: string,
        public status: string,
    ) {}
}

export class TournamentProof {
    constructor (
        public round: string,
        public route: string,
        public date: Date,
        public description: string,
        public url: string,
        public user: TournamentPlayer
    ) {}
}

export enum MatchState {
    Waiting = 0,
    CheckIn = 1,
    Playing = 2,
    Confirmation = 3,
    Conflict = 4,
    Ended = 5,
    Cancelled = 6,
    Paused = 7,
}

export enum PlayerStatus {
    NONE = "NONE",
    TRIAL = "TRIAL",
    PREMIUM = "PREMIUM",
}

export enum MatchConflictedStatus {
    Pending = 0,
    InProgress = 1,
    Closed = 2,
}

export enum MatchConflictedSubject {
    General = 0,
    Score = 1,
    Admin = 2,
}

export enum MatchUrgencyStatus {
    Low = 0,
    Medium = 1,
    High = 2,
}