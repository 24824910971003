import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import {Theme} from '@material-ui/core/styles';
import createStyles from '@material-ui/core/styles/createStyles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import React, {useState} from 'react';
import {toast} from 'react-toastify';
import {SkillGameBlock} from '../../../../models/SkillGame';
import {TrainingService} from '../../../../services/training.service';
import {LangPicker} from '../../../General/LangPicker/LangPicker';
import { TranslatedString } from 'src/models/TranslatedString';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formControl: {
            margin: theme.spacing(1),
            minWidth: 120,
            maxWidth: 300,
        },
        chips: {
            display: 'flex',
            flexWrap: 'wrap',
        },
        chip: {
            margin: 2,
        },
        noLabel: {
            marginTop: theme.spacing(3),
        },
    })
);

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

interface SkillGameBlockFormProps {
    block: SkillGameBlock;
    actionHook: any;
    creationMode?: boolean;
}

export const SkillGameBlockForm: React.FunctionComponent<SkillGameBlockFormProps> = ({
                                                                                         block,
                                                                                         actionHook,
                                                                                         creationMode
                                                                                     }) => {
    const [edit, setEdit] = useState<boolean>(false);
    const [lang, setLang] = useState<string>('en');
    const [skillGameBlock, setSkillGameBlock] = useState<SkillGameBlock>(block);
    const [abilities, setAbilities] = useState<Array<string>>(block.abilities);
    const classes = useStyles();

    const saveBlock = () => {
        if (block) {
            skillGameBlock.abilities = abilities;
            setSkillGameBlock(skillGameBlock);
            if (edit) {
                TrainingService.updateSkillGameBlock(skillGameBlock._id, skillGameBlock).then(data => {
                    if (!data) {
                        toast.error('Failed to update block');
                        actionHook(false);
                        return;
                    }
                    toast.success('Block updated');
                    actionHook(true);
                });
            } else {
                TrainingService.createSkillGameBlock(skillGameBlock).then(data => {
                    if (!data) {
                        toast.error('Failed to create block');
                        actionHook(false);
                        return;
                    }
                    toast.success('Block created');
                    actionHook(true);
                });
            }
        }
    };

    const onBlockChange = (event: any, isTranslatedString = false) => {
        const {name, value} = event.currentTarget;

        if (isTranslatedString) {
            const myLang = skillGameBlock[name as keyof SkillGameBlock] as TranslatedString;
            myLang[lang] = value;
            setSkillGameBlock(skillGameBlock);
        } else {
            setSkillGameBlock(Object.assign({}, skillGameBlock, {
                [name]: value,
            }));
        }
    };

    const handleChange = (event: React.ChangeEvent<{ value: any }>) => {
        setAbilities(event.target.value);
    };

    return (
        <div className="w-100">
            <div className="w-100 d-flex">
                <div className="w-50">
                    {
                        !creationMode &&
                        <FormControlLabel
                            control={<Switch color="primary" checked={edit} onChange={() => setEdit(!edit)}/>}
                            label="Edit"
                            labelPlacement="start"
                        />
                    }

                </div>
                <div className="w-50">
                    {
                        (edit || creationMode) &&
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                saveBlock();
                            }}
                        >
                            Save
                        </Button>
                    }
                </div>
            </div>
            <div className="d-flex mb-3">
                {
                    !creationMode &&
                    <TextField
                        disabled
                        label="ID"
                        name="_id"
                        value={skillGameBlock._id}
                        className="w-50"
                    />
                }
                <div className="w-50 mx-auto">
                    <LangPicker
                        setLang={setLang}
                    />
                </div>
            </div>
            <div className="d-flex mb-3">
                <TextField
                    disabled={!edit && !creationMode}
                    label="Name"
                    value={skillGameBlock.name[lang]}
                    className="w-50"
                    name="name"
                    onChange={(event: any) => {
                        onBlockChange(event, true);
                    }}
                />
                <TextField
                    disabled={!edit && !creationMode}
                    label="Value"
                    defaultValue={skillGameBlock.value}
                    className="w-50 ml-3"
                    name="value"
                    onChange={(event: any) => {
                        onBlockChange(event);
                    }}
                />
            </div>
            <div>
                <FormControl className={classes.formControl}>
                    <InputLabel id="demo-mutiple-checkbox-label">Tag</InputLabel>
                    <Select
                        labelId="demo-mutiple-checkbox-label"
                        id="demo-mutiple-checkbox"
                        multiple
                        value={abilities}
                        onChange={handleChange}
                        input={<Input/>}
                        renderValue={(selected) => (selected as string[]).join(', ')}
                        MenuProps={MenuProps}
                    >
                        <MenuItem value={'undefined'}>
                            None
                        </MenuItem>
                        <MenuItem value={'sound'}>
                            Sound
                        </MenuItem>
                        <MenuItem value={'color'}>
                            Color
                        </MenuItem>
                        <MenuItem value={'shape'}>
                            Shape
                        </MenuItem>
                        <MenuItem value={'reaction'}>
                            Reaction
                        </MenuItem>
                    </Select>
                </FormControl>
            </div>
        </div>
    );
};