import AppBar from "@material-ui/core/AppBar/AppBar";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import ListItemText from "@material-ui/core/ListItemText";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Switch from "@material-ui/core/Switch";
import Tab from "@material-ui/core/Tab/Tab";
import Tabs from "@material-ui/core/Tabs/Tabs";
import TextField from "@material-ui/core/TextField";
import { Autocomplete, TabContext } from "@material-ui/lab";
import TabList from "@material-ui/lab/TabList";
import { Editor } from "@tinymce/tinymce-react";
import React, { ChangeEvent, useEffect, useState } from "react";
import { SketchPicker } from "react-color";
import { TabPanel } from "src/atoms/TabPanel/TabPanel";
import { MediaManager } from "src/components/General/MediaManager/MediaManager";
import { Company } from "src/models/Company";
import { Country } from "src/models/Country";
import { Languages } from "src/models/Languages";
import { EOrganizationImage, OrganizationModel } from "src/models/Organization";
import { PartnerModel } from "src/models/Partner";
import { SocialLink } from "src/models/SocialLink";
import { CompanyService } from "src/services/company.service";
import { OrganizationService } from "src/services/organization.service";
import { useStoreState } from "src/store";
import "./OrganizationForm.scss";
import { Game } from "@wolfiesports/srm-component";



interface OrganizationsFormProps {
    organization: any;
    setOrganization: any;
    creationMode?: boolean;
    actionHook: any;
    gameList: Array<Game>;
    countryList: Array<Country>;
    partnerList: Array<PartnerModel>;
}

enum TabsName {
    General = 0,
    WhoAreWe = 1,
    Social = 2,
    Legals = 3,
    Medias = 4,
    Partners = 5,
}

const BACKGROUND_COLOR_REFERENCE = '#1a1a1a';

export const OrganizationForm: React.FunctionComponent<OrganizationsFormProps> = (props: OrganizationsFormProps) => {
    const [organization, setOrganization] = useState<OrganizationModel>(props.organization);
    const [selectedGames, setSelectedGames] = useState<Array<string>>(props.organization.games);
    const [selectedTrainingGames, setSelectedTrainingGames] = useState<Array<string>>(props.organization.trainingGames || []);
    const [value, setValue] = useState(0);
    const [state, setState] = useState({
        active: props.organization.active,
        orgIconVisible: props.organization.orgIconVisible,
        orgRedirectionLink: props.organization.orgRedirectionLink,
    });
    const [primaryColor, setPrimaryColor] = useState<string>(props.organization.color.primary);
    const [secondaryColor, setSecondaryColor] = useState<string>(props.organization.color.secondary);
    const [langValue, setLangValue] = useState(useStoreState(state => state.contentLang));
    const [languages] = useState(useStoreState(state => state.languages));

    const [companies, setCompanies] = useState<Array<Company>>();
    const [selectedCompany, setSelectedCompany] = useState<Company>();
    const [trainingGameList, setTrainingGameList] = useState<Array<Game>>();
    const [selectedCountries, setSelectedCountries] = useState<Array<string>>(props.organization.countries || []);
    const [selectedPartners, setSelectedPartners] = useState<Array<string>>(props.organization.partners || []);

    const [isSecondaryColorAllowed, setIsSecondaryColorAllowed] = useState<boolean>();
    const [isColorsAllowed, setIsColorsAllowed] = useState<boolean>();


    useEffect(() => {
        if (organization) {
            CompanyService.getAllCompanies().then((data) => {
                setCompanies(data);
            });
        }
        setTrainingGameList([]);
        /*GameService.get360Games().then((data) => {
            setTrainingGameList(data);
        });*/
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        setIsColorsAllowed(isAllowedColors(primaryColor, secondaryColor));
        setIsSecondaryColorAllowed(isAllowedSecondary(secondaryColor, BACKGROUND_COLOR_REFERENCE))
        // eslint-disable-next-line
    }, [secondaryColor, primaryColor]);

    if (!organization) {
        return <></>;
    }

    const onOrganizationChange = (event: any) => {
        const {name, value} = event.currentTarget;
        setOrganization(Object.assign({}, organization, {
            [name]: value,
        }));
    };

    const onEditorChange = (content: any) => {
        organization.whoAreWe = content;
        setOrganization(organization);
    };

    const onOrganizationSocialChange = (event: any) => {
        const {name, value} = event.currentTarget;
        if (!organization.social) {
            organization.social = new SocialLink();
        }
        organization.social[name as keyof SocialLink] = value;
        setOrganization(organization);
    };

    const handleGamesChange = (event: any) => {
        organization.games = event.target.value;
        setOrganization(organization);
        setSelectedGames(event.target.value as string[]);
    };

    const handleTrainingGamesChange = (event: any) => {
        organization.trainingGames = event.target.value;
        setOrganization(organization);
        setSelectedTrainingGames(event.target.value as string[]);
    };

    const handleCountriesChange = (event: any) => {
        organization.countries = event.target.value;
        setOrganization(organization);
        setSelectedCountries(event.target.value as string[]);
    };

    const handlePartnersChange = (selectedPartners: Array<string>) => {
        organization.partners = selectedPartners;
        setOrganization(organization);
        setSelectedPartners(selectedPartners);
    }

    const save = () => {
        if (!props.creationMode) {
            OrganizationService.updateOrganization(organization).then(() => {
                props.actionHook(true);
            });
        } else {
            OrganizationService.createOrganization(organization).then(() => {
                props.actionHook(true);
            });
        }
    };

    const renderSaveButton = () => {
        return (
            <Button
                className="mt-5 w-25 mx-auto"
                variant="contained"
                color="primary"
                onClick={() => {
                    save();
                }}
                // FIXME: Comment for now, improvement needed later with good specs
                // disabled={!isColorsAllowed || !isSecondaryColorAllowed}
            >
                Save
            </Button>
        );
    };

    const handleChangeValue = (event: React.ChangeEvent<HTMLInputElement>, newValue: number) => {
        setValue(newValue);
    };

    function a11yProps(index: any) {
        return {
            id: `full-width-tab-${index}`,
            "aria-controls": `full-width-tabpanel-${index}`,
        };
    }

    const handlePrimaryColorChange = (color: any) => {
        organization.color.primary = color.hex;
        setPrimaryColor(color.hex);
    };

    const handlePrimaryChangeComplete = () => {
        setOrganization(organization);
    };

    const handleSecondaryColorChange = (color: any) => {
        organization.color.secondary = color.hex;
        setSecondaryColor(color.hex);
    };

    const handleSecondaryChangeComplete = () => {
        setOrganization(organization);
    };

    const onLegalChange = (event: any, lang: string) => {
        organization.legals[lang] = event.currentTarget.value;
        setOrganization(organization);
    };

    const handleLegalsTabChange = (event: React.ChangeEvent<HTMLInputElement>, newValue: string) => {
        setLangValue(newValue);
    };

    const handleActiveChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const {name, checked} = event.target;
        if (name === "active" && !checked) {
            organization.orgRedirectionLink = false;
            organization.orgIconVisible = false;
            setOrganization(organization);
        }
        setState({...state, [name]: checked});
        setOrganization({ ...organization, [name]: checked});
    };

    const handleCompany = (event: any) => {
        setSelectedCompany(event.target.value);
        organization.company = event.target.value;
    };

    const hexToRgb = (hex: string) => {
        const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
        hex = hex.replace(shorthandRegex, function (m, r, g, b) {
            return r + r + g + g + b + b;
        });

        const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(
            hex,
        );
        return result
            ? {
                  r: parseInt(result[1], 16),
                  g: parseInt(result[2], 16),
                  b: parseInt(result[3], 16),
              }
            : { r: 0, g: 0, b: 0 };
    };

    const luminance = (r: number, g: number, b: number) => {
        const a = [r, g, b].map((v: number) => {
            v /= 255;
            return v <= 0.03928
                ? v / 12.92
                : Math.pow((v + 0.055) / 1.055, 2.4);
        });
        return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
    };

    const isAllowedSecondary = (secondaryColor: string, backgroundColor: string): boolean => {
        const secondaryColorRgb = hexToRgb(secondaryColor);
        const BackgroundRgb = hexToRgb(backgroundColor);
        const colorluminance = luminance(
            secondaryColorRgb.r,
            secondaryColorRgb.g,
            secondaryColorRgb.b,
        );
        const backgroundluminance = luminance(
            BackgroundRgb.r,
            BackgroundRgb.g,
            BackgroundRgb.b,
        );

        const ratio =
            colorluminance > backgroundluminance
                ? (backgroundluminance + 0.05) / (colorluminance + 0.05)
                : (colorluminance + 0.05) / (backgroundluminance + 0.05);
        return ratio <= 1 / 3;
    }

    const isAllowedColors = (primaryColor: string, secondaryColor: string): boolean => {
        const secondaryColorRgb = hexToRgb(secondaryColor);
        const primaryColorRgb = hexToRgb(primaryColor);
        const color1luminance = luminance(
            primaryColorRgb.r,
            primaryColorRgb.g,
            primaryColorRgb.b,
        );
        const color2luminance = luminance(
            secondaryColorRgb.r,
            secondaryColorRgb.g,
            secondaryColorRgb.b,
        );
        const ratio =
            color1luminance > color2luminance
                ? (color2luminance + 0.05) / (color1luminance + 0.05)
                : (color1luminance + 0.05) / (color2luminance + 0.05);
        return ratio <= 1 / 3;
    }

    return (
        <>
            <AppBar position="static" color="default" className="mb-2">
                <Tabs
                    value={value}
                    onChange={(event: React.ChangeEvent<any>, newValue: any) => handleChangeValue(event, newValue)}
                    variant="fullWidth"
                >
                    <Tab label="General" {...a11yProps(0)} />
                    <Tab label="WhoAreWe" {...a11yProps(1)} />
                    <Tab label="Social" {...a11yProps(2)} />
                    <Tab label="Legals" {...a11yProps(3)} />
                    <Tab label="Medias" {...a11yProps(4)} />
                    <Tab label="Partners" {...a11yProps(5)} />
                </Tabs>
            </AppBar>
            <TabPanel value={value} index={TabsName.General}>
                <form className="d-flex flex-column organization-form">
                    <div className="d-flex flex-row row my-2">
                        <div className="col-12 px-3 d-flex flex-row justify-content-center">
                            <FormControlLabel
                                control={
                                    <Switch checked={organization.active}
                                            onChange={handleActiveChange}
                                            name="active"
                                            color="primary"
                                    />
                                }
                                label="Active"
                                labelPlacement="start"
                            />
                            <FormControlLabel
                                control={
                                    <Switch checked={organization.orgIconVisible || false}
                                            onChange={handleActiveChange}
                                            name="orgIconVisible"
                                            color="primary"
                                    />
                                }
                                label="Published"
                                labelPlacement="start"
                            />
                            <FormControlLabel
                                control={
                                    <Switch checked={organization.orgRedirectionLink || false}
                                            onChange={handleActiveChange}
                                            name="orgRedirectionLink"
                                            color="primary"
                                    />
                                }
                                label="Redirection Link"
                                labelPlacement="start"
                            />
                            <FormControlLabel
                                control={
                                    <Switch checked={organization.hasTrainingGames || false}
                                            onChange={handleActiveChange}
                                            name="hasTrainingGames"
                                            color="primary"
                                    />
                                }
                                label="Training Games"
                                labelPlacement="start"
                            />
                            <FormControlLabel
                                control={
                                    <Switch checked={organization.ctaPremium}
                                            onChange={handleActiveChange}
                                            name="ctaPremium"
                                            color="primary"
                                    />
                                }
                                label="Cta Premium"
                                labelPlacement="start"
                            />
                        </div>
                        <div className="col-12 px-3">
                            <TextField
                                inputProps={{maxLength: 50}}
                                className="w-100"
                                id="name"
                                name="name"
                                label="Name (max 50 characters)"
                                value={organization.name}
                                onChange={(event: ChangeEvent<HTMLInputElement>) => (onOrganizationChange(event))}
                            />
                            <TextField
                                className="w-50 pr-1 mt-1"
                                id="domain"
                                name="domain"
                                label="Domain"
                                value={organization.domain}
                                onChange={(event: ChangeEvent<HTMLInputElement>) => (onOrganizationChange(event))}
                            />
                            <TextField
                                className="w-50 pl-1 mt-1"
                                id="corner"
                                name="corner"
                                label="Corner"
                                value={organization.corner}
                                onChange={(event: ChangeEvent<HTMLInputElement>) => (onOrganizationChange(event))}
                            />
                            <div className="d-flex flex-row">
                                <TextField
                                    className="w-25 pl-1 mt-1"
                                    id="externalLink"
                                    name="externalLink"
                                    label="External Link (optional)"
                                    value={organization.externalLink}
                                    onChange={(event: ChangeEvent<HTMLInputElement>) => (onOrganizationChange(event))}
                                />
                                <TextField
                                    className="w-25 pl-1 mt-1"
                                    id="externalLinkName"
                                    name="externalLinkName"
                                    label="External Link Name (Mandatory if external link)"
                                    value={organization.externalLinkName}
                                    onChange={(event: ChangeEvent<HTMLInputElement>) => (onOrganizationChange(event))}
                                />
                                <FormControlLabel
                                    control={
                                        <Switch checked={organization.subMenuActive || false}
                                                onChange={handleActiveChange}
                                                name="subMenuActive"
                                                color="primary"
                                        />
                                    }
                                    label="Submenu Active (Show external link on left-menu)"
                                    labelPlacement="start"
                                    className="mb-0"
                                />
                            </div>
                            <FormControl className="w-100 pb-4">
                                <InputLabel>Countries</InputLabel>
                                <Select
                                    multiple
                                    value={selectedCountries}
                                    onChange={handleCountriesChange}
                                    input={<Input/>}
                                    renderValue={(selected) => {
                                        return (
                                            <div>
                                                {
                                                    (selected as Array<string>).map((c: string, index: number) => {
                                                        const country = props.countryList && props.countryList.find(country => country.iso2 === c);
                                                        return <div className="mx-1"
                                                                    key={index}>{country ? country.name : ""}</div>;
                                                    })
                                                }
                                            </div>
                                        );
                                    }}
                                >
                                    {
                                        props.countryList && props.countryList.map((country: Country) => (
                                            <MenuItem key={country._id} value={country.iso2}>
                                                <Checkbox checked={selectedCountries.indexOf(country.iso2) > -1}/>
                                                <ListItemText primary={country.name}/>
                                            </MenuItem>
                                        ))
                                    }
                                </Select>
                            </FormControl>
                        </div>
                    </div>
                    <div className="d-flex flex-row row my-2">
                        <div className="col-12 col-sm-6 px-3 mt-auto d-flex">
                            <div className="w-50 pr-2">
                                <p className="primary-color">
                                    Primary Color:
                                    { !isColorsAllowed &&
                                        <span className="ml-1 error">Primary and secondary color are too close. Please provide a lighter shade.</span>}
                                </p>
                                <SketchPicker className="mx-auto mt-2"
                                              presetColors={[]}
                                              onChange={handlePrimaryColorChange}
                                              onChangeComplete={handlePrimaryChangeComplete}
                                              color={primaryColor}/>
                            </div>
                            <div className="w-50 pl-2">
                                <p className="primary-color">
                                    Secondary Color:
                                    { !isSecondaryColorAllowed &&
                                        <span className="ml-1 error">This color is too dark to be selected. Please provide a lighter shade.</span>}
                                </p>
                                <SketchPicker className="sketch-picker mx-auto mt-2" presetColors={[]}
                                              onChange={handleSecondaryColorChange}
                                              onChangeComplete={handleSecondaryChangeComplete}
                                              color={secondaryColor}/>
                            </div>
                        </div>
                        <div className="col-12 col-sm-6 px-3">
                            <FormControl className="w-100 pb-4">
                                <InputLabel>Games</InputLabel>
                                <Select
                                    multiple
                                    value={selectedGames}
                                    onChange={handleGamesChange}
                                    input={<Input/>}
                                    renderValue={(selected) => {
                                        return (
                                            <div>
                                                {
                                                    (selected as Array<string>).map((g: string, index: number) => {
                                                        const game = props.gameList && props.gameList.find(game => game.id === g);
                                                        return <div className="mx-1"
                                                                    key={index}>{game ? game.title : ""}</div>;
                                                    })
                                                }
                                            </div>
                                        );
                                    }}
                                >
                                    {
                                        props.gameList && props.gameList.map((game: Game) => (
                                            <MenuItem key={game.id} value={game.id}>
                                                <Checkbox checked={selectedGames.indexOf(game.id) > -1}/>
                                                <ListItemText primary={game.title}/>
                                            </MenuItem>
                                        ))
                                    }
                                </Select>
                            </FormControl>
                            {
                                organization.hasTrainingGames &&
                                <FormControl className="w-100 pb-4">
                                    <InputLabel>Training Games</InputLabel>
                                    <Select
                                        multiple
                                        value={selectedTrainingGames}
                                        onChange={handleTrainingGamesChange}
                                        input={<Input/>}
                                        renderValue={(selected) => {
                                            return (
                                                <div>
                                                    {
                                                        (selected as Array<string>).map((g: string, index: number) => {
                                                            const game = trainingGameList && trainingGameList.find(game => game.id === g);
                                                            return <div className="mx-1"
                                                                        key={index}>{game ? game.title : ""}</div>;
                                                        })
                                                    }
                                                </div>
                                            );
                                        }}
                                    >
                                        {
                                            trainingGameList && trainingGameList.map((game: Game) => (
                                                <MenuItem key={game.id} value={game.id}>
                                                    <Checkbox checked={selectedTrainingGames.indexOf(game.id) > -1}/>
                                                    <ListItemText primary={game.title}/>
                                                </MenuItem>
                                            ))
                                        }
                                    </Select>
                                </FormControl>
                            }

                            <FormControl className="w-100">
                                <InputLabel>Company</InputLabel>
                                <Select
                                    label="Companies"
                                    name="companies"
                                    value={selectedCompany}
                                    defaultValue={organization.company}
                                    onChange={handleCompany}
                                >
                                    {
                                        companies &&
                                        companies.map((company: Company) => {
                                            return (
                                                <MenuItem key={company._id}
                                                          value={company._id}>{company.name}</MenuItem>
                                            );
                                        })
                                    }
                                </Select>
                            </FormControl>
                        </div>
                    </div>
                    {renderSaveButton()}
                </form>
            </TabPanel>
            <TabPanel value={value} index={TabsName.WhoAreWe}>
                <form className="d-flex flex-column organization-form">
                    <div className="d-flex flex-row row my-2">
                        <div className="col-12 px-3">
                            <Editor apiKey={process.env.REACT_APP_TINYMCE_KEY}
                                    init={{
                                        height: 400,
                                        a11y_advanced_options: true,
                                        image_title: true,
                                        automatic_uploads: true,
                                        file_picker_types: 'image',
                                        plugins: 'link lists media table image imagetools media code',
                                        toolbar: 'undo redo | styleselect fontselect fontsizeselect removeformat | bold italic | alignleft aligncenter alignright alignjustify | ' +
                                            'bullist numlist outdent indent | image media link | spellchecker | forecolor backcolor',
                                    }}
                                    value={organization.whoAreWe}
                                    onEditorChange={(event: any) => (onEditorChange(event))}
                            />
                        </div>
                    </div>
                    {renderSaveButton()}
                </form>
            </TabPanel>
            <TabPanel value={value} index={TabsName.Social}>
                <form className="d-flex flex-column organization-form">
                    <div className="d-flex flex-row row my-2">
                        <div className="col-12 col-sm-6 px-3">
                            <TextField
                                className="w-100"
                                id="facebook"
                                name="facebook"
                                label="Facebook"
                                defaultValue={organization.social?.facebook}
                                onChange={(event: ChangeEvent<HTMLInputElement>) => (onOrganizationSocialChange(event))}
                            />
                        </div>
                        <div className="col-12 col-sm-6 px-3">
                            <TextField
                                className="w-100"
                                id="twitter"
                                name="twitter"
                                label="Twitter"
                                defaultValue={organization.social?.twitter}
                                onChange={(event: ChangeEvent<HTMLInputElement>) => (onOrganizationSocialChange(event))}
                            />
                        </div>
                        <div className="col-12 col-sm-6 px-3">
                            <TextField
                                className="w-100"
                                id="twitch"
                                name="twitch"
                                label="Twitch"
                                defaultValue={organization.social?.twitch}
                                onChange={(event: ChangeEvent<HTMLInputElement>) => (onOrganizationSocialChange(event))}
                            />
                        </div>
                        <div className="col-12 col-sm-6 px-3">

                            <TextField
                                className="w-100"
                                id="youtube"
                                name="youtube"
                                label="YouTube"
                                defaultValue={organization.social?.youtube}
                                onChange={(event: ChangeEvent<HTMLInputElement>) => (onOrganizationSocialChange(event))}
                            />
                        </div>
                        <div className="col-12 col-sm-6 px-3">
                            <TextField
                                className="w-100"
                                id="steam"
                                name="steam"
                                label="Steam"
                                defaultValue={organization.social?.steam}
                                onChange={(event: ChangeEvent<HTMLInputElement>) => (onOrganizationSocialChange(event))}
                            />
                        </div>
                        <div className="col-12 col-sm-6 px-3">
                            <TextField
                                className="w-100"
                                id="discord"
                                name="discord"
                                label="Discord"
                                defaultValue={organization.social?.discord}
                                onChange={(event: ChangeEvent<HTMLInputElement>) => (onOrganizationSocialChange(event))}
                            />
                        </div>
                        <div className="col-12 col-sm-6 px-3">
                            <TextField
                                className="w-100"
                                id="instagram"
                                name="instagram"
                                label="Instagram"
                                defaultValue={organization.social?.instagram}
                                onChange={(event: ChangeEvent<HTMLInputElement>) => (onOrganizationSocialChange(event))}
                            />
                        </div>
                        <div className="col-12 col-sm-6 px-3">
                            <TextField
                                className="w-100"
                                id="news-feed-tag"
                                name="newsFeedTag"
                                label="News feed tag"
                                defaultValue={organization.newsFeedTag}
                                onChange={(event: ChangeEvent<HTMLInputElement>) => (onOrganizationChange(event))}
                            />
                        </div>
                        <div className="col-12 col-sm-6 px-3">
                            <TextField
                                className="w-100"
                                id="linkedin"
                                name="linkedin"
                                label="Linkedin"
                                defaultValue={organization.social?.linkedin}
                                onChange={(event: ChangeEvent<HTMLInputElement>) => (onOrganizationSocialChange(event))}
                            />
                        </div>
                        <div className="col-12 col-sm-6 px-3">
                            <TextField
                                className="w-100"
                                id="goToLink"
                                name="goToLink"
                                label="Website"
                                defaultValue={organization.social?.goToLink}
                                onChange={(event: ChangeEvent<HTMLInputElement>) => (onOrganizationSocialChange(event))}
                            />
                        </div>
                    </div>
                    {renderSaveButton()}
                </form>
            </TabPanel>
            <TabPanel value={value} index={TabsName.Legals}>
                <form className="d-flex flex-column organization-form">
                    <div className="d-flex flex-row row my-2">
                        <TabContext value={langValue}>
                            <AppBar position="static">
                                <TabList
                                    onChange={(event: React.ChangeEvent<any>, langValue: any) => handleLegalsTabChange(event, langValue)}
                                    className="TabList">
                                    {
                                        languages.map((lang: Languages) => (
                                            <Tab key={lang._id} label={lang.name} value={lang.code}/>
                                        ))
                                    }
                                </TabList>
                            </AppBar>
                            <div className="w-100">
                                {
                                    languages.map((lang: Languages) => (
                                        <TabPanel key={lang._id} value={lang.code} index={langValue}>
                                            <div className="mt-2">
                                                <TextField
                                                    className="w-100"
                                                    id="legals"
                                                    name="legals"
                                                    label="Legals"
                                                    defaultValue={organization.legals[lang.code]}
                                                    onChange={(event: ChangeEvent<HTMLInputElement>) => onLegalChange(event, lang.code)}
                                                />
                                            </div>
                                        </TabPanel>
                                    ))
                                }
                            </div>
                        </TabContext>
                    </div>
                    <Button
                        className="mt-5 w-25 mx-auto"
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            save();
                        }}
                    >
                        Save
                    </Button>
                </form>
            </TabPanel>
            <TabPanel value={value} index={TabsName.Medias}>
                {
                    organization && !props.creationMode &&
                    <MediaManager 
                        id={organization.s3path || organization.domain}
                        path={["media"]} 
                        secondPath={["organizations"]} 
                        slug={organization.s3path || organization.domain}
                        mediaNames={Object.keys(EOrganizationImage)}
                    />
                }
                {
                    props.creationMode &&
                    <p>You must create Organization before update media</p>
                }
            </TabPanel>
            <TabPanel value={value} index={TabsName.Partners}>
                <Autocomplete
                    multiple
                    id="tags-outlined"
                    value={selectedPartners}
                    options={props.partnerList.map(p=>p._id || '')}
                    getOptionLabel={(partnerId: string) => props.partnerList.find(p => p._id === partnerId)?.name || ''}
                    filterSelectedOptions
                    onChange={(_, value) => handlePartnersChange(value)}
                    renderInput={(params    ) => (
                        <TextField
                            {...params}
                            variant="outlined"
                            placeholder="Partners"
                        />
                    )}
                />
                <div className="d-flex justify-content-center">
                    {renderSaveButton()}
                </div>
            </TabPanel>
        </>
    );
};