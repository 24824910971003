import AppBar from '@material-ui/core/AppBar';
import useTheme from '@material-ui/core/styles/useTheme';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import {TabPanel} from 'src/atoms/TabPanel/TabPanel';
import React, {useState} from 'react';
import {HomepageEvent} from 'src/models/HomepageEvent';
import {EHomepageMedia, HomepageGame} from 'src/models/HomepageGame';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Button from '@material-ui/core/Button';
import {HomepageService} from 'src/services/homepage.service';
import {toast} from 'react-toastify';
import {HomepageGameInfo} from 'src/components/Homepage/HomepageGame/HomepageGameInfo/HomepageGameInfo';
import {HomepageEventInfo} from 'src/components/Homepage/HomepageEvent/HomepageEventInfo/HomepageEventInfo';
import {MediaManager} from 'src/components/General/MediaManager/MediaManager';

enum TabsName {
    Info = 0,
    Media = 1,
}

interface HomepageFormProps {
    actionHook: any;
    homepage: HomepageEvent | HomepageGame;
    creationMode?: boolean;
    type: boolean;
}

export const HomepageForm: React.FunctionComponent<HomepageFormProps> = (props: HomepageFormProps) => {
    const [homepage, setHomepage] = useState<HomepageGame | HomepageEvent>(props.homepage);
    const [value, setValue] = useState<number>(0);
    const [edit, setEdit] = useState<boolean>(false);
    const theme = useTheme();

    function a11yProps(index: any) {
        return {
            id: `full-width-tab-${index}`,
            'aria-controls': `full-width-tabpanel-${index}`,
        };
    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>, newValue: number) => {
        setValue(newValue);
    };

    const saveHomepage = () => {
        if (!props.type) {
            if (edit) {
                HomepageService.updateHomePageEvent(homepage as HomepageEvent).then((state) => {
                    if (state) {
                        toast.success('Homepage updated');
                        props.actionHook(true);
                    }
                });
            } else {
                HomepageService.createHomepageEvent(homepage as HomepageEvent).then((state) => {
                    if (state) {
                        toast.success('Homepage created');
                        props.actionHook(true);
                    }
                });
            }
        } else {
            if (edit) {
                HomepageService.updateHomePageGame(homepage as HomepageGame).then((state) => {
                    if (state) {
                        toast.success('Homepage updated');
                        props.actionHook(true);
                    }
                });
            } else {
                HomepageService.createHomepageGame(homepage as HomepageGame).then((state) => {
                    if (state) {
                        toast.success('Homepage updated');
                        props.actionHook(true);
                    }
                });
            }
        }
        props.actionHook(false);
    };

    return (
        <div>
            <AppBar position="static" color="default">
                <Tabs
                    value={value}
                    onChange={(event: React.ChangeEvent<any>, newValue: any) => handleChange(event, newValue)}
                    variant="fullWidth"
                    className="user-form-tabs"
                >
                    <Tab label="Infos" {...a11yProps(0)} />
                    <Tab label="Medias" {...a11yProps(1)} />
                </Tabs>
            </AppBar>
            <div className="w-100 d-flex">
                <div className="w-50">
                    {
                        !props.creationMode &&
                        <FormControlLabel
                            control={<Switch color="primary" checked={edit} onChange={() => setEdit(!edit)}/>}
                            label="Edit"
                            labelPlacement="start"
                        />
                    }

                </div>
                <div className="w-50">
                    {
                        (edit || props.creationMode) &&
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                saveHomepage();
                            }}
                        >
                            Save
                        </Button>
                    }
                </div>
            </div>
            <TabPanel value={value} index={TabsName.Info} dir={theme.direction}>
                {props.type ?
                    <HomepageGameInfo homepage={homepage as HomepageGame} setHomepage={setHomepage} edit={edit}
                                      creationMode={props.creationMode}/> :
                    <HomepageEventInfo homepage={homepage as HomepageEvent} setHomepage={setHomepage} edit={edit}
                                       creationMode={props.creationMode}/>}
            </TabPanel>
            <TabPanel value={value} index={TabsName.Media} dir={theme.direction}>
                {props.type ?
                    <MediaManager optionalArgument={homepage.value} path={['homepage', 'game']} id={homepage.value}
                                  secondPath={['medias']} mediaNames={Object.keys(EHomepageMedia)}/> :
                    <MediaManager optionalArgument={homepage.value} path={['homepage', 'event']} id={homepage.value}
                                  secondPath={['medias']} mediaNames={Object.keys(EHomepageMedia)}/>}
            </TabPanel>
        </div>
    );
};