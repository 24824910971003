import React, { useState } from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import { ReactSRMWrapper } from '@cactus/react-srm-wrapper';
import './Bracket.scss';
import { NCTitle, NCTitleType } from 'src/atoms/NCTitle/NCTitle';

export const Bracket: React.FunctionComponent = () => {
    const originUrl = process.env.REACT_APP_BASE_BUNDLE_URL + '/webapp-bracket';
    const { tournamentid } = useParams();
    const navigate = useNavigate();
    
    const [fullscreen, setFullscreen] = useState<boolean>(false);

    if (!tournamentid || !originUrl) {
        return <></>;
    }
    
    const data = {
        fromBackOffice: true,
        windowed: true,
        tournamentId: tournamentid,
    }
    
    const handleResize = async(data?: { fullscreen: boolean }): Promise<any> => {
        if(data) {
            setFullscreen(data.fullscreen);
        }
    }

    const eventHandlers = {
        'canvas-resize': handleResize
    }

    return (
        <div className={'NC-container NC-card d-flex flex-column'}>
            <NCTitle text={'Tournament Bracket'} type={NCTitleType.TOURNAMENT}/>
            <div className={fullscreen ? 'fullscreen' : ''}>
                <ReactSRMWrapper 
                    originUrl={originUrl} 
                    exportPath="nicecactus.bracket" 
                    basename="/"  
                    language="en" 
                    arguments={data}
                    eventHandlers={eventHandlers}
                    nav={ navigate }
                />
            </div>
        </div>
    );
};