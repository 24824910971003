import React, {ChangeEvent, useState} from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import './LoginForm.scss';
import { useLogin } from 'src/session/session';
import { useNavigate } from 'react-router';

export const LoginForm: React.FunctionComponent = () => {
    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const navigate = useNavigate();
    const login = useLogin();

    const handleLogin = async () => {
        if (email && password) {
            await login.mutate({login: email, password: password}, {onSuccess: () => {
                console.log('logged in');
            }});
            navigate('/');
        }
    };

    const handleKeyDown = (e: any) => {
        if (e.keyCode === 13) {
            handleLogin();
        }
    };

    return (
        <form className="d-flex flex-column">
            <TextField
                id='login_email'
                name="email"
                label="Email"
                className="mb-3"
                value={email}
                onChange={(event: ChangeEvent<HTMLInputElement>) => setEmail(event.currentTarget.value)}
            />
            <TextField
                id='login_password'
                name="password"
                label="Password"
                className="mb-3"
                value={password}
                type="password"
                onChange={(event: ChangeEvent<HTMLInputElement>) => setPassword(event.currentTarget.value)}
                onKeyDown={handleKeyDown}
            />
            <Button
                className="mt-4"
                variant="contained"
                color="primary"
                onClick={() => {
                    handleLogin();
                }}
            >
                Log in
            </Button>
        </form>
    );
};