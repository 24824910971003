import { AppBar, Tabs, Tab, useTheme, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Button } from '@material-ui/core';
import { Add } from '@material-ui/icons'
import { TabPanel } from 'src/atoms/TabPanel/TabPanel';
import React, {useEffect, useState} from 'react';
import { FullScreenDialog } from 'src/components/General/FullScreenDialog/FullScreenDialog';
import { SanctionForm } from './SanctionForm/SanctionForm';
import { NoUserListBan, NoUserService } from 'src/services/no-user.service';
import { BanHistory } from 'src/models/NoUser';
import moment from 'moment';
import { BanType, User } from '@wolfiesports/srm-component';

enum TabsName {
    Notice = 0,
    Sanction = 1,
}

interface UserSanctionProps {
    user: User;
}

export const UserSanction: React.FunctionComponent<UserSanctionProps> = (props: UserSanctionProps) => {
    const theme = useTheme();
    const displayType = ['None', 'Notice', 'Warning', 'Temporary', 'Definitive']
    const displayReason = ['Default', 'Attitude', 'Delay', 'FairPlay', 'Cheat']

    const [ openBanForm, setOpenBanForm ] = useState<boolean>(false);
    const [ sanctions, setSanctions ] = useState<Array<BanHistory>>([]);
    const [ notices, setNotices ] = useState<Array<BanHistory>>([]);
    const [ tab, setTab ] = useState<number>(0);
    const [ noUser, setNoUser ] = useState<User|null>(null);

    useEffect(() => {
        getUser();
        getUserBan();
        // eslint-disable-next-line
    }, []);

    const getUserBan = () => {
        NoUserService.getNoUserBanById(props.user.userId).then((data: NoUserListBan) => {
            setSanctions(data.list.filter(s => s.type !== BanType.NOTICE));
            setNotices(data.list.filter(s => s.type === BanType.NOTICE))
        }).catch(error => console.log(error));
    }

    const injectBan = (bh :BanHistory) => {
        if (bh.type === BanType.NOTICE) {
            const tmp = notices;
            tmp.unshift(bh);
            setNotices([...tmp]);
        } else {
            getUser();
            const tmp = sanctions;
            tmp.unshift(bh);
            setSanctions([...tmp]);
        }
    }

    const getUser = () => {
        NoUserService.getNoUserById(props.user.userId).then((data: User | null) => {
            setNoUser(new User(data as any));
        }).catch(error => console.log(error));
    }

    const a11yProps = (index: number) => {
        return {
            id: `full-width-tab-${index}`,
            'aria-controls': `full-width-tabpanel-${index}`,
        };
    }

    const actionManager = () => {
        setOpenBanForm(true);
    };

    const getTabStats = (array: Array<BanHistory>, tab: number) => {
        switch (tab) {
            case TabsName.Notice:
                return (
                    <div>
                        <div className="d-flex">
                            <span className="primary-color mx-3">{`Notice: ${noUser?.ban.notice}`}</span>
                        </div>
                        {getSanctionList(array)}
                    </div>
                );
            case TabsName.Sanction:
                return (
                    <div>
                        <div className="d-flex">
                            <span className="primary-color mx-3">{`Warning: ${noUser?.ban.warning}`}</span>
                        </div>
                        {getSanctionList(array)}
                    </div>
                );
            default:
                return null;
        }
    }

    const getSanctionList = (array: Array<BanHistory>) => {
        return (
        <div className="w-100 d-flex flex-row mb-4">
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell align="left">Admin</TableCell>
                            <TableCell align="left">Sanction</TableCell>
                            <TableCell align="left">Reason</TableCell>
                            <TableCell align="left">Description</TableCell>
                            <TableCell align="left">Date Start</TableCell>
                            <TableCell align="left">Date End</TableCell>
                            <Button onClick={actionManager}>
                                <Add/>
                            </Button>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            array.map((bh: BanHistory) => {
                                return (
                                    <TableRow key={bh.route}>
                                        <TableCell align="left">{bh.admin}</TableCell>
                                        <TableCell align="left">{displayType[bh.type]}</TableCell>
                                        <TableCell align="left">{displayReason[bh.reason]}</TableCell>
                                        <TableCell align="left">{bh.message}</TableCell>
                                        <TableCell align="left">{bh.date.toString()}</TableCell>
                                        <TableCell align="left">{bh.until?.toString()}</TableCell>
                                    </TableRow>
                                )
                            })
                        }
                    </TableBody>
                </Table>
            </TableContainer>
             {/*<SimpleList
                rows={array}
                columns={columns}
                keepRows={keepRows}
                pagination={{actual: actualPage, total: totalItem, setActual: setActualPage}}
                actionsButtons={{ create: true }}
                handleClickOpen={actionManager}
            />*/}
        </div>
        )
    }

    const sanctionContent = (array: Array<BanHistory>, tab: number) => {
        return (
            <div>
                <div className='text-center'>
                    <div>Current status</div>
                    {renderStatus()}
                </div>
                {getTabStats(array, tab)}
            </div>
        )
    }

    const renderStatus = () => {
        let text = '-';
        let style = 'primary-color';

        if (noUser) {
            switch (noUser.ban.type) {
                case BanType.DEFINITIVE:
                    text = 'PERMANENT BAN';
                    style = 'red-color';
                    break;
                case BanType.TEMPORARY:
                    if ((Date.now() < moment(noUser?.ban.until).unix() * 1000)) {
                        text = 'TEMPORARY BAN';
                        style = 'orange-color';
                        break;
                    }
                // eslint-disable-next-line no-fallthrough
                default:
                    text = 'NONE';
                    break;
            }
        }

        return <div className={style}>{text}</div>;
    }

    return (
        <div className="user-ban pb-4 NC-card">
            <AppBar position="static" color="default" className="mb-2">
                <Tabs
                    value={tab}
                    onChange={(event: React.ChangeEvent<any>, tab: number) => setTab(tab)}
                    variant="scrollable"
                    scrollButtons="auto"
                >
                    <Tab label="Notice" {...a11yProps(0)} />
                    <Tab label="Sanction" {...a11yProps(1)} />
                </Tabs>
            </AppBar>
            <TabPanel value={tab} index={TabsName.Notice} dir={theme.direction}>
                {sanctionContent(notices, TabsName.Notice)}
            </TabPanel>
            <TabPanel value={tab} index={TabsName.Sanction} dir={theme.direction}>
                {sanctionContent(sanctions, TabsName.Sanction)}
            </TabPanel>
            <FullScreenDialog
                open={openBanForm}
                openHook={setOpenBanForm}
                center={true}
                componentToDisplay={
                    <SanctionForm user={props.user} setOpenBanForm={setOpenBanForm} actionHook={injectBan} />
                }
            />
        </div>
    );
};
