import {CircularProgress} from '@material-ui/core';
import { Game, getGamesAdmin } from '@wolfiesports/srm-component';
import React, {useEffect, useState} from 'react';
import {toast} from 'react-toastify';
import {NCTitle} from 'src/atoms/NCTitle/NCTitle';
import { NoGameForm } from 'src/components/Game/NoGameForm/NoGameForm';
import {ActionDialog} from 'src/components/General/ActionDialog/ActionDialog';
import {FullScreenDialog} from 'src/components/General/FullScreenDialog/FullScreenDialog';
import {SimpleList} from 'src/components/General/SimpleList/SimpleList';
import { NGGameService } from 'src/services/nggame.service';

// Only Works on display for now, need to remove bridge from No-Game for Create & Update to Work

export const Games: React.FunctionComponent = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [rows, setRows] = useState<any>([]);
    const [game, setGame] = useState<Game>();
    const [actionDelete, setActionDelete] = useState<boolean>(false);
    const [actionEdit, setActionEdit] = useState<boolean>(false);
    const [actionCreate, setActionCreate] = useState<boolean>(false);
    const [actualPage, setActualPage] = useState<number>(0);
    const [totalItem, setTotalItem] = useState<number>(0);
    const columns = ['ID', 'Active', 'Title'];
    const keepRows = ['id', 'active', 'title'];

    useEffect(() => {
        getGamesAdmin().then((data) => {
            setTotalItem(1);
            setRows(data.list);
            setIsLoading(false);
        }).catch(error => console.log(error));
    }, [totalItem, actualPage]);

    const actionManager = (game: Game, kind: string) => {
        setGame(game);

        switch (kind) {
            case 'delete':
                setActionDelete(true);
                break;
            case 'edit':
                setActionEdit(true);
                break;
            case 'create': {
                setGame(
                    new Game()
                );
                setActionCreate(true);
                break;
            }
            default:
                return;
        }
    };

    const deleteGame = (choice: boolean) => {
        if (choice && game) {
            NGGameService.deleteGame(game.id).then(data => {
                if (!data) {
                    toast.error('Failed to delete game');
                    updateList(false);
                    return;
                }
                toast.success('Game deleted');
                updateList(true);
            });
        }
    };

    const updateList = (result: boolean) => {
        setActionEdit(false);
        setActionCreate(false);
        setActionDelete(false);

        if (result) {
            setIsLoading(true);
            getGamesAdmin().then((data) => {
                setRows(data.list);
                setTotalItem(1);
                setIsLoading(false);
            }).catch(error => console.log(error));
        }
    };

    return (
        <div className="NC-container NC-card d-flex flex-column">
            <NCTitle text={'Games'}/>
            <div className="d-flex">
                {
                    isLoading && <CircularProgress className="mx-auto"/>
                }
                {
                    !isLoading &&
                    <SimpleList
                        rows={rows}
                        columns={columns}
                        keepRows={keepRows}
                        actionsButtons={{create: true, edit: true, delete: true}}
                        handleClickOpen={actionManager}
                        pagination={{actual: actualPage, total: totalItem, setActual: setActualPage}}
                    />
                }
            </div>
            <ActionDialog
                open={actionDelete}
                title={`Are you sure to delete this game : ${game?.title}`}
                openHook={setActionDelete}
                actionHook={deleteGame}
            />
            {
                game &&
                <FullScreenDialog
                    open={actionEdit}
                    openHook={setActionEdit}
                    actionHook={updateList}
                    componentToDisplay={
                        <NoGameForm
                            game={game}
                            actionHook={updateList}
                        />
                    }
                />
            }
            {
                game &&
                <FullScreenDialog
                    open={actionCreate}
                    openHook={setActionCreate}
                    actionHook={updateList}
                    componentToDisplay={
                        <NoGameForm
                            game={game}
                            actionHook={updateList}
                            creationMode={true}
                        />
                    }
                />
            }

        </div>
    );
};