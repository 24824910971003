import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { Currency } from 'src/models/Currency';
import { Country } from 'src/models/Country';
import { CurrencyService } from 'src/services/currency.service';
import { CountryService } from 'src/services/country.service';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import { MenuItem, Select } from '@material-ui/core';
import { useStoreState } from "src/store";
import { Languages } from "src/models/Languages";
import { NoUserService } from 'src/services/no-user.service';
import { User} from '@wolfiesports/srm-component'

interface UserSettingsProps {
    user: User;
    userChange: any
}

export const UserSettings: React.FunctionComponent<UserSettingsProps> = (props: UserSettingsProps) => {
    const [editedUser, setEditedUser] = useState<User>(props.user);
    const [currencies, setCurrencies] = useState<Array<Currency>>();
    const [countries, setCountries] = useState<Array<Country>>();

    const [testCountry, setTestCountry] = useState<string>(props.user.country);
    const [testCurrency, setTestCurrency] = useState<string>(props.user.currency);
    const [userLanguage, setUserLanguage] = useState<string>(props.user.language);
    const [testOptin, setTestOptin] = useState<boolean>(props.user.optIn);
    const [languages] = useState(useStoreState(state => state.languages));

    useEffect(() => {
        CurrencyService.getAllCurrencies().then((data) => {
            setCurrencies(data);
        });

        CountryService.getAllCountries().then((data) => {
            setCountries(data);
        });
    }, []);

    const saveUserInfos = () => {
        NoUserService.updateUserCurrency(props.user.userId, editedUser.currency)
        NoUserService.updateUserLanguage(props.user.userId, editedUser.language)
        NoUserService.updateUserCountry(props.user.userId, editedUser.country)
        NoUserService.updateUserOptin(props.user.userId, editedUser.optIn)
    };

    const handleHasOptedIn = () => {
        setTestOptin(!testOptin);
        editedUser.optIn = !editedUser.optIn;
        setEditedUser(editedUser);
    };

    const handleCountryCode = (event: any) => {
        setTestCountry(event.target.value);
        editedUser.country = event.target.value;
        setEditedUser(editedUser);
    };

    const handleCurrency = (event: any) => {
        setTestCurrency(event.target.value);
        editedUser.currency = event.target.value;
        setEditedUser(editedUser);
    };

    const handleLanguage = (event: any) => {
        setUserLanguage(event.target.value);
        editedUser.language = event.target.value;
        setEditedUser(editedUser);
    };

    const renderSaveButton = () => {
        return (
            <React.Fragment>
                <Button
                    className="w-25"
                    variant="contained"
                    color="primary"
                    onClick={saveUserInfos}
                >
                    Save
                </Button>
            </React.Fragment>
        );
    };

    return (
        <React.Fragment>
            {
                editedUser &&
                <div className="NC-card p-3">
                    <div className="row p-3">
                        <div className="col-md-6 col-12 my-2 px-2">
                            <FormControl className="w-100">
                                <InputLabel id="demo-simple-select-label">Language</InputLabel>
                                <Select
                                    label="Currencies"
                                    name="currencies"
                                    value={userLanguage}
                                    onChange={handleLanguage}
                                >
                                    {
                                        languages && languages.map((language: Languages) => {
                                            return (
                                                <MenuItem key={language._id} value={language.code}>{language.name}</MenuItem>
                                            );
                                        })
                                    }
                                </Select>
                            </FormControl>
                        </div>

                        <div className="col-md-6 col-12 my-2 px-2">
                            <FormControlLabel
                                className="w-100 mx-auto"
                                label="Has opted in"
                                labelPlacement="start"
                                control={
                                    <Switch checked={testOptin} color="primary" onChange={handleHasOptedIn} />
                                }
                            />
                        </div>

                        <div className="col-md-6 col-12 my-2 px-2">
                            <FormControl className="w-100">
                                <InputLabel id="demo-simple-select-label">Currencies</InputLabel>
                                <Select
                                    label="Currencies"
                                    name="currencies"
                                    value={testCurrency}
                                    onChange={handleCurrency}
                                >
                                    {
                                        currencies && currencies.map((currency: Currency) => {
                                            if (currency.active) {
                                                return (
                                                    <MenuItem key={currency._id}
                                                        value={currency.symbol}>{currency.symbol} - {currency.picto}</MenuItem>
                                                );
                                            } else {
                                                return (<div key={currency._id} className="d-none" />);
                                            }
                                        })
                                    }
                                </Select>
                            </FormControl>
                        </div>

                        <div className="col-md-6 col-12 my-2 px-2">
                            <FormControl className="w-100">
                                <InputLabel id="demo-simple-select-label">Country</InputLabel>
                                <Select
                                    label="Countries"
                                    name="countries"
                                    value={testCountry}
                                    onChange={handleCountryCode}
                                >
                                    {
                                        countries && countries.map((country: Country) => {
                                            return (
                                                <MenuItem key={country._id}
                                                    value={country.iso2}>{country.name}</MenuItem>
                                            );
                                        })
                                    }
                                </Select>
                            </FormControl>
                        </div>
                        <div className="col-12 mt-4 d-flex justify-content-center">
                            {renderSaveButton()}

                        </div>
                    </div>


                </div>

            }
        </React.Fragment>
    );
};
