import {AppBar, Button, FormControlLabel, Switch, Tab, Tabs, useTheme} from '@material-ui/core';
import React, {useState} from 'react';
import {toast} from 'react-toastify';
import {TabPanel} from 'src/atoms/TabPanel/TabPanel';
import {Game} from 'src/models/Game';
import {ESeoMedias, Seo} from 'src/models/Seo';
import {SeoService} from 'src/services/seo.service';
import {MediaManager} from 'src/components/General/MediaManager/MediaManager';
import {SeoInfo} from '../SeoInfo/SeoInfo';

enum TabsName {
    Info = 0,
    Media = 1,
}

interface SeoFormProps {
    actionHook: any;
    seo: Seo;
    creationMode?: boolean;
    gameList: Array<Game>;
}

export const SeoForm: React.FunctionComponent<SeoFormProps> = (props: SeoFormProps) => {
    const [seo, setSeo] = useState<Seo>(props.seo);
    const [edit, setEdit] = useState<boolean>(false);
    const [value, setValue] = useState<number>(0);
    const theme = useTheme();
    const a11yProps = (index: any) => {
        return {
            id: `full-width-tab-${index}`,
            'aria-controls': `full-width-tabpanel-${index}`,
        };
    };

    const saveSeo = () => {
        if (seo) {
            if (edit) {
                SeoService.updateSeo(seo).then((data) => {
                    if (data) {
                        toast.success('Seo saved');
                        props.actionHook(true);
                    } else {
                        toast.error('Seo created');
                        props.actionHook(false);
                    }
                });
            } else {
                SeoService.createSeo(seo).then(() => {
                    toast.success('Seo created');
                    props.actionHook(true);
                }).catch(() => {
                    toast.error('Error while creating Seo');
                    props.actionHook(false);
                });
            }
        }
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>, newValue: number) => {
        setValue(newValue);
    };

    return (
        <>
            <div className="w-100">
                <AppBar position="static" color="default">
                    <Tabs
                        value={value}
                        onChange={(event: React.ChangeEvent<any>, newValue: any) => handleChange(event, newValue)}
                        variant="fullWidth"
                        className="user-form-tabs"
                    >
                        <Tab label="Infos" {...a11yProps(0)} />
                        <Tab label="Medias" {...a11yProps(1)} />
                    </Tabs>
                </AppBar>
                <div className="w-100 d-flex">
                    <div className="w-50">
                        {
                            !props.creationMode &&
                            <FormControlLabel
                                control={<Switch color="primary" checked={edit} onChange={() => setEdit(!edit)}/>}
                                label="Edit"
                                labelPlacement="start"
                            />
                        }

                    </div>
                    <div className="w-50">
                        {
                            (edit || props.creationMode) &&
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                    saveSeo();
                                }}
                            >
                                Save
                            </Button>
                        }
                    </div>
                </div>
                <TabPanel value={value} index={TabsName.Info} dir={theme.direction}>
                    {
                        seo &&
                        <SeoInfo
                            seo={seo}
                            edit={edit}
                            setSeo={setSeo}
                            creationMode={props.creationMode}
                            gameList={props.gameList}
                        />
                    }
                </TabPanel>
                {
                    !props.creationMode &&
                    <TabPanel value={value} index={TabsName.Media} dir={theme.direction}>
                        <MediaManager
                            path={['seo']}
                            secondPath={['medias']}
                            id={seo._id}
                            mediaNames={Object.keys(ESeoMedias)}
                        />
                    </TabPanel>
                }
            </div>
        </>
    );
};