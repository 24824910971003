import './MatchSheet.scss';

import {
    Checkbox,
    FormControlLabel,
    IconButton,
    Tab,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    ThemeProvider,
    createTheme
} from '@material-ui/core';
import {
    MapMode,
    MatchScoreType,
    MatchState,
    TournamentMatch,
    TournamentMatchConflited,
    TournamentPlayer,
    TournamentProof,
    TournamentTeam
} from 'src/models/TournamentMatch';
import {NCTitle, NCTitleType} from 'src/atoms/NCTitle/NCTitle';
import React, {useCallback, useEffect, useState} from 'react';
import {TabContext, TabList, TabPanel} from '@material-ui/lab';
import {green, red} from '@material-ui/core/colors';
import {useParams} from 'react-router-dom';

import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import {ActionDialog} from '../../General/ActionDialog/ActionDialog';
import Button from '@material-ui/core/Button';
import CSVReader from 'react-csv-reader';
import CircularProgress from '@material-ui/core/CircularProgress';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import { Link } from 'react-router-dom';
import {MatchMessaging} from '../MatchMessaging/MatchMessaging';
import {MatchRequestsList} from '../MatchRequestsList/MatchRequestsList';
import MenuItem from '@material-ui/core/MenuItem';
import {NtService} from 'src/services/nt.service';
import RefreshIcon from '@material-ui/icons/Refresh';
import {ResultDialog} from '../../General/ResultDialog/ResultDialog';
import Select from '@material-ui/core/Select';
import StarIcon from '@material-ui/icons/Star';
import {Tournament} from 'src/models/Tournament';
import {TournamentTeamInfos} from '../TournamentTeamInfos/TournamentTeamInfos';
import Typography from '@material-ui/core/Typography';
import VisibilityIcon from '@material-ui/icons/Visibility';
import eventSourceSingletonRT from '../../../services/eventSourceSingletonRT';
import {faExternalLinkAlt} from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import {nth} from 'src/utils/nth';
import {toast} from 'react-toastify';
import {useIntl} from 'react-intl';

const errorButtonTheme = createTheme({
    palette: {
        primary: red,
    },
});
const uploadButtonTheme = createTheme({
    palette: {
        primary: green,
    },
});

export const MatchSheet: React.FunctionComponent = () => {
    const intl = useIntl();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isRefreshing, setIsRefreshing] = useState<boolean>(false);
    const [selectedTabInfo, setSelectedTabInfo] = useState<string>('teams');
    const [selectedTabMessage, setSelectedTabMessage] = useState<string>('messages');
    const [match, setMatch] = useState<TournamentMatch>();
    const [proofList, setProofList] = useState<Array<TournamentProof>>([]);
    const [openConfirmState, setOpenConfirmState] = useState<boolean>(false);
    const [newMatchState, setNewMatchState] = useState<string>('');
    const [newCSVResults, setNewCSVResults] = useState<Array<any>>([]);
    const [forceScoresResult, setForceScoresResult] = useState<Array<number>>([]);
    const [tournament, setTournament] = useState<Tournament | null>(null);
    const [roomId, setRoomId] = useState<string>('');
    const {tournamentid, matchid}: any = useParams();
    const { request } = useParams();
    const [requestObject, setRequestObject] = useState<TournamentMatchConflited | null>(null);
    const [actionReset, setActionReset] = useState<boolean>(false);
    const [actionCancel, setActionCancel] = useState<boolean>(false);
    const [actionUpload, setActionUpload] = useState<boolean>(false);
    const [actionForceAll, setActionForceAll] = useState<boolean>(false);
    const [showForceScoreDialogButtons, setShowForceScoreDialogButtons] = useState<boolean>(true);
    const indexedTeams = (match ? match.teams : []).map((team, index) => ({
        team,
        index
    })).filter(team => team.team !== null);
    const teamsPendingCheckIn = indexedTeams.filter(({team}) => !team.checkIn) || [];
    const teamsPendingConfirm = indexedTeams.filter(({team}) => {
        if (!match || !team.rounds) {
            return false;
        }
        const round = team.rounds[match.currentRound];
        if (!round) {
            return false;
        }

        return round.score != null && !round.confirmed;
    }) || [];

    useEffect(() => {
        NtService.getTournamentById(tournamentid).then(tnt => setTournament(tnt));
    }, [tournamentid]);

    const refreshMatchData = useCallback(async (refresh = true) => {
        if (refresh) {
            setIsRefreshing(true);
        }

        return NtService.getMatchById(tournamentid, matchid).then((data) => {
            if (!data) {
                toast.error('Error while getting match');
            }
            setMatch(data);
            setIsLoading(false);
            setIsRefreshing(false);
            setRoomId(`tournaments-${data.id}-${data.route.replace('/', '-')}`);
        });
    }, [matchid, tournamentid]);

    const listenMatchUpdated = () => {
        eventSourceSingletonRT.subscribe(
            'matchUpdated',
            roomId,
            () => localStorage.getItem('authToken'),
            (target, event) => {
                if (!event) {
                    return;
                }
                const newMatch = event.content as TournamentMatch;
                setMatch(newMatch);
            }
        );
        eventSourceSingletonRT.subscribe(
            'proofAdded',
            roomId,
            () => localStorage.getItem('authToken'),
            () => {
                updateProofs();
            }
        );
    };

    const updateProofs = () => {
        NtService.getMatchProof(tournamentid, matchid).then(data => {
            setProofList(data.List);
            updateRequest();
        });
    };

    const updateRequest = () => {
        NtService.getAdminRequest(tournamentid, request || '').then((res) => {
            setRequestObject(res);
        // eslint-disable-next-line
        }, () => {});
    };

    const changeAssignee = async (tournamentId: string , matchId: string) => {
            await NtService.updateMatchAssignee(tournamentId, matchId);
            toast.success('Assignee has changed');
            await refreshMatchData(true);
    };

    useEffect(() => {
        updateProofs();
        refreshMatchData(false);
        // eslint-disable-next-line
    }, [refreshMatchData, tournamentid, matchid]);

    useEffect(() => {
        if (!roomId) {
            return;
        }
        listenMatchUpdated();
        return () => {
            if (!roomId) {
                return;
            }
            eventSourceSingletonRT.unsubscribe('matchUpdated', roomId);
            eventSourceSingletonRT.unsubscribe('proofAdded', roomId);
        };
        // eslint-disable-next-line
    }, [roomId]);

    const renderMatchState = (state: MatchState) => {
        switch (state) {
            case MatchState.Waiting:
            case MatchState.Paused:
                return <span className="yellow-color font-weight-bold text-uppercase">{MatchState[state]}</span>;
            case MatchState.CheckIn:
                return <span className="vivid-orange font-weight-bold text-uppercase">{MatchState[state]}</span>;
            case MatchState.Playing:
                return <span className="primary-color font-weight-bold text-uppercase">{MatchState[state]}</span>;
            case MatchState.Confirmation:
                return <span className="vivid-orange font-weight-bold text-uppercase">{MatchState[state]}</span>;
            case MatchState.Conflict:
                return <span className="red-color font-weight-bold text-uppercase blink">{MatchState[state]}</span>;
            case MatchState.Ended:
                return <span className="primary-color font-weight-bold text-uppercase">{MatchState[state]}</span>;
            case MatchState.Cancelled:
                return <span className="red-color font-weight-bold text-uppercase">{MatchState[state]}</span>;
            default:
                return <span className="">{MatchState[state]}</span>;
        }
    };

    const onMatchStateChange = (event: any) => {
        setNewMatchState(event.target.value);
        setOpenConfirmState(true);
    };

    const uploadResults = async (data: Array<any>) => {
        setNewCSVResults([]);
        if (!match) {
            setNewCSVResults([<TableRow key="0"><TableCell className="result-error">No Match</TableCell></TableRow>]);
            return;
        }
        const tmp: Array<any> = [];
        data.shift();
        await Promise.all(data.map(async (row) => {
            let user: TournamentPlayer | undefined;
            match.teams.forEach(team => {
                if (!team || user) {
                    return;
                }
                user = Object.values(team.players).find(p => {
                    return p.account.trim() === row[0].trim();
                });
            });
            if (!user) {
                tmp.unshift(<TableRow key={tmp.length}><TableCell className="result-error">&quot;{row[0]}&quot; does not match any
                    user</TableCell></TableRow>);
                return;
            }
            const res = await NtService.sendScore(match.id, match.route.split('/')[1], user.userId, row[1], match.currentRound, true);
            if (res) {
                tmp.unshift(<TableRow key={tmp.length}><TableCell className="result-valid">&quot;{row[0]}&quot; successfully
                    update score to &quot;{row[1]}&quot;</TableCell></TableRow>);
            } else {
                tmp.unshift(<TableRow key={tmp.length}><TableCell className="result-error">&quot;{row[0]}&quot; failed to update
                    score to &quot;{row[1]}&quot;</TableCell></TableRow>);
            }
        }));
        setNewCSVResults(tmp);
    };

    const onCurrentRoundChange = (event: any) => {
        NtService.setCurrentRound(tournamentid, matchid, parseInt(event.target.value, 10)).then((res) => {
            if (!res) {
                toast.error('Can\'t change Current Round');
                return;
            }
            refreshMatchData(true);
            toast.success('Current Round changed');
        }, () => {
            toast.error('Can\'t change current round');
        });
    };

    const changeMatchState = (choice: boolean) => {
        if (!choice) {
            setNewMatchState('');
            return;
        }
        NtService.changeMatchState(tournamentid, matchid, parseInt(newMatchState, 10)).then((res) => {
            if (!res) {
                toast.error('Can\'t change state');
                return;
            }
            toast.success('State changed');
        }, () => {
            toast.error('Can\'t change state');
        });
        setNewMatchState('');
    };

    const forceTeamCheckIn = (team?: TournamentTeam) => {
        if (!match) {
            return;
        }

        if (!team) {
            for (const t of match.teams) {
                if (!t) {
                    continue;
                }
                forceTeamCheckIn(t);
            }
            return;
        }

        const teamName = team.name;
        const captain = Object.keys(team.players).find(uid => team.players[uid].captain);
        if (!captain) {
            toast.error(`Can't find a captain for team ${teamName}!`);
            return;
        }

        NtService.forceCheckIn(tournamentid, matchid, captain).then(async () => {
            toast.success(`Forced check in for ${teamName}`);

        }, () => {
            toast.error(`Can't force check in for team ${teamName}`);
        });
    };

    const getAvailableRounds = (match: TournamentMatch) => {
        const rounds = (2 * (match.bo | 0) - 1) > (match.nbMatch | 0) ? 2 * match.bo - 1 : match.nbMatch;
        const array = [];
        let i = 0;
        while (i < rounds) {
            i++;
            array.push(<MenuItem key={i} value={i}>{i}</MenuItem>);
        }
        return array;
    };

    const forceTeamConfirm = (team: TournamentTeam) => {
        if (!match && !team) {
            return;
        }

        const teamName = team.name;
        const captain = Object.keys(team.players).find(uid => team.players[uid].captain);
        if (!captain) {
            toast.error(`Can't find a captain for team ${teamName}!`);
            return;
        }

        NtService.confirmScore(tournamentid, matchid, captain).then(async () => {
            toast.success(`Forced score confirmation for ${teamName}`);
        }, () => {
            toast.error(`Can't force score confirmation for team ${teamName}`);
        });
    };

    const initForceAllScores = (state: boolean) => {
        if (state && match) {
            setForceScoresResult(new Array(match.ep).fill(null));
            setActionForceAll(true);
        } else {
            setActionForceAll(false);
        }
    };

    const onForceAllScoreChange = (idx: number, value: string) => {
        setShowForceScoreDialogButtons(true);
        if (!match) {
            return;
        }

        forceScoresResult[idx] = parseInt(value, 10);

        if (match.setting.scoreType === MatchScoreType.Ranking || match.setting.scoreType === MatchScoreType.WinLose) {
            if (match.setting.scoreType === MatchScoreType.WinLose) {
                for (let i = 0; i < match.ep; ++i) {
                    if (i !== idx && !forceScoresResult[i]) {
                        forceScoresResult[i] = 0;
                    }
                }
            }

            const count = forceScoresResult.reduce((r: { [key: string]: number }, k) => {
                if (k) {
                    r[k] = 1 + r[k] || 1;
                }
                return r;
            }, {});
            const check = Object.keys(count).some(c => count[c] === 2);
            if (check) {
                toast.error('Can\'t have two winners or same ranks for this type of match');
                setShowForceScoreDialogButtons(false);
                return;
            }
        }
        setForceScoresResult(forceScoresResult);
    };

    const saveForceAllScores = (state: boolean) => {
        if (!state) {
            setActionForceAll(false);
            return;
        }

        if (match && match.setting.scoreType === MatchScoreType.WinLose && match.teamNb !== forceScoresResult.length) {
            setForceScoresResult(formatWinLoseScore(forceScoresResult));
        }

        NtService.forceAllScore(tournamentid, matchid, forceScoresResult).then(data => {
            if (!data) {
                toast.error(`Can't update scores of match`);
                return;
            }

            toast.success('Match Scores Updated');
            setActionForceAll(false);
        }, () => {
            toast.error('Can\'t reset match ');
        });
    };

    const formatWinLoseScore = (arr: Array<number>) => {
        if (!match) {
            return arr;
        }

        for (let i = 0; i < match.teamNb; i++) {
            if (arr[i] === undefined) {
                arr[i] = 0;
            }
        }
        return arr;
    };

    const resetMatch = () => {
        NtService.resetMatch(tournamentid, matchid).then(data => {
            if (!data) {
                toast.error('Can\'t reset match ');
                return;
            }

            toast.success('Match reseted');
        }, () => {
            toast.error('Can\'t reset match ');
        });
    };

    const cancelMatch = () => {
        NtService.cancelMatch(tournamentid, matchid).then(data => {
            if (!data) {
                toast.error('Can\'t cancel match ');
                return;
            }

            toast.success('Match cancelled');
        }, () => {
            toast.error('Can\'t cancel match ');
        });
    };

    const changeRequestStatus = () => {
        if (!requestObject) {
            toast.error('No request Provided');
            return;
        }
        NtService.requestHandler(tournamentid, requestObject.route, requestObject.status + 1).then((data) => {
            if (!data) {
                toast.error('Too late, it\'s seems that some one already take care of it');
                return;
            }
            toast.success('You changed the status of the request to In progress');
            updateRequest();
        });
    };

    const renderMatchMaps = () => {
        if (!match) {
            return;
        }
        return (
            <div className="w-100 d-flex p-2 align-items-center">
                {match.maps && match.maps.length > 0 ?
                    match.maps.map((m: MapMode, index: number) => {
                        return (
                            <div
                                className="NC-info d-flex flex-column justify-content-between align-items-center m-1 text-center"
                                key={index}>
                                <span className="text-uppercase mb-1 title">Round {index + 1}</span>
                                <img className="w-100" src={m.thumbnail} alt={m.name}/>
                                <span className="mt-2">{m.name} - {m.mode}</span>
                            </div>
                        );
                    })
                    :
                    <div className="m-1">No maps</div>
                }
            </div>
        );
    };

    const renderMatchTransitions = () => {
        if (!match || !match.transition || Object.keys(match.transition.selection).length === 0) {
            return;
        }
        const nextMatchs: {[route: string]: string[]} = {};
        for(const [pos, dest] of Object.entries(match.transition.selection)) {
            if (dest.route.startsWith('matchs')) {
                const positions = nextMatchs[dest.route]
                nextMatchs[dest.route] = positions ? positions.concat([pos]) : [pos];
            }
        }
        
        return (
            <div className="NC-container NC-card d-flex flex-column">
            <NCTitle text={'Match Transitions'} type={NCTitleType.TOURNAMENT}/>
            <TableContainer>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Route</TableCell>
                            <TableCell>Positions</TableCell>
                            <TableCell>Show</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>                    
                    {
                        Object.keys(nextMatchs).length > 0 ?
                            [...Object.entries(nextMatchs)].map((item, index) => {
                                return (
                                    <TableRow key={index}>
                                        <TableCell>{item[0]}</TableCell>
                                        <TableCell>{item[1].join("-")}</TableCell>
                                        <TableCell>
                                            <Link target="_blank" to={`${item[0].split("/")[1]}`} className="ml-2">
                                                <VisibilityIcon className="primary-color"/>
                                            </Link>
                                        </TableCell>
                                    </TableRow>
                                );
                            })
                        :
                            <div className="m-1">No transitions</div>
                    }
                    </TableBody>
                </Table>
            </TableContainer>
            </div>
        );
    };

    const renderTitle = () => {
        const url = tournament ? `${process.env.REACT_APP_PLATFORM_URL}/tournament/${tournament.id}` : '#';
        const title = `Match "${(match && intl.formatMessage({id: match.label}, {'nbr': match.ep, ...match.lData})) || matchid}" ${match && match.gameName ? `(${match.gameName})` : ''}`;
        document.title = title;
        return (
            <>
                <div>
					<span>
						{title}
					</span>
                </div>
                {
                    tournament &&
                    <div className="small">
                        {`Tournament "${tournament.name}"`}
                        <a href={url} target="_blank" rel="noopener noreferrer" title={'Open in new tab'}>
                            <IconButton className="my-auto mx-2" size="small">
                                <FontAwesomeIcon icon={faExternalLinkAlt}/>
                            </IconButton>
                        </a>
                    </div>
                }
            </>
        );
    };

    const renderCaptainAccountName = (players: { [key: string]: TournamentPlayer }) => {
        if (!players) {
            return '-';
        }
        const captain = Object.keys(players).find(p => players[p].captain === true);
        if (!captain) {
            return '-';
        }

        return players[captain].account;
    };

    const renderRoundScoreInput = (team: TournamentTeam, index: number) => {
        if (!match) {
            return;
        }
        switch (match.setting.scoreType) {
            case MatchScoreType.WinLose:
                return (
                    <>
                        <Checkbox
                            color='primary'
                            onChange={(event: React.ChangeEvent<{ checked: boolean }>) => {
                                onForceAllScoreChange(index, event.target.checked ? '1' : '0');
                            }}/>
                        Has won
                    </>
                );
            case MatchScoreType.Ranking:
                if (team.rounds && team.rounds[match.currentRound] && team.rounds[match.currentRound].score) {
                    forceScoresResult[index] = team.rounds[match.currentRound].score;
                }
                return (
                    <FormControlLabel label="Rank" control={
                        <Select
                            value={team.rounds && team.rounds[match.currentRound] ? team.rounds[match.currentRound].score : undefined}
                            onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                                onForceAllScoreChange(index, String(event.target.value));
                            }}
                        >
                            {
                                // create an array of match.ep elements
                                // then map each of them into a <MenuItem>
                                match.teams.filter(t => t !== null).map((_, i) => {
                                    return <MenuItem key={i}
                                                     value={i}>{nth((match.teams.filter(t => t !== null)).length - i)}</MenuItem>;
                                }, [])
                            }
                        </Select>
                    }/>
                );
            case MatchScoreType.Score:
            default:
                return <TextField
                    label="Enter score"
                    type="number"
                    onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                        onForceAllScoreChange(index, String(event.target.value));
                    }}
                />;
        }
    };

    return (
        <div className="match-sheet">
            <ActionDialog
                open={actionReset}
                title={'This will remove all teams, score and change the state to Waiting, are you sure?'}
                openHook={setActionReset}
                actionHook={choice => choice && resetMatch()}
            />
            <ActionDialog
                open={actionCancel}
                title={'This will cancel the match, are you sure?'}
                openHook={setActionCancel}
                actionHook={choice => choice && cancelMatch()}
            />
            {
                isLoading && <CircularProgress className="mx-auto"/>
            }
            {
                !isLoading && match &&
                <div className="NC-container NC-card d-flex flex-column">

                    <div className="upper-actions">
                        {
                            requestObject && requestObject.status < 2 &&
                            <Button
                                variant="outlined"
                                color="primary"
                                onClick={changeRequestStatus}
                            >
                                {requestObject.status === 0 ? 'I\'m taking care of it' : 'Solve Request'}
                            </Button>
                        }
                        {
                            isRefreshing ?
                                <CircularProgress/> :
                                <IconButton onClick={() => refreshMatchData() }>
                                    <RefreshIcon/>
                                </IconButton>
                        }
                    </div>

                    <NCTitle text={renderTitle()} type={NCTitleType.TOURNAMENT}/>
                    <div className="w-100 d-flex flex-md-nowrap flex-wrap p-2 align-items-center">
                        <div
                            className="flex-fill NC-info d-flex flex-column justify-content-between align-items-center m-1">
                            <span className="text-uppercase mb-1 title">State</span>
                            <span>{renderMatchState(match.state)}</span>
                        </div>
                        <div
                            className="flex-fill NC-info d-flex flex-column justify-content-between align-items-center m-1">
                            <span className="text-uppercase mb-1 title">Round</span>
                            <span>{match.currentRound ? match.currentRound : '-'}</span>
                        </div>
                        <div
                            className="flex-fill NC-info d-flex flex-column justify-content-between align-items-center m-1">
                            <span className="text-uppercase mb-1 title">BO</span>
                            <span>{match.bo ? (match.bo * 2) - 1 : '-'}</span>
                        </div>
                        <div
                            className="flex-fill NC-info d-flex flex-column justify-content-between align-items-center text-center m-1">
                            <span className="text-uppercase mb-1 title">Date</span>
                            <span>{moment(match.fp ? match.fp : match.date).format('LLLL').toString()}</span>
                        </div>
                        <div
                            className="flex-fill NC-info d-flex flex-column justify-content-between align-items-center text-center m-1">
                            <span className="text-uppercase mb-1 title">Assignee</span>
                            <span>{match.assignee ? match.assignee : 
                                <Button variant="outlined"
                                    onClick={() => changeAssignee(match.id, match.route)}>
                                    <StarIcon className="primary-color"/>
                                </Button>}</span>
                        </div>
                    </div>
                    <div className="match-actions">
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon/>}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography component="div"
                                            className="d-flex justify-content-center align-items-center">
                                    Actions
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails className="d-flex flex-md-nowrap flex-wrap">
                                {
                                    match.state !== MatchState.Ended && <FormControl className="flex-fill mx-1 w-100">
                                        <InputLabel>Change match state</InputLabel>
                                        <Select
                                            value={match.state}
                                            onChange={onMatchStateChange}
                                        >
                                            {
                                                Object.keys(MatchState).filter((s, i) => MatchState[i] && i !== MatchState.Cancelled).map((state, index) => {
                                                    return <MenuItem key={index}
                                                                     value={state}>{MatchState[index]}</MenuItem>;
                                                })
                                            }
                                        </Select>
                                    </FormControl>
                                }
                                {
                                    [MatchState.Conflict, MatchState.CheckIn].includes(match.state) && teamsPendingCheckIn.length > 0 &&
                                    <FormControl className="flex-fill mx-1 w-100">
                                        <InputLabel>Force check in</InputLabel>
                                        <Select
                                            value={-1}
                                            onChange={e => forceTeamCheckIn(match.teams[e.target.value as number])}
                                        >
                                            <MenuItem value={-1}>Select team</MenuItem>
                                            <MenuItem value={-42}>All players</MenuItem>
                                            {
                                                teamsPendingCheckIn
                                                    .map(({team, index}) => (
                                                        <MenuItem key={index} value={index}>
                                                            {team.name}
                                                        </MenuItem>
                                                    ))
                                            }
                                        </Select>
                                    </FormControl>
                                }
                                {
                                    match.state === MatchState.Confirmation && teamsPendingConfirm.length > 0 &&
                                    <FormControl className="flex-fill mx-1 w-100">
                                        <InputLabel>Force confirm</InputLabel>
                                        <Select
                                            value={null}
                                            onChange={e => forceTeamConfirm(match.teams[e.target.value as number])}
                                        >
                                            {
                                                teamsPendingConfirm
                                                    .map(({team, index}) => (
                                                        <MenuItem key={index} value={index}>
                                                            {team.name}
                                                        </MenuItem>
                                                    ))
                                            }
                                        </Select>
                                    </FormControl>
                                }
                                {
                                    (match.bo > 1 || match.nbMatch > 1) &&
                                    <FormControl className="flex-fill mx-1 w-100">
                                        <InputLabel>Change Current Round</InputLabel>
                                        <Select
                                            value={match.currentRound}
                                            onChange={onCurrentRoundChange}
                                        >
                                            {
                                                getAvailableRounds(match)
                                            }
                                        </Select>
                                    </FormControl>
                                }
                                {
                                    (match.state === MatchState.Conflict) &&
                                    <FormControl className="flex-fill mx-1 w-100">
                                        <ThemeProvider theme={uploadButtonTheme}>
                                            <Button
                                                className="mt-2"
                                                variant="outlined"
                                                onClick={() => {
                                                    setActionUpload(true);
                                                }}
                                                color="primary"
                                            >
                                                Updload Results
                                            </Button>
                                        </ThemeProvider>
                                    </FormControl>
                                }
                                {
                                    (match.state === MatchState.Conflict) &&
                                    <FormControl className="flex-fill mx-1 w-100">
                                        <ThemeProvider theme={uploadButtonTheme}>
                                            <Button
                                                className="mt-2"
                                                variant="outlined"
                                                onClick={() => {
                                                    setActionForceAll(true);
                                                }}
                                                color="primary"
                                            >
                                                Current round result
                                            </Button>
                                        </ThemeProvider>
                                    </FormControl>
                                }
                                <FormControl className="flex-fill mx-1 w-100">
                                    <ThemeProvider theme={errorButtonTheme}>
                                        <Button
                                            className="mt-2"
                                            variant="outlined"
                                            onClick={() => {
                                                setActionReset(true);
                                            }}
                                            color="primary"
                                        >
                                            Reset match
                                        </Button>
                                    </ThemeProvider>
                                </FormControl>
                                <FormControl className="flex-fill mx-1 w-100">
                                    <ThemeProvider theme={errorButtonTheme}>
                                        <Button
                                            className="mt-2"
                                            variant="outlined"
                                            onClick={() => {
                                                setActionCancel(true);
                                            }}
                                            color="primary"
                                        >
                                            Cancel match
                                        </Button>
                                    </ThemeProvider>
                                </FormControl>
                            </AccordionDetails>
                        </Accordion>
                    </div>
                    <div className="mt-2 d-flex row">
                        <div className="col-md-8 col-12">
                            <TabContext value={selectedTabInfo}>
                                <TabList value={selectedTabInfo}
                                         onChange={(e, value: string) => setSelectedTabInfo(value)}>
                                    <Tab label={<NCTitle text={'Teams'}/>} value="teams"/>
                                    <Tab label={<NCTitle text={'Requests'}/>} value="requests"/>
                                    <Tab label={<NCTitle text={`Maps (${match.maps ? match.maps.length : 0})`}/>} value="maps"/>
                                    <Tab label={<NCTitle text={`Transitions (${match.transition.selection ? Object.keys(match.transition.selection).length : 0})`}/>} value="transitions"/>
                                </TabList>
                                <TabPanel value="teams">
                                    <div className="row">
                                        {match.teams.map((t: TournamentTeam, index: number) => {
                                            return (
                                                <div className="col-md-6 col-12" key={index}>
                                                    <TournamentTeamInfos
                                                        team={t}
                                                        tournamentId={tournamentid}
                                                        matchId={matchid}
                                                        match={match}
                                                        refresh={refreshMatchData}
                                                    />
                                                </div>
                                            );
                                        })}
                                    </div>
                                </TabPanel>
                                <TabPanel value="requests">
                                    <div className="row">
                                        {
                                            <MatchRequestsList
                                                tournamentId={tournamentid}
                                                match={match.route}
                                            />
                                        }
                                    </div>
                                </TabPanel>
                                <TabPanel value="maps">
                                    <div className="row">
                                        {renderMatchMaps()}
                                    </div>
                                </TabPanel>
                                <TabPanel value="transitions">
                                    <div className="row">
                                        {renderMatchTransitions()}
                                    </div>
                                </TabPanel>
                            </TabContext>
                        </div>
                        <div className="col-md-4 col-12">
                            <TabContext value={selectedTabMessage}>
                                <TabList value={selectedTabMessage}
                                         onChange={(e, value: string) => setSelectedTabMessage(value)}>
                                    <Tab label={<NCTitle text={'Message'}/>} value="messages"/>
                                    <Tab label={<NCTitle text={`Proof (${proofList.length})`}/>} value="proofs"/>
                                </TabList>
                                <TabPanel value="messages">
                                    <div className="d-flex">
                                        <MatchMessaging roomId={roomId} teams={match.teams}/>
                                    </div>
                                </TabPanel>
                                <TabPanel value="proofs">
                                    <div className="mt-2 right-box-area">
                                        {<div>
                                            <TableContainer>
                                                <Table size="small">
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell>Round</TableCell>
                                                            <TableCell>User</TableCell>
                                                            <TableCell>Date</TableCell>
                                                            <TableCell>Show</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {
                                                            proofList.map((p: any, index: number) => {
                                                                return (
                                                                    <TableRow key={index}>
                                                                        <TableCell>{p.round === 0 ? p.round : 'General'}</TableCell>
                                                                        <TableCell>{p.user.name ? p.user.name : '-'}</TableCell>
                                                                        <TableCell>{p.date ? moment(p.date*1000).format('LTS'): '-'}</TableCell>
                                                                        <TableCell>
                                                                            <a href={`${String(process.env.REACT_APP_S3_URL)}${p.url}`}
                                                                               target="_blank"
                                                                               rel="noopener noreferrer">
                                                                                <IconButton>
                                                                                    <VisibilityIcon/>
                                                                                </IconButton>
                                                                            </a>
                                                                        </TableCell>
                                                                    </TableRow>
                                                                );
                                                            })
                                                        }
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </div>}
                                    </div>
                                </TabPanel>
                            </TabContext>
                        </div>
                    </div>
                </div>
            }
            <ResultDialog
                open={actionUpload}
                openHook={setActionUpload}
                actionHook={refreshMatchData}
                componentToDisplay={
                    <div className="d-flex flex-column align-items-center"
                         style={{minHeight: '200px', height: '50vh', overflow: 'auto'}}>
                        <CSVReader
                            onFileLoaded={uploadResults}
                            onError={() => {
                                toast.error('Incorrect File');
                            }}
                        />
                        <TableContainer>
                            <Table size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Results</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        newCSVResults
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                }
            />
            <ResultDialog
                open={actionForceAll}
                openHook={initForceAllScores}
                actionHook={saveForceAllScores}
                displayButtons={showForceScoreDialogButtons}
                currentRound={match ? match.currentRound : undefined}
                componentToDisplay={
                    <div className="d-flex flex-column align-items-center"
                         style={{minHeight: '200px', height: '50vh', overflow: 'auto'}}>
                        <TableContainer>
                            <Table size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>User</TableCell>
                                        <TableCell>Account</TableCell>
                                        <TableCell>Result</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        match && match.teams && match.teams.reduce((result: Array<React.ReactNode>, team: TournamentTeam, index: number) => {
                                            if (team && team.id && team.checkIn) {
                                                result.push(
                                                    <TableRow key={index}>
                                                        <TableCell>
                                                            {
                                                                team.name
                                                            }
                                                        </TableCell>
                                                        <TableCell>
                                                            {
                                                                renderCaptainAccountName(team.players)
                                                            }
                                                        </TableCell>
                                                        <TableCell>
                                                            {renderRoundScoreInput(team, index)}
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            }
                                            return result;
                                        }, [])
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                }
            />
            <ActionDialog
                title="Are you sure to change match state ?"
                open={openConfirmState}
                openHook={setOpenConfirmState}
                actionHook={changeMatchState}
            />
        </div>
    );
};