export class Administrator {
    _id: string;
    date_creation: Date;
    company: string;
    role: Array<AdministratorsRole>;

    constructor(
        public email: string,
        public password: string,
        public first_name: string,
        public last_name: string,
        public favorite_language: AdministratorsLanguage,
    ) {

    }

}

export enum AdministratorsRole {
    GUEST = 0,
    TOURNAMENT = 'TOURNAMENT',
    TRAINING = 2,
    LEGAL = 3,
    PARTNER = 4,
    NCADMIN = 'ADMIN',
}

export enum AdministratorsLanguage {
    ENGLISH = 'en',
    FRENCH = 'fr',
    SPANISH = 'es',
}