import {Button, Input, InputAdornment, MenuItem, Select} from '@material-ui/core';
import React, {useEffect, useState} from 'react';
import getSymbolFromCurrency from 'currency-symbol-map';
import {Wallet} from 'src/models/Wallet';
import {WalletService} from 'src/services/wallet.service';
import './UserWallet.scss';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faMinus, faPlus} from '@fortawesome/free-solid-svg-icons';
import {Filter, TransactionList} from 'src/components/TransactionList/TransactionList';
import {toast} from 'react-toastify';
import {FullScreenDialog} from 'src/components/General/FullScreenDialog/FullScreenDialog';
import {WalletCreationForm} from './WalletCreationForm/WalletCreationForm';
import { User } from '@wolfiesports/srm-component';

interface UserWalletProps {
    user: User;
}

const CURRENCY_NB_DECIMAL: { [code: string]: number } = {
    EUR: 2,
    USD: 2,
    GBP: 2,
};

export function CurrencyDecimal(isoCode: string): number {
    if (!CURRENCY_NB_DECIMAL[isoCode]) {
        return 0;
    }
    return CURRENCY_NB_DECIMAL[isoCode];
}

export const UserWallet: React.FunctionComponent<UserWalletProps> = ({user}: UserWalletProps) => {
    const [wallets, setWallets] = useState<Array<Wallet>>([]);
    const [selectedWallet, setSelectedWallet] = useState<Wallet | null>(null);
    const [filter, setFilter] = useState<Filter | null>(null);
    const [amount, setAmount] = useState(0);
    const [openWalletCreationDialog, setOpenWalletCreationDialog] = useState<boolean>(false);

    useEffect(() => {
        if (openWalletCreationDialog) {
            return;
        }
        refreshWallets();
        // eslint-disable-next-line
    }, [user.userId, openWalletCreationDialog]);

    const refreshWallets = async () => {
        const wallets = await WalletService.getWalletsByUserID(user.userId);
        const wallet = (selectedWallet && wallets.find(w => w.route === selectedWallet.route)) || wallets[0];
        setSelectedWallet(wallet);
        setFilter(wallet ? {wallet} : null);
        setWallets(wallets.filter(w => w.cur));
    };

    // eslint-disable-next-line
    const selectWalletFromRoute = (event: React.ChangeEvent<{ value: any }>, _: any) => {
        const wallet = wallets?.find(w => w.route === event.target.value) || null;
        setSelectedWallet(wallet);
        setAmount(0);
        setFilter(wallet ? {wallet} : null);
    };

    const renderWalletCurrency = (wallet: Wallet | null) => {
        if (!wallet) {
            return '';
        }

        return getSymbolFromCurrency(wallet.cur) || wallet.cur;
    };

    const renderWalletValue = (wallet: Wallet | null) => {
        if (!wallet) {
            return 'N/A';
        }

        return `${wallet.value} ${renderWalletCurrency(wallet)}`;
    };

    const addFunds = async (amount: number) => {
        const currency = selectedWallet?.route.split('/')?.[2];
        if (!selectedWallet || amount <= 0 || !window.confirm(`This will add ${amount}${currency} to ${user.name}#${user.code} funds\nFinal Total: ${selectedWallet.value + amount}${currency}`)) {
            return;
        }

        setAmount(0);
        await WalletService.forceReward(user, selectedWallet, {
            kind: selectedWallet.route.split('/')[1] as any,
            cur: currency,
            value: amount,
        });

        toast.success(`Addition of ${amount}${currency} to ${user.name}#${user.code} funds sent for processing.`);
        refreshWallets();
        setTimeout(() => refreshWallets(), 2000);
    };

    const removeFunds = async (amount: number) => {
        const currency = selectedWallet?.route.split('/')?.[2];
        if (!selectedWallet || amount <= 0 || !window.confirm(`This will remove ${amount}${currency} to ${user.name}#${user.code} funds\nFinal Total: ${selectedWallet.value - amount}${currency}`)) {
            return;
        }

        setAmount(0);
        await WalletService.forceReward(user, selectedWallet, {
            kind: selectedWallet.route.split('/')[1] as any,
            cur: selectedWallet.route.split('/')[2],
            value: -amount,
        });

        toast.success(`Removal of ${amount}${currency} to ${user.name}#${user.code} funds sent for processing.`);
        refreshWallets();
        setTimeout(() => refreshWallets(), 2000);
    };

    const amountByCurrency = (amountValue: string, currency: string) => {
        return parseFloat(parseFloat(amountValue).toFixed(CurrencyDecimal(currency)));
    };

    const renderWalletCreation = () => {
        return (
            <React.Fragment>
                <div className="d-flex w-100 justify-content-center">
                    <Button
                        color="primary"
                        variant="outlined"
                        onClick={() => {
                            setOpenWalletCreationDialog(true);
                        }}
                    >
                        Create Wallet
                    </Button>
                    <FullScreenDialog
                        open={openWalletCreationDialog}
                        openHook={setOpenWalletCreationDialog}
                        center={true}
                        componentToDisplay={
                            <WalletCreationForm user={user} existingWallets={wallets}
                                                setHook={setOpenWalletCreationDialog}/>
                        }
                    />
                </div>
            </React.Fragment>
        );
    };

    if (!user) {
        return <></>;
    }

    if (wallets.length <= 0) {
        return renderWalletCreation();
    }

    return (
        <>
            <div className="p-3 NC-card user-wallet">
                {renderWalletCreation()}
                <div className="d-flex col-12 mt-2 wallet-select">
                    <Select
                        value={selectedWallet?.route}
                        onChange={selectWalletFromRoute}
                        renderValue={() => renderWalletValue(selectedWallet)}
                        variant="outlined"
                        fullWidth={true}
                        style={{
                            fontSize: '1.5em',
                            fontWeight: 'bold',
                        }}
                    >
                        {wallets?.map((w, i) => <MenuItem key={i} value={w.route}>{renderWalletValue(w)}</MenuItem>)}
                    </Select>
                </div>
                {
                    selectedWallet &&
                    <div className="row p-3">
                        <div className="col-12 mt-4 d-flex justify-content-center">
                            <Input
                                type="number"
                                endAdornment={<InputAdornment
                                    position="end">{renderWalletCurrency(selectedWallet)}</InputAdornment>}
                                value={amount}
                                onChange={(e) => setAmount(amountByCurrency(e.target.value, selectedWallet.cur))}
                            />
                        </div>
                        <div className="col-12 mt-4 d-flex justify-content-center">
                            <Button variant="contained" className="mr-1 button-add-funds"
                                    onClick={() => addFunds(amount)}>
                                <FontAwesomeIcon icon={faPlus} color="white"/>
                            </Button>
                            <Button variant="contained" className="ml-1 button-remove-funds"
                                    onClick={() => removeFunds(amount)}>
                                <FontAwesomeIcon icon={faMinus} color="white"/>
                            </Button>
                        </div>
                        <div className="col-12 mt-4 d-table">
                            {filter && <TransactionList limit={5} filter={filter} showStatusFilter={false}/>}
                        </div>
                    </div>
                }
            </div>
        </>
    );
};
