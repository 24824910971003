import axios, { AxiosError } from 'axios';
import { toast } from 'react-toastify';
import { NoPlatform } from 'src/models/Platform';
import { UserGameAccount } from 'src/models/UserGameAccount';
import { Account, GameAccount } from '../models/GameAccount';
import { AdministratorService } from './administrator.service';
import { Game } from '@wolfiesports/srm-component';

export class GameAccountService {
    private static baseUrl = String(process.env.REACT_APP_NG_URL);

    static async getAccountList(size: number = 128, id: string = ""): Promise<{ lastKey: any, list: Array<Account>}> {
        let res;
        let lastElement:string = '';
        if (id) {
            lastElement = `&id=${id}`;
        }
        try {
            res = (await axios.get(
                `${GameAccountService.baseUrl}/admin/accounts?limit=${size}${lastElement}`,
                {
                    headers: {
                        'authorization': AdministratorService.token(),
                    }
                }
            )).data;
        } catch (e) {
            throw (e as AxiosError).response;
        }

        return res;
    }

    static async createAccount(id: string, title: string, color: string, active: number, aType: string = '', placeholder: string): Promise<boolean> {
        try {
            await axios.post(
                `${GameAccountService.baseUrl}/admin/accounts`,
                {
                    id,
                    title,
                    color,
                    active,
                    aType,
                    placeholder
                },
                {
                    headers: {
                        'authorization': AdministratorService.token(),
                    }
                }
            );
        } catch {
            return false;
        }
        return true;
    }

    static async updateAccount(id: string, title: string, color: string, active: number, aType: string = '', placeholder: string): Promise<boolean> {
        try {
            await axios.put(
                `${GameAccountService.baseUrl}/admin/accounts/${id}`,
                {
                    title,
                    color,
                    active,
                    aType,
                    placeholder
                },
                {
                    headers: {
                        'authorization': AdministratorService.token(),
                    }
                }
            );
        } catch {
            return false;
        }
        return true;
    }

    static async getAccountByUserId(size: number = 128, userid: string = "", id: string = ""): Promise<{ lastKey: any, list: Array<UserGameAccount>}> {
        let res;
        let lastElement:string = '';
        if (id) {
            lastElement = `&id=${id}`;
        }
        try {
            res = (await axios.get(
                `${GameAccountService.baseUrl}/admin/users/${userid}/accounts?size=${size}${lastElement}`,
                {
                    headers: {
                        'authorization': AdministratorService.token(),
                    }
                }
            )).data;
        } catch (e) {
            throw (e as AxiosError).response;
        }

        return res;
    }

    static async updateUserGameAccount(userid: string = "", account: string = "", gamerTag: string = ""): Promise<boolean> {
        try {
            await axios.put(`${GameAccountService.baseUrl}/admin/accounts/${account}/users/${userid}`, { gamerTag }, {
                headers: {
                    'authorization': AdministratorService.token(),
                },
            });
        } catch (e) {
            toast.error('Fail to update user gameaccount, try again later or contact #tnt-public');
            return false;
        }

        toast.success('User game account updated');
        return true;
    }

    static async deleteUserGameAccount(userid: string = "", account: string = ""): Promise<boolean> {
        if (account === "") {
            toast.error('contact #tnt-public');
            return false;
        }

        try {
            await axios.delete(`${GameAccountService.baseUrl}/admin/accounts/${account}/users/${userid}`, {
                headers: {
                    'authorization': AdministratorService.token(),
                },
            });
            toast.success('User game account deleted');
            return true;
        } catch (e) {
            toast.error('Fail to delete user gameaccount, try again later or contact #tnt-public');
            return false;
        }
    }

    static async createGameAccount(gameid: string, account: string, active: number, platforms: Array<string>): Promise<boolean> {
        try {
            await axios.post(
                `${GameAccountService.baseUrl}/admin/games/${gameid}/accounts`,
                {
                    account,
                    active,
                    platforms
                },
                {
                    headers: {
                        'authorization': AdministratorService.token(),
                    }
                }
            );
        } catch {
            return false;
        }
        return true;
    }

    static async updateGameAccount(gameid: string, account: string, active: number, platforms: Array<string>): Promise<boolean> {
        try {
            await axios.put(
                `${GameAccountService.baseUrl}/admin/games/${gameid}/accounts/${account}`,
                {
                    active,
                    platforms,
                },
                {
                    headers: {
                        'authorization': AdministratorService.token(),
                    }
                }
            );
        } catch {
            return false;
        }
        return true;
    }

    static async getGameAccountByAccount(account: string, size: number = 20, id: string = ""): Promise<{ lastKey: any, list: Array<GameAccount>}> {
        let res;
        let lastElement:string = '';
        if (id) {
            lastElement = `&id=${id}`;
        }
        try {
            res = (await axios.get(
                `${GameAccountService.baseUrl}/admin/accounts/${account}/games?limit=${size}${lastElement}`,
                {
                    headers: {
                        'authorization': AdministratorService.token(),
                    }
                }
            )).data;
        } catch (e) {
            throw (e as AxiosError).response;
        }

        return res;
    }

    static async getGame(size: number = 128, id: string = ""): Promise<{ lastKey: any, list: Array<Game>}> {
        let res;
        let lastElement:string = '';
        if (id) {
            lastElement = `&id=${id}`;
        }
        try {
            res = (await axios.get(
                `${GameAccountService.baseUrl}/public/games?limit=${size}${lastElement}`,
                {},
            )).data;
        } catch (e) {
            throw (e as AxiosError).response;
        }

        return res;
    }

    static async getAdminGame(size: number = 128, id: string = ""): Promise<{ lastKey: any, list: Array<Game>}> {
        const params: {[key: string]: any} = {
            limit: size,
        }
        if (id) {
            params['id'] = id;
        }
        try {
            const res = (await axios.get(
                `${GameAccountService.baseUrl}/admin/games`,
                {
                    headers: {
                        'authorization': AdministratorService.token(),
                    },
                    params,
                },
            )).data;
            return res;
        } catch (e) {
            throw (e as AxiosError).response;
        }
    }

    static async getPlatforms(size: number = 128, id: string = ""): Promise<{ lastKey: any, list: Array<NoPlatform>}> {
        let res;
        let lastElement:string = '';
        if (id) {
            lastElement = `&id=${id}`;
        }
        try {
            res = (await axios.get(
                `${GameAccountService.baseUrl}/public/platforms?limit=${size}${lastElement}`,
                {
                    headers: {
                        'authorization': AdministratorService.token(),
                    }
                }
            )).data;
        } catch (e) {
            throw (e as AxiosError).response;
        }

        return res;
    }
}