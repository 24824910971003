import axios, { AxiosError } from 'axios';
import { Seo } from 'src/models/Seo';

export class SeoService {
    private static baseUrl: string = String(process.env.REACT_APP_ADMINAPI_URL);
    static token = (): string | null => localStorage.getItem('accessToken');

    static async getSeos(limit: number = 10, page: number = 0) {
        let res;

        try {
            res = await axios.get(
                `${SeoService.baseUrl}/seo?limit=${limit}&page=${page}`, {
                    headers: {
                        'x-access-token': SeoService.token(),
                    }
                });
        } catch (e) {
            throw (e as AxiosError).response;
        }

        let seoList = [];
        if (!res.data && !res.data.docs) {
            throw Error;
        }
        seoList = res.data.docs.map((item: any) => {
            return new Seo(
                item._id,
                item.value,
                item.game,
                item.title,
                item.description,
                item.keywords,
                item.createdAt,
            );
        });
        return {
            docs: seoList,
            page: res.data.page,
            total: res.data.total,
        }
    }

    static async createSeo(seo: Seo): Promise<Seo> {
        if (!seo) {
            throw Error;
        }
        let newSeo: Seo;
        delete seo['_id' as keyof Seo];
        try {
            newSeo = (await axios.post(
                `${SeoService.baseUrl}/seo`,
                seo, {
                    headers: {
                        'x-access-token': SeoService.token(),
                    }
                }
            )).data;
        } catch (e) {
            throw (e as AxiosError).response;
        }

        return newSeo;
    }

    static async updateSeo(seo: Seo): Promise<boolean> {
        if (!seo) {
            return false;
        }

        try {
            await axios.put(
                `${SeoService.baseUrl}/seo/${seo._id}`,
                seo, {
                    headers: {
                        'x-access-token': SeoService.token(),
                    }
                });
        } catch {
            return false;
        }

        return true;
    }

    static async deleteSeo(id: string): Promise<boolean> {
        if (!id) {
            return false;
        }

        try {
            await axios.delete(
                `${SeoService.baseUrl}/seo/${id}`, {
                    headers: {
                        'x-access-token': SeoService.token(),
                    }
                });
        } catch {
            return false;
        }

        return true;
    }
}