import React, { ChangeEvent, useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import "./CornerForm.scss";
import AppBar from "@material-ui/core/AppBar/AppBar";
import Tabs from "@material-ui/core/Tabs/Tabs";
import Tab from "@material-ui/core/Tab/Tab";
import { TabPanel } from "../../../atoms/TabPanel/TabPanel";
import { Corner } from "src/models/Corner";
import { CornerService } from "src/services/corner.service";
import { CircularProgress } from "@material-ui/core";

enum TabsName {
  Info = 0,
}

interface CornerFormProps {
  corner: Corner | undefined;
}

export const CornerForm: React.FunctionComponent<CornerFormProps> = (
  props: CornerFormProps
) => {
  const [corner, setCorner] = useState<Corner | undefined>();
  const [error, setError] = useState<Error>();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    props.corner &&
      CornerService.getCorner(props.corner.name)
        .then(setCorner)
        .then(() => setIsLoading(false))
        .catch((e) => {
          setError(e);
          setIsLoading(false);
        });
  }, [props.corner]);

  const onCornerChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.currentTarget;
    setCorner(
      Object.assign({}, corner, {
        [name]: value,
      })
    );
  };

  return (
    <div>
      <AppBar position="static" color="default" className="mb-2">
        <Tabs value={0} variant="fullWidth">
          <Tab label="Infos" />
        </Tabs>
      </AppBar>

      <TabPanel value={0} index={TabsName.Info}>
       {!!corner && <form className="d-flex flex-column corner-form">
          <div className="d-flex flex-row row my-2">
            <div className="col-12 col-sm-6 px-3">
              <TextField
                className="w-100"
                id="name"
                name="name"
                label="Name"
                value={corner.name || ""}
                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                  onCornerChange(event)
                }
              />
            </div>
            <div className="col-12 col-sm-6 px-3">
              <TextField
                disabled
                className="w-100"
                id="id"
                name="_id"
                label="Id"
                value={corner._id || ""}
              />
            </div>
          </div>
        </form> }
        {isLoading && (
          <CircularProgress
            data-testid="corner-form-loading"
            className="mx-auto"
          />
        )}
        {error && <div className="corner-form-error">{error.message}</div>}
      </TabPanel>
    </div>
  );
};
