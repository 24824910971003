import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem/MenuItem';
import Select from '@material-ui/core/Select';
import React, {useEffect, useState} from 'react';
import {toast} from 'react-toastify';
import {Currency} from 'src/models/Currency';
import {Wallet} from 'src/models/Wallet';
import {CurrencyService} from 'src/services/currency.service';
import {WalletService} from 'src/services/wallet.service';
import {RewardKind} from 'src/models/Transaction';
import './WalletCreationForm.scss';
import {CurrencyDecimal} from '../UserWallet';
import { User } from '@wolfiesports/srm-component';

interface WalletCreationFormProps {
    user: User;
    setHook?: any;
    existingWallets: Array<Wallet>;
}

export const WalletCreationForm: React.FunctionComponent<WalletCreationFormProps> = ({
                                                                                         user,
                                                                                         setHook,
                                                                                         existingWallets
                                                                                     }) => {
    const [currencies, setCurrencies] = useState<Array<Currency>>([]);
    const [selectedCurrency, setSelectedCurrency] = useState<Currency>();
    const [amount, setAmount] = useState<number>(0);

    useEffect(() => {
        getCurrencies();
        // eslint-disable-next-line
    }, []);

    const getCurrencies = async () => {
        const data = await CurrencyService.getCurrencies(250, 0, {active: true});
        if (!data) {
            setCurrencies([]);
            return;
        }
        setCurrencies(data.docs.filter(filterExistingWallet));
    };

    const filterExistingWallet = (currency: Currency): boolean => {
        return !existingWallets || !existingWallets.some(w => w.cur === currency.symbol);
    };

    const onCurrencyChange = (event: any) => {
        const cur = currencies.find(c => c.symbol === event.target.value);
        setSelectedCurrency(cur);
        setAmount(0);
    };

    const createWallet = async () => {
        if (!selectedCurrency || (amount <= 0)) {
            toast.error('The amount must be higher than 0');
            return;
        }
        const wallet: Wallet = {
            owner: user.userId,
            route: `wallets/${RewardKind.CashKind}/${selectedCurrency.symbol}`,
            cur: selectedCurrency.symbol,
            value: 0
        };
        await WalletService.forceReward(user, wallet, {
            kind: RewardKind.CashKind,
            cur: selectedCurrency.symbol,
            value: amount
        });
        if (setHook) {
            setHook(false);
        }
    };

    const amountByCurrency = (amountValue: string, currency: string) => {
        return parseFloat(parseFloat(amountValue).toFixed(CurrencyDecimal(currency)));
    };

    return (
        <div className="wallet-creation-form d-flex flex-column justify-content-center align-items-center w-100">
            <div className="m-3 w-100">
                <FormControl className="w-100">
                    <InputLabel>Currencies</InputLabel>
                    <Select
                        label="Currencies"
                        name="currencies"
                        value={selectedCurrency ? selectedCurrency.symbol : ''}
                        onChange={onCurrencyChange}
                    >
                        <MenuItem value="">Select currency</MenuItem>
                        {
                            currencies && currencies.map((currency: Currency) => {
                                if (currency.active) {
                                    return (
                                        <MenuItem key={currency._id}
                                                value={currency.symbol}>{currency.symbol} - {currency.picto}</MenuItem>
                                    );
                                } else {
                                    return (<div key={currency._id} className="d-none"/>);
                                }
                            })
                        }
                    </Select>
                </FormControl>
            </div>
            <div className="m-3 w-100 d-flex justify-content-center">
                {
                    selectedCurrency &&
                    <Input
                        type="number"
                        endAdornment={<InputAdornment position="end">{selectedCurrency?.symbol}</InputAdornment>}
                        value={amount}
                        onChange={(e) => setAmount(amountByCurrency(e.target.value, selectedCurrency.symbol))}
                    />
                }
            </div>
            <div className="m-3 w-100 d-flex justify-content-center">
                <Button
                    color="primary"
                    variant="contained"
                    onClick={() => {
                        createWallet();
                    }}
                >
                    Create Wallet
                </Button>
            </div>
        </div>
    );
};