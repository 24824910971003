import axios, { AxiosError } from 'axios';
import { toast } from 'react-toastify';
import { Media } from 'src/models/MediaLibrary';

export class MediaLibraryService {
    private static baseUrl: string = String(process.env.REACT_APP_ADMINAPI_URL);
    static token = (): string | null => localStorage.getItem('accessToken');

    static async getMediaLibrary(path: string = ''): Promise<Array<Media>> {
        let res;

        try {
            res = (await axios.get(`${MediaLibraryService.baseUrl}/media${path}`, {
                headers: {
                    'x-access-token': MediaLibraryService.token(),
                }
            }));
        } catch (e) {
            throw (e as AxiosError).response;
        }
        return res.data;
    }

    static async createMedia(path: string, data: string): Promise<Media> {
        if (!path) {
            throw Error;
        }

        let media;

        try {
            media = (await axios.post(
                `${MediaLibraryService.baseUrl}/media${path}`,
                {data: data},
                {
                    headers: {
                        'x-access-token': MediaLibraryService.token(),
                    }
                }
            )).data;
        } catch (e) {
            throw (e as AxiosError).response;
        }

        return media;
    }

    static async deleteMedia(id: string): Promise<boolean> {
        if (!id) {
            return false;
        }

        try {
            await axios.delete(
                `${MediaLibraryService.baseUrl}/media/${id}`, {
                    headers: {
                        'x-access-token': MediaLibraryService.token(),
                    }
                }
            );
        } catch {
            toast.error('Fail to delete media, try again later');
            return false;
        }

        toast.success('Media deleted');
        return true;
    }
}