export class Team {
    constructor(
        public cname: string,
        public date: string,
        public nal: Array<string>,
        public name: string,
        public owner: TeamOwner,
        public route: string,
        public tag: string,
        public team: string,
        public desc: string
    ) {}
}

export class TeamAugmented extends Team {
    members: Array<TeamMember>
    favoriteGames: Array<TeamGame>
}

export class TeamOwner {
    constructor(
        public code: string,
        public id: string,
        public name: string,
        public avatar: string,
        public banner: string
    ) {}
}

export class TeamMember {
    constructor(
        public code: number,
        public email: string,
        public name: string,
        public route: string,
        public team: string,
        public permission: TeamPermission,
        public teamInfo: TeamInfo,
        public userId: string,
        public avatar: string,
        public banner: string
    ) {}
}

export class TeamInfo {
    constructor(
        public date: string,
        public nal: Array<string> | null,
        public name: string,
        public tag: string,
        public owner: TeamOwner,
        public description: string,
        public members: number
    ) {}
}

export class TeamGame {
    constructor(
        public name: string,
        public route: string,
        public team: string,
        public teamInfo: TeamInfo,
    ) {}
}

export enum TeamPermission {
    MEMBER = 0,
    MANAGER = 1,
    OWNER = 2,
}

export enum TeamMedia {
    BANNER = 'BannerImage',
    PROFILE = 'ProfileImage',
}

export class TeamInvitation extends Team {
    constructor(
        cname: string,
        date: string,
        nal: Array<string>,
        name: string,
        owner: TeamOwner,
        route: string,
        tag: string,
        team: string,
        desc: string,
        public teamInfo: TeamInfo,
        public sn: {
            additionalProp1: string,
            additionalProp2: string,
            additionalProp3: string,
        }
    ) {
        super(cname, date, nal, name, owner, route, tag, team, desc);
    }
}