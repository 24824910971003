import 'src/components/Challenge/ChallengeForm/ChallengeFormAssignment/ChallengeFormAssignmentGeneral/ChallengeFormAssignmentGeneral.scss';

import {
    ComparisonOperatorLUT,
    InstructionType,
    Kind,
    Operator,
    PredicateCondition,
    PredicateEditionAugmented,
} from 'src/models/TrainingBrick';
import React, { ChangeEvent, useEffect, useState } from 'react';

import { Autocomplete } from '@material-ui/lab';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import { LangPicker } from 'src/components/General/LangPicker/LangPicker';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import {TrainingPreset} from 'src/models/TrainingPreset';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import { useStoreState } from 'src/store';
import {TrainingService} from 'src/services/training.service';

interface ModalAddPresets {
    open: boolean;
    type: PredicateCondition;
    lang: string;
    presets: Array<TrainingPreset>;
    predicate: PredicateEditionAugmented;
    predicateIndex: number;
    setOpenDialog: (isOpen: boolean) => void;
    toCapitalizeCase: (text: string) => string;
    actionHook: (type: PredicateCondition, predicate: PredicateEditionAugmented, index: number) => void;
}

enum SelectType {
    PRESET = 'preset',
    OPERATOR = 'operator',
    INSTRUCTION = 'instruction',
}

export const ModalAddPresets: React.FunctionComponent<ModalAddPresets> = (props: ModalAddPresets) => {
    const [ lang, setLang ] = useState(useStoreState(state => state.contentLang));
    const [ predicate, setPredicate ] = useState<PredicateEditionAugmented>(props.predicate);
    const [ selectedPreset, setSelectedPreset ] = useState<TrainingPreset>();
    const [ selectedOperator, setSelectedOperator ] = useState<Operator>(Operator.eq);
    const [ selectedInstruction, setSelectedInstruction ] = useState<InstructionType | string>(props.predicate.instruction as InstructionType || Object.keys(InstructionType)[0]);
    const [ selectedValue, setSelectedValue ] = useState<any>(props.predicate.value);
    const [ isConverted, setIsConverted ] = useState<boolean>(false);

    // Those metrics are converted from seconds to minutes on front side
    const timeConvertedMetrics: Array<string> = [ 'fortnite:player:finish:map:time:end' ];



    useEffect(() => {
        setLang(lang);
        setPredicate(props.predicate);
        setSelectedPreset(props.presets.find(preset => preset.path === TrainingService.parsePath(props.predicate.path).path));
        setSelectedOperator(Operator[props.predicate.operator as Operator]);
        setSelectedValue(props.predicate.value);
        if (props.type === PredicateCondition.TARGET) {
            if (props.predicateIndex >= 0) {
                setSelectedPreset(props.presets.find(
                    preset => preset.path === predicate.path.replace(`$$${props.predicate.instruction}$$`, ''))
                );
            }
            setSelectedInstruction(props.predicate.instruction as InstructionType || Object.keys(InstructionType)[0]);
        }
    }, [ lang, props.type, predicate.instruction, props.predicate, props.presets, predicate.path, props.predicateIndex ]);

    useEffect(() => {
        setSelectedInstruction(selectedInstruction);
    }, [ selectedInstruction ]);

    const onSelectChange = (value: string | TrainingPreset, type: SelectType) => {
        switch (type) {
            case SelectType.PRESET: {
                const _preset = value as TrainingPreset;
                setSelectedPreset(_preset);
                setIsConverted((_preset && timeConvertedMetrics.includes(_preset.path)) ? true : false);
                if (predicate.options && _preset) {
                    predicate.path = _preset.path;
                    predicate.options.kind = _preset.kind as Kind;
                }
                break;
            }
            case SelectType.OPERATOR:
                setSelectedOperator(Operator[value as Operator]);
                predicate[type] = value as Operator;
                break;
            case SelectType.INSTRUCTION:
                setSelectedInstruction(value as string);
                predicate[type] = value as InstructionType;
                break;
            default:
                break;
        }
        setPredicate(predicate);
    };

    const onDescriptionChange = (value: string) => {
        predicate.description[lang] = value;
        setPredicate(predicate);
        if (props.predicateIndex >= 0) {
            setPredicate(Object.assign({}, predicate));
        }
    };

    const onValueChange = (value: any) => {
        setSelectedValue(value);
        predicate.value = value;
    }

    const close = () => {
        if (props.predicateIndex < 0) {
            onSelectChange('', SelectType.PRESET);
        }
        setPredicate(props.predicate);
        props.setOpenDialog(false);
    };

    const save = () => {
        if (!predicate.path || !predicate.operator || !predicate.value || Object.keys(predicate.description).length === 0) {
            toast.error('You must fill all fields before saving');
            return;
        }
        if (props.type === PredicateCondition.TARGET) {
            if (!selectedInstruction || !selectedPreset) {
                toast.error('You must fill all fields before saving');
                return;
            }
            predicate.path = `$$${selectedInstruction}$$${selectedPreset.path}`;
            if (props.predicateIndex >= 0) {
                predicate.path = `$$${selectedInstruction}$$${selectedPreset.path.replace(`$$${selectedInstruction.toString()}$$`, '')}`;
            }
        }
        props.actionHook(props.type, predicate, props.predicateIndex);
        close();
    };

    return (
        <Dialog open={props.open} onClose={() => close()} fullWidth={true} aria-labelledby="form-dialog-title">
            <div className="d-flex justify-content-between">
                <DialogTitle id="form-dialog-title">Add new {props.type}</DialogTitle>
                <LangPicker setLang={ setLang } />
            </div>
            <DialogContent>
                <div className="row px-3">
                    {
                        props.type === PredicateCondition.TARGET ?
                            <FormControl className="col-12 px-3 mb-2">
                                <InputLabel shrink>Instruction</InputLabel>
                                <Select
                                    name="instruction"
                                    value={selectedInstruction}
                                    onChange={(event) => onSelectChange(event.target.value as string, SelectType.INSTRUCTION)}
                                    >
                                    {
                                        InstructionType && Object.keys(InstructionType).map((instruction: string, index: number) => {
                                            return <MenuItem key={index} value={instruction}>{instruction}</MenuItem>;
                                        })
                                    }
                                </Select>
                            </FormControl>
                        : null
                    }
                    <FormControl className="col-12 px-3 mb-2">
                        <Autocomplete
                            value={selectedPreset || null}
                            options={props.presets}
                            getOptionLabel={(preset) => preset.path}
                            onChange={(event, value) => onSelectChange(value as TrainingPreset, SelectType.PRESET)}
                            renderInput={(params) =>
                                <TextField {...params} label={props.toCapitalizeCase(props.type)} variant="outlined" />}
                        />
                        {
                            isConverted &&
                            <div className="d-flex mx-auto mt-3 mb-4 red-color">
                                <div className="my-auto mr-3">
                                    <FontAwesomeIcon icon={faExclamationTriangle} style={{ fontSize: 24 }}/>
                                </div>
                                <div>
                                    <div>This metric is displayed in minutes but computed is second.</div>
                                    <div>Please enter a value in seconds.</div>
                                </div>
                            </div>
                        }
                    </FormControl>
                    <FormControl className="col-12 px-3 mb-2">
                        <InputLabel shrink>Operator</InputLabel>
                        <Select
                            name="operator"
                            value={selectedOperator}
                            onChange={(event) => onSelectChange(event.target.value as string, SelectType.OPERATOR)}
                        >
                            {
                                Operator && Object.keys(Operator).map((operator: string, index: number) => {
                                    return <MenuItem key={index} value={operator}>{ComparisonOperatorLUT[operator as Operator]}</MenuItem>;
                                })
                            }
                        </Select>
                    </FormControl>
                    <TextField
                        className="col-12 px-3 mb-2"
                        name="value"
                        label="Value"
                        value={selectedValue}
                        onChange={(event: ChangeEvent<HTMLInputElement>) => (onValueChange(event.target.value))}
                    />
                    <TextField
                        className="col-12 px-3 mb-2"
                        name="description"
                        label="Description"
                        value={predicate.description[lang]}
                        onChange={(event: ChangeEvent<HTMLInputElement>) => (onDescriptionChange(event.target.value))}
                    />
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => close()} color="primary">Cancel</Button>
                <Button onClick={() => save()} color="primary">Save</Button>
            </DialogActions>
        </Dialog>
    );
};