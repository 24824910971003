import React from 'react';
import {NCTitle, NCTitleType} from 'src/atoms/NCTitle/NCTitle';
import {TransactionStatus} from 'src/models/Transaction';
import {TransactionList} from 'src/components/TransactionList/TransactionList';

export const Transactions: React.FunctionComponent = () => {
    return (
        <div className="NC-container NC-card">
            <NCTitle text={'Transactions'} type={NCTitleType.TOURNAMENT}/>
            <div className="d-flex">
                <TransactionList filter={{status: TransactionStatus.Validating}}/>
            </div>
        </div>
    );
};
