import React, {useEffect, useState} from 'react';
import {toast} from 'react-toastify';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import {useStoreState} from 'src/store';
import {TrainingService} from 'src/services/training.service';
import {GameService} from 'src/services/game.service';
import {QuizQuestionForm} from 'src/components/Training/Quiz/QuizQuestionForm/QuizQuestionForm';
import {FullScreenDialog} from 'src/components/General/FullScreenDialog/FullScreenDialog';
import {ActionDialog} from 'src/components/General/ActionDialog/ActionDialog';
import {SimpleList} from 'src/components/General/SimpleList/SimpleList';
import {LangPicker} from 'src/components/General/LangPicker/LangPicker';
import {QuizForm} from 'src/components/Training/Quiz/QuizForm/QuizForm';
import {Paginate} from 'src/atoms/Paginate/Paginate';
import {NCTitle, NCTitleType} from 'src/atoms/NCTitle/NCTitle';
import {Choices, TrainingQuestion, TrainingQuiz} from 'src/models/TrainingQuiz';
import {Game} from 'src/models/Game';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import AddIcon from '@material-ui/icons/Add';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SettingsIcon from '@material-ui/icons/Settings';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

export const Quiz: React.FunctionComponent = () => {
    const [lang, setLang] = useState<string>(useStoreState(state => state.contentLang));
    const [isLoading, setIsLoading] = useState(true);
    const [quizList, setQuizList] = useState<Array<TrainingQuiz>>([]);
    const [gameList, setGameList] = useState<Array<Game>>([]);

    const [quiz, setQuiz] = useState<TrainingQuiz>();
    const [actionQuizDelete, setActionQuizDelete] = useState<boolean>(false);
    const [actionQuizEdit, setActionQuizEdit] = useState<boolean>(false);
    const [actionQuizCreate, setActionQuizCreate] = useState<boolean>(false);

    const [question, setQuestion] = useState<TrainingQuestion>();
    const [questionActionDelete, setQuestionActionDelete] = useState<boolean>(false);
    const [questionActionEdit, setQuestionActionEdit] = useState<boolean>(false);
    const [questionActionCreate, setQuestionActionCreate] = useState<boolean>(false);

    const [expanded, setExpanded] = useState<string | false>(false);

    const [actualPage, setActualPage] = useState<number>(0);
    const [totalItem, setTotalItem] = useState<number>(0);

    useEffect(() => {
        toast.info('Work in progress on this page.\n' +
            'Contact BO team (#public-backoffice on slack) if you have any trouble');
        setIsLoading(true);

        TrainingService.getQuiz(20, actualPage).then((data) => {
            setTotalItem(Math.ceil(data.total / 20));
            setQuizList(data.docs);
            setIsLoading(false);
        });

        getGames();
    }, [actualPage]);

    const getGames = () => {
        GameService.getGames(50).then((data) => {
            setGameList(data.docs);
        }).catch(() => {
            toast.error('Error while fetching game list');
        });
    };

    const handlePanelChange = (panel: string) => (event: React.ChangeEvent<HTMLInputElement>, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : false);
    };

    const actionManager = (quiz: any, kind: string, type: string) => {
        type === 'quiz' ? setQuiz(quiz) : setQuestion(quiz);

        switch (kind) {
            case 'delete':
                type === 'quiz' ? setActionQuizDelete(true) : setQuestionActionDelete(true);
                break;
            case 'edit':
                type === 'quiz' ? setActionQuizEdit(true) : setQuestionActionEdit(true);
                break;
            case 'create': {
                if (type === 'quiz') {
                    setQuiz(new TrainingQuiz(
                        '',
                        false,
                        [],
                        {},
                        {} as Game
                    ));
                    setActionQuizCreate(true);
                } else {
                    setQuestion(new TrainingQuestion(
                        '',
                        false,
                        {},
                        {},
                        0,
                        [
                            new Choices({en: ''}, false, false),
                            new Choices({en: ''}, false, false),
                            new Choices({en: ''}, false, false),
                            new Choices({en: ''}, false, false)
                        ]
                    ));
                    setQuestionActionCreate(true);
                }
                break;
            }
            default:
                return;
        }
    };

    const deleteQuiz = (choice: boolean) => {
        if (choice) {
            if (quiz) {
                TrainingService.deleteQuiz(quiz._id).then(() => {
                    updateList(true);
                });
            }
        }
    };

    const deleteQuestion = (choice: boolean) => {
        if (choice) {
            const selectedQuiz = quizList.filter((quiz: TrainingQuiz) => {
                return (quiz._id === expanded ? quiz : null);
            });

            if (question) {
                const index = selectedQuiz[0].questions.indexOf(question, 0);
                selectedQuiz[0].questions.splice(index, 1);
            }

            TrainingService.updateQuiz(selectedQuiz[0]._id, selectedQuiz[0]).then((r: boolean) => {
                updateList(r);
            });

        }
    };

    const updateQuestion = () => {
        const selectedQuiz = quizList.filter((quiz: TrainingQuiz) => {
            return (quiz._id === expanded ? quiz : null);
        });

        TrainingService.updateQuiz(selectedQuiz[0]._id, selectedQuiz[0]).then((r: boolean) => {
            updateList(r);
        });
    };

    const postQuestion = () => {
        const selectedQuiz = quizList.filter((quiz: TrainingQuiz) => {
            return (quiz._id === expanded ? quiz : null);
        });

        if (question) {
            TrainingService.createQuizQuestion(selectedQuiz[0]._id, question).then((r: any) => {
                r ? updateList(true) : updateList(false);
            });
        }
    };

    const updateList = (edit: boolean) => {
        if (edit) {
            setIsLoading(false);

            TrainingService.getQuiz(20, actualPage).then((data) => {
                setTotalItem(Math.ceil(data.total / 20));
                setQuizList(data.docs);
                setIsLoading(false);
            });
        }
        setQuestionActionDelete(false);
        setQuestionActionEdit(false);
        setQuestionActionCreate(false);
        setActionQuizDelete(false);
        setActionQuizEdit(false);
        setActionQuizCreate(false);
    };

    const renderQuestions = (quiz: TrainingQuiz) => {

        return (
            <>
                <SimpleList
                    rows={quiz.questions.filter((question: TrainingQuestion) => question != null)}
                    columns={['ID', 'Name', 'Level', 'Published']}
                    keepRows={['_id', 'name', 'level', 'published']}
                    actionsButtons={{create: true, edit: true, delete: true}}
                    handleClickOpen={actionManager}
                    translatedString={true}
                />
                <ActionDialog
                    open={questionActionDelete}
                    title={`Are you sure to delete this question: "${question?.name[lang]}" ?`}
                    openHook={setQuestionActionDelete}
                    actionHook={deleteQuestion}
                />
            </>
        );
    };

    return (
        <div className="NC-container NC-card d-flex flex-column">
            <div className="d-flex flex-row justify-content-between">
                <NCTitle text={'Training Quiz'} type={NCTitleType.TRAINING}/>
                <LangPicker title={'Translate'} setLang={setLang}/>

            </div>
            <div className="d-flex flex-column">
                {
                    isLoading && <CircularProgress className="mx-auto"/>
                }
                {
                    !isLoading &&
                    <div className="d-flex flex-row justify-content-between align-items-end">
                        <Paginate pagination={{actual: actualPage, total: totalItem, setActual: setActualPage}}/>
                        <Button variant="text" color="primary" className="mx-1 my-auto h-25"
                                onClick={() => actionManager({}, 'create', 'quiz')}><AddIcon/></Button>
                    </div>
                }
                {
                    !isLoading && quizList.map((quiz: TrainingQuiz) => {
                        return (
                            <div key={quiz._id + Math.random()} className="mb-1">
                                <Accordion expanded={expanded === quiz._id} onChange={() => handlePanelChange(quiz._id)}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon/>}
                                    >
                                        <div
                                            className="d-flex flex-row justify-content-between w-100 align-items-center">
                                            <Typography className="font-weight-bold">{quiz.name[lang]}</Typography>
                                            <div>
                                                {quiz.published ? <VisibilityIcon className="mx-2"/> :
                                                    <VisibilityOffIcon className="mx-2"/>}
                                                <Button variant="text" color="primary"
                                                        className="mx-1"
                                                        onClick={() => actionManager(quiz, 'edit', 'quiz')}><SettingsIcon/></Button>
                                                <Button variant="text" color="secondary" className="mx-1"
                                                        onClick={() => actionManager(quiz, 'delete', 'quiz')}><DeleteForeverIcon/></Button>
                                            </div>
                                        </div>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        {quiz && renderQuestions(quiz)}
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                        );
                    })
                }

            </div>
            <ActionDialog
                open={actionQuizDelete}
                title={`Are you sure to delete this quiz : ${quiz?.name[lang]}`}
                openHook={setActionQuizDelete}
                actionHook={deleteQuiz}
            />
            {
                quiz &&
                <FullScreenDialog
                    open={actionQuizEdit}
                    openHook={setActionQuizEdit}
                    actionHook={updateList}
                    componentToDisplay={
                        <QuizForm
                            quiz={quiz}
                            setQuiz={setQuiz}
                            actionHook={updateList}
                            gameList={gameList}
                        />
                    }
                />
            }
            {
                quiz &&
                <FullScreenDialog
                    open={actionQuizCreate}
                    openHook={setActionQuizCreate}
                    actionHook={updateList}
                    componentToDisplay={
                        <QuizForm

                            quiz={quiz}
                            setQuiz={setQuiz}
                            actionHook={updateList}
                            gameList={gameList}
                            creationMode={true}
                        />
                    }
                />
            }

            {question && <FullScreenDialog
                open={questionActionEdit}
                openHook={setQuestionActionEdit}
                actionHook={updateList}
                componentToDisplay={
                    <QuizQuestionForm
                        handleChangeQuestion={updateQuestion}
                        question={question}
                        setQuestion={setQuestion}
                        actionHook={updateList}
                        gameList={gameList}
                    />
                }
            />}
            {question && <FullScreenDialog
                open={questionActionCreate}
                openHook={setQuestionActionCreate}
                actionHook={updateList}
                componentToDisplay={
                    <QuizQuestionForm
                        handleChangeQuestion={postQuestion}
                        question={question}
                        setQuestion={setQuestion}
                        actionHook={updateList}
                        gameList={gameList}
                    />
                }
            />}
        </div>
    );
};