import { CardActions, CardContent, CircularProgress, Divider } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import TextField from '@material-ui/core/TextField';
import SendIcon from '@material-ui/icons/Send';
import moment from 'moment';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { ChatMessage, EventMessage, SenderType } from 'src/models/ChatMessage';
import { TournamentTeam } from 'src/models/TournamentMatch';
import eventSourceSingletonRT from 'src/services/eventSourceSingletonRT';
import { CLK, MatchMessagingService } from 'src/services/match-messaging.service';
import { store } from 'src/store';
import './MatchMessaging.scss';



interface MatchMessagingProps {
    roomId: string;
    teams: Array<TournamentTeam>;
}

class UserColors {
    id: string;
    color: string;
}

export const MatchMessaging: React.FunctionComponent<MatchMessagingProps> = ({roomId , teams}) => {
    const [messageText, setMessageText] = useState<string>('');
    const [usersColors, setUsersColors] = useState<Array<UserColors>>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [messages, _setMessages] = useState<Array<EventMessage>>([]);
    const [newMessage, setNewMessage] = useState<boolean>(true);
    const messagesRef = useRef(messages);
    const setMessages = (messages: Array<EventMessage>) => {
        const data = messages.sort((a, b) => Date.parse(a.date) - Date.parse(b.date));
        messagesRef.current = data;
        _setMessages(data);
    };
    const loader = useRef<HTMLDivElement>(null);
    // eslint-disable-next-line
    const admin = store.getState().currentAdministrator!;

    const [lastKey, setLastKey] = useState<CLK | undefined>(undefined);
    const [pageNum, setPageNum] = useState(1);


    const colorizeUsers = (messages: Array<EventMessage>) => {
        const colorPalette = [{color:'#B2F617',team:1}, {color:'#00FFD1',team:2}, {color: '#007BFF',team:3}, {color: '#FF5B51',team:4},
            {color: '#48578E',team:5},{color: '#E6224E',team:6} ,{color: '#FCD719',team:7} ,{color: '#FC4910',team:8} ,{color: '#E70A9C',team:9}];

        for (const message of messages) {
            const posOfSender =  teams.filter((team)=> team && (Object.keys(team['players'])).includes(message.sender))[0]?.pos ;

            usersColors.push({id: message.sender, color: colorPalette.filter((o)=> o.team === posOfSender )[0]?.color });
        }

        return usersColors;
    };

    useEffect( () => {
        getMessagesFromRoom(true);
        eventSourceSingletonRT.subscribe('chat', roomId, () => localStorage.getItem('authToken'),
            (target, message) => {
                if (!admin) {
                    return;
                }

                const messages = messagesRef.current;
                const sentMessageIdx = messages.findIndex(m => m.content.id === message.content.id);
                if (sentMessageIdx !== -1) {
                    const newMessages = [...messages];
                    newMessages.splice(sentMessageIdx, 1, message);
                    setMessages(newMessages);
                } else {
                    setMessages([...messages, message]);
                }
            }
        );

        return function cleanup() {
            eventSourceSingletonRT.unsubscribe('chat', roomId);
        };
        // eslint-disable-next-line
    }, [roomId]);

    useEffect(() => {
        if (lastKey) {
            getMessagesFromRoom (false);
        }
        // eslint-disable-next-line
    },[pageNum])

    useEffect(() => {
        if (newMessage) {
            executeScroll(newMessage);
        } else {
            executeScroll();
        }
        // eslint-disable-next-line
    }, [messages]);

    const executeScroll = (newMessage: boolean = false) => {
        const divToScrollTo = document.querySelector('.message-box .message:last-child');
        const scrollCheckPoint = 1330;
        if (divToScrollTo && lastKey && !newMessage) {
            // eslint-disable-next-line
            // @ts-ignore
            divToScrollTo.parentNode.scrollTop = scrollCheckPoint;
            setNewMessage(false);
        } else if(divToScrollTo && newMessage) {
            // eslint-disable-next-line
            // @ts-ignore
            divToScrollTo.parentNode.scrollTop = divToScrollTo.offsetTop;
        }
    };

    const handleKeyDown = (e: any) => {
        if (e.keyCode === 13) {
            sendMessage();
        }
    };

    const onMessageTextChange = (value: string) => {
        setMessageText(value);
    };

    const sendMessage = () => {
        if (!admin) {
            toast.error('Not logged in');
            return;
        }
        if (!messageText) {
            return;
        }
        const msg = eventSourceSingletonRT.sendMessage('chat', roomId, messageText, admin);
        if (!msg) {
            toast.error('Failed to send message');
            return;
        }
        setMessages([...messages, msg]);
        setMessageText('');
        setNewMessage(true);
    };

    const displayColor = (userType: SenderType, userId: string) => {
        if (userType !== SenderType.Admin) {
            if (usersColors) {
                const c = usersColors.find((elem: UserColors) => elem.id === userId);
                return c ? c.color : '';
            }
        }
        return '#FF0000';
    };

    const getMessagesFromRoom = (reset: boolean = false) => {
        MatchMessagingService.getMessagesFromRoom(roomId ,  reset ? undefined : lastKey ).then(data => {
            if (!data) {
                toast.error('Failed to get messages');
            }

            setUsersColors(colorizeUsers(data.list));
            setIsLoading(false);
            executeScroll();

            if (lastKey && !reset) {
                setMessages(messages.concat(data.list));
            } else {
                setMessages(data.list);
            }

            if (data.lastKey) {
                setLastKey(data.lastKey);
            } else {
                setLastKey(undefined);
            }
        });
    }


    const scrollObserver = useCallback((entries: any) => {
            if (entries[0].isIntersecting) {
                setPageNum((no) => no + 1);
            }
    }, []);

    useEffect(() => {
        const observer = new IntersectionObserver(scrollObserver, {});
        if (loader.current !== null) {
            observer.observe(loader.current);
        }
    }, [scrollObserver]);

    const attenuateHexColor = (hex: string) => {
        const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        return result ?
            `rgb(${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(result[3], 16)}, 0.3)`
        : '';
    }

    return (
        <Card className="match-messaging w-100">
            <CardContent className="message-box" id="tnul">
            <div ref={loader} />
                {
                    isLoading ? <CircularProgress className="d-block mx-auto"/>
                     :
                    messages.map((m, index: number) => {
                        const content = m.content as ChatMessage;
                        return (
                            <div
                                className={`message mx-2 ${index + 1 === messages.length && 'last'} ${!m.target && 'pending'}`}
                                key={index} id={index.toString()}>
                                <div
                                    className={`message-author d-flex justify-content-start ${m.sender === admin._id ? 'me' : ''}`}
                                    style={{color: displayColor(m.senderType, m.sender)}}>
                                    {m.senderName}
                                </div>
                                <div
                                    className={`message-content align-items-center d-flex  ${m.sender === admin._id ? 'me' : ''}`}
                                    style={{backgroundColor: attenuateHexColor(displayColor(m.senderType, m.sender))}}>
                                    <div className="mx-2">{content.message}</div>
                                </div>
                                {!m.target && <div className="message-date d-flex justify-content-end">sending...</div>}
                                {m.target && <div className="message-date d-flex justify-content-end"
                                                  title={moment(m.date).format('LLLL')}>{moment(m.date).format('LLLL')}</div>}
                            </div>
                        );
                    })
                }
                {
                    !isLoading && messages.length === 0 &&
                    <div>No messages</div>
                }
            </CardContent>
            <Divider/>
            <CardActions>
                <div className="d-flex w-100">
                    <TextField
                        placeholder="Write message here"
                        className="w-100"
                        value={messageText}
                        onChange={(event: any) => {
                            onMessageTextChange(event.currentTarget.value);
                        }}
                        onKeyDown={handleKeyDown}
                    />
                    <div
                        className="mouse-pointer d-flex justify-content-center align-items-center mx-3"
                        onClick={() => {
                            sendMessage();
                        }}
                    >
                        <SendIcon fontSize="large" className="primary-color"/>
                    </div>
                </div>
            </CardActions>
        </Card>
    );
};
