import { Dialog, DialogContent, DialogTitle, Button, DialogActions } from '@material-ui/core';
import React from 'react';

interface DeleteFileFormProps {
    openDialog: boolean;
    name: string;
    setOpenDialog: (isOpen: boolean) => void;
    actionHook: () => void;
}

export const DeleteFileForm: React.FunctionComponent<DeleteFileFormProps> = (props: DeleteFileFormProps) => {
    const close = () => {
        props.setOpenDialog(false);
    }

    const save = () => {
        close();
        props.actionHook();
    }

    return (
        <Dialog open={props.openDialog} onClose={() => close()} aria-labelledby="form-dialog-title">
            <div className="d-flex justify-content-between">
                <DialogTitle id="form-create-folder">Delete `{props.name}`</DialogTitle>
            </div>
            <DialogContent className="d-flex">
                <div>
                    <span>If you delete this, be sure it is not used because it can create some issue.</span>
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => close()}>Cancel</Button>
                <Button onClick={() => save()} color="primary">Confirm</Button>
            </DialogActions>
        </Dialog>
    );
};