import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import React, {useState} from 'react';
import {toast} from 'react-toastify';
import {NoPlatform } from '../../../models/Platform';
import { NGPlatformService } from 'src/services/ngplatform.service';
import { MenuItem, Select } from '@material-ui/core';

interface NoPlatformFormProps {
    platformProps: NoPlatform;
    actionHook: any;
    creationMode?: boolean;
}

export const NoPlatformForm: React.FunctionComponent<NoPlatformFormProps> = ({platformProps, actionHook, creationMode}) => {
    const [platform, setPlatform] = useState<NoPlatform>(platformProps);
    const [edit, setEdit] = useState<boolean>(false);

    const onPlatformChange = (event: any) => {
        const {name, value} = event.target;

            setPlatform(Object.assign({}, platform, {
                [name]: value,
            }));
    };

    const save = () => {
        if (platform) {
            if (edit) {
                NGPlatformService.updatePlatform(platform.id, platform).then((data) => {
                    if (data) {
                        toast.success('Platform saved');
                        actionHook(true);
                    } else {
                        toast.error('Error while updating Platform');
                        actionHook(false);
                    }
                });
            } else {
                NGPlatformService.createPlatform(platform).then(() => {
                    toast.success('Platform created');
                    actionHook(true);
                }).catch(() => {
                    toast.error('Error while creating Platform');
                    actionHook(false);
                });
            }
        }
    };

    return (
        <div className="w-100">
            <div className="w-100 d-flex">
                <div className="w-50">
                    {
                        !creationMode &&
                        <FormControlLabel
                            control={<Switch color="primary" checked={edit} onChange={() => setEdit(!edit)}/>}
                            label="Edit"
                            labelPlacement="start"
                        />
                    }

                </div>
                <div className="w-50">
                    {
                        (edit || creationMode) &&
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                save();
                            }}
                        >
                            Save
                        </Button>
                    }
                </div>
            </div>
            <div className="d-flex mb-3">
                {
                    <TextField
                        disabled={!edit && !creationMode}
                        label="ID"
                        defaultValue={platform.id}
                        className="w-50"
                        name="id"
                        onChange={(event: any) => {
                            onPlatformChange(event);
                        }}
                    />
                }
            </div>
            <div className="d-flex mb-3">
                <TextField
                    disabled={!edit && !creationMode}
                    label="Title"
                    defaultValue={platform.title}
                    className="w-50 ml-3"
                    name="title"
                    onChange={(event: any) => {
                        onPlatformChange(event);
                    }}
                />
            </div>
            <div className="mb-3">
            <Select
                disabled={!edit && !creationMode}
                label="Active"
                defaultValue={platform.active}
                className="w-50 ml-3"
                name="active"
                onChange={(event: any) => {
                    onPlatformChange(event);
                }}
            >
                <MenuItem value={0}>Inactive</MenuItem>
                <MenuItem value={1}>Active</MenuItem>
            </Select>
            </div>
            <div className="mb-3">
                <TextField
                    disabled={!edit && !creationMode}
                    label="Color"
                    defaultValue={platform.color}
                    className="w-50 ml-3"
                    name="color"
                    onChange={(event: any) => {
                        onPlatformChange(event);
                    }}
                />
            </div>
        </div>
    );
};