import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import React, { ChangeEvent, useState, } from 'react';
import { Country } from 'src/models/Country';
import { Currency } from 'src/models/Currency';
import { PremiumPlan, PremiumPlanInterval } from 'src/models/PremiumPlan';
import { PremiumService } from 'src/services/premium.service';
import Input from "@material-ui/core/Input";
import Checkbox from "@material-ui/core/Checkbox";
import ListItemText from "@material-ui/core/ListItemText";

interface PremiumPlanFormProps {
    premiumPlan: any;
    setPremiumPlan: any;
    products: any;
    currencies: Array<Currency> | undefined;
    countries: Array<Country> | undefined;
    creationMode?: boolean;
    actionHook: any;
}

export const PremiumPlanForm: React.FunctionComponent<PremiumPlanFormProps> = (props: PremiumPlanFormProps) => {
    const [premiumPlan, setPremiumPlan] = useState<PremiumPlan>(props.premiumPlan);
    const [selectedProduct, setSelectedProduct] = useState<string>(props.premiumPlan.product);
    const [oneTimePayment, setOneTimePayment] = useState<boolean>(props.premiumPlan.oneTimePayment || false);

    const [selectedCurrency, setSelectedCurrency] = useState<string>(props.premiumPlan.currency);
    const [selectedInterval, setSelectedInterval] = useState<string>(props.premiumPlan.interval);
    const [state, setState] = useState({
        active: props.premiumPlan.active,
    });
    const [selectedCountries, setSelectedCountries] = useState<Array<string>>(props.premiumPlan.countries || []);
    const handleActiveChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setState({...state, [event.target.name]: event.target.checked});
        premiumPlan.active = event.target.checked;
        setPremiumPlan(premiumPlan);
    };

    if (!premiumPlan) {
        return <></>;
    }

    const handleCurrency = (event: any) => {
        setSelectedCurrency(event.target.value);
        premiumPlan.currency = event.target.value;
        setPremiumPlan(premiumPlan);
    };

    const handleInterval = (event: any) => {
        setSelectedInterval(event.target.value);
        premiumPlan.interval = event.target.value;
        setPremiumPlan(premiumPlan);
    };


    const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setSelectedProduct(event.target.value as string);
        premiumPlan.product = selectedProduct;
    };

    const handleCountriesChange = (event: any) => {
        premiumPlan.countries = event.target.value;
        setPremiumPlan(premiumPlan);
        setSelectedCountries(event.target.value as string[]);
    };

    const onPlanChange = (event: any) => {
        const {name, value} = event.currentTarget;
        setPremiumPlan(Object.assign({}, premiumPlan, {
            [name]: value,
        }));
    };

    const save = () => {
        if(props.creationMode) {
            premiumPlan.product = selectedProduct;
            premiumPlan.oneTimePayment = oneTimePayment;
            PremiumService.createPlan(premiumPlan).then(() => {
                props.actionHook(true);
            });
        } else {
            PremiumService.updatePlanCountries(premiumPlan).then(() => {
                props.actionHook(true);
            });
        }
    };

    const renderSaveButton = () => {
        return (
            <Button
                className="mt-5 w-25 mx-auto"
                variant="contained"
                color="primary"
                onClick={() => {
                    save();
                }}
            >
                Save
            </Button>
        );
    };

    return (
        <form className="d-flex flex-column">
            <div className="d-flex flex-row justify-content-between">
                <FormControlLabel
                    disabled={!props.creationMode}
                    control={<Switch checked={state.active} onChange={handleActiveChange} name="active"
                                     color="primary"/>}
                    label="Published"
                    labelPlacement="start"
                />
            </div>

            <div className="d-flex flex-row row my-2">
                <TextField
                    disabled={!props.creationMode}
                    className="col-12 col-sm-6 px-3 py-2"
                    name="name"
                    label="Name"
                    value={premiumPlan.name}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => (onPlanChange(event))}
                />
                <FormControl className="col-12 col-sm-6 px-3 py-2">
                    <InputLabel shrink>
                        Product
                    </InputLabel>
                    <Select
                        disabled={!props.creationMode}
                        label="Product"
                        name="product"
                        value={selectedProduct}
                        onChange={handleChange}
                    >
                        {props.products.map((product: any, index: number) => {
                            return <MenuItem key={index} value={product._id}>{product.name}</MenuItem>;
                        })
                        }
                    </Select>
                </FormControl>
                <TextField
                    disabled={!props.creationMode}
                    className="col-12 col-sm-6 px-3 py-2"
                    name="price"
                    label="Price"
                    value={premiumPlan.price}
                    type="number"
                    onChange={(event: ChangeEvent<HTMLInputElement>) => (onPlanChange(event))}
                />
                <FormControl className="col-12 col-sm-6 px-3 py-2">
                    <InputLabel id="demo-simple-select-label">Currencies</InputLabel>
                    <Select
                        label="Currencies"
                        name="currencies"
                        value={selectedCurrency}
                        onChange={handleCurrency}
                        disabled={!props.creationMode}
                    >
                        {
                            props.currencies &&
                            props.currencies.map((currency: Currency) => {
                                if (currency.active) {
                                    return (
                                        <MenuItem key={currency._id}
                                                  value={currency.symbol.toLowerCase()}>{currency.symbol} - {currency.picto}</MenuItem>
                                    );
                                } else {
                                    return (<div key={currency._id} className="d-none"/>);
                                }
                            })
                        }
                    </Select>
                </FormControl>
                <FormControl className="w-100 pb-4">
                    <InputLabel>Countries</InputLabel>
                    <Select
                        multiple
                        value={selectedCountries}
                        onChange={handleCountriesChange}
                        input={<Input/>}
                        renderValue={(selected) => {
                            return (
                                <div>
                                    {
                                        (selected as Array<string>).map((c: string, index: number) => {
                                            const country = props.countries && props.countries.find(country => country.iso2.toLowerCase() === c.toLowerCase());
                                            return <div className="mx-1"
                                                        key={index}>{country ? country.name : ''}</div>;
                                        })
                                    }
                                </div>
                            );
                        }}
                    >
                        {
                            props.countries && props.countries.map((country: Country) => (
                                <MenuItem key={country._id} value={country.iso2.toLowerCase()}>
                                    <Checkbox checked={selectedCountries.indexOf(country.iso2.toLowerCase()) > -1}/>
                                    <ListItemText primary={country.name}/>
                                </MenuItem>
                            ))
                        }
                    </Select>
                </FormControl>
                <FormControl className="col-12 col-sm-6 px-3 py-2">
                    <InputLabel id="demo-simple-select-label">Interval</InputLabel>
                    <Select
                        label="Interval"
                        name="interval"
                        value={selectedInterval}
                        onChange={handleInterval}
                        disabled={!props.creationMode}
                    >
                        {
                            PremiumPlanInterval &&
                            Object.values(PremiumPlanInterval).map((interval: any, index: number) => {
                                return (
                                    <MenuItem key={index} value={interval}>
                                        {interval.charAt(0).toUpperCase() + interval.slice(1)}
                                    </MenuItem>
                                );
                            })
                        }
                    </Select>
                </FormControl>
                <TextField
                    disabled={!props.creationMode}
                    className="col-12 col-sm-3 px-3 py-2"
                    name="duration"
                    label="Duration"
                    value={premiumPlan.duration}
                    type="number"
                    onChange={(event: ChangeEvent<HTMLInputElement>) => (onPlanChange(event))}
                />
                <FormControlLabel
                    disabled={!props.creationMode}
                    className="col-12 col-sm-3 mx-0 d-flex justify-content-center"
                    value="oneTimePayment"
                    control={<Switch color="primary" checked={oneTimePayment} onChange={() => {
                        setOneTimePayment(!oneTimePayment);
                    }}/>}
                    label="User pay in one time (not scheduled)"
                    labelPlacement="start"
                />
            </div>
            {renderSaveButton()}
        </form>
    );
};

