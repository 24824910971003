import CircularProgress from '@material-ui/core/CircularProgress';
import React, {useEffect, useState} from 'react';
import {NCTitle} from 'src/atoms/NCTitle/NCTitle';
import { ActionDialog } from 'src/components/General/ActionDialog/ActionDialog';
import { SearchBar } from 'src/components/General/SearchBar/SearchBar';
import {SimpleList} from 'src/components/General/SimpleList/SimpleList';
import { GroupCountryForm } from 'src/components/GroupCountry/GroupCountry';
import { Country } from 'src/models/Country';
import { GroupCountry } from 'src/models/GroupCountry';
import {CountryService} from 'src/services/country.service';

export const Countries: React.FunctionComponent = () => {
    const [ isLoading, setIsLoading ] = useState(true);
    const [ isLoadingCountries, setIsLoadingCounties ] = useState(true);
    const [ rowsCountry, setRowsCountry ] = useState([]);
    const [ rowsGroupCountry, setRowsGroupCountry ] = useState([]);
    const [ actualPageCountry, setActualPageCountry ] = useState<number>(0);
    const [ actualPageGroupCountry, setActualPageGroupCountry ] = useState<number>(0);
    const [ totalItemCountry, setTotalItemCountry ] = useState<number>(0);
    const [ totalItemGroupCountry, setTotalItemGroupCountry ] = useState<number>(0);
    const [ groupCountry, setGroupCountry ] = useState<GroupCountry>();
    const [ openModal, setOpenModal ] = useState<boolean>(false);
    const [ postActionDelete, setPostActionDelete ] = useState<boolean>(false);
    const [ countries, setCountries ] = useState<Array<Country>>([]);
    const columnsCountry = ['Name', 'Active', 'Iso2'];
    const columnsGroupCountry = ['Value', 'Countries', 'Home filter'];
    const keepRowsCountry = ['name', 'active', 'iso2'];
    const keepRowsGroupCountry = ['value', 'countries', 'isHomeFilter'];
    const searchFields: {[key: string]: { label: string }} = {
        iso2: { label: 'Iso2'},
        name: { label: 'Name'},
        _id: { label: 'ID' },
    }

    const getCountries = (query?: {key: string, item: string}) => {
        CountryService.getCountries(20, actualPageCountry, query).then((data: any) => {
            setTotalItemCountry(Math.ceil(data.total / 20));
            setRowsCountry(data.docs);
            setIsLoadingCounties(false);
        }).catch(error => console.log(error));
    }

    useEffect(() => {
        getCountries();
        CountryService.getAllCountries().then((data: any) => {
            setCountries(data);
        }).catch(error => console.log(error));
        // eslint-disable-next-line
    }, [ actualPageCountry ]);

    const getGroupCountry = () => {
        CountryService.getGroupCountries(20, actualPageGroupCountry).then((data: any) => {
            setTotalItemGroupCountry(Math.ceil(data.total / 20));
            setRowsGroupCountry(data.docs);
            setIsLoading(false);
            setGroupCountry(new GroupCountry('', '', []));
        }).catch(error => console.log(error));
    }

    useEffect(() => {
        getGroupCountry();
        // eslint-disable-next-line
    }, [ actualPageGroupCountry ]);

    const fetchData = (result: boolean) => {
        setPostActionDelete(false);
        if (result) {
            getGroupCountry();
        }
    }

    const handleGroupCountry = (groupCountryUpdated: GroupCountry) => {
        if (groupCountryUpdated._id) {
            CountryService.updateGroupCountry(groupCountryUpdated).then(() => fetchData(true));
        } else {
            CountryService.createGroupCountry({value: groupCountryUpdated.value, countries: groupCountryUpdated.countries}).then(() => fetchData(true));
        }
    }

    const deleteGroupCountry = (choice: boolean) => {
        if (choice && groupCountry) {
            CountryService.deleteGroupCountry(groupCountry._id).then(() => fetchData(true));
        }
    }

    const actionManager = (item: any, kind: string) => {
        console.log('open', item, kind);
        switch (kind) {
            case 'create':
                setOpenModal(true);
                setGroupCountry(new GroupCountry('', '', []));
                break;
            case 'edit':
                setOpenModal(true);
                setGroupCountry(item);
                break;
            case 'delete':
                setGroupCountry(item);
                setPostActionDelete(true);
                break;
            default:
                break;
        }
    };

    const searchCountry = (research?: any) => {
        let value = '';
        if (research) {
            Object.keys(research).forEach((item) => {
                if (research[item]) {
                    value = item;
                }
            });
            setIsLoadingCounties(true);
            getCountries({key: value, item: research[value]});
        }
    }

    return (
        <div className="row">
            <div className="NC-container NC-card col-4">
                <NCTitle text={'Group Country'}/>
                <div className="d-flex">
                    {
                        isLoading && <CircularProgress className="mx-auto"/>
                    }
                    {
                        !isLoading &&
                        <>
                            <SimpleList
                                rows={rowsGroupCountry}
                                columns={columnsGroupCountry}
                                keepRows={keepRowsGroupCountry}
                                pagination={{actual: actualPageGroupCountry, total: totalItemGroupCountry, setActual: setActualPageGroupCountry}}
                                actionsButtons={{create: true, edit: true, delete: true}}
                                handleClickOpen={actionManager}
                            />
                            <ActionDialog
                                open={postActionDelete}
                                title={`Are you sure to delete this group country ?`}
                                openHook={setPostActionDelete}
                                actionHook={deleteGroupCountry}
                            />
                        </>
                    }
                </div>
            </div>

            <div className="NC-container NC-card col-7">
                {
                    openModal &&
                        <GroupCountryForm
                            groupCountry={groupCountry}
                            countries={countries}
                            setOpenModal={setOpenModal}
                            actionHook={handleGroupCountry}
                        />
                }
                <NCTitle text={'Countries'}/>
                <div>
                    {
                        isLoadingCountries && <CircularProgress className="mx-auto"/>
                    }
                    {
                        !isLoadingCountries &&
                        <>
                            <SearchBar searchFields={searchFields} actionHook={searchCountry}/>
                            <SimpleList
                                rows={rowsCountry}
                                columns={columnsCountry}
                                keepRows={keepRowsCountry}
                                pagination={{actual: actualPageCountry, total: totalItemCountry, setActual: setActualPageCountry}}
                            />
                        </>
                    }
                </div>
            </div>
        </div>
    );
};