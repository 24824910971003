import React, {ChangeEvent, useState} from 'react';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import {useStoreState} from 'src/store';
import {TrainingService} from 'src/services/training.service';
import {LangPicker} from 'src/components/General/LangPicker/LangPicker';
import {Game} from 'src/models/Game';
import {TrainingQuiz} from 'src/models/TrainingQuiz';
import { TranslatedString } from 'src/models/TranslatedString';

interface QuizFormProps {
    quiz: TrainingQuiz;
    setQuiz: any;
    actionHook: any;
    gameList: Array<Game>;
    creationMode?: boolean
}

export const QuizForm: React.FunctionComponent<QuizFormProps> = (props: QuizFormProps) => {
	const [ edit, setEdit ] = useState<boolean>(false);
	const [ quiz, setQuiz ] = useState<TrainingQuiz>(props.quiz);
	const [ lang, setLang ] = useState(useStoreState(state => state.contentLang));

	if (!quiz) {
		return (<></>);
	}

	const handleSetLang = (lang: string) => {
		setLang(lang);
	};

	const onQuizChange = (event: any, isTranslatedString = false, optionalArgument: any = undefined) => {
		let name, value: any;

		if (optionalArgument) {
			name = event.target.name;
			value = event.target.value;
			const tt = props.gameList.find(g => g._id === value);
			if (tt) {
				quiz.game = tt;
			}
		} else {
			name = event.currentTarget.name;
			value = event.currentTarget.value;
		}

		if (isTranslatedString) {
			const myLang = quiz[name as keyof TrainingQuiz] as TranslatedString;
			myLang[lang] = value;
			setQuiz(Object.assign({}, quiz, {
				[name.langValue]: value,
			}));
		} else {
			if (!optionalArgument) {
				setQuiz(Object.assign({}, quiz, {
					[name]: value,
				}));
			}

		}
	};

	const onQuizChangeBool = (event: any, valueBool: boolean) => {
		setQuiz(Object.assign({}, quiz, {
			[event.currentTarget.name]: valueBool,
		}));
	};

	const save = () => {
		if (edit) {
			TrainingService.updateQuiz(quiz._id, quiz).then((data) => {
				if (!data) {
					props.actionHook(false);
				}
			});
		} else {
			TrainingService.createQuiz(quiz).then((data) => {
				if (!data) {
					props.actionHook(false);
				}
			});
		}
		props.actionHook(true);
	};

	const renderEditButton = () => {
		if (props.creationMode) {
			return <></>;
		}
		return (
			<FormControlLabel
				value="edit"
				control={<Switch color="primary" checked={edit} onChange={() => {
					setEdit(!edit);
				}}/>}
				label="Edit"
			/>
		);
	};

	const renderSaveButton = () => {
		if (!edit && !props.creationMode) {
			return <></>;
		}
		return (
			<Button
				className="mt-5 w-25 mx-auto"
				variant="contained"
				color="primary"
				onClick={() => {
					save();
				}}
			>
                Save
			</Button>
		);
	};


	return (
		<form className="d-flex flex-column">
			{renderEditButton()}
			<div className="row">
				<div className="col-12 px-3 row mb-3 translated-background">
					<TextField
						disabled={!edit && !props.creationMode}
						className="col-12 col-sm-6 my-auto px-3"
						id='name'
						name="name"
						label="Name"
						value={quiz.name[lang] || ''}
						onChange={(event: ChangeEvent<HTMLInputElement>) => (onQuizChange(event, true))}
					/>
					<div className="col-12 col-sm-6 d-flex justify-content-center">
						<LangPicker title={'Translate'} setLang={handleSetLang}/>
					</div>
				</div>
				<FormControl className="col-12 col-sm-6 px-3">
					<InputLabel>Game</InputLabel>
					<Select
						labelId="Game"
						defaultValue={quiz.game._id ? quiz.game._id : ''}
						onChange={(event: any) => {
							onQuizChange(event, false, true);
						}}
						name="game"
					>
						<MenuItem value=''>Select a game</MenuItem>
						{
							props.gameList.map((game) => {
								return (
									<MenuItem value={game._id} key={game._id}>
										{game.name}
									</MenuItem>
								);
							})
						}
					</Select>
				</FormControl>
				<TextField
					disabled
					className="col-12 col-sm-6 px-3"
					label="ID"
					name="_id"
					defaultValue={quiz._id}
				/>
				<FormControlLabel
					value="start"
					disabled={!edit && !props.creationMode}
					className="col-12 col-sm-6 px-3"
					control={
						<Switch
							color="primary"
							checked={quiz.published}
							name="published"
							onChange={(event: any) => {
								onQuizChangeBool(event, !quiz.published);
							}}
						/>
					}
					label="Published"
					labelPlacement="start"
				/>

			</div>
			{renderSaveButton()}
		</form>
	);
};