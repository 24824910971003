import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import ListSubheader from '@material-ui/core/ListSubheader/ListSubheader';
import createStyles from '@material-ui/core/styles/createStyles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { EGameImage } from 'src/models/Game';
import { Media } from './Media';
import { MediaLibraryService } from 'src/services/media-library.service';

interface MediaManagerProps {
    path: Array<string>;
    secondPath?: Array<string>;
    id: string;
    mediaNames: Array<string>;
    optionalArgument?: any;
    disabled?: boolean;
    width?: string;
    customTitle?: string;
    slug?: string;
}

export const MediaManager: React.FunctionComponent<MediaManagerProps> = (
    props: MediaManagerProps
) => {
    const [mediasList, setMediasList] = useState<Array<any>>();

    const useStyles = makeStyles(() =>
        createStyles({
            root: {
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'space-around',
                overflow: 'hidden',
            },
            gridList: {
                width: '100%',
            },
            icon: {
                color: 'rgba(255, 255, 255, 0.54)',
            },
        }),
    );
    const classes = useStyles();

    useEffect(() => {
        createMediaLinks();
    },[props.mediaNames]);


    const createMediaLinks = () => {
        setMediasList(
            props.mediaNames.map((name: string) => {
                const path = getImagePath(name as EGameImage);
                const s3Key = `${renderKey(props.path)}${props.id}-${renderKey(props.secondPath)}${name}`;
                return {
                    link: `${String(process.env.REACT_APP_S3_URL)}/${path}`,
                    name,
                    s3Key,
                    path: props.path,
                    author: '',
                };
            })
        );
    };

    const uploadMedia = async(type: string, path: Array<string>, key: string, file: any) => {
        try {
            console.log('PATH', path, type, key);
            await MediaLibraryService.createMedia(`/${renderPath(props.secondPath)}${props.slug}/${type}` ,file);
            toast.success('File uploaded successfully');
            return true;
        } catch(response: any) {
            toast.error(response);
            return false;
        }
    };

    const renderPath = (path: Array<string> | undefined) => {
        if (!path || path.length === 0) {
            return '';
        }
        const concatPath = path.join('/');
        return `${concatPath}/`;
    };

    const renderKey = (path: Array<string> | undefined) => {
        if (!path || path.length === 0) {
            return '';
        }
        const concatPath = path.join('-');
        return `${concatPath}-`;
    };

    const getImagePath = (name: EGameImage) => {
        return `${renderPath(props.path)}${renderPath(props.secondPath)}${props.slug}/${name}`;
    }

    return (
        <div className={classes.root}>
            <GridList cellHeight={180} className={classes.gridList}>
                <GridListTile key="Subheader" cols={2} style={{height: 'auto'}}>
                    <ListSubheader component="div" />
                </GridListTile>
                {mediasList?.map(tile => 
                    <Media 
                        key={tile.link}
                        title={tile.name}
                        s3Key={tile.s3Key} 
                        link={tile.link} 
                        path={tile.path} 
                        icon={classes.icon}
                        uploadMedia={uploadMedia}
                        disabled={props.disabled}
                        width={props.width} 
                    /> 
                )}
            </GridList>
        </div>
    );
};