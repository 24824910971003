import axios, { AxiosError } from 'axios';
import { toast } from 'react-toastify';
import { Company } from 'src/models/Company';

export class CompanyService {
    private static baseUrl: string = String(process.env.REACT_APP_ADMINAPI_URL);
    static token = (): string | null => localStorage.getItem('accessToken');

    static async getCompanies(limit: number = 10, page: number = 0) {
        let res;

        try {
            res = await axios.get(
                `${CompanyService.baseUrl}/companies?limit=${limit}&page=${page}`, {
                    headers: {
                        'x-access-token': CompanyService.token(),
                    }
                }
            );
        } catch (e) {
            throw (e as AxiosError).response;
        }

        let companyList = [];
        if (!res.data && !res.data.docs) {
            throw Error;
        }
        companyList = res.data.docs.map((item: any) => {
            return new Company(
                item._id,
                item.name,
                item.address,
                item.country,
                item.contact_email,
                item.slug,
            );
        });
        return {
            docs: companyList,
            page: res.data.page,
            pages: res.data.pages,
            total: res.data.total,
        }
    }


    static async getAllCompanies() {
        let companiesList: Array<Company> = [];

        try {
            companiesList = (await axios.get(`${CompanyService.baseUrl}/companies/all`, {
                headers: {
                    'x-access-token': CompanyService.token(),
                }
            })).data.docs;
            companiesList.sort((a: Company, b: Company) => {
                if (a.name < b.name) {
                    return -1;
                }
                if (a.name > b.name) {
                    return 1;
                }
                return 0;
            })

        } catch (e) {
            throw (e as AxiosError).response;
        }

        return companiesList;
    }

    static async createCompany(company: Company): Promise<Company> {
        if (!company) {
            throw Error;
        }

        delete company['_id' as keyof Company];
        let newCompany: Company;

        try {
            newCompany = (await axios.post(`${CompanyService.baseUrl}/companies`, company, {
                headers: {
                    'x-access-token': CompanyService.token(),
                }
            })).data;
        } catch (e) {
            toast.error('Fail to create company, try again later or contact #public-backoffice');
            throw Error;
        }

        toast.success('Company created');
        return newCompany
    }

    static async updateCompany(company: Company): Promise<boolean> {
        if (!company) {
            return false;
        }
        try {
            await axios.put(`${CompanyService.baseUrl}/companies/${company._id}`, company, {
                headers: {
                    'x-access-token': CompanyService.token(),
                }
            });
        } catch (e) {
            toast.error('Fail to update company, try again later or contact #public-backoffice');
            return false;
        }

        toast.success('Company updated');
        return true;
    }

    static async deleteCompany(companyId: string): Promise<boolean> {
        if (!companyId) {
            return false;
        }

        try {
            await axios.delete(`${CompanyService.baseUrl}/companies/${companyId}`, {
                headers: {
                    'x-access-token': CompanyService.token(),
                }
            })
        } catch (e) {
            toast.error('Fail to delete company, try again later or contact #public-backoffice');
            return false
        }


        toast.success('Company deleted');
        return true;
    }
}