import { TranslatedString } from "./TranslatedString";

export class NewsModel {
    _id: string;
    partner?: string | null;

    constructor(
        public title: TranslatedString,
        public content: TranslatedString,
        public image: string,
        public link: TranslatedString,
        public published: boolean,
        public countryGroup: Array<string>,
        public countries: Array<string>,
        public tag: string
    ) {
    }
}

export enum ENewsImage {
    Image = 'Image',
}
