import axios from 'axios';
import { GamePage } from 'src/models/GamePage';

export class GamePageService {
    private static baseUrl: string = String(process.env.REACT_APP_ADMINAPI_URL);
    static token = (): string | null => localStorage.getItem('accessToken');

    static async getGamePages(limit: number = 10, page: number = 0, query: string = ''): Promise<any> {
        let res;
        try {
            res = (await axios.get(
                `${GamePageService.baseUrl}/gamepage?limit=${limit}&page=${page}${query}`, {
                    headers: {
                        'x-access-token': GamePageService.token(),
                    }
                }
            )).data;
        } catch {
            throw Error;
        }

        return {
            docs: res.docs,
            page: res.page,
            total: res.total,
        }
    }

    static async createGamePage(body: GamePage): Promise<GamePage> {
        if (!body) {
            throw Error;
        }
        let newGamePage: GamePage;
        delete body['_id' as keyof GamePage];
        try {
            newGamePage = (await axios.post(
                `${GamePageService.baseUrl}/gamepage`,
                body,
                {
                    headers: {
                        'x-access-token': GamePageService.token(),
                    }
                }
            )).data;
        } catch {
            throw Error;
        }

        return newGamePage;
    }

    static async updateGamePage(_id: string, body: GamePage): Promise<boolean> {
        if (!_id && !body) {
            return false;
        }
        delete body['_id' as keyof GamePage];
        delete body['game' as keyof GamePage];
        delete body.countries;
        delete body.countryGroup;
        try {
            await axios.put(
                `${GamePageService.baseUrl}/gamepage/${_id}`,
                body,
                {
                    headers: {
                        'x-access-token': GamePageService.token(),
                    }
                }
            );
        } catch {
            return false
        }

        return true;
    }

    static async deleteGamePage(_id: string): Promise<boolean> {
        if (!_id) {
            return false;
        }
        try {
            await axios.delete(
                `${GamePageService.baseUrl}/gamepage/${_id}`,
                {
                    headers: {
                        'x-access-token': GamePageService.token(),
                    }
                }
            );
        } catch {
            return false
        }

        return true;
    }
}