import { TranslatedString } from './TranslatedString';

export interface GamePartial {
    _id: string;
    name: string;
    overwolfId: number;
}

export class TrainingPreset {
    public _id: string;

    constructor(
        public name: TranslatedString,
        public path: string,
        public game: GamePartial,
        public kind: string,
        public thematic: string,
        public showInGraph: boolean,
    ) {}
}
