import axios, { AxiosError } from 'axios';
import { Voucher } from 'src/models/Voucher';

export class VoucherService {
    private static baseUrl: string = String(process.env.REACT_APP_PRIVATE_AUTH_URL);
    static authToken = (): string | null => localStorage.getItem('authToken');

    static async getVouchers(limit: number = 20, page: number = 0, query: string = ''): Promise<any> {
        let res;

        try {
            res = await axios.get(
                `${VoucherService.baseUrl}/vouchers?limit=${limit}&start=${page * limit}${query}`,
                {
                    headers: {
                        Authorization: `Bearer ${VoucherService.authToken()}`,
                    },
                },
            );
        } catch (e) {
            throw (e as AxiosError).response;
        }

        let totalItems: number = 0;
        if (res.headers['x-count-item']) {
            totalItems = parseInt(res.headers['x-count-item'], 10);
        }

        return {
            docs: res.data,
            pages: page,
            total: totalItems,
        }
    }

    static async getVoucherByName(voutcherName: string): Promise<Voucher> {
        if (!voutcherName) {
            throw Error;
        }
        let res: Voucher;

        try {
            res = (await axios.get(
                `${VoucherService.baseUrl}/vouchers/${voutcherName}`,
                {
                    headers: {
                        Authorization: `Bearer ${VoucherService.authToken()}`,
                    },
                },
            )).data;
        } catch (e) {
            throw (e as AxiosError).response;
        }

        return res;
    }

    static async createVoucher(body: Voucher): Promise<Voucher> {
        if (!body) {
            throw Error;
        }

        let newVoutcher: Voucher;
        try {
            newVoutcher = (await axios.post(
                `${VoucherService.baseUrl}/vouchers`,
                body,
                {
                    headers: {
                        Authorization: `Bearer ${VoucherService.authToken()}`,
                    },
                },
            )).data;
        } catch (e) {
            throw Error;
        }

        return newVoutcher;
    }

    static async deleteVoucher(voutcherName: string): Promise<boolean> {
        if (!voutcherName) {
            return false;
        }

        try {
            await axios.delete(
                `${VoucherService.baseUrl}/vouchers/${voutcherName}`,
                {
                    headers: {
                        Authorization: `Bearer ${VoucherService.authToken()}`,
                    },
                },
            );
        } catch {
            return false;
        }

        return true;
    }
}