import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Card from '@material-ui/core/Card/Card';
import CardContent from '@material-ui/core/CardContent/CardContent';
import Chip from '@material-ui/core/Chip';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography/Typography';
import { SkipNext, SkipPrevious } from '@material-ui/icons';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import StarsIcon from '@material-ui/icons/Stars';
import VisibilityIcon from '@material-ui/icons/Visibility';
import React from 'react';
import { Link } from 'react-router-dom';
import { MatchScoreType, TournamentMatch, TournamentTeam, TournamentTeamRound } from 'src/models/TournamentMatch';
import { nth } from 'src/utils/nth';
import { TournamentPlayerInfos } from '../TournamentPlayerInfos/TournamentPlayerInfos';

interface TournamentTeamInfosProps {
    team: TournamentTeam;
    tournamentId: string;
    matchId: string;
    match: TournamentMatch;
    refresh: any
}

// eslint-disable-next-line
export const TournamentTeamInfos: React.FunctionComponent<TournamentTeamInfosProps> = ({ team, tournamentId, matchId, match, refresh }) => {
    if (!team) {
        return <></>;
    }

    const renderRoundScore = (round: TournamentTeamRound) => {
        switch (match.setting.scoreType) {
            case MatchScoreType.WinLose:
                return (
                    <>
                        <div>Has won:</div>
                        <div>{round.score != null ? (round.score > 0).toString() : '-'}</div>
                    </>
                );
            case MatchScoreType.Ranking:
                return (
                    <>
                        <div>Rank:</div>
                        <div>{round.score != null ? nth((match.teams.filter(t => t !== null)).length - round.score) : '-'}</div>
                    </>
                );
            case MatchScoreType.Score:
            default:
                return (
                    <>
                        <div>Score:</div>
                        <div>{round.score != null ? round.score : '-'}</div>
                    </>
                );
        }
    };

    return (
        <React.Fragment>
            <Card className="m-2 flex-fill">
                <CardContent>
                    <Typography color="textSecondary" className="d-flex align-items-center" gutterBottom
                                component="div">
                        <AccountCircleIcon fontSize="large"/>
                        <div className="mx-2 align-self-center">{team.name}</div>
                        {
                            team.prev?.includes("matchs") && <Link target="_blank" to={`${team.prev.split("/")[1]}`} className="ml-2">
                                <SkipPrevious className="primary-color"/>
                            </Link>
                        }
                        { team.slug &&
                            <Link to={'/teams/'+ team.slug}>
                                <VisibilityIcon className="primary-color"/> 
                            </Link>
                        } 
                        {
                            team.ranking && match.transition.selection[team.ranking.toString()]?.route.includes("matchs") && <Link target="_blank" to={`${match.transition.selection[team.ranking.toString()].route.split("/")[1]}`} className="ml-2">
                            <SkipNext className="primary-color"/>
                        </Link>
                        }
                        { team.pos === 1 ? <StarsIcon className="yellow-color"/> : ''}
                    </Typography>
                    <Typography color="textSecondary" className="d-flex align-items-center my-2" component="div">
                        <div className="flex-fill">Check-in: {team.checkIn ? <CheckIcon className="primary-color"/> :
                            <ClearIcon className="red-color"/>}</div>
                        <div className="flex-fill d-flex align-items-center" style={{fontSize: '20px'}}>
                            <div>
                                Full Score: <span className="font-weight-bold">{team.fullScore}</span>
                            </div>
                        </div>
                        <div className="flex-fill">Seed: {team.seed}</div>
                        {team.ranking != null && <div className="flex-fill">Ranking: {nth(team.ranking)}</div>}
                    </Typography>
                    <Divider/>
                    <Typography variant="body2" className="mt-2" component="div">
                        <div className="d-flex align-items-center">
                            <h5>Rounds</h5>
                        </div>
                        {
                            team.rounds && Object.keys(team.rounds).map((r: any, index: number) => {
                                return (
                                    <Accordion key={index}>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon/>}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            <Typography component="div"
                                                        className="d-flex justify-content-center align-items-center">
                                                Round {index + 1}
                                            </Typography>
                                        </AccordionSummary>
                                        <AccordionDetails className="d-flex justify-content-center align-items-center">
                                            <div
                                                className="flex-fill flex-column d-flex align-items-center justify-content-center">
                                                {renderRoundScore(team.rounds[r])}
                                            </div>
                                            <div
                                                className="flex-fill flex-column d-flex align-items-center justify-content-center">
                                                <div>Confirmed</div>
                                                <div>{team.rounds[r].confirmed ?
                                                    <Chip label={<CheckIcon/>} color="primary"/> :
                                                    <Chip label={<ClearIcon/>} color="secondary"/>}</div>
                                            </div>
                                            <div
                                                className="flex-fill flex-column d-flex align-items-center justify-content-center">
                                                <div>Diff</div>
                                                <div>{team.rounds[r].diff != null ? team.rounds[r].diff : '-'}</div>
                                            </div>
                                            <div
                                                className="flex-fill flex-column d-flex align-items-center justify-content-center">
                                                <div>Rank</div>
                                                <div>{team.rounds[r].rank != null ? team.rounds[r].rank : '-'}</div>
                                            </div>
                                        </AccordionDetails>
                                    </Accordion>
                                );
                            })
                        }

                    </Typography>
                    <Divider/>
                    <Typography variant="body2" className="mt-2" component="div">
                        <h5>Players</h5>
                        <div className="mt-2">
                            {
                                Object.keys(team.players).map((p: string, index: number) => {
                                    return (
                                        <TournamentPlayerInfos
                                            key={index}
                                            player={team.players[p]}
                                        />
                                    );
                                })
                            }
                        </div>
                    </Typography>
                </CardContent>
            </Card>
        </React.Fragment>
    );
};