import axios, { AxiosError } from 'axios';
import { BanHistory, Phone } from '../models/NoUser';
import { AdministratorService } from './administrator.service';
import { BanType, User } from '@wolfiesports/srm-component';


export interface UserListResponse {
    list: Array<User>;
    lastKey: {
        email: string;
        route: string;
        cname: string;
    };
}

export interface NoUserListBan {
    list: Array<BanHistory>
    lastkey: {
        email: string;
        route: string;
        date: string;
    }
}

export class NoUserService {
    private static baseUrl: string = String(process.env.REACT_APP_NU_URL);

    static searchUser = async (query: string = "", size: number = 10): Promise<UserListResponse> => {
        let res;

        try {
            res = (await axios.get(
                `${NoUserService.baseUrl}/users?size=${size}&search=${encodeURIComponent(query)}`,
                {
                    headers: {
                        "authorization": AdministratorService.token(),
                    },
                    method: "GET",
                }
            )).data;
        } catch (e) {
            throw (e as AxiosError).response;
        }

        return res;
    };

    static async getNoUserById(id: string): Promise<User | null> {
        if (!id) {
            return null
        }
        let res;

        try {
            res = await axios.get(
                `${NoUserService.baseUrl}/admin/users/${id}`,
                {
                    headers: {
                        'authorization': `${AdministratorService.token()}`,
                    }
                }
            );
        } catch (e) {
            throw (e as AxiosError).response;
        }
        return new User({
            email: res.data.email,
            route: res.data.route,
            userId: res.data.userId,
            name: res.data.name,
            code: res.data.code,
            valid: res.data.valid,
            reg: res.data.reg,
            origin: res.data.origin,
            ban: {
                notice: res.data.ban.notice,
                warning: res.data.ban.warning,
                type: res.data.ban.type,
                until: res.data.ban.until,
            },
            country: res.data.country,
            premium: {
                status: res.data.premium.status,
                until: res.data.premium.until
            },
            firstName: res.data.firstName,
            lastName: res.data.lastName,
            birthday: res.data.birthday,
            phone: new Phone(
                res.data.phone.number,
                res.data.phone.valid,
            ),
            optIn: res.data.optIn,
            language: res.data.language,
            currency: res.data.currency
        });
    }

    static async getNoUserBanById(id: string, type?: BanType): Promise<NoUserListBan> {
        let res;
        try {
            res = await axios.get(
                `${NoUserService.baseUrl}/admin/users/${id}/bans`,
                {
                    method: 'GET',
                    headers: {
                        'authorization': `${AdministratorService.token()}`,
                    },
                    params: {
                        type
                    }
                }
            );
        } catch (e) {
            throw (e as AxiosError).response;
        }
        return res.data;
    }

    static async addBan(bh: BanHistory): Promise<void> {
        try {
            await axios.patch(
                `${NoUserService.baseUrl}/admin/users/${bh.userId}/bans`,
                bh,
                {
                    headers: {
                        'authorization': `${AdministratorService.token()}`,
                    },
                }
            );
        } catch (e) {
            throw (e as AxiosError).response;
        }
    }

    static async getUserByEmail(userEmail: string, lastKeyEmail?: string): Promise<UserListResponse> {
        if (!userEmail) {
            console.log("Throwing error get email")
            throw Error;
        }

        let res;
        try {
            res = await axios.get(
                `${NoUserService.baseUrl}/public/users`,
                {
                    headers: {
                        'authorization': AdministratorService.token(),
                    },
                    params: { search: userEmail, email: lastKeyEmail }
                }
            );
        } catch (e) {
            throw (e as AxiosError).response;
        }

        return res.data;
    }

    static async getUserByNickName(nickName: string): Promise<UserListResponse> {
        if (!nickName) {
            throw Error;
        }
        let res;
        try {
            res = await axios.get(
                `${NoUserService.baseUrl}/users`,
                {
                    headers: {
                        'authorization': AdministratorService.token(),
                    },
                    params: { search: nickName }
                }
            );
        } catch (e) {
            throw (e as AxiosError).response;
        }

        return res.data;
    }

    static async getUsersByOrigin(partner: string): Promise<User | null> {
        let res;

        try {
            res = await axios.get(
                `${NoUserService.baseUrl}/admin/partners/${partner}/users`,
                {
                    headers: {
                        'authorization': `${AdministratorService.token()}`,
                    }
                }
            );
        } catch (e) {
            throw (e as AxiosError).response;
        }
        return new User({
            email: res.data.email,
            route: res.data.route,
            userId: res.data.userId,
            name: res.data.name,
            code: res.data.code,
            valid: res.data.valid,
            reg: res.data.reg,
            origin: res.data.origin,
            ban: {
                notice: res.data.ban.notice,
                warning: res.data.ban.warning,
                type: res.data.ban.type,
                until: res.data.ban.until,
            },
            country: res.data.country,
            premium: {
                status: res.data.premium.status,
                until: res.data.premium.until
            },
            firstName: res.data.firstName,
            lastName: res.data.lastName,
            birthday: res.data.birthday,
            phone: new Phone(
                res.data.phone.number,
                res.data.phone.valid,
            ),
            optIn: res.data.optIn,
            language: res.data.language,
            currency: res.data.currency
        });
    }

    static async getUsersByOrganization(partner: string): Promise<any> {
        let res;
        try {
            res = await axios.get(
                `${NoUserService.baseUrl}/admin/partners/${partner}/users`,
                {
                    headers: {
                        'authorization': AdministratorService.token(),
                    },
                    params: { search: 'any' }
                }
            );
        } catch (e) {
            throw (e as AxiosError).response;
        }

        return res.data;
    }

    static async updateUserCurrency(userId: string, newCurrency: string) {
        let res;
        try {
            res = await axios.patch(
                `${NoUserService.baseUrl}/users/${userId}/currency`,
                { newCurrency },
                {
                    headers: {
                        authorization: AdministratorService.token(),
                    },
                }
            );
        } catch (e) {
            throw (e as AxiosError).response;
        }

        return res.data;
    }

    static async updateUserCountry(userId: string, newCountry: string) {
        let res;
        try {
            res = await axios.patch(
                `${NoUserService.baseUrl}/users/${userId}/country`,
                { newCountryCode: newCountry },
                {
                    headers: {
                        authorization: AdministratorService.token(),
                    },
                }
            );
        } catch (e) {
            throw (e as AxiosError).response;
        }

        return res.data;
    }

    static async updateUserOptin(userId: string, newOptin: boolean) {
        let res;
        try {
            res = await axios.patch(
                `${NoUserService.baseUrl}/users/${userId}/optIn`,
                { optIn: newOptin },
                {
                    headers: {
                        authorization: AdministratorService.token(),
                    },
                }
            );
        } catch (e) {
            throw (e as AxiosError).response;
        }

        return res.data;
    }

    static async updateUserLanguage(userId: string, newLanguage: string) {
        let res;
        try {
            res = await axios.patch(
                `${NoUserService.baseUrl}/users/${userId}/language`,
                { newLanguage },
                {
                    headers: {
                        authorization: AdministratorService.token(),
                    },
                }
            );
        } catch (e) {
            throw (e as AxiosError).response;
        }

        return res.data;
    }
}