import axios, { AxiosError } from 'axios';
import { HomepageEvent } from 'src/models/HomepageEvent';
import { HomepageGame } from 'src/models/HomepageGame';

export class HomepageService {
    private static baseUrl: string = String(process.env.REACT_APP_ADMINAPI_URL);
    static token = (): string | null => localStorage.getItem('accessToken');

    static async getHomepageEvents(limit: number = 20, page: number = 10) {
        let res;

        try {
            res = await axios.get(
                `${HomepageService.baseUrl}/homepage/events?limit=${limit}&page=${page}`, {
                    headers: {
                        'x-access-token': HomepageService.token(),
                    }
                }
            );
        } catch (e) {
            throw (e as AxiosError).response;
        }
        let hpEventList = [];
        if (!res.data && !res.data.docs) {
            throw Error;
        }
        hpEventList = res.data.docs.map((item: any) => {
            return new HomepageEvent(
                item._id,
                item.name,
                item.value,
                item.link,
                item.published,
                item.target,
                item.createdAt,
            );
        });
        return {
            docs: hpEventList,
            page: res.data.page,
            total: res.data.total,
        }
    }

    static async getHomepageGames(limit: number = 20, page: number = 10) {
        let res;

        try {
            res = await axios.get(
                `${HomepageService.baseUrl}/homepage/games?limit=${limit}&page=${page}`, {
                    headers: {
                        'x-access-token': HomepageService.token(),
                    }
                }
            );
        } catch (e) {
            throw (e as AxiosError).response;
        }
        let hpGameList = [];
        if (!res.data && !res.data.docs) {
            throw Error;
        }
        hpGameList = res.data.docs.map((item: any) => {
            return new HomepageGame(
                item._id,
                item.name,
                item.value,
                item.count,
                item.link,
                item.published,
                item.target,
                item.createdAt,
            );
        });
        return {
            docs: hpGameList,
            page: res.data.page,
            total: res.data.total,
        }
    }

    static async createHomepageEvent(homepage: HomepageEvent): Promise<HomepageEvent> {
        if (!homepage) {
            throw Error;
        }

        let res;
        delete homepage['id' as keyof HomepageEvent];

        try {
            res = (await axios.post(
                `${HomepageService.baseUrl}/homepage/event`,
                homepage, {
                    headers: {
                        'x-access-token': HomepageService.token(),
                    }
                }
            )).data;
        } catch (e) {
            throw (e as AxiosError).response;
        }

        if (!res) {
            throw Error;
        }

        return new HomepageEvent(
            res._id,
            res.name,
            res.value,
            res.link,
            res.published,
            res.target,
            res.createdAt,
        );
    }

    static async createHomepageGame(homepage: HomepageGame): Promise<HomepageGame> {
        if (!homepage) {
            throw Error;
        }

        let res;
        delete homepage['id' as keyof HomepageEvent];

        try {
            res = (await axios.post(
                `${HomepageService.baseUrl}/homepage/event`,
                homepage, {
                    headers: {
                        'x-access-token': HomepageService.token(),
                    }
                }
            )).data;
        } catch (e) {
            throw (e as AxiosError).response;
        }

        if (!res) {
            throw Error;
        }

        return new HomepageGame(
            res._id,
            res.name,
            res.value,
            res.count,
            res.link,
            res.published,
            res.target,
            res.createdAt,
        );
    }

    static async updateHomePageEvent(homepage: HomepageEvent): Promise<boolean> {
        if (!homepage) {
            return false;
        }

        try {
            await axios.put(
                `${HomepageService.baseUrl}/homepage/event/${homepage.id}`,
                homepage, {
                    headers: {
                        'x-access-token': HomepageService.token(),
                    }
                }
            );
        } catch {
            return false;
        }

        return true;
    }

    static async updateHomePageGame(homepage: HomepageGame): Promise<boolean> {
        if (!homepage) {
            return false;
        }

        try {
            await axios.put(
                `${HomepageService.baseUrl}/homepage/game/${homepage.id}`,
                homepage, {
                    headers: {
                        'x-access-token': HomepageService.token(),
                    }
                }
            );
        } catch {
            return false;
        }

        return true;
    }

    static async deleteHomePageEvent(_id: string): Promise<boolean> {
        if (!_id) {
            return false;
        }

        try {
            await axios.delete(
                `${HomepageService.baseUrl}/homepage/event/${_id}`, {
                    headers: {
                        'x-access-token': HomepageService.token(),
                    }
                }
            );
        } catch {
            return false
        }

        return true;
    }

    static async deleteHomePageGame(_id: string): Promise<boolean> {
        if (!_id) {
            return false;
        }

        try {
            await axios.delete(
                `${HomepageService.baseUrl}/homepage/game/${_id}`, {
                    headers: {
                        'x-access-token': HomepageService.token(),
                    }
                }
            );
        } catch {
            return false
        }

        return true;
    }
}