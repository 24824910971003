import axios from 'axios';
import { LocalStorageService } from './local-storage.service';

interface UploadExtraParam {
    path: string;
    lang: string;
    eventId: string;
}

const UPLOAD_ROUTES = {
    'challenge': (id: string, type: string) => `/challenge/${id}/medias/${type}/0`,
    'thematic': (id: string) => `/training/${id}/icon`,
    'training/program': (id: string) => `/training/program/${id}/medias/Cover`,
    'training': (id: string, type: string, params: UploadExtraParam ) => 
        `/training/${params.path}/${id}/medias/${type}`,
    'homepage': (_id: string, type: string, params: UploadExtraParam) => 
         `/homepage/event/${params.eventId}/medias/${type}/${params.lang}`,
    'news': (id: string, type: string) => `/homepage/news/${id}/medias/${type}`
}

export class S3LoaderService {
    private static baseUrl: string = String(process.env.REACT_APP_S3_LOADER_URL);

    private static async upload(api: string, file: File | string): Promise<number> {
        try {
            const res = await axios.post(
                `${S3LoaderService.baseUrl}${api}`,
                {
                    data: file,
                },
                {
                    headers: {
                        'x-access-token': LocalStorageService.getToken(),
                    },
                }
            );
            return res.status;
        } catch (e) {
            if (axios.isAxiosError(e)) {
                throw e.response;
            }
            throw e;
        }
    }

    static async getPrivateUserMedia(userId: string, type: string): Promise<Blob | null> {
        if (!userId && !type) {
            return null;
        }
        try {
            const res = await axios.get(
                `${S3LoaderService.baseUrl}/user/${userId}/documents/${type}`, 
                {
                    headers: {
                        'x-access-token': LocalStorageService.getToken(),
                    },
                    responseType: 'blob',
                }
            );
            return res.data;
        } catch (e) {
            return null;
        }
    }

    static async uploadMedia(
        routeKey: string, 
        id: string, 
        type: string, 
        image: File | string,
        params: UploadExtraParam
    ): Promise<null | string> {
        if (!id && !type && !image) {
            return "Missing request params";
        }
        const myRoute = UPLOAD_ROUTES[routeKey as keyof typeof UPLOAD_ROUTES];
        const api = myRoute?.(id, type, params) ?? `/${routeKey}/${id}/medias/${type}`;
        return S3LoaderService.upload(api, image)
            .then(() => null)
            .catch((e) => e.data.message);
    }

    static async uploadTeamMedia(id: string, type: string, image: File): Promise<boolean> {
        return id && type && image.size ? true : false;
    }
}