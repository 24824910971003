import React from 'react';
import {NCTitle, NCTitleType} from 'src/atoms/NCTitle/NCTitle';
import './Dashboard.scss';
import {NavLink} from 'react-router-dom';
import {IconButton} from '@material-ui/core';
import PremiumIcon from 'src/assets/system/premium-gradient.svg';
import TournamentIcon from 'src/assets/system/tournaments.svg';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faWallet} from '@fortawesome/free-solid-svg-icons';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import GamepadIcon from '@material-ui/icons/Gamepad';
import PublicIcon from '@material-ui/icons/Public';
import PageviewIcon from '@material-ui/icons/Pageview';
import AppsIcon from '@material-ui/icons/Apps';
import PagesIcon from '@material-ui/icons/Pages';
import WarningIcon from '@material-ui/icons/Warning';


export const Dashboard: React.FunctionComponent = () => {
    return (
        <div className="NC-container">
            <div className="d-flex flex-column">
                <NCTitle text={'Annoucement'} type={NCTitleType.ADMINISTRATOR}/>
                <ul className="mt-3 px-5 NC-card">
                    <li>If you encounter any issue or you want to make a suggestion, please contact us on our discord
                        server:
                        <a href="https://discord.gg/VHrKSeXN6R" rel="noopener noreferrer"
                           target="_blank" className="vivid-orange"> Wolfie Backroom</a>
                    </li>
                </ul>
            </div>
            <div className="d-flex flex-wrap">
                <div className="d-flex flex-column flex-fill overflow-auto">
                    <NCTitle text={'Platform Management'}/>
                    <div className="mt-3 d-flex w-100">
                        <NavLink to={'/countries'}
                                 className="NC-card d-flex flex-column flex-fill align-items-center justify-content-center my-1 mx-1">
                            <IconButton>
                                <PublicIcon/>
                            </IconButton>
                            <div>Countries</div>
                        </NavLink>
                        <NavLink to={'/currencies'}
                                 className="NC-card d-flex flex-column flex-fill align-items-center justify-content-center my-1 mx-1">
                            <IconButton>
                                <PagesIcon/>
                            </IconButton>
                            <div>Currencies</div>
                        </NavLink>
                        <NavLink to={'/games'}
                                 className="NC-card d-flex flex-column flex-fill align-items-center justify-content-center my-1 mx-1">
                            <IconButton>
                                <GamepadIcon/>
                            </IconButton>
                            <div>Games</div>
                        </NavLink>
                        <NavLink to={'/gamepages'}
                                 className="NC-card d-flex flex-column flex-fill align-items-center justify-content-center my-1 mx-1">
                            <IconButton>
                                <PagesIcon/>
                            </IconButton>
                            <div>Game Page</div>
                        </NavLink>
                        <NavLink to={'/organizations'}
                                 className="NC-card d-flex flex-column flex-fill align-items-center justify-content-center my-1 mx-1">
                            <IconButton>
                                <SupervisedUserCircleIcon style={{color: 'white'}}/>
                            </IconButton>
                            <div>Organizations</div>
                        </NavLink>
                        <NavLink to={'/platforms'}
                                 className="NC-card d-flex flex-column flex-fill align-items-center justify-content-center my-1 mx-1">
                            <IconButton>
                                <AppsIcon/>
                            </IconButton>
                            <div>Platforms</div>
                        </NavLink>
                        <NavLink to={'/premium'}
                                 className="NC-card d-flex flex-column flex-fill align-items-center justify-content-center my-1 mx-1">
                            <IconButton>
                                <img src={PremiumIcon} alt="-"/>
                            </IconButton>
                            <div>Premium</div>
                        </NavLink>
                        <NavLink to={'/seo'}
                                 className="NC-card d-flex flex-column flex-fill align-items-center justify-content-center my-1 mx-1">
                            <IconButton>
                                <PageviewIcon/>
                            </IconButton>
                            <div>Seo</div>
                        </NavLink>
                        <NavLink to={'/voucher'}
                                 className="NC-card d-flex flex-column flex-fill align-items-center justify-content-center my-1 mx-1">
                            <IconButton>
                                <img src={PremiumIcon} alt="-"/>
                            </IconButton>
                            <div>Voucher</div>
                        </NavLink>
                    </div>
                </div>
                <div className="d-flex flex-column flex-fill overflow-auto">
                    <NCTitle text={'Tournament'} type={NCTitleType.TOURNAMENT}/>
                    <div className="mt-3 d-flex w-100">
                        <NavLink to={'/tournaments/list'}
                                 className="NC-card d-flex flex-column flex-fill align-items-center justify-content-center my-1 mx-1">
                            <IconButton>
                                <img src={TournamentIcon} alt="-"/>
                            </IconButton>
                            <div>Tournaments</div>
                        </NavLink>
                        <NavLink to={'/tournaments/refereeing/matchs/list'}
                                 className="NC-card d-flex flex-column flex-fill align-items-center justify-content-center my-1 mx-1">
                            <IconButton>
                                <WarningIcon/>
                            </IconButton>
                            <div>Refereeing</div>
                        </NavLink>
                        <NavLink to={'/withdrawal'}
                                 className="NC-card d-flex flex-column flex-fill align-items-center justify-content-center my-1 mx-1">
                            <IconButton>
                                <FontAwesomeIcon icon={faWallet} color="white"
                                                 style={{fontSize: 18, marginLeft: 4}}/>
                            </IconButton>

                            <div>Withdrawal</div>
                        </NavLink>
                    </div>
                </div>
            </div>
        </div>
    );
};