import React, {useEffect, useState} from 'react';
import './TransactionDetail.scss';
import cc from 'classcat';
import {Button, CircularProgress, FormControl, IconButton, MenuItem, Select, TextField} from '@material-ui/core';
import RefreshIcon from '@material-ui/icons/Refresh';
import {NCTitle, NCTitleType} from 'src/atoms/NCTitle/NCTitle';
import {TransactionService} from 'src/services/transaction.service';
import {Transaction, TransactionStatus, TransactionStatusToString} from 'src/models/Transaction';
import {useLocation, useNavigate, useParams} from 'react-router';
import moment from 'moment';
import {useIntl} from 'react-intl';
import {UserMedia} from 'src/atoms/UserMedia/UserMedia';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faArrowLeft} from '@fortawesome/free-solid-svg-icons';
import getSymbolFromCurrency from 'currency-symbol-map';

export const TransactionDetail: React.FunctionComponent = () => {
    const intl = useIntl();
    const navigate = useNavigate();

    const { owner = '' } = useParams();
    const location = useLocation();
    const route = location.pathname.split(`/withdrawal/${owner}/`)[1];

    const [isLoading, setIsLoading] = useState(true);
    const [isRefreshing, setIsRefreshing] = useState(false);
    const [transaction, setTransaction] = useState<Transaction | null>(null);
    const [idCard, setIdCard] = useState(false);
    const [consent, setConsent] = useState(false);
    const [legalGuardianID, setLegalGuardianID] = useState(false);
    const [declineReason, setDeclineReason] = useState('');
    const [action, setAction] = useState<'validate' | 'accept' | 'decline'>('validate');

    const dob = moment(transaction?.user?.birthday);
    const age = moment().diff(dob, 'years', false);

    useEffect(() => {
        console.log("Route is", route)
        getTransaction(owner, route);
        // eslint-disable-next-line
    }, [isRefreshing]);

    const refresh = () => setIsRefreshing(true);

    const getTransaction = async (owner: string, route: string) => {
        let tr;
        try {
            tr = await TransactionService.getTransaction(owner, route);
        } catch (err) {
            console.log(err);
            return;
        } finally {
            setIsLoading(false);
            setIsRefreshing(false);
        }

        setTransaction(tr);
    };

    const validateDocuments = async () => {
        if (!transaction) {
            return;
        }

        await TransactionService.validateTransactionDocuments(transaction);

        refresh();
        setTimeout(() => refresh(), 2000);
    };

    const acceptTransaction = async () => {
        if (!transaction) {
            return;
        }

        await TransactionService.approuveTransaction(transaction);

        refresh();
        setTimeout(() => refresh(), 2000);
    };

    const declineTransaction = async () => {
        if (!transaction) {
            return;
        }

        await TransactionService.declineTransaction(transaction, declineReason);

        refresh();
        setTimeout(() => refresh(), 2000);
    };

    const back = () => {
        navigate(-1);
    };

    const executeAction = () => {
        switch (action) {
            case 'validate':
                return validateDocuments();
            case 'accept':
                return acceptTransaction();
            case 'decline':
                return declineTransaction();
            default:
                return;
        }
    };

    const getStatusClassName = (status: TransactionStatus) => {
        switch (status) {
            case TransactionStatus.Validating:
                return 'status-validating';
            case TransactionStatus.Transiting:
                return 'status-transiting';
            case TransactionStatus.Delivered:
                return 'status-delivered';
            case TransactionStatus.Cancelled:
                return 'status-cancelled';
            case TransactionStatus.Unprocessable:
                return 'status-unprocessable';
            default:
                return '';
        }
    };

    const getValidationClassName = (transaction: Transaction) => {
        return [TransactionStatus.Delivered, TransactionStatus.Cancelled].includes(transaction.status) || transaction.approuved ? 'validated' : 'pending';
    };

    const getValidationText = (transaction: Transaction) => {
        return [TransactionStatus.Delivered, TransactionStatus.Cancelled].includes(transaction.status) || transaction.approuved ? 'Validated' : 'Pending';
    };

    return (
        <div className="NC-container NC-card transaction-detail">
            <IconButton onClick={back}>
                <FontAwesomeIcon icon={faArrowLeft}/>
            </IconButton>
            <NCTitle
                text={`Transaction for ${!isLoading ? `${transaction?.user?.name}#${transaction?.user?.code}` : ''}`}
                type={NCTitleType.TOURNAMENT}/>
            <div className="d-flex flex-column">
                {isLoading && <CircularProgress className="mx-auto"/>}
                {
                    !isLoading && transaction &&
                    <>
                        <div className="d-flex flex-row align-items-center">
                            <span>Request ID: {transaction?.route}</span>
                            <Button
                                onClick={() => {
                                    navigate(`/users/${transaction.owner}?tab=6`);
                                }}
                                variant="outlined"
                                color="primary"
                                className="ml-2"
                            >
                                Go to user wallet
                            </Button>
                        </div>
                        <div className="w-100 d-flex flex-md-nowrap flex-wrap p-2 align-items-center">
                            <div
                                className="flex-fill NC-info d-flex flex-column justify-content-between align-items-center m-1">
                                <span className="text-uppercase mb-1 title">State</span>
                                <span
                                    className={cc(['text-uppercase', 'transition-status', getStatusClassName(transaction.status)])}>{TransactionStatusToString(transaction.status)}</span>
                            </div>
                            <div
                                className="flex-fill NC-info d-flex flex-column justify-content-between align-items-center m-1">
                                <span className="text-uppercase mb-1 title">Documents validation</span>
                                <span
                                    className={cc(['text-uppercase', 'validation-status', getValidationClassName(transaction)])}>{getValidationText(transaction)}</span>
                            </div>
                            <div
                                className="flex-fill NC-info d-flex flex-column justify-content-between align-items-center text-center m-1">
                                <span className="text-uppercase mb-1 title">Date</span>
                                <span>{moment(transaction.date).format('LLLL').toString()}</span>
                            </div>
                        </div>
                        <div className="w-100 d-flex flex-md-nowrap flex-wrap p-2 align-items-center">
                            <div
                                className="flex-fill NC-info d-flex flex-column justify-content-between align-items-center m-1">
                                <span className="text-uppercase mb-1 title">Source</span>
                                <span>{transaction.detail.title}</span>
                            </div>
                            <div
                                className="flex-fill NC-info d-flex flex-column justify-content-between align-items-center m-1">
                                <span className="text-uppercase mb-1 title">Kind</span>
                                <span>{intl.formatMessage({id: transaction.kind})}</span>
                            </div>
                            <div
                                className="flex-fill NC-info d-flex flex-column justify-content-between align-items-center m-1">
                                <span className="text-uppercase mb-1 title">Value</span>
                                <span>{transaction.detail.value}{(transaction.detail.cur && getSymbolFromCurrency(transaction.detail.cur)) || transaction.detail.cur}</span>
                            </div>
                        </div>
                        {
                            transaction.reason &&
                            <div className="w-100 d-flex flex-md-nowrap flex-wrap p-2 align-items-center">
                                <div
                                    className="flex-fill NC-info d-flex flex-column justify-content-between align-items-center m-1">
                                    <span className="text-uppercase mb-1 title">Rejection reason</span>
                                    <span>{transaction.reason}</span>
                                </div>
                            </div>
                        }
                        <div className="d-flex flex-row">
                            <div className="ml-auto">
                                {
                                    isRefreshing ?
                                        <CircularProgress/> :
                                        <IconButton onClick={refresh}>
                                            <RefreshIcon/>
                                        </IconButton>
                                }
                            </div>
                        </div>

                        <div className="d-flex flex-column flex-sm-row">
                            <form className="d-flex flex-column col-6 mb-3">
                                <h3>Individual</h3>
                                <div className="d-flex flex-row justify-content-between">
                                    <FormControl className="w-100">
                                        <span><b>First Name: </b>{transaction.user?.firstname}</span>
                                    </FormControl>
                                </div>
                                <div className="d-flex flex-row justify-content-between">
                                    <FormControl className="w-100">
                                        <span><b>Last Name: </b>{transaction.user?.lastname}</span>
                                    </FormControl>
                                </div>
                                <div className="d-flex flex-row justify-content-between">
                                    <FormControl className="w-100">
                                        <span><b>Email: </b>{transaction.user?.email}</span>
                                    </FormControl>
                                </div>
                                <div className="d-flex flex-row justify-content-between">
                                    <FormControl className="w-100">
                                        <span><b>Birthday: </b>{dob.format('LLLL')} - {age} years old</span>
                                    </FormControl>
                                </div>
                                <div className="d-flex flex-row justify-content-between">
                                    <FormControl className="w-100">
                                        <span><b>Premium: </b>{transaction.user?.status}</span>
                                    </FormControl>
                                </div>
                            </form>

                            {
                                transaction.paypalUser &&
                                <form className="d-flex flex-column col-6 mb-3">
                                    <h3>Paypal</h3>
                                    <div className="d-flex flex-row justify-content-between">
                                        <FormControl className="w-100">
                                            <span><b>Name: </b>{transaction.paypalUser.name}</span>
                                        </FormControl>
                                    </div>
                                    <div className="d-flex flex-row justify-content-between">
                                        <FormControl className="w-100">
                                            <span><b>Email: </b>{transaction.paypalUser.email}</span>
                                        </FormControl>
                                    </div>
                                    <div className="d-flex flex-row justify-content-between">
                                        <FormControl className="w-100">
                                            <span><b>Country: </b>{transaction.paypalUser.country}</span>
                                        </FormControl>
                                    </div>
                                </form>
                            }
                        </div>

                        <div className="d-flex flex-column col-12 mb-3">
                            <h3>Medias</h3>
                            <div
                                className="d-flex flex-column flex-sm-row justify-content-center justify-content-sm-start">
                                <FormControl className={cc(['w-100', 'media-miniature', {hidden: !idCard}])}>
                                    <UserMedia
                                        userId={transaction.owner}
                                        mediaPath="IdCard"
                                        onLoad={() => setIdCard(true)}
                                        isPrivate
                                    >
                                        <span className="font-weight-bold">Id Card</span>
                                    </UserMedia>
                                </FormControl>
                                <FormControl className={cc(['w-100', 'media-miniature', {hidden: !consent}])}>
                                    <UserMedia
                                        userId={transaction.owner}
                                        mediaPath="Consent"
                                        onLoad={() => setConsent(true)}
                                        isPrivate
                                    >
                                        <span className="font-weight-bold">User consent</span>
                                    </UserMedia>
                                </FormControl>
                                <FormControl className={cc(['w-100', 'media-miniature', {hidden: !legalGuardianID}])}>
                                    <UserMedia
                                        userId={transaction.owner}
                                        mediaPath="LegalGuardianID"
                                        onLoad={() => setLegalGuardianID(true)}
                                        isPrivate
                                    >
                                        <span className="font-weight-bold">Legal guardian ID and consent</span>
                                    </UserMedia>
                                </FormControl>
                            </div>
                        </div>

                        {
                            transaction.status === TransactionStatus.Validating &&
                            <div className="d-flex flex-column mx-auto action-section">
                                <Select value={action} onChange={v => setAction(v.target?.value as any || 'decline')}
                                        className="mb-3">
                                    {!transaction.approuved &&
                                    <MenuItem value={'validate'}>Validate Documents</MenuItem>}
                                    <MenuItem value={'accept'}>Approuve</MenuItem>
                                    <MenuItem value={'decline'}>Decline</MenuItem>
                                </Select>
                                {
                                    action === 'decline' && <TextField
                                        variant="outlined"
                                        multiline
                                        rows={2}
                                        value={declineReason}
                                        onChange={v => setDeclineReason(v.target?.value)}
                                        className="mb-3"
                                    />
                                }
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={executeAction}
                                    disabled={action === 'decline' && !declineReason}
                                >
                                    Apply
                                </Button>
                            </div>
                        }
                    </>
                }
            </div>
        </div>
    );
};
