import {CircularProgress} from '@material-ui/core';
import React, {useEffect, useState} from 'react';
import {toast} from 'react-toastify';
import {NCTitle, NCTitleType} from 'src/atoms/NCTitle/NCTitle';
import {ActionDialog} from 'src/components/General/ActionDialog/ActionDialog';
import {FullScreenDialog} from 'src/components/General/FullScreenDialog/FullScreenDialog';
import {SimpleList} from 'src/components/General/SimpleList/SimpleList';
import {SkillGameForm} from 'src/components/Training/SkillGame/SkillGameForm/SkillGameForm';
import {SkillGame} from 'src/models/SkillGame';
import {TrainingService} from 'src/services/training.service';

export const SkillGames: React.FunctionComponent = () => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [actualPage, setActualPage] = useState<number>(0);
    const [totalItem, setTotalItem] = useState<number>(0);
    const [skillGames, setSkillGames] = useState<Array<SkillGame>>([]);
    const [skillGame, setSkillGame] = useState<SkillGame>();
    const [actionDelete, setActionDelete] = useState<boolean>(false);
    const [actionEdit, setActionEdit] = useState<boolean>(false);
    const [actionCreate, setActionCreate] = useState<boolean>(false);

    useEffect(() => {
        TrainingService.getSkillGames().then(data => {
            setSkillGames(data.docs);
            setTotalItem(Math.ceil(data.total / 20));
            setIsLoading(false);
        });
    }, [totalItem, actualPage, isLoading]);

    const actionManager = (skillGame: SkillGame, kind: string) => {
        setSkillGame(skillGame);

        switch (kind) {
            case 'delete':
                setActionDelete(true);
                break;
            case 'edit':
                setActionEdit(true);
                break;
            case 'create': {
                setSkillGame(
                    new SkillGame(
                        '',
                        '',
                        {},
                        [],
                        false
                    )
                );
                setActionCreate(true);
                break;
            }
            default:
                return;
        }
    };

    const updateList = (result: boolean) => {
        setActionCreate(false);
        setActionDelete(false);
        setActionEdit(false);
        if (result) {
            setIsLoading(true);
        }

    };

    const deleteSkillGame = (choice: boolean) => {
        if (!choice) {
            return;
        }
        if (skillGame) {
            TrainingService.deleteSkillGame(skillGame._id).then(data => {
                if (!data) {
                    toast.error('Failed to delete skill game');
                }

                toast.success('Skill game deleted');
                updateList(true);
            });
        }
    };

    return (
        <div className="NC-container NC-card">
            <NCTitle
                text={'Skill games'}
                type={NCTitleType.TRAINING}
                link={{label: 'View blocks', url: '/training/skillgameblocks'}}
            />
            <div className="d-flex">
                {
                    isLoading && <CircularProgress className="mx-auto"/>
                }
                {
                    !isLoading &&
                    <SimpleList
                        rows={skillGames}
                        columns={['Name', 'Value', 'Levels', 'Published']}
                        keepRows={['name', 'value', 'levels', 'published']}
                        actionsButtons={{delete: true, edit: true, create: true}}
                        handleClickOpen={actionManager}
                        pagination={{actual: actualPage, total: totalItem, setActual: setActualPage}}
                    />
                }
            </div>
            <ActionDialog
                open={actionDelete}
                title={`Are you sure to delete this game : ${skillGame?.value}`}
                openHook={setActionDelete}
                actionHook={deleteSkillGame}
            />
            {
                skillGame &&
                <FullScreenDialog
                    open={actionEdit}
                    openHook={setActionEdit}
                    actionHook={updateList}
                    componentToDisplay={
                        <SkillGameForm
                            skillGame={skillGame}
                            actionHook={updateList}
                        />
                    }
                />
            }
            {
                skillGame &&
                <FullScreenDialog
                    open={actionCreate}
                    openHook={setActionCreate}
                    actionHook={updateList}
                    componentToDisplay={
                        <SkillGameForm
                            skillGame={skillGame}
                            actionHook={updateList}
                            creationMode={true}
                        />
                    }
                />
            }
        </div>
    );
};