import './Module.scss';

import { BlockType, TrainingBrick, PredicateEditionAugmented } from 'src/models/TrainingBrick';
import { Button, CircularProgress } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { BrickInformation } from 'src/components/Training/Modules/BrickInformation';
import { Company } from 'src/models/Company';
import { CompanyService } from 'src/services/company.service';
import { GamePartial } from 'src/models/GamePartial';
import { GameService } from 'src/services/game.service';
import { LangPicker } from 'src/components/General/LangPicker/LangPicker';
import { ModuleInformation } from 'src/components/Training/Modules/ModuleInformation';
import { NCTitle } from 'src/atoms/NCTitle/NCTitle';
import { RenderTree } from 'src/components/Training/Modules/RenderTree';
import { TrainingModule } from 'src/models/TrainingModule';
import { TrainingService } from 'src/services/training.service';
import { TrainingThematic } from 'src/models/TrainingThematic';
import { useStoreState } from 'src/store';
import { ActionDialog } from 'src/components/General/ActionDialog/ActionDialog';

export const TrainingModules: React.FunctionComponent = () => {
    const navigate = useNavigate();
    const emptyModule: TrainingModule = new TrainingModule({}, {}, '', '');
    const { moduleId }: any = useParams();
    const [ isLoading, setIsLoading ] = useState<boolean>(true);
    const [ module, setModule ] = useState<any>();
    const [ moduleInfo, setModuleInfo ] = useState<TrainingModule>(emptyModule);
    const [ primaryBlock, setPrimaryBlock ] = useState<any>();
    const [ lang, setLang ] = useState<string>(useStoreState(state => state.contentLang));
    const [ thematicList, setThematicList ] = useState<Array<TrainingThematic>>([]);
    const [ selectedBrick, setSelectedBrick ] = useState<TrainingBrick>();
    const [ selectedPath, setSelectedPath ] = useState<string>();
    const [ games, setGames ] = useState<Array<GamePartial>>([]);
    const [ selectedGame, setSelectedGame ] = useState<GamePartial | string>();
    const [ isCreate, setIsCreate ] = useState<boolean>(false);
    const [ isMenuBrick, setIsMenuBrick ] = useState<boolean>(false);
    const [ refreshBrick, setRefreshBrick ] = useState<boolean>(false);
    const [ blockId, setBlockId ] = useState<string>();
    const [ companies, setCompanies ] = useState<Array<Company>>([]);
    const [ selectedPrerequisite, setSelectedPrerequisite] = useState<PredicateEditionAugmented | undefined>();
    const [ isPublished, setIsPublished] = useState<boolean>(false);
    const [ publishDialog, setPublishDialog] = useState<boolean>(false);
    const [ resetTrigger, setResetTrigger ] = useState<boolean>(false);

    useEffect(() => {
        setIsLoading(true);
        TrainingService.getThematics(50, 0).then((data: any) => {
            setThematicList(data.docs);
        });
        GameService.get360Games().then((data) => {
            setGames(data);
            setSelectedGame(data[0]);
        });
        CompanyService.getCompanies().then((data) => {
            setCompanies(data.docs);
        }).catch(error => console.log(error));
        if (moduleId) {
            TrainingService.getModuleById(moduleId).then((data) => {
                setModule(data);
                setModuleInfo(data['module']);
                setPrimaryBlock(data['primaryBlocks']);
                setLang(lang);
                setIsPublished(!data.module.comingSoon);
            });
        } else {
            const newModule: TrainingModule = new TrainingModule(
                { en: '', fr: '' },
                { en: '', fr: '' },
                '',
                '',
            );
            setModule(newModule);
        }
        setIsLoading(false);
    }, [ moduleId, lang ]);

    useEffect(() => {
        if (primaryBlock) {
            setPrimaryBlock(primaryBlock);
            setSelectedGame(games.find(game => game._id === moduleInfo.game));
            if (primaryBlock[0] && primaryBlock[0]._id) {
                setBlockId(primaryBlock[0]._id);
            }
        }
    }, [ refreshBrick, primaryBlock, moduleInfo.game, games ]);

    const onChangeBrick = (brick: TrainingBrick, row: number, column: number, create: boolean) => {
        setIsMenuBrick(true);
        setSelectedBrick(brick);
        setSelectedPath(`0/${row}/${column}`);
        setIsCreate(create);
    };

    const onGameChange = (game: GamePartial | string) => {
        setSelectedGame(game);
    };

    const onPrerequisiteChange = (prerequisite: PredicateEditionAugmented | undefined) => {
        setSelectedPrerequisite(prerequisite);
    }

    const save = () => {
        if (moduleInfo._id) {
            TrainingService.updateModule(moduleInfo._id, moduleInfo);
            navigate('/training/dashboard');
        } else {
            TrainingService.createModule(moduleInfo).then(data => {
                navigate(`/training/module/${data._id}`);
            });
        }
    };

    const checkSave = () => {
        if (!isPublished && !moduleInfo.comingSoon && (process.env.NODE_ENV === 'production')) {
            setPublishDialog(true);
        } else {
            save();
        }
    }

    const publishConfirmation = (choice: boolean) => {
        if (choice) {
            save();
        } else {
            setPublishDialog(false);
        }
    }

    const onRefreshBrick = () => {
        setRefreshBrick(!refreshBrick);
    };

    const onMenuChange = () => {
        setIsMenuBrick(!isMenuBrick);
    }

    const createBlock = () => {
        TrainingService.createPrimaryBlock(moduleInfo._id, { name: {}, type: BlockType.PRIMARY }).then(data => {
            setPrimaryBlock([ data ]);
            setBlockId(data._id);
            data.children.push({ children: [], name: { en: toCapitalizeCase(BlockType.SEQUENTIAL.toLowerCase())}, type: BlockType.SEQUENTIAL });
            TrainingService.updateBlock(data._id, data);
            onRefreshBrick();
        });
    };

    const resetAdd = () => {
        setResetTrigger(!resetTrigger);
    }

    const toCapitalizeCase = (text: string) => {
        return text.charAt(0).toUpperCase() + text.slice(1);
    };

    return (
        <div className="NC-container NC-card background">
            <div className="d-flex flex-row justify-content-between">
                {
                    moduleInfo && moduleInfo._id
                    ? <NCTitle text={moduleInfo.name[lang]} color='#b2f617'/>
                    : <NCTitle text='Create new module' color='#b2f617'/>
                }
                <div className="d-flex justify-content-center align-content-center">
                    <LangPicker setLang={ setLang } />
                    <Button className="m-2" variant="contained" color="primary" onClick={() => { checkSave(); }}>Save</Button>
                </div>
            </div>
            {
                isLoading && <CircularProgress className="mx-auto"/>
            }
            {
                !isLoading &&
                <div className="d-flex flex-row px-4">
                    <div className={`NC-card ${primaryBlock ? 'col-5' : 'col-12'} mr-2`}>
                    {
                        <ModuleInformation
                            module={moduleInfo}
                            primaryBlock={primaryBlock}
                            lang={lang}
                            games={games}
                            isMenuBrick={isMenuBrick}
                            companies={companies}
                            isPublished={isPublished}
                            onGameChange={onGameChange}
                            setUpdateModule={save}
                            onMenuChange={onMenuChange}
                            toCapitalizeCase={toCapitalizeCase}
                            onPrerequisiteChange={onPrerequisiteChange}
                        />
                    }
                    {
                        module && primaryBlock && selectedBrick && selectedPath ?
                            <BrickInformation
                                primaryBlock={primaryBlock}
                                lang={lang}
                                thematics={thematicList}
                                selectedBrick={selectedBrick}
                                selectedPath={selectedPath}
                                selectedGame={selectedGame as GamePartial}
                                isCreate={isCreate}
                                blockId={blockId}
                                isMenuBrick={isMenuBrick}
                                isPublished={isPublished}
                                resetAdd={resetAdd}
                                setUpdateModule={save}
                                onRefreshBrick={onRefreshBrick}
                                onMenuChange={onMenuChange}
                                toCapitalizeCase={toCapitalizeCase}
                            />
                        : null
                    }
                    </div>
                    {
                        primaryBlock ?
                            <div className="tree NC-card w-100 col-7 ml-2">
                                <div className="title">Module structure</div>
                                <div>
                                    Each training module is composed of blocks. Each block can be an assignment or a video.
                                    Combined together, these bricks present objectives in training tree.
                                    Players need to complete objectives in order to finish training tree (module).
                                    First you need to add a row, then the brick (+ in white = add brick) / (+ in green = add row)
                                </div>
                                {/* {renderCreateBlockButton()} */}
                                {
                                    (!primaryBlock || (primaryBlock && !primaryBlock[0]) || (primaryBlock && primaryBlock[0] && primaryBlock[0].type !== BlockType.PRIMARY)) ?
                                        <div className="d-flex justify-content-center">
                                            <Button className="m-2" variant="contained" color="primary" onClick={() => { createBlock() }}>
                                                Create block
                                            </Button>
                                        </div>
                                    : null
                                }
                                {
                                    module && moduleInfo && selectedGame && primaryBlock && primaryBlock.map((primaryBlock: any, index: number) => {
                                        return (
                                            <div key={index}>
                                                <RenderTree
                                                    module={module}
                                                    primaryBlock={primaryBlock}
                                                    gameId={moduleInfo.game}
                                                    modulePath={index.toString()}
                                                    lang={lang}
                                                    thematics={thematicList}
                                                    selectedGame={selectedGame as GamePartial}
                                                    isPublished={isPublished}
                                                    resetTrigger={resetTrigger}
                                                    blockId={blockId}
                                                    onChangeBrick={onChangeBrick}
                                                    toCapitalizeCase={toCapitalizeCase}
                                                    onRefreshBrick={onRefreshBrick}
                                                    selectedPrerequisite={selectedPrerequisite}
                                                />
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        : null
                    }
                </div>
            }
            <ActionDialog
                open={publishDialog}
                title={`Are you sure you want to publish this module? This change is not reversible on PROD.`}
                openHook={setPublishDialog}
                actionHook={publishConfirmation}
            />
        </div>
    );
}
