import {createStyles, makeStyles} from '@material-ui/core/styles';
import React, {useState} from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';
import {NavLink} from 'react-router-dom';
import './Navbar.scss';
import {useStoreState} from "../../../store";
import { AdministratorsRole } from 'src/models/Administrator';

const useStyles = makeStyles(() =>
    createStyles({
        nested: {
            backgroundColor: '#0c0c0c'
        },
    }),
);

interface RenderCollapsedLinkProps {
    item: any;
    index: number;
    adminRole?: Array<AdministratorsRole>,
}

export const RenderCollapsedLink: React.FunctionComponent<RenderCollapsedLinkProps> = ({item, index, adminRole}) => {
    const classes = useStyles();
    const [openCollapse, setOpenCollapse] = useState(useStoreState(state => state.menuItemCollapse) || false);
    const [hasChanged, setHasChanged] = useState<boolean>(false);

    const handleClick = () => {
        setHasChanged(!hasChanged)
        if (openCollapse[index]) {
            openCollapse[index] = !openCollapse[index]
        } else {
            openCollapse[index] = true;
        }
        setOpenCollapse(openCollapse);
    };

    return (
        <>
            <ListItem button key={item?.name + index + Math.random()} onClick={handleClick}>
                <ListItemIcon className="item-icon">{item?.icon}</ListItemIcon>
                <ListItemText primary={item?.name}/>
                {openCollapse[index] ? <ExpandLess/> : <ExpandMore/>}
            </ListItem>
            <Collapse in={openCollapse[index]}  timeout="auto">
                {item?.subMenu.map((item: any, index: number) => {
                    if (adminRole && !adminRole.some(r => item.role.includes(r))) {
                        return null;
                    } else {
                        return (
                            <NavLink 
                                to={item.link} 
                                key={item.name + index + Math.random()}
                                className={(props) => {
                                    return `${props.isActive ? 'active ' : ''}${item.color ? item.color : ''}`
                                }} 
                                end
                            >
                                <ListItem button className={classes.nested}>
                                    <ListItemIcon>
                                        {item.icon}
                                    </ListItemIcon>
                                    <ListItemText primary={item.name}/>
                                </ListItem>
                            </NavLink>
                        );
                    }
                })}
            </Collapse>
        </>
    );
};