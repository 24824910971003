import React from 'react';
import './ScrollTopButton.scss';

interface ScrollTopButtonProps {
    size: number;
    actionHook: (clicked: boolean) => void;
}

export const ScrollTopButton: React.FunctionComponent<ScrollTopButtonProps> = (props: ScrollTopButtonProps) => {
    const arrowIconPath: string = `url(${process.env.REACT_APP_S3_URL}/media/icons/goToPreviousPage.svg)`;

    return (
        <>
            <div
                className="button-container position-relative rounded-circle"
                style={{ width: `${props.size}px`, height: `${props.size}px` }}
                onClick={() => { props.actionHook(true) }}
            >
                <div className="circle high"></div>
                <div
                    className="arrow sliding"
                    style={{
                        maskImage: arrowIconPath,
                        WebkitMaskImage: arrowIconPath,
                    }}
                ></div>

                <div
                    className="arrow destination"
                    style={{
                        maskImage: arrowIconPath,
                        WebkitMaskImage: arrowIconPath,
                    }}
                ></div>
            </div>
        </>
    );
}