import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import React, { useState } from 'react';
import PanoramaFishEyeIcon from '@material-ui/icons/PanoramaFishEye';
import Link from '@material-ui/core/Link';
import { store } from '../../../store';
import { AdministratorsRole } from '../../../models/Administrator';
import { User } from '@wolfiesports/srm-component';

interface UserInfosProps {
    user: User;
    userChange: any;
}

export const UserInfos: React.FunctionComponent<UserInfosProps> = (props: UserInfosProps) => {
    const [editedUser, setEditedUser] = useState<User | null>(props.user);

    const saveUserInfos = () => {
        props.userChange(editedUser);
    };

    const onUserInfoChange = (event: any) => {
        const { name, value } = event.currentTarget;
        setEditedUser(Object.assign({}, editedUser, {
            [name]: value,
        }));
    };

    const renderSaveButton = () => {

        return (
            <React.Fragment>
                <Button
                    className="w-25"
                    variant="contained"
                    color="primary"
                    onClick={saveUserInfos}
                >
                    Save
                </Button>
            </React.Fragment>
        );
    };

    const renderUserInfoForm = () => {
        if (!editedUser) {
            return;
        }
        return (
            <React.Fragment>
                <div className="p-3 NC-card">
                    <div className="d-flex w-100">
                        {
                            store.getState().currentAdministrator?.role.includes(AdministratorsRole.NCADMIN) &&
                            <div className="d-flex justify-content-end w-100">
                                <Link
                                    href={`${String(process.env.REACT_APP_PLATFORM_URL)}/auth/impersonate?id=${store.getState().currentAdministrator?._id}&refresh=${store.getState().refreshToken}&userId=${props.user.userId}`}
                                    target="_blank"
                                    rel="noopener"
                                >
                                    <Button
                                        variant="text"
                                        color="primary"
                                        //placeholder="Connect as"
                                    >
                                        <PanoramaFishEyeIcon />
                                    </Button>
                                </Link>
                            </div>
                        }

                    </div>
                    <div className="row p-3">
                        <TextField
                            disabled
                            name="id"
                            value={editedUser.userId}
                            label="ID"
                            className="col-md-6 col-12 my-2 px-1"
                        />
                        <TextField
                            disabled
                            name="email"
                            value={editedUser.email}
                            label="Email"
                            className="col-md-6 col-12 my-2 px-1"
                            onChange={(event) => {
                                onUserInfoChange(event);
                            }}
                        />
                        <TextField
                            disabled
                            name="nickname"
                            value={editedUser.name}
                            label="Nickname"
                            className="col-md-6 col-12 my-2 px-1"
                            onChange={(event) => {
                                onUserInfoChange(event);
                            }}
                        />
                        <TextField
                            disabled
                            name="code"
                            value={editedUser.code}
                            label="Code"
                            className="col-md-6 col-12 my-2 px-2"
                        />
                        <TextField
                            name="firstName"
                            defaultValue={editedUser.firstName}
                            label="Firstname"
                            className="col-md-6 col-12 my-2 px-1"
                            onChange={(event) => {
                                onUserInfoChange(event);
                            }}
                        />
                        <TextField
                            name="lastName"
                            defaultValue={editedUser.lastName}
                            label="Lastname"
                            className="col-md-6 col-12 my-2 px-1"
                            onChange={(event) => {
                                onUserInfoChange(event);
                            }}
                        />
                        <TextField
                            id="date"
                            label="Birthday"
                            type="date"
                            disabled={true}
                            value={editedUser.birthday || '2020-01-01'}
                            className="col-md-6 col-12 my-2 px-1"
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                        <div className="col-12 mt-4 d-flex justify-content-center">
                            { renderSaveButton() }
                        </div>
                    </div>

                </div>
            </React.Fragment>
        );
    };

    return (
        <React.Fragment>
            {
                renderUserInfoForm()
            }
        </React.Fragment>
    );
};
