export enum SenderType {
    User = 0,
    Admin = 1,
}

export type MessageType = 'chat' | 'tournament' | 'matchUpdated' | 'proofAdded' | 'srm';

export interface ChatMessage {
    message: string,
    id: string
}

export interface EventMessage {
    room: string,
    target: string,
    type: MessageType,
    content: any,
    sender: string,
    senderType: SenderType,
    senderName: string,
    date: string,
}
