import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import React, {ChangeEvent, useState} from 'react';
import {toast} from 'react-toastify';
import {TrainingGuide} from 'src/models/TrainingGuide';
import {TrainingService} from 'src/services/training.service';
import {Editor} from '@tinymce/tinymce-react';
import {useStoreState} from 'src/store';
import TabContext from '@material-ui/lab/TabContext';
import AppBar from '@material-ui/core/AppBar';
import TabList from '@material-ui/lab/TabList';
import {Languages} from 'src/models/Languages';
import Tab from '@material-ui/core/Tab';
import TabPanel from '@material-ui/lab/TabPanel';
import {Game} from 'src/models/Game';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { TranslatedString } from 'src/models/TranslatedString';

interface GuideFormProps {
    guideProps: TrainingGuide;
    actionHook: any;
    creationMode?: boolean;
    gameList: Array<Game>;
}

export const GuideForm: React.FunctionComponent<GuideFormProps> = ({
                                                                       guideProps,
                                                                       actionHook,
                                                                       creationMode,
                                                                       gameList
                                                                   }) => {
    const [guide, setGuide] = useState<TrainingGuide>(guideProps);
    const [languages] = useState(useStoreState(state => state.languages));
    const [edit, setEdit] = useState<boolean>(false);
    const [langValue, setLangValue] = useState(useStoreState(state => state.contentLang));


    const saveGuide = () => {
        if (guide) {
            if (edit) {
                TrainingService.updateTrainingGuide(guide._id, guide).then(data => {
                    if (!data) {
                        toast.error('Failed to update guide');
                        actionHook(false);
                        return;
                    }
                    toast.success('Guide updated');
                    actionHook(true);
                });
            } else {
                TrainingService.createTrainingGuide(guide).then(data => {
                    if (!data) {
                        toast.error('Failed to create guide');
                        actionHook(false);
                        return;
                    }
                    toast.success('Guide created');
                    actionHook(true);
                });
            }
        }
    };

    const handleTabChange = (event: React.ChangeEvent<HTMLInputElement>, newValue: string) => {
        setLangValue(newValue);
    };

    const onGuideChange = (event: any, isTranslatedString = false, lang = '') => {
        const {name, value} = event.currentTarget;
        if (isTranslatedString) {
            const myGuideAttr = guide[name as keyof TrainingGuide] as TranslatedString; 
            myGuideAttr[lang] = value;
            setGuide(guide);
        } else {
            setGuide(Object.assign({}, guide, {
                [name]: value,
            }));
        }
    };

    const onDescriptionChange = (content: any, lang: string) => {
        guide.description[lang] = content;
        setGuide(guide);
    };

    const onContentChange = (content: any, lang: string) => {
        guide.content[lang] = content;
        setGuide(guide);
    };

    const onGameChange = (event: any) => {
        guide.game = event.target.value;
        setGuide(guide);
    };

    return (
        <div className="w-100">
            <div className="w-100 d-flex">
                <div className="w-50">
                    {
                        !creationMode &&
                        <FormControlLabel
                            control={<Switch color="primary" checked={edit} onChange={() => setEdit(!edit)}/>}
                            label="Edit"
                            labelPlacement="start"
                        />
                    }

                </div>
                <div className="w-50">
                    {
                        (edit || creationMode) &&
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                saveGuide();
                            }}
                        >
                            Save
                        </Button>
                    }
                </div>
            </div>
            <form className="d-flex flex-column NC-tab">
                <TabContext value={langValue}>
                    <AppBar position="static">
                        <TabList onChange={(event: React.ChangeEvent<any>, langValue: any) => handleTabChange(event, langValue)} aria-label="simple tabs example">
                            {

                                languages.map((lang: Languages) => (
                                    <Tab key={lang._id} label={lang.name} value={lang.code}/>
                                ))
                            }
                        </TabList>
                    </AppBar>
                    {
                        languages.map((lang: Languages) => (
                            <TabPanel className="w-100" key={lang._id} value={lang.code}>
                                <div className="d-flex flex-row row my-2">
                                    <TextField
                                        disabled={!edit && !creationMode}
                                        className="col-12  col-md-4 px-3"
                                        name="name"
                                        label="Name"
                                        defaultValue={guide.name[lang.code]}
                                        onChange={(event: ChangeEvent<HTMLInputElement>) => (onGuideChange(event, true, lang.code))}
                                    />
                                    <TextField
                                        disabled={!edit && !creationMode}
                                        className="col-12 col-md-4 px-3"
                                        name="slug"
                                        label="Slug"
                                        defaultValue={guide.slug[lang.code]}
                                        onChange={(event: ChangeEvent<HTMLInputElement>) => (onGuideChange(event, true, lang.code))}
                                    />
                                    <FormControl className="col-12  col-md-4 px-3">
                                        <InputLabel>Game</InputLabel>
                                        <Select
                                            labelId="Game"
                                            defaultValue={guide.game ? guide.game : ''}
                                            onChange={(event: any) => {
                                                onGameChange(event);
                                            }}
                                            name="game"
                                        >
                                            <MenuItem value=''>Select a game</MenuItem>
                                            {
                                                gameList.map((game) => {
                                                    return (
                                                        <MenuItem value={game._id} key={game._id}>
                                                            {game.name}
                                                        </MenuItem>
                                                    );
                                                })
                                            }
                                        </Select>
                                    </FormControl>

                                </div>
                                <div className="mt-2">
                                    Description
                                    <Editor apiKey={process.env.REACT_APP_TINYMCE_KEY}
                                            init={{
                                                height: 400,
                                                a11y_advanced_options: true,
                                                image_title: true,
                                                automatic_uploads: true,
                                                file_picker_types: 'image',
                                                plugins: 'link lists media table image imagetools media',
                                                toolbar: 'undo redo | styleselect fontselect fontsizeselect removeformat | bold italic | alignleft aligncenter alignright alignjustify | ' +
                                                    'bullist numlist outdent indent | image media link | spellchecker | forecolor backcolor',
                                            }}
                                            value={guide.description[lang.code]}
                                            onEditorChange={(event: any) => (onDescriptionChange(event, lang.code))}
                                    />
                                </div>
                                <div className="mt-2">
                                    Content
                                    <Editor apiKey={process.env.REACT_APP_TINYMCE_KEY}
                                            init={{
                                                height: 400,
                                                a11y_advanced_options: true,
                                                image_title: true,
                                                automatic_uploads: true,
                                                file_picker_types: 'image',
                                                plugins: 'link lists media table image imagetools media',
                                                toolbar: 'undo redo | styleselect fontselect fontsizeselect removeformat | bold italic | alignleft aligncenter alignright alignjustify | ' +
                                                    'bullist numlist outdent indent | image media link | spellchecker | forecolor backcolor',
                                            }}
                                            value={guide.content[lang.code]}
                                            onEditorChange={(event: any) => (onContentChange(event, lang.code))}
                                    />
                                </div>
                            </TabPanel>
                        ))
                    }
                </TabContext>
            </form>
        </div>
    );
};