export class Company {
    constructor(
        public _id: string,
        public name: string,
        public address: string,
        public country: string,
        public contact_email: string,
        public slug: string,
    ) {

    }
}

export enum ECompanyImage {
    Icon = 'Icon',
}