/* eslint-disable */
import { User } from '@wolfiesports/srm-component';
import React, { useEffect, useState } from 'react';
import { SimpleList } from 'src/components/General/SimpleList/SimpleList';
import { NoUser } from 'src/models/NoUser';
import { UserService } from 'src/services/user.service';


interface UserActivityProps {
    user: User | null;
}

export const UserActivity: React.FunctionComponent<UserActivityProps> = (props: UserActivityProps) => {
    const columns = ['Category', 'Date', 'Game', 'URL', 'Label', 'Version'];
    const keepRows = ['category', 'date', 'game', 'url','label', 'metadata.version'];
    const [ activityList, setActivityList ] = useState<any>([]);
    const [actualPage, setActualPage] = useState<number>(0);
    const [totalItem, setTotalItem] = useState<number>(0);



    useEffect( () => {
        /*if (props.user) {
            UserService.getUserActivity(props.user.userId, 20, actualPage).then((data) => {
                setTotalItem(Math.ceil(data.total / 20));
                setActivityList(data.docs);
            })
        }*/
    }, [props.user, actualPage])

    return (
        <>  
            {
                activityList && activityList.length > 0 &&
                    <div className="NC-card">
                        <SimpleList
                            rows={activityList}
                            columns={columns}
                            keepRows={keepRows}
                            pagination={{ actual: actualPage, total: totalItem, setActual: setActualPage }}
                        />
                    </div>
            }
            {
                activityList && activityList.length === 0 &&
                <div className="p-5 NC-card text-center w-100">No user acitivity recorded</div>
            }
            
        </>
    );
}