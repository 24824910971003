import axios from "axios";
import { Organization } from "src/models/Organization";
import { LocalStorageService } from "./local-storage.service";

export class NCCommonService {
    private static baseUrl: string = String(process.env.REACT_APP_COMMON_URL);
    
    public static getAllOrganisation = async (): Promise<
    Array<Organization>
    > => {
    let res;

    try {
        res = (
            await axios.get(`${NCCommonService.baseUrl}/organisation/all`, {
                headers: {
                    'x-access-token': LocalStorageService.getToken(),
                },
            })
        ).data;
    } catch (e: any) {
        throw e.response;
    }

    return res;
    };
}