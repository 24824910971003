import {
    Button,
    CircularProgress,
    Link,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import {
    MatchState,
    TournamentMatch,
    TournamentMatchConflited,
    TournamentTeam,
    TournamentPlayer
} from 'src/models/TournamentMatch';
import { NtService } from 'src/services/nt.service';
import VisibilityIcon from '@material-ui/icons/Visibility';
import './MatchList.scss';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import momentDurationFormatSetup from 'moment-duration-format';

momentDurationFormatSetup(moment as any);

interface MatchListProps {
    tournamentId: string;
    statesFilter?: Array<number>;
    searchFilter?: string;
}

export const MatchList: React.FunctionComponent<MatchListProps> = (props: MatchListProps) => {
    const [ isLoading, setIsLoading ] = useState(true);
    const [ matchList, setMatchList ] = useState<Array<TournamentMatch | TournamentMatchConflited>>([]);
    const [ refresh, setRefresh ] = useState<number>(0);

    const getStateOrder = (state: MatchState):number => {
        switch(state) {
            case MatchState.CheckIn :
                return 1;
            case MatchState.Confirmation :
                return 2;
            case MatchState.Playing :
                return 3;
            case MatchState.Waiting :
            case MatchState.Paused :
                return 4;
            case MatchState.Ended :
                return 5;
            case MatchState.Conflict :
            default:
                return 0;
        }
    }

    useEffect(() => {
        (async () => {
            let data = await NtService.getMatchesFromTournament(props.tournamentId, props.statesFilter);

            data = data.filter((item: TournamentMatch) => {
                if(item.teams.some((t) => t)) {
                    if (props.searchFilter) {
                        return item.route === props.searchFilter || item.teams.some((t: TournamentTeam | null | undefined) => {
                            return t && (t.name === props.searchFilter || t.tag === props.searchFilter || Object.values(t.players).some((p: TournamentPlayer) => {
                                return p.userId === props.searchFilter || p.name === props.searchFilter || p.account === props.searchFilter
                            }))
                        })
                    }

                    return true;
                }
                return false
            });
            data.sort((itemA, itemB) => {
                const orderA = getStateOrder(itemA.state);
                const orderB = getStateOrder(itemB.state);
                if(orderA === orderB) {
                    return itemA.clusterIndex - itemB.clusterIndex;
                }

                return orderA - orderB;
            })
            setMatchList(data);
            setIsLoading(false);
        })()
        const interval = setInterval(() => {
            setRefresh(refresh + 1);
        }, 30000);
        return () => clearInterval(interval);
    }, [ props.tournamentId, props.statesFilter, props.searchFilter, refresh ]);

    // const sortMatches = (matches: Array<TournamentMatch>) => {
    //     return matches.sort((a,b) => {
    //         return new Date(b.date).getTime() -
    //         new Date(a.date).getTime()
    //     });
    // }

    const RenderMatchDuration = ({ match }: { match: TournamentMatch }) => {
        const [ u, setU ] = useState(false);

        useEffect(() => {
            const t = setTimeout(() => setU(!u), 1000);
            return () => clearTimeout(t);
        }, [ u ]);

        if (match.state === MatchState.Ended) {
            return <>-</>;
        }
        const startDate = match.fp ? match.fp : match.date;
        const duration = moment.duration(Date.now() - new Date(startDate).getTime() * 1000);
        return <>{moment.duration(duration, 'second').format('hh:mm:ss')}</>;
    }

    const renderMatchState = (state: MatchState) => {
        switch (state) {
            case MatchState.Waiting:
            case MatchState.Paused:
                return <span className="yellow-color font-weight-bold text-uppercase">{MatchState[state]}</span>;
            case MatchState.CheckIn:
                return <span className="vivid-orange font-weight-bold text-uppercase">{MatchState[state]}</span>;
            case MatchState.Playing:
                return <span className="primary-color font-weight-bold text-uppercase">{MatchState[state]}</span>;
            case MatchState.Confirmation:
                return <span className="vivid-orange font-weight-bold text-uppercase">{MatchState[state]}</span>;
            case MatchState.Conflict:
                return <span className="red-color font-weight-bold text-uppercase blink">{MatchState[state]}</span>;
            case MatchState.Ended:
                return <span className="primary-color font-weight-bold text-uppercase">{MatchState[state]}</span>;
            case MatchState.Cancelled:
                return <span className="red-color font-weight-bold text-uppercase">{MatchState[state]}</span>;
            default:
                return <span className="">{MatchState[state]}</span>;
        }
    }

    const renderUrgencyStatus = (match: TournamentMatch) => {
        let className = '';
        const duration = match.setting && match.setting.delayTimer > 0 ? match.setting.delayTimer : 300;
        const startDate = match.fp ? match.fp : match.date;
        const formula = Math.floor(((Date.now().valueOf() / 1000) - startDate.valueOf()) / duration);
        switch (formula) {
            case 0:
                className = 'font-weight-bold text-uppercase';
                break;
            case 1:
                className = 'yellow-color font-weight-bold text-uppercase';
                break;
            default:
                className = 'red-color font-weight-bold text-uppercase';
                break;
        }
        return <span className={className}><RenderMatchDuration match={match}/></span>;
    }

    return (
        <>
            {
                isLoading && <CircularProgress className="mx-auto"/>
            }
            {
                matchList.length === 0 &&
                <div className="text-center">No matchs for this tournaments</div>
            }
            {
                !isLoading && matchList.length > 0 &&
                <div className="w-100 overflow-hidden d-flex">
                    <TableContainer>
                        <Table stickyHeader={true}>
                            <TableHead>
                                <TableRow>
                                    <TableCell align="left">ID</TableCell>
                                    <TableCell align="left">Date de Création</TableCell>
                                    <TableCell align="left">Début du Match</TableCell>
                                    <TableCell align="left">Durée</TableCell>
                                    <TableCell align="left">Teams</TableCell>
                                    <TableCell align="left">State</TableCell>
                                    <TableCell align="left">Assignee</TableCell>
                                    <TableCell align="left">Round</TableCell>
                                    <TableCell align="left">Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    (matchList as Array<TournamentMatch>).map((match: TournamentMatch, index: number) => {
                                        return (
                                            <TableRow key={index}>
                                                <TableCell align="left">{match.route}</TableCell>
                                                <TableCell size={'small'} align="left">
                                                    {
                                                        `${moment((match.date.getTime ? match.date.getTime() : match.date as any) * 1000).format('L')}
                                                        ${moment((match.date.getTime ? match.date.getTime() : match.date as any) * 1000).format('LTS')}`
                                                    }
                                                </TableCell>
                                                <TableCell>
                                                    {
                                                        match.fp ?
                                                            `${moment((match.fp.getTime ? match.fp.getTime() : match.fp as any) * 1000).format('L')}
                                                        ${moment((match.fp.getTime ? match.fp.getTime() : match.fp as any) * 1000).format('LTS')}` : '-'
                                                    }
                                                </TableCell>
                                                <TableCell align="left">{renderUrgencyStatus(match)}</TableCell>
                                                <TableCell align="left">
                                                    <div className="d-flex flex-wrap">
                                                        {
                                                            match.teams.map(t => {
                                                                if (!t || !t.name) {
                                                                    return '';
                                                                }
                                                                if (t.checkIn) {
                                                                    return <div key={t.name} style={{
                                                                        color: '#b2f617',
                                                                        marginRight: '5px'
                                                                    }}>{t.name}</div>
                                                                }
                                                                return `${t.name} `;
                                                            })
                                                        }
                                                    </div>
                                                </TableCell>
                                                <TableCell align="left">{renderMatchState(match.state)}</TableCell>
                                                <TableCell align="left">{match.assignee}</TableCell>
                                                <TableCell align="left">
                                                    <FormattedMessage
                                                        id={match.label}
                                                        values={match.lData}
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    <Link target="_blank"
                                                          href={`/tournaments/${props.tournamentId}/matchs/${match.route.split('/')[1]}`}>
                                                        <Button variant="outlined">
                                                            <VisibilityIcon className="primary-color"/>
                                                        </Button>
                                                    </Link>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            }
        </>
    );
}
