import React, {useEffect, useState} from 'react';
import {TrainingProgram} from 'src/models/TrainingProgram';
import {TrainingService} from 'src/services/training.service';
import {Mode, TrainingModule} from 'src/models/TrainingModule';
import './ProgramModules.scss';
import CircularProgress from '@material-ui/core/CircularProgress';
import {TrainingModuleCard} from 'src/atoms/TrainingModuleCard/TrainingModuleCard';
import {NCTitle, NCTitleType} from 'src/atoms/NCTitle/NCTitle';
import {Accordion} from '@material-ui/core';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Button from '@material-ui/core/Button/Button';
import AddIcon from '@material-ui/icons/Add';
import {FullScreenDialog} from 'src/components/General/FullScreenDialog/FullScreenDialog';
import {CreateModuleForm} from 'src/pages/Training/Programs/ProgramModules/CreateModuleForm/CreateModuleForm';

interface ProgramModulesProps {
    program: TrainingProgram;
    programChange: any;
    edit: boolean;
    lang: string;
}

export const ProgramModules: React.FunctionComponent<ProgramModulesProps> = (props: ProgramModulesProps) => {
    const [program] = useState<TrainingProgram>(props.program);
    const [isLoading, setIsLoading] = useState(true);
    const [programModules, setProgramModules] = useState<Array<TrainingModule>>([]);
    const [noProgramModules, setNoProgramModules] = useState<Array<TrainingModule>>([]);
    const [programModuleActionCreate, setProgramModuleActionCreate] = useState<boolean>(false);
    const [programModule, setProgramModule] = useState<TrainingModule>();


    useEffect(() => {
        getModules();
        // eslint-disable-next-line
    }, []);

    const getModules = (limit = 50, page = 0) => {
        TrainingService.getModule(limit, page).then((data) => {
            data.docs.filter((module: TrainingModule) => {
                if (module.mode === Mode.CHALLENGE) {
                    return null;
                } else if (module.program === program._id) {
                    return programModules.push(module);
                } else {
                    return noProgramModules.push(module);
                }
            });
            setProgramModules(programModules);
            setNoProgramModules(noProgramModules);
            if (data.total > (page + 1) * limit) {
                getModules(limit, page + 1);
            } else {
                setIsLoading(false);
            }
        });
    };

    const updateList = (result: boolean) => {
        if (result) {
            setProgramModuleActionCreate(false);
            setProgramModules([]);
            setNoProgramModules([]);
            getModules();
        }
    };

    const createProgramModule = () => {
        const mod = new TrainingModule(
            {en: ''},
            {en: ''},
            '',
            '',
        );
        mod.program = program._id;
        setProgramModule(mod);
        setProgramModuleActionCreate(true);
    };

    return (
        <>
            {
                isLoading && <CircularProgress className="mx-auto"/>
            }
            {
                !isLoading &&
                <>
                    <div className="mb-3">
                        <div className="d-flex justify-content-between">
                            <NCTitle text={'Active Modules'} type={NCTitleType.TRAINING}/>
                            <Button
                                variant="text"
                                onClick={createProgramModule}
                                color="primary"
                                className="">
                                <AddIcon/>
                            </Button>
                        </div>
                        <div className="d-flex flex-wrap overflow-auto">
                            {
                                programModules && programModules.map((module: TrainingModule) => {
                                    return (
                                        <div className="m-2" key={module._id}>
                                            <TrainingModuleCard module={module} updateHook={updateList}/>
                                        </div>
                                    );
                                })
                            }
                        </div>
                    </div>
                    <div className="mt-5">
                        <Accordion className="noBackground">
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon/>}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                className="p-0"
                            >
                                <NCTitle text={'Link other modules to this program'} type={NCTitleType.TRAINING}/>
                            </AccordionSummary>
                            <AccordionDetails>
                                <div className="d-flex flex-wrap overflow-auto">
                                    {
                                        noProgramModules && noProgramModules.map((module: TrainingModule) => {
                                            return (
                                                <div className="m-md-2" key={Math.random()}>
                                                    <TrainingModuleCard module={module} notAttached={true}
                                                                        updateHook={updateList}/>
                                                </div>
                                            );
                                        })
                                    }
                                </div>
                            </AccordionDetails>
                        </Accordion>
                    </div>

                </>
            }

            <FullScreenDialog
                open={programModuleActionCreate}
                openHook={setProgramModuleActionCreate}
                actionHook={updateList}
                componentToDisplay={
                    <CreateModuleForm module={programModule} setModule={setProgramModule} actionHook={updateList}
                                      creationMode={true}/>
                }
            />
        </>
    );
};