import { Button } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Folder, InsertDriveFile } from '@material-ui/icons';
import AddIcon from '@material-ui/icons/Add';
import BlockIcon from '@material-ui/icons/Block';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import SettingsIcon from '@material-ui/icons/Settings';
import SwapHorizIcon from '@material-ui/icons/SwapHoriz';
import VisibilityIcon from '@material-ui/icons/Visibility';
import moment from 'moment';
import React, { FunctionComponent, ReactElement, useEffect, useState } from 'react';
import { Paginate } from 'src/atoms/Paginate/Paginate';
import { LangPicker } from 'src/components/General/LangPicker/LangPicker';
import { useStoreState } from 'src/store';
import './SimpleList.scss';



export interface SimpleListActions {
    create?: boolean;
    edit?: boolean;
    delete?: boolean;
    switchActive?: boolean;
    see?: boolean;
    file?: boolean;
    cancel?: boolean;
}

export interface SimpleListProps {
    rows: any;
    columns: any;
    keepRows: any;
    actionsButtons?: SimpleListActions;
    handleClickOpen?: any;
    handleClickLine?: (item: any) => void;
    pagination?: { actual: number, total: number, setActual: any };
    translatedString?: boolean;
    small?: boolean;
    renderCell?: (cell: any, key: string, row: any) => ReactElement | null;
    editIcon?: FunctionComponent;
    metaData?: string;
}

export enum isBanType {
    iota,
    Notice,
    Warning,
    Temporary,
    Definitive
}

export const SimpleList: React.FunctionComponent<SimpleListProps> = (props: SimpleListProps) => {
    const EditIcon = props.editIcon || SettingsIcon;

    const [rows, setRows] = useState<Array<any>>([]);
    const [columns, setColumns] = useState<Array<string>>([]);
    const [keepRows, setKeepRows] = useState<Array<string>>([]);
    const [lang, setLang] = useState<string>(useStoreState(state => state.contentLang));

    useEffect(() => {
        setRows(props.rows);
        setColumns(props.columns);
        setKeepRows(props.keepRows);
    }, [props]);

    const renderObjectRow = (row: any) => {
        if (row[lang]) {
            return <>{row[lang]}</>;
        }
        if(row['status'] === 'NONE') {
            return <ClearIcon color="secondary"/>
        } else if ( row['status'] === 'PREMIUM' ) {
            return <CheckIcon color="primary"/>
        }
        if( (row['type'] === (isBanType.Definitive || isBanType.Temporary )) && moment(moment()).isBefore(row['until']) ) {
            return <CheckIcon color="primary"/>
        } else {
            return  <ClearIcon color="secondary"/>
        }
    };

    const renderArrayRow = (row: any) => {
        if (!row) {
            return <></>;
        }
        let stringToDisplay = '';

        for (let i = 0; i < row.length; i++) {
            if (i > 2) {
                return stringToDisplay += '...';
            }
            stringToDisplay += row[i];
            if (row[i + 1]) {
                stringToDisplay += ', ';
            }
        }
        return stringToDisplay;
    };

    return (
        <div className="d-flex flex-column w-100 h-100 simple-list">
            {props.translatedString && <LangPicker setLang={setLang}/>}
            <TableContainer component={Paper}>
                {
                    props.pagination &&
                    <Paginate pagination={props.pagination}/>
                }
                <Table size={props.small ? 'small' : undefined}>
                    <TableHead>
                        <TableRow>
                            {
                                columns.map((name: string, index: number) => (
                                    <TableCell align="left" key={index}>{name}</TableCell>
                                ))
                            }
                            {
                                props.actionsButtons && (props.actionsButtons.create || props.actionsButtons.edit || props.actionsButtons.delete || props.actionsButtons.see) &&
                                <TableCell align="left" key="action">
                                    <div className="d-flex flex-row justify-content-end align-items-center">
                                        {
                                            props.actionsButtons.create &&
                                            <Button variant="text" onClick={() => {
                                                props.handleClickOpen({}, 'create', undefined, props.metaData);
                                            }} color="primary"><AddIcon/></Button>
                                        }
                                    </div>
                                </TableCell>
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            rows && rows.length > 0 && rows.map((row: any, index: number) => (
                                <TableRow key={index} onClick={() => {props.handleClickLine && props.handleClickLine(row)}}>
                                    {
                                        keepRows.map((key: string, index: number) => {
                                            let r = row;
                                            const split = key.split('.');
                                            for (const s of split) {
                                                r = r[s];
                                                if (r === null) {
                                                    break;
                                                }
                                            }
                                            const render = props.renderCell?.(r, key, row);
                                            if (render) {
                                                return <TableCell align="left" key={index}>{render}</TableCell>;
                                            }

                                            if (r && Array.isArray(r)) {
                                                return <TableCell align="left" key={index}>{renderArrayRow(r)}</TableCell>
                                            }

                                            if (r instanceof Date) {
                                                return <TableCell align="left" key={index}>{moment(r).format('LLLL')}</TableCell>;
                                            }
                                            switch (typeof r) {
                                                case 'object':
                                                    return <TableCell align="left" key={index}>
                                                        {renderObjectRow(r)}
                                                    </TableCell>;
                                                case 'boolean':
                                                    if (props.actionsButtons && props.actionsButtons.file) {
                                                        return <TableCell align="left" key={index}>{r ?
                                                            <Button className="media"><InsertDriveFile /></Button> :
                                                            <Button className="media" color="primary"><Folder /></Button>}
                                                        </TableCell>;
                                                    }
                                                    return <TableCell align="left" key={index}>{r ?
                                                        <CheckIcon color="primary"/> :
                                                        <ClearIcon color="secondary"/>}</TableCell>;
                                                case 'number':
                                                default:
                                                    return <TableCell align="left" key={index}>{r}</TableCell>;
                                            }
                                        })
                                    }

                                    {
                                        props.actionsButtons && (props.actionsButtons.create || props.actionsButtons.edit || props.actionsButtons.delete || props.actionsButtons.see || props.actionsButtons.switchActive) &&
                                        <TableCell align="right" key="action">
                                            {
                                                props.actionsButtons && props.actionsButtons.edit &&
                                                <Button variant="text" onClick={() => {
                                                    props.handleClickOpen(row, 'edit');
                                                }} color="primary" className="mr-2"><EditIcon/></Button>
                                            }
                                            {
                                                props.actionsButtons && props.actionsButtons.switchActive &&
                                                <Button variant="text" onClick={() => {
                                                    props.handleClickOpen(row, 'switchActive');
                                                }} color="primary" className="mr-2"><SwapHorizIcon/></Button>
                                            }
                                            {
                                                props.actionsButtons && props.actionsButtons.delete &&
                                                <Button variant="text" onClick={() => {
                                                    props.handleClickOpen(row, 'delete');
                                                }} color="secondary"><DeleteForeverIcon/></Button>
                                            }
                                            {
                                                props.actionsButtons && props.actionsButtons.see &&
                                                <Button variant="text" onClick={() => {
                                                    props.handleClickOpen(row, 'see');
                                                }} color="primary"><VisibilityIcon/></Button>
                                            }
                                            {
                                                props.actionsButtons && props.actionsButtons.cancel &&
                                                <Button variant="text" onClick={() => {
                                                    props.handleClickOpen(row, 'cancel');
                                                }} color="primary"><BlockIcon/></Button>
                                            }
                                        </TableCell>
                                    }

                                </TableRow>
                            ))
                        }
                        {
                            (!rows || !rows.length) &&
                            <TableRow>
                                {
                                    keepRows.map((value: any, index: number) => {
                                        if (index === 0) {
                                            return (<TableCell key={index}>No data</TableCell>);
                                        }
                                        return (<TableCell key={index}/>);
                                    })
                                }

                            </TableRow>
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};