import { GamePartial } from 'src/models/GamePartial';
import { TranslatedString } from 'src/models/TranslatedString';

export abstract class TrainingBrick {
    public _id: string;
    public abstract type: BrickType;

    protected constructor(
        public name: TranslatedString,
        public description: TranslatedString,
        public thematic: string,
    ) {
    }
}

export enum BlockType {
    SEQUENTIAL = 'SEQUENTIAL',
    PARALLEL = 'PARALLEL',
    PRIMARY = 'PRIMARY',
}

export enum BrickType {
    VIDEO = 'VIDEO',
    QUIZ = 'QUIZ',
    ASSIGNEMENT = 'ASSIGNEMENT',
}

export enum Logical {
    AND = 'AND',
    OR = 'OR',
}

export class TrainingBrickVideo extends TrainingBrick {
    public type = BrickType.VIDEO;

    constructor(
        name: TranslatedString,
        description: TranslatedString,
        thematic: string,
        public targetId?: any,
        public cactusPoint?: number,
    ) {
        super(name, description, thematic);
    }
}

export class TrainingBrickQuiz extends TrainingBrick {
    public type = BrickType.QUIZ;
    public succeeded: boolean = false;
    public cactusPoint: number;

    constructor(
        name: TranslatedString,
        description: TranslatedString,
        thematic: string,
        public targetId?: any,
    ) {
        super(name, description, thematic);
    }
}

export class TrainingBrickAssignement extends TrainingBrick {
    public type = BrickType.ASSIGNEMENT;

    constructor(
        name: TranslatedString,
        description: TranslatedString,
        thematic: string,
        public tips: Array<TranslatedString>,
        public video: string,
        public startCondition: any,
        public winCondition: any,
        public game: GamePartial,
        public repetitions: number,
        public preProcessors: Array<PreProcessInstruction>,
        public startAfter: number,
        public durationDays: number,
    ) {
        super(name, description, thematic);
    }
}

export abstract class PreProcessInstruction {
    public abstract instruction: InstructionType;
    public abstract target: string;
    public abstract data: any;
}

export enum InstructionType {
    metricAVG = 'AVG',
    metricSUM = 'SUM',
    metricCOUNT = 'COUNT',
}



export type MetricData = Data | Data;

export class PreProcessInstructionMetricAVG extends PreProcessInstruction {
    public instruction = InstructionType.metricAVG;

    constructor(
        public target: string,
        public data: Data,
    ) {
        super();
    }
}

export interface Data {
    metric: string;
}

export class PreProcessInstructionMetricSUM extends PreProcessInstruction {
    public instruction = InstructionType.metricSUM;

    constructor(
        public target: string,
        public data: Data,
    ) {
        super();
    }
}

export interface Data {
    metric: string;
}

export enum Operator {
    gt = 'gt',
    gte = 'gte',
    lt = 'lt',
    lte = 'lte',
    eq = 'eq',
    ne = 'ne',
    regex = 'regex',
}

export const ComparisonOperatorLUT: { [op in Operator]: string } = {
    eq: '=',
    gt: '>',
    gte: '>=',
    lt: '<',
    lte: '<=',
    ne: '!=',
    regex: 'RegExp',
};


export enum Kind {
    Number = 'Number',
    String = 'String',
    Boolean = 'Boolean',
}

export interface Options {
    ignoreCase?: boolean;
    kind?: Kind;
}

export interface PredicateEdition {
    instruction?: InstructionType;
    path: string;
    operator?: Operator;
    value?: any;
    options?: Options;
    description: TranslatedString;
}

export enum PredicateCondition {
    PREREQUISITE = 'prerequisite',
    TARGET = 'target',
}

export interface PredicateEditionAugmented extends PredicateEdition {
    type: Type;
    predicateOptionsHandlers: object;
}

export enum Type {
    COMPARISON = 'COMPARISON',
    LOGICAL = 'LOGICAL',
}

