import axios, { AxiosError } from 'axios';
import { Reward, RewardKind } from 'src/models/Transaction';
import { LastKey } from '../models/Common';
import { Wallet } from '../models/Wallet';
import { User } from '@wolfiesports/srm-component';


export class WalletService {
    private static baseUrl: string = String(process.env.REACT_APP_NR_URL);
    static token = (): string | null => localStorage.getItem('accessToken');

    static async getWalletsByUserID(userId: string, lk?: LastKey): Promise<Array<Wallet>> {
        let res;
        try {
            res = await axios.get(`${WalletService.baseUrl}/admin/wallets/${userId}`, {
                headers: {
                    'authorization': WalletService.token(),
                },
                params: lk,
            });
        } catch (e) {
            throw (e as AxiosError).response;
        }

        return res.data.list;
    }

    static async getWallet(owner: string, route: string): Promise<Wallet> {
        let res;
        try {
            res = await axios.get(`${WalletService.baseUrl}/admin/wallets/${owner}/${route}`, {
                headers: {
                    'authorization': WalletService.token(),
                },
            });
        } catch (e) {
            throw (e as AxiosError).response;
        }

        return res.data;
    }

    static async forceReward(user: User, wallet: Wallet, reward: Reward): Promise<void> {
        if ([ RewardKind.GiftKind ].includes(reward.kind)) {
            console.error('Unsupported forced reward kind');
            return;
        }

        try {
            await axios.post(`${WalletService.baseUrl}/admin/transactions`, {
                owner: wallet.owner,
                user: {
                    email: user.email,
                    name: user.name,
                    code: user.code,
                    firstname: user.firstName,
                    lastname: user.lastName,
                    phone: user.phone,
                    birthday: user.birthday,
                    status: user.premium.status,
                    confirmation: user.valid,
                },
                kind: `admin/${reward.kind}`,
                detail: reward,
            }, {
                headers: {
                    'authorization': WalletService.token(),
                },
            });
        } catch (e) {
            throw (e as AxiosError).response;
        }

        return;
    }
}
