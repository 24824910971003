import { CircularProgress } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { NCTitle, NCTitleType } from 'src/atoms/NCTitle/NCTitle';
import { ActionDialog } from 'src/components/General/ActionDialog/ActionDialog';
import { FullScreenDialog } from 'src/components/General/FullScreenDialog/FullScreenDialog';
import { SimpleList } from 'src/components/General/SimpleList/SimpleList';
import { OrganizationForm } from 'src/components/Organization/OrganizationForm/OrganizationForm';
import { OrganizationModel } from 'src/models/Organization';
import { PartnerModel } from 'src/models/Partner';
import { OrganizationService } from 'src/services/organization.service';
import { PartnerService } from 'src/services/partner.service';
import { Country } from "../../models/Country";
import { CountryService } from "../../services/country.service";
import { NGGameService } from 'src/services/nggame.service';

export const Organization: React.FunctionComponent = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [rows, setRows] = useState([]);
    const [organization, setOrganization] = useState<OrganizationModel>();
    const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
    const [openEditDialog, setOpenEditDialog] = useState<boolean>(false);
    const [openCreateDialog, setOpenCreateDialog] = useState<boolean>(false);
    const [actualPage, setActualPage] = useState<number>(0);
    const [totalItem, setTotalItem] = useState<number>(0);
    const [gamesList, setGamesList] = useState<Array<any>>([]);
    const [countryList, setCountryList] = useState<Array<Country>>([]);
    const [partnerList, setPartnerList] = useState<Array<PartnerModel>>([]);

    const columns = ['Name', 'Domain', 'Active', 'Icon Visible', 'Redirection'];
    const keepRows = ['name', 'domain', 'active', 'orgIconVisible', 'orgRedirectionLink'];

    useEffect(() => {
        getOrganization();
        NGGameService.getGames().then((data) => {
            setGamesList(data.list);
        });
        CountryService.getAllCountries().then((data) => {
            setCountryList(data);
        })
        PartnerService.getAllPartners().then((data) => {
            setPartnerList(data);
        })
    }, [actualPage]);

    const handleClickOpen = (organization: OrganizationModel, kind: string) => {
        setOrganization(organization);

        switch (kind) {
            case 'delete':
                setOpenDeleteDialog(true);
                break;
            case 'edit':
                setOpenEditDialog(true);
                break;
            case 'create': {
                setOrganization(new OrganizationModel(
                    '',
                    '',
                    [''],
                    {primary: '#ffffff', secondary: '#ffffff'},
                    {facebook: '', twitch: '', instagram: '', youtube: '', twitter: '', discord: '', steam: '', linkedin: '', goToLink: ''},
                    {},
                ));
                setOpenCreateDialog(true);
                break;
            }
            default:
                return;
        }
    };

    const getOrganization = () => {
        OrganizationService.getOrganizations().then((data: any) => {
            setTotalItem(Math.ceil(data.length / 20));
            setRows(data);
            setIsLoading(false);
        }).catch((error: any) => console.log(error));
    };

    const deleteAdmin = (choice: boolean) => {
        if (choice) {
            OrganizationService.deleteOrganization(organization).then(() => {
                getOrganization();
            }).catch((error: any) => console.log(error));
        }
    };

    const updateList = () => {
        setOpenEditDialog(false);
        setOpenCreateDialog(false);
        getOrganization();
    };


    return (
        <div className="NC-container NC-card">
            <NCTitle text={`Organizations (${totalItem ? totalItem : '0'})`} type={NCTitleType.ADMINISTRATOR}/>
            <div className="d-flex">
                {
                    isLoading && <CircularProgress className="mx-auto"/>
                }
                {
                    !isLoading &&
                    <SimpleList
                        rows={rows}
                        columns={columns}
                        keepRows={keepRows}
                        actionsButtons={{create: true, edit: true, delete: true}}
                        handleClickOpen={handleClickOpen}
                        pagination={{actual: actualPage, total: Math.ceil(totalItem / 20), setActual: setActualPage}}
                    />
                }
            </div>
            <ActionDialog
                open={openDeleteDialog}
                title={`Are you sure to delete the organization : ${organization?.name}`}
                openHook={setOpenDeleteDialog}
                actionHook={deleteAdmin}
            />
            <FullScreenDialog
                disableEnforceFocus={true}
                open={openEditDialog}
                openHook={setOpenEditDialog}
                actionHook={updateList}
                componentToDisplay={
                    <OrganizationForm organization={organization} setOrganization={setOrganization}
                                      actionHook={updateList} gameList={gamesList} countryList={countryList} partnerList={partnerList}/>
                }
            />
            <FullScreenDialog
                disableEnforceFocus={true}
                open={openCreateDialog}
                openHook={setOpenCreateDialog}
                actionHook={updateList}
                componentToDisplay={
                    <OrganizationForm organization={organization} setOrganization={setOrganization}
                                      actionHook={updateList} creationMode={true} gameList={gamesList} countryList={countryList} partnerList={partnerList}/>
                }
            />
        </div>
    );
};