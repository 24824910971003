import { ProgramLevel } from 'src/models/TrainingProgram';
import { TranslatedString } from 'src/models/TranslatedString';

export enum Mode {
    LINEAR = 'LINEAR',
    TREE = 'TREE',
    CHALLENGE = 'CHALLENGE',
}

export class TrainingModule {
    public _id: string;
    public program?: string;
    public comingSoon: boolean = true;
    public mode = Mode.TREE;
    public level?: ProgramLevel;
    public challengeIndex: number = 1;
    public challengeTag: string = '';
    public challengeStart?: number;
    public challengeEnd?: number;
    public durationUnit: DurationUnit;
    public startDate?: number;
    public endDate?: number;
    public rewards?: Array<ModuleReward>;
    public ended: boolean = false;

    constructor(
        public name: TranslatedString,
        public description: TranslatedString,
        public game: string,
        public company: string,
    ) {}
}

export interface ModuleFilters {
    game?: string;
    user?: string;
    program?: string;
    _id?: string;
}

export enum EModuleImage {
    Cover = 'Cover',
    // Background = 'Background',
}

export enum DurationUnit {
    HOURS = 'hours',
    DAYS = 'days',
    WEEKS = 'weeks',
}

export interface ModuleReward {
    cactusPoint: number;
    cashPrize: number;
}

export enum EChallengeImage {
    ChampionImage= 'ChampionImage',
}