import { Icon, IconType } from '@wolfiesports/srm-component';
import { faWallet } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Menu, MenuItem, MenuProps } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import withStyles from '@material-ui/core/styles/withStyles';
import Toolbar from '@material-ui/core/Toolbar';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import AppsIcon from '@material-ui/icons/Apps';
import BusinessIcon from '@material-ui/icons/Business';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import EmojiEventsIcon from '@material-ui/icons/EmojiEvents';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import GamepadIcon from '@material-ui/icons/Gamepad';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import PagesIcon from '@material-ui/icons/Pages';
import PageviewIcon from '@material-ui/icons/Pageview';
import PeopleIcon from '@material-ui/icons/People';
import FitnessCenterIcon from '@material-ui/icons/FitnessCenter';
import PermMediaIcon from "@material-ui/icons/PermMedia";
import PersonIcon from '@material-ui/icons/Person';
import PublicIcon from '@material-ui/icons/Public';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import VideocamIcon from '@material-ui/icons/Videocam';
import clsx from 'clsx';
import React, { useState } from 'react';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import Logo from 'src/assets/nicecactus-logo/nicecactus-assets_logo-mobile.svg';
import LogoFull from 'src/assets/nicecactus-logo/nicecactus-logo.svg';
import DashboardIcon from 'src/assets/system/dashboard-home.svg';
import PremiumIcon from 'src/assets/system/premium-gradient.svg';
import PlatformSettingsIcon from 'src/assets/system/settings.svg';
import AdministratorIcon from 'src/assets/system/teams.svg';
import { NCTitleType } from 'src/atoms/NCTitle/NCTitle';
import { AdministratorsRole } from 'src/models/Administrator';
import { store } from 'src/store';
import './Navbar.scss';
import { RenderCollapsedLink } from './RenderCollapsedLink';
import { useLogout, useSession } from 'src/session/session';


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
        },
        appBar: {
            zIndex: theme.zIndex.drawer + 1,
            transition: theme.transitions.create(['width', 'margin'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
        },
        appBarShift: {
            marginLeft: drawerWidth,
            width: `calc(100% - ${drawerWidth}px)`,
            transition: theme.transitions.create(['width', 'margin'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
        menuButton: {
            padding: '10px'
        },
        hide: {
            display: 'none',
        },
        drawer: {
            width: drawerWidth,
            flexShrink: 0,
            whiteSpace: 'nowrap',
        },
        drawerOpen: {
            width: drawerWidth,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
            backgroundColor: '#1a1a1a',
        },
        drawerHeader: {
            display: 'flex',
            alignItems: 'center',
            padding: theme.spacing(0, 1),
            // necessary for content to be below app bar
            ...theme.mixins.toolbar,
            justifyContent: 'flex-end',
        },
        drawerClose: {
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            overflowX: 'hidden',
            width: '48px !important',
            [theme.breakpoints.up('sm')]: {
                width: theme.spacing(7.5) + 1,
            },
            backgroundColor: '#1a1a1a',
        },
        toolbar: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            padding: theme.spacing(0, 1),
            // necessary for content to be below app bar
            ...theme.mixins.toolbar,
            backgroundColor: '#0c0c0c',
        },
        content: {
            flexGrow: 1,
            padding: theme.spacing(3),
        },
        nested: {
            paddingLeft: theme.spacing(4),
        },
    }),
);

const StyledMenu = withStyles({
    paper: {
        width: '200px',
    },
})((props: MenuProps) => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
        }}
        {...props}
    />
));

const MenuItems = [
    {
        name: 'Dashboard',
        link: '/dashboard',
        icon: <img src={DashboardIcon} alt="-"/>,
        role: [AdministratorsRole.GUEST, AdministratorsRole.LEGAL, AdministratorsRole.PARTNER, AdministratorsRole.TOURNAMENT,
            AdministratorsRole.TRAINING, AdministratorsRole.LEGAL, AdministratorsRole.NCADMIN],
    },
    {
        name: 'Users',
        icon: <img src={AdministratorIcon} alt="-"/>,
        color: NCTitleType.TOURNAMENT,
        role: [AdministratorsRole.NCADMIN, AdministratorsRole.TOURNAMENT],
        subMenu: [
            {
                name: 'Administrators',
                link: '/administrators',
                icon: <img src={AdministratorIcon} alt="-"/>,
                color: NCTitleType.ADMINISTRATOR,
                role: [AdministratorsRole.NCADMIN],
            },
            {
                name: 'Members',
                link: '/users',
                icon: <PersonIcon/>,
                role: [AdministratorsRole.NCADMIN],
            },
            {
                name: 'Teams',
                link: '/teams',
                icon: <img src={AdministratorIcon} alt="-"/>,
                color: NCTitleType.TOURNAMENT,
                role: [AdministratorsRole.NCADMIN],
            },
            {
                name: 'Withdrawal',
                link: '/withdrawal',
                icon: <FontAwesomeIcon icon={faWallet} color="white" style={{fontSize: 18, marginLeft: 4}}/>,
                color: NCTitleType.TOURNAMENT,
                role: [AdministratorsRole.NCADMIN],
            },
        ],
    },
    {
        name: 'Tournaments',
        icon: <EmojiEventsIcon style={{color: 'white'}}/>,
        color: NCTitleType.TOURNAMENT,
        role: [AdministratorsRole.NCADMIN, AdministratorsRole.TOURNAMENT],
        subMenu: [
            {
                name: 'Dashboard',
                link: '/tournaments/beta',
                icon: <EmojiEventsIcon style={{color: 'white'}}/>,
                color: NCTitleType.TOURNAMENT,
                role: [AdministratorsRole.NCADMIN, AdministratorsRole.TOURNAMENT],
            },
            {
                name: 'Refereeing',
                link: '/tournaments/dashboard',
                icon: <EmojiEventsIcon style={{color: 'white'}}/>,
                color: NCTitleType.TOURNAMENT,
                role: [AdministratorsRole.NCADMIN, AdministratorsRole.TOURNAMENT],
            }
        ],
    },
    {
        name: 'Games',
        icon: <GamepadIcon/>,
        color: NCTitleType.TOURNAMENT,
        role: [AdministratorsRole.NCADMIN, AdministratorsRole.TOURNAMENT],
        subMenu: [
            {
                name: 'Platforms',
                link: '/platforms',
                icon: <AppsIcon/>,
                role: [AdministratorsRole.NCADMIN, AdministratorsRole.TRAINING],
            },
            {
                name: 'Games',
                link: '/games',
                icon: <GamepadIcon/>,
                role: [AdministratorsRole.NCADMIN, AdministratorsRole.TRAINING],
            },
            {
                name: 'Game accounts',
                link: 'gameaccounts',
                icon: <GamepadIcon/>,
                color: NCTitleType.ADMINISTRATOR,
                role: [AdministratorsRole.NCADMIN],
            },
            {
                name: 'Game pages',
                link: '/gamepages',
                icon: <PagesIcon/>,
                role: [AdministratorsRole.NCADMIN],
            },
        ],
    },
    {
        name: 'Media library',
        link: '/media-library',
        icon: <PermMediaIcon/>,
        role: [AdministratorsRole.NCADMIN],
    },
    {
        name: 'Monetize',
        icon: <img src={PremiumIcon} alt="-"/>,
        color: NCTitleType.TOURNAMENT,
        role: [AdministratorsRole.NCADMIN, AdministratorsRole.TOURNAMENT],
        subMenu: [
            {
                name: 'Currencies',
                link: '/currencies',
                icon: <MonetizationOnIcon/>,
                role: [AdministratorsRole.NCADMIN],
            },
            {
                name: 'Premium',
                link: '/premium',
                icon: <img src={PremiumIcon} alt="-"/>,
                role: [AdministratorsRole.NCADMIN],
            },
            {
                name: 'Voucher',
                link: '/voucher',
                icon: <img src={PremiumIcon} alt="-"/>,
                role: [AdministratorsRole.NCADMIN],
            },
            {
                name: 'Withdrawal',
                link: '/withdrawal',
                icon: <FontAwesomeIcon icon={faWallet} color="white" style={{fontSize: 18, marginLeft: 4}}/>,
                color: NCTitleType.TOURNAMENT,
                role: [AdministratorsRole.NCADMIN],
            },
        ],
    },
    {
        name: 'Organizations',
        icon: <SupervisedUserCircleIcon style={{color: 'white'}}/>,
        color: NCTitleType.TOURNAMENT,
        role: [AdministratorsRole.NCADMIN, AdministratorsRole.TOURNAMENT],
        subMenu: [
            {
                name: 'Partners',
                link: '/partners',
                icon: <PeopleIcon style={{color: 'white'}}/>,
                color: NCTitleType.ADMINISTRATOR,
                role: [AdministratorsRole.NCADMIN],
            },
            {
                name: 'Organizations',
                link: '/organizations',
                icon: <SupervisedUserCircleIcon style={{color: 'white'}}/>,
                color: NCTitleType.ADMINISTRATOR,
                role: [AdministratorsRole.NCADMIN],
            },
            {
                name: 'Challenges',
                link: '/challenges',
                icon: <FitnessCenterIcon style={{ color: 'white' }} />,
                role: [AdministratorsRole.NCADMIN],
            },
        ],
    },
    {
        name: 'Platform settings',
        link: '',
        icon: <img src={PlatformSettingsIcon} alt="-"/>,
        role: [AdministratorsRole.NCADMIN, AdministratorsRole.TRAINING, AdministratorsRole.LEGAL],
        subMenu: [
            {
                name: 'Companies',
                link: '/companies',
                icon: <BusinessIcon style={{color: 'white'}}/>,
                color: NCTitleType.ADMINISTRATOR,
                role: [AdministratorsRole.NCADMIN],
            },
            {
                name: 'Promotions',
                link: '/promotions',
                icon: <Icon icon={IconType.Comments} width={24} height={24} />,
                color: NCTitleType.ADMINISTRATOR,
                role: [AdministratorsRole.NCADMIN],
            },
            {
                name: 'Countries',
                link: '/Countries',
                icon: <PublicIcon/>,
                role: [AdministratorsRole.NCADMIN],
            },            
            {
                name: 'Seo',
                link: '/seo',
                icon: <PageviewIcon/>,
                role: [AdministratorsRole.NCADMIN],
            },
            
            {
                name: 'Videos',
                link: '/videos',
                icon: <VideocamIcon/>,
                role: [AdministratorsRole.NCADMIN],
            },
        ],
    },
];

const drawerWidth = 240;

interface NavLinkItem {
    link: string,
    name: string,
    color: string,
    icon: string
}

export const Navbar: React.FunctionComponent = () => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const adminRole = store.getState().currentAdministrator?.role;
    const user = useSession();
    const navigate = useNavigate();
    const logout = useLogout({
        onSuccess: () => {
            console.log('logOut');
            
        }
    });

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const logOut = () => {
        try {
            if(user.data) {
                logout.mutate(user.data);   
            }
        } finally {
            navigate('/');
        }
    };

    const openProfileMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const closeProfileMenu = () => {
        setAnchorEl(null);
    };

    const renderLink = (item: NavLinkItem, index: number) => {
        return (
            <NavLink 
                className={(props) => {
                    return `${props.isActive ? 'active ' : ''}${item.color ? item.color : ''}`}
                } 
                to={item.link}
                key={item.name + index + Math.random()}
            >
                <ListItem button>
                    <ListItemIcon className="item-icon">{item.icon}</ListItemIcon>
                    <ListItemText primary={item.name}/>
                </ListItem>
            </NavLink>
        );
    };

    return (
        <div className={classes.root}>
            <CssBaseline/>
            <AppBar
                position="fixed"
                className={clsx(classes.appBar, {
                    [classes.appBarShift]: open,
                })}
            >
                <Toolbar className="header-background position-relative">
                    <div
                        className="d-flex flex-row w-100 position-relative justify-content-end justify-content-sm-between">
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            className={clsx(classes.menuButton, {
                                [classes.hide]: open,
                            })}
                        >
                            <img src={Logo} style={{width: '30px'}} alt="-"/>
                        </IconButton>
                        <div className="d-flex flex-row mr-3 align-items-center w-100 justify-content-end">
                            <Button aria-controls="profile-menu" aria-haspopup="true" onClick={openProfileMenu}>
                                <div className="profile-picture mr-1"><AccountCircleIcon fontSize="large"/></div>
                                <div
                                    className="text my-auto font-weight-bold">{`${store.getState().currentAdministrator?.email}`}</div>
                            </Button>
                            <StyledMenu
                                id="top-profile-menu"
                                anchorEl={anchorEl}
                                keepMounted
                                open={Boolean(anchorEl)}
                                onClose={closeProfileMenu}
                            >
                                <MenuItem onClick={closeProfileMenu}>
                                    <Link to={'/profile'}>Profile</Link>
                                </MenuItem>
                                <MenuItem onClick={closeProfileMenu}>
                                    <div className="logout-button d-flex align-items-center" onClick={() => {
                                        logOut();
                                    }}>
                                        <p className="my-auto mr-2">Log-out</p>
                                        <ExitToAppIcon/>
                                    </div>
                                </MenuItem>
                            </StyledMenu>
                        </div>
                    </div>
                </Toolbar>
            </AppBar>
            <Drawer
                variant="permanent"
                className={clsx(classes.drawer, {
                    [classes.drawerOpen]: open,
                    [classes.drawerClose]: !open,
                })}
                classes={{
                    paper: clsx({
                        [classes.drawerOpen]: open,
                        [classes.drawerClose]: !open,
                    }),
                }}
                onMouseEnter={handleDrawerOpen}
                onMouseLeave={handleDrawerClose}
            >
                <div className={classes.drawerHeader}>
                    <img src={LogoFull} style={{width: '150px'}} alt="-"/>
                    <IconButton>
                        <ChevronLeftIcon/>
                    </IconButton>
                </div>
                <List>
                    {
                        MenuItems.map((item: any, index: number) => {
                            if (adminRole && !adminRole.some(r => item.role.includes(r))) {
                                return null;
                            } else {
                                return (
                                    <div key={item.name + index + Math.random()}>
                                        {item.subMenu ? <RenderCollapsedLink item={item} index={index}
                                                                             adminRole={adminRole}/> : renderLink(item, index)}
                                    </div>
                                );
                            }
                        })
                    }
                </List>
            </Drawer>
        </div>
    );
};