export enum AccountType {
    EDITOR = "EDITOR",
    GAME = "GAME"
}

export class Account {
    constructor(
        public active: number,
        public atype: AccountType,
        public color: string,
        public id: string,
        public route: string,
        public title: string,
        public placeholder: string,
    ) {}
}

export class GameAccount {
    constructor(
        public account: string,
        public active: number,
        public id: string,
        public route: string,
        public platforms: Array<string>,
    ) {}
}

export enum EGameAccountImage {
    LogoImage = 'LogoImage',
    GameAccountImage = 'GameAccountImage'
}