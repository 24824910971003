import axios from 'axios';
import { NoGame } from 'src/models/Game';

export interface LastKey {
    id: string,
    route: string,
}
export class NGGameService {
    private static baseUrl: string = String(process.env.REACT_APP_NG_URL);
    static token = (): string | null => localStorage.getItem('accessToken');

    static async getGames(lastKey?: LastKey): Promise<{ lastKey: LastKey, list: Array<NoGame> }> {
        return (
            await axios.get(
                `${NGGameService.baseUrl}/public/games`,
                {
                    params: {
                        id: lastKey?.id
                    },
                    headers: {
                        'authorization': NGGameService.token(),
                    },
                },
            )
        ).data;
    }

    static async createGame(body: NoGame): Promise<NoGame> {
        if (!body) {
            throw Error;
        }
        let res: NoGame;
        try {
            res = (await axios.post(
                `${NGGameService.baseUrl}/admin/games`,
                body, {
                    headers: {
                        'authorization': NGGameService.token(),
                    }
                }
            )).data;
        } catch {
            throw Error;
        }

        return res;
    }

    static async updateGame(id: string, body: NoGame): Promise<boolean> {
        if (!body && !id) {
            return false;
        }

        try {
            await axios.put(
                `${NGGameService.baseUrl}/admin/games/${id}`,
                body, {
                    headers: {
                        'authorization': NGGameService.token(),
                    }
                }
            );
        } catch {
            return false;
        }

        return true;
    }

    // Doesn't exist yet on NG - Need to do Manual delete for now
    static async deleteGame(_id: string): Promise<boolean> {
        if (!_id) {
            return false;
        }

        try {
            await axios.delete(
                `${NGGameService.baseUrl}/admin/games/${_id}`, {
                    headers: {
                        'authorization': NGGameService.token(),
                    }
                }
            );
        } catch {
            return false;
        }

        return true;
    }
}