import { TranslatedString } from './TranslatedString';

export class TrainingProgram {
    public _id: string;
    public premium: boolean = false;
    public published: boolean = false;

    constructor(
        public name: TranslatedString,
        public description: TranslatedString,
        public level: ProgramLevel,
        public access: Access,
        public game: string,
        public estimatedDuration: TranslatedString,
        public requiredLevel: TranslatedString,
        public mode: TranslatedString,
        public axes: Array<TranslatedString>,
        public improveTags: Array<TranslatedString>,
        public work: TranslatedString,
        public objectif: TranslatedString,
        public advice: TranslatedString,
        public listAbout: Array<TranslatedString>,
        public steps: Array<TranslatedString>,
        public author: string,
        public company: string,
    ) {}
}

export enum ProgramLevel {
    NOVICE = 'NOVICE',
    BEGINNER = 'BEGINNER',
    INTERMEDIATE = 'INTERMEDIATE',
    ADVANCED = 'ADVANCED',
}

export enum Access {
    VISIBLE = 'VISIBLE',
    UNLISTED = 'UNLISTED',
    ARCHIVED = 'ARCHIVED',
}

export interface ProgramFilters {
    game?: string;
    level?: string;
    author?: string;
    _id?: string;
}


export enum EProgramImage {
    Cover = 'Cover',
}
