
import React, { ChangeEvent, useState } from "react";
import { Button, CircularProgress, Dialog, TextField } from '@material-ui/core';
import { NoUserService } from "src/services/no-user.service";
import { TeamService } from 'src/services/team.service';
import { NoUser } from "src/models/NoUser";
import Illustration from 'src/assets/illustration/illustration-tournament.png';

import './TeamInvitationModal.scss';
import { User } from "@wolfiesports/srm-component";


interface TeamInvitationModal {
    open: boolean;
    teamId: string;
    setOpenDialog: (isOpen: boolean) => void;
    actionHook: (saved: boolean) => void;
}

export const TeamInvitationModal: React.FunctionComponent<TeamInvitationModal> = (props: TeamInvitationModal) => {
    const [search, setSearch] = useState<string>();
    const [isLoading, setIsLoading] = useState<boolean>();
    const [searchResult, setSearchResult] = useState<Array<User>>([]);
    const [invitationsList, setInvitationsList] = useState<Array<NoUser>>([]);
    const [memberAdded, setMemberAdded] = useState<boolean>(false);

    const doSearch = () => {
        setIsLoading(true);
        setSearchResult([]);
        findUsers(search);
    };

    const findUsers = async (value: any) => {
        const res = await NoUserService.searchUser(value, 40);
        if (res.list.length > 0) {
            setSearchResult(res.list);
        }
        setIsLoading(false);
    };

    const close = () => {
        setInvitationsList([]);
        setSearchResult([]);
        setSearch("");
        props.setOpenDialog(false);
    };

    const addToInvitationList = (u: any) => {
        invitationsList.push(u);
        setInvitationsList([...invitationsList]);
    };

    const removeFromList = (userId: string) => {
        const index = invitationsList.findIndex((user) => user.userId === userId);
        invitationsList.splice(index, 1);
        setInvitationsList([...invitationsList]);
    };

    const addTeamMembers = async () => {
        await Promise.all([
            invitationsList.map((user) => {
                return TeamService.addUserInTeam(props.teamId, user);
            }),
        ]);
        setInvitationsList([]);
        setSearchResult([]);
        setSearch("");
        props.actionHook(true);
        setMemberAdded(true);
      };

    return (
        <Dialog className="team-invitation-modal" open={props.open} onClose={() => close()} fullWidth={true}>
            <div className="background-scratched"></div>
            <div className="m-3 p-3 mt-5 text-center content-container">
                <h5>Invite members</h5>

                {
                    !memberAdded &&
                    <>
                        <div className="my-5">
                            <div>Write the username or email of the member you want to invite.</div>
                            <div>You may select users inside Wolfie Sports or send an invitation to a non registered email</div>
                        </div>

                        <div className="d-flex flex-wrap">
                            {invitationsList.length > 0 && invitationsList.map((user) => {
                                return (
                                    <div className="invited d-flex p-1 px-2 mr-2 mb-2" key={user.userId}>
                                        <div className="mr-2">{user.name}#{user.code}</div>
                                        <div
                                            className="mask-icon m-auto"
                                            style={{
                                                maskImage: `url(${process.env.REACT_APP_S3_URL}/media/icons/close.svg)`,
                                                WebkitMaskImage: `url(${process.env.REACT_APP_S3_URL}/media/icons/close.svg)`
                                            }}
                                            onClick={() => { removeFromList(user.userId) }}
                                        ></div>
                                    </div>
                                );
                            })}
                        </div>

                        <div className="description mb-1 text-left">Type the username, the numbertag or the email you want to invite to your team</div>
                        <div className="d-flex search-container">
                            <TextField
                                value={search}
                                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                    setSearch(event.target.value);
                                }}
                                name="name"
                                className="NC-textfield mr-2"
                            />
                            <Button variant="contained" color="primary" onClick={doSearch} className="text-uppercase search-button">
                                Search
                            </Button>
                        </div>
                        {
                            isLoading && <CircularProgress className="mx-auto"/>
                        }
                        <div className="search-list overflow-auto text-left">
                        {
                            searchResult.length > 0 && searchResult.map((r: any, idx: number) => {
                                return (
                                    <div
                                        key={idx}
                                        onClick={() => addToInvitationList(r)}
                                        className="search-item"
                                    >
                                        {r.name}#{r.code}
                                    </div>
                                );
                            })
                        }
                        </div>

                        <Button
                            className="text-uppercase mt-4"
                            variant="contained"
                            color="primary"
                            disabled={invitationsList.length > 0 ? false : true}
                            onClick={() => (invitationsList.length > 0 ? addTeamMembers() : null)}
                        >
                            Add members
                        </Button>
                    </>
                }

                {
                    memberAdded &&
                    <>
                        <div className="d-flex justify-content-between">
                            <img
                                alt=""
                                src={Illustration}
                                className="ml-3"
                            />
                            <div className="w-50 my-auto text-left">
                                The player has been added to the team.
                            </div>
                        </div>
                        <Button
                            className="text-uppercase mt-4"
                            variant="contained"
                            color="primary"
                            onClick={() => props.setOpenDialog(false)}
                        >
                            Close
                        </Button>
                    </>
                }
            </div>

            <div
                className="close-icon mask-icon position-absolute"
                onClick={() => props.setOpenDialog(false)}
                style={{
                    maskImage: `url(${process.env.REACT_APP_S3_URL}/media/icons/close.svg)`,
                    WebkitMaskImage: `url(${process.env.REACT_APP_S3_URL}/media/icons/close.svg)`
                }}
            ></div>
        </Dialog>
    );
};
