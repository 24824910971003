import React from 'react';
import { Link } from 'react-router-dom';
import './NCTitle.scss';

export enum NCTitleType {
    TOURNAMENT = 'tournament',
    TRAINING = 'training',
    ADMINISTRATOR = 'administrator',
    CORNER = 'corner'
}

interface NCTitle {
    text: string | JSX.Element;
    link?: { label: string, url: string };
    color?: string;
    clickable?: boolean;
    type?: NCTitleType;
}

export const NCTitle: React.FunctionComponent<NCTitle> = ({ text, link, color, clickable, type }) => {
	return (
		<div className={`NCTitle d-flex flex-md-row flex-wrap align-items-baseline my-2 ${clickable ? 'clickable' : ''}`}>
			<div className={`${type ? type : ''} title`} style={{ background: color ? color : '#5f82ff' }}/>
			<span>{text}</span>
			{
				link &&
                <Link className="ml-2 title-link" to={link.url}>{link.label}</Link>
			}
		</div>
	);
};