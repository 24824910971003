import axios, { AxiosError } from 'axios';
import { toast } from 'react-toastify';
import { MongoPaginateResult } from 'src/models/PaginateResult';
import { PromotionModel } from 'src/models/Promotion';
import { LocalStorageService } from './local-storage.service';

export class PromotionService {
    private static baseUrl: string = `${String(process.env.REACT_APP_ADMINAPI_URL)}/promotion`;

    static async getPromotions(query?: any): Promise<MongoPaginateResult<PromotionModel>> {
        let res;
        try {
            res = await axios.get(
                `${PromotionService.baseUrl}`, {
                    params: query,
                    headers: {
                        'x-access-token': LocalStorageService.getToken(),
                    }
                }
            );
        } catch (e) {
            throw (e as AxiosError).response;
        }

        return res.data;
    }

    static async createPromotion(promotion: PromotionModel): Promise<PromotionModel> {
        let res;

        try {
            res = await axios.post(
                `${PromotionService.baseUrl}`,
                promotion,
                {
                    headers: {
                        'x-access-token': LocalStorageService.getToken(),
                    }
                }
            );
        } catch (e) {
            toast.error('Fail to create promotion, try again later or contact #platform-public');
            throw (e as AxiosError).response;
        }
        toast.success('Promotion created');
        return res.data;
    }

    static async updatePromotion(promotion: PromotionModel): Promise<boolean> {
        try {
            await axios.put(`${PromotionService.baseUrl}/${promotion._id}`, promotion, {
                headers: {
                    'x-access-token': LocalStorageService.getToken(),
                }
            });
        } catch (e) {
            toast.error('Fail to update promotion, try again later or contact #platform-public');
            return false;
        }
        toast.success('Promotion updated');
        return true;
    }

    static async deletePromotion(id: string): Promise<void> {
        let res;

        try {
            res = await axios.delete(`${PromotionService.baseUrl}/${id}`, {
                headers: {
                    'x-access-token': LocalStorageService.getToken(),
                }
            });
        } catch (e) {
            toast.error('Fail to delete promotion, try again later or contact #platform-public');
            throw (e as AxiosError).response;
        }
        toast.success('Promotion deleted');
        return res.data;
    }
}