export class HomepageGame {

    constructor (
        public id: string,
        public name: string,
        public value: string,
        public count: HomepageGameCount,
        public link: HomepageGameLink,
        public published: boolean,
        public target: Array<string>,
        public createdAt: Date,
    ) {

    }
}

export interface HomepageGameCount {
    tournaments: number;
    videos: number;
    trainings: number;
}

export interface HomepageGameLink {
    shop: string;
    tournament: Array<string>;
    training: string;
    videos: string;
}

export enum EHomepageMedia {
    BannerImage = 'BannerImage',
    ProfileImage = 'ProfileImage',
    Thumbnail = 'Thumbnail',
}