import React, { useEffect, useState } from 'react';
import { NCTitle, NCTitleType } from 'src/atoms/NCTitle/NCTitle';
import CircularProgress from '@material-ui/core/CircularProgress';
import { SimpleList } from 'src/components/General/SimpleList/SimpleList';
import { ActionDialog } from 'src/components/General/ActionDialog/ActionDialog';
import { FullScreenDialog } from 'src/components/General/FullScreenDialog/FullScreenDialog';
import { HomepageCarouselService } from 'src/services/homepageCarousel.service';
import { HomepageCarouselModel } from 'src/models/HomepageCarousel';
import { HomepageCarouselForm } from 'src/components/Homepage/HomepageCarousel/HomepageCarouselForm/HomepageCarouselForm';
import { CountryService } from 'src/services/country.service';
import { GroupCountry } from 'src/models/GroupCountry';
import Button from '@material-ui/core/Button';

export const HomepageCarousel: React.FunctionComponent = () => {
    const [ isLoading, setIsLoading ] = useState<boolean>(true);
    const [ actualPage, setActualPage ] = useState<number>(0);
    const [ totalItem, setTotalItem ] = useState<number>(0);
    const [ actionDelete, setActionDelete ] = useState<boolean>(false);
    const [ actionEdit, setActionEdit ] = useState<boolean>(false);
    const [ actionCreate, setActionCreate ] = useState<boolean>(false);
    const [ rows, setRows ] = useState<any>([]);
    const [ carousel, setCarousel ] = useState<HomepageCarouselModel>();
    const columns = [ 'Title', 'Description', 'CountryGroup', 'Published' ];
    const keepRows = [ 'title', 'description', 'countryGroup', 'published' ];

    const [ rowsGroupCountry, setRowsGroupCountry ] = useState([]);
    const [ actualPageGroupCountry, setActualPageGroupCountry ] = useState<number>(0);
    const [ groupCountry, setGroupCountry ] = useState<GroupCountry>();

    const [ totalItemGroupCountry, setTotalItemGroupCountry ] = useState<number>(0);
    const columnsGroupCountry = [ 'Value', 'Countries' ];
    const keepRowsGroupCountry = [ 'value', 'countries' ];

    useEffect(() => {
        CountryService.getGroupCountries(20, actualPageGroupCountry).then((data: any) => {
            setTotalItemGroupCountry(Math.ceil(data.total / 20));
            setRowsGroupCountry(data.docs);
            setIsLoading(false);
            setGroupCountry(new GroupCountry('', '', []));
        }).catch(error => console.log(error));
    }, [ actualPageGroupCountry ]);

    useEffect(() => {
        if (groupCountry) {
            HomepageCarouselService.getHomepageCarousel(20, actualPage, groupCountry.value).then(data => {
                setTotalItem(Math.ceil(data.total / 20));
                setRows(data.docs);
                setIsLoading(false);
            });
        } else {
            HomepageCarouselService.getHomepageCarousel(20, actualPage).then(data => {
                setTotalItem(Math.ceil(data.total / 20));
                setRows(data.docs);
                setIsLoading(false);
            });
        }
    }, [ actualPage, isLoading, groupCountry ]);

    const actionGroupCountriesManager = (groupCountry: GroupCountry, kind: string) => {
        setGroupCountry(groupCountry);
        switch (kind) {
            case 'see':
        }
    };

    const actionCarouselManager = (carousel: HomepageCarouselModel, kind: string) => {
        setCarousel(carousel);
        switch (kind) {
            case 'delete':
                setActionDelete(true);
                break;
            case 'edit':
                setActionEdit(true);
                break;
            case 'create': {
                setCarousel(
                    new HomepageCarouselModel(
                        { en: '' },
                        { en: '' },
                        '',
                        '',
                        '',
                        '',
                        [],
                    )
                );
                setActionCreate(true);
                break;
            }
            default:
                return;
        }
    };

    const deleteNews = (choice: boolean) => {
        if (choice && carousel) {
            HomepageCarouselService.deleteHomepageCarousel(carousel._id).then(data => {
                updateList(data);
            });
        }
    };

    const updateList = (result: boolean) => {
        setActionEdit(false);
        setActionDelete(false);
        setActionCreate(false);
        if (!result) {
            return;
        }
        setIsLoading(true);
        const refresh = actualPage;
        setActualPage(0);
        setActualPage(refresh);
        setGroupCountry(undefined);
    };

    const setAllFilter = () => {
        setGroupCountry(undefined);
    };

    return (
        <div className="d-flex flex-row">
            <div className="NC-container NC-card d-flex flex-column w-25">
                <div className="d-flex flex-row justify-content-between">
                    <NCTitle text={'Group Country'}/>
                    {
                        groupCountry?.value &&
                        <Button
                            className="font-weight-bold"
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                setAllFilter();
                            }}>
                            All
                        </Button>
                    }
                </div>
                <div className="d-flex">
                    {
                        isLoading && <CircularProgress className="mx-auto"/>
                    }
                    {
                        !isLoading &&
                        <>
                            <SimpleList
                                rows={rowsGroupCountry}
                                columns={columnsGroupCountry}
                                keepRows={keepRowsGroupCountry}
                                pagination={{
                                    actual: actualPageGroupCountry,
                                    total: totalItemGroupCountry,
                                    setActual: setActualPageGroupCountry
                                }}
                                actionsButtons={{ see: true }}
                                handleClickOpen={actionGroupCountriesManager}
                            />
                        </>
                    }
                </div>
            </div>
            <div className="NC-container NC-card d-flex flex-column ml-3 w-75">
                {!groupCountry?.value && <NCTitle text={'All Homepages Slides'} type={NCTitleType.TRAINING}/>}
                {groupCountry?.value &&
                <NCTitle text={`${groupCountry.value} Homepage Slides`} type={NCTitleType.TRAINING}/>}
                <div className="d-flex">
                    {
                        isLoading &&
                        <CircularProgress className="mx-auto"/>
                    }
                    {
                        !isLoading &&
                        <SimpleList
                            rows={rows}
                            columns={columns}
                            keepRows={keepRows}
                            actionsButtons={{ create: true, edit: true, delete: true }}
                            handleClickOpen={actionCarouselManager}
                            pagination={{ actual: actualPage, total: totalItem, setActual: setActualPage }}
                        />
                    }
                </div>
                <ActionDialog
                    open={actionDelete}
                    title={'Are you sure to delete this carousel ?'}
                    openHook={setActionDelete}
                    actionHook={deleteNews}
                />
                {
                    carousel &&
                    <FullScreenDialog
                        open={actionEdit}
                        openHook={setActionEdit}
                        actionHook={updateList}
                        componentToDisplay={
                            <HomepageCarouselForm
                                homepageCarouselProps={carousel}
                                actionHook={updateList}
                                groupCountries={rowsGroupCountry}
                            />
                        }
                    />
                }
                {
                    carousel &&
                    <FullScreenDialog
                        open={actionCreate}
                        openHook={setActionCreate}
                        actionHook={updateList}
                        componentToDisplay={
                            <HomepageCarouselForm
                                homepageCarouselProps={carousel}
                                actionHook={updateList}
                                creationMode={true}
                                groupCountries={rowsGroupCountry}

                            />
                        }
                    />
                }
            </div>

        </div>
    );
};