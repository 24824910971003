import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import React, { ChangeEvent, useState } from 'react';
import { useStoreState } from 'src/store';
import TabContext from '@material-ui/lab/TabContext';
import AppBar from '@material-ui/core/AppBar';
import TabList from '@material-ui/lab/TabList';
import { Languages } from 'src/models/Languages';
import Tab from '@material-ui/core/Tab';
import TabPanel from '@material-ui/lab/TabPanel';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { GroupCountry } from 'src/models/GroupCountry';
import { HomepageCarouselModel, HomepageCarouselModelKey } from 'src/models/HomepageCarousel';
import { HomepageCarouselService } from 'src/services/homepageCarousel.service';
import { TranslatedString } from 'src/models/TranslatedString';

interface NewsFormProps {
    homepageCarouselProps: HomepageCarouselModel;
    actionHook: (result: boolean) => void;
    creationMode?: boolean;
    groupCountries: Array<GroupCountry>;
}

export const HomepageCarouselForm: React.FunctionComponent<NewsFormProps> = ({
                                                                                 homepageCarouselProps,
                                                                                 actionHook,
                                                                                 creationMode,
                                                                                 groupCountries,
                                                                             }) => {
    const [ carousel, setCarousel ] = useState<HomepageCarouselModel>(homepageCarouselProps);
    const [ languages ] = useState(useStoreState(state => state.languages));
    const [ langValue, setLangValue ] = useState(useStoreState(state => state.contentLang));
    const [ published, setPublished ] = useState<boolean>(homepageCarouselProps.published || false);
    const [ selectedGroups, setSelectedGroups ] = useState<Array<string>>(homepageCarouselProps.countryGroup);

    const saveNews = () => {
        if (carousel) {
            carousel.countryGroup = selectedGroups;
            carousel.published = published;
            if (!creationMode) {
                HomepageCarouselService.updateHomepageCarousel(carousel).then(data => {
                    data ? actionHook(true) : actionHook(false);
                    return;
                });
            } else {
                HomepageCarouselService.createHomepageCarousel(carousel).then(data => {
                    data ? actionHook(true) : actionHook(false);
                    return;
                });
            }
        }
    };

    const handleTabChange = (event: React.ChangeEvent<HTMLInputElement>, newValue: string) => {
        setLangValue(newValue);
    };

    const onNewsFieldChange = (event: any, isTranslatedString = false, lang = '') => {
        const { name, value } = event.currentTarget;
        if (isTranslatedString) {
            const myCarouselAttr = carousel[name as HomepageCarouselModelKey] as TranslatedString;
            myCarouselAttr[lang] = value;
            setCarousel(carousel);
        } else {
            setCarousel(Object.assign({}, carousel, {
                [name]: value,
            }));
        }
    };

    const onGroupChange = (event: any) => {
        setSelectedGroups(event.target.value);
    };

    return (
        <div className="w-100">
            <div className="w-100 d-flex">

            </div>
            <form className="d-flex flex-column NC-tab">
                <TabContext value={langValue}>
                    <AppBar position="static">
                        <TabList
                            onChange={(event: React.ChangeEvent<any>, langValue: string) => handleTabChange(event, langValue)}
                            aria-label="simple tabs example">
                            {
                                languages.map((lang: Languages) => (
                                    <Tab key={lang._id} label={lang.name} value={lang.code}/>
                                ))
                            }
                        </TabList>
                    </AppBar>
                    {
                        languages.map((lang: Languages) => (
                            <TabPanel className="w-100 p-0" key={lang._id} value={lang.code}>
                                <div className="d-flex background flex-row row mb-2">
                                    <TextField
                                        className="col-12 mb-1 px-3"
                                        name="title"
                                        label={'Title [' + lang.code + ']'}
                                        defaultValue={carousel.title[lang.code]}
                                        onChange={(event: ChangeEvent<HTMLInputElement>) => (onNewsFieldChange(event, true, lang.code))}
                                    />
                                    <TextField
                                        className="col-12 mb-1 px-3"
                                        name="description"
                                        label={'Description [' + lang.code + ']'}
                                        defaultValue={carousel.description[lang.code]}
                                        onChange={(event: ChangeEvent<HTMLInputElement>) => (onNewsFieldChange(event, true, lang.code))}
                                    />


                                </div>
                            </TabPanel>
                        ))
                    }
                </TabContext>
                <div className="row">

                    <FormControl className="col-9 pb-4 px-3">
                        <InputLabel>Group of countries (Visible on)</InputLabel>
                        <Select
                            multiple
                            defaultValue={carousel.countryGroup}
                            onChange={onGroupChange}
                            input={<Input/>}
                            renderValue={(selected) => {
                                return (
                                    <div>
                                        {
                                            (selected as Array<string>).map((group: string, index: number) => {
                                                return selectedGroups.includes(group) ?
                                                    <div className="mx-1" key={index}>{group}</div> :
                                                    ''
                                            })
                                        }
                                    </div>
                                );
                            }}
                        >
                            {
                                groupCountries && groupCountries.map((group: GroupCountry) => (
                                    <MenuItem key={group._id} value={group.value}>
                                        <Checkbox checked={selectedGroups.indexOf(group.value) > -1}/>
                                        <ListItemText primary={group.value}/>
                                    </MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={published}
                                onChange={() => setPublished(!published)}
                                name="Type"
                                color="primary"
                            />
                        }
                        disabled={creationMode}
                        label="Published"
                        className="col-3 mx-0"
                    />
                    <TextField
                        className="col-6 mb-1 pr-3"
                        name="video"
                        label="Video Link (url must finish by mp4 extension)"
                        defaultValue={carousel.video}
                        onChange={(event: ChangeEvent<HTMLInputElement>) => (onNewsFieldChange(event, false))}
                    />
                    <TextField
                        className="col-6 mb-1 pr-3"
                        name="mobileImage"
                        label="Mobile Img (replace video bg on mobile)"
                        defaultValue={carousel.mobileImage}
                        onChange={(event: ChangeEvent<HTMLInputElement>) => (onNewsFieldChange(event, false))}
                    />
                    <TextField
                        className="col-6 mb-1 pr-3"
                        name="image"
                        label="Logo Img (must have transparent bg)"
                        defaultValue={carousel.image}
                        onChange={(event: ChangeEvent<HTMLInputElement>) => (onNewsFieldChange(event, false))}
                    />
                    <TextField
                        className="col-6 mb-1 pr-3"
                        name="link"
                        label="Link Button"
                        defaultValue={carousel.link}
                        onChange={(event: ChangeEvent<HTMLInputElement>) => (onNewsFieldChange(event, false))}
                    />


                </div>
            </form>
            <Button
                variant="contained"
                color="primary"
                className="d-flex mx-auto w-25 my-5"
                onClick={() => {
                    saveNews();
                }}
            >
                Save
            </Button>
            {creationMode &&
            <p className="red text-center font-weight-bold mt-3">
                After creation don t forget to edit to add the media !
            </p>
            }

        </div>
    );
};