import { TranslatedString } from "./TranslatedString";

export class PromotionModel {
    _id: string;
    createdAt: string;
    countries: Array<string>;

    constructor(
        public name: string,
        public image: TranslatedString,
        public description: TranslatedString,
        public buttonLink?: TranslatedString,
        public buttonText?: TranslatedString,
        public displayOnHomepage?: boolean,
        public regions?: Array<string>,
        public organization?: string,
        public tournamentId?: string,
    ) {
    }
}