import axios, { AxiosError } from 'axios';

export class LangService {
    private static baseUrl: string = String(process.env.REACT_APP_ADMINAPI_URL);
    static token = (): string | null => localStorage.getItem('accessToken');

    static async getLanguages(): Promise<any> {
        try {
            const res = await axios.get(`${LangService.baseUrl}/languages`, {
                headers: {
                    'x-access-token': LangService.token(),
                }
            });
            return res.data;
        } catch (e) {
            throw (e as AxiosError).response;
        }
    }
}