import React, {useEffect, useState} from 'react';
import {TrainingService} from 'src/services/training.service';
import './ProgramList.scss';
import {TrainingProgram} from 'src/models/TrainingProgram';
import {Game} from 'src/models/Game';
import {GameService} from 'src/services/game.service';
import CircularProgress from '@material-ui/core/CircularProgress';
import {TrainingCard} from 'src/atoms/TrainingCard/TrainingCard';

export const ProgramList: React.FunctionComponent = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [programList, setProgramList] = useState<Array<TrainingProgram>>([]);

    const [gameList, setGameList] = useState<Array<Game>>([]);

    useEffect(() => {
        getPrograms();
        get360Games();
        setIsLoading(false);
    }, []);

    const getPrograms = () => {
        TrainingService.getPrograms(50, 0).then((data) => {
            setProgramList(data.docs);
        });
    };

    const get360Games = () => {
        GameService.get360Games().then((data) => {
            setGameList(data);
        });
    };

    return (
        <>
            {
                isLoading && <CircularProgress className="mx-auto"/>
            }
            {
                !isLoading &&
                <div className="w-100 d-flex flex-column program-list">
                    {
                        gameList && gameList.map((game: Game, index: number) => {
                            return (
                                <div key={index}>
                                    <div className="select-image-game">
                                        <img alt="game"
                                             src={`${process.env.REACT_APP_S3_URL}/game/${game._id}/medias/SelectImage`}/>
                                    </div>
                                    <div className="d-flex flex-row list-container NC-info mb-3 mt-1">
                                        {
                                            programList && programList.map((program: TrainingProgram, index: number) => {
                                                if (program.game === game._id) {
                                                    return <TrainingCard program={program} key={index}/>;
                                                }
                                                return null;
                                            })
                                        }
                                    </div>
                                </div>
                            );
                        })
                    }

                </div>

            }
        </>
    );
};