import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import React, {useState} from 'react';
import {AdministratorService} from 'src/services/administrator.service';
import {useStoreState} from 'src/store';
import './AdministratorSetting.scss';
import {LangPicker} from 'src/components/General/LangPicker/LangPicker';
import {NCTitle} from 'src/atoms/NCTitle/NCTitle';
import {toast} from 'react-toastify';

export const AdministratorSetting: React.FunctionComponent = () => {
    const [newPassword, setNewPassword] = useState<string>('');
    const [confirmNewPassword, setConfirmNewPassword] = useState<string>('');
    const administratorId = useStoreState(state => state.currentAdministrator?._id);

    const changePassword = async (): Promise<void> => {
        if (!administratorId) {
            return;
        }
        AdministratorService.updatePassword(administratorId, newPassword, confirmNewPassword).then(data => {
            setConfirmNewPassword('');
            setNewPassword('');
            if (!data) {
                return;
            }
            toast.success('Password changed');
        });
    };

    return (
        <div className="NC-container NC-card d-flex">
            <NCTitle text={'Settings'}/>
            <div className="row w-100">
                <div className="m-auto d-flex flex-column col-md-6 col-12">
                    <TextField
                        name="newPassword"
                        value={newPassword}
                        type="password"
                        label="New password"
                        className="m-2"
                        onChange={(event: any) => {
                            setNewPassword(event.currentTarget.value);
                        }}
                    />
                    <TextField
                        name="confirmNewPassword"
                        value={confirmNewPassword}
                        label="Confirm new password"
                        type="password"
                        className="m-2"
                        onChange={(event: any) => {
                            setConfirmNewPassword(event.currentTarget.value);
                        }}
                    />
                    <div className="red-color my-2">*Your password must contain at least 8 characters, one uppercase,
                        one digit and no specials characters
                    </div>
                    <Button
                        variant="contained"
                        color="primary"
                        className="w-50 m-auto"
                        onClick={() => changePassword()}
                    >
                        Change password
                    </Button>
                </div>
                <div className="col-md-6 col-12 justify-content-center d-flex align-items-center">
                    <LangPicker title={'Website Language'} websiteLanguage={true}/>
                </div>
            </div>
        </div>
    );
};