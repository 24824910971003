import {
    CircularProgress,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from '@material-ui/core';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { NCPlatformIcons } from '../../../atoms/NCPlatformIcons/NCPlatformIcons';
import { Tournament } from '../../../models/Tournament';
import { NtService } from '../../../services/nt.service';
import './UserTournaments.scss';
import { User } from '@wolfiesports/srm-component';

interface UserTournamentsProps {
    user: User;
}

export const UserTournaments: React.FunctionComponent<UserTournamentsProps> = (
    props: UserTournamentsProps
) => {
    const { userId } = props.user;
    const [isLoading, setIsLoading] = useState(true);
    const [tournaments, setTournaments] = useState<Array<Tournament>>([]);
    const goToIconUrl = `url(${process.env.REACT_APP_S3_URL}/media/icons/goToLink.svg)`;

    useEffect(() => {
        const getTournaments = async () => {
            setIsLoading(true);
             NtService.getRegisteredTournamentsByUser(userId)
                .then((data) => {
                    setTournaments(data.list);
                    setIsLoading(false);
                })
                .catch(error => console.log(error));
        };
        getTournaments();
    }, [userId]);

    return (
        <div className="NC-card user-tournaments-container d-flex flex-column">
        {isLoading
            ? <CircularProgress className="mx-auto" />
            : <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell align="left">Name</TableCell>
                            <TableCell align="left">Date</TableCell>
                            <TableCell align="left">Game</TableCell>
                            <TableCell align="left">Platform</TableCell>
                            <TableCell align="left"></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {tournaments.length > 0 
                            ? tournaments.map((tournament: Tournament) => {
                                const { id, name, date, platforms, gameSlug } = tournament;
                                return (
                                    <TableRow key={name}>
                                        <TableCell align="left" className="text-uppercase font-weight-bold" >
                                            {name}
                                        </TableCell>
                                        <TableCell align="left">
                                            {moment.unix(date).format('M/D/YYYY H:mm').toString()}
                                        </TableCell>
                                        <TableCell align="left" size="small">
                                            <div className="game">
                                                <img 
                                                    className="game-icon"
                                                    alt={gameSlug}
                                                    src={`${process.env.REACT_APP_S3_URL}/media/games/${gameSlug}/LogoImage`}
                                                />
                                            </div>
                                        </TableCell>
                                        <TableCell align="left">
                                            <NCPlatformIcons platforms={platforms} />
                                        </TableCell>
                                        <TableCell align="center" className="d-flex justify-content-around">
                                            <Link to={`/tournaments/beta/details/${id}/management/general`}>
                                                <div className="icon"
                                                    style={{
                                                        maskImage: goToIconUrl,
                                                        WebkitMaskImage: goToIconUrl
                                                    }}
                                                />
                                            </Link>
                                        </TableCell>
                                    </TableRow>
                                );
                            })
                            : <TableRow>
                                <TableCell align="left">No data</TableCell>
                            </TableRow>
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        }
        </div>
    );
};