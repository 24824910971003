import React, { useEffect, useState } from 'react';
import { List } from '@material-ui/core';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Button from '@material-ui/core/Button';
import { NGGameService } from 'src/services/nggame.service';
import { NoGame } from 'src/models/Game';
import { NGUserService } from 'src/services/nguser.service';
import { User } from '@wolfiesports/srm-component';

interface UserGamesProps {
    user: User;
}

export const UserGames: React.FunctionComponent<UserGamesProps> = (props: UserGamesProps) => {
    const [selectedGames, setSelectedGames] = useState<string[]>([]);
    const [allGames, setAllGames] = useState(new Array<NoGame>);
    const [isGamesFetched, setIsGamesFetched] = useState(false);

    const [edit, setEdit] = useState<boolean>(false);

    useEffect(() => {
        if (!isGamesFetched) {
            getGames();
        }
    }, [isGamesFetched]);

    const getGames = async () => {
        if(!props.user){
            return;
        }
        
        try {
            const publicGamesRes = await NGGameService.getGames();
            setAllGames(publicGamesRes.list);
            const userGamesRes = await NGUserService.getUserGames(props.user.userId);
            const userGameRoutes = userGamesRes.list.map((game: any) => game.route);
            const filteredGames = publicGamesRes.list.filter((game: any) => {
                return userGameRoutes.includes(game.route + "/" + game.id);
            });

            setSelectedGames(filteredGames.map((game: any) => game.id));
        } catch (error) {
            console.error("Failed to fetch games:", error)
        }
        setIsGamesFetched(true);
    }

    const toggleGameSelection = (game: string) => () => {
        setSelectedGames((prevSelectedGames) => {
          if (prevSelectedGames.includes(game)) {
            return prevSelectedGames.filter(
              (selectedGame) => selectedGame !== game
            );
          } else {
            return [...prevSelectedGames, game];
          }
        });
        setEdit(true);
      };

    const saveGames = async () => {
        await NGUserService.updateUserGames(props.user.userId, selectedGames)
    };

    const updateUser = () => {
        saveGames();
        setEdit(false);
    };

    return (
        <div className="NC-card d-flex flex-column">
            {
                edit &&
                <Button className="w-25 mx-auto my-2" variant="contained" color="primary"
                    onClick={updateUser}>Edit</Button>
            }
            <List className="w-100">
                {allGames.map((game: any) => {
                    return (
                        <ListItem key={game.id} role={undefined} dense button onClick={toggleGameSelection(game.id)}>
                            <ListItemText id={game.id} primary={`${game.title}`} />
                            <ListItemSecondaryAction>
                                <ListItemIcon>
                                    <Checkbox
                                        onClick={toggleGameSelection(game.id)}
                                        edge="start"
                                        checked={selectedGames.includes(game.id)}
                                        tabIndex={-1}
                                        disableRipple
                                        color="primary"
                                        inputProps={{ 'aria-labelledby': game.id }}
                                    />
                                </ListItemIcon>
                            </ListItemSecondaryAction>
                        </ListItem>
                    );
                })}
            </List>
        </div>
    );
};