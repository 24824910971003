import { CircularProgress, MenuItem, TextField } from '@material-ui/core';
import cc from 'classcat';
import getSymbolFromCurrency from 'currency-symbol-map';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Transaction, TransactionKind, TransactionStatus, TransactionStatusToString } from 'src/models/Transaction';
import { SimpleList } from '../../components/General/SimpleList/SimpleList';
import { LastKey } from '../../models/Common';
import { Currency } from '../../models/Currency';
import { Wallet } from '../../models/Wallet';
import { CurrencyService } from '../../services/currency.service';
import { TransactionService } from '../../services/transaction.service';
import './TransactionList.scss';
import { NCDialog } from '@wolfiesports/srm-component';
import { CancelTournamentModal } from '../../components/CancelTournamentModal/CancelTournamentModal';


export interface FilterStatus {
    status: TransactionStatus;
}

export interface FilterWallet {
    wallet: Wallet;
}

export type Filter = FilterStatus | FilterWallet;

export interface TransactionListProps {
    filter: Filter;
    limit?: number;
    showStatusFilter?: boolean;
}

export const TransactionList: React.FunctionComponent<TransactionListProps> = ({
    filter,
    limit = 20,
    showStatusFilter = true
}) => {
    const intl = useIntl();
    const [isLoading, setIsLoading] = useState(true);
    const [isRefreshing, setIsRefreshing] = useState(true);
    const [rows, setRows] = useState<Array<Transaction>>([]);
    const [lastKeys, setLastKeys] = useState<{ [page: number]: LastKey | undefined }>({});
    const [actualPage, _setActualPage] = useState<number>(0);
    const [totalItem, setTotalItem] = useState<number>(0);
    const [currencies, setCurrencies] = useState<Array<Currency>>([]);
    const columns = ['Date', 'User', 'Kind', 'Source', 'Amount', 'State', 'Validated', 'Premium'];
    const keepRows = ['date', 'userFormatted', 'kind', 'detail.title', 'detail.value', 'status', 'approuved', 'userStatus'];
    const [selectedStatus, setSelectedStatus] = useState<TransactionStatus | undefined>(TransactionStatus.Validating);
    const [selectedCurrency, setSelectedCurrency] = useState<Currency | undefined>(undefined);

    const [openCancelModal, setOpenCancelModal] = useState<boolean>(false);
    const [selectedTransaction, setSelectedTransaction] = useState<Transaction>();

    useEffect(() => {
        setIsRefreshing(true);

        getCUrrencies();
        setRows([]);
        if (selectedStatus === undefined) {
            getTransactions();
        } else {
            getTransactionsByStatus();
        }
        // eslint-disable-next-line
    }, [actualPage, selectedStatus, selectedCurrency, filter]);

    const setActualPage = (page: number) => {
        _setActualPage(page);
    };

    const getTransactionsByStatus = async () => {
        const lk = lastKeys[actualPage - 1];
        let req;
        if ((filter as FilterStatus).status !== undefined && selectedStatus !== undefined) {
            req = TransactionService.getTransactionsByStatusAndKind(selectedStatus, TransactionKind.WithdrawCash, selectedCurrency, limit, lk);
        } else if ((filter as FilterWallet).wallet !== undefined) {
            req = TransactionService.getTransactionsByWallet((filter as FilterWallet).wallet, limit, lk);
        } else {
            return;
        }

        let data;
        try {
            data = await req;
        } catch (err) {
            console.log(err);
            return;
        } finally {
            setIsLoading(false);
            setIsRefreshing(false);
        }

        const newLK = data.lastKey?.route ? data.lastKey : undefined;
        setLastKeys({...lastKeys, [actualPage]: newLK});
        setTotalItem(limit * actualPage + data.list.length + (newLK ? limit : 0));
        setRows(data.list);
    };

    const getTransactions = async () => {
        const lk = lastKeys[actualPage - 1];
        const req = TransactionService.getTransactions(limit, lk);
        let data;

        try {
            data = await req;
        } catch (err) {
            console.log(err);
            return;
        } finally {
            setIsLoading(false);
            setIsRefreshing(false);
        }
        const newLK = data.lastKey?.route ? data.lastKey : undefined;
        setLastKeys({...lastKeys, [actualPage]: newLK});
        setTotalItem(limit * actualPage + data.list.length + (newLK ? limit : 0));
        setRows(data.list);
    };

    const getCUrrencies = async () => {
        const curs = await CurrencyService.getAllCurrencies();
        setCurrencies(curs.filter(c => c.active));
    };

    const handleClickOpen = (transaction: Transaction, kind: string) => {
        switch (kind) {
            case 'see':
                window.open(`/withdrawal/${transaction.owner}/${transaction.route}`, '_blank');
                break;
            case 'cancel':
                setSelectedTransaction(transaction);
                setOpenCancelModal(!openCancelModal);
                break;
            default:
                return;
        }
    };

    const onStatusChange = (value: TransactionStatus) => {
        setSelectedStatus(value);
    };

    const onCurrencyChange = (symbol: string | undefined) => {
        const cur = symbol ? currencies.find(c => c.symbol === symbol) : undefined;
        setSelectedCurrency(cur);
    };

    const formatRows = () => {
        const tmp: any = rows;
        tmp.forEach((row: any) => {
            if (row.user) {
                row.userFormatted = `${row.user.name}#${row.user.code}`;
                row.userStatus = row.user.status;
            }
        });

        return tmp;
    };

    const getStatusClassName = (status: TransactionStatus) => {
        switch (status) {
            case TransactionStatus.Validating:
                return 'status-validating';
            case TransactionStatus.Transiting:
                return 'status-transiting';
            case TransactionStatus.Delivered:
                return 'status-delivered';
            case TransactionStatus.Cancelled:
                return 'status-cancelled';
            case TransactionStatus.Unprocessable:
                return 'status-unprocessable';
            default:
                return '';
        }
    };

    const renderCell = (cell: any, key: string, row: Transaction) => {
        switch (key) {
            case 'benefactor':
                if (row.kind?.startsWith('admin/')) {
                    return <span className="text-admin">[ADMIN]</span>;
                }
                return <>{cell}</>;
            case 'status':
                return <span
                    className={cc(['text-uppercase', 'transition-status', getStatusClassName(row.status)])}>{TransactionStatusToString(cell)}</span>;
            case 'date':
                return <>{moment(cell).format('LLLL')}</>;
            case 'detail.value':
                return <>{cell}{(row.detail.cur && getSymbolFromCurrency(row.detail.cur)) || row.detail.cur}</>;
            case 'kind':
                return <>{intl.formatMessage({id: cell})}</>;
            case 'user.status':
                return <>{cell === 'PREMIUM' ? cell : '-'}</>
            default:
                return null;
        }
    };

    const handleModalChange = async (validated: boolean, reason?: string) => {
        setOpenCancelModal(false);
        if (validated && selectedTransaction) {
            await TransactionService.declineTransaction(selectedTransaction, reason || '');
        }
    }

    if (isLoading) {
        return <CircularProgress className="mx-auto"/>;
    }

    return (
        <div className="d-flex flex-column w-100">
            {
                showStatusFilter &&
                <div>
                    <TextField
                        select
                        label="Select a status"
                        value={selectedStatus}
                        onChange={(event) => {
                            onStatusChange(event.target.value as any);
                        }}
                        className="col-12 col-md-3"
                    >
                        <MenuItem value={undefined}>All</MenuItem>
                        {
                            Object.keys(TransactionStatus).map((status, index) => {
                                if (!isNaN(Number(status))) {
                                    return (
                                        <MenuItem key={index} value={status}>
                                            {Number(status)}
                                        </MenuItem>
                                    );
                                } else {
                                    return null;
                                }
                            })
                        }
                    </TextField>
                    <TextField
                        select
                        label="Select a currency"
                        value={selectedCurrency?.symbol}
                        onChange={(event) => {
                            onCurrencyChange(event.target.value);
                        }}
                        className="ml-0 ml-md-2 col-12 col-md-3"
                    >
                        <MenuItem value={undefined}>All</MenuItem>
                        {
                            currencies.map((currency, index) => {
                                return (
                                    <MenuItem key={index} value={currency.symbol}>
                                        {currency.symbol}
                                    </MenuItem>
                                );
                            })
                        }
                    </TextField>
                </div>
            }
            {
                isRefreshing ?
                    <CircularProgress className="mx-auto my-4"/>
                    :
                    <SimpleList
                        rows={formatRows}
                        columns={columns}
                        keepRows={keepRows}
                        actionsButtons={{see: true, cancel: true}}
                        handleClickOpen={handleClickOpen}
                        pagination={{actual: actualPage, total: Math.ceil(totalItem / limit), setActual: setActualPage}}
                        renderCell={renderCell}
                    />
            }
            {
                <NCDialog
                    show={openCancelModal}
                    setShow={setOpenCancelModal}
                    title="Cancel transaction"
                    wildBody={true}
                >
                    <CancelTournamentModal
                        title="Are you sure you want to cancel this transaction?"
                        name={selectedTransaction?.detail.title}
                        actionHook={handleModalChange}
                        forceCustom
                    />
                </NCDialog>
            }
        </div>
    );
};
