import React  from 'react';
import { Link } from '@material-ui/core';
import { TrainingProgram } from 'src/models/TrainingProgram';
import './TrainingCard.scss';
import { CategorizedButton, CategorizedButtonVariant } from '../CategorizedButton/CategorizedButton';

interface TrainingCardProps {
    program: TrainingProgram;
}

export const TrainingCard: React.FunctionComponent<TrainingCardProps> = ({program}) => {

	return (
		<div className="training-card" key={ program._id }>
			<div className="NC-item-card d-flex justify-content-between" style={{ height: '100%' }}>
				<div className="header"  style={{ backgroundImage: `url(${process.env.REACT_APP_S3_URL}/training/program/${program._id}/medias/Cover)` }}>
					<div className="title d-flex align-items-start">
						<img src={`${process.env.REACT_APP_S3_URL}/game/${program.game}/medias/IconImage`} alt="game-logo" />
						<span className="ml-2 mt-1">{ program.name['en']}</span>
					</div>
				</div>
				<div className="footer d-flex justify-content-between flex-column">
					<div className="info mb-3 d-flex flex-wrap">
						<div className="d-flex align-items-center mr-auto">
							<div className="level icon"></div>
							<span className="ml-2">{ program.level }</span>
						</div>
						<div className="d-flex align-items-center mr-auto">
							<div className="calendar icon"></div>
							<span className="ml-2">{ program.estimatedDuration['en'] }</span>
						</div>
					</div>
					<Link href={`/training/program/${program._id}`} className="d-flex justify-content-center">
						<CategorizedButton
							text={'Manage this Program'}
							variant={CategorizedButtonVariant.TRAINING}
						/>
					</Link>
				</div>
			</div>
		</div>
	);
};