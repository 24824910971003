import './FileManagerForm.scss';

import { Dialog, DialogContent, DialogTitle, IconButton, Tooltip, TextField, Button } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { SimpleList } from '../General/SimpleList/SimpleList';
import { MediaLibraryService } from 'src/services/media-library.service';
import { CreateNewFolder, Delete, KeyboardArrowLeft, Publish } from '@material-ui/icons';
import { FileManagerMode, Media } from 'src/models/MediaLibrary';
import { CreateFolderForm } from './CreateFolderForm/CreateFolderForm';
import { NCTitle, NCTitleType } from 'src/atoms/NCTitle/NCTitle';
import { DeleteFileForm } from './DeleteFileForm/DeleteFileForm';
import { toast } from 'react-toastify';

interface FileManagerFormProps {
    openDialog?: boolean;
    mode: FileManagerMode;
    setOpenDialog?: (isOpen: boolean) => void;
    setPublicUrl?: (url: string) => void;
}

export const FileManagerForm: React.FunctionComponent<FileManagerFormProps> = (props: FileManagerFormProps) => {
    const columns = [ '', 'Name', 'Size', 'Last updated' ];
    const keepRows = [ 'file', 'name', 'size', 'modified' ];
    const authorizedFiles = [ 'image/gif', 'image/jpeg', 'image/png', 'image/svg+xml' ];

    const [ medias, setMedias ] = useState<Array<Media>>([]);
    const [ actualPage, setActualPage ] = useState<number>(0);
    const [ totalItem ] = useState<number>(0);
    const [ selectedItem, setSelectedItem ] = useState<Media>();
    const [ currentPath, setCurrentPath ] = useState<string>('/');
    const [ paths, setPaths ] = useState<Array<string>>([ currentPath ]);
    const [ createFolderModal, setCreateFolderModal ] = useState<boolean>(false);
    const [ deleteFileModal, setDeleteFileModal ] = useState<boolean>(false);
    const [ fileFormat, setFileFormat ] = useState<{width: number, height: number}>({ width: 0, height: 0 })

    useEffect(() => {
        getMediaLibrary();
        // eslint-disable-next-line
    }, []);

    const getMediaLibrary = (path: string = '') => {
        MediaLibraryService.getMediaLibrary(path).then((data: Array<Media>) => {
            filterMedia(data);
        }).catch(error => console.log(error));
    }

    const filterMedia = (medias: Array<Media>) => {
        setMedias(medias.sort(f => f.file ? 1 : -1));
    }

    const close = () => {
        if (props.setOpenDialog) {
            props.setOpenDialog(false);
        }
    }

    const handleClickLine = (media: Media) => {
        setSelectedItem(media);
        if (!media.file) {
            const newPath = `${media.key}`;
            setCurrentPath(newPath);
            getMediaLibrary(newPath);
            paths.push(newPath);
            setPaths(paths);
        }
    }

    const handleBackPath = () => {
        if (currentPath !== '/') {
            paths.pop();
            setCurrentPath(paths[paths.length - 1]);
            getMediaLibrary(paths[paths.length - 1]);
        }
    }

    const createMedia = (name: string) => {
        MediaLibraryService.createMedia(`${currentPath}${name}/`, '').then(() => {
            getMediaLibrary(currentPath);
        });
    }

    const uploadMedia = (event: any) => {
		const file = event.target.files[0];
        if (file && authorizedFiles.includes(file.type)) {
            const reader = new FileReader();
            reader.onload = (event: any) => {
                MediaLibraryService.createMedia(`${currentPath}${file.name}`, event.target.result.toString()).then(() => {
                    getMediaLibrary(currentPath);
                });
            };
            reader.readAsDataURL(file);
        } else {
            toast.error('You can only upload images');
        }
	};

    const deleteMedia = () => {
        if (selectedItem) {
            MediaLibraryService.deleteMedia(selectedItem.key).then(() => {
                selectedItem.file ? getMediaLibrary(currentPath) : handleBackPath();
                setSelectedItem(undefined);
            });
        }
    }

    const convertBytes = (bytes: any) => {
        const sizes = [ "bytes", "kB", "MB", "GB", "TB" ];

        if (bytes === 0) {
            return "n/a"
        }

        const i = Number(Math.floor(Math.log(bytes) / Math.log(1024)));

        if (i === 0) {
            return bytes + " " + sizes[i]
        }

        return (bytes / Math.pow(1024, i)).toFixed(1) + " " + sizes[i];
    }

    const TooltipsPath = () => {
        return (
            <div className="d-flex justify-content-start">
                <Tooltip title="Previous path">
                    <IconButton aria-label="previous path" onClick={() => {handleBackPath()}} disabled={currentPath === '/'}>
                        <KeyboardArrowLeft />
                    </IconButton>
                </Tooltip>
            </div>
        );
    }

    const TooltipsMedia = () => {
        return (
            <div className="d-flex justify-content-end">
                <Tooltip title="Create new folder">
                    <IconButton aria-label="create new fodler" onClick={() => {setCreateFolderModal(true)}}>
                        <CreateNewFolder />
                    </IconButton>
                </Tooltip>
                <Tooltip title="File upload">
                    <IconButton component="label">
                        <Publish />
                        <input type="file" accept={authorizedFiles.toString()} hidden onChange={uploadMedia} />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Delete">
                    <IconButton aria-label="delete" onClick={() => {setDeleteFileModal(true)}} disabled={!selectedItem}>
                        <Delete/>
                    </IconButton>
                </Tooltip>
            </div>
        );
    }

    const selectItem = () => {
        if (props.setPublicUrl && selectedItem && selectedItem.publicUrl) {
            props.setPublicUrl(`${process.env.REACT_APP_S3_URL}${selectedItem.publicUrl}`);
            close();
        }
    }

    const Content = () => {
        return (
        <React.Fragment>
             <div className="w-50 h-100">
                <div className="d-flex justify-content-between align-items-center">
                    <div className="d-flex align-items-center">
                        <TextField className="path" value={currentPath} InputProps={{ readOnly: true }} />
                        {TooltipsPath()}
                    </div>
                    {TooltipsMedia()}
                </div>
                <div className="list-media">
                    <SimpleList
                        rows={medias}
                        columns={columns}
                        keepRows={keepRows}
                        pagination={{actual: actualPage, total: totalItem, setActual: setActualPage}}
                        actionsButtons={{file: true}}
                        handleClickLine={handleClickLine}
                    />
                </div>
            </div>
            <div className="preview w-50 d-flex justify-content-center align-items-center">
                {
                    selectedItem && selectedItem.file ?
                        <div className="d-flex flex-column">
                            <div className="d-flex justify-content-center">
                                <img className="mw-100" src={`${process.env.REACT_APP_S3_URL}${selectedItem.publicUrl}`} alt={selectedItem.name}
                                    onLoad={(event) => setFileFormat({ width: event.currentTarget.naturalWidth, height: event.currentTarget.naturalHeight})} />
                            </div>
                            <div className="d-flex justify-content-between mt-5">
                                <span className="mx-2">Extension: {selectedItem.name.split('.')[1]?.toUpperCase() ?? 'none'}</span>
                                <span className="mx-2">Format: {fileFormat.width} x {fileFormat.height}</span>
                                <span className="mx-2">Size: {convertBytes(selectedItem.size)}</span>
                            </div>
                            {props.mode === FileManagerMode.SELECT &&
                                <Button
                                    variant="text"
                                    onClick={() => selectItem()}
                                    color="primary"
                                    className="position-absolute select-button">
                                    Select
                                </Button>
                            }
                        </div>
                    :
                        <span className="d-flex justify-content-center align-items-center h-100">Select a file to display</span>
                }
            </div>

            <CreateFolderForm openDialog={createFolderModal} setOpenDialog={setCreateFolderModal} actionHook={createMedia} />
            {
                selectedItem && selectedItem.name ?
                    <DeleteFileForm openDialog={deleteFileModal} name={selectedItem.name} setOpenDialog={setDeleteFileModal} actionHook={deleteMedia} />
                :
                    null
            }
        </React.Fragment>
    )};

    return (
        <React.Fragment>
        {
            props.mode !== FileManagerMode.NORMAL &&
                <Dialog className="file-manager" open={props.openDialog || false} onClose={() => close()} fullWidth={true} aria-labelledby="form-dialog-title">
                    <div className="d-flex justify-content-between">
                        <DialogTitle id="form-dialog-title">File Manager</DialogTitle>
                    </div>
                    <DialogContent className="d-flex">
                        {Content()}
                    </DialogContent>
                </Dialog >
        }
            {
                props.mode === FileManagerMode.NORMAL &&
                <div className="file-manager NC-container NC-card d-flex flex-column">
                    <NCTitle text={'File Manager'} type={NCTitleType.TRAINING}/>
                    <div className="d-flex">
                        {Content()}
                    </div>
                </div>
        }
        </React.Fragment>
    );
};