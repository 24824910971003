import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import useTheme from '@material-ui/core/styles/useTheme';
import Switch from '@material-ui/core/Switch';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import TextField from '@material-ui/core/TextField';
import React, {useEffect, useState} from 'react';
import {toast} from 'react-toastify';
import {EGamePageImage, EnableGamePage, GamePage, GamePageKey} from 'src/models/GamePage';
import {TabPanel} from 'src/atoms/TabPanel/TabPanel';
import {Game} from 'src/models/Game';
import {GamePageService} from 'src/services/gamePage.service';
import {MediaManager} from 'src/components/General/MediaManager/MediaManager';

interface GamePageFormProps {
    gamePageProps: GamePage;
    actionHook: any;
    creationMode?: boolean;
    gameList: Array<Game>;
}

export const GamePageForm: React.FunctionComponent<GamePageFormProps> = ({
                                                                             gamePageProps,
                                                                             actionHook,
                                                                             creationMode,
                                                                             gameList
                                                                         }) => {
    const [gamePage, setGamePage] = useState<GamePage>();
    const [change, setChange] = useState<boolean>(false);
    const [edit, setEdit] = useState<boolean>(false);
    const [value, setValue] = useState<number>(0);
    const theme = useTheme();

    useEffect(() => {
        if (!gamePage) {
            if (!creationMode) {
                GamePageService.getGamePages(1, 0, `&_id=${gamePageProps._id}`).then(data => {
                    if (!data.docs[0]) {
                        actionHook(false);
                        return;
                    }
                    setGamePage(data.docs[0]);
                });
            } else {
                setGamePage(
                    new GamePage(
                        '',
                        new Date(),
                        {id: '', slug: ''},
                        {enable: false, challengeId: ''},
                        {enable: false, url: ''},
                        {enable: false},
                        {enable: false},
                        {enable: false},
                        false,
                    )
                );
            }

        }

        setChange(false);
    }, [change, gamePageProps._id, actionHook, gamePage, creationMode]);


    const a11yProps = (index: any) => {
        return {
            id: `full-width-tab-${index}`,
            'aria-controls': `full-width-tabpanel-${index}`,
        };
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>, newValue: number) => {
        setValue(newValue);
    };

    const saveGamePage = () => {
        if (gamePage) {
            if (edit) {
                GamePageService.updateGamePage(gamePage._id, gamePage).then(data => {
                    if (!data) {
                        toast.error('Failed to update game page');
                        actionHook(false);
                        return;
                    }
                    toast.success('Game page updated');
                    actionHook(true);
                });
            } else {
                GamePageService.createGamePage(gamePage).then(data => {
                    if (!data) {
                        toast.error('Failed to create game page');
                        actionHook(false);
                        return;
                    }
                    toast.success('Game page created');
                    actionHook(true);
                });
            }
        }
    };

    const onGamePageChange = (event: any, optionalArgument: any = undefined) => {
        if (!gamePage) {
            return;
        }
        const name = event.currentTarget.name;
        let value = event.currentTarget.value;
        if (optionalArgument !== undefined) {
            value = optionalArgument;
            if (name ==='active') {
                gamePage.active = value;
            } else {
                const myEnableGame = gamePage[name as GamePageKey] as EnableGamePage;
                if(myEnableGame) {
                    myEnableGame.enable = value;
                }
            }
        } else {
            if (name === 'twitterUrl') {
                gamePage.twitter.url = value;
            }
        }
        setGamePage(gamePage);
        setChange(true);
    };

    const handleSelectedGameChange = (event: any) => {
        if (!gamePage) {
            return;
        }
        const selectedGame = gameList.find(g => g._id === event.target.value);
        if (!selectedGame) {
            return;
        }
        gamePage.game.id = selectedGame._id;
        gamePage.game.slug = selectedGame.slug;
        setGamePage(gamePage);
        setChange(true);
    };

    return (
        <div className="w-100 NC-tab">
            <AppBar position="static" color="default">
                <Tabs
                    value={value}
                    onChange={(event: React.ChangeEvent<any>, newValue: any) => handleChange(event, newValue)}
                    variant="fullWidth"
                >
                    <Tab label="Infos" {...a11yProps(0)} />
                    <Tab label="Medias" {...a11yProps(1)} />
                </Tabs>
            </AppBar>
            <div className="w-100 d-flex">
                <div className="w-50">
                    {
                        !creationMode &&
                        <FormControlLabel
                            control={<Switch color="primary" checked={edit} onChange={() => setEdit(!edit)}/>}
                            label="Edit"
                            labelPlacement="start"
                        />
                    }

                </div>
                <div className="w-50">
                    {
                        (edit || creationMode) &&
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                saveGamePage();
                            }}
                        >
                            Save
                        </Button>
                    }
                </div>
            </div>
            {
                gamePage &&
                <React.Fragment>
                    <TabPanel value={value} index={0} dir={theme.direction}>
                        <div className="w-100 p-4">
                            <div className="d-flex mb-3">
                                {
                                    !creationMode &&
                                    <TextField
                                        disabled
                                        label="ID"
                                        name="_id"
                                        value={gamePage._id ? gamePage._id : ''}
                                        className="w-50"
                                    />
                                }
                                <div className="w-50 ml-3">
                                    <FormControlLabel
                                        value="start"
                                        disabled={!edit && !creationMode}
                                        control={
                                            <Switch
                                                color="primary"
                                                checked={gamePage.challenge.enable}
                                                name="challenge"
                                                onChange={(event: any) => {
                                                    onGamePageChange(event, !gamePage.challenge.enable);
                                                }}
                                            />
                                        }
                                        label="Challenge"
                                        labelPlacement="start"
                                    />
                                    <FormControlLabel
                                        disabled={!edit && !creationMode}
                                        control={
                                            <Switch
                                                color="primary"
                                                checked={gamePage.twitter.enable}
                                                name="twitter"
                                                onChange={(event: any) => {
                                                    onGamePageChange(event, !gamePage.twitter.enable);
                                                }}
                                            />
                                        }
                                        label="Twitter"
                                        labelPlacement="start"
                                    />
                                    <FormControlLabel
                                        disabled={!edit && !creationMode}
                                        control={
                                            <Switch
                                                color="primary"
                                                checked={gamePage.tv.enable}
                                                name="tv"
                                                onChange={(event: any) => {
                                                    onGamePageChange(event, !gamePage.tv.enable);
                                                }}
                                            />
                                        }
                                        label="TV"
                                        labelPlacement="start"
                                    />
                                    <FormControlLabel
                                        value="start"
                                        disabled={!edit && !creationMode}
                                        control={
                                            <Switch color="primary"
                                                    checked={gamePage.tournament.enable}
                                                    name="tournament"
                                                    onChange={(event: any) => {
                                                        onGamePageChange(event, !gamePage.tournament.enable);
                                                    }}
                                            />
                                        }
                                        label="Tournament"
                                        labelPlacement="start"
                                    />
                                    <FormControlLabel
                                        value="start"
                                        disabled={!edit && !creationMode}
                                        control={
                                            <Switch
                                                color="primary"
                                                checked={gamePage.training.enable}
                                                name="training"
                                                onChange={(event: any) => {
                                                    onGamePageChange(event, !gamePage.training.enable);
                                                }}
                                            />
                                        }
                                        label="Training"
                                        labelPlacement="start"
                                    />
                                    <FormControlLabel
                                        value="start"
                                        disabled={!edit && !creationMode}
                                        control={
                                            <Switch
                                                color="primary"
                                                checked={gamePage.active}
                                                name="active"
                                                onChange={(event: any) => {
                                                    onGamePageChange(event, !gamePage.active);
                                                }}
                                            />
                                        }
                                        label="active"
                                        labelPlacement="start"
                                    />
                                </div>
                            </div>
                        </div>
                        <div>
                            <FormControl className="col-12 col-sm-6 px-3">
                                <InputLabel shrink>
                                    Game
                                </InputLabel>
                                <Select
                                    disabled={!creationMode}
                                    label="Game"
                                    name="game"
                                    value={gamePage.game && gamePage.game.id ? gamePage.game.id : ''}
                                    onChange={handleSelectedGameChange}
                                >
                                    {
                                        gameList.map((game: any, index: number) => {
                                            return <MenuItem key={index} value={game._id}>{game.name}</MenuItem>;
                                        })
                                    }
                                </Select>
                            </FormControl>
                            <TextField
                                disabled={!edit && !creationMode}
                                className="col-12 col-sm-6 px-3"
                                name="twitterUrl"
                                label="Twitter URL"
                                value={gamePage.twitter.url ? gamePage.twitter.url : ''}
                                onChange={(event: any) => onGamePageChange(event)}
                            />
                        </div>
                    </TabPanel>
                    <TabPanel value={value} index={1} dir={theme.direction}>
                        {
                            gamePage &&
                            <MediaManager
                                path={['gamepage']}
                                id={gamePage._id}
                                secondPath={['medias']}
                                mediaNames={Object.keys(EGamePageImage)}
                            />
                        }
                    </TabPanel>
                </React.Fragment>
            }
        </div>
    );
};