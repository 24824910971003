import React, { useState } from 'react';
import { ReactSRMWrapper } from '@cactus/react-srm-wrapper';
import { useNavigate, useParams } from 'react-router-dom';
import './WebappTournament.scss';
import eventSourceSingletonRT from 'src/services/eventSourceSingletonRT';

export const WebappBOTournament: React.FunctionComponent = () => {
    const originUrl = process.env.REACT_APP_BASE_BUNDLE_URL + '/bo-tournament';
    const [event, setEvent] = useState<any>(null);
    const { tournamentId } = useParams();
    const navigate = useNavigate();

    const data = {
        fromBackOffice: true,
        windowed: true,
        tournamentId: tournamentId,
    }

    const sendSrmEvent = async(data?: any): Promise<any> => {
        console.log('sendSrmEvent', data);
        setEvent({ type: 'ws', data: data });
    }

    const eventSubscribe = async(data?: any): Promise<any> => {
        if (data.rt) {
            eventSourceSingletonRT.subscribeSRM(data.id,
                () => localStorage.getItem('authToken'),
                (event) => {
                    if (!event) {
                        return;
                    }
                    console.log('eventSubscribe callback', event);
                    sendSrmEvent(event);
                });
        }
        return;

    }

    const eventHandlers = {
        'srm.subscribe': eventSubscribe
    }

    return (
            <div className="page-wrapper">
                <ReactSRMWrapper
                    originUrl={originUrl}
                    exportPath="nicecactus.bo-tournament"
                    basename="/tournaments/beta"
                    language="en"
                    arguments={data}
                    eventHandlers={eventHandlers}
                    event={event}
                    nav={ navigate }
                />
            </div>
    );
};