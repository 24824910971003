import React, {useEffect, useState} from 'react';
import {Navigate, useParams} from 'react-router-dom';
import './Programs.scss';
import {AppBar, CircularProgress} from '@material-ui/core';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {useStoreState} from 'src/store';
import {TrainingService} from 'src/services/training.service';
import {LangPicker} from 'src/components/General/LangPicker/LangPicker';
import {NCTitle, NCTitleType} from 'src/atoms/NCTitle/NCTitle';
import {TabPanel} from 'src/atoms/TabPanel/TabPanel';
import {ProgramAbout} from 'src/pages/Training/Programs/About/About';
import {ProgramHowItWork} from 'src/pages/Training/Programs/HowItWork/HowItWork';
import {ProgramModules} from 'src/pages/Training/Programs/ProgramModules/ProgramModules';
import {ProgramAdvice} from 'src/pages/Training/Programs/Advice/Advice';
import {Access, ProgramLevel, TrainingProgram} from 'src/models/TrainingProgram';
import {ProgramGeneral} from 'src/pages/Training/Programs/General/General';
import Button from '@material-ui/core/Button';
import {Visibility, VisibilityOff} from '@material-ui/icons';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import {ActionDialog} from '../../../components/General/ActionDialog/ActionDialog';

enum TabsName {
    General = 0,
    About = 1,
    howItWork = 2,
    Program = 3,
    Advice = 4,
}

export const TrainingPrograms: React.FunctionComponent = () => {
    const {programId}: any = useParams();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [program, setProgram] = useState<TrainingProgram>();
    const [value, setValue] = useState<number>(0);
    const [edit, setEdit] = useState<boolean>(true);
    const [saveMode, setSaveMode] = useState<boolean>(false);
    const [lang, setLang] = useState(useStoreState(state => state.contentLang));
    const [deleteAction, setDeleteAction] = useState<boolean>(false);
    const [redirect, setRedirect] = useState<boolean>(false);

    useEffect(() => {
        if (programId) {
            TrainingService.getProgramById(programId).then((data) => {
                setProgram(data);
            });
        } else {
            const newProgram: TrainingProgram = new TrainingProgram(
                {en: '', fr: ''},
                {en: '', fr: ''},
                ProgramLevel.NOVICE,
                Access.UNLISTED,
                '',
                {en: '', fr: ''},
                {en: '', fr: ''},
                {en: '', fr: ''},
                [],
                [],
                {en: '', fr: ''},
                {en: '', fr: ''},
                {en: '', fr: ''},
                [],
                [],
                '',
                '',
            );
            setProgram(newProgram);
        }
        setIsLoading(false);
    }, [programId]);

    function a11yProps(index: any) {
        return {
            id: `full-width-tab-${index}`,
            'aria-controls': `full-width-tabpanel-${index}`,
        };
    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>, newValue: number) => {
        setValue(newValue);
    };

    const handleProgramChange = () => {
        setProgram(program);
        setSaveMode(true);
    };

    const deleteProgram = (choice: boolean) => {
        if (!choice && !program) {
            return;
        }
        if (program) {
            TrainingService.deleteProgram(program._id).then(data => {
                if (!data) {
                    return;
                }
                setRedirect(true);
            });
        }
    };

    const save = () => {
        if (program) {
            if (!programId) {
                TrainingService.createProgram(program).then(data => {
                    if (!data) {
                        return;
                    }
                    setRedirect(true);
                });
            } else {
                TrainingService.updateProgram(programId, program).then(data => {
                    if (!data) {
                        return;
                    }
                });
            }
        }
    };

    return (
        <div className="NC-container NC-card d-flex flex-column w-100">
            <div className="d-flex flex-md-row flex-wrap">
                <div className="d-flex align-items-center">
                    {
                        program && program.name['en'] !== '' &&
                        <NCTitle text={program.name[lang] || program.name['en']} type={NCTitleType.TRAINING}/>
                    }
                    <div className="ml-3">
                        {
                            program && program.published ? <Visibility/> : <VisibilityOff/>
                        }
                    </div>
                </div>
                <div>
                    {
                        saveMode &&
                        <Button
                            className="my-auto"
                            variant="contained"
                            style={{height: '40px'}}
                            color="primary"
                            onClick={() => {
                                save();
                            }}
                        >Save</Button>
                    }
                </div>
                <div className="d-flex">
                    <LangPicker setLang={setLang}/>
                    <FormControlLabel
                        label="Edit"
                        labelPlacement="start"
                        className="m-0"
                        control={
                            <Switch
                                checked={edit}
                                color="primary"
                                onChange={() => {
                                    setEdit(!edit);
                                }}
                            />
                        }
                    />
                </div>
                <div>
                    <Button
                        variant="text"
                        color="secondary"
                        onClick={() => setDeleteAction(true)}
                    >
                        <DeleteForeverIcon/>
                    </Button>
                </div>
            </div>
            {
                isLoading && <CircularProgress className="mx-auto"/>
            }
            {
                !isLoading && program &&
                <div className="NC-tab">
                    <AppBar position="static" color="default" className="mb-2">
                        <Tabs
                            value={value}
                            onChange={(event: React.ChangeEvent<any>, newValue: any) => handleChange(event, newValue)}
                            variant="fullWidth"
                        >
                            <Tab label="General" {...a11yProps(0)} />
                            <Tab label="About" {...a11yProps(1)} />
                            <Tab label="How does it work?" {...a11yProps(2)} />
                            <Tab label="Modules" {...a11yProps(3)} />
                            <Tab label="Our advice" {...a11yProps(4)}/>
                        </Tabs>
                    </AppBar>
                    <TabPanel value={value} index={TabsName.General}>
                        <div className="background">
                            <ProgramGeneral program={program} programChange={handleProgramChange} lang={lang}
                                            edit={edit}/>
                        </div>
                    </TabPanel>
                    <TabPanel value={value} index={TabsName.About}>
                        <div className="background">
                            <ProgramAbout program={program} programChange={handleProgramChange} lang={lang}
                                          edit={edit}/>
                        </div>
                    </TabPanel>
                    <TabPanel value={value} index={TabsName.howItWork}>
                        <div className="background">
                            <ProgramHowItWork program={program} programChange={handleProgramChange} lang={lang}
                                              edit={edit}/>
                        </div>
                    </TabPanel>
                    <TabPanel value={value} index={TabsName.Program}>
                        <div className="background">
                            <ProgramModules program={program} programChange={handleProgramChange} lang={lang}
                                            edit={edit}/>
                        </div>
                    </TabPanel>
                    <TabPanel value={value} index={TabsName.Advice}>
                        <div className="background">
                            <ProgramAdvice program={program} programChange={handleProgramChange} lang={lang}/>
                        </div>
                    </TabPanel>
                </div>
            }
            <ActionDialog
                title={'Are you sure to delete this program ? All attached modules will be unattached'}
                open={deleteAction}
                openHook={setDeleteAction}
                actionHook={deleteProgram}
            />
            {
                redirect &&
                <Navigate to={'/training/dashboard'}/>
            }
        </div>
    );
};
