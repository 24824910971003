import { InputLabel, TextField } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import React, {ChangeEvent, useEffect, useState} from 'react';
import {NCTitle, NCTitleType} from 'src/atoms/NCTitle/NCTitle';
import {ActionDialog} from 'src/components/General/ActionDialog/ActionDialog';
import {FullScreenDialog} from 'src/components/General/FullScreenDialog/FullScreenDialog';
import {SimpleList} from 'src/components/General/SimpleList/SimpleList';
import {NewsForm} from 'src/components/News/NewsForm/NewsForm';
import {NewsModel} from 'src/models/News';
import { PartnerModel } from 'src/models/Partner';
import {NewsService} from 'src/services/news.service';
import { PartnerService } from 'src/services/partner.service';

export const HomepageNews: React.FunctionComponent = () => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [actualPage, setActualPage] = useState<number>(0);
    const [totalItem, setTotalItem] = useState<number>(0);
    const [actionDelete, setActionDelete] = useState<boolean>(false);
    const [actionEdit, setActionEdit] = useState<boolean>(false);
    const [actionCreate, setActionCreate] = useState<boolean>(false);
    const [tag, setTag] = useState<string | undefined>();
    const [currentTag, setCurrentTag] = useState<string>();
    const [rows, setRows] = useState<any>([]);
    const [news, setNews] = useState<NewsModel>();
    const columns = ['Title', 'Published', 'Tag',];
    const keepRows = ['title', 'published', 'tag',];
    const [partnerList, setPartnerList] = useState<Array<PartnerModel>>([]);

    const getNews = () => {
        setIsLoading(true);
        NewsService.getNews(20, actualPage, 'date_creation', tag).then(data => {
            setCurrentTag(tag);
            setTotalItem(Math.ceil(data.total / 20));
            setRows(data.docs);
            setIsLoading(false);
        });
    }

    useEffect(() => {
        getNews();
        // eslint-disable-next-line    
    }, [actualPage]);

    useEffect(() => {
        PartnerService.getAllPartners().then((data) => {
            setPartnerList(data);
        })
    }, []);

    const actionManager = (news: NewsModel, kind: string) => {
        setNews(news);
        switch (kind) {
            case 'delete':
                setActionDelete(true);
                break;
            case 'edit':
                setActionEdit(true);
                break;
            case 'create': {
                setNews(
                    new NewsModel(
                        {en: ''},
                        {en: ''},
                        '',
                        {en: ''},
                        false,
                        [],
                        [],
                        tag || ''
                    )
                );
                setActionCreate(true);
                break;
            }
            default:
                return;
        }
    };

    const deleteNews = (choice: boolean) => {
        if (choice && news) {
            NewsService.deleteNews(news._id).then(data => {
                updateList(data);
            });
        }
    };

    const updateList = (result: boolean) => {
        setActionEdit(false);
        setActionDelete(false);
        setActionCreate(false);
        if (!result) {
            return;
        }
        getNews();
    };

    const onFilterKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.key === 'Enter') {
            updateList(true);
        }
    };

    const onFilterBlur = () => {
        if(currentTag !== tag) {
            updateList(true)
        }
    }

    return (
        <div className="NC-container NC-card d-flex flex-column">
            <NCTitle text={'News'} type={NCTitleType.TRAINING}/>
            <div className='d-flex align-items-baseline mb-3'>
                <InputLabel>Filter:</InputLabel>
                <TextField 
                    className="mx-3"
                    label="Tag" 
                    onChange={(event: ChangeEvent<HTMLInputElement>) => setTag(event.target.value === '' ? undefined : event.target.value)}
                    onBlur={() => onFilterBlur()}
                    onKeyDown={(e) => onFilterKeyDown(e)}
                />
            </div>
            <div className="d-flex">
                {
                    isLoading &&
                    <CircularProgress className="mx-auto"/>
                }
                {
                    !isLoading &&
                    <SimpleList
                        rows={rows}
                        columns={columns}
                        keepRows={keepRows}
                        actionsButtons={{create: true, edit: true, delete: true}}
                        handleClickOpen={actionManager}
                        pagination={{actual: actualPage, total: totalItem, setActual: setActualPage}}
                    />
                }
            </div>
            <ActionDialog
                open={actionDelete}
                title={'Are you sure to delete this news ?'}
                openHook={setActionDelete}
                actionHook={deleteNews}
            />
            {
                news &&
                <FullScreenDialog
                    disableEnforceFocus={true}
                    open={actionEdit}
                    openHook={setActionEdit}
                    actionHook={updateList}
                    componentToDisplay={
                        <NewsForm
                            newsProps={news}
                            actionHook={updateList}
                            partnerList={partnerList}
                        />
                    }
                />
            }
            {
                news &&
                <FullScreenDialog
                    disableEnforceFocus={true}
                    open={actionCreate}
                    openHook={setActionCreate}
                    actionHook={updateList}
                    componentToDisplay={
                        <NewsForm
                            newsProps={news}
                            actionHook={updateList}
                            creationMode={true}
                            partnerList={partnerList}
                        />
                    }
                />
            }
        </div>
    );
};