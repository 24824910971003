import React, {ChangeEvent, useState} from 'react';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextField from '@material-ui/core/TextField';
import Switch from '@material-ui/core/Switch';
import {useStoreState} from 'src/store';
import {TrainingService} from 'src/services/training.service';
import {LangPicker} from 'src/components/General/LangPicker/LangPicker';
import {Game} from 'src/models/Game';
import {TrainingPreset} from 'src/models/TrainingPreset';
import {TrainingThematic} from 'src/models/TrainingThematic';
import './PresetForm.scss';
import { TranslatedString } from 'src/models/TranslatedString';

interface PresetsFormProps {
    actionHook: any;
    preset: TrainingPreset;
    creationMode?: boolean;
    thematics: Array<TrainingThematic>
    gameList: Array<Game>;
}

export const PresetsForm: React.FunctionComponent<PresetsFormProps> = (props: PresetsFormProps) => {
    const [preset, setPreset] = useState<TrainingPreset>(props.preset);
    const [edit, setEdit] = useState<boolean>(false);
    const [langValue, setLangValue] = useState(useStoreState(state => state.contentLang));
    const [selectedGame, setSelectedGame] = useState<string>(props.preset.game._id);
    const [selectedThematic, setSelectedThematic] = useState<string>(props.preset.thematic);
    const [state, setState] = useState({
        showInGraph: props.preset.showInGraph,
    });

    const renderEditButton = () => {
        if (props.creationMode) {
            return <></>;
        }
        return (
            <FormControlLabel
                value="edit"
                control={<Switch color="primary" checked={edit} onChange={() => {
                    setEdit(!edit);
                }}/>}
                label="Edit"
            />
        );
    };

    const renderSaveButton = () => {
        if (!edit && !props.creationMode) {
            return <></>;
        }
        return (
            <Button
                className="mt-5 w-25 mx-auto"
                variant="contained"
                color="primary"
                onClick={() => {
                    save();
                }}
            >
                Save
            </Button>
        );
    };

    const save = () => {
        if (edit) {
            TrainingService.updatePreset(preset).then((data) => {
                if (data) {
                    props.actionHook(true);
                } else {
                    props.actionHook(false);
                }
            });
        } else {
            TrainingService.createPreset(preset).then(() => {
                props.actionHook(true);
            }).catch(() => {
                props.actionHook(false);
            });
        }
    };

    const onPresetChange = (event: any, isTranslatedString = false) => {
        const {name, value} = event.currentTarget;

        if (isTranslatedString) {
            const myPresetLang = preset[name as keyof TrainingPreset] as TranslatedString;
            myPresetLang[langValue] = value;
            setPreset(Object.assign({}, preset, {
                [name.langValue]: value,
            }));
        } else {
            setPreset(Object.assign({}, preset, {
                [name]: value,
            }));
        }

    };


    const handleSetLang = (lang: string) => {
        setLangValue(lang);
    };

    const handleShowInGraphChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setState({...state, [event.target.name]: event.target.checked});
        preset.showInGraph = event.target.checked;
        setPreset(preset);
    };

    const handleSelectedGameChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setSelectedGame(event.target.value as string);
        const game = props.gameList.find((game: Game) => game._id === event.target.value);
        if (game) {
            preset.game._id = game._id;
            preset.game.overwolfId = game.overwolfId;
            setPreset(preset);
        }
    };

    const handleSelectedThematicChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setSelectedThematic(event.target.value as string);
        preset.thematic = event.target.value as string;
        setPreset(preset);
    };

    return (
        <form className="d-flex flex-column">
            {renderEditButton()}
            <div className="row">
                <div className="col-12 px-3 row mb-3 translated-background">
                    <TextField
                        disabled={!edit && !props.creationMode}
                        className="col-12 col-sm-6 my-auto px-3"
                        id='name'
                        name="name"
                        label="Name"
                        value={preset.name[langValue] || ''}
                        onChange={(event: ChangeEvent<HTMLInputElement>) => (onPresetChange(event, true))}
                    />
                    <div className="col-12 col-sm-6 d-flex justify-content-center">
                        <LangPicker title={'Translate'} setLang={handleSetLang}/>
                    </div>
                </div>
                <FormControlLabel
                    disabled={!edit && !props.creationMode}
                    control={<Switch checked={preset.showInGraph} onChange={handleShowInGraphChange} name="showInGraph"
                                     color="primary"/>}
                    label="Show Dataviz"
                    labelPlacement="start"
                    className="col-12 px-3"
                />
                <TextField
                    disabled={!edit && !props.creationMode}
                    className="col-12 col-sm-6 px-3"
                    id='path'
                    name="path"
                    label="Path"
                    value={preset.path}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => (onPresetChange(event))}
                />
                <TextField
                    disabled={!edit && !props.creationMode}
                    className="col-12 col-sm-6 px-3"
                    id='kind'
                    name="kind"
                    label="Kind"
                    value={preset.kind}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => (onPresetChange(event))}
                />
                <FormControl className="col-12 col-sm-6 px-3">
                    <InputLabel shrink>
                        Game
                    </InputLabel>
                    <Select
                        disabled={!edit && !props.creationMode}
                        label="Game"
                        name="game"
                        value={selectedGame ? selectedGame : ''}
                        onChange={handleSelectedGameChange}
                    >
                        {
                            props.gameList.map((game: any, index: number) => {
                                return <MenuItem key={index} value={game._id}>{game.name}</MenuItem>;
                            })
                        }
                    </Select>
                </FormControl>
                <FormControl className="col-12 col-sm-6 px-3">
                    <InputLabel shrink>
                        Thematic
                    </InputLabel>
                    <Select
                        disabled={!edit && !props.creationMode}
                        label="Thematic"
                        name="thematic"
                        value={selectedThematic ? selectedThematic : ''}
                        onChange={handleSelectedThematicChange}
                    >
                        {
                            props.thematics.map((thematic: any, index: number) => {
                                return <MenuItem key={index} value={thematic.value}>{thematic.value}</MenuItem>;
                            })
                        }
                    </Select>
                </FormControl>
            </div>
            {renderSaveButton()}
        </form>
    );
};