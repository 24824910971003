import { TranslatedString } from "./TranslatedString";

export class TrainingBroadcast {
    constructor(
        public _id: string,
        public disabled: boolean,
        public primary: boolean,
        public games: Array<string>,
        public message: TranslatedString,
        public createdAt: Date,
        public finishedAt: Date,
    ) {}
}