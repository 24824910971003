import React, {useState} from 'react';
import {Link} from '@material-ui/core';
import {TrainingModule} from 'src/models/TrainingModule';
import './TrainingModuleCard.scss';
import Button from '@material-ui/core/Button/Button';
import EditIcon from '@material-ui/icons/Edit';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import Tooltip from '@material-ui/core/Tooltip';
import Zoom from '@material-ui/core/Zoom';
import {FullScreenDialog} from 'src/components/General/FullScreenDialog/FullScreenDialog';
import {CreateModuleForm} from 'src/pages/Training/Programs/ProgramModules/CreateModuleForm/CreateModuleForm';
import {TrainingService} from 'src/services/training.service';
import {useParams} from 'react-router-dom';
import {CategorizedButton, CategorizedButtonVariant} from 'src/atoms/CategorizedButton/CategorizedButton';
import {ActionDialog} from 'src/components/General/ActionDialog/ActionDialog';

interface TrainingModuleCardProps {
    module: TrainingModule;
    notAttached?: boolean;
    updateHook: any;
    noProgram?: boolean;
}

export const TrainingModuleCard: React.FunctionComponent<TrainingModuleCardProps> = ({
                                                                                         module,
                                                                                         notAttached = false,
                                                                                         updateHook,
                                                                                         noProgram = false
                                                                                     }) => {
    const [programModule, setProgramModule] = useState<TrainingModule>(module);
    const [programModuleActionCreate, setProgramModuleActionCreate] = useState<boolean>(false);
    const {programId}: any = useParams();
    const [deleteAction, setDeleteAction] = useState<boolean>(false);

    const updateList = () => {
        setProgramModuleActionCreate(false);
    };

    const removeModuleFromProgram = () => {
        module.program = undefined;
        TrainingService.updateModule(module._id, module).then(data => {
            if (!data) {
                updateHook(false);
                return;
            }
            updateHook(true);
        });
    };


    const addModuleToProgram = () => {
        module.program = programId;
        TrainingService.updateModule(module._id, module).then(data => {
            if (!data) {
                updateHook(false);
                return;
            }
            updateHook(true);
        });
    };

    const deleteModule = (choice: boolean) => {
        if (!choice) {
            return;
        }
        TrainingService.deleteModule(module._id).then(data => {
            if (!data) {
                updateHook(false);
                return;
            }
            updateHook(true);
        });
    };

    return (
        <>
            <div className="training-module-card">
                <div className="NC-item-card d-flex justify-content-between" style={{height: '100%'}}>
                    <div className="header"
                         style={{backgroundImage: `url(${process.env.REACT_APP_S3_URL}/training/module/${module._id}/medias/Cover)`}}>
                        <div className="title d-flex align-items-start">
                            <img src={`${process.env.REACT_APP_S3_URL}/game/${module.game}/medias/IconImage`}
                                 alt="game-logo"/>
                            <span className="ml-2 mt-1">{module.name['en']}</span>
                        </div>
                    </div>
                    <div className="footer d-flex justify-content-between flex-column">
                        <div className="info mb-3 d-flex flex-wrap">
                            <div className="d-flex align-items-center flex-fill">
                                <Tooltip arrow TransitionComponent={Zoom} title="Edit module" aria-label="add">
                                    <Button
                                        variant="text"
                                        onClick={() => setProgramModuleActionCreate(true)}
                                        color="primary"
                                        className="">
                                        <EditIcon/>
                                    </Button>
                                </Tooltip>
                            </div>
                            <div className="d-flex align-items-center flex-fill">
                                <Tooltip arrow TransitionComponent={Zoom} title="Delete module" aria-label="add">
                                    <Button
                                        variant="text"
                                        onClick={() => setDeleteAction(true)}
                                        color="secondary"
                                        className="">
                                        <DeleteForeverIcon/>
                                    </Button>
                                </Tooltip>
                            </div>
                            {
                                !noProgram &&
                                <div className="d-flex align-items-center flex-fill">
                                    {
                                        notAttached &&
                                        <Tooltip arrow TransitionComponent={Zoom} title="Add to this program"
                                                 aria-label="add">
                                            <Button
                                                variant="text"
                                                onClick={addModuleToProgram}
                                                color="primary"
                                                className="">
                                                <ArrowUpwardIcon/>
                                            </Button>
                                        </Tooltip>
                                    }
                                    {
                                        !notAttached &&
                                        <Tooltip arrow TransitionComponent={Zoom} title="Remove from this program"
                                                 aria-label="add">
                                            <Button
                                                variant="text"
                                                onClick={removeModuleFromProgram}
                                                color="primary"
                                                className="">
                                                <ArrowDownwardIcon/>
                                            </Button>
                                        </Tooltip>
                                    }
                                </div>
                            }
                        </div>
                        <Link href={`/training/module/${module._id}`} className="d-flex justify-content-center">
                            <CategorizedButton
                                text={'Manage this module'}
                                variant={CategorizedButtonVariant.TRAINING}
                            />
                        </Link>
                    </div>
                </div>
            </div>
            <FullScreenDialog
                open={programModuleActionCreate}
                openHook={setProgramModuleActionCreate}
                actionHook={updateList}
                componentToDisplay={
                    <CreateModuleForm module={programModule} setModule={setProgramModule} actionHook={updateList}/>
                }
            />
            <ActionDialog
                title={`Are you sure to delete ${module.name['en']} ?`}
                open={deleteAction}
                openHook={setDeleteAction}
                actionHook={deleteModule}
            />
        </>
    );
};