import axios, { AxiosError } from 'axios';
import { EventMessage } from 'src/models/ChatMessage';

export interface CLK {
    target: string;
    date: string;
}

export interface messagesAndLastKey {
    list:Array<EventMessage>,
    lastKey:CLK
}

export class MatchMessagingService  {
    static baseUrl: string = String(process.env.REACT_APP_RT_API_URL);
    static token = (): string | null => localStorage.getItem('accessToken');

    static async getMessagesFromRoom(roomId: string , lastKey: CLK | undefined ): Promise<messagesAndLastKey> {
        if (!roomId) {
            throw Error; 
        }

        let response: messagesAndLastKey;
        try {
            response = (await axios.get(
                `${MatchMessagingService.baseUrl}/rooms/${roomId}/events`,
                {
                     headers: { 'authorization': MatchMessagingService.token() } , 
                     params : lastKey
                   
                },
                
            )).data;
        } catch (e) {
            throw (e as AxiosError).response;
        }
      
        const messages = response.list.filter(m => m.type === 'chat')
        return { list : messages , lastKey : response.lastKey };
    }   
}
