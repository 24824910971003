import { createStore, action, Action, createTypedHooks, persist } from "easy-peasy";
import { Administrator } from "./models/Administrator";
import { Game } from "./models/Game";

export interface LoginStore {
    accessToken: string;
    setAccessToken: Action<LoginStore, string>;
    refreshToken: string;
    setRefreshToken: Action<LoginStore, string>;
    authToken: string;
    setAuthToken: Action<LoginStore, string>;
    isLoggedIn: boolean;
    setIsLoggedIn: Action<LoginStore, boolean>;
    currentAdministrator: Administrator | null;
    setCurrentAdministrator: Action<LoginStore, Administrator | null>;
    logOutAction: Action<LoginStore>,
    websiteLang: string;
    setWebsiteLang: Action<LoginStore, string>,
    contentLang: string;
    setContentLang: Action<LoginStore, string>,
    languages: any;
    setLanguages: Action<LoginStore, any>,
    gameList: Array<Game>,
    setGameList: Action<LoginStore, Array<Game>>,
    menuItemCollapse: Array<any>;
    setMenuItemCollapse: Action<LoginStore, Array<any>>,
}

export const store = createStore<LoginStore>(
    persist({
        accessToken: '',
        setAccessToken: action((state, data) => {
            state.accessToken = data;
        }),
        refreshToken: '',
        setRefreshToken: action((state, data) => {
            state.refreshToken = data;
        }),
        authToken: '',
        setAuthToken: action((state, data) => {
            state.authToken = data;
        }),
        isLoggedIn: false,
        setIsLoggedIn: action((state, data) => {
            state.isLoggedIn = data;
        }),
        currentAdministrator: null,
        setCurrentAdministrator: action((state, data) => {
            state.currentAdministrator = data;
        }),
        languages: null,
        setLanguages: action((state, data) => {
            state.languages = data;
        }),
        websiteLang: 'en',
        setWebsiteLang: action((state, data) => {
            state.websiteLang = data;
        }),
        contentLang: 'en',
        setContentLang: action((state, data) => {
            state.contentLang = data;
        }),
        gameList: [],
        setGameList: action((state, data) => {
            state.gameList = data;
        }),
        menuItemCollapse: [],
        setMenuItemCollapse: action((state, data) => {
            state.menuItemCollapse = data;
        }),
        logOutAction: action((state) => {
            state.currentAdministrator = null;
            state.accessToken = '';
            state.authToken = '';
            state.isLoggedIn = false;
            state.refreshToken = '';
            state.languages = [];
            state.websiteLang = '';
            state.contentLang = '';
            state.gameList = [];
        }),
    })
);

const typedHooks = createTypedHooks<LoginStore>();

export const useStoreActions = typedHooks.useStoreActions;
export const useStoreDispatch = typedHooks.useStoreDispatch;
export const useStoreState = typedHooks.useStoreState;