import React, {ChangeEvent, useState} from 'react';
import './QuizQuestionForm.scss';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import AppBar from '@material-ui/core/AppBar';
import {Tab} from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import TabList from '@material-ui/lab/TabList';
import {TabContext, TabPanel} from '@material-ui/lab';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import {useStoreState} from 'src/store';
import {Game} from 'src/models/Game';
import {Languages} from 'src/models/Languages';
import {Choices, TrainingQuestion} from 'src/models/TrainingQuiz';
import { TranslatedString } from 'src/models/TranslatedString';

interface QuizQuestionFormProps {
    question: TrainingQuestion;
    setQuestion: any;
    actionHook: any;
    handleChangeQuestion: any;
    gameList: Array<Game>;
    creationMode?: boolean
}

export const QuizQuestionForm: React.FunctionComponent<QuizQuestionFormProps> = (props: QuizQuestionFormProps) => {
    const [edit, setEdit] = useState<boolean>(false);
    const [lang, setLang] = useState(useStoreState(state => state.contentLang));
    const [languages] = useState(useStoreState(state => state.languages));
    const [question, setQuestion] = useState<TrainingQuestion>(props.question);
    const [answers, setAnswers] = useState<Array<Choices>>(props.question.choices);

    if (!question) {
        return (<></>);
    }

    const onQuizChange = (event: any, isTranslatedString = false) => {
        const {name, value} = event.target;

        if (isTranslatedString) {
            const myLang = question[name as keyof TrainingQuestion] as TranslatedString;
            myLang[lang] = value;
            setQuestion(Object.assign({}, question, {
                [name.langValue]: value,
            }));
        } else {
            setQuestion(Object.assign({}, question, {
                [name]: value,
            }));
        }
    };

    const onQuizChangeBool = (event: any, valueBool: boolean) => {
        const value = valueBool;
        question.published = value;
        setQuestion(question);
        setQuestion(Object.assign({}, question, {
            [event.currentTarget.name]: value,
        }));
    };

    const save = () => {
        props.handleChangeQuestion(question);
    };

    const renderEditButton = () => {
        if (props.creationMode) {
            return <></>;
        }
        return (
            <FormControlLabel
                value="edit"
                control={<Switch color="primary" checked={edit} onChange={() => {
                    setEdit(!edit);
                }}/>}
                label="Edit"
            />
        );
    };

    const renderSaveButton = () => {
        if (!edit && !props.creationMode) {
            return <></>;
        }
        return (
            <Button
                variant="contained"
                color="primary"
                onClick={() => {
                    save();
                }}
            >Save</Button>
        );
    };

    const onQuizChoiceChange = (event: any, currentIndex: number) => {
        answers[currentIndex].name[lang] = event.currentTarget.value;
        setAnswers(answers);
        question.choices = answers;
        setQuestion(question);
    };

    const handleChange = (event: any, currentIndex: any) => {
        if (answers[currentIndex].answerType === 'A') {
            answers[currentIndex].answerType = false;
        } else {
            answers[currentIndex].answerType = 'A';
        }
        setAnswers(answers);
        question.choices = answers;
        setQuestion(question);
    };

    const handleTabChange = (event: React.ChangeEvent<HTMLInputElement>, newValue: string) => {
        setLang(newValue);
    };

    const deleteAnswer = (answer: Choices) => {
        const index = answers.indexOf(answer, 0);
        if (index > -1) {
            answers.splice(index, 1);
        }
        setAnswers(answers);
        question.choices = answers;
        setQuestion(question);
    };

    const addNewAnswer = () => {
        answers.push(new Choices(
            {en: ''},
            false,
            false,
        ));
        setAnswers(answers);
        question.choices = answers;
        setQuestion(question);
    };

    return (
        <form className="d-flex flex-column">
            <div className="row mb-3">
                <div className="col-12 col-sm-6 px-3">
                    {renderEditButton()}
                </div>
                <div className="col-12 col-sm-6 px-3">
                    <FormControlLabel
                        value="start"
                        disabled={!edit && !props.creationMode}
                        className="col-12 col-sm-6 px-3"
                        control={
                            <Switch
                                color="primary"
                                checked={question.published}
                                name="published"
                                onChange={(event: any) => {
                                    onQuizChangeBool(event, !question.published);
                                }}
                            />
                        }
                        label="Published"
                    />
                </div>
            </div>
            <div className="NC-tab">
                <TabContext value={lang}>
                    <AppBar position="static">
                        <TabList  onChange={(event: React.ChangeEvent<any>) => handleTabChange(event, lang)} aria-label="simple tabs example">
                            {
                                languages.map((lang: Languages) => (
                                    <Tab key={lang._id} label={lang.name} value={lang.code}/>
                                ))
                            }
                        </TabList>
                    </AppBar>
                    {
                        languages.map((lang: Languages) => (
                            <TabPanel className="w-100" key={lang._id} value={lang.code}>
                                <div className="row">
                                    <TextField
                                        disabled
                                        className="col-12 col-sm-6 px-3"
                                        label="ID"
                                        name="_id"
                                        defaultValue={question._id}
                                    />
                                    <TextField
                                        disabled={!edit && !props.creationMode}
                                        className="col-12 col-sm-5 my-auto px-3"
                                        id={'name'}
                                        name="name"
                                        label="Name"
                                        defaultValue={question.name[lang.code]}
                                        helperText={question.name['en'] ? 'EN : {' + question.name['en'] + '}' : 'FR : {' + question.name['fr'] + '}'}
                                        onChange={(event: ChangeEvent<HTMLInputElement>) => (onQuizChange(event, true))}
                                    />
                                    <TextField
                                        disabled={!edit && !props.creationMode}
                                        className="col-12 col-sm-5 my-auto px-3"
                                        id={'comment' + Math.random()}
                                        name="comment"
                                        label="Comment"
                                        value={question.comment[lang.code] || ''}
                                        onChange={(event: ChangeEvent<HTMLInputElement>) => (onQuizChange(event, true))}
                                    />
                                    <div className="col-12 px-3 row mt-3 ml-0 translated-background">
                                        <div className="col-12 d-flex my-2 justify-content-center">
                                            <Button variant="contained" color="primary" onClick={addNewAnswer}>Add
                                                answer</Button>
                                        </div>
                                        {
                                            answers && answers.map((choice: Choices, index: number) => {
                                                return (
                                                    <div className="col-12 col-sm-6 my-auto d-flex flex-row px-1"
                                                         key={index}>
                                                        <Button
                                                            variant="contained"
                                                            color="primary"
                                                            className="mr-2 my-auto deleteAnswerButton"
                                                            onClick={() => deleteAnswer(choice)}
                                                        ><DeleteForeverIcon/></Button>

                                                        <TextField
                                                            className="w-75"
                                                            disabled={!edit && !props.creationMode}
                                                            id={'answer' + Math.random()}
                                                            name="answer"
                                                            label={'Answer'}
                                                            helperText={answers[index].name['en'] ? 'EN : {' + answers[index].name['en'] + '}' : 'FR : {' + answers[index].name['fr'] + '}'}
                                                            defaultValue={answers[index].name[lang.code]}
                                                            onChange={(event: ChangeEvent<HTMLInputElement>) => (onQuizChoiceChange(event, index))}
                                                        />
                                                        <FormControlLabel
                                                            className="w-25 my-auto mb-0 mr-0"
                                                            control={
                                                                <Checkbox
                                                                    checked={answers[index].answerType === 'A'}
                                                                    onChange={(event: any) => handleChange(event, index)}
                                                                    name="goodAnswer"
                                                                    color="primary"
                                                                />
                                                            }
                                                            label="Good answer"
                                                        />
                                                    </div>
                                                );
                                            })
                                        }
                                    </div>
                                </div>
                            </TabPanel>
                        ))
                    }
                </TabContext>
            </div>
            {renderSaveButton()}
        </form>
    );
};