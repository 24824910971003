import React from 'react';
import {NCTitle, NCTitleType} from 'src/atoms/NCTitle/NCTitle';
import './Dashboard.scss';
import {IconButton, Link, Tooltip} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import TournamentIcon from 'src/assets/system/tournaments.svg';
import SkillGamesIcon from 'src/assets/system/skill-games.svg';
import GavelIcon from '@material-ui/icons/Gavel';
import PolicyIcon from '@material-ui/icons/Policy';
import ListIcon from '@material-ui/icons/List';

export const TournamentsDashboard: React.FunctionComponent = () => {
    return (
        <div className="NC-container">
            <NCTitle text={'Tournament Dashboard'} type={NCTitleType.TOURNAMENT}/>
            <div className="d-flex flex-wrap">
                <div className="d-flex flex-column  flex-fill overflow-auto">
                    <div className="mt-3 d-flex">
                        <div
                            className="NC-card align-items-center mx-1 d-flex justify-content-center flex-fill d-flex flex-column">
                            <Tooltip title="Create Tournament">
                                <IconButton>
                                    <img src={TournamentIcon} alt="-"/>
                                </IconButton>
                            </Tooltip>
                            <Typography>Create Tournament</Typography>
                            <div className="primary-color font-weight-bold">Coming soon</div>
                        </div>
                        <div
                            className="NC-card align-items-center mx-1 d-flex justify-content-center flex-fill d-flex flex-column">
                            <Tooltip title="Templater">
                                <IconButton>
                                    <img src={SkillGamesIcon} alt="-" style={{filter: 'brightness(0) invert(1)'}}/>
                                </IconButton>
                            </Tooltip>
                            <Typography>Templater</Typography>
                            <div className="primary-color font-weight-bold">Coming soon</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="d-flex flex-wrap">
                <div className="d-flex flex-column  flex-fill overflow-auto">
                    <div className="mt-1 d-flex">
                        <div
                            className="NC-card align-items-center mx-1 d-flex justify-content-center flex-fill d-flex flex-column">
                            <Tooltip title="Tournaments">
                                <Link href={'/tournaments/list'}>
                                    <IconButton>
                                        <ListIcon/>
                                    </IconButton>
                                </Link>
                            </Tooltip>
                            <Typography>Tournament List</Typography>
                        </div>
                        <div
                            className="NC-card align-items-center mx-1 d-flex justify-content-center flex-fill d-flex flex-column">
                            <Tooltip title="Refereeing">
                                <Link href={'/tournaments/referee-list'}>
                                    <IconButton>
                                        <PolicyIcon/>
                                    </IconButton>
                                </Link>
                            </Tooltip>
                            <Typography>Referee Tournament List</Typography>
                        </div>
                        <div
                            className="NC-card align-items-center mx-1 d-flex justify-content-center flex-fill d-flex flex-column">
                            <Tooltip title="Refereeing">
                                <Link href={'/tournaments/refereeing/matchs/list'}>
                                    <IconButton>
                                        <GavelIcon/>
                                    </IconButton>
                                </Link>
                            </Tooltip>
                            <Typography>Match Refereeing</Typography>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};