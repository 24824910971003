import {CircularProgress} from '@material-ui/core';
import React, {useEffect, useState} from 'react';
import {NCTitle} from 'src/atoms/NCTitle/NCTitle';
import {CompanyForm} from 'src/components/Company/CompanyForm/CompanyForm';
import {ActionDialog} from 'src/components/General/ActionDialog/ActionDialog';
import {FullScreenDialog} from 'src/components/General/FullScreenDialog/FullScreenDialog';
import {SimpleList} from 'src/components/General/SimpleList/SimpleList';
import {Company} from 'src/models/Company';
import {CompanyService} from 'src/services/company.service';

export const Companies: React.FunctionComponent = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [rows, setRows] = useState<any>([]);
    const [company, setCompany] = useState<Company>();
    const [actionDelete, setActionDelete] = useState<boolean>(false);
    const [actionEdit, setActionEdit] = useState<boolean>(false);
    const [actionCreate, setActionCreate] = useState<boolean>(false);
    const [actualPage, setActualPage] = useState<number>(0);
    const [totalItem, setTotalItem] = useState<number>(0);
    const columns = ['Name', 'Email'];
    const keepRows = ['name', 'contact_email'];

    useEffect(() => {
        CompanyService.getCompanies(20, actualPage).then((data) => {
            setTotalItem(Math.ceil(data.total / 20));
            setRows(data.docs);
            setIsLoading(false);
        }).catch(error => console.log(error));
    }, [totalItem, actualPage]);

    const actionManager = (company: Company, kind: string) => {
        setCompany(company);

        switch (kind) {
            case 'delete':
                setActionDelete(true);
                break;
            case 'edit':
                setActionEdit(true);
                break;
            case 'create': {
                setCompany(
                    new Company(
                        '',
                        '',
                        '',
                        '',
                        '',
                        '',
                    )
                );
                setActionCreate(true);
                break;
            }
            default:
                return;
        }
    };

    const deleteCompany = (choice: boolean) => {
        if (choice && company) {
            CompanyService.deleteCompany(company._id).then(data => {
                if (!data) {
                    updateList(false);
                    return;
                }
                updateList(true);
            });
        }
    };

    const updateList = (result: boolean) => {
        setActionEdit(false);
        setActionCreate(false);
        setActionDelete(false);

        if (result) {
            setIsLoading(true);
            CompanyService.getCompanies(20, actualPage).then((data) => {
                setRows(data.docs);
                setTotalItem(Math.ceil(data.total / 20));
                setIsLoading(false);
            }).catch(error => console.log(error));
        }
    };

    return (
        <div className="NC-container NC-card d-flex flex-column">
            <NCTitle text={'Companies'}/>
            <div className="d-flex">
                {
                    isLoading && <CircularProgress className="mx-auto"/>
                }
                {
                    !isLoading &&
                    <SimpleList
                        rows={rows}
                        columns={columns}
                        keepRows={keepRows}
                        actionsButtons={{create: true, edit: true, delete: true}}
                        handleClickOpen={actionManager}
                        pagination={{actual: actualPage, total: totalItem, setActual: setActualPage}}
                    />
                }
            </div>
            <ActionDialog
                open={actionDelete}
                title={`Are you sure to delete this company : ${company?.name}`}
                openHook={setActionDelete}
                actionHook={deleteCompany}
            />
            {
                company &&
                <FullScreenDialog
                    open={actionEdit}
                    openHook={setActionEdit}
                    actionHook={updateList}
                    componentToDisplay={
                        <CompanyForm
                            company={company}
                            actionHook={updateList}
                        />
                    }
                />
            }
            {
                company &&
                <FullScreenDialog
                    open={actionCreate}
                    openHook={setActionCreate}
                    actionHook={updateList}
                    componentToDisplay={
                        <CompanyForm
                            company={company}
                            actionHook={updateList}
                            creationMode={true}
                        />
                    }
                />
            }

        </div>
    );
};