import axios from 'axios';
import { NoPlatform } from '../models/Platform';

export interface LastKey {
    id: string,
    route: string,
}
export class NGPlatformService {
    private static baseUrl: string = String(process.env.REACT_APP_NG_URL);
    static token = (): string | null => localStorage.getItem('accessToken');

    static async getPlatforms(lastKey?: LastKey): Promise<{ lastKey: LastKey, list: Array<NoPlatform> }> {
        return (
            await axios.get(
                `${NGPlatformService.baseUrl}/public/platforms`,
                {
                    params: {
                        id: lastKey?.id
                    },
                    headers: {
                        'authorization': NGPlatformService.token(),
                    },
                },
            )
        ).data;
    }

    static async createPlatform(body: NoPlatform): Promise<NoPlatform> {
        if (!body) {
            throw Error;
        }
        let res: NoPlatform;
        try {
            res = (await axios.post(
                `${NGPlatformService.baseUrl}/admin/platforms`,
                body, {
                    headers: {
                        'authorization': NGPlatformService.token(),
                    }
                }
            )).data;
        } catch {
            throw Error;
        }

        return res;
    }

    static async updatePlatform(id: string, body: NoPlatform): Promise<boolean> {
        if (!body && !id) {
            return false;
        }

        try {
            await axios.put(
                `${NGPlatformService.baseUrl}/admin/platforms/${id}`,
                body, {
                    headers: {
                        'authorization': NGPlatformService.token(),
                    }
                }
            );
        } catch {
            return false;
        }

        return true;
    }

    // Doesn't exist yet on NG - Need to do Manual delete for now
    static async deletePlatform(_id: string): Promise<boolean> {
        if (!_id) {
            return false;
        }

        try {
            await axios.delete(
                `${NGPlatformService.baseUrl}/admin/platforms/${_id}`, {
                    headers: {
                        'authorization': NGPlatformService.token(),
                    }
                }
            );
        } catch {
            return false;
        }

        return true;
    }
}