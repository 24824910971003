import { TranslatedString } from "./TranslatedString";

export class Seo {
    constructor(
        public _id: string,
        public value: string,
        public game: string,
        public title: TranslatedString,
        public description: TranslatedString,
        public keywords: TranslatedString,
        public createdAt: Date,
    ) {
    }
}

export enum ESeoMedias {
    ContentImage = 'ContentImage',
    Thumbnail = 'Thumbnail',
}