import { TranslatedString } from '../models/TranslatedString';

export enum UserStatus {
    NONE = 'NONE',
    CONFIRMED = 'CONFIRMED'
}

export enum PremiumStatus {
    NONE = 'NONE',
    TRIAL = 'TRIAL',
    PREMIUM = 'PREMIUM'
}

export enum PremiumDurationScope {
    DAY = 'day',
    MONTH = 'month',
    YEAR = 'year'
}

export enum EUserMedias {
    BannerImage = 'BannerImage',
    ProfileImage = 'ProfileImage',
}

export interface FavoriteGames {
    id: string;
    name: string;
}

export class User {
    constructor(
        public _id: string,
        public email: string,
        public firstName: string,
        public lastName: string,
        public nickname: string,
        public code: string,
        public birthday: any,
        public account: Account,
        public gameInfo: GameInfo,
        public media: Media,
        public settings: Settings,
        public phoneNumber: string,
    ) {}
}

export class GameInfo {
    constructor (
        public favoriteGames: Array<any>,
        public favoritePlatforms: Array<any>,
    ) {}
}

export class Content {
    constructor (
        public about: TranslatedString
    ) {}
}

export class Account {
    public constructor(
        public confirmation: UserStatus,
        public subscriptionStatus: PremiumStatus,
        public premiumUntil: Date,
        public createdAt: Date
    ) {}
}

export class Media {
    public constructor(
        public BannerImage: string,
        public ProfileImage: string,
    ) {}
}

export class Settings {
    public constructor(
       public countryCode: string,
       public currency: string,
       public hasOptedIn: any,
       public lang: string,
    ) {}
}