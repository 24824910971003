import axios, { AxiosError } from 'axios';
import { Team, TeamAugmented, TeamGame, TeamInvitation, TeamMember } from 'src/models/Team';
import { LastKey, PaginatedList } from '../models/Common';
import { NoUser } from '../models/NoUser';
import { AdministratorService } from './administrator.service';

export class TeamService {
    private static baseUrl = String(process.env.REACT_APP_TEAM_URL);
    private static adminUrl = `${TeamService.baseUrl}/admin`;

    static async getTeamList(lastKey?: LastKey, query?: string): Promise<PaginatedList<Team>> {
        let res;
        const params: {[key: string]: any} = {};

        if (lastKey) {
            params['team'] = lastKey.team;
            params['cname'] = lastKey.cname;
        }

        try {
            res = (await axios.get(
                `${TeamService.baseUrl}/admin/teams?${query}`,
                {
                    headers: {
                        'authorization':  AdministratorService.token(),
                    },
                    params: params,
                }
            )).data;
        } catch (e) {
            throw (e as AxiosError).response;
        }

        if (!res.list) {
            throw Error;
        }

        return res;
    }

    static async getTeamMembers(teamId: string): Promise<PaginatedList<TeamMember>> {
        try {
            const res = await axios.get(
                `${TeamService.baseUrl}/teams/${teamId}/users`,
                {
                    headers: {
                        'authorization': AdministratorService.token(),
                    }
                }
            );
            return res.data;
        } catch (e) {
            throw (e as AxiosError).response;
        }
    }

    static async getTeamGames(teamId: string): Promise<PaginatedList<TeamGame>> {
        let res;

        try {
            res = await axios.get(
                `${TeamService.baseUrl}/public/teams/${teamId}/games`,
                {
                    headers: {
                        'authorization': AdministratorService.token(),
                    }
                }
            );
        } catch (e) {
            throw (e as AxiosError).response;
        }

        return res.data;
    }

    static async addUserInTeam(teamId: string, user: NoUser): Promise<boolean> {
        try {
            await axios.post(
                `${TeamService.adminUrl}/teams/${teamId}/users`,
                {
                    userId: user.userId,
                    name: user.name,
                    code: user.code,
                    email: user.email
                },
                {
                    headers: {
                        'authorization': AdministratorService.token(),
                    }
                }
            );
        } catch {
            return false;
        }
        return true;
    }

    static async updateTeamName(teamId: string, newName: string): Promise<boolean> {
        try {
            await axios.patch(
                `${TeamService.adminUrl}/teams/${teamId}/name`,
                {
                    name: newName,
                },
                {
                    headers: {
                        'authorization': AdministratorService.token(),
                    }
                }
            );
        } catch {
            return false;
        }

        return true;
    }

    static async updateTeamTag(teamId: string, newTag: string): Promise<boolean> {
        try {
            await axios.patch(
                `${TeamService.adminUrl}/teams/${teamId}/tag`,
                {
                    tag: newTag,
                },
                {
                    headers: {
                        'authorization': AdministratorService.token(),
                    }
                }
            );
        } catch {
            return false;
        }

        return true;
    }

    static async updateTeamDescription(teamId: string, newDescription: string): Promise<boolean> {
        try {
            await axios.patch(
                `${TeamService.adminUrl}/teams/${teamId}/description`,
                {
                    desc: newDescription,
                },
                {
                    headers: {
                        'authorization': AdministratorService.token(),
                    }
                }
            );
        } catch {
            return false;
        }

        return true;
    }

    static async updatePermission(teamId: string, userId: string, permission: string): Promise<boolean> {
        try {
            await axios.patch(
                `${TeamService.adminUrl}/teams/${teamId}/users/${userId}`,
                {
                    permission: parseInt(permission, 10),
                },
                {
                    headers: {
                        'authorization': AdministratorService.token(),
                    }
                }
            );
        } catch {
            return false;
        }

        return true;
    }

    static async updateOwner(teamId: string, currentOwner: string, newOwner: string): Promise<boolean> {
        try {
            await axios.patch(
                `${TeamService.adminUrl}/teams/${teamId}/owner`,
                {
                    team: teamId,
                    owner: currentOwner,
                    userId: newOwner,
                },
                {
                    headers: {
                        'authorization': AdministratorService.token(),
                    }
                }
            );
        } catch {
            return false;
        }

        return true;
    }

    static async removeUserFromTeam(teamId: string, userId: string): Promise<boolean> {
        try {
            await axios.delete(
                `${TeamService.adminUrl}/teams/${teamId}/users/${userId}`,
                {
                    headers: {
                        'authorization': AdministratorService.token(),
                    },
                    method: 'DELETE',
                }
            );
        } catch {
            return false;
        }
        return true;
    }

    static async deleteTeam(teamId: string): Promise<boolean> {
        if (!teamId) {
            return false;
        }

        try {
            await axios.delete(
                `${TeamService.adminUrl}/teams/${teamId}`,
                {
                    headers: {
                        'authorization': AdministratorService.token(),
                    }
                }
            );
        } catch {
            return false;
        }
        return true;
    }

    static async getTeam(id: string): Promise<TeamAugmented> {
        let res;

        try {
            res = (await axios.get(
                `${TeamService.baseUrl}/teams/${id}`,
                {
                    headers: {
                        'authorization':  AdministratorService.token(),
                    },
                }
            )).data;
        } catch (e) {
            throw (e as AxiosError).response;
        }

        return res;
    }

    static async getInvitationsByUser(userId: string): Promise<PaginatedList<TeamInvitation>> {
        try {
            const res = (await axios.get(
                `${TeamService.adminUrl}/invitations/users/${userId}`,
                {
                    headers: {
                        'authorization':  AdministratorService.token(),
                    },
                }
            ));
            return res.data;
        } catch (e) {
            throw (e as AxiosError).response;
        }
    }

    static async getTeamsByUser(userId: string): Promise<PaginatedList<TeamMember>> {
        try {
            const res = (await axios.get(
                `${TeamService.adminUrl}/teams/users/${userId}`,
                {
                    headers: {
                        'authorization':  AdministratorService.token(),
                    },
                }
            ));
            return res.data;
        } catch (e) {
            throw (e as AxiosError).response;
        }
    }
}