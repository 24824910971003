import { TranslatedString } from "./TranslatedString";

export class Platform {

    constructor(
        public _id: string,
        public name: string,
        public description: TranslatedString,
    ) {}
}

export class NoPlatform {
    constructor(
        public id: string,
        public route: string,
        public title: string,
        public active: number,
        public color: string,
    ){}
}