export class PremiumProduct {
    _id: string;

    constructor(
        public name: string,
        public ids: {
            stripe: string,
            paypal: string,
        }
    ) {
    }
}