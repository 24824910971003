import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem/MenuItem';
import Select from '@material-ui/core/Select';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import React, {useState} from 'react';
import {toast} from 'react-toastify';
import {Game} from 'src/models/Game';
import {TrainingBroadcast} from 'src/models/TrainingBroadcast';
import {TrainingService} from 'src/services/training.service';
import {LangPicker} from 'src/components/General/LangPicker/LangPicker';
import { TranslatedString } from 'src/models/TranslatedString';

interface BroadcastFormProps {
    broadcast: TrainingBroadcast,
    creationMode?: boolean,
    gameList: Array<Game>;
    actionHook?: any;
}

export const BroadcastForm: React.FunctionComponent<BroadcastFormProps> = ({
                                                                               broadcast,
                                                                               creationMode,
                                                                               gameList,
                                                                               actionHook
                                                                           }) => {
    const [edit, setEdit] = useState<boolean>(false);
    const [lang, setLang] = useState<string>('en');
    const [selectedGames, setSelectedGames] = useState<Array<string>>(broadcast.games);
    const [broadcastState, setBroadcastState] = useState<TrainingBroadcast>(broadcast);

    const save = () => {
        if (edit) {
            TrainingService.updateBroadcast(broadcastState._id, broadcastState).then((data) => {
                if (!data) {
                    toast.error('Fail to update broadcast');
                    actionHook(false);
                    return;
                }
                toast.success('Broadcast updated');
                actionHook(true);
            });
        } else {
            TrainingService.createBroadcast(broadcastState).then(data => {
                if (!data) {
                    toast.error('Fail to create broadcast');
                    actionHook(false);
                    return;
                }
                toast.success('Broadcast created');
                actionHook(true);
            });
        }
    };

    const handleGamesChange = (event: any) => {
        broadcastState.games = event.target.value;
        setBroadcastState(broadcastState);
        setSelectedGames(event.target.value as string[]);
    };

    const onBroadcastInfoChange = (event: any, isTranslatedString = false, optionalArgument: any = undefined) => {
        const name= event.currentTarget.name;
        let value = event.currentTarget.value;

        if (isTranslatedString) {
            const myBSAttr = broadcastState[name as keyof TrainingBroadcast] as TranslatedString;
            myBSAttr[lang] = value;
            setBroadcastState({ ...broadcastState, [name.lang]: value });
        } else {
            if (optionalArgument !== undefined) {
                value = optionalArgument;
            }
            setBroadcastState(Object.assign({}, broadcastState, {
                [name]: value,
            }));
        }

    };

    return (
        <>
            <div className="w-100 d-flex">
                <div className="w-50">
                    {
                        !creationMode &&
                        <FormControlLabel
                            control={<Switch color="primary" checked={edit} onChange={() => setEdit(!edit)}/>}
                            label="Edit"
                            labelPlacement="start"
                        />
                    }

                </div>
                <div className="w-50">
                    {
                        (edit || creationMode) &&
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                save();
                            }}
                        >
                            Save
                        </Button>
                    }
                </div>
            </div>
            <div className="w-100">
                <div className="d-flex mb-3">
                    {
                        !creationMode &&
                        <TextField
                            disabled
                            label="ID"
                            name="_id"
                            defaultValue={broadcastState._id ? broadcastState._id : ''}
                            className="w-50"
                        />
                    }
                    <div className="w-50 mx-auto d-flex">
                        <div className="flex-fill">
                            <LangPicker
                                setLang={setLang}
                            />
                        </div>
                        <FormControlLabel
                            value="start"
                            disabled={!edit && !creationMode}
                            className="flex-fill"
                            control={
                                <Switch color="primary"
                                        checked={broadcastState.disabled}
                                        name="disabled"
                                        onChange={(event: any) => onBroadcastInfoChange(event, false, !broadcast.disabled)}
                                />
                            }
                            label="Disable"
                            labelPlacement="start"
                        />
                        <FormControlLabel
                            value="start"
                            disabled
                            className="flex-fill"
                            control={
                                <Switch
                                    color="primary"
                                    checked={broadcastState.primary}
                                    name="primary"
                                    onChange={(event: any) => onBroadcastInfoChange(event, false, !broadcast.primary)}
                                />
                            }
                            label="Primary"
                            labelPlacement="start"
                        />
                    </div>
                </div>
                <div className="d-flex mb-3">
                    <TextField
                        disabled={!edit && !creationMode}
                        label="Message"
                        name="message"
                        className="w-100"
                        value={broadcastState.message[lang] || ''}
                        onChange={(event: any) => onBroadcastInfoChange(event, true)}
                    />
                </div>
                <div className="d-flex mb-3">
                    <FormControl className="col-12 col-sm-6 px-3">
                        <InputLabel>Games</InputLabel>
                        <Select
                            disabled={!edit && !creationMode}
                            multiple
                            value={selectedGames}
                            onChange={handleGamesChange}
                            input={<Input/>}
                            renderValue={(selected) => {
                                return (
                                    <div>
                                        {
                                            (selected as Array<string>).map((g: string, index: number) => {
                                                const tto = gameList && gameList.find(game => game._id === g);
                                                return <div className="mx-1" key={index}>{tto ? tto.name : ''}</div>;
                                            })
                                        }
                                    </div>
                                );
                            }}
                        >
                            {
                                gameList && gameList.map((game: Game) => (
                                    <MenuItem key={game._id} value={game._id}>
                                        <Checkbox checked={selectedGames.indexOf(game._id) > -1}/>
                                        <ListItemText primary={game.name}/>
                                    </MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl>
                </div>
            </div>
        </>
    );
};