import React, {useEffect, useState} from 'react';
import {NCTitle, NCTitleType} from 'src/atoms/NCTitle/NCTitle';
import {
    MatchConflictedStatus,
    MatchConflictedSubject,
    TournamentMatchConflited
} from 'src/models/TournamentMatch';
import RefreshIcon from '@material-ui/icons/Refresh';
import VisibilityIcon from '@material-ui/icons/Visibility';
import {NtService} from 'src/services/nt.service';
import {
    TextField,
    Button,
    Checkbox,
    CircularProgress,
    FormControl,
    IconButton,
    InputLabel,
    Link,
    ListItemText,
    MenuItem,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from '@material-ui/core';
import {DynamoPagination} from 'src/atoms/DynamoPagination/DynamoPagination';
import StarIcon from '@material-ui/icons/Star';
import {Done} from '@material-ui/icons';
import moment from 'moment';
import {toast} from 'react-toastify';
import {store} from 'src/store';
import {Enum} from '../../../utils/Enum';

const matchConflictedStatus = Enum.keys(MatchConflictedStatus);

export const TournamentMatchRefereeing: React.FunctionComponent = () => {
    document.title = 'Match Refereing list';
    const [requests, setRequests] = useState<Array<TournamentMatchConflited>>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [lastKeys, setLastKeys] = useState<Array<any>>([]);
    const [page, setPage] = useState<number>(0);
    const [isLastPage, setIsLastPage] = useState<boolean>(true);
    const [partner, setPartner] = useState<string>("");
    const [seconds, setSeconds] = useState<any>(0);
    const [requestStatuses, setRequestStatuses] = useState<Array<MatchConflictedStatus | string>>([
        matchConflictedStatus[MatchConflictedStatus.InProgress],
        matchConflictedStatus[MatchConflictedStatus.Pending]
    ]);

    useEffect(() => {
        updateList();
        const interval = setInterval(() => {
            setSeconds(seconds + 1);
        }, 30000);
        return () => clearInterval(interval);
        // eslint-disable-next-line
    }, [requestStatuses, page, lastKeys, seconds, partner]);

    const getStateOrder = (state: MatchConflictedStatus):number => {
        switch(state) {
            case MatchConflictedStatus.Pending :
                return 1;
            case MatchConflictedStatus.InProgress :
                return 2;
            case MatchConflictedStatus.Closed :
                return 3;
            default:
                return 0;
        }
    }

    const onPartnerChange = (event: any) => {
        setPartner(event.target.value);
    }

    const updateList = () => {
        setIsLoading(true);
        let query = '';
        if (lastKeys.length > 0) {
            if (page > 0 && lastKeys[page - 1]) {
                query = query.concat(query, `&id=${lastKeys[page - 1].id}`);
            }
        }
        const state = requestStatuses.map(v => v).join(',');
        NtService.getMatchesRequestList(state, query).then(data => {
            let res = data.docs;
            if (partner !== "") {
                res = data.docs.filter((item: TournamentMatchConflited) => { return item.p.toLowerCase().includes(partner.toLowerCase())})
            }
            res.sort((a: TournamentMatchConflited, b: TournamentMatchConflited) => {
                return getStateOrder(a.status) - getStateOrder(b.status);
            });
            res.sort((a: TournamentMatchConflited, b: TournamentMatchConflited) => {
                if (a.status === MatchConflictedStatus.InProgress && b.status === MatchConflictedStatus.InProgress) {
                    if (b.assignee && a.assignee && (a.assignee < b.assignee)) {
                        return -1;
                    }
                    if (b.assignee && a.assignee && (a.assignee > b.assignee)) {
                        return 1;
                    }
                }
                return 0;
            })
            setRequests(res);
            if (data.last) {
                lastKeys[page] = data.last;
                setLastKeys(lastKeys);
                setIsLastPage(false);
            } else {
                setIsLastPage(true);
            }
            setIsLoading(false);
        });
    };

    // eslint-disable-next-line
    const changeRequestStatus = (id: string, route: string, status: number, matchUrl: string) => {
        let name;
        if (store.getState().currentAdministrator !== null) {
            name = store.getState().currentAdministrator?.first_name;
        }
        NtService.requestHandler(id, route, status, name).then((data) => {
            if (!data) {
                toast.error('Too late, it\'s seems that some one already take care of it');
                return;
            }
            toast.success('You changed the status of the request');
        }).then(() => updateList());
    };

    const renderMatchConflictedStatus = (status: MatchConflictedStatus) => {
        switch (status) {
            case 0:
                return <span
                    className="orange-color font-weight-bold text-uppercase">{MatchConflictedStatus[status]}</span>;
            case 1:
                return <span
                    className="yellow-color font-weight-bold text-uppercase">{MatchConflictedStatus[status]}</span>;
            case 2:
                return <span
                    className="primary-color font-weight-bold text-uppercase">{MatchConflictedStatus[status]}</span>;
            default:
                return <span className="font-weight-bold text-uppercase">{MatchConflictedStatus[status]}</span>;
        }
    };

    const RenderRequestDuration = ({ match }: { match: TournamentMatchConflited }) => {
        const [ u, setU ] = useState(false);

        useEffect(() => {
            const t = setTimeout(() => setU(!u), 1000);
            return () => clearTimeout(t);
        }, [ u ]);

        const duration = moment.duration(Date.now() - new Date(match.rDate).getTime());
        return <>{moment.duration(duration, 'second').format('hh:mm:ss')}</>;
    }

    return (
        <div className="NC-container NC-card d-flex flex-column">
            <NCTitle text={'Match refereeing list'} type={NCTitleType.TOURNAMENT}/>
            <div className="d-flex">
                <DynamoPagination
                    page={page}
                    setPage={setPage}
                    itemPerPage={20}
                    displayNext={isLastPage}
                />
                <FormControl className="mx-2" style={{width: '250px'}}>
                    <InputLabel>Status</InputLabel>
                    <Select
                        multiple
                        value={requestStatuses}
                        onChange={ev => setRequestStatuses(ev.target.value as Array<MatchConflictedStatus>)}
                        renderValue={(selected) => (selected as string[]).join(',')}
                    >
                        {
                            Object.keys(MatchConflictedStatus).filter((_, v) => MatchConflictedStatus[v]).map((state: string, index: number) => (
                                <MenuItem key={index} value={state}>
                                    <Checkbox checked={requestStatuses.indexOf(state) > -1}/>
                                    <ListItemText primary={state as string}/>
                                </MenuItem>
                            ))
                        }
                    </Select>
                </FormControl>
                <div className="w-75 d-flex justify-content-center align-items-center">
                    <TextField
                        id="partner-local"
                        label="Partner"
                        type="partner-local"
                        defaultValue={partner}
                        className="mx-2"
                        onChange={(event: any) => {
                            onPartnerChange(event)
                        }}
                    />
                </div>
                {
                    isLoading ?
                        <CircularProgress size='53px' style={{padding: '12px'}}/> :
                        <IconButton onClick={updateList}>
                            <RefreshIcon/>
                        </IconButton>
                }
            </div>
            {
                !isLoading && requests.length > 0 &&
                <div>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell align="left">Tournament</TableCell>
                                    <TableCell align="left">Partner</TableCell>
                                    <TableCell align="left">Subject</TableCell>
                                    <TableCell align="left">Status</TableCell>
                                    <TableCell align="left">User</TableCell>
                                    <TableCell align="left">Date</TableCell>
                                    <TableCell align="left">Actions</TableCell>
                                    <TableCell align="left">Assignee</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    (requests as Array<TournamentMatchConflited>).map((match: TournamentMatchConflited, index: number) => {
                                        return (
                                            <TableRow key={index}>
                                                <TableCell align="left">{match.name}</TableCell>
                                                <TableCell align="left">{match.p}</TableCell>
                                                <TableCell align="left">{MatchConflictedSubject[match.subject]}</TableCell>
                                                <TableCell
                                                    align="left">{renderMatchConflictedStatus(match.status)}
                                                </TableCell>
                                                <TableCell>{match.user ? match.user.name : ""}</TableCell>
                                                <TableCell><RenderRequestDuration match={ match } /></TableCell>
                                                <TableCell className="d-flex justify-content-between">
                                                    <Link href={`${match.matchUrl}?request=${match.route}&state=0`}
                                                          target="_blank">
                                                        <Button variant="outlined">
                                                            <VisibilityIcon className="primary-color"/>
                                                        </Button>
                                                    </Link>
                                                    {
                                                        match.status !== 1 &&
                                                        <Button variant="outlined"
                                                                onClick={() => changeRequestStatus(match.id, match.route, 1, match.matchUrl)}>
                                                            <StarIcon className="primary-color"/>
                                                        </Button>
                                                    }
                                                    {
                                                        match.status !== 2 &&
                                                        <Button variant="outlined"
                                                                onClick={() => changeRequestStatus(match.id, match.route, 2, match.matchUrl)}>
                                                            <Done className="primary-color"/>
                                                        </Button>
                                                    }
                                                </TableCell>
                                                <TableCell align="left">{match.assignee}</TableCell>
                                            </TableRow>
                                        );
                                    })
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            }
        </div>
    );
};