import axios, { AxiosError } from 'axios';
import { toast } from 'react-toastify';
import { VideoModel } from 'src/models/Video';

export class MediaService {
    private static baseUrl: string = String(process.env.REACT_APP_MEDIA_URL);
    static token = (): string | null => localStorage.getItem('accessToken');

    static async getVideos(limit: number = 20, page: number = 0): Promise<any> {
        let res;

        try {
            res = (await axios.get(`${MediaService.baseUrl}/video?limit=${limit}&page=${page}`, {
                headers: {
                    'x-access-token': MediaService.token(),
                }
            }));
        } catch (e) {
            throw (e as AxiosError).response;
        }

        return {
            docs: res.data.data.docs,
            page: res.data.data.page,
            total: res.data.data.total,
        };
    }


    static async updateVideo(body: VideoModel): Promise<boolean> {
        if (!body) {
            return false;
        }

        try {
            await axios.put(
                `${MediaService.baseUrl}/video`,
                body, {
                    headers: {
                        'x-access-token': MediaService.token(),
                    }
                }
            );
        } catch {
            toast.error('Fail to edit video, try again later or contact #public-backoffice');
            return false;
        }

        toast.success('Video edited');
        return true;
    }


    static async createVideo(body: VideoModel): Promise<VideoModel> {
        if (!body) {
            throw Error;
        }

        let newVideo: VideoModel;
        delete body['_id' as keyof VideoModel];
        try {
            newVideo = (await axios.post(
                `${MediaService.baseUrl}/video`,
                body, {
                    headers: {
                        'x-access-token': MediaService.token(),
                    }
                }
            )).data.data;
        } catch {
            toast.error('Fail to create video, try again later or contact #public-backoffice');
            throw Error;
        }

        toast.success('Video created');
        return newVideo;
    }

    static async deleteVideo(_id: string): Promise<boolean> {
        if (!_id) {
            return false;
        }

        try {
            await axios.delete(
                `${MediaService.baseUrl}/video/${_id}`, {
                    headers: {
                        'x-access-token': MediaService.token(),
                    }
                }
            );
        } catch {
            toast.error('Fail to delete video, try again later or contact #public-backoffice');
            return false;
        }

        toast.success('Video deleted');
        return true;
    }

}