import axios, { AxiosError } from 'axios';
import { toast } from 'react-toastify';
import { OrganizationModel } from 'src/models/Organization';

export class OrganizationService {
    private static baseUrl: string = String(process.env.REACT_APP_ADMINAPI_URL);
    static token = (): string | null => localStorage.getItem('accessToken');

    static async getOrganizations(): Promise<any> {
        let res;

        try {
            res = await axios.get(
                `${OrganizationService.baseUrl}/organisation/all`, {
                    headers: {
                        'x-access-token': OrganizationService.token(),
                    }
                }
            );
        } catch (e) {
            throw (e as AxiosError).response;
        }

        return res.data;
    }

    static async getOrganization(id: string): Promise<any> {
        let res;

        try {
            res = await axios.get(
                `${OrganizationService.baseUrl}/organisation/${id}`,
            );
        } catch (e) {
            throw (e as AxiosError).response;
        }

        return res.data;
    }

    static async getOrganizationByCompany(id: string): Promise<any> {
        let res;

        try {
            res = await axios.get(
                `${OrganizationService.baseUrl}/organisation/by-company/${id}`,
            );
        } catch (e) {
            throw (e as AxiosError).response;
        }

        return res.data;
    }

    static async createOrganization(organization: OrganizationModel): Promise<OrganizationModel> {
        const token = localStorage.getItem('accessToken');
        let res;

        try {
            res = await axios.post(
                `${OrganizationService.baseUrl}/organisation`,
                organization,
                {
                    headers: {
                        'x-access-token': token,
                    }
                }
            );
        } catch (e) {
            toast.error('Fail to create organization, try again later or contact #public-backoffice');
            throw (e as AxiosError).response;
        }
        toast.success('Organization created');
        return new OrganizationModel(
            res.data.name,
            res.data.domain,
            res.data.games,
            res.data.color,
            res.data.social,
            res.data.legals,
        );
    }

    static async updateOrganization(organization: any): Promise<boolean> {
        try {
            await axios.put(`${OrganizationService.baseUrl}/organisation/${organization._id}`, organization, {
                headers: {
                    'x-access-token': OrganizationService.token(),
                }
            });
        } catch (e) {
            toast.error('Fail to update organization, try again later or contact #public-backoffice');
            return false;
        }
        toast.success('Organization updated');
        return true;
    }

    static async deleteOrganization(organization: any): Promise<any> {
        let res;

        try {
            res = await axios.delete(`${OrganizationService.baseUrl}/organisation/${organization._id}`, {
                headers: {
                    'x-access-token': OrganizationService.token(),
                }
            });
        } catch (e) {
            toast.error('Currently not implemented, try again later');
            //toast.error('Fail to delete organization, try again later or contact #public-backoffice');
            throw (e as AxiosError).response;
        }
        toast.success('Organization deleted');
        return res.data;
    }
}