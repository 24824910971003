import { CircularProgress } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { NCTitle } from "src/atoms/NCTitle/NCTitle";
import { AccountForm } from "src/components/GameAccount/AccountForm/AccountForm";
import { ActionDialog } from "src/components/General/ActionDialog/ActionDialog";
import { FullScreenDialog } from "src/components/General/FullScreenDialog/FullScreenDialog";
import { SimpleList } from "src/components/General/SimpleList/SimpleList";
import { Account, AccountType } from "src/models/GameAccount";
import { GameAccountService } from "src/services/game-account.service";

export const GameAccounts: React.FunctionComponent = () => {
    const [account, setAccount] = useState<Account | undefined>(undefined);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [rows, setRows] = useState<Array<Account>>([]);
    const [actionCreate, setActionCreate] = useState<boolean>(false);
    const [actionDelete, setActionDelete] = useState<boolean>(false);
    const [actionEdit, setActionEdit] = useState<boolean>(false);
    const [lastKeys, setLastKeys] = useState<{ [page: number]: any | undefined }>({});
    const [actualPage, _setActualPage] = useState<number>(0);
    const [totalItem, setTotalItem] = useState<number>(0);

    const columns = ['Title', 'Atype', 'Active'];
    const keepRows = ['title', 'atype', 'active'];

    useEffect(() => {
        getAccounts();
        // eslint-disable-next-line
    }, [actualPage]);

    const setActualPage = (page: number) => {
        _setActualPage(page);
    };

    const actionManager = (account: Account, kind: string) => {
        switch (kind) {
            case 'create':
                setAccount(
                    new Account(
                        1,
                        AccountType.EDITOR,
                        '',
                        '',
                        '',
                        '',
                        ''
                    )
                );
                setActionCreate(true);
                break;
            case 'edit':
                setAccount(account);
                setActionEdit(true);
                break;
            case 'delete':
                setAccount(account);
                setActionDelete(true);
                break;
        }
    }

    const getAccounts = async () => {
        const lk = lastKeys[actualPage - 1];
        const res = await GameAccountService.getAccountList(128, lk?.id);
        const newLK = res.lastKey?.route ? res.lastKey : undefined;
        setLastKeys({ ...lastKeys, [actualPage]: newLK });
        setTotalItem(20 * actualPage + res.list.length + (newLK ? 20 : 0));
        setIsLoading(false);
        if (res && res.list) {
            setRows(res.list);
        }
    }

    const deleteAccount = (choice: boolean) => {
        if (choice && account) {
            toast.success('Feature not implemented');
            updateList(true);
        }
    }

    const updateList = (result: boolean) => {
        setActionCreate(false);
        setActionDelete(false);
        setActionEdit(false);
        if (result) {
            setIsLoading(true);
            getAccounts();
        }
    }

    return (
        <div className="row">
            <div className="NC-container NC-card col d-flex flex-column">
                <NCTitle text={'Game account'} />
                <div className="d-flex">
                    {
                        isLoading && <CircularProgress className="mx-auto" />
                    }
                    {
                        !isLoading &&
                        <SimpleList
                            rows={rows}
                            columns={columns}
                            keepRows={keepRows}
                            actionsButtons={{ create: true, edit: true, delete: false }}
                            handleClickOpen={actionManager}
                            pagination={{ actual: actualPage, total: Math.ceil(totalItem / 20), setActual: setActualPage }}
                        />
                    }
                </div>
                <ActionDialog
                    open={actionDelete}
                    title={`Are you sure to delete this account: ${account?.title}`}
                    openHook={setActionDelete}
                    actionHook={deleteAccount}
                />
                {
                    account &&
                    <FullScreenDialog
                        open={actionCreate}
                        openHook={setActionCreate}
                        actionHook={updateList}
                        componentToDisplay={
                            <AccountForm
                                account={account}
                                setAccount={setAccount}
                                creationMode
                                actionHook={updateList}
                            />
                        }
                    />
                }
                {
                    account &&
                    <FullScreenDialog
                        open={actionEdit}
                        openHook={setActionEdit}
                        actionHook={updateList}
                        componentToDisplay={
                            <AccountForm
                                account={account}
                                setAccount={setAccount}
                                actionHook={updateList}
                            />
                        }
                    />
                }
            </div>
        </div>
    );
}