/* eslint-disable */
import { AppBar, useTheme } from '@material-ui/core';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { TabPanel } from 'src/atoms/TabPanel/TabPanel';
import { MediaManager } from 'src/components/General/MediaManager/MediaManager';
import { EUserMedias } from 'src/models/User';
import { UserAccount } from '../UserAccount/UserAccount';
import { UserActivity } from '../UserActivity/UserActivity';
import { UserGameAccounts } from '../UserGameAccounts/UserGameAccounts';
import { UserGames } from '../UserGames/UserGames';
import { UserInfos } from '../UserInfos/UserInfos';
import { UserSanction } from '../UserSanction/UserSanction';
import { UserSettings } from '../UserSettings/UserSettings';
import { UserTeams } from '../UserTeams/UserTeams';
import { UserTournaments } from '../UserTournaments/UserTournaments';
import { UserWallet } from '../UserWallet/UserWallet';
import { User } from '@wolfiesports/srm-component';

enum TabsName {
    Info = 0,
    Account = 1,
    Games = 2,
    Activity = 3,
    Medias = 4,
    Settings = 5,
    Wallet = 6,
    GameAccount = 7,
    Sanction = 8,
    Team = 9,
    Tournament = 10,
}

interface UserFormProps {
    user: User;
    updateUser?: () => any;
    tab: number;
    tabChange?: (tabIndex: number) => any;
}

export const UserForm: React.FunctionComponent<UserFormProps> = ({user, updateUser, tab, tabChange}: UserFormProps) => {
    const theme = useTheme();
    const [change, setChange] = useState<boolean>(false);

    function a11yProps(index: any) {
        return {
            id: `full-width-tab-${index}`,
            'aria-controls': `full-width-tabpanel-${index}`,
        };
    }

    useEffect(() => {
        setChange(false);
    }, [change]);

    const handleUserChange = (user: User) => {
      /*  UserService.updateUser(user).then(() => {
            NoUserService.getNoUserById(user._id).then((r) => {
                if (!r) {
                    toast.error('Failed to update user');
                    return;
                }
                toast.success('User updated');
                // eslint-disable-next-line
                updateUser?.();
                setChange(true);
            });
        })*/
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>, newValue: number) => {
        // eslint-disable-next-line
        tabChange?.(newValue);
    };

    if (user) {
        return (
            <div className="user-form w-100 NC-tab">
                <AppBar position="static" color="default" className="mb-2">
                    <Tabs
                        value={tab}
                        onChange={(event: React.ChangeEvent<any>, newValue: any) =>
                            handleChange(event, newValue)
                        }
                        variant="scrollable"
                        scrollButtons="auto"
                    >
                        <Tab label="Infos" {...a11yProps(TabsName.Info)} />
                        <Tab label="Account" {...a11yProps(TabsName.Account)} />
                        <Tab label="Games" {...a11yProps(TabsName.Games)} />
                        <Tab label="Activity" {...a11yProps(TabsName.Activity)}/>
                        <Tab label="Medias" {...a11yProps(TabsName.Medias)}/>
                        <Tab label="Settings" {...a11yProps(TabsName.Settings)}/>
                        <Tab label="Wallet" {...a11yProps(TabsName.Wallet)}/>
                        <Tab label="Game Account" {...a11yProps(TabsName.GameAccount)}/>
                        <Tab label="Sanction" {...a11yProps(TabsName.Sanction)}/>
                        <Tab label="Team" {...a11yProps(TabsName.Team)}/>
                        <Tab label="Tournament" {...a11yProps(TabsName.Tournament)}/>
                    </Tabs>
                </AppBar>
                <TabPanel value={tab} index={TabsName.Info} dir={theme.direction}>
                    <UserInfos user={user} userChange={handleUserChange}/>
                </TabPanel>
                <TabPanel value={tab} index={TabsName.Account} dir={theme.direction}>
                    <UserAccount user={user} userChange={handleUserChange}/>
                </TabPanel>
                <TabPanel value={tab} index={TabsName.Games} dir={theme.direction}>
                    <UserGames user={user}/>
                </TabPanel>
                <TabPanel value={tab} index={TabsName.Activity} dir={theme.direction}>
                    <UserActivity user={user}/>
                </TabPanel>
                <TabPanel value={tab} index={TabsName.Medias} dir={theme.direction}>
                    <div className="NC-card">
                        <MediaManager
                            path={['user']}
                            secondPath={['medias']}
                            id={user.userId}
                            mediaNames={Object.keys(EUserMedias)}
                        />
                    </div>
                </TabPanel>
                <TabPanel value={tab} index={TabsName.Settings} dir={theme.direction}>
                    <UserSettings user={new User(user)} userChange={handleUserChange}/>
                </TabPanel>
                <TabPanel value={tab} index={TabsName.Wallet} dir={theme.direction}>
                    <UserWallet user={user}/>
                </TabPanel>
                <TabPanel value={tab} index={TabsName.GameAccount} dir={theme.direction}>
                    <UserGameAccounts user={user}/>
                </TabPanel>
                <TabPanel value={tab} index={TabsName.Sanction} dir={theme.direction}>
                    <UserSanction user={user}/>
                </TabPanel>
                <TabPanel value={tab} index={TabsName.Team} dir={theme.direction}>
                    <UserTeams user={user}/>
                </TabPanel>
                <TabPanel value={tab} index={TabsName.Tournament} dir={theme.direction}>
                    <UserTournaments user={user} />
                </TabPanel>
            </div>
        );
    } else {
        return (
            <div>Something went wrong, try again later</div>
        );
    }
};