import axios, { AxiosError } from 'axios';
import { Country } from 'src/models/Country';
import { GroupCountry } from './../models/GroupCountry';

export class CountryService {
    private static baseUrl: string = String(process.env.REACT_APP_ADMINAPI_URL);
    static token = (): string | null => localStorage.getItem('accessToken');

    static async getCountries(limit: number = 10, page: number = 0, research?: {key: string, item: string}) {
        let res;
        let query = '';

        if (research) {
            query = `&${research.key}=${research.item}`;
        }

        try {
            res = await axios.get(
                `${CountryService.baseUrl}/countries?limit=${limit}&page=${page}${query}`, {
                    headers: {
                        'x-access-token': CountryService.token(),
                    }
                }
            );
        } catch (e) {
            throw (e as AxiosError).response;
        }

        let countryList = [];
        if (!res.data && !res.data.docs) {
            throw Error;
        }
        countryList = res.data.docs.map((item: any) => {
            return new Country(
                item._id,
                item.langs,
                item.name,
                item.active,
                item.tax,
                item.iso2,
                item.createdAt,
            );
        });
        return {
            docs: countryList,
            page: res.data.page,
            pages: res.data.pages,
            total: res.data.total,
        }
    }

    static async getAllCountries() {
        let countriesList: Array<Country> = [];

        try {
            countriesList = (await axios.get(`${CountryService.baseUrl}/countries/all`, {
                headers: {
                    'x-access-token': CountryService.token(),
                }
            })).data.docs;
            countriesList.sort((a: Country, b: Country) => {
                if (a.name < b.name) {
                    return -1;
                }
                if (a.name > b.name) {
                    return 1;
                }
                return 0;
            })

        } catch (e) {
            throw (e as AxiosError).response;
        }

        return countriesList;
    }

    static async getGroupCountries(limit: number = 10, page: number = 0) {
        let res;

        try {
            res = await axios.get(
                `${CountryService.baseUrl}/groupcountry?limit=${limit}&page=${page}`, {
                    headers: {
                        'x-access-token': CountryService.token(),
                    }
                }
            );
        } catch (e) {
            throw (e as AxiosError).response;
        }

        let groupCountries = [];
        if (!res.data && !res.data.docs) {
            throw Error;
        }
        groupCountries = res.data.docs.map((item: any) => {
            return new GroupCountry(
                item._id,
                item.value,
                item.countries,
                item.isHomeFilter,
            );
        });
        return {
            docs: groupCountries,
            page: res.data.page,
            pages: res.data.pages,
            total: res.data.total,
        }
    }

    static async createGroupCountry(body: {value: string, countries: Array<string>} ) {
        if (!body.value) {
            return false;
        }

        try {
            await axios.post(
                `${CountryService.baseUrl}/groupcountry`, body, {
                    headers: {
                        'x-access-token': CountryService.token(),
                    }
                }
            );
        } catch (e) {
            throw (e as AxiosError).response;
        }
        return true;
    }

    static async updateGroupCountry(groupCountry: GroupCountry) {
        if (!groupCountry || !groupCountry._id) {
            return false;
        }

        try {
            await axios.put(
                `${CountryService.baseUrl}/groupcountry/${groupCountry._id}`, groupCountry, {
                    headers: {
                        'x-access-token': CountryService.token(),
                    }
                }
            );
        } catch (e) {
            throw (e as AxiosError).response;
        }
        return true;
    }

    static async deleteGroupCountry(id: string) {
        if (!id) {
            return false;
        }

        try {
            await axios.delete(
                `${CountryService.baseUrl}/groupcountry/${id}`, {
                    headers: {
                        'x-access-token': CountryService.token(),
                    }
                }
            );
        } catch (e) {
            throw (e as AxiosError).response;
        }
        return true;
    }
}