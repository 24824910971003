import './RenderTree.scss';

import { BlockType, Logical, PredicateEditionAugmented, TrainingBrick, TrainingBrickAssignement } from 'src/models/TrainingBrick';
import React, { useEffect, useState } from 'react';

import { GamePartial } from 'src/models/GamePartial';
import PremiumIcon from 'src/assets/system/premium-gradient.svg';
import { TrainingModule } from 'src/models/TrainingModule';
import { TrainingService } from 'src/services/training.service';
import { TrainingThematic } from 'src/models/TrainingThematic';
import { toast } from 'react-toastify';

interface RenderTreeProps {
    module: TrainingModule;
    primaryBlock: any;
    gameId: string;
    modulePath: string;
    lang: string;
    thematics: Array<TrainingThematic>;
    selectedGame: GamePartial;
    isPublished: boolean,
    resetTrigger?: boolean,
    blockId?: string;
    selectedPrerequisite?: PredicateEditionAugmented;
    onChangeBrick: (brick: TrainingBrick, row: number, column: number, create: boolean) => void;
    toCapitalizeCase: (text: string) => string;
    onRefreshBrick: () => void;
}

export const RenderTree: React.FunctionComponent<RenderTreeProps> = (props: RenderTreeProps) => {
    const maxBricks = 3;
    const startCondition = { members: [], operator: Logical.AND, type: 'LOGICAL' };
    const winCondition = { members: [], operator: Logical.AND, type: 'LOGICAL' };
    const newBrick: TrainingBrickAssignement = new TrainingBrickAssignement(
        {},
        {},
        '',
        [],
        '',
        startCondition,
        winCondition,
        props.selectedGame,
        5,
        [],
        0,
        0,
    );

    const [ brick, setBrick ] = useState<number>(-1);
    const [ blockIndex, setBlockIndex ] = useState<number>(-1);
    const [ brickIndex, setBrickIndex ] = useState<number>(-1);

    useEffect(() => {
        setBrick(-1);
    }, [ props.resetTrigger ]);

    const addBlock = () => {
        const rows = props.primaryBlock.children[0].children;
        if (rows.length === 0 || (rows.length > 0 && rows[rows.length - 1].children.length > 0)) {
            const rowNumber = props.primaryBlock.children[0].children.push({
                children: [],
                name: { en: props.toCapitalizeCase(BlockType.PARALLEL.toLowerCase())},
                type: BlockType.PARALLEL,
                completionMandatory: true,
                premium:  props.primaryBlock.children[0].children.length > 0 ?
                    props.primaryBlock.children[0].children[props.primaryBlock.children[0].children.length - 1].premium : false,
            });
            if (props.blockId) {
                addBrick(rowNumber - 1);
                onSelectedBrick(newBrick, rowNumber - 1, 0, true)
                props.onRefreshBrick();
            }
        } else {
            toast.error('You have to save previous row before creating a new one');
            return;
        }
    };

    const addBrick = (i: number) => {
        if (brick > -1 && props.primaryBlock.children[0].children[brick].children.length < 1) {
            props.primaryBlock.children[0].children.splice(brick, 1);
        }
        setBrick(i);
    };

    const onSelectedBrick = (brick: any, row: number, column: number, create: boolean) => {
        props.onChangeBrick(brick, row, column, create);
        setBlockIndex(row);
        setBrickIndex(column);
    };

    const isPrerequisiteIncluded = (startConditions: Array<PredicateEditionAugmented>) => {
        if (props.selectedPrerequisite) {
            for (const predicate of startConditions) {
                if (TrainingService.comparePredicate(predicate, props.selectedPrerequisite)) {
                    return true;
                }
            }
        }
        return false;
    }

    const switchPremiumBlock = (index: number) => {
        if (props.blockId) {
            const value = !props.primaryBlock.children[0].children[index].premium;
            const coef = value ? 1 : -1;
            for (let i = index; i < props.primaryBlock.children[0].children.length && i > -1; i = i + coef) {
                props.primaryBlock.children[0].children[i].premium = value;
            }
            TrainingService.updateBlock(props.blockId, props.primaryBlock);
            props.onRefreshBrick();
        }
    };

    return (
        <div className="w-100 mt-5">
            {
                props.primaryBlock.children && props.primaryBlock.children.length > 0 && props.primaryBlock.children.map((child: any, index: number) => {
                    return (
                        <div key={index} className="d-flex flex-column align-items-center">
                            {
                                child.children && child.children.length > 0 && child.children.map((block: any, row: number) => (
                                    <div key={`${index}/${row}`} className="block d-flex justify-content-center align-items-center">
                                        <div className={`base circle column ${block.premium ? 'premium' : ''}`} onClick={() => switchPremiumBlock(row)}>
                                            <img src={PremiumIcon} alt="-"/>
                                        </div>
                                        {
                                            block.children && block.children.length > 0 && block.children.map((brick: any, column: number) => (
                                                <div key={`${index}/${row}/${column}`}
                                                    className={`base brick ${(row === blockIndex && column === brickIndex) ?'active' : ''}
                                                        ${brick.startCondition && isPrerequisiteIncluded(brick.startCondition.members) ?
                                                        'primary' : block.premium ? 'premium': ''}`}
                                                    onClick={() => onSelectedBrick(brick, row, column, false)}>
                                                    <img alt="thematic"
                                                        src={`${process.env.REACT_APP_S3_URL}/training/thematic/${(props.thematics
                                                        .filter(t => t.value === brick.thematic)
                                                        .map(t => t._id))
                                                        .find(t => t)}/icon`}
                                                    />
                                                </div>
                                            ))
                                        }
                                        {
                                            row === brick && block.children && block.children.length < maxBricks ?
                                            <div key={0} className="base brick new"
                                                onClick={() => onSelectedBrick(newBrick, row, block.children.length, true)}></div>
                                            : null
                                        }
                                        {
                                            (process.env.NODE_ENV !== 'production' || !props.isPublished) &&
                                            block.children && block.children.length < maxBricks && row !== brick ?
                                            <div className="base plus column" onClick={() => addBrick(row)}>+</div> : null
                                        }
                                    </div>
                                ))
                            }
                            {
                                (process.env.NODE_ENV !== 'production' || !props.isPublished) &&
                                <div className="base plus line" onClick={() => addBlock()}>+</div>
                            }
                        </div>
                    );
                })
            }
        </div>
    );
};
