import { TranslatedString } from "./TranslatedString";

export class SkillGame {
    constructor (
        public _id: string,
        public value: string,
        public name: TranslatedString,
        public levels: Array<SkillGameLevel>,
        public published: boolean,
    ){}
}

export class SkillGameLevel {
    constructor (
        public name: TranslatedString,
        public description: TranslatedString,
        public waitingText: TranslatedString,
        public scoreSettings: ScoreSettings,
        public blocks: Array<SkillGameBlock>,
    ) {}
}

export class SkillGameBlock {
        constructor (
            public _id: string,
            public name: TranslatedString,
            public value: string,
            public victory: boolean,
            public abilities: Array<string>,
            public data: Array<SkillGameBlockData>,
        ) {}
}

export class SkillGameBlockData {
    constructor (
        public link: string,
        public win: boolean,
    ) {}
}

export interface ScoreSettings {
    min: number;
    max: number;
    scale: number;
}