import React from 'react';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import IconButton from '@material-ui/core/IconButton';

interface DynamoPaginationProps {
    itemPerPage: number,
    page: number,
    setPage: any,
    displayNext?: boolean;
}

export const DynamoPagination: React.FunctionComponent<DynamoPaginationProps> = (props: DynamoPaginationProps) => {
	const previousPage = () => {
		if ((props.page - 1) >= 0) {
			props.setPage(props.page - 1);
		}
	};

	const nextPage = () => {
		props.setPage(props.page + 1);
	};

	return (
		<div className="w-100 d-flex align-items-center">
			<IconButton
				color="inherit"
				aria-label="open drawer"
				onClick={ previousPage }
			>
				<ChevronLeftIcon />
			</IconButton>
			{
				<div>
					{ props.itemPerPage * props.page + 1 } of { props.itemPerPage * (props.page + 1) }
				</div>
			}
			<IconButton
				disabled={props.displayNext ? props.displayNext : false}
				color="inherit"
				aria-label="open drawer"
				onClick={ nextPage }
			>
				<ChevronRightIcon />
			</IconButton>
		</div>
	);
};