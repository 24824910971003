
import React from 'react';
import { UserInvitationsTable } from '../UserInvitationsTable/UserInvitationsTable';
import { UserTeamsTable } from '../UserTeamsTable/UserTeamsTable';
import './UserTeams.scss';
import { User } from '@wolfiesports/srm-component';

interface UserTeamsProps {
    user: User;
}

export const UserTeams: React.FunctionComponent<UserTeamsProps> = (
    props: UserTeamsProps
) => {
    const { userId } = props.user;
    return (
        <div className="NC-card user-teams-list-container d-flex flex-column">
           <div className="NC-info invitations-list-title d-flex">
                <span className="primary-color big-text mx-3">Invitations</span>
            </div>
            <UserInvitationsTable userId={userId} />
            <div className="NC-info teams-list-title d-flex">
                <span className="primary-color big-text mx-3">Teams</span>
            </div>
            <UserTeamsTable userId={userId} />
        </div>
    );
};