import React, {useEffect, useState} from 'react';
import {AdministratorService} from 'src/services/administrator.service';
import {SimpleList} from 'src/components/General/SimpleList/SimpleList';
import {ActionDialog} from 'src/components/General/ActionDialog/ActionDialog';
import {CircularProgress} from '@material-ui/core';
import {FullScreenDialog} from 'src/components/General/FullScreenDialog/FullScreenDialog';
import {AdministratorsForm} from 'src/components/Administrators/AdministratorsForm/AdministratorsForm';
import {Administrator, AdministratorsLanguage} from 'src/models/Administrator';
import {NCTitle, NCTitleType} from 'src/atoms/NCTitle/NCTitle';
import {CompanyService} from 'src/services/company.service';
import {Company} from 'src/models/Company';

export const Administrators: React.FunctionComponent = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [rows, setRows] = useState([]);
    const [administrator, setAdministrator] = useState<Administrator>();
    const [companies, setCompanies] = useState<Array<Company>>();
    const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
    const [openEditDialog, setOpenEditDialog] = useState<boolean>(false);
    const [openCreateDialog, setOpenCreateDialog] = useState<boolean>(false);
    const [actualPage, setActualPage] = useState<number>(0);
    const [totalItem, setTotalItem] = useState<number>(0);
    const columns = ['Company', 'First name', 'Last name', 'Email'];
    const keepRows = ['company_name', 'first_name', 'last_name', 'email'];

    useEffect(() => {
        AdministratorService.getAdministrators(20, '').then((data) => {
            console.log('administrators', data);
            setTotalItem(data.total);
            setIsLoading(false);

            CompanyService.getAllCompanies().then((companies) => {
                for (const admin of data.docs) {
                    for (const company of companies) {
                        if (admin.company === company._id) {
                            admin.company_name = company.name;
                        }
                    }
                }
                setRows(data.docs);
                setCompanies(companies);
            });

        }).catch(error => console.log(error));
    }, [actualPage]);

    const handleClickOpen = (administrator: Administrator, kind: string) => {
        setAdministrator(administrator);

        switch (kind) {
            case 'delete':
                setOpenDeleteDialog(true);
                break;
            case 'edit':
                setOpenEditDialog(true);
                break;
            case 'create': {
                setAdministrator(new Administrator('', '', '', '', AdministratorsLanguage.ENGLISH));
                setOpenCreateDialog(true);
                break;
            }
            default:
                return;
        }
    };

    const deleteAdmin = (choice: boolean) => {
        if (choice) {
            AdministratorService.deleteAdministrator(administrator).then(() => {
                AdministratorService.getAdministrators(20, '').then((data) => {
                    for (const admin of data.docs) {
                        if (companies) {
                            for (const company of companies) {
                                if (admin.company === company._id) {
                                    admin.company_name = company.name;
                                }
                            }
                        }
                    }
                    setTotalItem(Math.ceil(data.total / 20));
                    setRows(data.docs);
                    setIsLoading(false);
                }).catch(error => console.log(error));
            }).catch(error => console.log(error));
        }
    };

    const updateList = () => {
        setOpenEditDialog(false);
        setOpenCreateDialog(false);
        AdministratorService.getAdministrators(20, '').then((data) => {
            for (const admin of data.docs) {
                if (companies) {
                    for (const company of companies) {
                        if (admin.company === company._id) {
                            admin.company_name = company.name;
                        }
                    }
                }
            }
            setTotalItem(Math.ceil(data.total / 20));
            setRows(data.docs);
            setIsLoading(false);
        }).catch(error => console.log(error));
    };


    return (
        <div className="NC-container NC-card">
            <NCTitle text={`Administrators (${totalItem ? totalItem : '0'})`} type={NCTitleType.ADMINISTRATOR}/>
            <div className="d-flex">
                {
                    isLoading && <CircularProgress className="mx-auto"/>
                }
                {
                    !isLoading &&
                    <SimpleList
                        rows={rows}
                        columns={columns}
                        keepRows={keepRows}
                        actionsButtons={{create: true, edit: true, delete: true}}
                        handleClickOpen={handleClickOpen}
                        pagination={{actual: actualPage, total: Math.ceil(totalItem / 20), setActual: setActualPage}}
                    />
                }
            </div>
            <ActionDialog
                open={openDeleteDialog}
                title={`Are you sure to delete the administrator : ${administrator?.first_name} ${administrator?.last_name}`}
                openHook={setOpenDeleteDialog}
                actionHook={deleteAdmin}
            />
            <FullScreenDialog
                open={openEditDialog}
                openHook={setOpenEditDialog}
                actionHook={updateList}
                componentToDisplay={<AdministratorsForm administrator={administrator}
                                                        setAdministrator={setAdministrator} actionHook={updateList}/>}
            />
            <FullScreenDialog
                open={openCreateDialog}
                openHook={setOpenCreateDialog}
                actionHook={updateList}
                componentToDisplay={<AdministratorsForm administrator={administrator}
                                                        setAdministrator={setAdministrator} actionHook={updateList}
                                                        creationMode={true}/>}
            />
        </div>
    );
};