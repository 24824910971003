export class SocialLink {
    public facebook: string = '';
    public twitter: string = '';
    public twitch: string = '';
    public youtube: string = '';
    public steam: string = '';
    public discord: string = '';
    public instagram: string = '';
    public linkedin: string = '';
    public goToLink: string = '';
}

export enum SocialEnum {
    Facebook = 'facebook',
    Twitter = 'twitter',
    Twitch = 'twitch',
    YouTube = 'youtube',
    Discord = 'discord',
    Instagram = 'instagram',
    LinkedIn = 'linkedin',
    GoToLink = 'goToLink'
}