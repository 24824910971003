import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    AppBar,
    Divider,
    IconButton,
    Tab,
    Tabs,
    Typography,
    useTheme
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import React, {useState} from 'react';
import {NCTitle} from 'src/atoms/NCTitle/NCTitle';
import {TabPanel} from 'src/atoms/TabPanel/TabPanel';
import {SkillGame, SkillGameBlock, SkillGameLevel} from 'src/models/SkillGame';
import {LangPicker} from 'src/components/General/LangPicker/LangPicker';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CloseIcon from '@material-ui/icons/Close';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import './SkillGameForm.scss';
import {TrainingService} from 'src/services/training.service';
import {toast} from 'react-toastify';
import { TranslatedString } from 'src/models/TranslatedString';

interface SkillGameFormProps {
    skillGame: SkillGame;
    actionHook: any;
    creationMode?: boolean
}

export const SkillGameForm: React.FunctionComponent<SkillGameFormProps> = ({skillGame, actionHook, creationMode}) => {
    const [skillGameState, setSkillGameState] = useState<SkillGame>(skillGame);
    const [edit, setEdit] = useState<boolean>(false);
    const [value, setValue] = useState<number>(0);
    const [lang, setLang] = useState<string>('en');
    const theme = useTheme();

    const saveSkillGame = () => {
        if (edit) {
            TrainingService.updateSkillGame(skillGameState._id, skillGameState).then(data => {
                if (!data) {
                    toast.error('Failed to update skill game');
                    actionHook(true);
                    return;
                }
                toast.success('Skill game updated');
            });
        } else {
            TrainingService.createSkillGame(skillGameState).then(data => {
                if (!data) {
                    toast.error('Failed to create skill game');
                    actionHook(true);
                    return;
                }
                toast.success('Skill game created');
            });
        }
        actionHook(true);
    };

    const onSkillGameInfoChange = (event: any, isTranslatedString = false) => {
        const {name, value} = event.currentTarget;

        if (isTranslatedString) {
            const myLang = skillGameState[name as keyof SkillGame] as TranslatedString;
            myLang[lang] = value;
            setSkillGameState(skillGameState);
        } else {
            setSkillGameState(Object.assign({}, skillGameState, {
                [name]: value,
            }));
        }
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>, newValue: number) => {
        setValue(newValue);
    };

    return (
        <div className="w-100 skillGame-form">
            <div className="w-100 d-flex">
                <div className="w-50">
                    {
                        !creationMode &&
                        <FormControlLabel
                            control={<Switch color="primary" checked={edit} onChange={() => setEdit(!edit)}/>}
                            label="Edit"
                            labelPlacement="start"
                        />
                    }

                </div>
                <div className="w-50">
                    {
                        (edit || creationMode) &&
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                saveSkillGame();
                            }}
                        >
                            Save
                        </Button>
                    }
                </div>
            </div>
            <div className="d-flex mb-3">
                {
                    !creationMode &&
                    <TextField
                        disabled
                        label="ID"
                        name="_id"
                        value={skillGameState._id}
                        className="w-50"
                    />
                }
                <div className="w-50 mx-auto">
                    <LangPicker
                        setLang={setLang}
                    />
                </div>
            </div>
            <div className="d-flex mb-3">
                <TextField
                    disabled={!edit && !creationMode}
                    label="Name"
                    value={skillGameState.name[lang]}
                    className="w-50"
                    name="name"
                    onChange={(event: any) => {
                        onSkillGameInfoChange(event, true);
                    }}
                />
                <TextField
                    disabled={!edit && !creationMode}
                    label="Value"
                    value={skillGameState.value}
                    className="w-50 ml-3"
                    name="value"
                    onChange={(event: any) => {
                        onSkillGameInfoChange(event);
                    }}
                />
            </div>
            <div className="w-100">
                <div className="d-flex">
                    <NCTitle text={'Levels'}/>
                    <IconButton edge="end" className="ml-2">
                        <AddCircleOutlineIcon className="primary-color"/>
                    </IconButton>
                </div>
                <AppBar position="static" color="default">
                    <Tabs
                        value={value}
                        variant="fullWidth"
                        onChange={(event: React.ChangeEvent<any>, newValue: any) => handleChange(event, newValue)}
                    >
                        {
                            skillGameState.levels.map((l: SkillGameLevel, index: number) => {
                                return <Tab
                                    key={index}
                                    label={index}
                                    id={`full-width-tab-${index}`}
                                    aria-controls={`full-width-tabpanel-${index}`}
                                />;
                            })
                        }
                    </Tabs>
                </AppBar>
                {
                    skillGameState.levels.map((l: SkillGameLevel, i: number) => {
                        return (
                            <TabPanel value={value} index={i} key={i} dir={theme.direction}>
                                <div className="d-flex mb-3">
                                    <TextField
                                        disabled={!edit && !creationMode}
                                        label="Level's name"
                                        value={l.name[lang]}
                                        className="w-50"
                                        name="name"
                                        onChange={(event: any) => {
                                            onSkillGameInfoChange(event);
                                        }}
                                    />
                                    <TextField
                                        disabled={!edit && !creationMode}
                                        label="Level's value"
                                        value={l.description[lang]}
                                        className="w-50 ml-3"
                                        name="value"
                                        onChange={(event: any) => {
                                            onSkillGameInfoChange(event);
                                        }}
                                    />
                                </div>
                                <div className="mb-3">
                                    <TextField
                                        disabled
                                        label="Waiting Text"
                                        multiline
                                        rowsMax={10}
                                        rows={5}
                                        value={l.waitingText[lang]}
                                        className="w-100"
                                        name="waitingText"
                                        onChange={(event: any) => {
                                            onSkillGameInfoChange(event);
                                        }}
                                    />
                                </div>
                                <div>
                                    {
                                        l.blocks.map((block: SkillGameBlock, i: number) => {
                                            return (
                                                <Accordion key={i}>
                                                    <AccordionSummary
                                                        expandIcon={<ExpandMoreIcon/>}
                                                        aria-controls="panel1a-content"
                                                        id="panel1a-header"
                                                        className="position-relative my-1"
                                                    >
                                                        <Typography component="div"
                                                                    className="d-flex justify-content-center align-items-center">
                                                            {block.name[lang]}
                                                        </Typography>
                                                        <div>
                                                            <FormControlLabel
                                                                control={<Switch
                                                                    color="primary"
                                                                    checked={block.victory}
                                                                />
                                                                }
                                                                label="Victory"
                                                                labelPlacement="start"
                                                            />
                                                        </div>
                                                        <IconButton edge="end" className="remove-button"
                                                                    aria-label="close">
                                                            <CloseIcon className="red-color"/>
                                                        </IconButton>
                                                    </AccordionSummary>
                                                    <AccordionDetails className="d-flex flex-column p-3">
                                                        <div className="w-100 d-flex">
                                                            <TextField
                                                                disabled={!edit && !creationMode}
                                                                label="Add data for your block (sound / color / ect..)"
                                                                value={''}
                                                                className="w-75"
                                                                name="value"
                                                                onChange={(event: any) => {
                                                                    onSkillGameInfoChange(event);
                                                                }}
                                                            />
                                                            <Button
                                                                color="primary"
                                                                variant="outlined"
                                                                className="mx-3"
                                                            >
                                                                Add
                                                            </Button>
                                                        </div>
                                                        <Divider className="my-4"/>
                                                        <div>
                                                            {
                                                                block.data && block.data.map((d: any, index: number) => {
                                                                    return (
                                                                        <div className="w-100 d-flex align-items-center position-relative" key={index}>
                                                                            <div>{d.link}</div>
                                                                            <div>
                                                                                <FormControlLabel
                                                                                    control={<Switch
                                                                                        color="primary"
                                                                                        checked={d.win}
                                                                                    />
                                                                                    }
                                                                                    label="Win"
                                                                                    labelPlacement="start"
                                                                                />
                                                                            </div>
                                                                            <IconButton edge="start"
                                                                                        className="remove-button"
                                                                                        aria-label="close">
                                                                                <CloseIcon className="red-color"/>
                                                                            </IconButton>
                                                                        </div>
                                                                    );
                                                                })
                                                            }
                                                        </div>
                                                    </AccordionDetails>
                                                </Accordion>
                                            );
                                        })
                                    }
                                </div>
                            </TabPanel>
                        );
                    })
                }
            </div>
        </div>
    );
};