import React, {useEffect, useState} from 'react';
import {AdministratorService} from 'src/services/administrator.service';
import {SimpleList} from '../../General/SimpleList/SimpleList';

interface AdministratorActivityProps {
    administratorId: string;
}

export const AdministratorActivity: React.FunctionComponent<AdministratorActivityProps> = ({administratorId}) => {
    const columns = ['Location', 'Method', 'URL', 'Date'];
    const keepRows = ['location', 'method', 'url', 'createdAt'];
    const [activityList, setActivityList] = useState<any>([]);
    const [actualPage, setActualPage] = useState<number>(0);
    const [totalItem, setTotalItem] = useState<number>(0);

    useEffect(() => {
        AdministratorService.getAdministratorActivity(20, actualPage, administratorId).then((data) => {
            setTotalItem(Math.ceil(data.total / 20));
            setActivityList(data.docs);
        });
    }, [administratorId, actualPage]);

    const filterRows = () => {
        const tmpRows: any = activityList;
        tmpRows.forEach((row: any) => {
            row.method = row.meta.method;
            row.url = row.meta.action;
        });
        return tmpRows;
    };

    return (
        <>
            {
                activityList && activityList.length > 0 &&
                <div className="NC-card">
                    <SimpleList
                        rows={filterRows}
                        columns={columns}
                        keepRows={keepRows}
                        pagination={{actual: actualPage, total: totalItem, setActual: setActualPage}}
                    />
                </div>
            }
            {
                activityList && activityList.length === 0 &&
                <div className="p-5 NC-card text-center w-100">No administrator activity recorded</div>
            }
        </>
    );
};