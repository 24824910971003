
import {
    CircularProgress,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from '@material-ui/core';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { TeamMember, TeamPermission } from '../../../models/Team';
import { TeamService } from '../../../services/team.service';
import './UserTeamsTable.scss';

interface UserTeamsTableProps {
    userId: string;
}

export const UserTeamsTable: React.FunctionComponent<UserTeamsTableProps> = (
    props: UserTeamsTableProps
) => {
    const { userId } = props;
    const [isLoading, setIsLoading] = useState(true);
    const [teams, setTeams] = useState<Array<TeamMember>>([]);
    const goToIconUrl = `url(${process.env.REACT_APP_S3_URL}/media/icons/goToLink.svg)`;

    useEffect(() => {
        const getTeams = async () => {
            setIsLoading(true);
            TeamService.getTeamsByUser(userId)
                .then((data) => {
                    setTeams(data.list);
                    setIsLoading(false);
                })
                .catch(error => console.log(error));
        };
        getTeams();
    }, [userId]);

    const renderPermission = (p: TeamPermission) => {
        const pIconUrl = `url(${process.env.REACT_APP_S3_URL}/media/icons/${
            p === TeamPermission.OWNER ? 'crown' :
            p === TeamPermission.MANAGER ? 'people' : 'user'}.svg)`;
        return (
            <div className="d-flex permission-container">
                <div
                    className="mask-icon my-auto ml-2"
                    style={{ maskImage: pIconUrl, WebkitMaskImage: pIconUrl }}
                />
                <div className="text-capitalize permission-text">
                    {TeamPermission[p].toLowerCase()}
                </div>
            </div>
        );
    }

    return (
        isLoading
            ? <CircularProgress className="mx-auto" />
            : <TableContainer>
                <Table>
                    <TableHead>
                            <TableRow>
                                <TableCell align="left">Tag</TableCell>
                                <TableCell align="left">Name</TableCell>
                                <TableCell align="left">Captain</TableCell>
                                <TableCell align="left">Members</TableCell>
                                <TableCell align="left">Role</TableCell>
                                <TableCell align="left">Creation date</TableCell>
                                <TableCell align="center"></TableCell>
                            </TableRow>
                    </TableHead>
                    <TableBody>
                        {teams.length > 0 
                            ? teams.map((teamMember: TeamMember) => {
                                const { team: teamSlug, teamInfo, permission } = teamMember;
                                const { owner, members } = teamInfo;
                                return (
                                    <TableRow key={teamSlug}>
                                        <TableCell align="left" className="text-uppercase font-weight-bold">{teamInfo.tag}</TableCell>
                                        <TableCell align="left">{teamInfo.name}</TableCell>
                                        <TableCell align="left">
                                            <div className="d-flex owner">
                                                <div className="avatar-container">
                                                    <img className="avatar"
                                                        alt=""
                                                        src={`${process.env.REACT_APP_S3_URL}/user/${owner.id}/medias/ProfileImage`}
                                                        onError={e => e.currentTarget.src = `${process.env.REACT_APP_S3_URL}/media/default/default-user-avatar.svg`}
                                                    />
                                                </div>
                                                <span className="my-auto ml-2 mr-5">{owner.name}</span>
                                            </div>
                                        </TableCell>
                                        <TableCell align="left">{members}</TableCell>
                                        <TableCell align="left">
                                            {renderPermission(permission)}
                                        </TableCell>
                                        <TableCell align="left">
                                            {moment(Date.parse(teamInfo.date)).format('L').toString()}
                                        </TableCell>
                                        <TableCell align="center" className="d-flex justify-content-around">
                                            <Link to={`/teams/${teamSlug}`}>
                                                <div className="icon"
                                                    style={{
                                                        maskImage: goToIconUrl,
                                                        WebkitMaskImage: goToIconUrl
                                                    }}
                                                />
                                            </Link>
                                        </TableCell>
                                    </TableRow>
                                );
                            })
                            : <TableRow>
                                <TableCell align="left">No data</TableCell>
                            </TableRow>
                        }
                    </TableBody>
                </Table>
            </TableContainer>
    );
};