import React from 'react';
import './Login.scss';
import {LoginForm} from 'src/components/Login/LoginForm/LoginForm';

export const Login: React.FunctionComponent = () => {
    return (
        <>
            <div className="bg-image"/>
            <div className="bg-text">
                <h2 className="primary-color font-weight-bold">Wolfie Sports Backoffice</h2>
                <div className="login-form mx-auto mt-4">
                    <LoginForm/>
                </div>
            </div>
        </>
    );
};