import "./PartnerForm.scss";

import React, { useState, MouseEvent, ChangeEvent } from "react";
import { Button } from "@wolfiesports/srm-component";
import { FileManagerForm } from "src/components/FileManager/FileManagerForm";
import { FileManagerMode } from 'src/models/MediaLibrary';
import { PartnerModel } from "src/models/Partner";
import { SocialLink, SocialEnum } from "src/models/SocialLink";
import { PartnerService } from "src/services/partner.service";
import { TextField } from "@material-ui/core";

interface OrganizationPartnersFormProps {
    partner: PartnerModel,
    creationMode?: boolean;
    actionHook: (changed: boolean) => void;
}

export const PartnerForm: React.FunctionComponent<OrganizationPartnersFormProps> = (props: OrganizationPartnersFormProps) => {
    const [partner, setPartner] = useState<PartnerModel>(props.partner);
    const [fileManagerVisible, setFileManagerVisible] = useState<boolean>(false);

    const selectImage = (e: MouseEvent) => {
        e.preventDefault();
        setFileManagerVisible(true);
    }

    const savePartner = async() => {
        try {
            if (props.creationMode) {
                await PartnerService.createPartner(partner);
            } else {
                await PartnerService.updatePartner(partner);
            }
            props.actionHook(true);
        } catch {
            props.actionHook(false);
        }
    }

    const renderSaveButton = () => {
        return (
            <div className="d-flex justify-content-center">
                <Button
                    color="primary"
                    setClick={() => {
                        savePartner();
                    }}
                    label="Save"
                    disabled={!partner.name || !partner.image}
                />
            </div>
        );
    };

    const onSocialChange = (socialType: SocialEnum, e: ChangeEvent<HTMLInputElement>) => {
        if(!partner.social ) {
            partner.social = new SocialLink();
        }
        partner.social[socialType] = e.target.value;
        setPartner({...partner});
    }

    const onNameChange = (e: ChangeEvent<HTMLInputElement>) => {
        partner.name = e.target.value;
        setPartner({...partner});
    }

    const onImageChange = (image: string) => {
        partner.image = image;
        setPartner({...partner})
    }

    return <div className="partner-form">
        <div className='my-3 p-3 partner-container'>
            <form className="d-flex flex-column organization-form">
                <div className="d-flex flex-row row">
                    <div className="col-12 px-3">
                        <TextField
                            className="w-100"
                            name='partner-name'
                            label='Name'
                            defaultValue={partner.name}
                            onChange={(event: ChangeEvent<HTMLInputElement>) => onNameChange(event)}
                        />
                    </div>
                    <div className="col-12 px-3 partner-image my-3">
                        {partner.image && <img src={partner.image} alt='partner' />}
                        <Button label="Select image" styleClass="mt-2" setClick={(e) => selectImage(e)}/>
                    </div>
                    <div className="col-12 mt-4 px-3">
                        <h4>Social</h4>
                    </div>
                    {Object.keys(SocialEnum).map(s => {
                        return  <div key={s} className="col-12 col-sm-6 px-3">
                            <TextField
                                className="w-100"
                                name={s}
                                label={s}
                                defaultValue={partner.social?.[s as keyof SocialLink]}
                                onChange={(event: ChangeEvent<HTMLInputElement>) => (onSocialChange(s as SocialEnum, event))}
                            />
                        </div>
                    }) }
                </div>
            </form>
        </div>
        {renderSaveButton()}
        <FileManagerForm 
            mode={FileManagerMode.SELECT} 
            setOpenDialog={() => setFileManagerVisible(false)} 
            openDialog={fileManagerVisible} 
            setPublicUrl={(url) => onImageChange(url)}
        />
    </div>
};