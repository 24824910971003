import { NCDialog } from '@wolfiesports/srm-component';
import {
    Accordion, AccordionDetails, AccordionSummary,
    Button, Checkbox, FormControl, Input, InputLabel, Link, ListItemText, MenuItem, Select, Tooltip
} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import { Edit } from '@material-ui/icons';
import BlockIcon from "@material-ui/icons/Block";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import VisibilityIcon from "@material-ui/icons/Visibility";
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { NCTitle, NCTitleType } from 'src/atoms/NCTitle/NCTitle';
import { SearchBar } from 'src/components/General/SearchBar/SearchBar';
import { MatchList } from 'src/components/Tournaments/MatchList/MatchList';
import { Tournament, TournamentState } from 'src/models/Tournament';
import { MatchState } from 'src/models/TournamentMatch';
import { NtService } from 'src/services/nt.service';
import { Enum } from 'src/utils/Enum';
import useQueryState from 'src/utils/useQueryState';
import { CancelTournamentModal } from '../../../components/CancelTournamentModal/CancelTournamentModal';
import './TournamentRefereeList.scss';

//const TOURNAMENT_LIMIT = 256;
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const matchStateKeys = Enum.keys(MatchState);

export const TournamentRefereeList: React.FunctionComponent = () => {
    document.title = `Referee Tournament List`;
    const startDate = new Date();
    startDate.setHours(0,0,1);
    const endDate = new Date();
    endDate.setHours(23,59,59);

    const [tournaments, setTournaments] = useState<Array<Tournament>>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [fromDate, setFromDate] = useQueryState('from', startDate.toString());
    const [toDate, setToDate] = useQueryState('to', endDate.toString());
    const [partner, setPartner] = useState<string>("");
    const [matchStates, setMatchStates] = useQueryState<Array<MatchState | string>>('states', [matchStateKeys[MatchState.Playing], matchStateKeys[MatchState.CheckIn], matchStateKeys[MatchState.Conflict], matchStateKeys[MatchState.Confirmation]]);
    const [matchStatesTemp, setMatchStatesTemp] = useState<Array<MatchState | string>>(matchStates);
    const [seconds, setSeconds] = useState<number>(0);
    const [searchFilter, setSearchFilter] = useQueryState<string | undefined>('filter', undefined);
    const [expandedTournaments, setExpandedTournaments] = useState<Array<string>>([]);
    const [selectedTournament, setSelectedTournament] = useState<string>();
    
    const [openPreviewModal, setOpenPreviewModal] = useState<boolean>(false);

    const searchFields: { [key: string]: { label: string } } = {
        filter: {label: 'Filter'},
    }

    useEffect(() => {
        setIsLoading(true)
        search(fromDate, toDate);
    // eslint-disable-next-line
    }, [toDate, fromDate, partner]);

    useEffect(() => {
        if (seconds !== 0) {
            search(fromDate, toDate);
        }
        const interval = setInterval(() => {
            setSeconds(seconds + 1);
        }, 30000);
        return () => clearInterval(interval);
    // eslint-disable-next-line
    }, [seconds, partner]);

    const setSearchResult = (search: any) => {
        setSearchFilter(search['filter']);
    };

    const applyMatchStates = (states: Array<MatchState>) => {
        setMatchStates(states);
    };

    const onToDateChange = (event: any) => {
        setToDate(event.target.value);
    }

    const onPartnerChange = (event: any) => {
        setPartner(event.target.value);
    }

    const onFromDateChange = (event: any) => {
        setFromDate(event.target.value);
    }

    const toTimestamp = (strDate: any) => {
        return (Date.parse(strDate)/1000).toString();
    }
    const openActionDialog = (tournamentId: string) => {
        setSelectedTournament(tournamentId);
        setOpenPreviewModal(true)
    }

    const tournamentIsOpen = (tournamentId: string) => {
        return expandedTournaments.includes(tournamentId);
    }

    const handleChangeExpandedTournaments = (tournamentId: string, expanded: boolean) => {
        let newExpanded = [];
        if(!expanded) {
            newExpanded = expandedTournaments.filter((tId: string) => tId !== tournamentId);
        } else {
            newExpanded = [...expandedTournaments, tournamentId];
        }
        setExpandedTournaments(newExpanded);
    } 

    const getStateOrder = (state: TournamentState):number => {
        switch(state) {
            case TournamentState.Generating :
                return 1;
            case TournamentState.Paused :
                return 2;
            case TournamentState.Ended :
                return 3;
            case TournamentState.Scheduled :
                return 4;
            case TournamentState.Cancelled :
                return 5;
            case TournamentState.Started :
            default:
                return 0;
        }
    }

    const search = async (fromDate?: string, toDate?: string, lastKey: string = '', lastDate: string = '') => {
        let query = '';
        if (fromDate && toDate) {
            query = query.concat(query, `&start=${toTimestamp(fromDate)}&end=${toTimestamp(toDate)}`);
        }

        let element = '';
        if (lastKey) {
            element = element.concat(element, `&id=${lastKey}`);
            if (lastDate) {
                element = element.concat(element, `&lastDate=${lastDate}`);
            }
        }
        const data = await NtService.getTournamentList(256, element, query);
        let res = data.docs;
        if (partner !== "") {
            res = data.docs.filter((t: Tournament) => {
                return t.partner.toLowerCase().includes(partner.toLowerCase());
            });
        }
        res.sort((itemA: Tournament, itemB: Tournament) => {
            const orderA = getStateOrder(itemA.state);
            const orderB = getStateOrder(itemB.state);
            return orderA - orderB;
        });
        setTournaments([...res]);
        setIsLoading(false);
    }

    const deleteTournamentMatches = async (choice: boolean , reason : string | undefined) => {
        if (!choice || !selectedTournament) {
            return;
        }
        console.log(reason)
        const data = await NtService.cancelTournament(selectedTournament , reason)
        if (!data) {
            toast.error('Error');
            return;
        }
        toast.success('Success');
    }

    const renderTournamentStatus = (status: TournamentState) => {
        switch (status) {
            case 0:
                return <span className="nt-scheduled font-weight-bold text-uppercase">{TournamentState[status]}</span>;
            case 1:
                return <span className="nt-generating font-weight-bold text-uppercase">{TournamentState[status]}</span>;
            case 2:
                return <span className="nt-started font-weight-bold text-uppercase">{TournamentState[status]}</span>;
            case 3:
                return <span className="nt-paused font-weight-bold text-uppercase">{TournamentState[status]}</span>;
            case 4:
                return <span className="nt-ended font-weight-bold text-uppercase">{TournamentState[status]}</span>;
            case 5:
                return <span className="nt-cancelled font-weight-bold text-uppercase">{TournamentState[status]}</span>;
            default:
                return <span className="font-weight-bold text-uppercase">{TournamentState[status]}</span>;
        }
    };

    return (
        <div className="NC-container NC-card d-flex flex-column">
            <NCTitle text={'Tournament referee list'} type={NCTitleType.TOURNAMENT}/>
            <div className="d-flex mb-5">
                <div className="w-75">
                    <SearchBar setSearchResult={setSearchResult} searchFields={searchFields} />
                </div>
                <div className="w-50 d-flex justify-content-center align-items-center pl-5 pr-3">
                    <FormControl className="col-12 mx-2">
                        <InputLabel id="match-status-filter-label">Match State</InputLabel>
                        <Select
                        labelId="match-status-filter-label"
                        id="match-status-filter"
                        multiple
                        value={matchStatesTemp}
                        onChange={ev => setMatchStatesTemp(ev.target.value as Array<MatchState>)}
                            // eslint-disable-next-line
                        onClose={ev => applyMatchStates(matchStatesTemp as Array<MatchState>)}
                        input={<Input />}
                        MenuProps={MenuProps}
                        renderValue={(selected) => (selected as string[]).join(', ')}
                        >
                        {matchStateKeys.map((state) => (
                            <MenuItem key={state} value={state}>
                                <Checkbox checked={matchStatesTemp.indexOf(state as any) > -1} />
                                <ListItemText primary={state} />
                            </MenuItem>
                        ))}
                        </Select>
                    </FormControl>
                </div>
                <div className="w-75 d-flex justify-content-center align-items-center">
                    <TextField
                        id="datetime-local"
                        label="Partner"
                        type="partner-local"
                        defaultValue={partner}
                        className="mx-2"
                        onChange={(event: any) => {
                            onPartnerChange(event)
                        }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </div>
                <div className="w-75 d-flex justify-content-center align-items-center">
                    <TextField
                        id="datetime-local"
                        label="From"
                        type="datetime-local"
                        defaultValue={fromDate}
                        className="mx-2"
                        onChange={(event: any) => {
                            onFromDateChange(event)
                        }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    <TextField
                        id="datetime-local"
                        label="To"
                        type="datetime-local"
                        defaultValue={toDate}
                        className="mx-2"
                        onChange={(event: any) => {
                            onToDateChange(event)
                        }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </div>
            </div>
            <div className="d-flex">
                {
                    isLoading && <CircularProgress className="mx-auto"/>
                }
                {
                    !isLoading && tournaments.length > 0 &&
                    <div className="w-100 d-flex flex-column">
                        <div className="d-flex flex-row">
                            <div className=" justify-content-around align-items-center row w-100 text-center font-weight-bold underline mx-auto mb-1" style={{padding: '0 20px 0 0'}}>
                                <div className="col-2">Name</div>
                                <div className="col-1">Platforms</div>
                                <div className="col-1">Partner</div>
                                <div className="col-2">Id</div>
                                <div className="col-1">G4gId</div>
                                <div className="col-1">Date</div>
                                <div className="col-1">State</div>
                                <div className="col-3">Action</div>
                            </div>
                            <div style={{width:'48px'}}></div>
                        </div>
                        {
                            tournaments.map((t: Tournament) => {
                                return (
                                <Accordion expanded={ tournamentIsOpen(t.id) } onChange={(event, expanded) => handleChangeExpandedTournaments(t.id, expanded) } className="w-100 mb-2" key={t.id} TransitionProps={{ unmountOnExit: true }}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon/>} aria-controls="panel1a-content" id="panel1a-header">
                                        <div className="d-flex flex-row justify-content-around align-items-center row w-100 text-center">
                                            <div className="col-2">{t.name}<div>[{t.gameSlug}]</div></div>
                                            <div className="col-1">{t.platforms && t.platforms.map((platform) => <i key={platform} className={ "platform-icon " + platform }/>)}</div>
                                            <div className="col-1">{t.partner.toUpperCase()}</div>
                                            <div className="col-2">{t.id}</div>
                                            <div className="col-1">{t.g4gId}</div>
                                            <div className="col-1">{moment(t.date * 1000).format('L')} {moment(t.date * 1000).format('LTS')}</div>
                                            <div className="col-1">{renderTournamentStatus(t.state)}</div>
                                            <div className="col-3">
                                                <Link className="" target="_blank" href={t ? `${process.env.REACT_APP_PLATFORM_URL}/tournament/${t.id}/bracket` : '#'}>
                                                    <Button variant="text">
                                                        <VisibilityIcon className="primary-color"/>
                                                    </Button>
                                                </Link>
                                                <Link href={`beta/details/${t.id}/bracket`}>
                                                    <Button variant="text">
                                                        <div
                                                            className="bracket-icon"
                                                            style={{
                                                                maskImage: `url(${process.env.REACT_APP_S3_URL}/media/icons/brackets.svg)`,
                                                                WebkitMaskImage:`url(${process.env.REACT_APP_S3_URL}/media/icons/brackets.svg)`
                                                            }}
                                                        ></div>
                                                    </Button>
                                                </Link>
                                                <Link target="_blank" href={t ? `${process.env.REACT_APP_G4G_BO_URL}/tournament/${t.g4gId}/show` : '#'}>
                                                    <Button variant="text">
                                                        <Edit className="primary-color"/>
                                                    </Button>
                                                </Link>
                                                <Tooltip title="cancel">
                                                    <Button variant="text" color="secondary" onClick={ () => { openActionDialog(t.id) } }><BlockIcon/></Button>
                                                </Tooltip>
                                            </div>
                                        </div>
                                    </AccordionSummary>
                                    <div className='ncRapper'>
                                        <NCDialog
                                                show={openPreviewModal}
                                                setShow={setOpenPreviewModal}
                                                title="Cancel Tournament"
                                                wildBody={true}
                                            >
                                            <CancelTournamentModal
                                                title="Are you sure want to cancel this tournament ?"
                                                name={t.name}
                                                actionHook={(e, reason) => {
                                                    if (e) {
                                                        // updateTournamentState(5, reason);
                                                        deleteTournamentMatches(true , reason)
                                                        setOpenPreviewModal(false)
                                                    } else setOpenPreviewModal(false);
                                                }}
                                            />
                                        </NCDialog>
                                    </div>
                                    <AccordionDetails>
                                        <div className="d-flex flex-column w-100">
                                            <MatchList tournamentId={t.id} statesFilter={matchStates as Array<MatchState>} searchFilter={searchFilter}/>
                                        </div>
                                    </AccordionDetails>
                                </Accordion>
                                )
                            })
                        }
                    </div>
                }
            </div>
        </div>
    );
}