import axios, { AxiosError } from 'axios';
import { toast } from 'react-toastify';
import { Game, OverwolfState } from 'src/models/Game';
import { store } from 'src/store';

export class GameService {
    private static baseUrl: string = String(process.env.REACT_APP_ADMINAPI_URL);
    private static actions = store.getActions();
    static token = (): string | null => localStorage.getItem('accessToken');

    static async getGames(limit: number = 20, page: number = 0, active: boolean = false) {
        let res;
        let query = '';
        if (active) {
            query = '&active=true'
        }
        try {
            res = await axios.get(
                `${GameService.baseUrl}/games?limit=${limit}&page=${page}${query}`, {
                    headers: {
                        'x-access-token': GameService.token(),
                    }
                }
            );
        } catch (e) {
            throw (e as AxiosError).response;
        }

        /*if (!res.data && !res.data.docs) {
            throw Error;
        }*/

        let gamesList = [];
        gamesList = res.data.docs.map((item: Game) => {
            const model = new Game(
                item.name,
                item.overwolfId,
                item.value,
                item.slug,
                item.regions,
                item.versions,
                item.description,
                item.platforms,
                item.active,
                item.displayHolding,
                item.showGameIdentifier,
                item.playersCanFillScore,
                item.trainingActivated,
                item.trackingActivated,
                item.tournamentApiAvailable,
                item.installPath,
                item.date_creation,
                item.usernameLabel,
                item.analytic,
                item.countryGroup,
                item.countries,
                item.isNT
            );
            model._id = item._id
            return model;
        });

        return {
            docs: gamesList,
            page: res.data.page,
            pages: res.data.pages,
            total: res.data.total,
        };
    }

    static async getAllGames() {
        if (store.getState().gameList.length === 0) {
            let gamesRes = await GameService.getGames(50);
            let games = gamesRes.docs
            let page = 1;

            while (page < gamesRes.pages) {
                gamesRes = await GameService.getGames(50, page);
                games = [...games, ...gamesRes.docs];
                page += 1;
            }

            GameService.actions.setGameList(games);
        }

        return store.getState().gameList;
    }

    static async get360Games() {
        const games = (await this.getAllGames()).filter(game => game.overwolfId);
        const overwolfGames = await this.checkOverwolfGameStatus();
        if (games) {
            games.forEach(g => {
                if (overwolfGames.state) {
                    g.state = OverwolfState[overwolfGames.filter((game: any) => game.game_id === g.overwolfId)[0].state];
                }
            })
        }
        return games;
    }

    static async checkOverwolfGameStatus(): Promise<any> {
        return (await axios.get('https://game-events-status.overwolf.com/gamestatus_prod.json')).data;
    }

    static async checkOverwolfId(overwolfId: number): Promise<boolean> {
        let validate: boolean = false;

        if (overwolfId === 0 || overwolfId === undefined) {
            return true;
        }

        const overwolfGames = await this.checkOverwolfGameStatus();

        overwolfGames.forEach((oG: any) => {
            if (oG.game_id === overwolfId) {
                validate = true;
            }
        })

        return validate;
    }


    static async getGameById(_id: string): Promise<Game> {
        if (!_id) {
            throw Error;
        }

        let res: Game;
        try {
            res = (await axios.get(
                `${GameService.baseUrl}/games/${_id}`, {
                    headers: {
                        'x-access-token': GameService.token(),
                    }
                }
            )).data;
        } catch (e) {
            throw (e as AxiosError).response;
        }

        return res;
    }

    static async getGamesByIds(ids: Array<string>) {
        const allGames: Array<Game> = (await this.getGames(50, 0, false)).docs;
        const gameList: Array<Game> = [];
        allGames.forEach((game: Game) => {
            ids.forEach((id: string) => {
                if (id === game._id) {
                    gameList.push(game);
                }
            })
        })
        return gameList;
    }

    static async createGame(game: Game): Promise<Game> {
        if (!game) {
            throw Error;
        }
        let newGame: Game;

        try {
            newGame = (await axios.post(
                `${GameService.baseUrl}/games`,
                game,
                {
                    headers: {
                        'x-access-token': GameService.token(),
                    }
                }
            )).data;
        } catch (e) {
            throw (e as AxiosError).response;
        }

        return newGame;
    }

    static async updateGame(game: Game): Promise<boolean> {
        if (!game) {
            return false;
        }

        try {
            if (!await this.checkOverwolfId(game.overwolfId)) {
                toast.error('Fail to find overwolf id, check documentation');
                return false;
            }
            await axios.put(
                `${GameService.baseUrl}/games/${game._id}`,
                game, {
                    headers: {
                        'x-access-token': GameService.token(),
                    }
                }
            );
        } catch {
            return false;
        }

        return true;
    }

    static async deleteGame(id: string): Promise<boolean> {
        if (!id) {
            return false;
        }

        try {
            await axios.delete(
                `${GameService.baseUrl}/games/${id}`, {
                    headers: {
                        'x-access-token': GameService.token(),
                    }
                }
            );
        } catch {
            return false;
        }

        return true;
    }
}