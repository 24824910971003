import {CircularProgress} from '@material-ui/core';
import React, {useEffect, useState} from 'react';
import {toast} from 'react-toastify';
import {NCTitle} from 'src/atoms/NCTitle/NCTitle';
import {GamePageForm} from 'src/components/GamePages/GamePageForm/GamePageForm';
import {ActionDialog} from 'src/components/General/ActionDialog/ActionDialog';
import {FullScreenDialog} from 'src/components/General/FullScreenDialog/FullScreenDialog';
import {SimpleList} from 'src/components/General/SimpleList/SimpleList';
import {GamePage} from 'src/models/GamePage';
import {GamePageService} from 'src/services/gamePage.service';
import {Game} from '../../models/Game';
import {GameService} from '../../services/game.service';

export const GamePages: React.FunctionComponent = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [rows, setRows] = useState<any>([]);
    const [gamePage, setGamePage] = useState<GamePage>();
    const [actualPage, setActualPage] = useState<number>(0);
    const [totalItem, setTotalItem] = useState<number>(0);
    const [actionDelete, setActionDelete] = useState<boolean>(false);
    const [actionEdit, setActionEdit] = useState<boolean>(false);
    const [actionCreate, setActionCreate] = useState<boolean>(false);
    const [gameList, setGameList] = useState<Array<Game>>([]);
    const columns = ['Game', 'Challenge', 'Twitter', 'TV', 'Tournament', 'Training', 'Active'];
    const keepRows = ['gameList', 'challengeList', 'twitterList', 'tvList', 'tournamentList', 'trainingList', 'active'];

    useEffect(() => {
        GameService.getAllGames().then(data => {
            setGameList(data);
        });
        GamePageService.getGamePages(20, actualPage).then(data => {
            setTotalItem(Math.ceil(data.total / 20));
            setRows(data.docs);
            setIsLoading(false);
        });
    }, [totalItem, actualPage]);

    const actionManager = (gamePage: GamePage, kind: string) => {
        setGamePage(gamePage);

        switch (kind) {
            case 'delete':
                setActionDelete(true);
                break;
            case 'edit':
                setActionEdit(true);
                break;
            case 'create': {
                setActionCreate(true);
                break;
            }
            default:
                return;
        }
    };


    const filterRows = () => {
        const tmpRows: any = rows;
        tmpRows.forEach((row: any) => {
            row.gameList = gameList.find(g => g._id === row.game.id)?.name;
            row.challengeList = row.challenge.enable;
            row.twitterList = row.twitter.enable;
            row.tvList = row.tv.enable;
            row.tournamentList = row.tournament.enable;
            row.trainingList = row.training.enable;
        });
        return tmpRows;
    };

    const deleteGamePage = (choice: boolean) => {
        if (choice && gamePage) {
            GamePageService.deleteGamePage(gamePage._id).then(data => {
                if (!data) {
                    toast.error('Failed to delete game page');
                    updateList(false);
                    return;
                }
                toast.error('Game page deleted');
                updateList(true);
            });
        }
    };

    const updateList = (result: boolean) => {
        setActionEdit(false);
        setActionCreate(false);
        setActionDelete(false);

        if (result) {
            setIsLoading(true);
            GamePageService.getGamePages(20, actualPage).then((data) => {
                setRows(data.docs);
                setTotalItem(Math.ceil(data.total / 20));
                setIsLoading(false);
            }).catch(error => console.log(error));
        }
    };

    return (
        <div className="NC-container NC-card">
            <NCTitle text={'Game pages'}/>
            <div className="d-flex">
                {
                    isLoading && <CircularProgress className="mx-auto"/>
                }
                {
                    !isLoading &&
                    <SimpleList
                        rows={filterRows}
                        columns={columns}
                        keepRows={keepRows}
                        actionsButtons={{create: true, edit: true, delete: true}}
                        handleClickOpen={actionManager}
                        pagination={{actual: actualPage, total: totalItem, setActual: setActualPage}}
                    />
                }
            </div>
            <ActionDialog
                open={actionDelete}
                title={'Are you sure to delete this game page'}
                openHook={setActionDelete}
                actionHook={deleteGamePage}
            />
            {
                gamePage &&
                <FullScreenDialog
                    open={actionEdit}
                    openHook={setActionEdit}
                    actionHook={updateList}
                    componentToDisplay={
                        <GamePageForm
                            gamePageProps={gamePage}
                            actionHook={updateList}
                            gameList={gameList}
                        />
                    }
                />
            }
            {
                gamePage &&
                <FullScreenDialog
                    open={actionCreate}
                    openHook={setActionCreate}
                    actionHook={updateList}
                    componentToDisplay={
                        <GamePageForm
                            gamePageProps={gamePage}
                            actionHook={updateList}
                            creationMode={true}
                            gameList={gameList}
                        />
                    }
                />
            }

        </div>
    );
};