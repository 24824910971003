import CircularProgress from '@material-ui/core/CircularProgress';
import React, {useEffect, useState} from 'react';
import {toast} from 'react-toastify';
import {NCTitle, NCTitleType} from 'src/atoms/NCTitle/NCTitle';
import {ActionDialog} from 'src/components/General/ActionDialog/ActionDialog';
import {FullScreenDialog} from 'src/components/General/FullScreenDialog/FullScreenDialog';
import {SimpleList} from 'src/components/General/SimpleList/SimpleList';
import {GuideForm} from 'src/components/Training/Guide/GuideForm/GuideForm';
import {Game} from 'src/models/Game';
import {TrainingGuide} from 'src/models/TrainingGuide';
import {GameService} from 'src/services/game.service';
import {TrainingService} from 'src/services/training.service';

export const Guides: React.FunctionComponent = () => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [actualPage, setActualPage] = useState<number>(0);
    const [totalItem, setTotalItem] = useState<number>(0);
    const [actionDelete, setActionDelete] = useState<boolean>(false);
    const [actionEdit, setActionEdit] = useState<boolean>(false);
    const [actionCreate, setActionCreate] = useState<boolean>(false);
    const [gameList, setGameList] = useState<Array<Game>>([]);
    const [rows, setRows] = useState<any>([]);
    const [guide, setGuide] = useState<TrainingGuide>();
    const columns = ['ID', 'Name', 'Slug'];
    const keepRows = ['_id', 'name', 'slug'];

    useEffect(() => {
        TrainingService.getTrainingGuides(20, actualPage).then(data => {
            setTotalItem(Math.ceil(data.total / 20));
            setRows(data.docs);
            setIsLoading(false);
        });
        getGames();
    }, [actualPage]);

    const getGames = () => {
        GameService.getGames(50).then((data) => {
            setGameList(data.docs);
        }).catch(() => {
            toast.error('Error while fetching game list');
        });
    };

    const actionManager = (guide: TrainingGuide, kind: string) => {
        setGuide(guide);
        switch (kind) {
            case 'delete':
                setActionDelete(true);
                break;
            case 'edit':
                setActionEdit(true);
                break;
            case 'create': {
                setGuide(
                    new TrainingGuide(
                        '',
                        {},
                        {},
                        {},
                        {},
                        '',
                    )
                );
                setActionCreate(true);
                break;
            }
            default:
                return;
        }
    };

    const deleteGuide = (choice: boolean) => {
        if (choice && guide) {
            TrainingService.deleteTrainingGuide(guide._id).then(data => {
                if (!data) {
                    toast.error('Failed to delete training guide');
                    updateList(false);
                    return;
                }
                toast.success('Training guide deleted');
                updateList(true);
            });
        }
    };

    const updateList = (result: boolean) => {
        setActionEdit(false);
        setActionDelete(false);
        setActionCreate(false);
        if (!result) {
            return;
        }
        setIsLoading(true);
        TrainingService.getTrainingGuides(20, actualPage).then(data => {
            setTotalItem(Math.ceil(data.total / 20));
            setRows(data.docs);
            setIsLoading(false);
        });
    };

    return (
        <div className="NC-container NC-card d-flex flex-column">
            <NCTitle text={'Guides'} type={NCTitleType.TRAINING}/>
            <div className="d-flex">
                {
                    isLoading &&
                    <CircularProgress className="mx-auto"/>
                }
                {
                    !isLoading &&
                    <SimpleList
                        rows={rows}
                        columns={columns}
                        keepRows={keepRows}
                        actionsButtons={{create: true, edit: true, delete: true}}
                        handleClickOpen={actionManager}
                        pagination={{actual: actualPage, total: totalItem, setActual: setActualPage}}
                        translatedString={true}
                    />
                }
            </div>
            <ActionDialog
                open={actionDelete}
                title={'Are you sure to delete this guide ?'}
                openHook={setActionDelete}
                actionHook={deleteGuide}
            />
            {
                guide &&
                <FullScreenDialog
                    open={actionEdit}
                    openHook={setActionEdit}
                    actionHook={updateList}
                    componentToDisplay={
                        <GuideForm
                            guideProps={guide}
                            actionHook={updateList}
                            gameList={gameList}
                        />
                    }
                />
            }
            {
                guide &&
                <FullScreenDialog
                    open={actionCreate}
                    openHook={setActionCreate}
                    actionHook={updateList}
                    componentToDisplay={
                        <GuideForm
                            guideProps={guide}
                            actionHook={updateList}
                            gameList={gameList}
                            creationMode={true}
                        />
                    }
                />
            }
        </div>
    );
};