import axios from "axios";
import { Corner } from "src/models/Corner";
import { AdministratorService } from "./administrator.service";

export class CornerService {
  private static baseUrl: string | undefined =
    process.env.REACT_APP_CORNER_SERVICE_URL;

  static async getCorners(): Promise<Corner[]> {
    const res = await axios.post(
      `${CornerService.baseUrl}/`,
      {
        query: `
              query CornersQuery {
                corners {
                  _id
                  name
                }
              }
                `,
        variables: {},
      },
      {
        headers: {
          "x-access-token": AdministratorService.token(),
        },
      }
    );

    return res.data.data.corners;
  }

  static async getCorner(name: string): Promise<Corner> {
    if (!name) {
      throw new Error("No corner avialable");
    }

    const res = await axios.post(
      `${CornerService.baseUrl}/`,
      {
        query: `
           query CornerQuery($cornerName: String!) {
            corner(name: $cornerName) {
              name
              prod {
                name
                value
              }
              _id
              dev {
                name
                value
              }
              next {
                name
                value
              }
              theme {
                colors {
                  name
                  value
                }
              }
              locales {
                code
                iso
                name
                file
              }
              layouts {
                name
              }
            }
          }
                 `,
        variables: { cornerName: name },
      },
      {
        headers: {
          "x-access-token": AdministratorService.token(),
        },
      }
    );

    return res.data.data.corner;
  }
}
