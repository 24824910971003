import { SocialLink } from "./SocialLink";
import { TranslatedString } from "./TranslatedString";

export class OrganizationModel {
    _id: string;
    active: boolean = false;
    orgIconVisible: boolean = false;
    orgRedirectionLink: boolean = false;
    hasTrainingGames: boolean = false;
    trainingGames?: Array<string>;
    countries?: Array<string>;
    corner?: string;
    ctaPremium: boolean = false;
    externalLink?: string = "";
    externalLinkName?: string = "";
    subMenuActive: boolean = false;
    partners?: Array<string>;
    newsFeedTag?: string;

    constructor(
        public name: string,
        public domain: string,
        public games: Array<string>,
        public color: { primary: string, secondary: string },
        public social: SocialLink,
        public legals: TranslatedString,
        public whoAreWe?: string,
        public company?: string,
        public s3path?: string,
    ) {}

}

export enum EOrganizationImage {
    BannerLeftImage = 'BannerLeftImage',
    BannerRightImage = 'BannerRightImage',
    ProfilePicture = 'ProfilePicture',
    LeftMenuIcon = 'LeftMenuIcon',
    MobileBanner = 'MobileBanner'
}

export class Organization {
    constructor(
        public active: boolean,
        public domain: string,
        public name: string,
    ) {}
}

