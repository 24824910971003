import { TranslatedString } from "./TranslatedString";

export interface Version {
    name: string;
    date: Date;
}

export class Game {
    _id: string;

    constructor(
        public name: string,
        public overwolfId: number,
        public value: string,
        public slug: string,
        public regions: Array<string>,
        public versions: Array<Version>,
        public description: TranslatedString,
        public platforms: Array<string>,
        public active: boolean,
        public displayHolding: boolean,
        public showGameIdentifier: boolean,
        public playersCanFillScore: boolean,
        public trainingActivated: boolean,
        public trackingActivated: boolean,
        public tournamentApiAvailable: boolean,
        public installPath: { darwin: string, windowsNT: string },
        public date_creation: Date,
        public usernameLabel: string,
        public analytic: string,
        public countryGroup: Array<string>,
        public countries: Array<string>,
        public isNT: boolean,
        public state?: string,
    ) {
    }
}

export enum OverwolfState {
    UNSUPPORTED = 0,
    GREEN = 1,
    YELLOW = 2,
    RED = 3,
}

export enum EGameImage {
    BannerImage = 'BannerImage',
    CarrouselImage = 'CarrouselImage',
    SelectImage =  'SelectImage',
    BackgroundImage = 'BackgroundImage',
    BadgeImage = 'BadgeImage',
    ChampionImage = 'ChampionImage',
    LogoImage = 'LogoImage',
    TournamentBanner = 'TournamentBanner',
    TournamentMobileBanner = 'TournamentMobileBanner',
    GameAccountImage = 'GameAccountImage'
}

export class NoGame {
    constructor(
        public id: string,
        public route: string,
        public active: number,
        public title: string,
        public icon: string,
    ) {}
}