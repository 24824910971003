import axios, { AxiosError } from 'axios';
import { toast } from 'react-toastify';
import { LastKey, PaginatedList } from 'src/models/Common';
import { Transaction, TransactionKind, TransactionStatus } from 'src/models/Transaction';
import { Currency } from '../models/Currency';
import { Wallet } from '../models/Wallet';


type TransactionListResponse = PaginatedList<Transaction>;

export class TransactionService {
    private static baseUrl: string = String(process.env.REACT_APP_NR_URL);
    static token = (): string | null => localStorage.getItem('accessToken');

    static async getTransactions(limit = 20, lk?: LastKey): Promise<TransactionListResponse> {
        try {
            const res = await axios.get(`${TransactionService.baseUrl}/admin/transactions`, {
                headers: {
                    'authorization': TransactionService.token(),
                },
                params: {
                    size: limit,
                    ...lk,
                },
            });
            return res.data;
        } catch (e) {
            throw (e as AxiosError).response;
        }
    }

    static async getTransactionsByWallet(wallet: Wallet, limit = 20, lk?: LastKey): Promise<TransactionListResponse> {
        const route = wallet.route.split('/').slice(1).join('/');
        let res;

        try {
            res = await axios.get(`${TransactionService.baseUrl}/admin/transactions/wallets/${wallet.owner}/${route}`, {
                headers: {
                    'authorization': TransactionService.token(),
                },
                params: {
                    size: limit,
                    ...lk,
                },
            });
        } catch (e) {
            throw (e as AxiosError).response;
        }

        return res.data;
    }

    static async getTransactionsByUserId(userID: string, limit = 20, lk?: LastKey): Promise<TransactionListResponse> {
        let res;

        try {
            res = await axios.get(`${TransactionService.baseUrl}/admin/transactions/owners/${userID}`, {
                headers: {
                    'authorization': TransactionService.token(),
                },
                params: {
                    size: limit,
                    ...lk,
                },
            });
        } catch (e) {
            throw (e as AxiosError).response;
        }

        return res.data;
    }

    static async getTransactionsByStatusAndKind(
        status: TransactionStatus, 
        kind: TransactionKind, 
        currency: Currency | undefined,
        limit = 20, 
        lk?: LastKey
    ): Promise<TransactionListResponse> {
        const params: any = {
            size: limit,
            ...lk,
        };

        if (currency) {
            params.currency = currency.symbol;
        }

        try {
            const res = await axios.get(`${TransactionService.baseUrl}/admin/transactions/statuses/${status}/kind/${kind}`, {
                headers: {
                    'authorization': TransactionService.token(),
                },
                params,
            });
            return res.data;
        } catch (e) {
            throw (e as AxiosError).response;
        }
    }

    static async getTransaction(owner: string, route: string): Promise<Transaction> {
        let res;

        try {
            res = await axios.get(`${TransactionService.baseUrl}/admin/transactions/owners/${owner}/${route}`, {
                headers: {
                    'authorization': TransactionService.token(),
                },
            });
        } catch (e) {
            throw (e as AxiosError).response;
        }

        return res.data;
    }

    static async validateTransactionDocuments(transaction: Transaction): Promise<boolean> {
        try {
            await axios.patch(`${TransactionService.baseUrl}/admin/transactions/approuve`, {
                owner: transaction.owner,
                route: transaction.route,
                approuval: true,
            }, {
                headers: {
                    'authorization': TransactionService.token(),
                },
            });
        } catch (e) {
            toast.error('Fail to validate transaction documents, try again later or contact #public-backoffice');
            return false;
        }
        toast.success('Transaction documents validated');
        return true;
    }

    static async approuveTransaction(transaction: Transaction): Promise<boolean> {
        try {
            await axios.patch(`${TransactionService.baseUrl}/admin/transactions/validate`, {
                owner: transaction.owner,
                route: transaction.route,
                status: TransactionStatus.Transiting,
            }, {
                headers: {
                    'authorization': TransactionService.token(),
                },
            });
        } catch (e) {
            toast.error('Fail to approuve transaction, try again later or contact #public-backoffice');
            return false;
        }
        toast.success('Transaction aprouved');
        return true;
    }

    static async declineTransaction(transaction: Transaction, reason: string): Promise<any> {
        try {
            await axios.patch(`${TransactionService.baseUrl}/admin/transactions/validate`, {
                owner: transaction.owner,
                route: transaction.route,
                status: TransactionStatus.Cancelled,
                reason,
            }, {
                headers: {
                    'authorization': TransactionService.token(),
                },
            });
        } catch (e) {
            toast.error('Fail to decline transaction, try again later or contact #public-backoffice');
            return false;
        }
        toast.success('Transaction declined');
        return true;
    }
}
