import { MenuItem, Select, TextField } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { Add, DeleteForever, Update } from '@material-ui/icons';
import { User } from '@wolfiesports/srm-component';
import React, { useEffect, useState } from 'react';
import { Account } from 'src/models/GameAccount';
import { UserGameAccount } from 'src/models/UserGameAccount';
import { GameAccountService } from 'src/services/game-account.service';

interface UserGameAccountsProps {
    user: User;
}

interface NewGameAccount {
    account: string;
    gamerTag: string;
}

export const UserGameAccounts: React.FunctionComponent<UserGameAccountsProps> = (props: UserGameAccountsProps) => {
    const [editedUser] = useState<User>(props.user);
    const [accountList, setAccountList] = useState<Array<Account>>([]);
    const [gameAccounts, setGameAccounts] = useState<Array<UserGameAccount>>([]);
    const [newGameAccounts, setNewGameAccounts] = useState<NewGameAccount>({ account: '', gamerTag: '' });
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        GameAccountService.getAccountList().then((data) => {
            setAccountList(data.list);
        });

        GameAccountService.getAccountByUserId(128, props.user.userId).then((data) => {
            setGameAccounts(data.list)
        });
    }, [props.user]);

    const onGameAccountChange = (event: any, elem: UserGameAccount) => {
        elem.gamerTag = event.currentTarget.value;
    };

    const handleSelectAccount = (event: any) => {
        setNewGameAccounts(Object.assign({}, newGameAccounts, {
            account: event.target.value,
        }));
    };

    const handleGamerTag = (event: any) => {
        setNewGameAccounts(Object.assign({}, newGameAccounts, {
            gamerTag: event.currentTarget.value,
        }));
    };

    const saveUserGameAccount = (elem?: UserGameAccount) => {
        if (elem) {
            elem.account = elem.route.split('/')[1];
        }
        const _target = elem || newGameAccounts;

        GameAccountService.updateUserGameAccount(props.user.userId, _target.account, _target.gamerTag);
        if (!elem) {
            setGameAccounts([
                ...gameAccounts,
                {
                    id: props.user.userId,
                    route: `uga/${newGameAccounts.account}`,
                    gamerTag: newGameAccounts.gamerTag
                }
            ])
        } else {
            setGameAccounts(gameAccounts)
        }
        setNewGameAccounts({ account: '', gamerTag: '' });
    };

    const removeUserGameAccount = (elem: UserGameAccount, idx: number) => {
        if (elem) {
            elem.account = elem.route.split('/')[1];
        }
        GameAccountService.deleteUserGameAccount(props.user.userId, elem.account).then((b) => {
            if (b) {
                setIsLoading(true);
                gameAccounts.splice(idx, 1);
                setGameAccounts([...gameAccounts]);
                setIsLoading(false);
            }
        })
    };

    const renderSaveButton = () => {
        return (
            <React.Fragment>
                <Button
                    variant="text"
                    color="primary"
                    onClick={() => saveUserGameAccount()}
                >
                    <Add />
                </Button>
            </React.Fragment>
        );
    };

    const renderUpdateElementButton = (elem: UserGameAccount) => {
        return (
            <React.Fragment>
                <Button
                    variant="text"
                    color="primary"
                    onClick={() => saveUserGameAccount(elem)}
                >
                    <Update />
                </Button>
            </React.Fragment>
        );
    };

    const renderRemoveElementButton = (elem: UserGameAccount, idx: number) => {
        return (
            <React.Fragment>
                <Button
                    className="red-icon"
                    variant="text"
                    color="primary"
                    onClick={() => removeUserGameAccount(elem, idx)}
                >
                    <DeleteForever />
                </Button>
            </React.Fragment>
        );
    };

    return (
        <React.Fragment>
            {
                editedUser &&
                <div className="NC-card p-3">
                    {!isLoading && gameAccounts.map((value, index) => {
                        return (<div className="row p-3 align-items-center" key={`galist-${value.id}-${index}`}>
                            <Select
                                key={`ga-${value.id}-${index}`}
                                id={`ga-${value.id}-${index}`}
                                disabled={true}
                                label="Account"
                                name="account"
                                className="col-md-5 my-2 px-1"
                                defaultValue={value.route.split('/')[1]}
                            >
                                {accountList.map((account: Account, index: number) => {
                                    return <MenuItem key={index}
                                        value={account.id}>{account.id}</MenuItem>;
                                })}
                            </Select>
                            <TextField
                                name="gamerTag"
                                defaultValue={value.gamerTag}
                                key={value.id}
                                className="col-md-5 my-2 px-1"
                                onChange={(event) => {
                                    onGameAccountChange(event, value);
                                }}
                            />
                            {renderUpdateElementButton(value)}
                            {renderRemoveElementButton(value, index)}
                        </div>)
                    })}
                    <div className="row p-3">
                        <Select
                            label="Account"
                            name="newAccount"
                            className="col-4 my-2 px-1"
                            defaultValue={'default'}
                            onChange={handleSelectAccount}
                        >
                            <MenuItem key={'default'} selected={true} value={'default'} disabled={true}>Select an account *</MenuItem>;
                            {accountList.map((account: Account, index: number) => {
                                return <MenuItem key={index} value={account.id}>{account.id}</MenuItem>;
                            })}
                        </Select>
                        <TextField
                            name="newGamertag"
                            label="gamerTag"
                            value={newGameAccounts.gamerTag}
                            className="col-md-5 col-12 my-2 px-1"
                            onChange={handleGamerTag}
                        />

                        <div className="col-2 mt-4 d-flex justify-content-center">
                            {renderSaveButton()}
                        </div>
                    </div>


                </div>

            }
        </React.Fragment>
    );
};
