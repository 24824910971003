import { Button, CircularProgress, TextField } from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { MediaSelector } from 'src/components/MediaSelector/MediaSelector';
import { TeamCard } from 'src/components/Team/TeamCard/TeamCard';
import { TeamInvitationModal } from 'src/components/Team/TeamInvitationModal/TeamInvitationModal';
import { TeamAugmented, TeamMedia, TeamMember, TeamPermission } from 'src/models/Team';
import { S3LoaderService } from 'src/services/s3Loader.service';
import { TeamService } from 'src/services/team.service';
import './TeamDetails.scss';

interface TeamDetailsProps {
    team: TeamAugmented;
}

export const TeamDetails: React.FunctionComponent<TeamDetailsProps> = ({ team })  => {
    const [name, setName] = useState<string>(team.name);
    const [tag, setTag] = useState<string>(team.tag);
    const [description, setDescription] = useState<string>(team.desc);
    const [profileImage, setProfileImage] = useState<File | null>(null);
    const [bannerImage, setBannerImage] = useState<File | null>(null);

    const [updatedPermission, setUpdatedPermission] = useState<{[key: string]: {previous?: TeamPermission, value?: TeamPermission, deleted?: boolean}}>({});
    const [updatedInfo, setUpdateInfo] = useState<{[key: string]: {previous?: string, value: string}}>({});
    const [updatedMedia, setUpdatedMedia] = useState<{[key: string]: {previous?: File, value: File}}>({});

    const [ownerError, setOwnerError] = useState<boolean>(false);
    const [ownerId, setOwnerId] = useState<string>(team.owner.id);
    const [removable, setRemovable] = useState<boolean>(team.members.length > 1);

    const [memberModal, setMemberModal] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        const ownerCount = Object.values(updatedPermission).filter((member) => (member.value === TeamPermission.OWNER && !member.deleted)).length;
        const ownerUpdated = updatedPermission[ownerId];
        if (ownerCount === 0) {
            setOwnerError(!!ownerUpdated);
        } else if (ownerCount === 1) {
            setOwnerError(!ownerUpdated);
        } else {
            setOwnerError(true);
        }

        const deletedCount = Object.values(updatedPermission).filter((member) => member.deleted).length;
        setRemovable(team.members.length - deletedCount > 1);
    }, [updatedPermission, ownerId, team]);

    const updateTeam = async () => {
        setIsLoading(true);

        if (updatedInfo['name']) {
            await updateName();
        }

        if (updatedInfo['tag']) {
            await updateTag();
        }

        if (updatedInfo['description']) {
            await updateDescription();
        }

        for (const key of Object.keys(updatedMedia)) {
            await updateTeamMedia(key, updatedMedia[key].value);
        }

        const orderedKeys = Object.keys(updatedPermission).sort((keyA, keyB) => {
            const a = updatedPermission[keyA];
            const b = updatedPermission[keyB];
            return (
                (a.value === TeamPermission.OWNER && !a.deleted) ? -1 :
                (b.value === TeamPermission.OWNER && !b.deleted) ? 1 :
                a.deleted ? -1 :
                b.deleted ? 1 :
                0
            )
        });

        for (const key of orderedKeys) {
            if (updatedPermission[key].deleted) {
                await removeTeamMember(key);
            } else {
                await updateTeamPermission(key, updatedPermission[key].value as TeamPermission);
            }
        }
        setIsLoading(false);
    }

    const handleInfoChange = (key: string, value: string) => {
        if (updatedInfo[key]) {
            updatedInfo[key].value = value;
        } else {
            updatedInfo[key] = { value };
        }
        setUpdatedPermission(JSON.parse(JSON.stringify(updatedPermission)));
    }

    const handlePermissionChange = (memberId: string, value: TeamPermission) => {
        if (updatedPermission[memberId] && updatedPermission[memberId].value) {
            if (typeof updatedPermission[memberId].previous === 'undefined') {
                const member = team.members.find((member) => member.userId === memberId);
                if (member && (member.permission === value)) {
                    delete updatedPermission[memberId];
                }
            } else {
                updatedPermission[memberId] = { value };
            }
        } else {
            updatedPermission[memberId] = { value };
        }
        setUpdatedPermission(JSON.parse(JSON.stringify(updatedPermission)));
    }


    const handleMediaChange = (key: string, value: File) => {
        if (updatedMedia[key]) {
            updatedMedia[key].value = value;
        } else {
            updatedMedia[key] = { value };
        }
        setUpdatedMedia(JSON.parse(JSON.stringify(updatedMedia)));
    }

    const updateName = async () => {
        const res = await TeamService.updateTeamName(team.team, updatedInfo['name'].value);
        if (!res) {
            toast.error('Update team name failed');
            const previousVal = handleSaveError('name');
            setName(previousVal ? previousVal : team.name);
        } else {
            updatedInfo['name'].previous = name;
            setUpdateInfo(JSON.parse(JSON.stringify(updatedInfo)));
        }
    }

    const updateTag = async () => {
        const res = await TeamService.updateTeamTag(team.team, updatedInfo['tag'].value);
        if (!res) {
            toast.error('Update team tag failed');
            const previousVal = handleSaveError('tag');
            setTag(previousVal ? previousVal : team.tag);
        } else {
            updatedInfo['tag'].previous = tag;
            setUpdateInfo(JSON.parse(JSON.stringify(updatedInfo)));
        }
    }

      const updateDescription = async () => {
        const res = await TeamService.updateTeamDescription(team.team, updatedInfo['description'].value);
        if (!res) {
            toast.error('Update team description failed');
            const previousVal = handleSaveError('description');
            setDescription(previousVal ? previousVal : team.desc);
        } else {
            updatedInfo['description'].previous = description;
            setUpdateInfo(JSON.parse(JSON.stringify(updatedInfo)));
        }
    }
    
    const updateTeamMedia = async (type: string, file: File) => {
        const res = await S3LoaderService.uploadTeamMedia(team.team, type, file);
        if (!res) {
            toast.error(`Fail to upload ${type}`);
            const previousVal = handleMediaError(type);
            switch(type) {
                case TeamMedia.BANNER:
                    setBannerImage(previousVal || null);
                    break;
                case TeamMedia.PROFILE:
                    setProfileImage(previousVal || null);
                    break;
                default:
                    break;
            }
        } else {
            switch(type) {
                case TeamMedia.BANNER:
                    updatedMedia[type].previous = bannerImage as File;
                    break;
                case TeamMedia.PROFILE:
                    updatedMedia[type].previous = profileImage as File;
                    break;
                default:
                    break;
            }
            setUpdatedMedia(JSON.parse(JSON.stringify(updatedMedia)));
        }
    }

    const updateTeamPermission = async (userId: string, permission: TeamPermission) => {
        let res;
        if (permission === TeamPermission.OWNER) {
            res = await TeamService.updateOwner(team.team, ownerId, userId);
        } else {
            res = await TeamService.updatePermission(team.team, userId, permission.toString());
        }

        if (!res) {
            toast.error(`Update user ${userId} permission failed`);
            handlePermissionError(userId);
        } else {
            updatedPermission[userId].previous = updatedPermission[userId].value;
            setUpdatedPermission(JSON.parse(JSON.stringify(updatedPermission)));

            if (permission === TeamPermission.OWNER) {
                setOwnerId(userId);
            }
        }
    }

    const removeTeamMember = async (userId: string) => {
        const res = await TeamService.removeUserFromTeam(team.team, userId);
        if (!res) {
            toast.error(`Remove user ${userId} failed`);
            delete updatedPermission[userId];
            setUpdatedPermission(updatedPermission);
        }
    }

    const handleSaveError = (key: string): string | undefined => {
        const val = updatedInfo[key].previous;
        if (val) {
            updatedInfo[key].value = val;
        } else {
            delete updatedInfo[key];
        }
        setUpdateInfo(JSON.parse(JSON.stringify(updatedInfo)));
        return val;
    }

    const handleMediaError = (key: string): File | undefined => {
        const val = updatedMedia[key].previous;
        if (val) {
            updatedMedia[key].value = val;
        } else {
            delete updatedMedia[key];
        }
        setUpdatedMedia(JSON.parse(JSON.stringify(updatedMedia)));
        return val;
    }

    const handlePermissionError = (key: string) => {
        const val = updatedPermission[key].previous;
        if (val) {
            updatedPermission[key].value = val;
        } else {
            delete updatedPermission[key];
        }
        setUpdatedPermission(JSON.parse(JSON.stringify(updatedPermission)));
    }

    const handleMemberDelete = (memberId: string) => {
        if (updatedPermission[memberId]) {
            updatedPermission[memberId].deleted = true;
        } else {
            updatedPermission[memberId] = { deleted: true };
        }
        updatedPermission[memberId].deleted = true;
        setUpdatedPermission(JSON.parse(JSON.stringify(updatedPermission)));
    }

    const memberAdded = async () => {
        team.members = (await TeamService.getTeamMembers(team.team)).list;
    }

    return (
        <>
            {
                team &&
                <div className="team-details">
                    <h6 className="mb-3 mt-4">General information</h6>
                    <div className="d-flex row">
                        <div className="d-flex flex-column col-5 mr-4">
                            <span className="description mb-1">Team name (3 to 32 characters)</span>
                            <TextField
                                disabled={true}
                                value={name}
                                name="name"
                                className="NC-textfield"
                                inputProps={{ minLenght: 3, maxLength: 32 }}
                            />
                        </div>
                        <div className="d-flex flex-column col-3">
                            <span className="description mb-1">Team tag (2 to 6 characters)</span>
                            <TextField
                                value={tag}
                                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                    setTag(event.target.value);
                                    handleInfoChange('tag', event.target.value);
                                }}
                                name="name"
                                className="NC-textfield"
                                inputProps={{ minLenght:2, maxLength: 6 }}
                            />
                        </div>
                        <div className="d-flex flex-column col-12">
                            <span className="description mb-1 mt-4">Team description (max: 256 characters)</span>
                            <TextField
                                multiline={true}
                                value={description} 
                                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                    setDescription(event.target.value);
                                    handleInfoChange('description', event.target.value);
                                }}
                                name="name"
                                className="NC-textfield"
                                inputProps={{ maxLength: 256 }}
                            />
                        </div>
                    </div>

                    <div className="d-flex row">
                        <div className="col-7 mr-4">
                            <h6 className="mb-3 mt-4 pt-2">Banner</h6>
                            <div className="media">
                                <MediaSelector
                                    currentImage={bannerImage || `${process.env.REACT_APP_S3_URL}/teams/${team.team}/medias/BannerImage` }
                                    defaultImg={ `${process.env.REACT_APP_S3_URL}/media/default/default-team-banner.svg` }
                                    actionHook={(image: File) => {
                                        setBannerImage(image);
                                        handleMediaChange('BannerImage', image);
                                    }}
                                ></MediaSelector>
                            </div>

                        </div>
                        <div className="col-2">
                            <h6 className="mb-3 mt-4 pt-2">Logo</h6>
                            <div className="media logo">
                                <MediaSelector
                                    currentImage={ profileImage || `${process.env.REACT_APP_S3_URL}/teams/${team.team}/medias/ProfileImage` }
                                    defaultImg={ `${process.env.REACT_APP_S3_URL}/media/default/default-team-avatar.png`}
                                    actionHook={(image: File) => {
                                        setProfileImage(image);
                                        handleMediaChange('ProfileImage', image);
                                    }}
                                ></MediaSelector>
                            </div>
                        </div>
                    </div>

                    <div className="d-flex justify-content-between mb-3 mt-4 pt-2">
                        <h6>Members</h6>
                        <div
                            className="mask-icon add-user"
                            style={{
                                maskImage: `url(${process.env.REACT_APP_S3_URL}/media/icons/addUser.svg)`,
                                WebkitMaskImage:`url(${process.env.REACT_APP_S3_URL}/media/icons/addUser.svg)`
                            }}
                            onClick={() => { setMemberModal(true) }}
                        ></div>
                    </div>
                    <div className="d-flex flex-wrap row">
                        {
                            team.members.map((member: TeamMember) => {
                                return (
                                    (!updatedPermission[member.userId] || !updatedPermission[member.userId].deleted) &&
                                    <div key={member.userId} className="team mb-3">
                                        <TeamCard
                                            key={member.route}
                                            member={member}
                                            selected={updatedPermission[member.userId] ? updatedPermission[member.userId].value : undefined}
                                            noClose={!removable}
                                            actionHook={handlePermissionChange}
                                            closeClicked={handleMemberDelete}
                                        ></TeamCard>
                                    </div>
                                )
                            })
                        }
                    </div>
                    {
                        <div className={(ownerError ? 'active ' : '') + 'd-flex justify-content-center error-owner mb-2'}>
                            <WarningIcon className="mr-2 warning"/>
                            <div>Team must have one owner</div>
                        </div>
                    }

                    <div className="w-100 text-center">
                        {
                            isLoading &&
                            <CircularProgress />
                        }
                        {
                            !isLoading &&
                            <Button
                                className="text-uppercase"
                                variant="contained"
                                color="primary"
                                onClick={updateTeam}
                                disabled={ownerError}
                            >
                                Save
                            </Button>
                        }
                    </div>
                    <TeamInvitationModal
                        open={memberModal}
                        teamId={team.team}
                        setOpenDialog={setMemberModal}
                        actionHook={memberAdded}
                    />
                </div>
            }
            </>
    );
}