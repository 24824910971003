import React, { useState } from 'react';
import './CancelTournamentModal.scss';

import {
    Button,
    ButtonSize,
    ButtonTheme,
    ButtonType,
    NCSelect,
    NCTextArea
} from '@wolfiesports/srm-component';
import { useIntl } from 'react-intl';

interface GameAccountModalProps {
    title: string;
    actionHook: (e: boolean, reason?: string) => void;
    name?: string;
    forceCustom?: boolean;
}

export const CancelTournamentModal: React.FunctionComponent<GameAccountModalProps> = (props: GameAccountModalProps) => {
        const intl = useIntl();

        // NCSelect
        const ncSelectValues = [
            {
                key: intl.formatMessage({
                    id: 'tournament.cancel.select.none',
                }),
                value: ' ',
            },
            {
                key: intl.formatMessage({
                    id: 'tournament.cancel.select.custom',
                }),
                value: 'custom',
            },
            {
                key: intl.formatMessage({
                    id: 'tournament.cancel.select.external_issue',
                }),
                value: 'tournament.cancel.reason.external_issue',
            },
            {
                key: intl.formatMessage({
                    id: 'tournament.cancel.select.technical_issue',
                }),
                value: 'tournament.cancel.reason.technical_issue',
            },
            {
                key: intl.formatMessage({
                    id: 'tournament.cancel.select.low_checkin',
                }),
                value: 'tournament.cancel.reason.low_checkin',
            },
        ];

        // NC TextArea
        const [ncAreaValue, setNcAreaValue] = useState<string | undefined>(ncSelectValues[0].value);
        const [ncAreaValueKey, setNcAreaValueKey] = useState<string>('');
        const [disableTextArea, setDisableTextArea] = useState<boolean>(!props.forceCustom);

        return (
            <div className="Cancel-tournament-modal text-center p-3">
                <p>{props.title}</p>
                <h6 className="mt-4">{props.name}</h6>
                <div style={{ height: '260px' }}>
                    <div className="text-justify mt-4">
                        <p className="secondary-color-cool">
                            Please write down a reason for the users (optional)
                        </p>
                        {
                            !props.forceCustom &&
                            <NCSelect
                                selectFields={ncSelectValues}
                                fieldValue={'value'}
                                fieldName={'key'}
                                actionHook={(e) => {
                                    if (e) {
                                        if(  e === 'custom'){
                                            setNcAreaValue('')
                                            setDisableTextArea(false)
                                        }
                                        else {
                                            setNcAreaValue(intl.formatMessage({
                                                id: e,
                                            }));
                                            setNcAreaValueKey(e)
                                            setDisableTextArea(true);
                                        }
                                    }
                                }}
                            />
                        }
                    </div>
                    <div className="mt-4 textAreaWrapper">
                        <NCTextArea
                            value={ncAreaValue ? ncAreaValue : ''}
                            actionHook={(e) => {
                                setNcAreaValue(e);
                                setNcAreaValueKey(e);
                            }}
                            placeHolder="Write something..."
                            minRows={8}
                            maxRows={8}
                            disabled={disableTextArea}
                        />
                    </div>
                </div>
                <div className="d-flex justify-content-center mt-3">
                    <div className="mr-4 ">
                        <Button
                            label={'Validate'}
                            theme={ButtonTheme.CLASSIC}
                            type={ButtonType.PRIMARY}
                            size={ButtonSize.SMALL}
                            setClick={() => props.actionHook(true, ncAreaValueKey)}
                        />
                    </div>
                    <Button
                        label="Cancel"
                        theme={ButtonTheme.CLASSIC}
                        type={ButtonType.SECONDARY}
                        size={ButtonSize.SMALL}
                        setClick={() => props.actionHook(false)}
                    />
                </div>
            </div>
        );
    };
