import React, { ChangeEvent } from 'react'
import './MediaSelector.scss';


interface MediaSelectorProps {
    currentImage: string | File;
    defaultImg: string;
    actionHook: (image: File) => void;
}

export const MediaSelector: React.FunctionComponent<MediaSelectorProps> = ({currentImage, defaultImg, actionHook}) => {

    const handleUploadClick = (event: ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files ? event.target.files[0] : null;
        if (!file) {
            return;
        }
        const reader = new FileReader();
        reader.onload = (event: any) => {
            actionHook(event.target.result.toString());
        };
        reader.readAsDataURL(file);
    };

    return (
        <div
            className="media-container background-fullsize w-100 h-100 p-4 text-center d-flex flex-column justify-content-center position-relative"
            style={{ backgroundImage: `url(${currentImage}), url(${defaultImg})` }}
        >
            <input className="media-input position-absolute w-100 h-100 top-0 bottom-0" accept="image/*" type="file" onChange={handleUploadClick} />
            <div className="pb-3">drag and drop an image or click</div>
            <div
                className="cloud-icon mask-icon mx-auto"
                style={{
                    maskImage: `url(${process.env.REACT_APP_S3_URL}/media/icons/uploadCloud.svg)`,
                    WebkitMaskImage:`url(${process.env.REACT_APP_S3_URL}/media/icons/uploadCloud.svg)`
                }}
            ></div>
        </div>
    );
};