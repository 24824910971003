import { CircularProgress } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { NCTitle, NCTitleType } from 'src/atoms/NCTitle/NCTitle';
import { ActionDialog } from 'src/components/General/ActionDialog/ActionDialog';
import { FullScreenDialog } from 'src/components/General/FullScreenDialog/FullScreenDialog';
import { SimpleList } from 'src/components/General/SimpleList/SimpleList';
import { PartnerForm } from 'src/components/Partner/PartnerForm';
import { MongoPaginateResult } from 'src/models/PaginateResult';
import { PartnerModel } from 'src/models/Partner';
import { PartnerService } from 'src/services/partner.service';

export const Partners: React.FunctionComponent = () => {
    const [isLoading, setIsLoading] = useState(true);
    
    const [totalItem, setTotalItem] = useState<number>(0);
    const [totalPages, setTotalPages] = useState<number>(0);
    const [rows, setRows] = useState<Array<PartnerModel>>([]);

    const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
    const [openEditDialog, setOpenEditDialog] = useState<boolean>(false);
    const [openCreateDialog, setOpenCreateDialog] = useState<boolean>(false);
    
    const [actualPage, setActualPage] = useState<number>(0);
    const [selectedPartner, setSelectedPartner] = useState<PartnerModel>();
    
    const columns = ['Name'];
    const keepRows = ['name'];
    const sortColumn = 'name';

    useEffect(() => {
        getPartners();
        // eslint-disable-next-line
    }, [actualPage]);

    const getPartners = () => {
        setIsLoading(true);
        PartnerService.getPartners(actualPage, sortColumn).then((data: MongoPaginateResult<PartnerModel>) => {
            setRows(data.docs);
            setTotalItem(data.total);
            setTotalPages(data.pages);
        })
        .catch((error: any) => console.log(error))
        .finally(() => setIsLoading(false));
    };

    const handleClickOpen = (partner: PartnerModel, kind: string) => {
        setSelectedPartner(partner);
        
        switch (kind) {
            case 'delete':
                setOpenDeleteDialog(true);
                break;
            case 'edit':
                setOpenEditDialog(true);
                break;
            case 'create': {
                setSelectedPartner({name: '', image: ''})
                setOpenCreateDialog(true);
                break;
            }
            default:
                return;
        }
    };

    const deleteAction = async(choice: boolean) => {
        if (choice && selectedPartner?._id) {
            PartnerService.deletePartner(selectedPartner?._id).then(() => {
                updateList();
            }).catch((error: any) => console.log(error));
        }
    };

    const updateList = () => {
        setOpenEditDialog(false);
        setOpenCreateDialog(false);
        setOpenDeleteDialog(false);
        getPartners();
    };

    return <div className="NC-container NC-card">
        <NCTitle text={`Partners (${totalItem ? totalItem : '0'})`} type={NCTitleType.ADMINISTRATOR}/>
        <div className="d-flex">
            {
                isLoading && <CircularProgress className="mx-auto"/>
            }
            {
                !isLoading &&
                <SimpleList
                    rows={rows}
                    columns={columns}
                    keepRows={keepRows}
                    actionsButtons={{create: true, edit: true, delete: true}}
                    handleClickOpen={handleClickOpen}
                    pagination={{actual: actualPage, total: totalPages, setActual: setActualPage}}
                />
                
            }
        </div>
        {selectedPartner && <>
            <ActionDialog
                    open={openDeleteDialog}
                    title={`Are you sure to delete the partner : ${selectedPartner.name}`}
                    openHook={setOpenDeleteDialog}
                    actionHook={deleteAction}
                />
            <FullScreenDialog
                disableEnforceFocus={true}
                open={openEditDialog}
                openHook={setOpenEditDialog}
                actionHook={updateList}
                componentToDisplay={
                    <PartnerForm actionHook={updateList} partner={selectedPartner} />
                }
            />
            <FullScreenDialog
                disableEnforceFocus={true}
                open={openCreateDialog}
                openHook={setOpenCreateDialog}
                actionHook={updateList}
                componentToDisplay={
                    <PartnerForm actionHook={updateList} partner={selectedPartner} creationMode={true} />
                }
            />
        </>}
    </div>
}