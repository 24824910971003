
export class PremiumPlan {
    _id: string;

    constructor(
        public name: string,
        public product: string,
        public ids: {
            stripe: string,
            paypal: string,
        },
        public currency: string,
        public countries: Array<string>,
        public price: number,
        public interval: string,
        public duration: number,
        public active: boolean,
        public oneTimePayment: boolean,
    ) {
    }
}

export enum PremiumPlanInterval {
    DAY = 'day',
    MONTH = 'month',
    YEAR = 'year',
}
