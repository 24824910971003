export class Tournament {
    constructor(
        public id: string,
        public g4gId: string,
        public name: string,
        public partner: string,
        public route: string,
        public templateId: string,
        public date: any,
        public state: TournamentState,
        public gameSlug: string,
        public platforms: string[]
    ) {}
}

export enum TournamentState{
    Scheduled = 0,
	Generating = 1,
	Started = 2,
    Paused = 3,
    Ended = 4,
    Cancelled = 5
}