import { Accordion, Button, FormControl, IconButton, InputLabel, List, ListItem, ListItemSecondaryAction, ListItemText, MenuItem, Select, TextField } from '@material-ui/core';
import { BrickType, ComparisonOperatorLUT, Kind, Logical, Operator, PredicateCondition, PredicateEditionAugmented, TrainingBrickAssignement, TrainingBrickVideo, Type } from 'src/models/TrainingBrick';
import { GamePartial, TrainingPreset } from 'src/models/TrainingPreset';
import React, { ChangeEvent, useEffect, useState } from 'react';

import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import CreateIcon from '@material-ui/icons/Create';
import DeleteIcon from '@material-ui/icons/Delete';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { MediaService } from 'src/services/media.service';
import { ModalAddPresets } from 'src/components/Training/Presets/ModalAddPresets';
import SaveIcon from '@material-ui/icons/Save';
import { TrainingService } from 'src/services/training.service';
import { TrainingThematic } from 'src/models/TrainingThematic';
import { TranslatedString } from 'src/models/TranslatedString';
import { toast } from 'react-toastify';
import { LangPicker } from 'src/components/General/LangPicker/LangPicker';
import { useStoreState } from 'src/store';

interface BrickInformationProps {
    primaryBlock: any;
    lang: string;
    thematics: Array<TrainingThematic>;
    selectedBrick: TrainingBrickAssignement | TrainingBrickVideo;
    selectedPath?: string;
    selectedGame: GamePartial;
    isCreate?: boolean;
    blockId?: string;
    isMenuBrick: boolean;
    isPublished: boolean;
    resetAdd: () => void;
    setUpdateModule: () => void;
    onRefreshBrick: () => void;
    onMenuChange: () => void;
    toCapitalizeCase: (text: string) => string;
}

export const BrickInformation: React.FunctionComponent<BrickInformationProps> = (props: BrickInformationProps) => {
    const emptyPredicate: PredicateEditionAugmented = { path: '', description: {}, operator: Operator.eq, options: { kind: Kind.String }, type: Type.COMPARISON, predicateOptionsHandlers: {} };
    const [ videoList, setVideoList ] = useState<Array<any>>([]);
    const [ presetList, setPresetList ] = useState<Array<TrainingPreset>>([]);
    const [ selectedVideo, setSelectedVideo ] = useState<string>();
    const [ selectedThematic, setSelectedThematic ] = useState<string>();
    const [ selectedType, setSelectedType ] = useState<string>();
    const [ tip, setTip] = useState<TranslatedString>({});
    const [ refresh, setRefresh] = useState<boolean>(false);
    const [ openDialog, setOpenDialog] = useState<boolean>(false);
    const [ typeModal, setTypeModal ] = useState<PredicateCondition>();
    const [ brick, setBrick ] = useState<TrainingBrickAssignement | TrainingBrickVideo>(props.selectedBrick);
    const [ repetition, setRepetition ] = useState<number>((props.selectedBrick as TrainingBrickAssignement).repetitions);
    const [ predicate, setPredicate ] = useState<PredicateEditionAugmented>(emptyPredicate);
    const [ predicateIndex, setPredicateIndex ] = useState<number>(-1);
    const [ tips, setTips ] = useState<Array<TranslatedString>>((brick as TrainingBrickAssignement).tips);
    const [ tipIndex, setTipIndex ] = useState<number>(-1);
    const [ lang, setLang ] = useState<string>(useStoreState(state => state.contentLang));


    useEffect(() => {
        if (props.selectedGame._id) {
            MediaService.getVideos().then((data: any) => {
                setVideoList(data.docs);
            });
            TrainingService.getPresets(500, 0, `&game._id=${props.selectedGame._id}`).then((data: any) => {
                setPresetList(data.docs);
            });
        }
    }, [ props.selectedGame._id ]);

    useEffect(() => {
        if (props.selectedBrick && props.lang) {
            setBrick(props.selectedBrick);
            setSelectedType(props.selectedBrick.type as BrickType);
            setSelectedThematic((props.thematics
                .filter(t => t.value === (props.selectedBrick && props.selectedBrick.thematic))
                .map(t => t.value))
                .find(t => t));
            switch (props.selectedBrick.type) {
                case BrickType.ASSIGNEMENT:
                    setTips((props.selectedBrick as TrainingBrickAssignement).tips);
                    setRepetition((props.selectedBrick as TrainingBrickAssignement).repetitions);
                    if (typeof (props.selectedBrick as TrainingBrickAssignement).video === 'string') {
                        setSelectedVideo((props.selectedBrick as TrainingBrickAssignement).video);
                    }
                    break;
                case BrickType.VIDEO:
                    if (typeof (props.selectedBrick as TrainingBrickVideo).targetId === 'string') {
                        setSelectedVideo((props.selectedBrick as TrainingBrickVideo).targetId);
                    }
                    break;
            }
        }
    }, [ props.selectedBrick, props.thematics, props.lang ]);

    useEffect(() => {
        if (props.selectedBrick.type === BrickType.ASSIGNEMENT) {
            setTips((props.selectedBrick as TrainingBrickAssignement).tips);
        }
    }, [ props.selectedBrick, lang ]);

    const refreshBrick = (type: PredicateCondition, predicate: PredicateEditionAugmented, index: number) => {
        if (brick.type === BrickType.ASSIGNEMENT) {
            switch (type) {
                case PredicateCondition.PREREQUISITE:
                    if (index < 0) {
                        (brick as TrainingBrickAssignement).startCondition.members.push(predicate);
                    } else {
                        (brick as TrainingBrickAssignement).startCondition.members[index] = predicate;
                    }
                    break;
                case PredicateCondition.TARGET:
                    if (index < 0) {
                        (brick as TrainingBrickAssignement).winCondition.members.push(predicate);
                    } else {
                        (brick as TrainingBrickAssignement).winCondition.members[index] = predicate;
                    }
                    break;
            }
        }
        setBrick(brick);
    };

    const onTypeChange = (type: BrickType) => {
        brick.type = type;
        setSelectedType(type);
        const startCondition = { members: [], operator: Logical.AND, type: 'LOGICAL' };
        const winCondition = { members: [], operator: Logical.AND, type: 'LOGICAL' };
        const brickAssignement: TrainingBrickAssignement = new TrainingBrickAssignement({}, {}, '', [], '', startCondition, winCondition, props.selectedGame, 5, [], 0, 0);
        const brickVideo: TrainingBrickVideo = new TrainingBrickVideo({}, {}, '');

        switch (type) {
            case BrickType.ASSIGNEMENT:
                setBrick(brickAssignement);
                break;
            case BrickType.VIDEO:
                setBrick(brickVideo);
                break;
        }
    };

    const onThematicChange = (thematic: string) => {
        brick.thematic = thematic;
        setSelectedThematic(thematic);
        setBrick(brick);
    };

    const onVideoChange = (video: string) => {
        switch (brick.type) {
            case BrickType.ASSIGNEMENT:
                (brick as TrainingBrickAssignement).video = video;
                break;
            case BrickType.VIDEO:
                (brick as TrainingBrickVideo).targetId = video;
            break;
        }
        setSelectedVideo(video);
        setBrick(brick);
    };

    const onBrickChange = (event: any, translated = false) => {
        const { name, value } = event.currentTarget;

        if (translated) {
            const myBrickAttr = brick[name as keyof (TrainingBrickAssignement | TrainingBrickVideo)] as TranslatedString;
            myBrickAttr[props.lang] = value;
            setBrick(Object.assign({}, brick, {
                [name.lang]: value,
            }));
        }
    };

    const onRepetitionChange = (value: string) => {
        setRepetition(Number(value));
    };

    const save = () => {
        if (!props.selectedPath || Object.keys(brick.name).length === 0 || Object.keys(brick.description).length === 0 || !brick.thematic) {
            toast.error('You must fill mandatory fields before saving');
            return;
        }
        const path = props.selectedPath.split('/');
        if (brick.type === BrickType.ASSIGNEMENT) {
            (brick as TrainingBrickAssignement).repetitions = repetition;
            (brick as TrainingBrickAssignement).tips = tips;
        }
        if (props.blockId) {
            if (!props.isCreate) {
                TrainingService.updateBlock(props.blockId, props.primaryBlock[0]);
            }
            if (props.isCreate) {
                props.primaryBlock[0].children[path[0]].children[path[1]].children.push(brick);
                TrainingService.updateBlock(props.blockId, props.primaryBlock[0]);
            }
            props.resetAdd();
            props.onRefreshBrick();
        }
    };

    const onTipChange = (text: string) => {
        setTip(Object.assign({}, tip, {
            [lang]: text,
        }));
    };

    const addTip = () => {
        if (Object.keys(tip).length === 0) {
            toast.error('You must fill at least one lang before adding');
            return;
        }
        tips.push(tip);
        setTip({});
    };

    const updateTip = () => {
        tips[tipIndex] = tip;
        setTip({});
        setTipIndex(-1);
    }

    const updateItem = (item: TranslatedString, index: number) => {
        setTip(item);
        setTipIndex(index);
    };

    const deleteItem = (list: Array<any>, index: number) => {
        list.splice(index, 1);
        setRefresh(!refresh);
    };

    const openModal = (type: PredicateCondition, data: PredicateEditionAugmented, index: number = -1) => {
        setTypeModal(type);
        setPredicate(data);
        setPredicateIndex(index);
        setOpenDialog(true);
    };

    const deleteBrick = () => {
        if (!window.confirm(`This will delete the brick with the path ${props.selectedPath} forever, are you sure ?`)) {
            return;
        }
        if (!props.selectedPath) {
            return;
        }
        const path = props.selectedPath.split('/');
        if (props.blockId) {
            if (!props.isCreate) {
                const sequentialBlock = props.primaryBlock[0].children[path[0]];
                if (sequentialBlock.children[path[1]].children.length < 2){
                    sequentialBlock.children.splice(path[1], 1);
                } else {
                    sequentialBlock.children[path[1]].children.splice(path[2], 1);
                }
                props.primaryBlock[0].children[path[0]] = sequentialBlock;
                TrainingService.updateBlock(props.blockId, props.primaryBlock[0]);
            }
            props.onRefreshBrick();
        }
    };

    const ButtonOpenModal = (type: PredicateCondition) => {
        return (
            <Button className="m-2" variant="contained" color="secondary"onClick={() => openModal(type, emptyPredicate)}>
                {`Add ${type}`}
            </Button>
        );
    };

    const DisplayList = (list: Array<any>, type?: PredicateCondition) => {
        return (
            <FormControl className="w-100 mb-4">
                <List>
                    {list && list.length > 0 && list.map((item, index) =>
                        <ListItem key={index}>
                            {
                                type ?
                                    <ListItemText primary={`${item.path} ${ComparisonOperatorLUT[item.operator as Operator]} ${item.value}`}
                                        secondary={item.description[props.lang]} />
                                :
                                    item[lang] ?
                                        <ListItemText primary={item[lang]} />
                                    :
                                        <ListItemText primary={`No translation for ${lang.toUpperCase()} ${item['en'] ? `(${item['en']})` : ''}`} style={{ color: "#ff0000" }} />
                            }
                            <ListItemSecondaryAction>
                                {
                                    type ?
                                        <IconButton edge="end" aria-label="update" onClick={() => openModal(type, item, index)}>
                                            <CreateIcon />
                                        </IconButton>
                                    :
                                        <IconButton edge="end" aria-label="update" onClick={() => updateItem(item, index)}>
                                            <CreateIcon />
                                        </IconButton>
                                }
                                <IconButton edge="end" aria-label="delete" onClick={() => (deleteItem(list, index))}>
                                    <DeleteIcon />
                                </IconButton>
                            </ListItemSecondaryAction>
                        </ListItem>
                    )}
                </List>
            </FormControl>
        );
    };

    return (
        <>
        <div className="module-information w-100">
            <Accordion expanded={props.isMenuBrick} key={brick._id} onChange={() => props.onMenuChange()}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon/>}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                >
                    Brick Information
                </AccordionSummary>
                <AccordionDetails className="d-flex flex-column">
                    <FormControl className="w-100 mb-4">
                        <InputLabel shrink>
                            Type
                        </InputLabel>
                        <Select
                            name="type"
                            value={selectedType ? selectedType : ''}
                            onChange={(event) => {onTypeChange(event.target.value as BrickType)}}
                        >
                            <MenuItem key={0} value={BrickType.ASSIGNEMENT}>{BrickType.ASSIGNEMENT}</MenuItem>
                            <MenuItem key={1} value={BrickType.VIDEO}>{BrickType.VIDEO}</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl className="w-100 mb-4">
                        <TextField
                            name="name"
                            label="Title (mandatory)"
                            value={brick.name[props.lang] || ''}
                            onChange={(event: ChangeEvent<HTMLInputElement>) => (onBrickChange(event, true))}
                        />
                    </FormControl>
                    <FormControl className="w-100 mb-4">
                        <TextField
                            name="description"
                            label="Description (mandatory)"
                            multiline
                            value={brick.description[props.lang] || ''}
                            onChange={(event: ChangeEvent<HTMLInputElement>) => (onBrickChange(event, true))}
                        />
                    </FormControl>
                    <FormControl className="w-100 mb-4">
                        <InputLabel shrink>
                            Thematic
                        </InputLabel>
                        <Select
                            name="thematic"
                            value={selectedThematic ? selectedThematic : ''}
                            onChange={(event) => {onThematicChange(event.target.value as string)}}
                        >
                            {
                                props.thematics.map((thematic: any, index: number) => {
                                    return (
                                        <MenuItem key={index} value={thematic.value}>
                                            {thematic.name[props.lang] ? thematic.name[props.lang] : thematic.name['en']}
                                        </MenuItem>
                                    );
                                })
                            }
                        </Select>
                    </FormControl>
                    <FormControl className="w-100 mb-4">
                        <InputLabel shrink>
                            Tutorial video
                        </InputLabel>
                        <Select
                            name="video"
                            value={selectedVideo ? selectedVideo : ''}
                            onChange={(event) => {onVideoChange(event.target.value as string)}}
                        >
                            <MenuItem key={0} value=''>NONE</MenuItem>
                            {
                                videoList.map((video: any, index: number) => {
                                    return (
                                        <MenuItem key={index} value={video._id}>
                                            {video.title[props.lang] ? video.title[props.lang] : video.title['en']}
                                        </MenuItem>
                                    );
                                })
                            }
                        </Select>
                    </FormControl>
                    {
                        brick && brick.type === BrickType.ASSIGNEMENT ?
                            <div className="w-100 d-flex flex-column">
                                <FormControl className="w-100 d-flex flex-row mb-4">
                                    <TextField
                                        name="tips"
                                        label="Tips"
                                        value={tip[lang] || ''}
                                        className="w-100"
                                        onChange={(event: ChangeEvent<HTMLInputElement>) => (onTipChange(event.currentTarget.value))}
                                        />
                                    {
                                        tipIndex < 0 ?
                                            <IconButton edge="end" aria-label="delete" onClick={() => (addTip())}>
                                                <AddCircleIcon />
                                            </IconButton>
                                        :
                                            <IconButton edge="end" aria-label="delete" onClick={() => (updateTip())}>
                                                <SaveIcon />
                                            </IconButton>
                                    }
                                    <LangPicker setLang={ setLang } />
                                </FormControl>
                                { DisplayList(tips) }
                                <FormControl className="w-100 mb-4">
                                    <TextField
                                        name="repetitions"
                                        label="Repetitions (number of games)"
                                        type="number"
                                        value={repetition}
                                        onChange={(event: ChangeEvent<HTMLInputElement>) => (onRepetitionChange(event.currentTarget.value))}
                                    />
                                </FormControl>
                                { ButtonOpenModal(PredicateCondition.PREREQUISITE) }
                                { DisplayList((brick as TrainingBrickAssignement).startCondition.members, PredicateCondition.PREREQUISITE) }
                                { ButtonOpenModal(PredicateCondition.TARGET) }
                                { DisplayList((brick as TrainingBrickAssignement).winCondition.members, PredicateCondition.TARGET) }
                            </div>
                        : null
                    }
                    <Button className="m-2" variant="contained" color="primary" onClick={() => save()}>Save</Button>
                    {
                        (process.env.NODE_ENV !== 'production' || !props.isPublished) &&
                        <Button className="m-2" variant="contained" color="default" onClick={() => deleteBrick()}>Delete</Button>
                    }
                </AccordionDetails>
            </Accordion>
            {
                typeModal ?
                    <ModalAddPresets
                        open={openDialog}
                        type={typeModal}
                        presets={presetList}
                        lang={props.lang}
                        predicate={predicate}
                        predicateIndex={predicateIndex}
                        setOpenDialog={setOpenDialog}
                        toCapitalizeCase={props.toCapitalizeCase}
                        actionHook={refreshBrick}
                    />
                : null
            }
        </div>
        </>
    )
}
