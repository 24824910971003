import React, {ChangeEvent, useEffect, useState} from 'react';
import {TrainingProgram} from 'src/models/TrainingProgram';
import {NCTitle} from 'src/atoms/NCTitle/NCTitle';
import TextField from '@material-ui/core/TextField';
import {Editor} from '@tinymce/tinymce-react';
import {Button} from '@material-ui/core';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { TranslatedString } from 'src/models/TranslatedString';

interface ProgramAboutProps {
    program: TrainingProgram;
    programChange: any;
    edit: boolean;
    lang: string;
}

export const ProgramAbout: React.FunctionComponent<ProgramAboutProps> = ({program, programChange, edit, lang}) => {
    const [onChanged, setOnChanged] = useState<boolean>(false);

    useEffect(() => {
        setOnChanged(false);
    }, [onChanged]);

    const onEditorChange = (content: any) => {
        program.description[lang] = content;
        programChange(program);
    };

    const onArrayTranslatedChange = (event: any, index: number) => {
        const {name, value} = event.currentTarget;
        const myLang = program[name as keyof TrainingProgram] as Array<TranslatedString>;
        myLang[index][lang] = value;
        programChange(program);
    };

    const addNewItem = (itemType: string) => {
        switch (itemType) {
            case 'axe':
                program.axes.push({en: ''});
                break;
            case 'improveTag':
                program.improveTags.push({en: ''});
                break;
            case 'about':
                program.listAbout.push({en: ''});
                break;
        }
        setOnChanged(true);
        programChange(program);
    };

    const deleteItem = (itemType: string, item: any) => {
        switch (itemType) {
            case 'axe':
                if (program.axes.indexOf(item, 0) > -1) {
                    program.axes.splice(program.axes.indexOf(item, 0), 1);
                }
                break;
            case 'improveTag':
                if (program.improveTags.indexOf(item, 0) > -1) {
                    program.improveTags.splice(program.improveTags.indexOf(item, 0), 1);
                }
                break;
            case 'about':
                if (program.listAbout.indexOf(item, 0) > -1) {
                    program.listAbout.splice(program.listAbout.indexOf(item, 0), 1);
                }
                break;
        }
        setOnChanged(true);
        programChange(program);
    };


    return (
        <div className="row px-0 px-sm-5 mt-3">
            <div className="col-12 mb-3 px-3">
                <NCTitle text={'Description'} color="white"/>
                <Editor apiKey={process.env.REACT_APP_TINYMCE_KEY}
                        init={{
                            height: 200,
                            a11y_advanced_options: true,
                            image_title: true,
                            automatic_uploads: true,
                            file_picker_types: 'image',
                            plugins: 'link lists media table image imagetools media',
                            toolbar: 'undo redo | styleselect fontselect fontsizeselect removeformat | bold italic | alignleft aligncenter alignright alignjustify | ' +
                                'bullist numlist outdent indent | image media link | spellchecker | forecolor backcolor',
                        }}
                        value={program.description[lang]}
                        onEditorChange={(event: any) => (onEditorChange(event))}
                />
                {
                    (program.description['en'] || program.description['fr']) &&
                        <p>
                            Helper : {program.description['en'] ? `EN "${program.description['en']}"` : `FR "${program.description['fr']}"`}
                        </p>
                }
            </div>
            <div className="col-12 mb-3 px-3">
                <NCTitle text={'Axes'} color="white"/>
                <Button variant="contained" className="my-2" color="primary" onClick={() => addNewItem('axe')}>
                    Add answer
                 </Button>
                {
                    program.axes && program.axes.map((axe: any, index: number) => {
                        return (
                            <div className="col-12 mb-3 px-3 row" key={index}>
                                <div className="col-1 align-self-center">
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        className="mr-2 my-auto deleteAnswerButton"
                                        onClick={() => deleteItem('axe', axe)}
                                    ><DeleteForeverIcon/></Button>
                                </div>
                                <TextField
                                    disabled={!edit}
                                    name="axes"
                                    label="Axes"
                                    className="col-11"
                                    defaultValue={axe[lang] || ''}
                                    onChange={(event: ChangeEvent<HTMLInputElement>) => (onArrayTranslatedChange(event, index))}
                                    helperText={lang === 'en' ? `EN : "' + ${lang}"` : (lang === 'fr' ? `FR : "${lang}"` : '')}
                                />
                            </div>
                        );
                    })
                }
            </div>

            <div className="col-12 mb-3 px-3">
                <NCTitle text={'Improvement Tags'} color="white"/>
                <Button variant="contained" className="my-2" color="primary" onClick={() => addNewItem('improveTag')}>
                    Add tag
                </Button>
                {
                    program.improveTags && program.improveTags.map((improveTag: any, index: number) => {
                        return (
                            <div className="col-12 mb-3 px-3 row" key={index}>
                                <div className="col-1 align-self-center">
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        className="mr-2 my-auto deleteAnswerButton"
                                        onClick={() => deleteItem('improveTag', improveTag)}
                                    ><DeleteForeverIcon/></Button>
                                </div>
                                <TextField
                                    disabled={!edit}
                                    name="improveTags"
                                    label="Improve Tag"
                                    className="col-11"
                                    defaultValue={improveTag[lang] || ''}
                                    onChange={(event: ChangeEvent<HTMLInputElement>) => (onArrayTranslatedChange(event, index))}
                                    helperText={improveTag['en'] ? (improveTag['en'] !== undefined ? 'EN : "' + improveTag['en'] + '"' : '') : (improveTag['fr'] !== undefined ? 'FR : "' + improveTag['fr'] + '"' : '')}
                                />
                            </div>
                        );
                    })
                }
            </div>

            <div className="col-12 mb-3 px-3">
                <NCTitle text={'About List'} color="white"/>
                <Button variant="contained" className="my-2" color="primary" onClick={() => addNewItem('about')}>Add
                    tag</Button>
                {
                    program.listAbout && program.listAbout.map((about: any, index: number) => {
                        return (
                            <div className="col-12 mb-3 px-3 row" key={index}>
                                <div className="col-1 align-self-center">
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        className="mr-2 my-auto deleteAnswerButton"
                                        onClick={() => deleteItem('about', about)}
                                    ><DeleteForeverIcon/></Button>
                                </div>
                                <TextField
                                    disabled={!edit}
                                    name="listAbout"
                                    label="About"
                                    className="col-11"
                                    defaultValue={about[lang] || ''}
                                    onChange={(event: ChangeEvent<HTMLInputElement>) => (onArrayTranslatedChange(event, index))}
                                    helperText={about['en'] ? (about['en'] !== undefined ? 'EN : "' + about['en'] + '"' : '') : (about['fr'] !== undefined ? 'FR : "' + about['fr'] + '"' : '')}
                                />
                            </div>
                        );
                    })
                }
            </div>
        </div>
    );
};