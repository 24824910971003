import {CircularProgress} from '@material-ui/core';
import React, {useEffect, useState} from 'react';
import {toast} from 'react-toastify';
import {GameService} from 'src/services/game.service';
import {TrainingService} from 'src/services/training.service';
import {ActionDialog} from 'src/components/General/ActionDialog/ActionDialog';
import {FullScreenDialog} from 'src/components/General/FullScreenDialog/FullScreenDialog';
import {SimpleList} from 'src/components/General/SimpleList/SimpleList';
import {ThematicsForm} from 'src/components/Training/Thematics/ThematicsForms/ThematicsForm';
import {NCTitle, NCTitleType} from 'src/atoms/NCTitle/NCTitle';
import {Game} from 'src/models/Game';
import {TrainingThematic} from 'src/models/TrainingThematic';
import './Thematics.scss';

export const TrainingThematics: React.FunctionComponent = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [rows, setRows] = useState<any>([]);
    const [thematic, setThematic] = useState<TrainingThematic>();
    const [actionDelete, setActionDelete] = useState<boolean>(false);
    const [actionEdit, setActionEdit] = useState<boolean>(false);
    const [actionCreate, setActionCreate] = useState<boolean>(false);
    const [actualPage, setActualPage] = useState<number>(0);
    const [totalItem, setTotalItem] = useState<number>(0);
    const [gameList, setGameList] = useState<Array<Game>>([]);
    const [searchedGame] = useState<string>('');
    const columns = ['Name', 'Games', 'Visible'];
    const keepRows = ['name', 'games', 'visible'];

    useEffect(() => {
        TrainingService.getThematics(20, actualPage).then((data) => {
            setTotalItem(Math.ceil(data.total / 20));
            Promise.all(data.docs.map(async (d: any) => ({
                ...d,
                games: (await GameService.getGamesByIds(d.gameIds).then(r => r.map(g => g.name).join(', '))),
            }))).then(r => setRows(r));
            setIsLoading(false);
        }).catch(() => {
            toast.error('Error while fetching training thematics');
        });

        getGames();
    }, [searchedGame, actualPage]);

    const getGames = () => {
        GameService.getGames(50, 0, true).then((data) => {
            setGameList(data.docs);
        }).catch(() => {
            toast.error('Error while fetching game list');
        });
    };

    const actionManager = (thematic: TrainingThematic, kind: string) => {
        setThematic(thematic);

        switch (kind) {
            case 'delete':
                setActionDelete(true);
                break;
            case 'edit':
                setActionEdit(true);
                break;
            case 'create': {
                setThematic(
                    new TrainingThematic(
                        {en: '', fr: ''},
                        [],
                        '',
                        '',
                        false,
                    )
                );
                setActionCreate(true);
                break;
            }
            default:
                return;
        }
    };

    const deleteThematic = (choice: boolean) => {
        if (choice && thematic) {
            TrainingService.deleteThematic(thematic._id).then(() => {
                updateList(true);
            }).catch(error => console.log(error));
        }
    };

    const updateList = (result: boolean) => {
        setActionEdit(false);
        setActionCreate(false);
        if (result) {
            TrainingService.getThematics(20, actualPage).then((data) => {
                setTotalItem(Math.ceil(data.total / 20));
                Promise.all(data.docs.map(async (d: any) => ({
                    ...d,
                    games: (await GameService.getGamesByIds(d.gameIds).then(r => r.map(g => g.name).join(', '))),
                }))).then(r => setRows(r));
                setIsLoading(false);
            }).catch(() => {
                toast.error('Error while fetching training thematics');
            });
        }
    };

    return (
        <div className="NC-container NC-card d-flex flex-column">
            <NCTitle text={'Training Thematics'} type={NCTitleType.TRAINING}/>
            <div className="d-flex">
                {
                    isLoading && <CircularProgress className="mx-auto"/>
                }
                {
                    !isLoading &&
                    <SimpleList
                        rows={rows}
                        columns={columns}
                        keepRows={keepRows}
                        actionsButtons={{create: true, edit: true, delete: true}}
                        handleClickOpen={actionManager}
                        pagination={{actual: actualPage, total: totalItem, setActual: setActualPage}}
                    />
                }
            </div>
            <ActionDialog
                open={actionDelete}
                title={`Are you sure to delete this game : ${thematic?.name.en}`}
                openHook={setActionDelete}
                actionHook={deleteThematic}
            />
            {
                thematic &&
                <FullScreenDialog
                    open={actionEdit}
                    openHook={setActionEdit}
                    actionHook={updateList}
                    componentToDisplay={
                        <ThematicsForm
                            thematic={thematic}
                            actionHook={updateList}
                            gameList={gameList}
                        />
                    }
                />
            }
            {
                thematic &&
                <FullScreenDialog
                    open={actionCreate}
                    openHook={setActionCreate}
                    actionHook={updateList}
                    componentToDisplay={
                        <ThematicsForm
                            thematic={thematic}
                            actionHook={updateList}
                            creationMode={true}
                            gameList={gameList}
                        />
                    }
                />
            }
        </div>
    );
};