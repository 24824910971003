export class GamePage {
    public countryGroup?: Array<string>;
    public countries?: Array<string>;

    constructor(
        public _id: string,
        public date_creation: Date,
        public game: GamePageGame,
        public challenge: EnableGamePage,
        public twitter: EnableGamePage,
        public tv: EnableGamePage,
        public tournament: EnableGamePage,
        public training: EnableGamePage,
        public active: boolean,
    ) {}
}

export interface EnableGamePage {
    enable: boolean;
    url?: string,
    challengeId?: string,
}

export interface GamePageGame {
    id: string;
    slug: string,
}

export enum EGamePageImage {
    BackgroundImage = 'BackgroundImage',
    LogoImage = 'LogoImage',
}

export type GamePageKey = keyof GamePage;