export class HomepageEvent {
    constructor(
        public id: string,
        public name: string,
        public value: string,
        public link: string,
        public published: boolean,
        public target: Array<string>,
        public createdAt: Date,
    ) {
    }
}