import './Dashboard.scss';

import {CategorizedButton, CategorizedButtonVariant} from '../../../atoms/CategorizedButton/CategorizedButton';
import {NCTitle, NCTitleType} from 'src/atoms/NCTitle/NCTitle';
import React, {useEffect, useState} from 'react';

import {CreateModuleForm} from 'src/pages/Training/Programs/ProgramModules/CreateModuleForm/CreateModuleForm';
import {FullScreenDialog} from 'src/components/General/FullScreenDialog/FullScreenDialog';
import {LangPicker} from 'src/components/General/LangPicker/LangPicker';
import {Link} from '@material-ui/core';
import {ModuleList} from 'src/components/Training/Modules/ModuleList';
import {ProgramList} from 'src/components/Training/Programs/ProgramList';
import {TrainingModule} from 'src/models/TrainingModule';
import {TrainingService} from '../../../services/training.service';
import {useStoreState} from 'src/store';

export const TrainingDashboard: React.FunctionComponent = () => {
    const [lang, setLang] = useState<string>(useStoreState(state => state.contentLang));
    const [presetCount, setPresetCount] = useState<number>(0);
    const [thematicCount, setThematicCount] = useState<number>(0);
    const [quizCount, setQuizCount] = useState<number>(0);
    const [broadcastCount, setBroadcastCount] = useState<number>(0);
    const [moduleCreation, setModuleCreation] = useState<boolean>(false);
    const [moduleRefresh, setModuleRefresh] = useState<boolean>(false);
    const [module, setModule] = useState<TrainingModule>();

    useEffect(() => {
        TrainingService.getPresetsCount().then(data => {
            setPresetCount(data);
        });

        TrainingService.getThematicCount().then(data => {
            setThematicCount(data);
        });

        TrainingService.getQuizCount().then(data => {
            setQuizCount(data);
        });

        TrainingService.getBroadcastCount().then(data => {
            setBroadcastCount(data);
        });
    });

    const moduleUpdated = () => {
        setModuleCreation(false);
        setModuleRefresh(!moduleRefresh);
    };

    return (
        <div className="NC-container training-dashboard">
            <div className="d-flex flex-row justify-content-between align-items-center">
                <NCTitle text={'Training Dashboard'} type={NCTitleType.TRAINING}/>
                <div className="d-flex">
                    <LangPicker setLang={setLang}/>
                </div>
            </div>
            <div className="row">
                <div className="row w-100">
                    <div className="col-md-3 col-12">
                        <div className="NC-card d-flex flex-column">
                            <NCTitle text={'Presets'} link={{label: 'View more', url: '/training/presets'}}
                                     type={NCTitleType.TRAINING}/>
                            <div
                                className="flex-fill NC-info d-flex flex-column justify-content-between align-items-center m-1">
                                <span className="text-uppercase mb-1 title"></span>
                                <span className="big-text">{presetCount}</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 col-12">
                        <div className="NC-card d-flex flex-column">
                            <NCTitle text={'Thematics'} link={{label: 'View more', url: '/training/thematics'}}
                                     type={NCTitleType.TRAINING}/>
                            <div
                                className="flex-fill NC-info d-flex flex-column justify-content-between align-items-center m-1">
                                <span className="text-uppercase mb-1 title"></span>
                                <span className="big-text">{thematicCount}</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 col-12">
                        <div className="NC-card d-flex flex-column">
                            <NCTitle text={'Quiz'} link={{label: 'View more', url: '/training/quiz'}}
                                     type={NCTitleType.TRAINING}/>
                            <div
                                className="flex-fill NC-info d-flex flex-column justify-content-between align-items-center m-1">
                                <span className="text-uppercase mb-1 title"></span>
                                <span className="big-text">{quizCount}</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 col-12">
                        <div className="NC-card d-flex flex-column">
                            <NCTitle text={'Broadcast'} link={{label: 'View more', url: '/training/broadcast'}}
                                     type={NCTitleType.TRAINING}/>
                            <div
                                className="flex-fill NC-info d-flex flex-column justify-content-between align-items-center m-1">
                                <span className="text-uppercase mb-1 title"></span>
                                <span className="big-text">{broadcastCount}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row w-100">
                    <div className="col-md-12">
                        <div className="NC-card d-flex flex-column">
                            <div className="d-flex flex-row justify-content-between">
                                <NCTitle text={'Programs'} type={NCTitleType.TRAINING}/>
                                <div>
                                    <Link href={'/training/program/'}>
                                        <CategorizedButton
                                            text={'Create Program'}
                                            variant={CategorizedButtonVariant.TRAINING}
                                        />
                                    </Link>
                                </div>
                            </div>
                            <div
                                className="flex-fill d-flex flex-column justify-content-between align-items-center m-1">
                                <ProgramList/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row w-100">
                    <div className="col-md-12">
                        <div className="NC-card d-flex flex-column">
                            <div className="d-flex flex-row justify-content-between">
                                <NCTitle text={'Modules'} type={NCTitleType.TRAINING}/>
                                <Link className="mr-2" href={'/training/module/'}>
                                    <CategorizedButton
                                        text={'Create Module'}
                                        variant={CategorizedButtonVariant.TRAINING}
                                    />
                                </Link>
                            </div>
                            <div
                                className="flex-fill d-flex flex-column justify-content-between align-items-center m-1">
                                <ModuleList language={lang} update={moduleRefresh}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <FullScreenDialog
                open={moduleCreation}
                openHook={setModuleCreation}
                actionHook={undefined}
                componentToDisplay={
                    <CreateModuleForm module={module} setModule={setModule}
                                      actionHook={moduleUpdated} creationMode={true}/>
                }
            />
        </div>
    );
};