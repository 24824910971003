import React from 'react';
import {useParams} from 'react-router-dom';
import {NCTitle, NCTitleType} from 'src/atoms/NCTitle/NCTitle';
import {MatchList} from 'src/components/Tournaments/MatchList/MatchList';

export const MatchListFull: React.FunctionComponent = () => {
    const {tournamentid}: any = useParams();

    if (!tournamentid) {
        return <></>;
    }
    return (
        <div className="NC-container NC-card d-flex flex-column">
            <NCTitle text={'Match List'} type={NCTitleType.TOURNAMENT}/>
            <MatchList tournamentId={tournamentid}/>
        </div>
    );
};