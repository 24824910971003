import { TranslatedString } from './TranslatedString';

export class TrainingThematic {
    public _id: string;

    constructor(
        public name: TranslatedString,
        public gameIds: Array<string>,
        public value: string,
        public color: string,
        public visible: boolean,
) {}
}

export enum EThematicsMedia {
    icon = 'icon',
}
