import axios, { AxiosError } from 'axios';
import { NewsModel } from 'src/models/News';
import { MongoPaginateResult } from 'src/models/PaginateResult';

export class NewsService {
    private static baseUrl: string = String(process.env.REACT_APP_ADMINAPI_URL);
    static token = (): string | null => localStorage.getItem('accessToken');

    static async getNews(limit: number = 20, page: number = 0, sortColumn?: string, tag?: string): Promise<MongoPaginateResult<NewsModel>> {
        let res;
    
        const sort: {[key: string]: any} = {};
        if (sortColumn) {
            sort[sortColumn] = -1;
        }

        try {
            res = await axios.get(
                `${NewsService.baseUrl}/news`, {
                    params: {
                        limit,
                        page,
                        tag,
                        sort
                    },
                    headers: {
                        'x-access-token': NewsService.token(),
                    }
                }
            );
        } catch (e) {
            throw (e as AxiosError).response;
        }

        if (!res.data && !res.data.docs) {
            throw Error;
        }

        let newsList = [];
        newsList = res.data.docs.map((item: NewsModel) => {
            const model = new NewsModel(
                item.title,
                item.content,
                item.image,
                item.link,
                item.published,
                item.countryGroup,
                item.countries,
                item.tag
            );
            model._id = item._id;
            model.partner = item.partner;
            return model;
        });

        return {
            docs: newsList,
            page: res.data.page,
            total: res.data.total,
            limit: res.data.limit,
            pages: res.data.pages,
        };
    }

    static async createNews(news: NewsModel): Promise<NewsModel> {
        if (!news) {
            throw Error;
        }
        let newNews: NewsModel;

        try {
            newNews = (await axios.post(
                `${NewsService.baseUrl}/news`,
                news, {
                    headers: {
                        'x-access-token': NewsService.token(),
                    }
                }
            )).data;
        } catch (e) {
            throw (e as AxiosError).response;
        }

        return newNews;
    }

    static async updateNews(news: NewsModel): Promise<boolean> {
        if (!news) {
            return false;
        }

        try {
            await axios.put(
                `${NewsService.baseUrl}/news/${news._id}`,
                news, {
                    headers: {
                        'x-access-token': NewsService.token(),
                    }
                }
            );
        } catch {
            return false;
        }

        return true;
    }

    static async deleteNews(id: string): Promise<boolean> {
        if (!id) {
            return false;
        }

        try {
            await axios.delete(
                `${NewsService.baseUrl}/news/${id}`, {
                    headers: {
                        'x-access-token': NewsService.token(),
                    }
                }
            );
        } catch {
            return false;
        }

        return true;
    }
}