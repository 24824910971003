export interface Media {
    name: string;
    modified: string;
    size: number;
    file: boolean;
    key: string;
    publicUrl: string;
}

export enum FileManagerMode {
    NORMAL = 'NORMAL',
    SELECT = 'SELECT',
}
